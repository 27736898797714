import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {CustomWindowConfig} from '../../../event.model';
import {AppServices} from '../../../app-services.service';
import {EventService} from '../../../event.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-new-route',
  standalone: false,
  templateUrl: './new-route.component.html',
  styleUrl: './new-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewRouteComponent {
  @ViewChild('nameTextBox') nameTextBox;

  @Input() isVisible: boolean;
  @Input() readOnly: boolean;
  @Input() mode: string;
  @Input() addingServiceOrdersRouteId: number;
  @Input() addingModeServiceOrdersRoute: string = '';
  @Input() context: string = '';
  @Input() focusedSelected = [];
  @Input() selectedCRMTask = [];
  @Input() crmParam = {};

  @Output() onClosing = new EventEmitter();
  @Output() updateData = new EventEmitter();
  @Output() onSaveRouteMobileEmitter = new EventEmitter();

  shortcuts: ShortcutInput[] = [];
  mobileHeight = '100%';
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: 546,
      height: 290,
    },
    params: [],
  };
  minWidth: any = null;
  maxHeight: any = null;
  unicalGuid: number;
  title: string;
  form: FormGroup;
  submitted: boolean = false;
  loadChildComponent: boolean = false;
  companyId: number;
  visibleUserExtensions = false;
  isCrmRouteConfirmVisible = false;
  addRouteWithoutContractors = false;
  mobileOperationsVisible = false;
  contextMenuOperationsMobile = [
    {name: 'Pola dodatkowe', itemIndex: 100},
  ];

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private appService: AppServices
  ) {
    this.companyId = this.event.footerInfo.AppCompanyId;

    this.minWidth = this.event.setMinWidthPopUp();
    this.maxHeight = this.event.setMaxHeight;
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      this.createForm();
      switch (this.mode) {
        case 'add':
          this.title = this.translate.instant('routes.addingRoute');
          break;
        case 'edit':
          this.title = this.translate.instant('routes.changingRoute');
      }
    }
  }

  ngAfterViewInit() {
    this.translate
      .get('categories.externalFields')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));

    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(true);
          this.isVisible = false;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.nameTextBox.instance.blur();
            this.onSaveRoute();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + p',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        preventDefault: true,
        command: () => {
          if (!this.readOnly) {
            this.visibleUserExtensions = true;
            this.cd.detectChanges();
          }
        },
      }
    );
  }

  contextMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 100:
        this.visibleUserExtensions = true
        break;
    }
  }

  visibleChange() {
    this.submitted = false;

    if (!this.isVisible) {
      this.onClosing.emit(true);
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      RouteDate: [new Date()],
      RouteId: [null],
      CompanyId: [this.companyId, Validators.required],
      Name: ['', Validators.required],
      Description: [''],
      Field1: [''],
      Field2: [''],
      Field3: [''],
      Field4: [''],
      Field5: [''],
      Field6: [''],
      Field7: [''],
      Field8: [''],
      Field9: [''],
      Field10: ['']
    });

    if (this.focusedSelected.length > 0 && this.mode === 'edit') {
      this.form.patchValue(this.focusedSelected[0]);
    }
  }

  onSaveRouteMobile(mobile = true) {
    this.onSaveRoute(mobile);
  }


  onInit(e: any) {
    e.component.registerKeyHandler('escape', function() {
    });
  }

  onSaveRoute(mobile = false) {
    this.submitted = true;

    if (!this.form.valid) {
      this.nameTextBox.instance.focus();
      return;
    }

    if (this.context === 'CRM' && !this.addRouteWithoutContractors) {
      this.appService.getAuth(`crm/tasks`, this.crmParam)
        .pipe(
          catchError(() => {
            // Kontynuowanie działania funkcji po błędzie
            return of({data: []}); // Zwraca pustą tablicę w przypadku błędu
          })
        )
        .subscribe((res) => {
          const selectedTaskIds = this.selectedCRMTask.length > 0 ? new Set(this.selectedCRMTask) : new Set([this.addingServiceOrdersRouteId]);

          const filteredTasks = res.data.filter((task) => selectedTaskIds.has(task.TaskId));

          const hasTasksWithoutCustomerName = filteredTasks.some(task => !task.CustomerName);

          if (hasTasksWithoutCustomerName) {
            this.isCrmRouteConfirmVisible = true;
            this.cd.detectChanges();
            return;
          } else {
            this.performSaveOperation(mobile);
          }
        });
    } else {
      this.performSaveOperation(mobile);
    }
  }

  performSaveOperation(mobile = false) {
    const formData = this.prepareFormData();

    if (!this.addingModeServiceOrdersRoute) {
      this.appService.postAuth('customers/CustomerRoute', formData)
        .subscribe(
          (res) => {
            this.handleSaveSuccess(res);
            if (mobile) {
              this.isVisible = false;
              this.onSaveRouteMobileEmitter.emit(true);
            }
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    } else {
      this.appService.postAuth('customers/RoutesAddOperation', formData)
        .subscribe(
          (res) => {
            this.handleSaveSuccess(res);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  }

  private prepareFormData(): any {
    const formValue = this.form.value;
    const contextCall = this.context;
    if (this.addingModeServiceOrdersRoute) {
      return {
        ...formValue,
        ContextCall: contextCall,
        Id: this.addingModeServiceOrdersRoute === 'multiple' ? 0 : this.addingServiceOrdersRouteId,
      };
    }

    return formValue;
  }

  private handleSaveSuccess(response: any) {
    this.isVisible = false;
    if (!this.addingModeServiceOrdersRoute) {
      this.updateData.emit(response.Id);
    }
    this.onClosing.emit(true);
    this.cd.detectChanges();
  }

  onInsertedUserExtensions(e: any) {
    this.form.patchValue(e);
  }

  onClosingUserExtensions() {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  }

  onCancelRoute() {
    this.isVisible = false;
  }

  onChoosedDescription = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.controls.Description.setValue(text);
    }
  };

  setFocus() {
    setTimeout(() => {
      this.nameTextBox.instance.focus();
    }, 300);
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  addCRMRouteWithoutContractors() {
    this.addRouteWithoutContractors = true;
    this.isCrmRouteConfirmVisible = false;
    this.onSaveRoute();
  }

  onHiddenPopUp() {
    this.addRouteWithoutContractors = false;
    this.isCrmRouteConfirmVisible = false;
  }
}
