import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { EventService } from 'src/app/event.service';
import { DateFilter } from '../stock-availability.component';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { HelpService } from 'src/app/help-service.service';
import { DxNumberBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrl: './date-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DateFilterComponent {
  @Input('isVisible') isVisible: boolean;
  @Input('values') values: DateFilter;
  @Output('onClosing') onClosing: EventEmitter<any> = new EventEmitter();
  @Output('onSaving') onSaving: EventEmitter<any> = new EventEmitter();

  @ViewChild('focusFirst') focusFirst: DxNumberBoxComponent;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  initialFormValue: DateFilter = {
    firstSaleDaysMax: 0,
    firstSaleDaysMin: 0,
    saleTimeMax: 0,
    saleTimeMin: 0,
  };
  form: FormGroup;
  numberBoxDaysFormat: string = '#0 dni';
  numberBoxDayFormat: string = '#0 dzień';
  isSubmitted: boolean = false;
  formValueChanged: boolean = false;

  shortcuts: ShortcutInput[] = [];
  constructor(
    public cd: ChangeDetectorRef,
    public event: EventService,
    public helpService: HelpService,
    public translate: TranslateService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group(this.initialFormValue);
    this.form.setValidators([
      this.firstSaleComparisonValidator(),
      this.saleTimeComparisonValidator(),
    ]);
    this.getTranslation();
  }

  public firstSaleComparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const firstSaleDaysMax = group.controls['firstSaleDaysMax'];
      const firstSaleDaysMin = group.controls['firstSaleDaysMin'];
      if (
        firstSaleDaysMax.value &&
        firstSaleDaysMin.value &&
        firstSaleDaysMax.value < firstSaleDaysMin.value
      ) {
        firstSaleDaysMin.setErrors({ minGreaterThanMax: true });
        return { minGreaterThanMax: true };
      }
      return null;
    };
  }

  public saleTimeComparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const saleTimeMax = group.controls['saleTimeMax'];
      const saleTimeMin = group.controls['saleTimeMin'];
      if (
        saleTimeMax.value &&
        saleTimeMin.value &&
        saleTimeMax.value < saleTimeMin.value
      ) {
        saleTimeMin.setErrors({ minGreaterThanMax: true });
        return { minGreaterThanMax: true };
      }
      return null;
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.values?.currentValue) {
      this.form.patchValue(changes.values.currentValue);
    }

    this.formValueChanged = this.areObjectsDifferent(
      this.values,
      this.initialFormValue
    );
  }

  areObjectsDifferent(obj1, obj2) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }

  getTranslation() {
    this.translate.get('articles.days').subscribe((res: string) => {
      this.numberBoxDaysFormat = `#0 ${res}`;
    });
    this.translate.get('articles.day').subscribe((res: string) => {
      this.numberBoxDayFormat = `#0 ${res}`;
    });
  }
  visibleChange(_e) {}

  onPopupShown() {
    this.event.onShownPopUp();
    this.setFocus();
  }

  setFocus() {
    setTimeout(() => {
      this.event.setFocus(this.focusFirst);
    });
  }

  onPopupInitialized(_e) {}

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'ctrl + k',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.resetForm();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/menu-raporty/analiza-plynnosci'
          );
        },
        preventDefault: true,
      },
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClose();
        },
        preventDefault: true,
      }
    );
  }

  onSave() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      if (this.form.errors?.minGreaterThanMax) {
        this.event.showNotification(
          'warning',
          'Wartość minimalna nie może być większa od wartości maksymalnej'
        );
      }
      return;
    }
    this.onSaving.emit(this.form.value);
    this.cd.detectChanges();
  }

  onClose() {
    this.onClosing.emit();
  }

  onValueChanged(e) {
    this.isSubmitted = false;

    if (
      this.form.get('firstSaleDaysMin').errors?.minGreaterThanMax &&
      (this.form.controls['firstSaleDaysMin'].value <=
        this.form.controls['firstSaleDaysMax'].value ||
        this.form.controls['firstSaleDaysMax'].value === 0)
    ) {
      this.form.get('firstSaleDaysMin').setErrors(null);
    }

    if (
      this.form.get('saleTimeMin').errors?.minGreaterThanMax &&
      (this.form.controls['saleTimeMin'].value <=
        this.form.controls['saleTimeMax'].value ||
        this.form.controls['saleTimeMax'].value === 0)
    ) {
      this.form.get('saleTimeMin').setErrors(null);
    }

    let control = e.component;
    if (e.value === 1) {
      control.option('format', this.numberBoxDayFormat);
    } else {
      control.option('format', this.numberBoxDaysFormat);
    }
  }

  resetForm() {
    this.form.reset(this.initialFormValue);
    this.isSubmitted = false;
    this.formValueChanged = this.areObjectsDifferent(
      this.form.value,
      this.initialFormValue
    );
  }
}
