<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType != 'mobile' ? heightWindow : 440"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); event.setFocus(IsNRB)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="office-bank-accounts-save"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 470px">
          <form [formGroup]="form">
            <label style="width: 120px">{{
              "bankAccounts.form.accountFormat" | translate
            }}</label>
            <dx-radio-group
              (onValueChanged)="accountFormatChanged($event)"
              [items]="accountFormat"
              layout="horizontal"
              displayExpr="label"
              valueExpr="value"
              [readOnly]="readOnly"
              formControlName="IsNRB"
              style="position: relative"
              #IsNRB
            >
            </dx-radio-group>
            <br /><br />

            <label style="width: 120px">{{
              "bankAccounts.form.accountNumber" | translate
            }}</label>
            <dx-text-box
              *ngIf="form.controls.IsNRB.value"
              width="276"
              [readOnly]="readOnly"
              (onBlur)="onBankNumberChanged()"
              formControlName="AccountNumber"
              [mask]="mask"
              [maskRules]="rules"
              data-cy="office-bank-accounts-number"
            ></dx-text-box>
            <dx-text-box
              *ngIf="!form.controls.IsNRB.value"
              width="276"
              [readOnly]="readOnly"
              formControlName="AccountNumber"
              [ngClass]="{
                'input-required':
                  !form.controls.AccountNumber.valid && submitted
              }"
            ></dx-text-box>

            <label *ngIf="form.controls.IsNRB.value" style="width: 120px"
              >{{ "bankAccounts.form.bank" | translate }}
              <strong>*</strong></label
            >
            <label *ngIf="!form.controls.IsNRB.value" style="width: 120px">{{
              "bankAccounts.form.bank" | translate
            }}</label>
            <div
              *ngIf="form.controls.IsNRB.value"
              style="display: inline-block; width: 276px"
            >
              <app-bank-list
                [dropDownBoxMode]="true"
                (onChoosedBank)="
                  onChoosedBank($event);
                  event.setFocus(currencyBox.currencyDropDownBox)
                "
                [selectedBankId]="form.get('BankId').value"
                [inputRequired]="!form.controls.BankId.valid && submitted"
                data-cy="office-bank-list"
              >
              </app-bank-list>
            </div>
            <div
              *ngIf="!form.controls.IsNRB.value"
              style="display: inline-block; width: 276px"
            >
              <app-bank-list
                [dropDownBoxMode]="true"
                (onChoosedBank)="
                  onChoosedBank($event);
                  event.setFocus(currencyBox.currencyDropDownBox)
                "
                [selectedBankId]="form.get('BankId').value"
              >
              </app-bank-list>
            </div>
            <br />

            <label style="width: 120px">{{
              "bankAccounts.form.currency" | translate
            }}</label>
            <app-currency-box
              [dropDownBoxMode]="true"
              [readOnly]="readOnly"
              #currencyBox
              [selectedId]="form.get('CurrencySymbol').value"
              (onChoosed)="onChoosedCurrency($event)"
            >
            </app-currency-box>

            <label style="width: 120px"
              >{{ "bankAccounts.form.comments" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              width="276"
              [readOnly]="readOnly"
              formControlName="TaxOfficeName"
              #nameInput
              [ngClass]="{
                'input-required':
                  !form.controls.TaxOfficeName.valid && submitted
              }"
              (onFocusIn)="event.onFocusCountry('TaxOfficeName', form)"
              valueChangeEvent="input"
              (onFocusOut)="event.onFocusOut('TaxOfficeName', form)"
              data-cy="office-bank-accounts-comments"
            ></dx-text-box>
            <small
              class="text-danger"
              *ngIf="
                submitted &&
                form.get('TaxOfficeName').focus &&
                !form.controls.TaxOfficeName.valid
              "
            >
              {{ "form.required" | translate }}
            </small>

            <label style="width: 120px"></label>
            <dx-check-box
              text="{{ 'bankAccounts.form.active' | translate }}"
              style="width: 120px"
              [readOnly]="readOnly"
              formControlName="IsActive"
            >
            </dx-check-box>
            <br />
            <label style="width: 120px"></label>
            <dx-check-box
              (onValueChanged)="changedAccountType($event)"
              [readOnly]="readOnly"
              formControlName="IsMicroAccountType"
              style="width: 120px"
              text="{{ 'bankAccounts.form.microAccount' | translate }}"
            >
            </dx-check-box>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-confirm-dialog
  [confirmText]="'bankAccounts.warningAccountNumber'"
  [isVisible]="warningAccountNumber"
  [kindDialog]="'warning'"
  btnConfig="close"
  (onClosing)="cancelWarningAccountNumber()"
>
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isVisibleConfirmCalculateCheckSum"
  [confirmText]="'MessageChecksumMismatch'"
  [isVisible]="true"
  (onRemoving)="onRemovingConfirmCalculateCheckSum()"
  (onClosing)="isCancelConfirmCalculateCheckSum()"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
></wapro-keyboard-shortcuts>
