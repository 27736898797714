import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  DxButtonModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { TaxRatesComponent } from '../../tax-rates/tax-rates.component';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-change-vat',
    templateUrl: './change-vat.component.html',
    styleUrls: ['./change-vat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslateModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxScrollViewModule,
        TaxRatesComponent,
        DxRadioGroupModule,
        NgShortcutsComponent,
        ReactiveFormsModule,
        FormsModule,
    ]
})
export class ChangeVatComponent implements OnInit {
  @Output() onClosed: EventEmitter<void> = new EventEmitter();
  @Output() onSaved: EventEmitter<void> = new EventEmitter();
  form: FormGroup;
  shortcuts: ShortcutInput[] = [];
  priceItems = [
    { label: 'Brutto', value: 'Brutto' },
    { label: 'Netto', value: 'Netto' },
  ];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  constructor(
    public event: EventService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private appServices: AppServices
  ) {
    this.createForm();
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.saveForm();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosed.emit();
        },
        preventDefault: true,
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      VatIn: ['22', Validators.required],
      VatOut: ['23', Validators.required],
      BruttoNetto: ['Brutto', Validators.required],
    });
  }

  saveForm() {
    this.appServices
      .postAuth(`products/additionalOperations/taxRate`, this.form.value)
      .subscribe({
        next: () => {
          this.onSaved.emit();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onTaxRateFromChanged(e) {
    if (e) {
      this.f.VatIn.setValue(e[0].TaxCode);
    }
  }

  onTaxRateToChanged(e) {
    if (e) {
      this.f.VatOut.setValue(e[0].TaxCode);
    }
  }
}
