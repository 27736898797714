import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';
import { HelpService } from '../../../../help-service.service';

@Component({
    selector: 'app-add-order-type',
    templateUrl: './add-order-type.component.html',
    styleUrls: ['./add-order-type.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title'],
    standalone: false
})
export class AddOrderTypeComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @Input() mode: string = 'add';

  @ViewChild('focusFirst') focusFirst;

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;

  widthWindow = 450;
  heightWindow = 150;

  shortcuts: ShortcutInput[] = [];

  form;
  submitted: boolean = false;

  unicalGuid;

  constructor(
    private helpService: HelpService,
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.createForm();
  }

  ngOnChanges() {
    if (this.selectedId && this.mode === 'edit') {
      if (this.selectedId.length != 0 && this.isVisible) {
        this.getDateSelectedId(this.selectedId[0]);
        this.cd.detectChanges();
      }
    }

    if (this.isVisible) {
      setTimeout(() => {
        this.focusFirst.instance.focus();
      }, 700);
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(`services/orders/types?orderTypeId=${selectedId}`)
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      ServiceOrderTypeId: [0],
      Name: ['', Validators.required],
      IsDefault: [false],
    });
  };

  visibleChange = (e) => {
    this.visibleShortcut = e;
    //reset form with initial values
    this.submitted = false;

    if (!e) {
      this.createForm();
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (this.form.value.ServiceOrderTypeId == 0) {
        this.appService
          .postAuth('services/orders/types', this.form.value)
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else {
        this.appService
          .putAuth(
            `services/orders/types/${this.form.value.ServiceOrderTypeId}`,
            this.form.value
          )
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };
}
