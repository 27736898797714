<dx-popup
  [width]="420"
  [height]="event.deviceType != 'mobile' ? 270 : 370"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'discountMarkupFactor.title' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="onSave()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <!-- <div class="top-menu-popup">
            <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div> -->

    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</dx-popup>

<!-- <ng-container *ngIf="!isPopupMode">
    <div *ngIf="!dropDownBoxMode; else dropDownMode">
        <div class="top-menu-panel">
            <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>

        <div class="center-panel-scroll-verticall">
            <ng-container *ngTemplateOutlet="grid"></ng-container>
        </div>
    </div>
</ng-container> -->

<!-- <ng-template #dropDownMode>
    <dx-drop-down-box [(value)]="chossingValue" valueExpr="DocPositionId" [deferRendering]="false"
        displayExpr="Barcode" [(opened)]="isGridBoxOpened" [dataSource]="formBarcodesList"
        (openedChange)="onOpenedChanged($event)" [showClearButton]="true" [ngStyle]="{width: width || '276px;'}" [readOnly]="readOnly"
        (keydown.space)="isGridBoxOpened = true;cd.detectChanges()">
        <div *dxTemplate="let data of 'content'" style="height: 300px">
            <div class="top-menu-popup">
                <ng-container *ngTemplateOutlet="topButtons"></ng-container>
            </div>

            <br>
            <ng-container *ngTemplateOutlet="grid"></ng-container>
        </div>
    </dx-drop-down-box>
</ng-template> -->

<ng-template #topButtons> </ng-template>

<ng-template #grid>
  <div class="d-flex justify-content-center flex-wrap">
    <div class="form-group-inline-right" style="width: 100%">
      <div class="c-frame-row" style="width: 100%">
        <h5 *ngIf="type == 0">
          {{ "form-commercial-operation.grid.netValue" | translate }}
        </h5>
        <h5 *ngIf="type == 1">
          {{ "form-commercial-operation.grid.grossValue" | translate }}
        </h5>
      </div>
      <label style="width: 185px" for="">{{
        "discountMarkupFactor.valueBefore" | translate
      }}</label>
      <dx-number-box
        width="160"
        [readOnly]="true"
        format="#0.00"
        [value]="valueBefore"
      ></dx-number-box>
      <br />
      <label style="width: 185px" for="">
        {{ "discountMarkupFactor.markupDiscountPercent" | translate }}</label
      >
      <dx-number-box
        width="160"
        [readOnly]="readOnly"
        format="#0.00"
        [useLargeSpinButtons]="true"
        [showSpinButtons]="true"
        [(value)]="discountMarkupPercent"
        (onValueChanged)="onEnterMarkupPercent($event)"
      ></dx-number-box>
      <br />
      <!-- onFocusOut -->
      <label style="width: 185px" for="">
        {{ "discountMarkupFactor.markupDiscountValue" | translate }}</label
      >
      <dx-number-box
        width="160"
        [readOnly]="readOnly"
        format="#0.00"
        [(value)]="discountMarkupAmount"
        (onValueChanged)="onEnterMarkupAmount($event)"
      ></dx-number-box>
      <br />
      <label style="width: 185px" for="">
        {{ "discountMarkupFactor.valueWithDiscount" | translate }}</label
      >
      <dx-number-box
        width="160"
        [readOnly]="readOnly"
        format="#0.00"
        [(value)]="valueAfter"
        (onValueChanged)="onEnterMarkupValueAfter($event)"
      ></dx-number-box>
    </div>
  </div>
  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [unicalGuid]="unicalGuid"
    [disabled]="isVisible"
  >
  </wapro-keyboard-shortcuts>
</ng-template>
