<dx-popup
  [width]="
    event.deviceType != 'mobile' ? localStorageData.windowSize.width : '100%'
  "
  [height]="event.deviceType != 'mobile' ? '650px' : '90%'"
  [position]="event.deviceType != 'mobile' ? 'bottom' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); event.setFocus(firstFocus.focusDropBox)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <form
        *ngIf="
          typeDocument !== 'services/orders';
          else formServicesOrderTemplate
        "
        [formGroup]="form"
      >
        <dx-tab-panel
          #tabPanel
          [items]="['companies.tabs.basicInfo' | translate]"
          [loop]="false"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          class="mt5px"
        >
          <div *dxTemplate="let name of 'item'" >
            <ng-container [ngSwitch]="name">
              <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                <div class="form-columns">
                  <div class="form-column form-column__left">
                    <!-- Forma dostawy -->
                    <div class="form-column__item">
                      <label
                        >{{ "deliveries.formDelivery" | translate
                        }}<strong>*</strong></label
                      >

                      <div class="form-control">
                        <app-delivery
                          [errorClass]="
                            form.controls.DeliveryFormId.invalid && submitted
                          "
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          [ngClass]="{ maxWidth: event.deviceType == 'mobile' }"
                          (onChoosed)="
                            onChoosedDeliveryForm($event);
                            event.setFocus(customerBox.contractorsBox)
                          "
                          #firstFocus
                          [selectedId]="form.value.DeliveryFormId"
                        >
                        </app-delivery>
                      </div>
                    </div>
                  </div>
                  <div class="form-column form-column__left">
                    <div class="form-column__header">
                      {{
                        "form-commercial-operation.deliveryAddress" | translate
                      }}
                    </div>

                    <!-- Kontrahent -->
                    <div class="form-column__item">
                      <label>{{
                        "form-financial-document.payer-type.contractor"
                          | translate
                      }}</label>
                      <div class="form-control">
                        <app-contractors
                          [selectedId]="form.get('CustomerId').value"
                          [readOnly]="readOnly"
                          [dropDownBoxMode]="true"
                          (onChoosed)="onChoosedCustomer($event)"
                          #customerBox
                        >
                        </app-contractors>
                      </div>
                    </div>

                    <!-- Wybierz adres -->
                    <div class="form-column__item">
                      <div class="form-control">
                        <dx-button
                          class="btn-dx-secondary"
                          text="{{ 'crmDeliveries.chooseAddress' | translate }}"
                          (onClick)="showAddresse = true"
                          [disabled]="readOnly"
                          #btnShowAddresse
                        >
                        </dx-button>
                      </div>
                    </div>

                    <!-- Kraj -->
                    <div class="form-column__item">
                      <label>{{
                        "companies.form.addressDetail.country" | translate
                      }}</label>
                      <div class="form-control">
                        <app-country-box
                          [advancedChooser]="true"
                          #countryBox
                          [selectedCountry]="form.get('CountryCode').value"
                          (onChoosed)="selectedCountryChange($event)"
                          [readOnly]="true"
                          [ngClass]="{ maxWidth: event.deviceType == 'mobile' }"
                        >
                        </app-country-box>
                      </div>
                    </div>

                    <!-- Kod pocztowy -->
                    <div class="form-column__item">
                      <label>{{
                        "companies.form.postalCode" | translate
                      }}</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="true"
                          formControlName="PostalCode"
                          #postalCodeInput
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <!-- Miejscowość -->
                    <div class="form-column__item">
                      <label>{{
                        "companies.grid.columns.city" | translate
                      }}</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="true"
                          formControlName="City"
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <!-- Ulica, lokal -->
                    <div class="form-column__item">
                      <label
                        >{{ "companies.grid.columns.street" | translate }},
                        {{
                          "companies.grid.columns.local" | translate | lowercase
                        }}</label
                      >
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="true"
                          formControlName="Street"
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <!-- Typ adresu -->
                    <div class="form-column__item">
                      <label>{{ "delivery.addressType" | translate }}</label>
                      <div class="form-control">
                        <dx-select-box
                          [dataSource]="addressTypeList"
                          displayExpr="label"
                          valueExpr="value"
                          [readOnly]="true"
                          formControlName="AddressType"
                        ></dx-select-box>
                      </div>
                    </div>

                    <!-- Telefon -->
                    <div class="form-column__item">
                      <label>{{ "companies.form.phone" | translate }}</label>
                      <div class="form-control">
                        <dx-text-box [readOnly]="true" formControlName="Phone">
                        </dx-text-box>
                      </div>
                    </div>

                    <!-- Email -->
                    <div class="form-column__item">
                      <label>{{ "menu.eMail" | translate }}</label>
                      <div class="form-control">
                        <dx-text-box [readOnly]="true" formControlName="Email">
                        </dx-text-box>
                      </div>
                    </div>

                    <!-- Operator -->
                    <div class="form-column__item">
                      <label style="width: 120px">Operator</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="true"
                          formControlName="PostageOperator"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <!-- Punkt odbioru -->
                    <div class="form-column__item">
                      <label>{{
                        "constractors.pickupPoint" | translate
                      }}</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="true"
                          formControlName="PickUpAddressCode"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <div class="form-control">
                        <dx-button
                          class="btn-dx-secondary"
                          text="{{
                            'serviceOrdersDeliveryAddress.correctAddress'
                              | translate
                          }}"
                          (onClick)="correctAddress = true"
                          [disabled]="
                            readOnly ||
                            !this.form.controls.PostalCode.value ||
                            !this.form.controls.City.value ||
                            !this.form.controls.Street.value
                          "
                          #btnShowAddresse
                        >
                        </dx-button>
                      </div>
                    </div>
                  </div>
                  <div class="form-column form-column__right">
                    <div class="form-column__header">
                      {{ "constractors.pickupDeliveryParameters" | translate }}
                    </div>

                    <!-- Planowana data  -->
                    <div class="form-column__item">
                      <label>{{ "deliveries.plannedDate" | translate }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="date"
                          [readOnly]="readOnly"
                          [displayFormat]="event.dateFormat"
                          formControlName="PlanDate"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <!-- Planowana godzina od -->
                    <div class="form-column__item">
                      <label>{{
                        "deliveries.receivedPlanTimeFrom" | translate
                      }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="time"
                          [readOnly]="readOnly"
                          formControlName="ReceivedPlanTimeFrom"
                          displayFormat="HH:mm"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <!-- Planowana godzina do -->
                    <div class="form-column__item">
                      <label>{{
                        "deliveries.receivedPlanTimeTo" | translate
                      }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="time"
                          [readOnly]="readOnly"
                          formControlName="ReceivedPlanTimeTo"
                          displayFormat="HH:mm"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <!-- Data odbioru -->
                    <div class="form-column__item">
                      <label>{{ "deliveries.receivedDate" | translate }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="date"
                          [readOnly]="readOnly"
                          formControlName="ReceivedDate"
                          [displayFormat]="event.dateFormat"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <!-- Godzina odbioru -->
                    <div class="form-column__item">
                      <label>{{ "deliveries.receivedTime" | translate }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="time"
                          [readOnly]="readOnly"
                          formControlName="ReceivedTime"
                          displayFormat="HH:mm"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <!-- Odebrano -->
                    <div class="form-column__item">
                      <div class="form-control">
                        <dx-check-box
                          text="{{ 'deliveries.received' | translate }}"
                          formControlName="IsReceived"
                          [readOnly]="readOnly"
                          [ngClass]="{ maxWidth: event.deviceType == 'mobile' }"
                        ></dx-check-box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </form>

      <ng-template #formServicesOrderTemplate>
        <form [formGroup]="formServicesOrder">
          <dx-tab-panel
            #tabPanel
            [items]="['companies.tabs.basicInfo' | translate]"
            [loop]="false"
            [animationEnabled]="false"
            [swipeEnabled]="false"
          >
            <div *dxTemplate="let name of 'item'" >
              <ng-container [ngSwitch]="name">
                <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                  <div class="form-columns">
                    <div class="form-column form-column__left">

                      <!-- Forma dostawy -->
                      <div class="form-column__item">
                        <label
                          >{{ "deliveries.formDelivery" | translate
                          }}<strong>*</strong></label
                        >
                        <div class="form-control">
                          <app-delivery
                            [errorClass]="
                              formServicesOrder.controls.DeliveryFormId
                                .invalid && submitted
                            "
                            [dropDownBoxMode]="true"
                            [readOnly]="readOnly"
                            [ngClass]="{
                              maxWidth: event.deviceType == 'mobile'
                            }"
                            (onChoosed)="
                              onChoosedDeliveryForm($event);
                              event.setFocus(customerBox.contractorsBox)
                            "
                            #firstFocus
                            [selectedId]="
                              formServicesOrder.value.DeliveryFormId
                            "
                          >
                          </app-delivery>
                        </div>
                      </div>
                    </div>

                    <div class="form-column form-column__left">
                      <div class="form-column__header">
                        {{
                          "form-commercial-operation.deliveryAddress"
                            | translate
                        }}
                      </div>

                      <!-- Kontrahent -->
                      <div class="form-column__item">
                        <label>{{
                          "form-financial-document.payer-type.contractor"
                            | translate
                        }}</label>
                        <div class="form-control">
                          <app-contractors
                            [selectedId]="form.get('CustomerId').value"
                            [readOnly]="readOnly"
                            [dropDownBoxMode]="true"
                            (onChoosed)="
                              onChoosedCustomerForServicesOrder($event)
                            "
                            #customerBox
                          >
                          </app-contractors>
                        </div>
                      </div>

                      <!-- Wybierz adres -->
                      <div class="form-column__item">
                        <div class="form-control">
                          <dx-button
                            class="btn-dx-secondary"
                            text="{{
                              'crmDeliveries.chooseAddress' | translate
                            }}"
                            (onClick)="showAddresse = true"
                            [disabled]="readOnly"
                            #btnShowAddresse
                          >
                          </dx-button>
                        </div>
                      </div>

                      <!-- Kraj -->
                      <div class="form-column__item">
                        <label>{{
                          "companies.form.addressDetail.country" | translate
                        }}</label>
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="deliveryAddress[0].CountryCode"
                            [ngClass]="{
                              maxWidth: event.deviceType == 'mobile'
                            }"
                            #countryBox
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Kod pocztowy -->
                      <div class="form-column__item">
                        <label>{{
                          "companies.form.postalCode" | translate
                        }}</label>
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="deliveryAddress[0].PostalCode"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Miejscowość -->
                      <label>{{
                        "companies.grid.columns.city" | translate
                      }}</label>
                      <dx-text-box
                        [disabled]="true"
                        [value]="deliveryAddress[0].City"
                      >
                      </dx-text-box>

                      <!-- Ulica, lokal -->
                      <div class="form-column__item">
                        <label
                          >{{ "companies.grid.columns.street" | translate }},
                          {{
                            "companies.grid.columns.local"
                              | translate
                              | lowercase
                          }}</label
                        >
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="deliveryAddress[0].Street"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Typ adresu -->
                      <div class="form-column__item">
                        <label>{{ "delivery.addressType" | translate }}</label>
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="
                              deliveryAddress[0].AddressType === 0
                                ? this.addressTypeList[0].label
                                : this.addressTypeList[1].label
                            "
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Telefon -->
                      <div class="form-column__item">
                        <label>{{ "companies.form.phone" | translate }}</label>
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="deliveryAddress[0].Phone"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Operator -->
                      <div class="form-column__item">
                        <label>{{ "formPayment.operator" | translate }}</label>
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="deliveryAddress[0].PostageOperator"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Punkt odbioru -->
                      <div class="form-column__item">
                        <label>{{
                          "constractors.pickupPoint" | translate
                        }}</label>
                        <div class="form-control">
                          <dx-text-box
                            [disabled]="true"
                            [value]="deliveryAddress[0].PickUpAddressCode"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <!-- Popraw adres -->
                      <div class="form-column__item">
                        <div class="form-control">
                          <dx-button
                            class="btn-dx-secondary"
                            text="{{
                              'serviceOrdersDeliveryAddress.correctAddress'
                                | translate
                            }}"
                            (onClick)="correctAddress = true"
                            [disabled]="
                              readOnly ||
                              !this.deliveryAddress[0].PostalCode ||
                              !this.deliveryAddress[0].City ||
                              !this.deliveryAddress[0].Street
                            "
                            #btnShowAddresse
                          >
                          </dx-button>
                        </div>
                      </div>
                    </div>

                    <div class="form-column form-colum__right">
                      <div class="form-column__header">
                        {{
                          "constractors.pickupDeliveryParameters" | translate
                        }}
                      </div>

                      <!-- Planowana data  -->
                      <div class="form-column__item">
                        <label>{{
                          "deliveries.plannedDate" | translate
                        }}</label>
                        <div class="form-control">
                          <wapro-date-box
                            type="date"
                            [readOnly]="readOnly"
                            [displayFormat]="event.dateFormat"
                            formControlName="PlanDate"
                          >
                          </wapro-date-box>
                        </div>
                      </div>

                      <!-- Planowana godzina od -->
                      <div class="form-column__item">
                        <label>{{
                          "deliveries.receivedPlanTimeFrom" | translate
                        }}</label>
                        <div class="form-control">
                          <wapro-date-box
                            type="time"
                            [readOnly]="readOnly"
                            formControlName="ReceivedPlanTimeFrom"
                            displayFormat="HH:mm"
                            (onValueChanged)="
                              onReceivedPlanTimeFromChanged($event)
                            "
                          >
                          </wapro-date-box>
                        </div>
                      </div>

                      <!-- Planowana godzina do -->
                      <div class="form-column__item">
                        <label>{{
                          "deliveries.receivedPlanTimeTo" | translate
                        }}</label>
                        <div class="form-control">
                          <wapro-date-box
                            type="time"
                            [readOnly]="readOnly"
                            formControlName="ReceivedPlanTimeTo"
                            displayFormat="HH:mm"
                            (onValueChanged)="
                              onReceivedPlanTimeToChanged($event)
                            "
                          >
                          </wapro-date-box>
                        </div>
                      </div>

                      <!-- Data odbioru -->
                      <div class="form-column__item">
                        <label>{{
                          "deliveries.receivedDate" | translate
                        }}</label>
                        <div class="form-control">
                          <wapro-date-box
                            type="date"
                            [readOnly]="readOnly"
                            formControlName="ReceivedDate"
                            [displayFormat]="event.dateFormat"
                          >
                          </wapro-date-box>
                        </div>
                      </div>

                      <!-- Godzina odbioru -->
                      <div class="form-column__item">
                        <label>{{
                          "deliveries.receivedTime" | translate
                        }}</label>
                        <div class="form-control">
                          <wapro-date-box
                            type="time"
                            [readOnly]="readOnly"
                            formControlName="ReceivedTime"
                            displayFormat="HH:mm"
                            (onValueChanged)="onReceivedTimeChanged($event)"
                          >
                          </wapro-date-box>
                        </div>
                      </div>

                      <!-- Odebrano -->
                      <div class="form-column__item">
                        <div class="form-control">
                          <dx-check-box
                            text="{{ 'deliveries.received' | translate }}"
                            formControlName="IsReceived"
                            [readOnly]="readOnly"
                            [ngClass]="{
                              maxWidth: event.deviceType == 'mobile'
                            }"
                          ></dx-check-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </dx-tab-panel>
        </form>
      </ng-template>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-adress
  [correctAddress]="correctAddress"
  [customerId]="
    typeDocument !== 'services/orders'
      ? form.value.CustomerId
      : formServicesOrder.value.CustomerId
  "
  [popUpMode]="true"
  [isVisible]="showAddresse"
  (onClosed)="onClosedAddress()"
  (onCorrected)="onCorectedAddress()"
  (onChoosed)="onChoosedAddress($event)"
  #addressesList
></app-adress>
<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  [confirmText]="'serviceOrdersDeliveryAddress.pleaseSelectDeliveryAddress'"
  [isVisible]="showConfirmSelectDeliveryAddress"
  [kindDialog]="'warning'"
  btnConfig="close"
  (onClosing)="cancelConfirmSelectDeliveryAddress()"
>
</app-confirm-dialog>
