<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '260px'"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); event.setFocus(firsFocus)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical" #scrollView>
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 470px">
          <form [formGroup]="form">
            <label style="width: 100px"
              >{{ "bankBox.grid.columns.postalCode" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              width="276"
              formControlName="Code"
              [ngClass]="{
                'input-required': !form.controls.Code.valid && submitted
              }"
              [readOnly]="readOnly"
              valueChangeEvent="input"
              #firsFocus
            ></dx-text-box
            ><br />

            <label style="width: 100px"
              >{{ "companies.grid.columns.name" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              width="276"
              formControlName="Name"
              [ngClass]="{
                'input-required': !form.controls.Name.valid && submitted
              }"
              [readOnly]="readOnly"
              valueChangeEvent="input"
            ></dx-text-box>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>

  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [disabled]="!isVisible"
    [unicalGuid]="unicalGuid"
  >
  </wapro-keyboard-shortcuts>
</dx-popup>
