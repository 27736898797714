<dx-data-grid #dateTimesGrid [dataSource]="dataSource" [remoteOperations]="true" [wordWrapEnabled]="true" keyExpr="Date"
    [showBorders]="true" (onSelectionChanged)="onSelectionChanged($event)" [(selectedRowKeys)]="selectedDateTimes">

<!-- format="shortDate"   -->
    <dxo-selection mode="single"></dxo-selection>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
<!-- [groupIndex]="0" -->
    <dxi-column dataField="Date" [caption]="'baseCurrencies.rateDay' | translate"></dxi-column>
    <!-- [cellTemplate]="'dateTemplate'" -->
    <!-- <dxi-column dataField="CurrencyCode" [caption]="'bankAccounts.form.currency' | translate"></dxi-column>
    <dxi-column dataField="Buy" [caption]="'baseCurrencies.buyValue' | translate" [cellTemplate]="'currencyTemplate'"></dxi-column>
    <dxi-column dataField="Sell" [caption]="'baseCurrencies.sellValue' | translate" [cellTemplate]="'currencyTemplate'"></dxi-column>
    <dxi-column dataField="Mid" [caption]="'baseCurrencies.midValue' | translate" [cellTemplate]="'currencyTemplate'"></dxi-column> -->
    <!--  [cellTemplate]="'timeTemplate'" -->
<!-- dataType="number" format="shortTime" -->
    <!-- <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail> -->
    <!-- <div *dxTemplate="let employee of 'detail'">
        <dx-data-grid #currencyValuesGrid [dataSource]="nestedData" *ngIf="nestedData.length; else noData"
        (onSelectionChanged)="onCurrencySelectionChanged($event)"
        [(selectedRowKeys)]="selectedCurrencyValues">
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="CurrencyCode" [caption]="'bankAccounts.form.currency' | translate"></dxi-column>
            <dxi-column dataField="Buy" [caption]="'baseCurrencies.buyValue' | translate" [cellTemplate]="'currencyTemplate'"></dxi-column>
            <dxi-column dataField="Sell" [caption]="'baseCurrencies.sellValue' | translate" [cellTemplate]="'currencyTemplate'"></dxi-column>
            <dxi-column dataField="Mid" [caption]="'baseCurrencies.midValue' | translate" [cellTemplate]="'currencyTemplate'"></dxi-column>

            <div *dxTemplate="let cell of 'currencyTemplate'">
                {{ cell.value | number : '1.4-4' }}
            </div>

            <dxo-selection mode="single"></dxo-selection>
        </dx-data-grid>
    </div> -->

    <div *dxTemplate="let cell of 'currencyTemplate'">
        {{ cell.value | number : '1.4-4' }}
    </div>

    <div *dxTemplate="let cell of 'dateTemplate'">
        {{ cell.data.Date | date:'shortDate' }}
    </div>

    <div *dxTemplate="let cell of 'timeTemplate'">
        {{ cell.data.Date | date:'shortTime' }}
    </div>

</dx-data-grid>
