import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { SetupWizardConfig } from 'src/app/core/setup-wizard/ISetupWizardConfig';
import { CustomWindowConfig, SingleRecordMode } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { HelpService } from 'src/app/help-service.service';
import { InterconnectorService } from 'src/app/interconnector.service';
import { PrintConfigStorageData } from '../../core/print-settings/print-config-storage-data.model';
import { FiscalPrinter } from './fiscal-printer/fiscal-printer';

@Component({
    selector: 'app-fiscal-printers',
    templateUrl: './fiscal-printers.component.html',
    styleUrls: ['./fiscal-printers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FiscalPrintersComponent {
  helpService = inject(HelpService);
  @Output() onClosing = new EventEmitter();
  @Input() isVisible = false;
  @Input() isPopupMode = false;
  @ViewChild('fiscalPrintersGrid') fiscalPrintersGrid;
  @ViewChild('gridContainer') gridContainer;
  @ViewChild('popup') popup;
  componentVisible: boolean = false;
  mode: SingleRecordMode = 'add';
  isSingleFiscalPrinterVisible: boolean = false;
  isDeleteFiscalPrinterVisible: boolean = false;
  deleteFiscalPrinterConfirmText: string =
    'externalDevices.deleteConfirmText.single';
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: '',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      isVisible: null,
      height: null,
      selectedIndex: null,
    },
    sidePanel: {
      width: null,
      isVisible: true,
      selectedIndex: null,
    },
    windowSize: {
      isFullscreen: false,
      width: '90%',
      height: '90%',
    },
    params: [],
  };

  focusedFiscalPrinterId: number = null;
  focusedFiscalPrinterIndex: number = null;
  selectedFiscalPrinters: number[] = [];

  pages: number = 100;
  currentPage = 0;

  dataSource: FiscalPrinter[];
  loadChildComponent: boolean = false;
  isAllSelected: boolean = false;

  contextMenu: any[];
  permission: boolean;
  computerName: string = null;
  fiscalPrinterTypes: any;
  printConfig: PrintConfigStorageData;
  noInterconnectorResponse: boolean = false;
  loading: boolean = true;
  firstLoad: boolean = true;
  isSetupWizardVisible: boolean;
  connectionErrorNotification: string;
  connectionErrorNotificationHeader: string;

  setupWizardConfig: SetupWizardConfig = {
    headerText: 'fiscalPrinter.setupWizard.headerText',
    bodyText: 'fiscalPrinter.setupWizard.bodyText',
    buttons: [
      { type: 'success', text: 'fiscalPrinter.setupWizard.buttons.primary' },
      { type: 'danger', text: 'fiscalPrinter.setupWizard.buttons.secondary' },
    ],
    footerItems: [
      {
        icon: 'icon absui-icon--help',
        text: 'fiscalPrinter.setupWizard.footerText',
        type: 'link',
      },
    ],
  };
  shortcuts: ShortcutInput[] = [];
  setupWizardShown: boolean = false;
  constructor(
    private appService: AppServices,
    private interconnector: InterconnectorService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.permission = this.event.checkPermissions('DrukFis');
    this.printConfig = this.event.getPrintConfig();

    this.contextMenu = [
      {
        text: 'Dodaj',
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        translation: 'buttons.add',
      },
      {
        text: 'Edytuj',
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        translation: 'buttons.edit',
      },
      {
        text: 'Usuń',
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        translation: 'buttons.delete',
      },
      {
        text: 'Zaznacz wszystko',
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 4,
        translation: 'dataCollectors.contextMenu.selectAll',
      },

      {
        text: 'Odznacz wszystko',
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 5,
        translation: 'dataCollectors.contextMenu.unselectAll',
      },
      {
        text: 'Odwróć zaznaczenie',
        icon: 'icon absui-icon--checkbox-arrowback',
        itemIndex: 6,
        translation: 'dataCollectors.contextMenu.invertSelection',
      },
      {
        text: 'Operacje dodatkowe',
        translation: 'form-commercial-operation.additionalOperations',
        items: [],
      },
    ];

    this.translateContextMenu(this.contextMenu);
    this.connectionErrorNotification = this.translate.instant(
      'fiscalPrinter.connectionErrorNotification'
    );
    this.connectionErrorNotificationHeader = this.translate.instant(
      'fiscalPrinter.connectionErrorNotificationHeader'
    );
  }

  async getComputerName() {
    return new Promise<string>((resolve) => {
      if (this.event.deviceType === 'mobile') {
        this.showMobileInterconnectorErrorNotification();
        resolve(null);
        return;
      }
      if (!this.computerName) {
        this.interconnector.getComputerName().subscribe({
          next: (res) => {
            this.printConfig.computerName = res;
            resolve(res);
          },
          error: (err) => {
            let error = JSON.parse(err);
            if (error.error == 0 && this.computerName) {
              resolve(this.computerName);
              return;
            }
            if (error.error == 0) {
              this.noInterconnectorResponse = true;

              this.showInterconnectorErrorNotification();
            } else {
              // this.event.httpErrorNotification(err);
            }
            resolve(null);
          },
        });
      } else {
        this.noInterconnectorResponse = false;
        resolve(this.computerName);
      }
    });
  }

  showInterconnectorErrorNotification() {
    if (this.event.deviceType === 'mobile') {
      this.showMobileInterconnectorErrorNotification();
      return;
    }
    this.setupWizardShown =
      this.interconnector.isSetupWizardShown('Drukarka fiskalna');
    if (this.setupWizardShown) return;
    this.isSetupWizardVisible = true;
  }

  showMobileInterconnectorErrorNotification() {
    this.setupWizardConfig = {
      headerText: 'externalDevices.setupWizard.headerText.mobile',
      bodyText: 'externalDevices.setupWizard.mobileBodyText',
      buttons: [{ type: 'danger', text: 'buttons.close' }],
      footerItems: [
        {
          icon: 'icon absui-icon--help',
          text: 'externalDevices.setupWizard.footerText',
          type: 'link',
        },
      ],
    };
    this.isSetupWizardVisible = true;
    this.cd.detectChanges();
  }

  translateContextMenu(list) {
    for (let item of list) {
      if (item.translation)
        this.translate
          .get(item.translation)
          .subscribe((text) => (item.text = text));
      if (item.items?.length) this.translateContextMenu(item.items);
    }
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (this.permission) this.showSingleFiscalPrinter('add');
        break;
      case 1:
        if (this.permission) this.showSingleFiscalPrinter('edit');
        break;
      case 2:
        if (this.permission) this.deleteFiscalPrinter();
        break;
      case 4:
        for (let printer of this.dataSource) {
          this.selectedFiscalPrinters.push(printer.FiscalPrinterId);
        }
        break;
      case 5:
        this.selectedFiscalPrinters = [];
        break;
      case 6:
        for (let printer of this.dataSource) {
          if (this.selectedFiscalPrinters.includes(printer.FiscalPrinterId)) {
            this.selectedFiscalPrinters.splice(
              this.selectedFiscalPrinters.indexOf(printer.FiscalPrinterId),
              1
            );
          } else {
            this.selectedFiscalPrinters.push(printer.FiscalPrinterId);
          }
        }
        break;
    }
  };

  onPopupResizeEnd() {
  }

  showSingleFiscalPrinter = (mode) => {
    this.mode = mode;
    this.isSingleFiscalPrinterVisible = true;
    this.cd.detectChanges();
  };

  deleteFiscalPrinter = () => {
    if (this.selectedFiscalPrinters?.length) {
      this.deleteFiscalPrinterConfirmText =
        'dataCollectors.deleteConfirmText.manyTypes';
    } else {
      this.deleteFiscalPrinterConfirmText =
        'dataCollectors.deleteConfirmText.singleType';
    }
    this.isDeleteFiscalPrinterVisible = true;
  };

  onPrinterSave = (e: FiscalPrinter) => {
    this.refresh(e.FiscalPrinterId);
  };

  async ngOnInit() {
    this.componentVisible = true;
    this.loading = true;
    this.event.onShown();
    this.computerName = await this.getComputerName();
    this.getPrinterTypes().then(() => {
      this.getFiscalPrinters().then(() => this.cd.detectChanges());
      this.event.onHidden();
    });
    this.getDefaultPrinter();
    this.loading = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadChildComponent = true;
    }, 1000);
    this.shortcuts.push(
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/administrator/urzadzenia-zewnetrzne/'
          );
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.loading || !this.noInterconnectorResponse) {
            this.showSingleFiscalPrinter('add');
          }
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            (this.focusedFiscalPrinterId && !this.loading) ||
            (this.focusedFiscalPrinterId && !this.noInterconnectorResponse)
          ) {
            this.showSingleFiscalPrinter('edit');
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            (this.focusedFiscalPrinterId && !this.loading) ||
            (this.focusedFiscalPrinterId && !this.noInterconnectorResponse)
          ) {
            this.deleteFiscalPrinter();
          }
        },
        preventDefault: true,
      }
    );
  }

  onClosingForm = () => {
    this.isSingleFiscalPrinterVisible = false;
  };

  onClosingDeleteFiscalPrinter = () => {
    this.isDeleteFiscalPrinterVisible = false;
  };

  onCancelingDeleteFiscalPrinter = () => {
    this.isDeleteFiscalPrinterVisible = false;
  };

  onRemovingFiscalPrinter() {
    const toDelete = this.selectedFiscalPrinters.length
      ? this.selectedFiscalPrinters
      : [this.focusedFiscalPrinterId];
    this.deletePrinters(toDelete).then(() => {
      this.isDeleteFiscalPrinterVisible = false;
      this.refresh().then(() => {
        this.cd.detectChanges();
      });
    });
  }

  deletePrinters(toDelete: number[]) {
    return new Promise<boolean>((resolve, reject) => {
      this.appService
        .deleteAuth(`device/fiscalPrinter/${toDelete[0]}`)
        .subscribe({
          next: () => {
            if (this.printConfig.defaultPrinterId == toDelete[0]) {
              this.setDefaultPrinter(toDelete[0]);
            }
            toDelete.shift();
            if (toDelete.length) {
              resolve(this.deletePrinters(toDelete));
            } else {
              resolve(true);
            }
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
            reject(false);
          },
        });
    });
  }

  refresh = (idToFocus?) => {
    return new Promise<boolean>((resolve) => {
      this.getFiscalPrinters().then(() => {
        if (!idToFocus) this.fiscalPrintersGrid.instance.focus();

        this.getDefaultPrinter();
        resolve(true);
      });
    });
  };

  getPrinterTypes = () => {
    return new Promise<void>((resolve, reject) => {
      this.appService.getAuth('device/fiscalPrinter/types').subscribe({
        next: (res) => {
          this.fiscalPrinterTypes = res.data;
          resolve();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          reject();
        },
      });
    });
  };

  getFiscalPrinters = (mode?) => {
    return new Promise((resolve) => {
      const params: any = {
        computerName: this.computerName,
      };
      if (mode === 'single')
        params.fiscalPrinterId = this.focusedFiscalPrinterId;

      this.appService.getAuth(`device/fiscalPrinter`, params).subscribe({
        next: (res) => {
          this.dataSource = res.data;
          this.dataSource.forEach((element) => {
            element.TypeDescription = this.fiscalPrinterTypes.find(
              (x) => x.TypeId == element.TypeId
            ).Description;
          });
          this.cd.detectChanges();
          resolve(true);
        },
        error: (err) => {
          if (err) {
            err = JSON.parse(err);
            this.event.showNotification('error', err.detail);
            resolve(false);
          }
        },
      });
    });
  };

  onGridReady = () => {};

  gridOnInitialized() {}
  onRowClick = () => {};
  onRowDblClick = () => {
    this.showSingleFiscalPrinter('edit');
  };

  getDefaultPrinter = () => {
    const params: any = {
      computerName: this.computerName,
      showDefault: true,
    };
    this.appService.getAuth(`device/fiscalPrinter`, params).subscribe({
      next: (res) => {
        const defaultPrinter = res.data[0] ? res.data[0] : null;
        this.saveDefaultPrinter(defaultPrinter);
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  };

  setDefaultPrinter = (id?) => {
    id = id || null;

    if (!id) {
      this.saveDefaultPrinter(null);
      return;
    }

    this.appService
      .postAuth(
        `device/fiscalPrinter/${id}/setDefault/${this.computerName}`,
        null
      )
      .subscribe({
        next: () => {
          this.getDefaultPrinter();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  };

  saveDefaultPrinter = (fiscalPrinter: FiscalPrinter) => {
    this.printConfig = this.event.getPrintConfig();
    this.printConfig.computerName = this.computerName;
    // this.printConfig.defaultPrinterId = fiscalPrinter.FiscalPrinterId;
    this.printConfig.defaultFiscalPrinter = fiscalPrinter;
    this.event.savePrintConfig(this.printConfig);
    this.cd.detectChanges();
  };

  onConfirm() {}
  onClose() {}

  async changeComputerName(e) {
    if (!e.value) {
      this.computerName = null;
      e.component.option('value', this.computerName);
      this.cd.detectChanges();
      return;
    }
    this.computerName = e.value;
    this.interconnector.setComputerName(e.value);
    this.refresh();
  }

  onSetupWizardClosing() {
    this.interconnector.onSetupWizardShown('Drukarka fiskalna');
    this.setupWizardShown = true;
    this.isSetupWizardVisible = false;
    this.cd.detectChanges();
  }

  onSetupWizardContinue() {
    // this.isSetupWizardVisible = false;
    // this.cd.detectChanges();
    const url =
      'https://storage.wapro.pl/storage/InstalatorWAPRO/InstalatorWAPRO.exe';
    window.open(url, '_blank');
  }

  onSetupWizardLinkClick() {
    const url =
      'https://wapro.pl/dokumentacja-erp/anywhere/docs/sprzedaz-i-magazyn/urzadzenia-fiskalne/interkonektor';
    window.open(url, '_blank');
  }
}
