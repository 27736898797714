import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { DoubleClickResult, SingleRecordMode } from 'src/app/event.model';
import { HelpService } from 'src/app/help-service.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-role-customer',
    templateUrl: './role-customer.component.html',
    styleUrls: ['./role-customer.component.scss'],
    inputs: ['dropDownBoxMode', 'selectedId', 'readOnly'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RoleCustomerComponent implements OnInit {
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass;

  helpService = inject(HelpService);
  readOnly;
  isContextMenuMobile = false;
  selectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  documentListPom = [];

  isGridBoxOpened: boolean = false;
  documentList;

  dataSource;

  isDeleteRow: boolean = false;

  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 200;

  myEventEdit;
  myEventChoose;
  myEventEsc;
  myEventShow;
  myEventDelete;
  unicalGuid;
  contextMenu;
  widthCityColumn = '250';
  focusedSelected = [];
  doubleClickResult: DoubleClickResult = 'Edycja';

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
    });

    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.getDate();

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }

    this.myEventEdit = this.event.edit.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.selectedRow.length > 0) this.setSingleRecordVisible('edit');
      }
    });

    this.myEventDelete = this.event.delete.subscribe(() => {
      this.isDelete();
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.gridClass.instance.getSelectedRowsData().length > 0)
          this.onChoosingRow();
      }
    });

    this.contextMenu = [
      { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
      { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
      { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    ];

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) this.setSingleRecordVisible('add');
        break;
      case 1:
        if (!this.readOnly) this.setSingleRecordVisible('edit');
        break;
      case 2:
        if (!this.readOnly) this.isDelete();
        break;
    }
  };

  ngOnChanges() {
    if (this.selectedId && this.selectedId != '') {
      this.appService
        .getAuth(`services/roles?roleId=${this.selectedId}`)
        .subscribe((res) => {
          this.documentList = res.data;
          this.choosingDocument = [Number(this.selectedId)];
          this.cd.detectChanges();
        });
    } else {
      this.documentList = [];
      this.choosingDocument = [];
    }
  }

  getDate = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RoleId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}services/roles`,
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}services/roles`,
      }),
      reshapeOnPush: true,
    });
  };

  ngOnDestroy() {
    this.myEventEdit.unsubscribe();
    this.myEventDelete.unsubscribe();
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            document.getElementById(`btn-add${this.unicalGuid}`).click();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
        },
        preventDefault: true,
      }
    );
  }

  onClosingForm = () => {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  onInserted = () => {
    this.dataSource.reload();
    this.singleRecordMode = null;
  };

  onFocusedRowChanged = (e) => {
    this.selectedRow = [e.row.data.RoleId];
    this.focusedSelected = [e.row.data];
  };

  onChoosingRow = () => {
    if (!this.readOnly) {
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      this.onChoosed.emit(this.documentList[0]);
      this.isGridBoxOpened = false;
    }
  };

  onOpenedChanged = (e) => {
    setTimeout(() => {
      this.event.disabletShortcuts.next(e);
    }, 0);

    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onSelectionChanged = (e) => {
    this.documentListPom = [];
    this.documentListPom.push(e.selectedRowsData[0]);
  };

  isDelete = () => {
    if (
      this.selectedRow.length > 0 &&
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) this.gridClass.instance.focus();
    }, 500);
  }

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) {
      if (this.doubleClickResult === 'Edycja') {
        this.setSingleRecordVisible('edit');
      } else {
        this.setSingleRecordVisible('show');
      }
    } else this.onChoosingRow();
  };

  delete = () => {
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(this.selectedRow[0])
    );
    if (this.choosingDocument == this.selectedRow[0])
      this.choosingDocument = [];
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.focusedRowIndex = 0;
        this.gridClass.instance.focus();
      }
    }, 500);
  };

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  editRecord(data) {
    if (data) this.focusedSelected = [data];
    if (
      !this.readOnly &&
      !this.isSingleRecordVisible &&
      this.focusedSelected.length > 0 &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    )
      this.setSingleRecordVisible('edit');
  }

  isSingleRecordVisible: boolean = false;
  singleRecordMode: SingleRecordMode = null;

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  mobileGridWidth;
  mobileColWidth = {
    name: 160,
    def: 70,
    dots: 40,
  };

  onMobileGridContentReady = (e) => {
    this.mobileGridWidth = e.element.offsetWidth;
    let { def, dots } = this.mobileColWidth;

    this.mobileColWidth.name = this.mobileGridWidth - def - dots;
  };
  focusedRowIndex: number = 0;
}
