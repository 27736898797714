import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { EventService } from '../../event.service';

import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { CustomWindowConfig } from 'src/app/event.model';
import { FkServices } from '../../fk/services/fk-services.service'
import { SingleRecordMode } from 'src/app/event.model';

@Component({
    selector: 'app-vrhicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.scss'],
    inputs: [
        'isPopupMode',
        'isVisible',
        'readOnly',
        'isSimpleView',
        'isChoose'
    ],
    standalone: false
})
export class VehiclesComponent implements OnInit {
  @ViewChild('treeView') treeView;
  @ViewChild('gridClass') gridClass;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @Output() onClosing = new EventEmitter();

  @ViewChild('leftPanel') leftPanel;
  //@ViewChild('rightPanel') rightPanel;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('markFilterVC') markFilterVC;
  @ViewChild('yearFilterVC') yearFilterVC;
  @ViewChild('useFilterVC') useFilterVC;


  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'documentName',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 215,
    },
    mainPanelWidth: '100%',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 385,
    },
    windowSize: {
      isFullscreen: false,
      width: 0,
      height: 0,
    },
    params: [],
  };

  unicalGuid;
  isDeleteSelected: boolean = false;
  focusedRowIndex;
  lastTreeSelection = [
    {
      DocumentCode: '',
      DocumentId: 1,
      DocumentName: '',
      DocumentParentId: null,
      Documents: null,
      PositionId: null,
      expanded: true,
      items: null,
      selected: true,
    },
  ];

  contextObjectId;
  contextTypeSignature: string;
  tableName: string;
  permission;
  parentDocumentName;
  isSimpleView = false;
  isChoose = false;
  windowWidth;

  DocumentCode;
  documentNumberDescription: string;
  DocumentId: number;
  isPopupMode: boolean = false;
  isVisible;
  selectionModes: string[] = ['multiple', 'single'];
  selectionMode: string = this.selectionModes[0];
  selectNodesRecursive = true;
  readOnly;
  dataSourceHistory;
  focusedHistoryRowIndex;

  selectedtabIndex = 0;
  positionId = 14;
  formAddMode: SingleRecordMode = null;

  sizeLimit = 20971520;
  headers = {
    Authorization: 'Bearer ' + localStorage.getItem('WAPRO-HTML-token'),
  };
  uploadUrl;
  isUploud: boolean = false;
  dataSource;

  heightGrid: number = 200;
  selectedRows = [];
  focusedSelected = [];

  isDeleteRow: boolean = false;
  myEventDelete;
  shortcuts: ShortcutInput[] = [];

  detalisFile = {
    ContextObjectDescription: '',
    ParentDocumentName: '',
  };

  documentTypeId;
  readOnlyDetalis: boolean = true;
  isVisibleAddVehicle: boolean = false;

  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    {
      text: 'Zaznacz wszystko',
      icon: 'icon absui-icon--checkbox-all',
      itemIndex: 1,
    },
    {
      text: 'Odznacz wszystko',
      icon: 'icon absui-icon--deselct-all',
      itemIndex: 2,
    },
    { text: 'Usuń zaznaczone', itemIndex: 3 },
   
  ];
  openCustomDropDown: boolean = false;
  onlySelected = false;
  filterCriteria: ICustomSearchItem[] = [
    { value: 'NumerRej', label: 'Numer rejestracyjny' },
  ];
  valueCriteria = 'NumerRej';
  markFilter;
  filterMarkList = [
    { id: 1, label: 'Seat Leon' }
  ];
  yearFilter;
  filterYearList = [
    { id: 1, label: '2009' }
  ];
  useFilter;
  filterUseList = [
    { id: 1, label: 'Osobowy prywatny' }
  ];

  filterValue = '';
  paramsObj;
  isAllSelected: boolean;
  skipSendSelectionEvent: boolean;
  isDocumentIdFilterActive: boolean;
  contextFilter: string = 'Primitive';
  singleDocumentFilter: {
    documentId: number;
    documentNumber: string;
  } = {
    documentId: null,
    documentNumber: '',
  };
  componentHeight;
  progressMsg: string = 'Przesyłanie załączników';
  textColor = ['', '#741111', '#0B3A83', '#164C1A', '#733200'];
  backgroundColor = ['', '#FFCDD2', '#BBDEFB', '#C8E6C9', '#FFECB3'];

  constructor(
   // private appService: AppServices,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public fkServices: FkServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
  }

  ngOnInit(): void { }



  ngOnChanges(changes: SimpleChanges): void {
    changes=changes;
    
  }

  ngAfterViewInit(): void {
    if(!this.isPopupMode) {
      this.componentHeight = window.innerHeight;
    } else {
      this.componentHeight = 900;
    }
    if(!this.isSimpleView) {
      this.windowWidth = 1440;
    } else {
      this.windowWidth = 900;
    }
    this.heightGrid = this.componentHeight - 215;
    this.getData();
    this.resetType();
  }

  changeSimpleView(): void {
    this.isSimpleView = false;
    this.windowWidth = 1440;
  }

  ngOnDestroy(): void {
  }

  chooseVehicles(): void {

  }

  contextMenuClick(e): void {
    e=e;
  }

  clearFilters(): void {
    this.filterValue = null;
    this.markFilterVC.clearValue();
    this.yearFilterVC.clearValue();
    this.useFilterVC.clearValue();
  }

  closeConfirm(): void {

  }

  deleteSelected(): void {

  }

  onDeselectAll(): void {

  }

  onOnlySelected(): void {

  }

  addBtn(): void {
    this.formAddMode = 'add';
    this.isVisibleAddVehicle = true;
  }

  isEdit(): void {
    
  }

  delete(): void {

  }

  onPopupClose(): void {
    this.onClosing.emit();
  }

  onPopupShown(): void {

  }

  visibleChangePopUp(e): void {
    e=e;
  }

  treeViewSelectionChanged(e): void {
    e=e;
  }

  cancelUpdateDetalis(): void {

  }

  onUpdate(): void {

  }

  onSelectionChanged(): void {

  }

  isDelete(): void {

  }

  onOptionChanged(): void {

  }

  onKeyDown(e): void {
    e=e
  }

  onRowClick(e): void {
    e=e;
  }

  onContentReady(e): void {
    e=e;
  }

  onFocusedRowChanged(e): void {
    e=e;
  }

  onInitialized(e): void {
    e=e;
  }

  resetSingleDocumentFilter(): void {

  }

  goToFilteredDocument(): void {

  }

  onFilterDataChanged(e): void {
    e=e;
  }

  refreshData(): void {

  }

  valueChange(): void {
    
  }

  getData(): void {
    this.dataSource = this.fkServices.mockVehicles;
    this.dataSourceHistory = this.fkServices.mockVehiclesHistory;
  }

  resetType(): void {
    this.treeView?.instance?.selectItem(1);
  };

  // bottom panel

  heightBottomPanel;
  showBottomPanel: boolean = false;
  heightBottomGrid = 180;

  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizeEndBottom(): void {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  resizingBottom(e): void {
    let height;
    this.showBottomPanel = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '230px';
    if (height < 100) {
      height = 100;
    }

    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 25;

    this.heightGrid = this.componentHeight - height - 215;
    this.localStorageData.bottomPanel.height = `${height}px`;
  };

  mouseEnterDown(): void {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseLeaveDown(): void {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  timeoutHandler;

  mouseup(): void {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown(): void {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange(): void {
    this.showBottomPanel = !this.showBottomPanel;
    if (
      this.showBottomPanel &&
      this.localStorageData.bottomPanel.height == null
    ) {
      this.bottomSidePanel.nativeElement.style.minHeight = `135px`;
      this.heightGrid = this.componentHeight - 435;
      this.tabPanel.height = '100%';
      this.heightBottomGrid = 215;
    } else if (
      this.showBottomPanel &&
      this.localStorageData.bottomPanel.height != null
    ) {
      try {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanel.height;

        let height;
        if (typeof this.localStorageData.bottomPanel.height == 'number') {
          height = this.localStorageData.bottomPanel.height;
        }
        if (typeof this.localStorageData.bottomPanel.height == 'string') {
          height = Number(
            this.localStorageData.bottomPanel.height.replace('px', '')
          );
        }
        this.heightGrid = this.componentHeight - height - 215;
        this.tabPanel.height = '100%';
        this.heightBottomGrid = height - 25;
      } catch {}
    } else {
      this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
      this.tabPanel.height = '34px';
      this.bottomSidePanel.nativeElement.style.height = `34px`;
      this.heightGrid = this.componentHeight - 215;
    }

    this.localStorageData.bottomPanel.isVisible = this.showBottomPanel;
  }

  //-----------------------------------------------------------------------

}
