<dx-popup
  [(width)]="localStorageData.windowSize.width"
  [height]="event.deviceType != 'mobile' ? localStorageData.windowSize.height : mobileHeight"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="275"
  [maxHeight]="maxHeight"
  (onShown)="
    event.onShownPopUp(); loadChildComponent = true; setFocus()
  "
  (onHidden)="event.onHiddenPopUp(); onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <div *ngIf="event.deviceType != 'mobile'"
           style="display: inline-block">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
    </div>
    <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
      <ng-container *ngTemplateOutlet="desktop"></ng-container>
    </div>
  </div>
</dx-popup>

<ng-template #desktop>
  <form [formGroup]="form">
    <div>
      <dx-scroll-view width="100%"
                      height="100%"
                      direction="vertical">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right" style="width: 400px">
<!--            <div class="c-frame-row" style="width: 100%">-->
<!--              <h5 style="padding-left: 10px">-->
<!--                {{-->
<!--                  "sadDocuments.headerDate.positionIncome.basicInformation"-->
<!--                    | translate-->
<!--                }}-->
<!--              </h5>-->
<!--            </div>-->

            <div>
              <label style="width: 110px"
              >{{ "warehouse.grid.nameMobile" | translate }}
                <strong>*</strong></label
              >
              <dx-text-box
                #nameTextBox
                width="276"
                [readOnly]="readOnly"
                formControlName="Name"
                [ngClass]="{
                    'input-required': form.controls.Name.invalid && submitted
                  }"
              >
              </dx-text-box>
            </div>

            <div>
              <label style="width: 110px; top: -2px"
              >{{ "intrastate-documents.date" | translate }}
                <strong>*</strong></label
              >
              <wapro-date-box
                [readOnly]="readOnly"
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                formControlName="RouteDate"
              >
              </wapro-date-box>
            </div>

            <div>
              <label style="width: 110px">{{
                  "articles.description200" | translate
                }}</label>
              <dx-text-area
                class="route_text-area"
                [maxLength]="200"
                [elementAttr]="{ class: 'no-max-width' }"
                formControlName="Description"
                width="276"
              ></dx-text-area>
            </div>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </form>
</ng-template>

<ng-template #mobileTemplate>
  <form [formGroup]="form">
    <div>
      <dx-scroll-view width="100%"
                      height="100%"
                      direction="vertical">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right">
            <div>
              <label style="width: 110px"
              >{{ "warehouse.grid.nameMobile" | translate }}
                <strong>*</strong></label
              >
              <dx-text-box
                #nameTextBox
                width="276"
                [readOnly]="readOnly"
                formControlName="Name"
                [ngClass]="{
                    'input-required': form.controls.Name.invalid && submitted
                  }"
              >
              </dx-text-box>
            </div>

            <div>
              <label style="width: 110px; top: -2px"
              >{{ "intrastate-documents.date" | translate }}
                <strong>*</strong></label
              >
              <wapro-date-box
                [readOnly]="readOnly"
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                formControlName="RouteDate"
              >
              </wapro-date-box>
            </div>

            <div>
              <label style="width: 110px">{{
                  "articles.description200" | translate
                }}</label>
              <dx-text-area
                class="route_text-area"
                [maxLength]="200"
                [elementAttr]="{ class: 'no-max-width' }"
                formControlName="Description"
                width="276"
              ></dx-text-area>
            </div>
          </div>

        </div>

      </dx-scroll-view>
    </div>
  </form>
  <div>
    <dx-button
      style="position: absolute;
    bottom: 20px;
    left: 20px;
z-index: 100"
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()">
    </dx-button>
  </div>

  <div class="popup-footer">
    <ng-container *ngTemplateOutlet="topButtonsMobile"></ng-container>
  </div>

  <app-mobile-list
    [dataSource]="contextMenuOperationsMobile"
    [visible]="mobileOperationsVisible"
    (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
    (onItemClick)="contextMenuClick($event)">
  </app-mobile-list>
</ng-template>



<div *ngIf="loadChildComponent">
  <app-form-user-extensions
    title="{{ 'categories.externalFields' | translate }}"
    [isVisible]="visibleUserExtensions"
    groupCode="MOB_TRASA"
    [readOnly]="readOnly"
    (onChoosed)="onInsertedUserExtensions($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="form.value"
  >
  </app-form-user-extensions>
</div>

<ng-template #topButtons>
  <div class="d-inline-block">

    <dx-button
      class="outline-secondary"
      stylingMode="outlined"
      text="{{ 'categories.externalFields' | translate }}"
      style="margin-right: 6px"
      (onClick)="visibleUserExtensions = true"
      [id]="'ext-fields' + unicalGuid"
    >
    </dx-button>

    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 6px"
      (onClick)="onSaveRoute()"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
    >
    </dx-button>

    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelRoute()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>


  <dx-tooltip
    [target]="'#ext-fields' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "categories.externalFields" | translate }} (Ctrl+P)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#add' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#fitToWidth' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "servicesDevices.fitToWidth" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #topButtonsMobile>
  <div class="d-inline-block">

    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 6px"
      (onClick)="onSaveRouteMobile(true)"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
    >
    </dx-button>

    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelRoute()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>


  <dx-tooltip
    [target]="'#ext-fields' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "categories.externalFields" | translate }} (Ctrl+P)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#add' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#fitToWidth' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "servicesDevices.fitToWidth" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>


<app-confirm-dialog
  kindDialog="question"
  confirmText="routes.crmRouteConfirm"
  [isVisible]="isCrmRouteConfirmVisible"
  (onRemoving)="addCRMRouteWithoutContractors()"
  (onClosing)="isCrmRouteConfirmVisible = false"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || visibleUserExtensions"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>


