<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
m  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="save-delivery-btn"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClose()"
        [id]="'cancel' + unicalGuid"
        data-cy="cancel-delivery-btn"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 570px">

          <label style="width: 150px">Marka</label>
          <dx-text-box 
              width="356"
              [readOnly]="readOnly"
          ></dx-text-box>
          <br />

          <label style="width: 150px">Model</label>
          <dx-text-box 
              width="356"
              [readOnly]="readOnly"
          ></dx-text-box>
          <br />

          <label style="width: 150px">Nr rejestracyjny<strong>*</strong></label>
          <dx-text-box 
              width="356"
              [readOnly]="readOnly"
          ></dx-text-box>
          <br />

          <label style="width: 150px">Rok produkcji<strong>*</strong></label>
          <dx-text-box 
              width="356"
              [readOnly]="readOnly"
          ></dx-text-box>
          <br />

          <label style="width: 150px">Uwagi</label>
          <dx-text-area
            [height]="90"
            [width]="356"
            [readOnly]="readOnly"
          >
          </dx-text-area>
          <br /><br />

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'addgr' + unicalGuid"
      type="default"
      stylingMode="text"
      [disabled]="readOnly"
      (click)="addBtn()"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      [id]="'editgr' + unicalGuid"
      icon="icon absui-icon--mode-edit"
      type="default"
      stylingMode="text"
      [disabled]="true"
      data-cy="email-signature-edit"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--highlight-off"
      type="default"  
      stylingMode="text"
      [id]="'deletegr' + unicalGuid"
      [disabled]="true"
      data-cy="email-signature-delete"
    >
    </dx-button>

    <dx-tooltip
      [target]="'#addgr' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#editgr' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#deletegr' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-data-grid
      [dataSource]="dataSource"
      [wordWrapEnabled]="true"
      [showBorders]="true"
      shortcut
      [height]="130"
      [columnResizingMode]="'widget'"
      [remoteOperations]="true"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      [id]="'grid' + unicalGuid"
      [focusedRowEnabled]="true"
      #gridClass
      [hoverStateEnabled]="true"
      data-cy="email-signature-grid"
    >
      <dxo-load-panel [enabled]="false"></dxo-load-panel>
      <dxo-scrolling mode="infinite"></dxo-scrolling>
      <dxo-paging [pageSize]="50"></dxo-paging>
      <dxo-selection [mode]="'single'" showCheckBoxesMode="always">
      </dxo-selection>

      <dxi-column
        caption="Data zmiany"
        dataField="Name"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        caption="Sposób wykorzystania"
        dataField="Name"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        caption="Wartość leasing"
        dataField="Name"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        caption="Wartość ubezpiecz."
        dataField="Name"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        caption="Odlicz. VAT"
        dataField="Name"
        [allowSorting]="false"
      ></dxi-column>
    </dx-data-grid>


        </div>
      </div>
    
    </dx-scroll-view>
  </div>

  <wapro-keyboard-shortcuts
    [unicalGuid]="unicalGuid"
    [shortcuts]="shortcuts"
    [disabled]="!isVisible"
  ></wapro-keyboard-shortcuts>
</dx-popup>

<app-add-vehicles-changes
  [title]="'Dodawanie zmian'"
  [isVisible]="isVisibleAddVehicle"
  [mode]="formAddMode"
  (onClosed)="isVisibleAddVehicle = false"
>
</app-add-vehicles-changes>