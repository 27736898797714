import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { EventService } from '../../../event.service';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-histrory-status',
    templateUrl: './histrory-status.component.html',
    styleUrls: ['./histrory-status.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'title',
        'invoicesDocument',
        'warehouseDocument',
    ],
    standalone: false
})
export class HistroryStatusComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  unicalGuid;
  isVisible;
  selectedId;
  title;
  invoicesDocument;
  warehouseDocument;
  dataSource;
  shortcuts: ShortcutInput[] = [];

  widthWindow = 650;
  heightWindow = 350;

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  getDate = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceOrderStatusHistoryId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}services/orders/${this.selectedId[0]}/statusHistory`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  getDateInvoices = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'DocumentStatusHistoryId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${this.selectedId[0].InvoiceDocumentId}/statusHistory`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  getDateWarehouse = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'DocumentStatusHistoryId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}WarehouseDocuments/documents/${this.selectedId.WarehouseDocumentId}/statusHistory?ObjectId=${this.selectedId.WarehouseDocumentId}`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  visibleChange = (e) => {
    if (e) {
      !this.invoicesDocument
        ? !this.warehouseDocument
          ? this.getDate()
          : this.getDateWarehouse()
        : this.getDateInvoices();
    }
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}
