<div *ngIf="!dropDownBoxMode; else dropDownMode">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <div *ngIf="event.deviceType != 'mobile'">
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </div>
</div>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="choosingCode"
    valueExpr="Code"
    [deferRendering]="false"
    displayExpr="Code"
    [(opened)]="isGridBoxOpened"
    [dataSource]="codeList"
    [showClearButton]="true"
    style="width: 276px"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="onOpenedChanged($event)"
    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
    [ngClass]="{ 'input-required': className == true }"
    [readOnly]="readOnly"
  >
    <div *dxTemplate="let data of 'content'" style="height: 280px">
      <div class="row">
        <div class="col-md-12" class="no-padding" *ngIf="isGridBoxOpened">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="258"
            [(selectedRowKeys)]="selectedRow"
            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            (onSelectionChanged)="onSelectionChanged($event)"
            [focusedRowEnabled]="true"
            #gridClass
            [hoverStateEnabled]="true"
            (onKeyDown)="onKeyDown($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              [mode]="dropDownBoxMode ? 'single' : 'multiple'"
              showCheckBoxesMode="always"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              [width]="event.deviceType != 'mobile' ? 45 : 10"
              cellTemplate="editTemplate"
              [allowSorting]="false"
              alignment="center"
              renderAsync="true"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
              <dx-button
                class="btn-dx-top-menu-folder-grid"
                icon="icon absui-icon--mode-edit hover-edit-icon"
                [disabled]="readOnly"
                (onClick)="editRow()"
              >
              </dx-button>
            </div>
            <dxi-column
              caption="{{ 'bankBox.grid.columns.postalCode' | translate }}"
              width="80"
              dataField="Code"
              [allowSorting]="false"
              renderAsync="true"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'companies.grid.columns.name' | translate }}"
              dataField="Name"
              [allowSorting]="false"
            ></dxi-column>
          </dx-data-grid>
        </div>
      </div>
      <div class="bottom-drop-box">
        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--add-circle"
          [id]="'btn-drop-add' + unicalGuid"
          [text]="'buttons.add' | translate"
          [disabled]="readOnly"
          (onClick)="addNewRow()"
        >
        </dx-button>

        <dx-tooltip
          [target]="'#btn-drop-add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }}
          </div>
        </dx-tooltip>
      </div>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="95%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
  >
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      id="btn-add-customers-clasyfication"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly"
      (onClick)="addNewRow()"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      id="btn-edit-customers-clasyfication"
      icon="icon absui-icon--mode-edit"
      [text]="'buttons.edit' | translate"
      [disabled]="focusedSelected.length == 0 || readOnly"
      (onClick)="editRow()"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--highlight-off"
      [text]="'buttons.delete' | translate"
      id="btn-delete-customers-clasyfication"
      [disabled]="focusedSelected.length == 0 || readOnly"
      (onClick)="isDelete()"
    >
    </dx-button>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <app-custom-dropdown-box
        [filterValue]="code"
        (onValueChanged)="onValueChangeSearch($event)"
        [hideFilter]="true"
        style="margin-right: 5px"
        *ngIf="!dropDownBoxMode"
      >
      </app-custom-dropdown-box>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <dx-data-grid
        id="gridContainer"
        #gridClass
        [dataSource]="dataSource"
        [remoteOperations]="true"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        shortcut
        [height]="heightGrid"
        [(selectedRowKeys)]="selectedRow"
        (onSelectionChanged)="onSelectionChanged($event)"
        [focusedRowEnabled]="true"
        [(focusedRowIndex)]="focusedRowIndex"
        (onRowDblClick)="onRowDblClick()"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        [id]="'grid' + unicalGuid"
        (onKeyDown)="onKeyDown($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

        <dxi-column
          caption="{{ 'bankBox.grid.columns.postalCode' | translate }}"
          width="80"
          dataField="Code"
          [allowSorting]="false"
          cellTemplate="mainColumn"
          renderAsync="true"
        >
        </dxi-column>

        <dxi-column
          caption="{{ 'companies.grid.columns.name' | translate }}"
          dataField="Name"
          [allowSorting]="false"
          renderAsync="true"
        >
        </dxi-column>

        <div *dxTemplate="let data of 'mainColumn'">
          <span class="hov-underline" (click)="editRecord($event)">{{
            data.value
          }}</span>
        </div>
      </dx-data-grid>
    </div>
  </div>
</ng-template>

<dx-context-menu
  [dataSource]="contextMenu"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
></dx-context-menu>

<app-add-finance-operation
  [isVisible]="addRow"
  [mode]="mode"
  title="{{ 'form-commercial-operation.financialOperation' | translate }}"
  (onClosing)="onClosing()"
  (onInserted)="onInserted()"
  [selected]="focusedSelected"
></app-add-finance-operation>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="onClosing()"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="addRow || dropDownBoxMode"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
