import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { uuidv4 } from 'src/app/libraries/photos/photo';
import { DeliveryAddressType } from '../delivery-address.model';
import { CountryBoxComponent } from 'src/app/libraries/country-box/country-box.component';

@Component({
    selector: 'app-add-delivery-address',
    templateUrl: './add-delivery-address.component.html',
    styleUrls: ['./add-delivery-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddDeliveryAddressComponent implements OnInit {
  @Input() operatorCode: string = null;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('countryBox') countryBox: CountryBoxComponent;

  @Input() type: DeliveryAddressType = 'nadanie';
  @Input() isVisible = false;
  @Input() selectedId: number = null;
  @Input() readOnly = false;

  widthWindow = 650;
  heightWindow = 550;
  currentCompany: any = {};
  currentCountryCode = '';
  shortcuts: ShortcutInput[] = [];

  form: FormGroup;
  submitted: boolean = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    if (window.innerWidth < 1200) {
      this.widthWindow = 300;
      this.heightWindow = 260;
    }

    this.getRadioTranslations();

    this.createForm();
    this.fetchCompanyAddress();
    this.getCurrentCompany();
  }

  getRadioTranslations() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible?.currentValue) {
      if (!this.selectedId) {
        this.createForm();
      } else {
        this.getDeliveryAddress(this.selectedId);
      }
    }
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      DeliveryAddressId: new FormControl(0),
      DeliveryOperatorCode: new FormControl(this.operatorCode),
      CountryCode: new FormControl('PL'),
      CompanyName: new FormControl('', { validators: [Validators.required] }),
      Street: new FormControl('', { validators: [Validators.required] }),
      BuildingNumer: new FormControl('', { validators: [Validators.required] }),
      LocalNumber: new FormControl(''),
      PostalCode: new FormControl('', { validators: [Validators.required] }),
      City: new FormControl('', { validators: [Validators.required] }),
      Name: new FormControl(''),
      Email: new FormControl(''),
      Phone: new FormControl('', { validators: [Validators.required] }),
      IsReturnType: new FormControl(this.type === 'zwrot'),
      Guid: new FormControl(uuidv4()),
      RodoDate: new Date(),
      IsAnonimized: new FormControl(false),
      WarehouseId: new FormControl(0),
      WarehouseName: new FormControl(''),
      WarehouseSymbol: new FormControl(''),
      IsDefault: new FormControl(false),
    });
  };

  getDeliveryAddress = (id) => {
    this.appService
      .getAuth(
        `deliveries/operators/${this.operatorCode}/addresses?deliveryAddressId=${id}`
      )
      .subscribe((res) => {
        let matchingAddressData = res.data.find(
          (obj) => obj.DeliveryAddressId === id
        );
        this.form.patchValue(matchingAddressData);
        if (matchingAddressData.WarehouseId != 0) {
          this.defaultValue = 2;
        } else if (matchingAddressData.IsDefault) {
          this.defaultValue = 1;
        } else {
          this.defaultValue = 0;
        }
      });
  };

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;

    if (!e) {
      this.event.disabletShortcuts.next(true);
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (this.form.value.DeliveryAddressId == 0) {
        this.appService
          .postAuth(
            `deliveries/operators/${this.operatorCode}/addresses`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.form.controls['DeliveryAddressId'].setValue(
                res.DeliveryAddressId
              );
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              var errorDetail = JSON.parse(error);
              if (errorDetail.detail && errorDetail.detail !== '') {
                this.event.showNotification('error', errorDetail.detail);
              } else {
                this.event.showNotification('error', errorDetail.message);
              }
            }
          );
      } else {
        this.appService
          .putAuth(
            `deliveries/operators/${this.operatorCode}/addresses/${this.form.value.DeliveryAddressId}`,
            this.form.value
          )
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              var errorDetail = JSON.parse(error);
              if (errorDetail.detail && errorDetail.detail !== '') {
                this.event.showNotification('error', errorDetail.detail);
              } else {
                this.event.showNotification('error', errorDetail.message);
              }
            }
          );
      }
    }
  };

  getCurrentCompany() {
    this.currentCompany = {
      CompanyId: this.event.footerInfo.CompanyId,
      CompanyName: this.event.footerInfo.CompanyName,
      CompanyVat: this.event.footerInfo.CompanyVat,
    };
  }

  selectedCountryChange = (e) => {
    if (e) {
      this.form.controls.CountryCode.setValue(e[0].CountryCode);
    } else if (e === null) {
      this.form.controls.CountryCode.setValue('');
    }
  };

  typeItems: any[] = [
    { value: false, label: 'nadanie' },
    { value: true, label: 'zwrot' },
  ];

  defaultItems: any[] = [
    { value: 0, label: 'brak' },
    { value: 1, label: 'domyślny' },
    { value: 2, label: 'domyślny w magazynie' },
  ];

  defaultValue: number = 0;

  onDefaultValueChanged(e) {
    this.form.controls.WarehouseId.setValue(0);
    this.form.controls.WarehouseName.setValue('');
    this.form.controls.WarehouseSymbol.setValue('');
    this.form.controls.IsDefault.setValue(!!e.value);
  }

  onWarehouseChoosed(e) {
    if (!e) return;
    this.form.controls.WarehouseId.setValue(e.WarehouseId);
    this.form.controls.WarehouseName.setValue(e.WarehouseName);
    this.form.controls.WarehouseSymbol.setValue(e.WarehouseSymbol);
  }

  companyData = {
    AddressId: null,
    CompanyId: null,
    CompanyName: '',
    Postal: '',
    Community: '',
    BuildingNumber: '',
    LocaleNumber: '',
    Phone: '',
    Email: '',
    DateFrom: '',
    CountryCode: '',
    Province: '',
    District: '',
    City: '',
    PostalCode: '',
    Street: '',
  };

  fetchCompanyAddress() {
    this.appService.getAuth('companies/addresses?skip=0&take=1').subscribe(
      (res) => {
        if (res.data.length) {
          this.companyData = res.data[0];
        }
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  }

  getCompanyAddress() {
    if (this.companyData.AddressId != null) {
      this.form.controls.CountryCode.setValue(this.companyData.CountryCode);
      this.form.controls.CompanyName.setValue(
        this.event.footerInfo.CompanyName
      );
      this.form.controls.Street.setValue(this.companyData.Street);
      this.form.controls.BuildingNumer.setValue(
        this.companyData.BuildingNumber
      );
      this.form.controls.LocalNumber.setValue(this.companyData.LocaleNumber);
      this.form.controls.PostalCode.setValue(this.companyData.PostalCode);
      this.form.controls.City.setValue(this.companyData.City);
      this.currentCountryCode = this.companyData.CountryCode;
    }
  }
}
