<ng-container *ngIf="componentVisible">
  <ng-container *ngIf="!isPopupMode; else popupTemplate">
    <ng-container *ngTemplateOutlet="header"></ng-container>

    <div class="top-menu-panel">
      <div *ngTemplateOutlet="topButtons"></div>
    </div>
    <div
      class="center-panel-scroll-verticall"
    >
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </ng-container>

  <ng-template #popupTemplate>
    <dx-popup
      [animation]="{
        show: { type: 'slideIn', direction: 'bottom' },
        hide: { type: 'slideOut', direction: 'bottom' }
      }"
      [showTitle]="true"
      [title]="'menu.fiscalPrinters' | translate"
      [(visible)]="isVisible"
      [showCloseButton]="false"
      [minHeight]="400"
      [minWidth]="600"
      [width]="600"
      [height]="400"
      (onShown)="event.onShownPopUp()"
      (onHidden)="event.onHiddenPopUp()"
      [wrapperAttr]="{ id: unicalGuid }"
      [resizeEnabled]="true"
      (onResizeEnd)="onPopupResizeEnd()"
      [dragEnabled]="true"
      #popup
    >
      <dxo-position my="center" at="center" of="window"> </dxo-position>

      <div *dxTemplate="let content of 'content'">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="pb-3">
          <div *ngTemplateOutlet="topButtons"></div>
        </div>
        <div class="grid-container" *ngTemplateOutlet="grid"></div>
      </div>
    </dx-popup>
  </ng-template>

  <ng-template #header>
    <div class="text-right title-pop-up">
      <!-- Buttons -->
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="onConfirm()"
        [id]="'send' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClose()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <!-- Buttons -->
    </div>
  </ng-template>

  <ng-template #topButtons>
    <div class="top-buttons-container"
    style="display: flex; align-items: center">
      <app-wapro-selected-box
        [(ngModel)]="selectedFiscalPrinters"
        [selectedAll]="isAllSelected"
        *ngIf="loadChildComponent"
      ></app-wapro-selected-box>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        (onClick)="showSingleFiscalPrinter('add')"
        [id]="'btn-add' + unicalGuid"
        [disabled]="loading"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--mode-edit"
        [id]="'btn-editPosition' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="!focusedFiscalPrinterId || loading"
        stylingMode="text"
        type="default"
        (onClick)="showSingleFiscalPrinter('edit')"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="!focusedFiscalPrinterId || loading"
        stylingMode="text"
        type="default"
        (onClick)="deleteFiscalPrinter()"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--check-circle"
        type="default"
        stylingMode="text"
        [text]="'fiscalPrinter.setDefaultPrinter' | translate"
        *ngIf="
          (focusedFiscalPrinterId && !loading) ||
          (focusedFiscalPrinterId && !noInterconnectorResponse)
        "
        [id]="'btn-set-default' + unicalGuid"
        (onClick)="setDefaultPrinter(focusedFiscalPrinterId)"
      >
      </dx-button>

      <div
        style="display: flex; align-items: center; gap: 6px; margin-left: auto"
        [style.marginRight.px]="event.deviceType === 'mobile' ? 0 : 6"
      >
        <div
          class="form-item"
          style="display: inline-flex; gap: 8px"
          *ngIf="noInterconnectorResponse"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--help"
            stylingMode="text"
            type="default"
            (click)="isSetupWizardVisible = true"
            [id]="'btn-help' + unicalGuid"
          ></dx-button>
          <label>{{ "externalDevices.computerName" | translate }}</label>
          <dx-text-box
            [disabled]="false"
            [value]="computerName"
            width="220"
            (onValueChanged)="changeComputerName($event)"
            [placeholder]="
              'externalDevices.ComputerNamePlaceholder' | translate
            "
          >
          </dx-text-box>
        </div>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          stylingMode="text"
          type="default"
          (click)="refresh()"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>
      </div>

      <!-- Tooltips -->
      <dx-tooltip
        [target]="'#btn-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-editPosition' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.edit" | translate }} (F2)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Esc)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-set-default' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "fiscalPrinter.setDefaultPrinter" | translate }}
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>
      <!-- / Tooltips -->
    </div>
  </ng-template>

  <ng-template #grid>
    <div class="row">
      <div class="col-md-12">
        <div #gridContainer>
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [(selectedRowKeys)]="selectedFiscalPrinters"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            [autoNavigateToFocusedRow]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            [id]="'grid' + unicalGuid"
            (onContentReady)="onGridReady()"
            [allowColumnReordering]="true"
            [(focusedRowKey)]="focusedFiscalPrinterId"
            [(focusedRowIndex)]="focusedFiscalPrinterIndex"
            keyExpr="FiscalPrinterId"
            [focusedRowEnabled]="true"
            #fiscalPrintersGrid
            [width]="'100%'"
            [hoverStateEnabled]="true"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-sorting mode="none"> </dxo-sorting>
            <dxo-scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
            <dxo-selection
              [mode]="'multiple'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxi-column
              dataField="IsDefault"
              [width]="40"
              caption="D"
              cellTemplate="isDefaultTemplate"
              headerCellTemplate="isDefaultHeaderTemplate"
            ></dxi-column>
            <div *dxTemplate="let cell of 'isDefaultHeaderTemplate'">
              <div class="text-center">D</div>
            </div>
            <div *dxTemplate="let cell of 'isDefaultTemplate'">
              <div
                class="d-flex align-items-center justify-content-center text-center"
              >
                <i
                  class="icon absui-icon--status-ok-green"
                  [id]="'isDefault' + cell.data.FiscalPrinterId"
                  *ngIf="
                    cell.data.FiscalPrinterId ===
                    printConfig?.defaultFiscalPrinter?.FiscalPrinterId
                  "
                ></i>
                <i
                  class="icon icon-circle-blank"
                  [id]="'setDefault' + cell.data.FiscalPrinterId"
                  *ngIf="
                    cell.data.FiscalPrinterId !==
                    printConfig?.defaultFiscalPrinter?.FiscalPrinterId
                  "
                  (click)="setDefaultPrinter(cell.data.FiscalPrinterId)"
                ></i>
              </div>
              <dx-tooltip
                [target]="'#isDefault' + cell.data.FiscalPrinterId"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="true"
                [showEvent]="event.tooltipShowEvent"
                [hideEvent]="{ name: 'dxhoverend' }"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "fiscalPrinter.isDefaultPrinter" | translate }}
                </div>
              </dx-tooltip>

              <dx-tooltip
                [target]="'#setDefault' + cell.data.FiscalPrinterId"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="true"
                [showEvent]="event.tooltipShowEvent"
                [hideEvent]="{ name: 'dxhoverend' }"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "fiscalPrinter.setDefaultPrinter" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="{{ 'fiscalPrinter.name' | translate }}"
              dataField="Name"
              cellTemplate="mainColumn"
            >
              <div *dxTemplate="let data of 'mainColumn'">
                <span
                  class="hov-underline"
                  (click)="showSingleFiscalPrinter('edit')"
                >
                  {{ data.value }}
                </span>
              </div>
            </dxi-column>
            <dxi-column
              caption="{{ 'fiscalPrinter.type' | translate }}"
              [allowSorting]="false"
              dataField="TypeDescription"
            >
            </dxi-column>
          </dx-data-grid>
          <dx-context-menu
            [dataSource]="contextMenu"
            [width]="200"
            [target]="'#grid' + unicalGuid"
            (onItemClick)="contextMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          >
          </dx-context-menu>
        </div>
      </div>
    </div>
  </ng-template>

  <app-fiscal-printer
    [isVisible]="isSingleFiscalPrinterVisible"
    (onClosingForm)="onClosingForm()"
    (onSaved)="onPrinterSave($event)"
    [fiscalPrinterId]="focusedFiscalPrinterId"
    [mode]="mode"
    [computerName]="computerName"
    *ngIf="isSingleFiscalPrinterVisible"
  >
  </app-fiscal-printer>

  <app-confirm-dialog
    [isVisible]="isDeleteFiscalPrinterVisible"
    (onClosing)="onClosingDeleteFiscalPrinter()"
    (onCanceling)="onCancelingDeleteFiscalPrinter()"
    (onRemoving)="onRemovingFiscalPrinter()"
    [confirmText]="deleteFiscalPrinterConfirmText"
  >
  </app-confirm-dialog>

  <app-setup-wizard
    [isVisible]="isSetupWizardVisible"
    [config]="setupWizardConfig"
    (onClosing)="onSetupWizardClosing()"
    (onContinue)="onSetupWizardContinue()"
    (onLinkClick)="onSetupWizardLinkClick()"
  ></app-setup-wizard>
</ng-container>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    isSingleFiscalPrinterVisible ||
    isSetupWizardVisible ||
    isDeleteFiscalPrinterVisible
  "
>
</wapro-keyboard-shortcuts>
