<dx-popup
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="'fiscalPrinter.connectionConfig.individualSettings' | translate"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="400"
  [height]="400"
  [width]="480"
  [minWidth]="480"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{
        boxShadow: scrollPosition > 0 && event.deviceType != 'mobile'
      }"
    >
      <div class="d-inline-block">
        <dx-button
          text="{{ 'buttons.save' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onSave()"
          [id]="'add' + unicalGuid"
        >
        </dx-button>
        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="onCancel()"
          [id]="'cancel' + unicalGuid"
        >
        </dx-button>
      </div>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'100%'"
      direction="vertical"
      (onScroll)="onScroll($event)"
    >
      <form class="popup-form">
        <div class="c-frame-row w-100">
          <h5>
            {{ "externalDevices.connectionParams" | translate }}
          </h5>
        </div>
        <div class="form-column">
          <ng-container *ngFor="let setting of connectionSettingsDataSource">
            <ng-container [ngSwitch]="setting.type">
              <ng-container *ngSwitchCase="'selectbox'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label>{{ setting.label }}</label>
                  <dx-select-box
                    [dataSource]="setting.items"
                    valueExpr="name"
                    displayExpr="label"
                    [(value)]="settings[setting.name]"
                    (onSelectionChanged)="onSelectionChanged($event)"
                  >
                  </dx-select-box>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'textbox'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label>{{ setting.label }}</label>
                  <dx-text-box
                    [(value)]="settings[setting.name]"
                    [disabled]="setting.disabled"
                  >
                  </dx-text-box>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label></label>
                  <dx-check-box
                    [(value)]="settings[setting.name]"
                    [disabled]="setting.disabled"
                    [text]="setting.label"
                  >
                  </dx-check-box>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'numberbox'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label>{{ setting.label }}</label>
                  <dx-number-box
                    [(value)]="settings[setting.name]"
                    [disabled]="setting.disabled"
                    [min]="setting.min"
                  >
                  </dx-number-box>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'switch'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label>{{ setting.label }}</label>
                  <dx-switch [value]="true"></dx-switch>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <hr />

        <div class="form-column">
          <ng-container *ngFor="let setting of checkboxes">
            <ng-container [ngSwitch]="setting.type">
              <ng-container *ngSwitchCase="'checkbox'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label></label>
                  <dx-check-box
                    [(value)]="settings[setting.name]"
                    [disabled]="setting.disabled"
                    [text]="setting.label"
                  >
                  </dx-check-box>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'switch'">
                <div class="form-item" *ngIf="setting.isVisible">
                  <label>{{ setting.label }}</label>
                  <dx-switch [value]="true"></dx-switch>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>
