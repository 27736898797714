import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { CollectorPosValidatorCfg } from '../data-collector.model';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
    selector: 'app-edit-collector-position',
    templateUrl: './edit-collector-position.component.html',
    styleUrls: ['./edit-collector-position.component.scss'],
    inputs: ['readOnly', 'isVisible', 'isPopupMode', 'mode'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditCollectorPositionComponent {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key == 'F10' &&
      this.collectorPositionId &&
      this.form.touched &&
      this.isDataFetched
    ) {
      this.onSaving();
    }
  }

  widthPopup: string;
  isVisible: boolean = false;
  unicalGuid;
  @Output('onClosed') onClosed = new EventEmitter();
  @Output('onSaved') onSaved = new EventEmitter();
  @Input('collectorPositionId') collectorPositionId;
  @Input('collectorTransferId') collectorTransferId;
  @ViewChild('firstField') firstField;
  collectorPositionData;
  shortcuts: ShortcutInput[] = [];
  form: FormGroup;
  isDataFetched: boolean = false;
  validators: Array<CollectorPosValidatorCfg> = [
    {
      showPopup: false,
      valid: true,
      forceOmit: false,
      message: 'dataCollectors.validators.amountToProceedGreaterThanAmount',
      type: 'warning',
      confirmDialogBtnConfig: null,
    },
  ];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.widthPopup = this.event.setWidthPopUp();
    this.form = this.fb.group({
      Amount: [null],
      AmountToProceed: [null],
      CollectorTransferId: [this.collectorTransferId],
      CollectorPositionId: [this.collectorPositionId],
      ExpirationDate: [null],
      SerialNumber: [null],
      UnitId: [null],
    });
  }

  ngAfterViewInit = () => {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSaving();
        },
        preventDefault: true,
      }
    );
  };

  ngOnChanges(changes) {
    if (changes?.isVisible?.currentValue) {
      this.unicalGuid =
        new Date().getTime() + Math.round(Math.random() * 10000);
      this.getPosition().then(() => {
        this.createForm();
        if (!this.form.value.UnitId) {
          this.getUnitId(this.collectorPositionData.Unit);
        }
      });
    }
  }

  formCreated = false;
  createForm() {
    this.form = this.fb.group({
      Amount: [this.collectorPositionData.Amount, Validators.required],
      AmountToProceed: [
        this.collectorPositionData.AmountToProceed,
        Validators.required,
      ],
      CollectorTransferId: this.collectorPositionData.CollectorTransferId,
      CollectorPositionId: this.collectorPositionData.CollectorPositionId,
      ExpirationDate: this.collectorPositionData.ExpirationDate,
      SerialNumber: this.collectorPositionData.SerialNumber,
      UnitId: [this.collectorPositionData.UnitId, Validators.required],
    });
    setTimeout(() => {
      this.formCreated = true;
    }, 500);
  }

  validateForm() {
    return new Promise((resolve) => {
      if (
        this.form.value.AmountToProceed > this.form.value.Amount &&
        !this.validators[0].forceOmit
      ) {
        this.validators[0].valid = false;
        this.validators[0].showPopup = true;
        this.cd.detectChanges();
        resolve(false);
      }
      if (this.validators.findIndex((v) => v.valid === false) == -1) {
        resolve(true);
      }
    });
  }

  getUnitId = (code) => {
    return new Promise((resolve) => {
      this.appService.getAuth(`products/units`, { code: code }).subscribe({
        next: (res) => {
          this.form.controls.UnitId.setValue(res.data[0].UnitId);
          resolve(true);
        },
        error: (err) => {
          err = JSON.parse(err);
          this.event.showNotification('error', err.message);
        },
      });
    });
  };

  getPosition() {
    return new Promise((resolve) => {
      const params = {
        CollectorPositionId: this.collectorPositionId,
        CollectorTransferId: this.collectorTransferId,
        skip: 0,
        take: 100,
      };
      this.appService
        .getAuth(
          `device/collector/transfer/${params.CollectorTransferId}/positions`,
          params
        )
        .subscribe({
          next: (res) => {
            this.collectorPositionData = res.data[0];
            this.isDataFetched = true;
            resolve(true);
          },
          error: () => {
            resolve(false);
          },
        });
    });
  }

  savePosition() {
    this.appService
      .putAuth(
        `device/collector/transfer/${this.collectorTransferId}/positions/${this.collectorPositionId}`,
        this.form.value
      )
      .subscribe({
        next: (res) => {
          this.collectorPositionData = res.data;
        },
        error: (err) => {
          err = JSON.parse(err);
          this.event.showNotification('error', err.message);
        },
      });
  }

  visibleChange(e) {
    if (!e) {
      this.onClosed.emit(true);
      this.event.onHiddenPopUp();
      this.form = this.fb.group({
        Amount: [null],
        AmountToProceed: [null],
        collectorTransferId: [null],
        CollectorPositionId: [null],
        ExpirationDate: [null],
        SerialNumber: [null],
        UnitId: [null],
      });
    }
  }

  setFocus() {
    setTimeout(() => {
      this.event.setFocus(this.firstField);
    }, 400);
  }

  resetValidators() {
    this.validators.forEach((v) => {
      v.showPopup = false;
      v.valid = true;
      v.forceOmit = false;
    });
  }

  onClosing() {
    this.isVisible = false;
    this.resetValidators();
    this.isDataFetched = false;
  }
  onSaving() {
    this.validateForm().then((res) => {
      if (res) {
        this.savePosition();
        this.resetValidators();
        this.isVisible = false;
        this.onSaved.emit(true);
        this.isDataFetched = false;
      } else {
      }
    });
  }

  onFormInput() {
    if (this.formCreated) this.form.markAsTouched();
  }

  onUnitChoosed(e) {
    this.form.controls.UnitId.setValue(e.UnitId);
    this.form.markAsTouched();
  }

  onConfirmDialogConfirm(i) {
    this.validators[i].showPopup = false;
    this.validators[i].valid = true;
    this.validators[i].forceOmit = true;
    this.onSaving();
  }

  onConfirmDialogCancel(i) {
    this.validators[i].showPopup = false;
    // this.isDataFetched = false;
  }

  onConfirmDialogClose(i) {
    this.validators[i].showPopup = false;
    // this.isDataFetched = false;
  }
}
