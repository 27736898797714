import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDraggableModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxGalleryModule,
  DxListModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSortableModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { DragulaModule } from 'ng2-dragula';
import { CoreModule } from '../core/core.module';
import { GroupsRouting } from '../groups/groups-routing.module';
import { FilterWidthPipe } from './../core/filter-panel/filter-width.pipe';
import { AmountFormatterPipe } from './../pipes/amountFormatter.pipe';
import { PipesModule } from './../pipes/pipes.module';
import { CategoryOverheadComponent } from './articles/categories/category-overhead/category-overhead.component';
import { ChangeVatComponent } from './articles/change-vat/change-vat.component';
import { GenerateBarcodeComponent } from './articles/generate-barcode/generate-barcode.component';
import { GroupChangePriceComponent } from './articles/group-change-price/group-change-price.component';
import { OtherArticleUnitsComponent } from './articles/other-article-units/other-article-units.component';
import { SelectedListComponent } from './articles/selected-articles-list/selected-list.component';
import { TradeDataGroupChangeComponent } from './articles/trade-data-group-change/trade-data-group-change.component';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { ContractorLimitComponent } from './contractors/contractor-limit/contractor-limit.component';
import { CustomerGroupTradeChangeComponent } from './contractors/customer-group-trade-change/customer-group-trade-change.component';
import { RecipientComponent } from './contractors/recipient/recipient.component';
import { RodoConsentsComponent } from './contractors/rodo-consents/rodo-consents.component';
import { DeliveryOperatorComponent } from './delivery-operator/delivery-operator.component';
import { DiscountMarkupFactorComponent } from './discount-markup-factor/discount-markup-factor.component';
import { AddIngredientComponent } from './ingredients/add-ingredient/add-ingredient.component';
import { IngredientsComponent } from './ingredients/ingredients.component';
import { ProductScanerComponent } from './product-scaner/product-scaner.component';
import { AddSmsComponent } from './sms-offers/add-sms/add-sms.component';
import { SmsOffersComponent } from './sms-offers/sms-offers.component';
import { ResizableModule } from 'angular-resizable-element';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxColorBoxModule } from 'devextreme-angular/ui/color-box';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { LabelModule } from '../label/label.module';
import { SingleBankAccountComponent } from './bank-accounts/single-bank-account/single-bank-account.component';
import { BankHistoryNamesComponent } from './bank-box/bank-history-names/bank-history-names.component';
import { SingleBankHistoryNameComponent } from './bank-box/bank-history-names/single-bank-history-name/single-bank-history-name.component';
import { BankListComponent } from './bank-list/bank-list.component';
import { BaseCurrencyValuesComponent } from './base-currency-values/base-currency-values.component';
import { DateTimesGridComponent } from './base-currency-values/date-times-grid/date-times-grid.component';
import { SingleCurrencyDateComponent } from './base-currency-values/single-currency-date/single-currency-date.component';
import { SingleCurrencyValueComponent } from './base-currency-values/single-currency-value/single-currency-value.component';
import { AddbankComponent } from './contractors/bank-accounts-contractors/addbank/addbank.component';
import { BankAccountsContractorsComponent } from './contractors/bank-accounts-contractors/bank-accounts-contractors.component';
import { AddClasificationComponent } from './contractors/clasification/add-clasification/add-clasification.component';
import { ClasificationComponent } from './contractors/clasification/clasification.component';
import { ContactAddComponent } from './contractors/contact-add/contact-add.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { CustomerGroupsComponent } from './contractors/customer-groups/customer-groups.component';
import { NewCustomerGroupComponent } from './contractors/customer-groups/new-customer-group/new-customer-group.component';
import { NewContractorsComponent } from './contractors/new-contractors/new-contractors.component';
import { CountryBoxComponent } from './country-box/country-box.component';
import { NewCountryComponent } from './country-box/new-country/new-country.component';
import { CurrencyBoxComponent } from './currency-box/currency-box.component';
import { SingleCurrencyComponent } from './currency-box/single-currency/single-currency.component';
import { DataExchangeComponent } from './data-exchange/data-exchange.component';
import { AddAdressComponent } from './delivery/adress/add-adress/add-adress.component';
import { AdressComponent } from './delivery/adress/adress.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { routing } from './files-routing-module';
import { FormsPaymentComponent } from './forms-payment/forms-payment.component';
import { GusComponent } from './gus/gus.component';
import { AddHistoryAddressComponent } from './history-address/add-history-address/add-history-address.component';
import { HistoryAddressComponent } from './history-address/history-address.component';
import { InterestComponent } from './interest/interest.component';
import { NewBankComponent } from './new-bank/new-bank.component';
import { NewWarehouseComponent } from './new-warehouse/new-warehouse.component';
import { SplitPaymentBankAccountBoxComponent } from './split-payment-bank-account-box/split-payment-bank-account-box.component';
import { WarehouseListComponent } from './warehouse-list/warehouse-list.component';
import { AddNewWordersComponent } from './workers/add-new-worders/add-new-worders.component';
import { WorkersComponent } from './workers/workers.component';
import { ContactPositionsComponent } from './contractors/contact-positions/contact-positions.component';
import { AddSizeUnitComponent } from './size-units/add-size-unit/add-size-unit.component';
import { AddBankWorkersComponent } from './workers/add-bank-workers/add-bank-workers.component';
import { AddWorkersAddressComponent } from './workers/add-workers-address/add-workers-address.component';

import { UsersModule } from '../users/users.module';
import { ArticlePricesComponent } from './articles/article-prices/article-prices.component';
import { SingleArticlePriceComponent } from './articles/article-prices/single-article-price/single-article-price.component';
import { AddCategoryTreeComponent } from './articles/articles-category-tree/add-category-tree/add-category-tree.component';
import { ArticlesCategoryTreeComponent } from './articles/articles-category-tree/articles-category-tree.component';
import { ArticlesComponent } from './articles/articles.component';
import { AddCategoryComponent } from './articles/categories/add-category/add-category.component';
import { CategoriesComponent } from './articles/categories/categories.component';
import { DeliveriesComponent } from './articles/deliveries/deliveries.component';
import { NewArticleComponent } from './articles/new-article/new-article.component';
import { PlannedOrdersSummaryComponent } from './articles/planned-orders/planned-orders-summary/planned-orders-summary.component';
import { PlannedOrdersComponent } from './articles/planned-orders/planned-orders.component';
import { DependentPostitionComponent } from './articles/stock-history/dependent-postition/dependent-postition.component';
import { AddBarcodeComponent } from './barcodes/add-barcode/add-barcode.component';
import { BarcodesComponent } from './barcodes/barcodes.component';
import { AddCashBoxesComponent } from './cash-boxes/add-cash-boxes/add-cash-boxes.component';
import { CashBoxesComponent } from './cash-boxes/cash-boxes.component';
import { AddContactPositionComponent } from './contractors/contact-positions/add-contact-position/add-contact-position.component';
import { AddIdentityMasksComponent } from './contractors/identity-masks/add-identity-masks/add-identity-masks.component';
import { IdentityMasksComponent } from './contractors/identity-masks/identity-masks.component';
import { AddCalendarComponent } from './crm/calendars/add-calendar/add-calendar.component';
import { CalendarsComponent } from './crm/calendars/calendars.component';
import { AddTaskTypesComponent } from './crm/task-types/add-task-types/add-task-types.component';
import { TaskTypesComponent } from './crm/task-types/task-types.component';
import { AddFormDeliveryComponent } from './delivery/add-form-delivery/add-form-delivery.component';
import { AddFormPaymentComponent } from './forms-payment/add-form-payment/add-form-payment.component';
import { AddIndividualPriceComponent } from './individual-prices/add-individual-price/add-individual-price.component';
import { IndividualPricesComponent } from './individual-prices/individual-prices.component';
import { AddCnCodeComponent } from './intrastat-cn-codes/add-cn-code/add-cn-code.component';
import { IntrastatCnCodesComponent } from './intrastat-cn-codes/intrastat-cn-codes.component';
import { AddLocationComponent } from './locations/add-location/add-location.component';
import { LocationsComponent } from './locations/locations.component';
import { AddLoyaltyProgramComponent } from './loyalty-program/add-loyalty-program/add-loyalty-program.component';
import { LoyaltyProgramComponent } from './loyalty-program/loyalty-program.component';
import { AddPhotoComponent } from './photos/add-photo/add-photo.component';
import { PhotosComponent } from './photos/photos.component';
import { AddPriceComponent } from './prices/add-price/add-price.component';
import { PricesComponent } from './prices/prices.component';
import { AddBrandComponent } from './services/brands/add-brand/add-brand.component';
import { BrandsComponent } from './services/brands/brands.component';
import { AddDevicesComponent } from './services/devices/add-devices/add-devices.component';
import { DevicesComponent } from './services/devices/devices.component';
import { AddServicesGroupComponent } from './services/groups/add-services-group/add-services-group.component';
import { GroupsDeviceComponent } from './services/groups/groups.component';
import { AddModelBrandComponent } from './services/model-brand/add-model-brand/add-model-brand.component';
import { ModelBrandComponent } from './services/model-brand/model-brand.component';
import { ArchivedUnitsPipe } from './size-units/size-pipe';
import { SizeUnitsComponent } from './size-units/size-units.component';
import { AddSubstituteComponent } from './substitutes/add-substitute/add-substitute.component';
import { SubstitutesComponent } from './substitutes/substitutes.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';
import { TypeOfCommercialTransactionComponent } from './type-of-commercial-transaction/type-of-commercial-transaction.component';
import { AddVariantComponent } from './variants/add-variant/add-variant.component';
import { VariantsComponent } from './variants/variants.component';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { UserExtensionModule } from '../user-extension/user-extension.module';
import { OrderHistoryComponent } from './articles/order-history/order-history.component';
import { StockLimitsComponent } from './articles/stock-limits/stock-limits.component';
import { StockSummaryComponent } from './articles/stock-summary/stock-summary.component';
import { ContactsComponent } from './contractors/contacts/contacts.component';
import { AddDeliveryAddressComponent } from './crm/delivery-address/add-delivery-address/add-delivery-address.component';
import { DeliveryAddressComponent } from './crm/delivery-address/delivery-address.component';
import { DeliveryPointsComponent } from './crm/delivery-points/delivery-points.component';
import { EDokumentsComponent } from './e-dokuments/e-dokuments.component';
import { AddInterestComponent } from './interest/add-interest/add-interest.component';
import { AddOfficesComponent } from './offices/add-offices/add-offices.component';
import { OfficesBankAccountsComponent } from './offices/offices-bank-accounts/offices-bank-accounts.component';
import { OfficesComponent } from './offices/offices.component';
import { AddDescriptionDictionaryComponent } from './services/description-dictionary/add-description-dictionary/add-description-dictionary.component';
import { DescriptionDictionaryComponent } from './services/description-dictionary/description-dictionary.component';
import { AddRoleToCustomerComponent } from './services/devices/add-role-to-customer/add-role-to-customer.component';
import { HistroryStatusComponent } from './services/histrory-status/histrory-status.component';
import { AddRoleCustomerComponent } from './services/role-customer/add-role-customer/add-role-customer.component';
import { RoleCustomerComponent } from './services/role-customer/role-customer.component';
import { IsTemplateUnitsPipe } from './size-units/template-pipe';
import { VerticalTabPanelComponent } from './vertical-tab-panel/vertical-tab-panel.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { AddVehiclesComponent } from './vehicles/add-vehicles/add-vehicles.component';
import { AddVehiclesChangesComponent } from './vehicles/add-vehicles-changes/add-vehicles-changes.component';

import { QuicklinkModule } from 'ngx-quicklink';
import { NewTaskComponent } from '../crm/tasks/new-task/new-task.component';
import { DataCollectorComponent } from '../libraries/data-collector/data-collector.component';
import { PointReplacerPipe } from '../pipes/pipe';
import { ArticlePricePositionDocumentComponent } from './articles/article-price-position-document/article-price-position-document.component';
import { ArticleQuantitiesComponent } from './articles/article-quantities/article-quantities.component';
import { ArticleReplacementsComponent } from './articles/article-replacements/article-replacements.component';
import { ArticleSellPricesComponent } from './articles/article-sell-prices/article-sell-prices.component';
import { ArticleSetsComponent } from './articles/article-sets/article-sets.component';
import { ArticleStoreStateComponent } from './articles/article-store-state/article-store-state.component';
import { FundForPromotionAgriculturalComponent } from './articles/fund-for-promotion-agricultural/fund-for-promotion-agricultural.component';
import { AddOtherArticleUnitComponent } from './articles/other-article-units/add-other-article-unit/add-other-article-unit.component';
import { NewSelectedListComponent } from './articles/selected-articles-list/new-selected-list/new-selected-list.component';
import { PriceGroupComponent } from './contractors/customer-groups/price-group/price-group.component';
import { DeliveryAddressesPositionDocumentComponent } from './contractors/delivery-addresses-position-document/delivery-addresses-position-document.component';
import { IndyvidualPricesProductComponent } from './contractors/indyvidual-prices-product/indyvidual-prices-product.component';
import { NewRecipirntComponent } from './contractors/recipient/new-recipient/new-recipient.component';
import { AddRodoConsentsComponent } from './contractors/rodo-consents/add-rodo-consents/add-rodo-consents.component';
import { AddDataCollectorComponent } from './data-collector/add-data-collector/add-data-collector.component';
import { CollectorChoiceComponent } from './data-collector/collector-choice/collector-choice.component';
import { TextFileReaderComponent } from './data-collector/collector-choice/text-file-reader/text-file-reader.component';
import { EditCollectorPositionComponent } from './data-collector/edit-collector-position/edit-collector-position.component';
import { DocumentPrintersComponent } from './external-devices/document-printers/document-printers.component';
import { ExternalDevicesComponent } from './external-devices/external-devices.component';
import { AddFinanceOperationComponent } from './finance-operation/add-finance-operation/add-finance-operation.component';
import { FinanceOperationComponent } from './finance-operation/finance-operation.component';
import { FiscalPrinterSettingsComponent } from './fiscal-printers/fiscal-printer-settings/fiscal-printer-settings.component';
import { FiscalPrinterComponent } from './fiscal-printers/fiscal-printer/fiscal-printer.component';
import { FiscalPrintersComponent } from './fiscal-printers/fiscal-printers.component';
import { AddRateComponent } from './interest/add-rate/add-rate.component';
import { SumLoyaltyProgramComponent } from './loyalty-program/sum-loyalty-program/sum-loyalty-program.component';
import { Base64PhotoPipe } from './photos/add-photo/photo.pipe';
import { EnterScanerValueComponent } from './product-scaner/enter-scaner-value/enter-scaner-value.component';
import { VariantsSizeUnitComponent } from './variants/variants-size-unit/variants-size-unit.component';
import { ViewDynamicQuickFiltersComponent } from './view-dynamic-quick-filters/view-dynamic-quick-filters.component';
import { ConfirmDialogComponent } from '../core/confirm-dialog/confirm-dialog.component';
import { TaskSubjectGeniusComponent } from '../crm/tasks/new-task/task-subject-genius/task-subject-genius.component';
import { BtnExportExcelComponent } from '../menu-wapro/btn-export-excel/btn-export-excel.component';
import { NgShortcutsComponent } from '../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { ExpansionListComponent } from '../expansion-slote/expansion-list/expansion-list.component';
import { SkeletonDirective } from '../core/directives/skeleton.directive';
import { CustomChipsButtonComponent } from '../core/custom-chips-button/custom-chips-button.component';
import { MobileListComponent } from '../core/mobile-list/mobile-list.component';
import { CustomDropdownBoxComponent } from '../core/custom-dropdown-box/custom-dropdown-box.component';
import { TableDataUserExtensionComponent } from '../core/table-user-extension/table-data-user-extension/table-data-user-extension.component';
import { TableUserExtensionComponent } from '../core/table-user-extension/table-user-extension.component';
import { WaproDateBoxComponent } from '../core/wapro-date-box/wapro-date-box.component';
import { NestComponent } from 'src/app/nest/nest.component';
import { DynamicComponent } from '../core/dynamic-component/dynamic-component.component';
import { NoDataComponent } from '../menu-wapro/no-data/no-data.component';
import { ArticleBundleComponent } from './articles/article-bundle/article-bundle.component';
import { NewArticleBundleComponent } from './articles/article-bundle/new-article-bundle/new-article-bundle.component';
import { StockAvailabilityComponent } from './stock-availability/stock-availability.component';
import { DateFilterComponent } from './stock-availability/date-filter/date-filter.component';
import { ImportExportCsvComponent } from './articles/import-export-csv/import-export-csv.component';
import { SingleDeviceComponent } from './external-devices/single-device/single-device.component';
import { SmsTrackingUrlComponent } from './sms-offers/sms-tracking-url/sms-tracking-url.component';
import { AddSmsTrackingUrlComponent } from './sms-offers/sms-tracking-url/add-sms-tracking-url/add-sms-tracking-url.component';
import { WarehouseDocumentComponent } from '../administrator/typeDocument/warehouse-document/warehouse-document.component';
// import { LabelPrinterOperationsComponent } from './external-devices/label-printers/label-printer-operations/label-printer-operations.component';
// import { LabelPrintersModule } from './external-devices/label-printers/label-printers.module';
import { IngredientDemandComponent } from '../warehouse/production-documents/ingredient-demand/ingredient-demand.component';
import { EstablishingRelationshipsWithContractorComponent } from './contractors/establishing-relationships-with-contractor/establishing-relationships-with-contractor.component';
import { LabelComponent } from '../core/label/label.component';
import { ValidationDataComponent } from './articles/group-change-price/validation-data/validation-data.component';
import { ShortcutDirective } from '../menu-wapro/shortcut.directive';
import { StatusLabelComponent } from '../core/status-label/status-label.component';
import { PreventRightClickDirective } from '../core/directives/prevent-right-click.directive';
import { RemoveBgComponent } from './remove-bg/remove-bg.component';
import { RecordCardComponent } from './record-card/record-card.component';
import { StockHistoryModule } from './articles/stock-history/stock-history.module';
import { ColorPickerComponent } from '../core/color-picker/color-picker.component';
import { PackingTypesComponent } from './packing-types/packing-types.component';
import { ContractorsRoutesComponent } from './contractors-routes/contractors-routes.component';
import { NewRouteComponent } from './contractors-routes/new-route/new-route.component';
import { RouteUsersComponent } from './contractors-routes/route-users/route-users.component';
import {RoutesMapComponent} from './contractors-routes/routes-map/routes-map.component';
import {StatusRouteTextPipe} from './contractors-routes/routes-map/status-route-text.pipe';
import {EditAddressRouteComponent} from './contractors-routes/edit-address-route/edit-address-route.component';
import { NotesComponent } from '../core/notes/notes.component';
import { LabelPrinterOperationsComponent } from './external-devices/label-printers/label-printer-operations/label-printer-operations.component';

@NgModule({
  declarations: [
    NewRouteComponent,
    ContractorsRoutesComponent,
    AddBrandComponent,
    AddCashBoxesComponent,
    AddDeliveryAddressComponent,
    AddDescriptionDictionaryComponent,
    AddDevicesComponent,
    AddFinanceOperationComponent,
    AddModelBrandComponent,
    AddOfficesComponent,
    AddRoleCustomerComponent,
    AddRoleToCustomerComponent,
    AddServicesGroupComponent,
    AddSmsComponent,
    AddTaskTypesComponent,
    ArticlePricePositionDocumentComponent,
    BrandsComponent,
    CashBoxesComponent,
    ContractorsComponent,
    CustomerGroupTradeChangeComponent,
    DataCollectorComponent,
    DataExchangeComponent,
    DateFilterComponent,
    DeliveryAddressComponent,
    DeliveryAddressesPositionDocumentComponent,
    DescriptionDictionaryComponent,
    DevicesComponent,
    DiscountMarkupFactorComponent,
    DocumentPrintersComponent,
    EditCollectorPositionComponent,
    EnterScanerValueComponent,
    ExternalDevicesComponent,
    FinanceOperationComponent,
    FiscalPrinterComponent,
    FiscalPrintersComponent,
    FiscalPrinterSettingsComponent,
    GroupsDeviceComponent,
    HistroryStatusComponent,
    ModelBrandComponent,
    NewTaskComponent,
    OfficesBankAccountsComponent,
    OfficesComponent,
    ProductScanerComponent,
    RoleCustomerComponent,
    SingleDeviceComponent,
    SmsOffersComponent,
    StockAvailabilityComponent,
    TaskSubjectGeniusComponent,
    TaskTypesComponent,
    VehiclesComponent,
    AddVehiclesComponent,
    AddVehiclesChangesComponent,
  ],
  imports: [NgShortcutsComponent,

    IndyvidualPricesProductComponent,
    NewContractorsComponent,
    SubstitutesComponent,
    AddSubstituteComponent,
    ArticleBundleComponent,
    ArticlesCategoryTreeComponent,
    AddCategoryTreeComponent,
    BarcodesComponent,
    AddBarcodeComponent,
    ArticlePricesComponent,
    CustomerGroupsComponent,
    NewCustomerGroupComponent,
    PriceGroupComponent,
    IndividualPricesComponent,
    AddIndividualPriceComponent,
    NewArticleComponent,
    CollectorChoiceComponent,
    AddDataCollectorComponent,
    TextFileReaderComponent,
    GroupChangePriceComponent,
    TradeDataGroupChangeComponent,
    SingleArticlePriceComponent,
    ArticleSetsComponent,
    IngredientsComponent,
    VariantsComponent,
    ArticlesComponent,
    NewArticleBundleComponent,
    AddIngredientComponent,
    ArticleQuantitiesComponent,
    ArticleReplacementsComponent,
    ArticleSellPricesComponent,
    IntrastatCnCodesComponent,
    AddCnCodeComponent,
    LocationsComponent,
    AddLocationComponent,
    StockLimitsComponent,
    StockSummaryComponent,
    SelectedListComponent,
    NewSelectedListComponent,
    PlannedOrdersComponent,
    OtherArticleUnitsComponent,
    AddOtherArticleUnitComponent,
    OrderHistoryComponent,
    PlannedOrdersSummaryComponent,
    ViewDynamicQuickFiltersComponent,
    FundForPromotionAgriculturalComponent,
    DeliveriesComponent,
    ChangeVatComponent,
    CategoriesComponent,
    AddCategoryComponent,
    CategoryOverheadComponent,
    GusComponent,
    HistoryAddressComponent,
    AddHistoryAddressComponent,
    InterestComponent,
    AddInterestComponent,
    AddRateComponent,
    Base64PhotoPipe,
    IsTemplateUnitsPipe,
    IsTemplateUnitsPipe,
    ArchivedUnitsPipe,
    LoyaltyProgramComponent,
    SumLoyaltyProgramComponent,
    AddLoyaltyProgramComponent,
    PhotosComponent,
    AddPhotoComponent,
    PricesComponent,
    AddPriceComponent,
    SizeUnitsComponent,
    AddSizeUnitComponent,
    TaxRatesComponent,
    TypeOfCommercialTransactionComponent,
    AddVariantComponent,
    VariantsSizeUnitComponent,
    DeliveryComponent,
    AdressComponent,
    DeliveryPointsComponent,
    AddAdressComponent,
    AddFormDeliveryComponent,
    DeliveryOperatorComponent,
    EDokumentsComponent,
    WorkersComponent,
    AddNewWordersComponent,
    AddCalendarComponent,
    AddWorkersAddressComponent,
    AddBankWorkersComponent,
    RecipientComponent,
    NewRecipirntComponent,
    ContractorLimitComponent,
    BankAccountsContractorsComponent,
    AddbankComponent,
    ContactsComponent,
    WarehouseListComponent,
    NewWarehouseComponent,
    BankAccountsComponent,
    SingleBankAccountComponent,
    SplitPaymentBankAccountBoxComponent,
    BaseCurrencyValuesComponent,
    SingleCurrencyDateComponent,
    SingleCurrencyValueComponent,
    DateTimesGridComponent,
    CurrencyBoxComponent,
    SingleCurrencyComponent,
    BankListComponent,
    NewBankComponent,
    BankHistoryNamesComponent,
    SingleBankHistoryNameComponent,
    IdentityMasksComponent,
    AddIdentityMasksComponent,
    NewCountryComponent,
    CountryBoxComponent,
    AddClasificationComponent,
    ClasificationComponent,
    AddFormPaymentComponent,
    FormsPaymentComponent,
    AddRodoConsentsComponent,
    RodoConsentsComponent,
    AddContactPositionComponent,
    ContactPositionsComponent,
    ContactAddComponent,
    PackingTypesComponent,
    StockHistoryModule,
    VerticalTabPanelComponent,
    ArticleStoreStateComponent,
    DependentPostitionComponent,
    RemoveBgComponent,
    ValidationDataComponent,
    AddSmsTrackingUrlComponent,
    BtnExportExcelComponent,
    CommonModule,
    ConfirmDialogComponent,
    CoreModule,
    CustomChipsButtonComponent,
    CustomDropdownBoxComponent,
    DragulaModule,
    DxAccordionModule,
    DxButtonModule,
    DxChartModule,
    DxCheckBoxModule,
    DxColorBoxModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDraggableModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxFileUploaderModule,
    DxGalleryModule,
    DxListModule,
    DxNumberBoxModule,
    DxPopupModule,
    LabelComponent,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSortableModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxValidatorModule,
    DynamicComponent,
    ExpansionListComponent,
    FormsModule,
    GenerateBarcodeComponent,
    GroupsRouting,
    ImportExportCsvComponent,
    IngredientDemandComponent,
    KeyboardShortcutsModule,
    LabelModule,
    // LabelPrintersModule,
    LabelPrinterOperationsComponent,
    MobileListComponent,
    NestComponent,
    NgShortcutsComponent,
    NoDataComponent,
    PipesModule,
    QuicklinkModule,
    ReactiveFormsModule,
    ResizableModule,
    routing,
    SkeletonDirective,
    SmsTrackingUrlComponent,
    TableDataUserExtensionComponent,
    TableUserExtensionComponent,
    TranslateModule,
    UserExtensionModule,
    UserExtensionModule,
    UsersModule,
    WaproDateBoxComponent,
    WarehouseDocumentComponent,
    ColorPickerComponent,
    EstablishingRelationshipsWithContractorComponent,
    ShortcutDirective,
    StatusLabelComponent,
    PreventRightClickDirective,
    RecordCardComponent,
    RouteUsersComponent,
    CalendarsComponent,
    RoutesMapComponent,
    StatusRouteTextPipe,
    EditAddressRouteComponent,
    NotesComponent
  ],
  exports: [
    AddCalendarComponent,
    FormsPaymentComponent,
    CategoriesComponent,
    NewTaskComponent,
    PlannedOrdersComponent,
    NewSelectedListComponent,
    ArticlesComponent,
    AddFinanceOperationComponent,
    FinanceOperationComponent,
    AddSmsTrackingUrlComponent,
    BankAccountsComponent,
    CurrencyBoxComponent,
    AddHistoryAddressComponent,
    CountryBoxComponent,
    BankListComponent,
    NewBankComponent,
    AddRateComponent,
    SmsOffersComponent,
    AddSmsComponent,
    IndyvidualPricesProductComponent,
    DeliveryAddressesPositionDocumentComponent,
    DeliveryComponent,
    OfficesComponent,
    AddOfficesComponent,
    HistoryAddressComponent,
    DataExchangeComponent,
    InterestComponent,
    AdressComponent,
    ContractorsComponent,
    NewContractorsComponent,
    BankAccountsContractorsComponent,
    WorkersComponent,
    ClasificationComponent,
    CustomerGroupsComponent,
    AddClasificationComponent,
    NewCustomerGroupComponent,
    WarehouseListComponent,
    NewWarehouseComponent,
    OfficesBankAccountsComponent,
    GusComponent,
    PricesComponent,
    RoleCustomerComponent,
    DescriptionDictionaryComponent,
    NewCountryComponent,
    AddbankComponent,
    AddWorkersAddressComponent,
    AddBankWorkersComponent,
    CashBoxesComponent,
    AddFormDeliveryComponent,
    CalendarsComponent,
    SubstitutesComponent,
    TaskTypesComponent,
    AddInterestComponent,
    AddRoleToCustomerComponent,
    TypeOfCommercialTransactionComponent,
    AddNewWordersComponent,
    NewCustomerGroupComponent,
    AddPriceComponent,
    HistroryStatusComponent,
    NewArticleComponent,
    ContactsComponent,
    AddCategoryComponent,
    SizeUnitsComponent,
    DependentPostitionComponent,
    TaxRatesComponent,
    DiscountMarkupFactorComponent,
    ProductScanerComponent,
    ArticlePricesComponent,
    ArticlePricePositionDocumentComponent,
    VariantsComponent,
    DevicesComponent,
    DeliveryAddressComponent,
    AddDeliveryAddressComponent,
    DeliveryPointsComponent,
    DeliveryOperatorComponent,
    IntrastatCnCodesComponent,
    AddTaskTypesComponent,
    PlannedOrdersComponent,
    ViewDynamicQuickFiltersComponent,
    DataCollectorComponent,
    CollectorChoiceComponent,
    AddDataCollectorComponent,
    FiscalPrintersComponent,
    FiscalPrinterComponent,
    BankAccountsContractorsComponent,
    EDokumentsComponent,
    VehiclesComponent,
    AddVehiclesComponent,
    AddVehiclesChangesComponent,
    ArticleBundleComponent,
    NewArticleBundleComponent,
    StockAvailabilityComponent,
    DateFilterComponent,
    GroupChangePriceComponent,
    FiscalPrinterSettingsComponent,
    // LabelPrinterOperationsComponent,
    // LabelPrintersModule,
    DxPopupModule,
    NewRouteComponent,
  ],
  providers: [
    ArchivedUnitsPipe,
    CurrencyPipe,
    PointReplacerPipe,
    AmountFormatterPipe,
    FilterWidthPipe,
  ],
})
export class LibrariesModule {}
