import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserExtensionModule } from '../../../user-extension/user-extension.module';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { CustomWindowConfig } from '../../../event.model';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';

@Component({
  selector: 'app-edit-address-route',
  imports: [
    CommonModule,
    DxButtonModule,
    DxPopupModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    FormsModule,
    NgShortcutsComponent,
    ReactiveFormsModule,
    TranslateModule,
    UserExtensionModule,
    DxScrollViewModule,
  ],
  templateUrl: './edit-address-route.component.html',
  styleUrl: './edit-address-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAddressRouteComponent {
  @Input() isVisible: boolean;
  @Input() readOnly: boolean;
  @Input() selectedContractor = [];

  @Output() onClosing = new EventEmitter();

  shortcuts: ShortcutInput[] = [];
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: 521,
      height: 340,
    },
    params: [],
  };
  unicalGuid: number;
  title: string;
  form: FormGroup;
  submitted: boolean = false;

  constructor(
    public translate: TranslateService,
    public cd: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public event: EventService,
    private appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      this.createForm();

      this.title = this.translate.instant('routes.editingRouteAddress');
    }
  }

  visibleChange() {
    this.submitted = false;

    if (!this.isVisible) {
      this.onClosing.emit(true);
      this.selectedContractor = [];
    }

    this.cd.detectChanges();
  }

  createForm() {
    this.form = this.formBuilder.group({
      RoutePosId: [null, Validators.required],
      DeliveryPostalCode: [''],
      DeliveryCity: ['', Validators.required],
      DeliveryStreet: [''],
      DeliveryHouseNo: [''],
      DeliveryApNo: '',
    });

    if (this.selectedContractor.length > 0) {
      const selectedRouteAddress = {
        RoutePosId: this.selectedContractor[0].RoutePosId,
        DeliveryPostalCode: this.selectedContractor[0].DeliveryPostalCode,
        DeliveryCity: this.selectedContractor[0].DeliveryCity,
        DeliveryStreet: this.selectedContractor[0].DeliveryStreet,
        DeliveryHouseNo: this.selectedContractor[0].DeliveryHoNr,
        DeliveryApNo: this.selectedContractor[0].DeliveryApNr,
      };
      this.form.patchValue(selectedRouteAddress);
    }
  }

  onSaveAddressRoute() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    this.appService
      .postAuth(`customers/RoutesChangeDeliveryAddress`, this.form.value)
      .subscribe({
        next: () => {
          this.isVisible = false;
          this.onClosing.emit(true);
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onCancelAddressRoute() {
    this.isVisible = false;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }
}
