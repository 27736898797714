<div *ngIf="!dropDownBoxMode && !isPopupMode">
  <ng-container>
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtonsTransmissions"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <div *ngIf="event.deviceType != 'mobile'">
        <ng-container
          *ngTemplateOutlet="transmissionsTabTemplate"
        ></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="isPopupMode">
  <dx-popup
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [title]="'dataCollectors.popupTitle' | translate"
    [(visible)]="visible"
    [showCloseButton]="false"
    [minHeight]="480"
    [minWidth]="600"
    [(width)]="localStorageData.windowSize.width"
    [(height)]="localStorageData.windowSize.height"
    (onShown)="event.onShownPopUp(); loadChildComponent = true"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
    [fullScreen]="localStorageData.windowSize.isFullscreen"
    (fullScreenChange)="onFullScreenChange($event)"
    [resizeEnabled]="true"
    [dragEnabled]="true"
    (onResize)="onPopupResize()"
    (onInitialized)="onInit($event)"
    [id]="'data-collector-popup' + unicalGuid"
    [deferRendering]="false"
    [wrapperAttr]="{ class: 'collector-buffer' }"
  >
    <dxo-position my="bottom" at="bottom" of="window"> </dxo-position>

    <div *dxTemplate="let content of 'content'" class="">
      <ng-container *ngTemplateOutlet="header"></ng-container>
      <div class="panels-flex-container collector-buffer" style="height: 100%">
        <section
          #leftPanel
          [ngStyle]="{
            width: localStorageData.mainPanel.width,
            height: '100%'
          }"
          class="d-flex flex-column"
        >
          <div
            class="flex-columns-mode"
            #flexColumnsMode
            *ngIf="viewMode === 'flexColumns'"
          >
            <ng-container
              *ngTemplateOutlet="transmissionsTabTemplate"
            ></ng-container>
            <div class="resize-bar" #resizeBar></div>
            <ng-container *ngTemplateOutlet="positionsTabTemplate">
            </ng-container>
          </div>
        </section>
        <section
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeEnd)="resizeEnd()"
          [ngClass]="{ 'popup-side-panel': isPopupMode }"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          #rightPanel
          [ngStyle]="{ width: localStorageData.sidePanel.width, top: 0 }"
        >
          <div class="right-panel-btn">
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>
          <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
        </section>
      </div>
    </div>
  </dx-popup>
</div>

<ng-template #header>
  <div
    class="text-right title-pop-up"
    style="padding-top: 10px; padding-bottom: 10px"
  >
    <!-- Buttons -->

    <dx-drop-down-button
      #dropdownbtn
      [splitButton]="false"
      [useSelectMode]="false"
      stylingMode="text"
      [text]="'dataCollectors.buttons.transmissionParams' | translate"
      class="transmission-params"
      [dropDownOptions]="dropDownOptions"
      [dropDownContentTemplate]="'contentTemplate'"
      [id]="'btn-transmission-params' + unicalGuid"
      class="transmission-params"
    >
      <div *dxTemplate="let data of 'contentTemplate'">
        <div class="list-title">
          {{ "dataCollectors.buttons.transmissionParams" | translate }}
        </div>
        <hr class="title-hr" />
        <dx-list
          [dataSource]="transmissionParams"
          [searchEnabled]="false"
          class="report-list transmission-params"
          height="140"
        >
          <div *dxTemplate="let item of 'item'">
            <div class="w-100 d-flex align-items-center" style="height: 100%">
              <dx-check-box
                [text]="item.label | translate"
                [(value)]="transferConfig[item.name]"
                [enableThreeStateBehavior]="false"
              ></dx-check-box>
            </div>
          </div>
        </dx-list>
        <hr class="buttons-hr" />
        <div
          class="d-flex align-items-center justify-content-end"
          style="gap: 6px; padding-bottom: 6px; padding-right: 6px"
        >
          <!-- <dx-button
            [id]="'btn-save-params' + unicalGuid"
            [text]="'buttons.save' | translate"
            stylingMode="contained"
            type="success"
            (onClick)="saveParams()"
          >
          </dx-button> -->
          <dx-button
            [id]="'btn-clear-params' + unicalGuid"
            icon="icon absui-icon--clear-filter"
            stylingMode="text"
            type="normal"
            (onClick)="clearParams()"
          >
          </dx-button>
        </div>
      </div>
    </dx-drop-down-button>

    <div class="btn-separator"></div>

    <dx-button
      *ngIf="localStorageData.windowSize.isFullscreen"
      icon="icon absui-icon--fullscreen-off"
      type="normal"
      stylingMode="contained"
      (onClick)="toggleFullscreen()"
      [id]="'fullscreen' + unicalGuid"
    ></dx-button>
    <dx-button
      *ngIf="!localStorageData.windowSize.isFullscreen"
      icon="icon absui-icon--fullscreen2"
      type="normal"
      stylingMode="contained"
      (onClick)="toggleFullscreen()"
      [id]="'fullscreen' + unicalGuid"
    ></dx-button>
    <dx-button
      text="{{ 'buttons.close' | translate }}"
      type="danger"
      (onClick)="onClose()"
      [id]="'cancel' + unicalGuid"
    ></dx-button>
    <!-- Buttons -->
  </div>

  <!-- Tooltips -->
  <dx-tooltip
    [target]="'#fullscreen' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "servicesDevices.fitToWidth" | translate }}
    </div>
  </dx-tooltip>
  <dx-tooltip
    [target]="'#send' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.confirm" | translate }} (F10)
    </div>
  </dx-tooltip>
  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.close" | translate }} (Esc)
    </div>
  </dx-tooltip>
  <!-- / Tooltips -->
</ng-template>

<ng-template #topButtonsTransmissions>
  <div class="top-buttons-container">
    <app-wapro-selected-box
      [(ngModel)]="selectedCollectors"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      stylingMode="text"
      type="default"
      [disabled]="readOnly || !perAbdExtended.transferInBtn"
      (onClick)="chooseCollector()"
      [id]="'btn-transferIn' + unicalGuid"
    >
    </dx-button>

    <dx-button
      icon="icon absui-icon--highlight-off"
      [id]="'btn-delete' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="
        (selectedCollectors.length == 0 && !focusedCollectorTransferId) ||
        readOnly ||
        !perABD.deleteBtn
      "
      stylingMode="text"
      type="default"
      (onClick)="confirmDeletingCollector()"
    >
    </dx-button>

    <!-- [fullScreen]="fullScreen" -->
    <app-product-scaner
      [showScanerContainer]="false"
      [readOnly]="readOnly"
      (onInputChanged)="onScanerInputChanged($event)"
      [keyboardShortcutsDisabled]="
        isCollectorChoiceVisible ||
        isdeleteTransmissionVisible ||
        isDeleteCollectorPositionVisible ||
        isAddPositionVisible ||
        isShowArticleVisible ||
        isEditPositionVisible ||
        localStorageData?.sidePanel?.isVisible
      "
      [parentId]="unicalGuid"
    >
    </app-product-scaner>

    <div class="ml-auto d-flex align-items-center"></div>
  </div>

  <dx-tooltip
    [target]="'#btn-transferIn' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "dataCollectors.buttons.transferIn" | translate }} (Insert)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-delete' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "dataCollectors.buttons.deleteTransmission" | translate }} (Del)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-send' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "dataCollectors.buttons.send" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-refresh' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "refresh" | translate }}
    </div>
  </dx-tooltip>
  <dx-tooltip
    [target]="'#btn-show-dynamic-filters' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "filterPanel.filters" | translate }} (F8)
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #positionFilters>
  <div class="filters-container">
    <app-custom-dropdown-box
      [items]="positionFilterCriteria"
      [(selectedItem)]="positionOrderBy"
      style="margin-bottom: 0; margin-right: 10px"
      [(filterValue)]="positionFilterValue"
      (onValueChanged)="onPositionFilterDataChanged($event)"
    >
    </app-custom-dropdown-box>

    <app-custom-chips-button
      [name]="'dataCollectors.contextMenu.showPositions' | translate"
      [list]="proceededFilterList"
      [selectedValueInput]="proceededFilterLabel"
      (onValueChanged)="setProceededFilterValue($event)"
      [disabledClear]="false"
      valueExpr="value"
      labelExpr="label"
    ></app-custom-chips-button>

    <app-custom-chips-button
      *ngIf="
        localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
        localStorageData?.advancedFilter?.IsPinned
      "
      [name]="'filterPanel.template' | translate"
      [labelExpr]="'Name'"
      [valueExpr]="'FilterUsersDefinitionId'"
      [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
      [list]="dynamicFilterUserDefinitions"
      (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
    >
    </app-custom-chips-button>
  </div>
</ng-template>

<ng-template #transmissionsTabTemplate>
  <dx-tab-panel
    class="text-left"
    #tabPanel
    [items]="transmissionsTab"
    [(selectedIndex)]="localStorageData.selectionTab"
    [loop]="false"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="transmissions-tab"
  >
    <div *dxTemplate="let name of 'item'">
      <div *ngTemplateOutlet="topButtonsTransmissions"></div>
      <dx-data-grid
        [showBorders]="true"
        [allowColumnReordering]="false"
        (onKeyDown)="collectorOnKeyDown($event)"
        (onSelectionChanged)="onSelectionChanged($event)"
        [id]="'collector' + unicalGuid"
        [wordWrapEnabled]="false"
        [remoteOperations]="true"
        [(focusedRowKey)]="focusedCollectorTransferId"
        [(selectedRowKeys)]="selectedCollectors"
        [allowColumnResizing]="true"
        (onFocusedRowChanged)="onFocusedCollectorChanged()"
        [columnResizingMode]="'widget'"
        style="width: 100%; margin-bottom: 10px; margin-top: 10px"
        shortcut
        [focusedRowEnabled]="true"
        [dataSource]="dataSource"
        keyExpr="CollectorTransferId"
        alignment="left"
        #transmissionGrid
        [height]="localStorageData.mainPanel.height"
        [width]="'100%'"
        [columnAutoWidth]="true"
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling
          mode="virtual"
          rowRenderingMode="virtual"
        ></dxo-scrolling>
        <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
        <dxo-selection
          mode="multiple"
          showCheckBoxesMode="always"
          selectAllMode="page"
        >
        </dxo-selection>

        <dxi-column
          [caption]="'dataCollectors.user' | translate"
          dataField="Employee"
          [allowSorting]="false"
          [renderAsync]="true"
          cellTemplate="userTemplate"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'userTemplate'">
          <ng-container *ngFor="let state of collectorStates; let i = index">
            <div
              *ngIf="data.data.CollectorStatus === collectorStates[i].state"
              class="collector-status"
              [ngClass]="collectorStates[i].className"
              [id]="'state' + data.rowIndex + unicalGuid"
            ></div>
            <dx-tooltip
              *ngIf="data.data.CollectorStatus === collectorStates[i].state"
              [target]="'#state' + data.rowIndex + unicalGuid"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              [showEvent]="event.tooltipShowEvent"
            >
              <div *dxTemplate="let data of 'content'">
                {{ collectorStates[i].tooltip | translate }}
              </div>
            </dx-tooltip>
          </ng-container>
          {{ data.data.Employee }}
        </div>
        <dxi-column
          [caption]="'dataCollectors.collectorGrid.date' | translate"
          [allowSorting]="false"
          dataField="DateTime"
          cellTemplate="dateTemplate"
          [renderAsync]="true"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'dateTemplate'">
          {{ data.data.DateTime | date : "yyyy-MM-dd" : "UTC+2" }} <br />
          {{ data.data.DateTime | date : "hh:mm:ss" : "UTC+2" }}
        </div>
      </dx-data-grid>
      <dx-context-menu
        [dataSource]="collectorGridContextMenu"
        [width]="200"
        [target]="'#collector' + unicalGuid"
        (onItemClick)="transmissionsContextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
      </dx-context-menu>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #positionsTabTemplate>
  <dx-tab-panel
    class="text-left"
    #tabPanel
    [items]="positionsTab"
    [(selectedIndex)]="localStorageData.selectionTab"
    [loop]="false"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="positions-tab"
  >
    <div *dxTemplate="let name of 'item'">
      <div class="pb-2">
        <div *ngTemplateOutlet="topButtonsPositions"></div>
        <div *ngTemplateOutlet="positionFilters"></div>
      </div>
      <dx-data-grid
        [showBorders]="true"
        (onKeyDown)="positionOnKeyDown($event)"
        [wordWrapEnabled]="false"
        [hoverStateEnabled]="true"
        [dataSource]="dataSourcePositions"
        keyExpr="CollectorPositionId"
        [(focusedRowKey)]="focusedCollectorPositionKey"
        [(selectedRowKeys)]="selectedCollectorPositions"
        (onSelectionChanged)="onPositionsSelectionChanged($event)"
        [focusedRowEnabled]="true"
        [columnResizingMode]="'widget'"
        [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
        [id]="'positionsGrid' + unicalGuid"
        [height]="'calc(100% - 116px)'"
        [width]="'100%'"
        #positionsGrid
        (onContentReady)="onPositionsGridReady()"
        [columnAutoWidth]="true"
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-selection
          mode="multiple"
          showCheckBoxesMode="always"
          selectAllMode="page"
        >
        </dxo-selection>
        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.name' | translate }}"
          [allowSorting]="false"
          dataField="ProductName"
          [renderAsync]="true"
          cellTemplate="ProductNameCell"
          headerCellTemplate="ProductNameHeader"
          [cssClass]="
            positionOrderBy == 'ProductName'
              ? 'sort-column-bg'
              : 'sort-column-muted'
          "
        >
          <div *dxTemplate="let data of 'ProductNameHeader'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchCriteria('ProductName')"
            >
              {{ "dataCollectors.positionsGrid.name" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="positionOrder === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>
          <div *dxTemplate="let data of 'ProductNameCell'">
            <div
              *ngIf="data.data.AmountToProceed === 0"
              class="collector-status"
              [ngClass]="collectorStates[2].className"
              [id]="'state' + data.rowIndex + unicalGuid"
            ></div>
            <div
              *ngIf="
                data.data.AmountToProceed !== 0 &&
                data.data.AmountToProceed !== data.data.Amount
              "
              class="collector-status"
              [ngClass]="collectorStates[1].className"
              [id]="'state' + data.rowIndex + unicalGuid"
            ></div>
            <div
              *ngIf="
                data.data.AmountToProceed !== 0 &&
                data.data.AmountToProceed === data.data.Amount
              "
              class="collector-status"
              [ngClass]="collectorStates[0].className"
              [id]="'state' + data.rowIndex + unicalGuid"
            ></div>

            {{ data.data.ProductName }}
          </div>
        </dxi-column>

        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.index' | translate }}"
          [allowSorting]="false"
          dataField="IndexCatalogue"
          [renderAsync]="true"
          headerCellTemplate="IndexCatalogueHeader"
          [cssClass]="
            positionOrderBy == 'IndexCatalogue'
              ? 'sort-column-bg'
              : 'sort-column-muted'
          "
        >
          <div *dxTemplate="let data of 'IndexCatalogueHeader'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchCriteria('IndexCatalogue')"
            >
              {{ "dataCollectors.positionsGrid.index" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="positionOrder === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>
        </dxi-column>

        <dxi-column
          caption="{{
            'dataCollectors.positionsGrid.tradingindex' | translate
          }}"
          [allowSorting]="false"
          dataField="IndexTrading"
          [renderAsync]="true"
          headerCellTemplate="IndexTradingHeader"
          [cssClass]="
            positionOrderBy == 'IndexTrading'
              ? 'sort-column-bg'
              : 'sort-column-muted'
          "
        >
          <div *dxTemplate="let data of 'IndexTradingHeader'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchCriteria('IndexTrading')"
            >
              {{ "dataCollectors.positionsGrid.tradingindex" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="positionOrder === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>
        </dxi-column>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.barcode' | translate }}"
          [allowSorting]="false"
          dataField="EAN"
          [hidingPriority]="9"
          [renderAsync]="true"
          headerCellTemplate="EANHeader"
          [cssClass]="
            positionOrderBy == 'EAN' ? 'sort-column-bg' : 'sort-column-muted'
          "
        >
          <div *dxTemplate="let data of 'EANHeader'">
            <div class="grid-header-wrapper" (click)="setSearchCriteria('EAN')">
              {{ "dataCollectors.positionsGrid.barcode" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="positionOrder === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>
        </dxi-column>

        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.quantity' | translate }}"
          [allowSorting]="false"
          dataField="Amount"
          [hidingPriority]="8"
          [renderAsync]="true"
          cellTemplate="numberPrecisionTemplate"
        >
        </dxi-column>
        <dxi-column
          caption="{{
            'dataCollectors.positionsGrid.quantityToProcess' | translate
          }}"
          [allowSorting]="false"
          dataField="AmountToProceed"
          [hidingPriority]="7"
          [renderAsync]="true"
          cellTemplate="numberPrecisionTemplate"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.unit' | translate }}"
          [allowSorting]="false"
          dataField="Unit"
          [hidingPriority]="6"
          [renderAsync]="true"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.priceNet' | translate }}"
          [allowSorting]="false"
          dataField="PriceNet"
          [hidingPriority]="5"
          [renderAsync]="true"
          cellTemplate="PriceNetCell"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'PriceNetCell'">
          <p style="margin-bottom: 0px">
            {{ data.data.PriceNet | amountFormatter : data }}
          </p>
        </div>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.priceGross' | translate }}"
          [allowSorting]="false"
          dataField="PriceGross"
          [hidingPriority]="4"
          [renderAsync]="true"
          cellTemplate="PriceGrossCell"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'PriceGrossCell'">
          <p style="margin-bottom: 0px">
            {{ data.data.PriceGross | amountFormatter : data }}
          </p>
        </div>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.plu' | translate }}"
          [allowSorting]="false"
          dataField="CodePLU"
          [hidingPriority]="3"
          [renderAsync]="true"
          headerCellTemplate="PLUHeader"
          [cssClass]="
            positionOrderBy == 'CodePLU'
              ? 'sort-column-bg'
              : 'sort-column-muted'
          "
        >
          <div *dxTemplate="let data of 'PLUHeader'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchCriteria('CodePLU')"
            >
              {{ "dataCollectors.positionsGrid.plu" | translate }}
              <i
                class="header-sort-icon"
                [ngClass]="positionOrder === 'DESC' ? 'arr-down' : 'arr-up'"
              ></i>
            </div>
          </div>
          >
        </dxi-column>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.warehouse' | translate }}"
          [allowSorting]="false"
          dataField="WarehouseSymbol"
          [hidingPriority]="2"
          [renderAsync]="true"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.state' | translate }}"
          [allowSorting]="false"
          dataField="State"
          [hidingPriority]="1"
          [renderAsync]="true"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'dataCollectors.positionsGrid.available' | translate }}"
          [allowSorting]="false"
          dataField="Stock"
          [hidingPriority]="0"
          [renderAsync]="true"
          cellTemplate="numberPrecisionTemplate"
        >
        </dxi-column>

        <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
          {{ cell.value | amountFormatter : cell : (withoutComma ? 0 : 4) }}
        </div>
      </dx-data-grid>

      <dx-tooltip
        *ngFor="let icon of sortingIcons.asc"
        [target]="icon"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSorting.asc" | translate }}
        </div>
      </dx-tooltip>
      <dx-tooltip
        *ngFor="let icon of sortingIcons.desc"
        [target]="icon"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSorting.desc" | translate }}
        </div>
      </dx-tooltip>
      <dx-context-menu
        [dataSource]="positionsContextMenu"
        [width]="200"
        [target]="'#positionsGrid' + unicalGuid"
        (onItemClick)="positionsContextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
      </dx-context-menu>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #topButtonsPositions>
  <div class="top-buttons-container">
    <app-wapro-selected-box
      [(ngModel)]="selectedCollectorPositions"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      stylingMode="text"
      type="default"
      [disabled]="!focusedCollectorTransferId || readOnly || !perABD.addBtn"
      (onClick)="onAddingPosition()"
      [id]="'btn-addPosition' + unicalGuid"
    >
    </dx-button>

    <dx-button
      icon="icon absui-icon--mode-edit"
      [id]="'btn-editPosition' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="!focusedCollectorPositionKey || readOnly || !perABD.editBtn"
      stylingMode="text"
      type="default"
      (onClick)="onEditingPosition()"
    >
    </dx-button>

    <dx-button
      icon="icon absui-icon--highlight-off"
      [id]="'btn-deletePosition' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="!focusedCollectorPositionKey || readOnly || !perABD.editBtn"
      stylingMode="text"
      type="default"
      (onClick)="confirmDeletingPosition()"
    >
    </dx-button>
    <dx-button
      icon="icon absui-icon--file-out"
      [id]="'btn-send' + unicalGuid"
      [text]="'dataCollectors.buttons.send' | translate"
      class="btn-dx-top-menu"
      [disabled]="dataSourcePositions.length < 1"
      stylingMode="text"
      type="default"
      (onClick)="onSending()"
    >
    </dx-button>
    <div class="ml-auto d-flex align-items-center">
      <dx-button
        icon="icon icon-refresh"
        [id]="'btn-refresh' + unicalGuid"
        class="btn-dx-top-menu-folder"
        stylingMode="text"
        type="default"
        (onClick)="refresh()"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>
    </div>

    <dx-tooltip
      [target]="'#btn-addPosition' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-editPosition' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-deletePosition' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#btn-transmission-params' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "dataCollectors.buttons.transmissionParams" | translate }}
      </div>
    </dx-tooltip>
  </div>
</ng-template>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[0]"
    [selectedIndex]="localStorageData?.sidePanel?.selectedIndex"
    style="
      margin-top: -30px;
      height: 100%;
      width: 100%;
      padding-right: 10px;
      padding-left: 6px;
    "
    class="filter-panel right-panel-box documents-orders-step-5"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="false"
    #rightTabPanel
    itemTitleTemplate="title"
    class="no-title"
  >
    <div *dxTemplate="let item of 'title'">
      <!-- <div class="title-temp">
        <i
          class="icon absui-icon--filter"
          (click)="selectCategoryTab(0)"
          *ngIf="item == 0"
        ></i>
      </div> -->
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="0">
          <div style="width: calc(100% - 15px); padding-left: 5px">
            <app-filter-panel
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData.sidePanel.isVisible &&
                localStorageData.sidePanel.selectedIndex == 0
              "
              [panelWidth]="localStorageData.sidePanel.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            >
            </app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<div *ngIf="loadChildComponent">
  <app-collector-choice
    *ngIf="isCollectorChoiceVisible"
    [isVisible]="isCollectorChoiceVisible"
    [isPopupMode]="true"
    transferMode="transfer-in"
    (onClosing)="onClosingCollectorChoice($event)"
    (onReading)="onReadingCollectorChoice($event)"
  >
  </app-collector-choice>

  <app-confirm-dialog
    [isVisible]="isdeleteTransmissionVisible"
    (onClosing)="onClosingdeleteTransmission()"
    (onCanceling)="onCancelingdeleteTransmission()"
    (onRemoving)="onRemovingCollector()"
    [confirmText]="deleteTransmissionConfirmText"
  >
  </app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isDeleteCollectorPositionVisible"
    (onClosing)="onClosingDeleteCollectorPosition()"
    (onCanceling)="onCancelingDeleteCollectorPosition()"
    (onRemoving)="onRemovingCollectorPosition()"
    [confirmText]="deleteCollectorPositionConfirmText"
  >
  </app-confirm-dialog>

  <app-articles
    *ngIf="isAddPositionVisible"
    [isVisible]="isAddPositionVisible"
    [isPopupMode]="true"
    [readOnly]="readOnly"
    (onChoosed)="onPositionChoosed($event)"
    (onClosing)="isAddPositionVisible = false"
  >
  </app-articles>
  <app-new-article
    *ngIf="isShowArticleVisible"
    [isVisible]="isShowArticleVisible"
    [readOnly]="
      (articleMode === 'edit' && !articlePermission.editBtn) ||
      (articleMode === 'add' && !articlePermission.addBtn)
    "
    [selectedId]="[articleAttachedId]"
    [mode]="articleMode"
    (onClosing)="isShowArticleVisible = false"
    [componentNests]="null"
    [newCategoryId]="null"
    [newCategoryTreeId]="null"
    [isCopy]="false"
  >
  </app-new-article>

  <app-edit-collector-position
    *ngIf="isEditPositionVisible"
    [isVisible]="isEditPositionVisible"
    [isPopupMode]="true"
    [readOnly]="readOnly"
    [mode]="positionPopupMode"
    [collectorTransferId]="focusedCollectorTransferId"
    [collectorPositionId]="getCurrentPositionId()"
    (onSaved)="onPositionEdited()"
    (onClosed)="onPositionClosed()"
  >
  </app-edit-collector-position>

  <app-setup-wizard
    [isVisible]="isSetupWizardVisible"
    [config]="setupWizardConfig"
    (onClosing)="onSetupWizardClosing()"
    (onContinue)="onSetupWizardContinue()"
    (onLinkClick)="onSetupWizardLinkClick()"
  ></app-setup-wizard>
</div>

<!-- <wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [(disabled)]="!visible"
  [unicalGuid]="'data-collector-popup' + unicalGuid"
></wapro-keyboard-shortcuts> -->
