<div class="top-menu-panel">
  <ng-container *ngTemplateOutlet="topButtons"></ng-container>
</div>

<div class="center-panel-scroll-verticall">
  <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</div>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <app-wapro-selected-box
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      [selectedAll]="isAllSelected"
      [(ngModel)]="selectedRows"
      [selectedAmount]="selectedAmount()"
      [dataSource]="dataSource"
    ></app-wapro-selected-box>

    <dx-button
      class="btn-dx-top-menu-folder"
      stylingMode="text"
      type="default"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      (onClick)="addNewRoutes()"
      [disabled]="readOnly"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    >
    </dx-button>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
      (onItemClick)="contextMenuClick($event)"
    >
    </app-mobile-list>
  </div>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <app-wapro-selected-box
      [selectedAll]="isAllSelected"
      [(ngModel)]="selectedRows"
      [selectedAmount]="selectedAmount()"
      [dataSource]="dataSource"
    ></app-wapro-selected-box>

    <div style="width: 473px">
      <dx-button
        class="btn-dx-top-menu-folder"
        stylingMode="text"
        type="default"
        icon="icon absui-icon--add-circle"
        [id]="'btn-add' + unicalGuid"
        (onClick)="addNewRoutes()"
        [disabled]="readOnly || !this.routePermission.buttonsPer.addBtn"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        stylingMode="text"
        type="default"
        [id]="'btn-edit' + unicalGuid"
        (onClick)="editRoutes()"
        [disabled]="readOnly || focusedSelected.length === 0 || !this.routePermission.buttonsPer.editBtn"
        (disabledChange)="disabledChange($event)"
      >
      </dx-button>

      <dx-button
        (disabledChange)="disabledChange($event)"
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        stylingMode="text"
        type="default"
        class="btn-dx-top-menu-folder"
        [disabled]="focusedSelected.length == 0 || readOnly || !this.routePermission.buttonsPer.deleteBtn"
        (onClick)="confirmDeleteRoutesVisible = true"
      >
      </dx-button>

      <dx-button
        text="{{ 'routes.routePreview' | translate }}"
        [id]="'btn-route' + unicalGuid"
        stylingMode="text"
        type="default"
        class="btn-dx-top-menu"
        icon="icon absui-icon--map-pin"
        [disabled]="
          focusedSelected.length == 0 ||
          readOnly ||
          dataRoutesPosition.length == 0 ||
          !isData
        "
        (onClick)="routeMapVisible = true"
      >
      </dx-button>

      <!--      <dx-button-->
      <!--        text="{{ 'routes.routeUsers' | translate }}"-->
      <!--        [id]="'btn-routeUsers' + unicalGuid"-->
      <!--        stylingMode="text"-->
      <!--        type="default"-->
      <!--        class="btn-dx-top-menu"-->
      <!--        icon="icon absui-icon&#45;&#45;patch-map"-->
      <!--        [disabled]="-->
      <!--          focusedSelected.length == 0 || readOnly-->
      <!--        "-->
      <!--        (onClick)="routeUsersVisible = true"-->
      <!--      >-->
      <!--      </dx-button>-->
    </div>

    <div class="right-header-btn d-flex">
      <div>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          stylingMode="text"
          type="default"
          (click)="getRoutes()"
          style="margin-right: 6px"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>

        <dx-button
          class="btn-dx-top-menu-folder intrastate-step-3"
          icon="column-chooser"
          stylingMode="text"
          type="default"
          (click)="openColumnsChooser()"
          *ngIf="routePermission.columnConfiguration"
          [id]="'btn-columns' + unicalGuid"
        >
        </dx-button>
      </div>
    </div>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#btn-routeUsers' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "routes.routeUsers" | translate }} (ctrl + U)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#btn-refresh' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "refresh" | translate }}
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <app-custom-dropdown-box
        [openDropDown]="openCustomDropDownMain"
        [items]="filterCriteriaMain"
        [selectedItem]="
          localStorageData.filter.orderBy
            ? localStorageData.filter.orderBy
            : 'Name'
        "
        [(filterValue)]="filterValueMain"
        (onValueChanged)="onFilterDataChangedMain($event)"
        style="margin-right: 5px"
        #searchControl
      >
      </app-custom-dropdown-box>
      <app-date-range
        [readOnly]="readOnly"
        [isPopupMode]="true"
        (onChoosed)="getRoutes()"
        #dateRange
        [chipsVisible]="true"
      >
      </app-date-range>
      <div>
        <dx-data-grid
          class="mainGrid"
          [dataSource]="dataSource"
          [remoteOperations]="true"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          [(selectedRowKeys)]="selectedRows"
          [autoNavigateToFocusedRow]="true"
          [columnResizingMode]="'widget'"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onRowDblClick)="onRowDblClick()"
          [id]="'grid' + unicalGuid"
          (onSelectionChanged)="onSelectionChanged($event)"
          [(focusedRowIndex)]="focusedRowIndex"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [height]="heightGrid"
          [focusedRowEnabled]="true"
          [hoverStateEnabled]="true"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onOptionChanged)="onOptionChanged($event)"
          [allowColumnReordering]="true"
          (onKeyDown)="onKeyDown($event)"
          (onInitialized)="onInitialized()"
          #gridBank
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling
            mode="virtual"
            rowRenderingMode="virtual"
          ></dxo-scrolling>
          <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
          <dxo-selection
            [mode]="'multiple'"
            showCheckBoxesMode="always"
            selectAllMode="page"
          >
          </dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
          <dxo-column-fixing [enabled]="false"></dxo-column-fixing>

          <dxi-column
            caption="{{ 'routes.routeName' | translate }}"
            [allowSorting]="false"
            dataField="Name"
            cellTemplate="mainColumn"
            width="300"
            [cssClass]="
              localStorageData.filter.orderBy == 'Name'
                ? 'sort-column-bg'
                : 'sort-column-muted'
            "
            headerCellTemplate="sortingHeaderMainTemplate"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'mainColumn'">
            <div class="d-flex align-items-center">
              <i
                id="directions-car"
                style="margin-right: 6px"
                [ngClass]="{ disabled: isDisabled() }"
                class="icon absui-icon--map-pin icon-tag icon-tag_gray-car"
                (click)="!isDisabled() ? (routeMapVisible = true) : null"
              ></i>
              <span class="hov-underline" (click)="editRoutes()">{{
                data.data.Name
              }}</span>
            </div>
            <dx-tooltip
              target="#directions-car"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "routes.routePreview" | translate }}
              </div>
            </dx-tooltip>
          </div>

          <dxi-column
            *ngIf="dataRoutesPosition.length > 0"
            caption="{{ 'gdpr.implementation' | translate }}"
            [allowSorting]="false"
            [cssClass]="'sorting-unavailable'"
            width="200"
            cellTemplate="implementation"
            alignment="left"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'implementation'">
            <div *ngIf="data.data.CountPos">
              <dx-progress-bar
                style="display: inline-block"
                width="70%"
                [min]="0"
                [max]="100"
                [value]="(data.data.CountRegType2 / data.data.CountPos) * 100"
              >
              </dx-progress-bar>
              <div class="dx-progressbar-percent">
                {{ data.data.CountRegType2 }} / {{ data.data.CountPos }}
              </div>
            </div>
          </div>

          <dxi-column
            caption="{{ 'intrastate-documents.date' | translate }}"
            [allowSorting]="false"
            dataField="RouteDate"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            alignment="left"
            [width]="100"
            [cssClass]="
              localStorageData.filter.orderBy == 'RouteDate'
                ? 'sort-column-bg'
                : 'sort-column-muted'
            "
            headerCellTemplate="sortingHeaderMainTemplate"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-financial-document.description' | translate }}"
            [allowSorting]="false"
            [cssClass]="'sorting-unavailable'"
            dataField="Description"
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole1'].Name"
            dataField="Field1"
            [allowSorting]="false"
            [cssClass]="'sorting-unavailable'"
            width="100"
            *ngIf="
              userExtensionColumns['Pole1'] &&
              userExtensionColumns['Pole1'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole2'].Name"
            dataField="Field2"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole2'] &&
              userExtensionColumns['Pole2'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole3'].Name"
            dataField="Field3"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole3'] &&
              userExtensionColumns['Pole3'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole4'].Name"
            dataField="Field4"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole4'] &&
              userExtensionColumns['Pole4'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole5'].Name"
            dataField="Field5"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole5'] &&
              userExtensionColumns['Pole5'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole6'].Name"
            dataField="Field6"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole6'] &&
              userExtensionColumns['Pole6'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole7'].Name"
            dataField="Field7"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole7'] &&
              userExtensionColumns['Pole7'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole8'].Name"
            dataField="Field8"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole8'] &&
              userExtensionColumns['Pole8'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole9'].Name"
            dataField="Field9"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole9'] &&
              userExtensionColumns['Pole9'].IsActive
            "
          >
          </dxi-column>

          <dxi-column
            [caption]="userExtensionColumns['Pole10'].Name"
            dataField="Field10"
            [allowSorting]="false"
            width="100"
            *ngIf="
              userExtensionColumns['Pole10'] &&
              userExtensionColumns['Pole10'].IsActive
            "
          >
          </dxi-column>

          <div *dxTemplate="let cell of 'sortingHeaderMainTemplate'">
            <div
              class="grid-header-wrapper"
              (click)="setSearchMainCriteria(cell.column.dataField)"
            >
              {{ cell.column.caption }}
              <i
                class="header-sort-icon"
                [ngClass]="
                  localStorageData.filter.order === 'DESC'
                    ? 'arr-down'
                    : 'arr-up'
                "
              ></i>
            </div>
          </div>
        </dx-data-grid>
      </div>
      <div
        style="padding-left: 0"
        class="bottom-side-panel"
        mwlResizable
        #bottomSidePanel
        (resizeStart)="onResizeStartBottom()"
        (resizeEnd)="resizeEndBottom()"
        (resizing)="resizingBottom($event)"
      >
        <div
          class="resize-handle-top"
          mwlResizeHandle
          [resizeEdges]="{ top: true }"
          (mouseleave)="mouseLeaveDown()"
          (mouseenter)="mouseEnterDown()"
        ></div>
        <div class="col-md-12 text-right resize-bottop-panel">
          <i
            class="icon bottom-panel-icon"
            mwlResizeHandle
            [resizeEdges]="{ top: true }"
            [ngClass]="{
              'absui-icon--arrow-drop-down bottom-icon-resize-down':
                localStorageData.bottomPanel.isVisible,
              'absui-icon--arrow-drop-up bottom-icon-resize-up':
                !localStorageData.bottomPanel.isVisible
            }"
            (mousedown)="mousedown()"
            (mouseup)="mouseup()"
            (touchend)="mouseup()"
            (touchstart)="mousedown()"
          ></i>
        </div>
        <div class="col-md-12">
          <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #bottomTab>
  <dx-tab-panel
    [scrollByContent]="true"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    #tabPanel
    width="100%"
    [items]="bottomPanelTabs"
    [(selectedIndex)]="selectedIndex"
    [swipeEnabled]="false"
    [animationEnabled]="false"
    [ngClass]="
      dataRoutesPosition.length != 0
        ? 'tab-panel-route-mobile'
        : 'tab-panel-route-mobile-empty'
    "
    class="tab-panel-document"
  >
    <div *dxTemplate="let name of 'item'">
      <ng-container
        [ngSwitch]="name"
        *ngIf="localStorageData.bottomPanel.isVisible"
      >
        <div *ngSwitchCase="'routes.routeDetails' | translate">
          <div style="margin-bottom: 6px">
            <app-wapro-selected-box
              [(ngModel)]="selectedPositionDocument"
              [dataSource]="positionList"
              [selectedAll]="isAllSelectedPosition"
              [selectedAmount]="selectedAmountPosition()"
            >
            </app-wapro-selected-box>

            <dx-button
              *ngIf="isData || !!localStorageData.bottomPanelFilter.value"
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--add-circle"
              [text]="'buttons.add' | translate"
              (onClick)="addNewContractors()"
              [id]="'btn-add-position' + unicalGuid"
              [disabled]="readOnly || focusedSelected.length === 0 || !routePermission.contractorsUpdate"
            >
            </dx-button>

            <dx-button
              [ngStyle]="
                event.deviceType === 'mobile'
                  ? { 'margin-left': '-12px' }
                  : { 'margin-left': '0px' }
              "
              *ngIf="event.deviceType === 'mobile'"
              text="{{ 'routes.routePreview' | translate }}"
              [id]="'btn-route' + unicalGuid"
              stylingMode="text"
              type="default"
              class="btn-dx-top-menu"
              icon="icon absui-icon--map-pin"
              [disabled]="
                focusedSelected.length == 0 ||
                readOnly ||
                dataRoutesPosition.length == 0 ||
                !isData
              "
              (onClick)="routeMapVisible = true"
            >
            </dx-button>

            <dx-button
              *ngIf="
                (isData || !!localStorageData.bottomPanelFilter.value) &&
                event.deviceType != 'mobile'
              "
              (disabledChange)="disabledChangeDetail($event)"
              data-cy="intrastate_delete-position-btn"
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--highlight-off"
              [text]="'buttons.delete' | translate"
              (onClick)="confirmDeleteRoutesContractorVisible = true"
              [id]="'btn-delete-position' + unicalGuid"
              [disabled]="
                readOnly ||
                focusedSelected.length === 0 ||
                focusedSelected2.length === 0 ||
                !routePermission.contractorsUpdate
              "
            >
            </dx-button>
            <dx-button
              *ngIf="
                (isData || !!localStorageData.bottomPanelFilter.value) &&
                event.deviceType != 'mobile'
              "
              (disabledChange)="disabledChangeDetail($event)"
              class="btn-dx-top-menu"
              icon="icon absui-icon--location-edit"
              [text]="'routes.changeAddressOnRoute' | translate"
              (onClick)="editingRouteAddressVisible = true"
              [id]="'btn-editingRouteAddress' + unicalGuid"
              [disabled]="
                readOnly ||
                focusedSelected.length === 0 ||
                focusedSelected2.length === 0
              "
            >
            </dx-button>

            <dx-drop-down-button
              class="route-status"
              width="40"
              *ngIf="
                (isData || !!localStorageData.bottomPanelFilter.value) &&
                event.deviceType != 'mobile'
              "
              icon="icon absui-icon--tag-edit"
              [items]="statuses"
              stylingMode="text"
              (onItemClick)="onItemClickStatus($event)"
              type="default"
              itemTemplate="fontItem"
              [id]="'btn-changingTheRouteStatus' + unicalGuid"
              [disabled]="
                readOnly ||
                focusedSelected.length === 0 ||
                focusedSelected2.length === 0
              "
            >
              <div *dxTemplate="let data of 'fontItem'">
                <span
                  title="{{ data.text | translate }}"
                  style="padding-left: 26px"
                  [ngClass]="{
                    'selected-status':
                      focusedSelected2[0].RegistrationType === data.value
                  }"
                >
                  {{ data.text | translate }}
                </span>
              </div>
            </dx-drop-down-button>

            <!--                        <dx-select-box-->
            <!--                          [useSelectMode]="true"-->
            <!--                          [(value)]="selectedStatus"-->
            <!--                          [dataSource]="statuses"-->
            <!--                          displayExpr="text"-->
            <!--                          valueExpr="value"-->
            <!--                          placeholder="Wybierz status">-->
            <!--                        </dx-select-box>-->

            <!--            <dx-button-->
            <!--              *ngIf="isData || !!localStorageData.bottomPanelFilter.value"-->
            <!--              (disabledChange)="disabledChangeDetail($event)"-->
            <!--              class="btn-dx-top-menu-folder"-->
            <!--              icon="icon absui-icon&#45;&#45;tag-edit"-->
            <!--              [text]="'routes.changingTheRouteStatus' | translate"-->
            <!--              (onClick)="isVisibleChangeRouteStatus = true"-->
            <!--              [id]="'btn-changingTheRouteStatus' + unicalGuid"-->
            <!--              [disabled]="-->
            <!--                readOnly ||-->
            <!--                focusedSelected.length === 0 ||-->
            <!--                focusedSelected2.length === 0"-->
            <!--            >-->
            <!--            </dx-button>-->

            <dx-button
              *ngIf="
                (isData || !!localStorageData.bottomPanelFilter.value) &&
                event.deviceType != 'mobile'
              "
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--trend-up"
              stylingMode="text"
              type="default"
              [id]="'btn-trend-up' + unicalGuid"
              (onClick)="moveOrder('up')"
              [disabled]="
                readOnly ||
                focusedSelected.length === 0 ||
                focusedSelected2.length === 0
              "
            >
            </dx-button>

            <dx-button
              *ngIf="
                (isData || !!localStorageData.bottomPanelFilter.value) &&
                event.deviceType != 'mobile'
              "
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--trend-down"
              stylingMode="text"
              type="default"
              [id]="'btn-trend-down' + unicalGuid"
              (onClick)="moveOrder('down')"
              [disabled]="
                readOnly ||
                focusedSelected.length === 0 ||
                focusedSelected2.length === 0
              "
            >
            </dx-button>

            <app-additional-operation
              *ngIf="isData || !!localStorageData.bottomPanelFilter.value"
              nameComponent="Routes"
              (onItemClick)="contextMenuClickDetail($event)"
              (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
            >
            </app-additional-operation>

            <dx-tooltip
              [target]="'#btn-trend-up' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "routes.moveOrderUp" | translate }} (Ctrl + Up)
              </div>
            </dx-tooltip>
            <dx-tooltip
              [target]="'#btn-trend-down' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "routes.moveOrderDown" | translate }} (Ctrl + Down)
              </div>
            </dx-tooltip>
            <dx-tooltip
              [target]="'#btn-changingTheRouteStatus' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "routes.changingTheRouteStatus" | translate }} (Ctrl + S)
              </div>
            </dx-tooltip>

            <dx-tooltip
              [target]="'#btn-add-position' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "buttons.add" | translate }} (Ctrl + Insert)
              </div>
            </dx-tooltip>
            <dx-tooltip
              [target]="'#btn-editingRouteAddress' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "routes.changeAddressOnRoute" | translate }} (Ctrl + F2)
              </div>
            </dx-tooltip>
            <dx-tooltip
              [target]="'#btn-delete-position' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "buttons.delete" | translate }} (Ctrl + Del)
              </div>
            </dx-tooltip>
            <dx-tooltip
              [target]="'#btn-route' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "routes.route" | translate }} (ctrl + M)
              </div>
            </dx-tooltip>
          </div>

          <div
            *ngIf="
              !isData && !!localStorageData.bottomPanelFilter.value == false
            "
          >
            <ng-container *ngTemplateOutlet="emptyStateGird"></ng-container>
          </div>

          <app-custom-dropdown-box
            *ngIf="isData || !!localStorageData.bottomPanelFilter.value"
            [openDropDown]="openCustomDropDown"
            [items]="filterCriteria"
            [selectedItem]="
              localStorageData.bottomPanelFilter.orderBy
                ? localStorageData.bottomPanelFilter.orderBy
                : 'Orders'
            "
            [(filterValue)]="localStorageData.bottomPanelFilter.value"
            (onValueChanged)="onFilterDataChanged($event)"
            style="margin-right: 5px"
          >
          </app-custom-dropdown-box>

          <ng-container *ngIf="event.deviceType === 'mobile'">
            <dx-list
              (onItemClick)="onItemClickContractorsDetail($event)"
              class="route-mobile"
              #contractorsMobileList
              [dataSource]="dataSourceDetail"
              keyExpr="RoutePosId"
            >
              <dxo-item-dragging
                [height]="'100%'"
                class="timeline-list"
                [allowReordering]="true"
                [onDragStart]="onDragStart"
                [onAdd]="onAdd"
                [onRemove]="onRemove"
                [onReorder]="onReorder"
              >
                <div
                  class="d-flex justify-content-center align-items-center"
                  *dxTemplate="let data of 'item'"
                >
                  <div class="time-line-text time-line-text_orders">
                    {{ data.Orders }}
                  </div>
                  <!--                  <div class="time-line">-->
                  <!--                  </div>-->
                  <div class="time-line">
                    <div class="time-line-text time-line-text_title">
                      {{
                        data.Name.length >= 29
                          ? (data.Name | slice : 0 : 29) + " ..."
                          : data.Name
                      }}
                    </div>
                    <div class="time-line-text time-line-text_desc">
                      {{ data.DeliveryPostalCode }} {{ data.DeliveryCity }}
                    </div>
                    <div class="time-line-text time-line-text_desc">
                      {{ data.DeliveryStreet }} {{ data.DeliveryHoNr }}
                      {{ data.DeliveryApNr ? "/" : "" }} {{ data.DeliveryApNr }}
                    </div>
                    <div
                      style="display: inline-block"
                      class="status"
                      [ngClass]="{
                        O: data.RegistrationType == 0,
                        R: data.RegistrationType == 1,
                        Z: data.RegistrationType == 2,
                        A: data.RegistrationType == 3
                      }"
                    >
                      {{ data.RegistrationType | statusRouteText | translate }}
                    </div>
                  </div>
                  <div
                    style="
                      top: calc(100% - 34px);
                      left: calc(100% - 37px);
                      position: absolute;
                    "
                  >
                    <dx-button
                      class="btn-dx-top-menu-folder"
                      icon="icon absui-icon--more-horiz"
                      (onClick)="isContextMenuDetailMobile = true"
                    ></dx-button>
                  </div>
                </div>
              </dxo-item-dragging>
            </dx-list>
          </ng-container>

          <app-mobile-list
            [dataSource]="contextMenuDetailMobile"
            [visible]="isContextMenuDetailMobile"
            (onClosed)="isContextMenuDetailMobile = false"
            (onItemClick)="contextMenuClickDetail($event)"
          ></app-mobile-list>

          <dx-data-grid
            [visible]="
              (isData || !!localStorageData.bottomPanelFilter.value) &&
              event.deviceType != 'mobile'
            "
            [dataSource]="dataSourceDetail"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            [height]="heightBottomGrid"
            [(selectedRowKeys)]="selectedPositionDocument"
            [columnResizingMode]="'widget'"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            [(focusedRowIndex)]="focusedRowIndexPosition"
            [id]="'grid2' + unicalGuid"
            [focusedRowEnabled]="true"
            [hoverStateEnabled]="true"
            (onEditorPreparing)="onEditorPreparing($event)"
            (onSelectionChanged)="onSelectionPositionChanged($event)"
            #gridBank2
            shortcut
            (onOptionChanged)="event.onOptionChanged($event)"
            (onKeyDown)="onKeyDown2($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-selection
              [mode]="'multiple'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxo-paging [pageSize]="50"></dxo-paging>

            <dxi-column
              [minWidth]="50"
              caption="{{ 'routes.order' | translate }}"
              [allowSorting]="false"
              dataField="Orders"
              headerCellTemplate="sortingHeaderTemplate"
              [cssClass]="
                localStorageData.bottomPanelFilter.orderBy == 'Orders'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
            ></dxi-column>

            <!--            <dxi-column-->
            <!--              caption="{{ 'form-financial-document.number' | translate }}"-->
            <!--              [allowSorting]="false"-->
            <!--              dataField="CustomerNo"-->
            <!--              [width]="80"-->
            <!--              [cssClass]="-->
            <!--                    localStorageData.bottomPanelFilter.orderBy == 'CustomerNo'-->
            <!--                      ? 'sort-column-bg'-->
            <!--                      : 'sort-column-muted'-->
            <!--                  "-->
            <!--              headerCellTemplate="sortingHeaderTemplate"-->
            <!--            >-->
            <!--            </dxi-column>-->

            <dxi-column
              caption="{{ 'userExtensions.customers' | translate }}"
              [allowSorting]="false"
              dataField="Name"
              headerCellTemplate="sortingHeaderTemplate"
              [cssClass]="
                localStorageData.bottomPanelFilter.orderBy == 'Name'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
            ></dxi-column>

            <dxi-column
              caption="{{ 'production-documents.status' | translate }}"
              [allowSorting]="false"
              dataField="RegistrationType"
              cellTemplate="statusCell"
              headerCellTemplate="headerCellStatusTemplate"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'headerCellStatusTemplate'"
              class="text-center"
            >
              <div class="grid-header-wrapper">
                {{ "production-documents.status" | translate }}
              </div>
            </div>
            <div *dxTemplate="let data of 'statusCell'" class="text-center">
              <div
                class="status"
                [ngClass]="{
                  O: data.data.RegistrationType == 0,
                  R: data.data.RegistrationType == 1,
                  Z: data.data.RegistrationType == 2,
                  A: data.data.RegistrationType == 3
                }"
              >
                {{ data.data.RegistrationType | statusRouteText | translate }}
              </div>
            </div>

            <dxi-column
              [caption]="'routes.addressOnRoute' | translate"
              alignment="center"
            >
              <dxi-column
                caption="{{ 'routes.postalCodeRoute' | translate }}"
                [allowSorting]="false"
                dataField="DeliveryPostalCode"
              ></dxi-column>

              <dxi-column
                caption="{{ 'routes.cityRoute' | translate }}"
                [allowSorting]="false"
                dataField="DeliveryCity"
              ></dxi-column>

              <dxi-column
                caption="{{ 'routes.streetRoute' | translate }}"
                [allowSorting]="false"
                dataField="DeliveryStreet"
              ></dxi-column>

              <dxi-column
                caption="{{ 'routes.buildRoute' | translate }}"
                [allowSorting]="false"
                dataField="DeliveryHoNr"
              ></dxi-column>

              <dxi-column
                caption="{{ 'routes.apartmentRoute' | translate }}"
                [allowSorting]="false"
                dataField="DeliveryApNr"
              ></dxi-column>
            </dxi-column>

            <dxi-column
              [caption]="'routes.ContractorsAddress' | translate"
              alignment="center"
            >
              <dxi-column
                [allowSorting]="false"
                dataField="Street"
                [caption]="'companies.grid.columns.street' | translate"
              ></dxi-column>

              <dxi-column
                [allowSorting]="false"
                dataField="City"
                [caption]="'companies.grid.columns.city' | translate"
                headerCellTemplate="sortingHeaderTemplate"
                [cssClass]="
                  localStorageData.bottomPanelFilter.orderBy == 'City'
                    ? 'sort-column-bg'
                    : 'sort-column-muted'
                "
              ></dxi-column>

              <dxi-column
                caption="{{
                  'companies.form.addressDetail.voivodeship' | translate
                }}"
                dataField="Province"
                [allowEditing]="false"
                headerCellTemplate="sortingHeaderTemplate"
                [cssClass]="
                  localStorageData.bottomPanelFilter.orderBy == 'Province'
                    ? 'sort-column-bg'
                    : 'sort-column-muted'
                "
              ></dxi-column>
            </dxi-column>

            <!--            <dxi-column-->
            <!--              caption="NIP"-->
            <!--              [allowSorting]="false"-->
            <!--              dataField="VatNumber"-->
            <!--            >-->
            <!--            </dxi-column>-->

            <!--            <dxi-column-->
            <!--              caption="REGON"-->
            <!--              [allowSorting]="false"-->
            <!--              dataField="REGON"-->
            <!--            >-->
            <!--            </dxi-column>-->

            <!--            <dxi-column-->
            <!--              caption="{{-->
            <!--                    'companies.form.fullName' | translate-->
            <!--                  }}"-->
            <!--              [allowSorting]="false"-->
            <!--              dataField="FullName"-->
            <!--              width="300"-->
            <!--            ></dxi-column>-->

            <!--            <dxi-column-->
            <!--              caption="{{ 'production-documents.remarks' | translate }}"-->
            <!--              dataField="Remarks"-->
            <!--              [allowSorting]="false"-->
            <!--            >-->
            <!--            </dxi-column>-->

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole1'].Name"
              dataField="Field1"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole1'] &&
                userExtensionColumnsPos['Pole1'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole2'].Name"
              dataField="Field2"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole2'] &&
                userExtensionColumnsPos['Pole2'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole3'].Name"
              dataField="Field3"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole3'] &&
                userExtensionColumnsPos['Pole3'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole4'].Name"
              dataField="Field4"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole4'] &&
                userExtensionColumnsPos['Pole4'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole5'].Name"
              dataField="Field5"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole5'] &&
                userExtensionColumnsPos['Pole5'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole6'].Name"
              dataField="Field6"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole6'] &&
                userExtensionColumnsPos['Pole6'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole7'].Name"
              dataField="Field7"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole7'] &&
                userExtensionColumnsPos['Pole7'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole8'].Name"
              dataField="Field8"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole8'] &&
                userExtensionColumnsPos['Pole8'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole9'].Name"
              dataField="Field9"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole9'] &&
                userExtensionColumnsPos['Pole9'].IsActive
              "
            >
            </dxi-column>

            <dxi-column
              [caption]="userExtensionColumnsPos['Pole10'].Name"
              dataField="Field10"
              [allowSorting]="false"
              width="100"
              *ngIf="
                userExtensionColumnsPos['Pole10'] &&
                userExtensionColumnsPos['Pole10'].IsActive
              "
            >
            </dxi-column>

            <div *dxTemplate="let cell of 'sortingHeaderTemplate'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria(cell.column.dataField)"
              >
                {{ cell.column.caption }}
                <i
                  class="header-sort-icon"
                  [ngClass]="
                    localStorageData.bottomPanelFilter.order === 'DESC'
                      ? 'arr-down'
                      : 'arr-up'
                  "
                ></i>
              </div>
            </div>
          </dx-data-grid>
        </div>
        <div *ngSwitchCase="'menu.users' | translate">
          <div style="margin-bottom: 6px">
            <div>
              <dx-button
                class="btn-dx-top-menu-folder"
                stylingMode="text"
                type="default"
                icon="icon absui-icon--add-circle"
                [id]="'btn-add-user' + unicalGuid"
                (onClick)="addUserRoute()"
                [disabled]="readOnly || focusedSelected.length === 0 || !routePermission.usersUpdate"
              >
              </dx-button>
              <dx-button
                icon="icon absui-icon--highlight-off"
                [id]="'btn-delete-user' + unicalGuid"
                stylingMode="text"
                type="default"
                class="btn-dx-top-menu-folder"
                [disabled]="focusedSelectedUser.length == 0 || readOnly || !routePermission.usersUpdate"
                (onClick)="confirmDeleteRoutesUserVisible = true"
              >
              </dx-button>
              <dx-tooltip
                [target]="'#btn-add-user' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "buttons.add" | translate }} (Ctrl + Insert)
                </div>
              </dx-tooltip>
              <dx-tooltip
                [target]="'#btn-delete-user' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "buttons.delete" | translate }} (Ctrl + Del)
                </div>
              </dx-tooltip>
            </div>
            <dx-list
              [indicateLoading]="false"
              class="route-users"
              [visible]="event.deviceType === 'mobile'"
              #list
              [dataSource]="dataSourceUsers"
              [height]="'100%'"
              [searchEnabled]="false"
              selectionMode="single"
              selectAllMode="allPages"
              [showSelectionControls]="true"
            >
              <div
                style="
                  padding: 12px;
                  margin-top: 8px;
                  border-bottom: 1px solid var(--neutral-80);
                "
                class="d-flex justify-content-between align-items-center"
                *dxTemplate="let data of 'item'"
              >
                <div>
                  <div class="user user_title">
                    {{ data.UserName }}
                  </div>
                  <div class="user user_desc">
                    {{ data.Ident }}
                  </div>
                </div>
                <div
                  class="right-mobile-grid text-center"
                  [ngClass]="{
                    'right-mobile-grid-selected':
                      modeSelectionMobile == 'multiple'
                  }"
                >
                  <dx-button
                    class="btn-dx-top-menu-folder"
                    icon="icon absui-icon--more-horiz"
                    style="
                      float: left;
                      position: relative;
                      top: calc(50% - 13px);
                    "
                    (onClick)="isContextUsersMenuMobile = true"
                  ></dx-button>
                </div>
              </div>
            </dx-list>
            <app-mobile-list
              [dataSource]="contextMenuUsersOperationsMobile"
              [visible]="isContextUsersMenuMobile"
              (onClosed)="isContextUsersMenuMobile = false; cd.detectChanges()"
              (onItemClick)="contextUsersMenuMobile($event)"
            >
            </app-mobile-list>
            <dx-data-grid
              class="mainGrid"
              [visible]="event.deviceType != 'mobile'"
              [dataSource]="dataSourceUsers"
              [remoteOperations]="true"
              [wordWrapEnabled]="false"
              [showBorders]="true"
              [autoNavigateToFocusedRow]="true"
              [columnResizingMode]="'widget'"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [id]="'grid3' + unicalGuid"
              [(focusedRowIndex)]="focusedRowIndexUsers"
              (onFocusedRowChanged)="onFocusedRowChangedUser($event)"
              [height]="heightBottomGrid"
              [focusedRowEnabled]="true"
              [hoverStateEnabled]="true"
              [allowColumnReordering]="true"
              #gridBank3
            >
              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxo-scrolling
                mode="virtual"
                rowRenderingMode="virtual"
              ></dxo-scrolling>
              <dxi-column
                caption="{{ 'users.identyficator' | translate }}"
                [allowSorting]="false"
                dataField="Ident"
                width="300"
              >
              </dxi-column>

              <dxi-column
                caption="{{ 'users.userName' | translate }}"
                [allowSorting]="false"
                [cssClass]="'sorting-unavailable'"
                dataField="UserName"
              >
              </dxi-column>
            </dx-data-grid>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #emptyStateGird>
  <div
    class="text-center"
    [ngStyle]="{
      'height.px': heightBottomGrid
    }"
    style="display: flex; justify-content: center; align-items: center"
    [id]="'grid2' + unicalGuid"
  >
    <dx-scroll-view width="100%" [height]="'100%'" direction="vertical">
      <div class="inside">
        <img
          src="../../../assets/img/Empty-state/empty-state-position-route.svg"
          alt="empty-state-grid"
        />
        <p class="noDataToDisplay" style="margin-bottom: 6px">
          {{ "gdpr.noPointsOnTheRoute" | translate }}
        </p>
        <p class="noDataToDisplayDscr">
          {{ "gdpr.addTheFirstContractor" | translate }}
        </p>

        <dx-button
          text="{{ 'gdpr.addContractor' | translate }}"
          icon="icon absui-icon--add-circle-visible"
          type="success"
          style="border: 2px !important"
          [width]="150"
          (onClick)="addNewContractors()"
          [disabled]="readOnly || focusedSelected.length === 0"
        ></dx-button>
      </div>
    </dx-scroll-view>
  </div>
</ng-template>

<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [openDropDown]="openCustomDropDownMain"
      [items]="filterCriteriaMain"
      [selectedItem]="
        localStorageData.filter.orderBy
          ? localStorageData.filter.orderBy
          : 'Name'
      "
      [(filterValue)]="filterValueMain"
      (onValueChanged)="onFilterDataChangedMain($event)"
      style="margin-right: 5px"
      #searchControl
    >
    </app-custom-dropdown-box>

    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getRoutes()"
      ></i>
    </dx-button>
  </div>
  <app-date-range
    [readOnly]="readOnly"
    [isPopupMode]="true"
    (onChoosed)="getRoutes()"
    #dateRange
    [chipsVisible]="true"
  >
  </app-date-range>
  <dx-data-grid
    [dataSource]="dataSource"
    [remoteOperations]="true"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    [(selectedRowKeys)]="selectedRows"
    [autoNavigateToFocusedRow]="true"
    [columnResizingMode]="'widget'"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    (onRowDblClick)="onRowDblClick()"
    [id]="'grid' + unicalGuid"
    (onSelectionChanged)="onSelectionChanged($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [height]="heightGrid"
    [focusedRowEnabled]="true"
    [hoverStateEnabled]="true"
    (onEditorPreparing)="onEditorPreparing($event)"
    [allowColumnReordering]="true"
    (onKeyDown)="onKeyDown($event)"
    class="main-mobile-grid"
    #gridBank
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="virtual"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    >
    </dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    >
    </dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex">
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <div>
            <span class="hov-underline-mobile" (click)="editRoutes()"
              >{{ data.data.Name }}
            </span>
          </div>
          <div *ngIf="data.data.CountPos">
            <dx-progress-bar
              style="display: inline-block"
              width="75%"
              [min]="0"
              [max]="100"
              [value]="(data.data.CountRegType2 / data.data.CountPos) * 100"
            >
            </dx-progress-bar>
            <div class="dx-progressbar-percent">
              {{ data.data.CountRegType2 }} / {{ data.data.CountPos }}
            </div>
          </div>
          <div>
            <label class="mobile-grid-label"
              >{{ "intrastate-documents.date" | translate }}:</label
            >
            <p class="mobile-grid-text">
              {{ data.data.RouteDate | date : event.dateFormat }}
            </p>
          </div>
          <div>
            <label class="mobile-grid-label"
              >{{ "form-financial-document.description" | translate }}:</label
            >
            <p class="mobile-grid-text">
              {{ data.data.Description }}
            </p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole1'] &&
              userExtensionColumns['Pole1'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole1"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field1 }}</p>
          </div>
          <div *ngIf="userExtensionColumns['Pole2'].IsActive">
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole2"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field2 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole3'] &&
              userExtensionColumns['Pole3'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole3"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field3 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole4'] &&
              userExtensionColumns['Pole4'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole4"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field4 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole5'] &&
              userExtensionColumns['Pole5'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole5"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field5 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole6'] &&
              userExtensionColumns['Pole6'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole6"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field6 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole7'] &&
              userExtensionColumns['Pole7'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole7"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field7 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole8'] &&
              userExtensionColumns['Pole8'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole8"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field8 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole9'] &&
              userExtensionColumns['Pole9'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole9"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field9 }}</p>
          </div>
          <div
            *ngIf="
              userExtensionColumns['Pole10'] &&
              userExtensionColumns['Pole10'].IsActive
            "
          >
            <label class="mobile-grid-label"
              >{{ userExtensionColumns["Pole10"].Name }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Field10 }}</p>
          </div>
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="isContextMenuMobile = true"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobile"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>
</ng-template>

<app-users
  *ngIf="addUserVisible"
  (onClosed)="addUserVisible = false; gridBank3.instance.focus()"
  [popUpMode]="true"
  [isVisible]="addUserVisible"
  [title]="'routes.routeUsersTitle'"
  (onChoosed)="onChoosedUser($event)"
></app-users>

<app-confirm-dialog
  [confirmText]="'routes.deleteConfirmRouteUser'"
  [isVisible]="confirmDeleteRoutesUserVisible"
  [kindDialog]="'question'"
  btnConfig="yesno"
  (onClosing)="confirmDeleteRoutesUserVisible = false"
  (onRemoving)="
    onDeleteUserRoutes(); this.confirmDeleteRoutesUserVisible = false
  "
>
</app-confirm-dialog>

<app-new-route
  [isVisible]="newRouteVisible"
  [readOnly]="readOnly"
  [mode]="mode"
  [focusedSelected]="focusedSelected"
  (onClosing)="newRouteVisible = false; gridBank.instance.focus()"
  (updateData)="updateRoutes($event)"
  (onSaveRouteMobileEmitter)="onSaveRouteMobileEmitter($event)"
></app-new-route>

<app-route-users
  [isVisible]="routeUsersVisible"
  [focusedSelected]="focusedSelected"
  (onClosing)="routeUsersVisible = false"
>
</app-route-users>

<app-confirm-dialog
  [confirmHeader]="'gdpr.selectedContractorNoAddress'"
  [confirmText]="'gdpr.addAddressOrUpdateProfile'"
  [isVisible]="confirmAddCustomerWithoutAddress"
  [kindDialog]="'warning'"
  btnConfig="close"
  (onClosing)="closingConfirmCustomerWithoutAddress()"
>
</app-confirm-dialog>

<app-contractors
  [popUpMode]="true"
  [selectedId]="focusedSelected2[0]?.CustomerId"
  [isVisible]="isCustomerVisible"
  (onClosed)="isCustomerVisible = false; gridBank2.instance.focus()"
  (onChoosed)="onChoosedCustomer($event)"
  (multipleSelectCustomers)="multipleSelectCustomers = $event"
></app-contractors>

<app-confirm-dialog
  [confirmText]="'routes.deleteRoute'"
  [isVisible]="confirmDeleteRoutesVisible"
  [kindDialog]="'question'"
  btnConfig="yesno"
  (onClosing)="cancelConfirmDeleteRoutes(); confirmDeleteRoutesVisible = false"
  (onRemoving)="onDeleteRoutesVisible()"
>
</app-confirm-dialog>

<app-routes-map
  *ngIf="routeMapVisible"
  [addresses]="addresses"
  [readOnly]="readOnly"
  [selectedRoute]="focusedSelected"
  [isVisible]="routeMapVisible"
  (onClosing)="routeMapVisible = false"
>
</app-routes-map>

<app-edit-address-route
  *ngIf="editingRouteAddressVisible"
  [readOnly]="readOnly"
  [isVisible]="editingRouteAddressVisible"
  (onClosing)="editingRouteAddressVisible = false; dataSourceDetail.reload()"
  [selectedContractor]="focusedSelected2"
>
</app-edit-address-route>

<app-confirm-dialog
  [confirmText]="'routes.deleteConfirmRouteContractor'"
  [isVisible]="confirmDeleteRoutesContractorVisible"
  [kindDialog]="'question'"
  btnConfig="yesno"
  (onClosing)="
    cancelConfirmDeleteRoutesContractor();
    confirmDeleteRoutesContractorVisible = false
  "
  (onRemoving)="
    deleteContractors(); this.confirmDeleteRoutesContractorVisible = false
  "
>
</app-confirm-dialog>

<dx-context-menu
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
  [dataSource]="contextMenu()"
  [target]="'#grid' + unicalGuid"
  [width]="200"
>
</dx-context-menu>

<dx-context-menu
  (onItemClick)="contextMenuClickDetail($event)"
  (onItemRendered)="event.onItemRendered($event)"
  [dataSource]="contextMenuDetail()"
  [target]="'#grid2' + unicalGuid"
  [width]="200"
>
</dx-context-menu>

<dx-popup
  [height]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.height
      : mobileHeight
  "
  [width]="'100%'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [visible]="isDetailRouteMobileVisible"
  (visibleChange)="visibleChangeDetailRouteMobile()"
  [resizeEnabled]="true"
  [showTitle]="false"
  [dragEnabled]="true"
  [minHeight]="275"
  [maxHeight]="maxHeight"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div class="title-route-mobile-detail">
      <div class="d-flex align-items-center">
        <div (click)="isShowMoreMobileDetails = !isShowMoreMobileDetails">
          <i
            style="padding: 6px"
            class="icon"
            [ngClass]="{
              'absui-icon--expand-less': isShowMoreMobileDetails,
              'absui-icon--expand-more': !isShowMoreMobileDetails
            }"
          ></i>
        </div>
        <div class="title-route-mobile-detail_title">
          {{ focusedSelected[0]?.Name }}
        </div>
        <i
          style="padding: 6px; margin-left: auto"
          (click)="isDetailRouteMobileVisible = false"
          class="icon absui-icon--close"
        ></i>
      </div>
      <div style="padding-left: 38px" *ngIf="isShowMoreMobileDetails">
        <div class="header-details header-details_mb12">
          <label class="mobile-grid-text"
            >{{ "intrastate-documents.date" | translate }}:</label
          >
          <p class="mobile-grid-label">
            {{ focusedSelected[0]?.RouteDate | date : event.dateFormat }}
          </p>
        </div>
        <div class="header-details header-details_mb12">
          <label class="mobile-grid-text"
            >{{ "form-financial-document.description" | translate }}:</label
          >
          <p class="mobile-grid-label">
            {{ focusedSelected[0]?.Description }}
          </p>
        </div>
        <div
          class="header-details header-details_mb12"
          *ngIf="focusedSelected[0]?.CountPos"
        >
          <label class="mobile-grid-text"
            >{{ "gdpr.implementation" | translate }}:</label
          >
          <dx-progress-bar
            style="display: inline-block"
            width="75%"
            [min]="0"
            [max]="100"
            [value]="
              (focusedSelected[0]?.CountRegType2 /
                focusedSelected[0]?.CountPos) *
              100
            "
          >
          </dx-progress-bar>
          <div class="dx-progressbar-percent">
            {{ focusedSelected[0]?.CountRegType2 }} /
            {{ focusedSelected[0]?.CountPos }}
          </div>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole1'] &&
            userExtensionColumns['Pole1'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole1"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field1 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="userExtensionColumns['Pole2'].IsActive"
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole2"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field2 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole3'] &&
            userExtensionColumns['Pole3'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole3"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field3 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole4'] &&
            userExtensionColumns['Pole4'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole4"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field4 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole5'] &&
            userExtensionColumns['Pole5'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole5"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field5 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole6'] &&
            userExtensionColumns['Pole6'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole6"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field6 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole7'] &&
            userExtensionColumns['Pole7'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole7"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field7 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole8'] &&
            userExtensionColumns['Pole8'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole8"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field8 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole9'] &&
            userExtensionColumns['Pole9'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole9"].Name }}:</label
          >
          <p class="mobile-grid-label">{{ focusedSelected[0]?.Field9 }}</p>
        </div>
        <div
          class="header-details"
          *ngIf="
            userExtensionColumns['Pole10'] &&
            userExtensionColumns['Pole10'].IsActive
          "
        >
          <label class="mobile-grid-text"
            >{{ userExtensionColumns["Pole10"].Name }}:</label
          >
          <p class="mobile-grid-text">{{ focusedSelected[0]?.Field10 }}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 100%">
          <div class="d-flex align-items-center">
            <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="200"
  [height]="140"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="titleChengeRouteStatus"
  [visible]="isVisibleChangeRouteStatus"
  (visibleChange)="visibleChangeChengeRouteStatus()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <div style="display: inline-block">
        <ng-container *ngTemplateOutlet="topButtonsStatus"></ng-container>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 392px">
          <div class="d-flex align-items-center">
            <label style="width: 65px; top: -2px">{{
              "production-documents.status" | translate
            }}</label>
            <dx-select-box
              [(value)]="selectedStatus"
              [dataSource]="statuses"
              displayExpr="text"
              valueExpr="value"
              placeholder="Wybierz status"
            >
            </dx-select-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
<ng-template #topButtonsStatus>
  <div class="d-inline-block">
    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 6px"
      (onClick)="onSaveStatusRoute()"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
    >
    </dx-button>

    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelStatusRoute()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>

  <dx-tooltip
    [target]="'#add' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<app-form-user-extensions
  title="{{ 'categories.externalFields' | translate }}"
  [isVisible]="isVisibleExternalFieldsPos"
  groupCode="MOB_POZYCJA_TRASY"
  [readOnly]="readOnly || onlyShow"
  [groupChanged]="true"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="additionalFieldsListPos"
>
</app-form-user-extensions>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    isVisibleChangeRouteStatus ||
    newRouteVisible ||
    routeUsersVisible ||
    isCustomerVisible ||
    confirmDeleteRoutesVisible ||
    routeMapVisible ||
    editingRouteAddressVisible ||
    confirmDeleteRoutesContractorVisible ||
    openCustomDropDown ||
    openCustomDropDownMain
  "
>
</wapro-keyboard-shortcuts>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcutsStatus"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisibleChangeRouteStatus"
>
</wapro-keyboard-shortcuts>

<app-column-chooser
  [gridRef]="gridBank"
  [(visible)]="columnsChooserVisible"
  (columnsChanged)="onColumnsChanged($event)"
></app-column-chooser>
