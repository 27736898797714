import { Component, OnInit } from '@angular/core';
import { EventService } from '../../event.service';

@Component({
    selector: 'app-data-exchange',
    templateUrl: './data-exchange.component.html',
    styleUrls: ['./data-exchange.component.scss'],
    inputs: ['readOnly'],
    standalone: false
})
export class DataExchangeComponent implements OnInit {
  readOnly;
  dataExchangeList = [];
  selectedRowsKey = [];

  exchangeValue = '';
  isGridBoxOpened: boolean = false;

  constructor(public event: EventService) { }

  ngOnInit(): void {
    if(!this.readOnly) this.readOnly = this.event.readOnly;
  }

}
