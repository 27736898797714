<dx-popup
  [width]="520"
  [height]="300"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  title="{{ 'Edycja filtra' | translate }}"
  [(visible)]="isVisible"
  [focusStateEnabled]="false"
  (visibleChange)="visibleChange($event)"
  (onShown)="onPopupShown()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (onInitialized)="onPopupInitialized($event)"
>
  <div *dxTemplate="let content of 'content'" class="bottom-grid">
    <div class="text-right title-pop-up">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--clear-filter"
        type="default"
        stylingMode="text"
        [disabled]="form.pristine && !formValueChanged"
        (onClick)="resetForm()"
        [id]="'resetForm' + unicalGuid"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'choose-nest' + unicalGuid"
        [disabled]="!form.valid && isSubmitted"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
        (onClick)="onClose()"
      ></dx-button>

      <dx-tooltip
        *ngIf="form.valid || !isSubmitted"
        [target]="'#choose-nest' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
      <dx-tooltip
        *ngIf="!form.pristine"
        [target]="'#resetForm' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "filterPanel.clearValues" | translate }} (Ctrl + K)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view
      width="95%"
      height="100%"
      direction="horizontal"
      showScrollbar="never"
      tabindex="-1"
    >
      <form [formGroup]="form" class="popup-form">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-column">
            <div class="c-frame-row w-100">
              <h5>
                {{ "articles.dateFilter" | translate }}
              </h5>
            </div>
            <div class="form-item">
              <label class="">{{
                "articles.firstSaleDaysMax" | translate
              }}</label>
              <dx-number-box
                formControlName="firstSaleDaysMax"
                [format]="numberBoxDaysFormat"
                (onValueChanged)="onValueChanged($event)"
                [min]="0"
                [step]="1"
                valueChangeEvent="keyup blur change input focusout"
                [useLargeSpinButtons]="true"
                [showSpinButtons]="true"
                #focusFirst
              ></dx-number-box>
            </div>
            <div class="form-item">
              <label class=""
                >{{ "articles.firstSaleDaysMin" | translate }}
              </label>
              <dx-number-box
                formControlName="firstSaleDaysMin"
                [format]="numberBoxDaysFormat"
                (onValueChanged)="onValueChanged($event)"
                [min]="0"
                valueChangeEvent="keyup blur change input focusout"
                [showSpinButtons]="true"
                [useLargeSpinButtons]="true"
                [ngClass]="{
                  'input-required':
                    form.get('firstSaleDaysMin').errors?.minGreaterThanMax &&
                    isSubmitted
                }"
              ></dx-number-box>
            </div>
            <div class="form-item">
              <label class=""
                >{{ "articles.saleTimeMax" | translate }}
              </label>
              <dx-number-box
                formControlName="saleTimeMax"
                [format]="numberBoxDaysFormat"
                (onValueChanged)="onValueChanged($event)"
                [min]="0"
                valueChangeEvent="keyup blur change input focusout"
                [showSpinButtons]="true"
                [useLargeSpinButtons]="true"
              ></dx-number-box>
            </div>
            <div class="form-item">
              <label class=""
                >{{ "articles.saleTimeMin" | translate }}
              </label>
              <dx-number-box
                formControlName="saleTimeMin"
                [format]="numberBoxDaysFormat"
                (onValueChanged)="onValueChanged($event)"
                [min]="0"
                valueChangeEvent="keyup blur change input focusout"
                [showSpinButtons]="true"
                [useLargeSpinButtons]="true"
                [ngClass]="{
                  'input-required':
                    form.get('saleTimeMin').errors?.minGreaterThanMax &&
                    isSubmitted
                }"
              ></dx-number-box>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>
<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
