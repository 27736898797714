<dx-popup
  [width]="550"
  [height]="560"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'articles.groupChangeTradeData' | translate }}"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="saveForm()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px;"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="onClosed.emit()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.confirm' | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.close' | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
      *ngIf="form"
    >
      <form [formGroup]="form" class="popup-form">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right" style="width: 520px;">
            <dx-tab-panel
              #tabPanel
              [items]="itemsTab"
              [loop]="false"
              [animationEnabled]="false"
              [(selectedIndex)]="selectedTabIndex"
              [swipeEnabled]="false"
            >
              <div *dxTemplate="let name of 'item'">
                <ng-container [ngSwitch]="name">
                  <div
                    *ngSwitchCase="'constractors.commercialData' | translate"
                  >
                    <div
                      class="d-flex justify-content-center flex-wrap flex-box-wrapper"
                    >
                      <div
                        class="form-group-inline-right flex-box-column"
                        style="width: 505px;"
                      >
                        <div class="form-box">
                          <div class="box-title"></div>
                          <div class="flex-box-column-content">
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'customerTradeData.blockSell' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="BlockingModeDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="BlockingMode"
                                  [readOnly]="
                                    !form.get('checks.BlockingMode').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="BlockingMode"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'BlockingMode')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>

                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'constractors.merchantLimit' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-number-box
                                  [width]="276"
                                  [step]="1"
                                  format="#0.00"
                                  [showSpinButtons]="true"
                                  [useLargeSpinButtons]="true"
                                  valueChangeEvent="keyup"
                                  formControlName="BuyerLimit"
                                  [readOnly]="
                                    !form.get('checks.BuyerLimit').value
                                  "
                                ></dx-number-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="BuyerLimit"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'BuyerLimit')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>

                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'customerTradeData.daysAfterPeriod'
                                    | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-number-box
                                  width="276"
                                  [step]="1"
                                  format="#0"
                                  [min]="0"
                                  [showSpinButtons]="true"
                                  [useLargeSpinButtons]="true"
                                  valueChangeEvent="keyup"
                                  formControlName="BlockAfterDays"
                                  [readOnly]="
                                    !form.get('checks.BlockAfterDays').value
                                  "
                                ></dx-number-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="BlockAfterDays"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'BlockAfterDays')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>

                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'constractors.orderPriority' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="PriorityDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="Priority"
                                  [readOnly]="
                                    !form.get('checks.Priority').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="Priority"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'Priority')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'form-payment.paymentMethod' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <app-forms-payment
                                  [onlyActive]="true"
                                  [dropDownBoxMode]="true"
                                  style="width: 276px;"
                                  [selectedId]="
                                    form.get('data.PaymentForm').value
                                  "
                                  (onChoosed)="onChoosedFormPeyment($event)"
                                ></app-forms-payment>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="PaymentForm"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'PaymentForm')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'constractors.maturityReceivables' | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-number-box
                                  width="276"
                                  [step]="1"
                                  format="#0"
                                  [min]="0"
                                  [showSpinButtons]="true"
                                  [useLargeSpinButtons]="true"
                                  valueChangeEvent="keyup"
                                  formControlName="DaysForDue"
                                  [readOnly]="
                                    !form.get('checks.DaysForDue').value
                                  "
                                ></dx-number-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="DaysForDue"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'DaysForDue')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'customerTradeData.dayCommitment' | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-number-box
                                  width="276"
                                  [step]="1"
                                  format="#0"
                                  [min]="0"
                                  [showSpinButtons]="true"
                                  [useLargeSpinButtons]="true"
                                  valueChangeEvent="keyup"
                                  formControlName="DaysForCommitment"
                                  [readOnly]="
                                    !form.get('checks.DaysForCommitment').value
                                  "
                                ></dx-number-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="DaysForCommitment"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'DaysForCommitment'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'customerTradeData.defaultOverhead'
                                    | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-number-box
                                  width="276"
                                  [step]="1"
                                  format="#0.00"
                                  [showSpinButtons]="true"
                                  [useLargeSpinButtons]="true"
                                  valueChangeEvent="keyup"
                                  formControlName="DefaultDiscount"
                                  [readOnly]="
                                    !form.get('checks.DefaultDiscount').value
                                  "
                                ></dx-number-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="DefaultDiscount"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'DefaultDiscount')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            @if( event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' && event.footerInfo.VariantName !=='FAKTUROWANIE' && event.footerInfo.VariantName !==
                            'ANY FAKTUROWANIE PRO' && event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'constractors.participationLoyaltyProgram'
                                    | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="IsLoyalProgramActive"
                                  [readOnly]="
                                    !form.get('checks.IsLoyalProgramActive')
                                      .value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="IsLoyalProgramActive"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'IsLoyalProgramActive'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            }
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'constractors.trader' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <app-users
                                  [dropDownBoxMode]="true"
                                  (onChoosed)="onChoosedUser($event)"
                                  [readOnly]="
                                    !form.get('checks.TraderId').value
                                  "
                                  [selectedId]="form.get('data.TraderId').value"
                                ></app-users>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="TraderId"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'TraderId')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'deliveries.formDelivery' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <app-delivery
                                  [dropDownBoxMode]="true"
                                  [readOnly]="
                                    !form.get('checks.DeliveryFormId').value
                                  "
                                  style="display: inline-block;"
                                  (onChoosed)="onChoosedDeliveryForm($event)"
                                  [selectedId]="
                                    form.get('data.DeliveryFormId').value
                                  "
                                ></app-delivery>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="DeliveryFormId"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'DeliveryFormId')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'customerTradeData.JPK' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <app-type-of-commercial-transaction
                                  type="customers"
                                  [readOnly]="
                                    !form.get(
                                      'checks.TypeOfCommercialTransaction'
                                    ).value
                                  "
                                  [width]="'276px'"
                                  [dropDownBoxMode]="true"
                                  [selectedId]="
                                    form.get('data.TypeOfCommercialTransaction')
                                      .value
                                  "
                                  (onChoosed)="onChoosedJPK($event)"
                                ></app-type-of-commercial-transaction>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="TypeOfCommercialTransaction"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'TypeOfCommercialTransaction'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'customerTradeData.tags' | translate">
                    <div
                      class="d-flex justify-content-center flex-wrap flex-box-wrapper"
                    >
                      <div
                        class="form-group-inline-right flex-box-column"
                        style="width: 505px;"
                      >
                        <div class="form-box">
                          <div class="box-title"></div>
                          <div class="flex-box-column-content">
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'customerTradeData.block' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="IsBlocked"
                                  [readOnly]="
                                    !form.get('checks.IsBlocked').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="IsBlocked"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'IsBlocked')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            @if( event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' && event.footerInfo.VariantName !==
                            'FAKTUROWANIE' && event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' && event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'constractors.participatesPromotions'
                                    | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="IsIncludedInPromotion"
                                  [readOnly]="
                                    !form.get('checks.IsIncludedInPromotion')
                                      .value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="IsIncludedInPromotion"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'IsIncludedInPromotion'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>

                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'customerTradeData.exemptExcise' | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="CustomTypeDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="CustomType"
                                  [readOnly]="
                                    !form.get('checks.CustomType').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="CustomType"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'CustomType')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            }
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'customerTradeData.autofiscalization'
                                    | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="UseAutoFiscal"
                                  [readOnly]="
                                    !form.get('checks.UseAutoFiscal').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="UseAutoFiscal"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'UseAutoFiscal')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'customerTradeData.groupPayments' | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="UseGroupPayment"
                                  [readOnly]="
                                    !form.get('checks.UseGroupPayment').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="UseGroupPayment"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'UseGroupPayment')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'constractors.keepsRecords' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="VatPayerEvidenceDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="VatPayerEvidence"
                                  [readOnly]="
                                    !form.get('checks.VatPayerEvidence').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="VatPayerEvidence"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'VatPayerEvidence'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'constractors.activeVATtaxpayer' | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="IsActiveVatPayer"
                                  [readOnly]="
                                    !form.get('checks.IsActiveVatPayer').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="IsActiveVatPayer"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'IsActiveVatPayer'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'constractors.emailForReports' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="EmailTypeDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="EmailTypeForReports"
                                  [readOnly]="
                                    !form.get('checks.EmailTypeForReports')
                                      .value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="EmailTypeForReports"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'EmailTypeForReports'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'articles.specialCharacters' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-text-box
                                  width="276"
                                  formControlName="DistinguishCode"
                                  valueChangeEvent="keyup"
                                  [readOnly]="
                                    !form.get('checks.DistinguishCode').value
                                  "
                                ></dx-text-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="DistinguishCode"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'DistinguishCode')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{
                                  'constractors.declarationOfSplitPayment'
                                    | translate
                                }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="SplitPaymentDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="SplitPaymentPreferred"
                                  [readOnly]="
                                    !form.get('checks.SplitPaymentPreferred')
                                      .value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="SplitPaymentPreferred"
                                  (onValueChanged)="
                                    onCheckBoxChanged(
                                      $event,
                                      'SplitPaymentPreferred'
                                    )
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            @if( event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' && event.footerInfo.VariantName !=='FAKTUROWANIE' && event.footerInfo.VariantName !==
                            'ANY FAKTUROWANIE PRO' && event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'constractors.sugarTaxPayer' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <dx-select-box
                                  width="276"
                                  [dataSource]="YesNoDataSource"
                                  displayExpr="label"
                                  valueExpr="value"
                                  valueChangeEvent="keyup"
                                  formControlName="IsSugarPayer"
                                  [readOnly]="
                                    !form.get('checks.IsSugarPayer').value
                                  "
                                ></dx-select-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="IsSugarPayer"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'IsSugarPayer')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'RODO'">
                    <div
                      class="d-flex justify-content-center flex-wrap flex-box-wrapper"
                    >
                      <div
                        class="form-group-inline-right flex-box-column"
                        style="width: 505px;"
                      >
                        <div class="form-box">
                          <div class="box-title"></div>
                          <div class="flex-box-column-content">
                            <div class="box-row">
                              <label style="width: 180px;">
                                {{ 'customerTradeData.rodoDate' | translate }}
                              </label>
                              <ng-container formGroupName="data">
                                <wapro-date-box
                                  width="276"
                                  formControlName="RodoDate"
                                  type="date"
                                  [displayFormat]="event.dateFormat"
                                  [readOnly]="
                                    !form.get('checks.RodoDate').value
                                  "
                                ></wapro-date-box>
                              </ng-container>
                              <ng-container formGroupName="checks">
                                <dx-switch
                                  style="margin-left: 6px;"
                                  formControlName="RodoDate"
                                  (onValueChanged)="
                                    onCheckBoxChanged($event, 'RodoDate')
                                  "
                                ></dx-switch>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </dx-tab-panel>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>
