import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

import { DoubleClickResult } from 'src/app/event.model';
import { HelpService } from 'src/app/help-service.service';
import { SERVICE_TYPES_KEY } from 'src/app/consts';
import { of, tap } from 'rxjs';

@Component({
    selector: 'app-order-type',
    templateUrl: './order-type.component.html',
    styleUrls: ['./order-type.component.scss'],
    inputs: [
        'dropDownBoxMode',
        'selectedId',
        'readOnly',
        'width',
        'selectBoxMode',
        'isGridBoxOpened',
        'isSetDefault',
        'className',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrderTypeComponent implements OnInit {
  @Output() onChoosed = new EventEmitter();
  @Output() onOpenDrobBox = new EventEmitter();
  @ViewChild('gridClass') gridClass;
  @ViewChild('mobileGridClass') mobileGridClass;

  focusedRowIndex = -1;
  helpService = inject(HelpService);
  className: boolean = false;
  isSetDefault: boolean = false;
  alreadySetDefault: boolean = false;
  defaultElement = null;
  readOnly;
  selectBoxMode;
  isContextMenuMobile: boolean = false;
  selectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  documentListPom = [];
  mobileGridWidth;

  isGridBoxOpened: boolean = false;
  documentList;

  dataSource = [];
  width;

  addRow: boolean = false;
  editRow: boolean = false;
  isDeleteRow: boolean = false;

  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 240;

  myEventEdit;
  myEventChoose;
  myEventEsc;
  myEventShow;
  myEventDelete;
  unicalGuid;
  contextMenu;
  widthCityColumn = '250';
  mode = 'add';
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };
  doubleClickResult: DoubleClickResult = 'Edycja';

  closeSub = null;
  readOnlySub = null;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.closeSub = this.event.closeDrobBox.subscribe(() => {
      this.closeDropBox();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.readOnlySub = this.event.sendReadOnlyInfo.subscribe((res) => {
      this.readOnly = res;
    });

    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
    this.getDate();

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }
    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.gridClass.instance.getSelectedRowsData().length > 0) {
          this.onChoosingRow();
        }
      }
    });

    this.contextMenu = [
      { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
      { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
      { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    ];

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }

    //sprawqdzam czy jest przekazana szerokosc i ustawiam szerokosc grida na podstawie przekazanej szerokości
    if (this.width) {
      this.widthCityColumn = String(this.width - 60);
    }

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));

    this.perABD = this.event.checkPermissionsBtn(
      'SerwisTypZlecenieD',
      'SerwisTypZlecenieE',
      'SerwisTypZlecenieU',
      null
    );
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.addRow = true;
        }
        break;
      case 1:
        if (!this.readOnly) {
          this.addRow = true;
          this.mode = 'edit';
        }
        break;
      case 2:
        if (!this.readOnly) {
          this.isDelete();
        }
        break;
    }
  };

  editRecord(data?) {
    if (data) {
      this.selectedRow = [data];
    }
    if (
      !this.readOnly &&
      this.selectedRow.length > 0 &&
      this.perABD.editBtn &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.addRow = true;
    }
    this.mode = 'edit';
  }

  getTypesDef() {
    const localData = this.event.getSessionStorageData(SERVICE_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`services/orders/types`).pipe(
        tap((data) => {
          this.event.saveSessionStorageData(SERVICE_TYPES_KEY, data);
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedId && this.selectedId != '' && this.dropDownBoxMode) {
      this.getTypesDef().subscribe((res) => {
        this.documentList = res.data.filter(
          (el) => el.ServiceOrderTypeId == this.selectedId
        );
        this.choosingDocument = [Number(this.selectedId)];
        this.cd.detectChanges();
      });
    } else {
      this.documentList = [];
      this.choosingDocument = [];
    }

    if (this.selectBoxMode) {
      setTimeout(() => {
        if (this.selectedId) {
          this.selectedId = Number(this.selectedId);
        }
        this.cd.detectChanges();
      }, 8000);
    }

    if (changes?.isSetDefault?.currentValue) {
      this.setDefaultElement();
    }
  }

  getDate = () => {
    this.getTypesDef().subscribe((res) => {
      this.dataSource = res.data;
      if (this.isSetDefault && !this.alreadySetDefault) {
        this.alreadySetDefault = true;
        this.defaultElement = res.data.find((el) => el.IsDefault);
        this.setDefaultElement();
      }
      if (res.data.length > 0) {
        this.focusedRowIndex = 0;
        this.onFocusedRowChanged({
          row: { data: res.data[this.focusedRowIndex] },
        });
        this.cd.detectChanges();
      } else {
        this.focusedRowIndex = -1;
      }
    });
  };

  refreshData() {
    sessionStorage.removeItem(SERVICE_TYPES_KEY);
    this.getDate();
  }

  setDefaultElement() {
    if (this.defaultElement) {
      this.selectedRow = [this.defaultElement.ServiceOrderTypeId];
      this.documentList = [this.defaultElement];
      this.documentListPom = [this.defaultElement];
      this.onChoosed.emit(this.defaultElement);
    }
  }

  ngOnDestroy() {
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
    this.closeSub?.unsubscribe();
    this.readOnlySub?.unsubscribe();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            document.getElementById(`btn-add${this.unicalGuid}`).click();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRow.length != 0 &&
            !this.readOnly &&
            this.perABD.editBtn
          ) {
            this.addRow = true;
            this.mode = 'edit';
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRow.length != 0 &&
            !this.readOnly &&
            this.perABD.deleteBtn
          ) {
            this.isDelete();
          }
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
        },
        preventDefault: true,
      }
    );
  }

  onClosingForm = () => {
    this.addRow = false;
    this.editRow = false;
    this.showRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  onInserted = () => {
    sessionStorage.removeItem(SERVICE_TYPES_KEY);
    this.getDate();
  };

  onFocusedRowChanged = (e) => {
    this.selectedRow = [e.row.data.ServiceOrderTypeId];
    this.documentListPom = [e.row.data];
  };

  onChoosingRow = () => {
    if (!this.readOnly) {
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      this.onChoosed.emit(this.documentList[0]);
      this.isGridBoxOpened = false;
    }
  };

  onOpenedChanged = (e) => {
    setTimeout(() => {
      this.event.disabletShortcuts.next(e);
    }, 0);

    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
    this.onOpenDrobBox.emit(e);
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onSelectionChanged = (e) => {
    this.documentListPom = [];
    this.documentListPom.push(e.selectedRowsData[0]);
  };

  isDelete = () => {
    if (
      this.selectedRow.length > 0 &&
      this.perABD.deleteBtn &&
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.isDeleteRow = true;
      this.cd.detectChanges();
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.instance.focus();
      }
    }, 500);
  }

  showRow: boolean = false;
  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) {
      if (this.doubleClickResult === 'Edycja' && this.perABD.editBtn) {
        this.editRow = true;
        this.cd.detectChanges();
      } else {
        this.showRow = true;
        this.cd.detectChanges();
      }
    } else {
      this.onChoosingRow();
    }
  };

  onKeyDown = (e) => {
    if (e.event.keyCode === 113 || e.event.keyCode === 46)
      e.event.preventDefault();

    try {
      if (e.event.originalEvent.code == 'Enter') {
        this.onChoosingRow();
        this.cd.detectChanges();
      }

      if (e.event.originalEvent.code == 'Escape') {
        this.isGridBoxOpened = false;
        this.cd.detectChanges();
      }
    } catch {}
  };

  delete = () => {
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(this.selectedRow[0])
    );
    if (this.choosingDocument == this.selectedRow[0]) {
      this.choosingDocument = [];
    }
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.focusedRowIndex = 0;
        this.gridClass.instance.focus();
        this.cd.detectChanges();
      }
    }, 500);
  };

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };
  isContextMenuVisible = false;
  contextMenuTarget;

  mobileColWidth = {
    name: 160,
    def: 70,
    dots: 40,
  };

  onMobileGridContentReady = (e) => {
    this.mobileGridWidth = e.element.offsetWidth;
    let { def, dots } = this.mobileColWidth;

    this.mobileColWidth.name = this.mobileGridWidth - def - dots;
  };

  showContextMenu = (e) => {
    this.isContextMenuVisible = true;
    this.contextMenuTarget = e.target;
  };
}
