<div class="row">
  <div class="col-md-12">
    <dx-drop-down-box
      [(value)]="exchangeValue"
      valueExpr="deliveryId"
      [deferRendering]="false"
      displayExpr="deliveryName"
      [(opened)]="isGridBoxOpened"
      style="width: 276px"
      [dataSource]="dataExchangeList"
      [showClearButton]="true"
      [readOnly]="readOnly"
    >
      <div *dxTemplate="let data of 'content'" style="height: 300px">
        <div class="top-menu-popup">
          <dx-scroll-view
            width="95%"
            height="100%"
            direction="horizontal"
            showScrollbar="never"
          >
            <dx-button
              icon="icon absui-icon--add-circle"
              id="btn-add-form-delivery"
              class="btn-dx-top-menu-folder"
              style="margin-right: 0px"
            >
            </dx-button>

            <dx-button
              icon="icon absui-icon--mode-edit"
              id="btn-edit-form-delivery"
              class="btn-dx-top-menu-folder"
              style="margin-right: 0px"
              [disabled]="selectedRowsKey.length == 0"
            >
            </dx-button>

            <dx-button
              icon="icon absui-icon--highlight-off"
              id="btn-delete-form-delivery"
              class="btn-dx-top-menu-folder"
              [disabled]="selectedRowsKey.length == 0"
            >
            </dx-button>

            <dx-button
              icon="icon absui-icon--check-circle"
              id="btn-chose-form-delivery"
              class="btn-dx-top-menu"
              text="{{ 'buttons.choose' | translate }}"
              [disabled]="selectedRowsKey.length == 0"
            >
            </dx-button>
          </dx-scroll-view>
        </div>

        <div class="row">
          <div class="col-md-12">
            <dx-data-grid
              [showBorders]="true"
              [allowColumnReordering]="true"
              [dataSource]="dataExchangeList"
              keyExpr="deliveryId"
              shortcut
              style="width: 100%; max-height: 200px; margin-bottom: 10px"
              [focusedRowEnabled]="true"
              *ngIf="dataExchangeList.length !== 0; else noData"
              [(selectedRowKeys)]="selectedRowsKey"
            >
              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
              <dxo-selection mode="single"></dxo-selection>

              <dxi-column
                caption="{{ 'companies.grid.columns.name' | translate }}"
              ></dxi-column>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </dx-drop-down-box>
  </div>
</div>

<ng-template #noData>
  <div class="col-md-12 text-center">
    <app-no-data></app-no-data>
  </div>
</ng-template>
