<dx-popup
  [(width)]="localStorageData.windowSize.width"
  [(height)]="localStorageData.windowSize.height"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  [fullScreen]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.isFullscreen
      : false
  "
  [maxHeight]="maxHeight"
  (onShown)="
    event.onShownPopUp();
    loadChildComponent = true;
    setFocus()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{
        boxShadow: scrollPosition > 0 && event.deviceType != 'mobile'
      }"
    >
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 45px)'"
      direction="vertical"
      (onScroll)="onScroll($event)"
    >
      <div style="padding: 14px">
        <p class="header"
           [innerHtml]="'bussinesLink.headerEstablishingRelationshipWithBusinessLink' | translate"
        ></p>
        <p class="description"
           [innerHtml]="'bussinesLink.infoEstablishingRelationshipWithBusinessLink' | translate"
        >
        </p>
        <form [formGroup]="form">
          <div style="position: relative">
            <div
              class="d-flex flex-wrap"
              style="justify-content: left"
            >
              <div
                class="form-group-inline-right"
              >
                <div class="c-frame-row"
                     style="width: 100%">
                  <h5 style="padding-left: 10px; margin-top: 0">
                    {{
                      "crmDeliveries.contractorData"
                        | translate
                    }}
                  </h5>
                </div>

                <div>
                  <label style="width: 83px"
                  >{{ "dataCollectors.name" | translate }}
                  </label>

                  <dx-text-box
                    formControlName="Name"
                    width="300"
                    [readOnly]="true"
                  >
                  </dx-text-box>
                </div>

                <div style="margin-bottom: 14px">
                  <label style="width: 83px"
                  >NIP
                  </label>

                  <dx-text-box
                    formControlName="TIN"
                    width="300"
                    [readOnly]="true"
                  >
                  </dx-text-box>
                </div>

                <div *ngIf="!mode; else withGrid"
                     class="inactiveMode">
                  <p style="display: flex; margin: 0;"
                     class="p-error-text-box">
                    <i class="icon icon absui-icon--warning2 icon-warning-box"></i>
                    <span style="padding-left: 8px; display: block"
                          [innerHTML]="'bussinesLink.warningEstablishingRelationshipWithBusinessLink' | translate"></span>
                  </p>
                </div>
                <ng-template #withGrid>
                  <div class="c-frame-row"
                       style="width: 100%">
                    <h5 style="padding-left: 10px; margin-top: 0">
                      {{
                        "bussinesLink.existingServiceContractors"
                          | translate
                      }}
                    </h5>
                  </div>

                  <dx-data-grid
                    [(focusedRowIndex)]="partnerFindListFocusedRowIndex"
                    [showBorders]="true"
                    [allowColumnReordering]="true"
                    [dataSource]="partnerFindList"
                    [wordWrapEnabled]="false"
                    style="width:795px; margin-bottom: 10px"
                    [focusedRowEnabled]="true"
                    [height]="heightBottomGrid"
                    [allowColumnResizing]="true"
                    (onFocusedRowChanged)="onFocusedRowChanged($event)"
                    (onOptionChanged)="event.onOptionChanged($event)"
                    [remoteOperations]="true"
                    (onKeyDown)="onKeyDown($event)"
                  >
                    <dxo-load-panel [enabled]="false"></dxo-load-panel>
                    <dxo-paging [pageSize]="50"
                                [enabled]="true"></dxo-paging>
                    <dxo-scrolling
                      mode="virtual"
                      rowRenderingMode="virtual"></dxo-scrolling>
                    <dxo-selection [mode]="'single'"></dxo-selection>

                    <dxi-column
                      [width]="300"
                      [allowSorting]="false"
                      [cssClass]="'sorting-unavailable'"
                      caption="{{ 'bussinesLink.contractorNameInService' | translate }}"
                      dataField="companyName"
                    >
                    </dxi-column>

                    <dxi-column
                      [width]="150"
                      [allowSorting]="false"
                      [cssClass]="'sorting-unavailable'"
                      caption="{{ 'bussinesLink.branchNIP_JST' | translate }}"
                      dataField="internalTIN"
                    >
                    </dxi-column>

                    <dxi-column
                      [width]="150"
                      [allowSorting]="false"
                      [cssClass]="'sorting-unavailable'"
                      caption="{{ 'bussinesLink.internalIdentifierKSeF' | translate }}"
                      dataField="internalID"
                    >
                    </dxi-column>

                    <dxi-column
                      [width]="100"
                      [allowSorting]="false"
                      [cssClass]="'sorting-unavailable'"
                      caption="{{ 'bussinesLink.lang' | translate }}"
                      dataField="country"
                    >
                    </dxi-column>

                    <dxi-column
                      [width]="200"
                      [allowSorting]="false"
                      [cssClass]="'sorting-unavailable'"
                      caption="{{ 'bussinesLink.relationshipStatus' | translate }}"
                      dataField="relationStateDescription"
                    >
                    </dxi-column>
                  </dx-data-grid>

                </ng-template>

                <div *ngIf="mode != 'active'" class="d-flex">
                  <div class="c-frame-row"
                       [ngStyle]="!mode ? { 'margin-top': '46px' } : { 'margin-top': '0' }"
                       style="width: 100%; margin-right: 26px">
                    <h5 style="padding-left: 10px">
                      {{
                        "bussinesLink.contactDetails"
                          | translate
                      }}
                    </h5>
                    <div class="inputs">
                      <label style="width: 83px"
                      >{{ 'start.userInfo.firstName' | translate }}
                        <strong>*</strong></label>

                      <dx-text-box
                        [ngClass]="{
                              'input-required':
                                (!form.controls.adminFirstname.valid &&
                                  submitted)
                            }"
                        formControlName="adminFirstname"
                        width="276"
                        #name
                      >
                      </dx-text-box>

                    </div>
                    <div class="inputs">
                      <label style="width: 83px"
                      >{{ 'start.userInfo.lastName' | translate }}
                        <strong>*</strong></label>

                      <dx-text-box
                        [ngClass]="{
                              'input-required':
                                (!form.controls.adminSurname.valid &&
                                  submitted)
                            }"
                        formControlName="adminSurname"
                        width="276"
                      >
                      </dx-text-box>
                    </div>
                    <div class="inputs">
                      <label style="width: 83px"
                      >{{ 'bussinesLink.lang' | translate }}
                      </label>

                      <dx-select-box
                        [dataSource]="languages"
                        displayExpr="label"
                        valueExpr="value"
                        formControlName="adminDefaultLanguage"
                        width="276">
                      </dx-select-box>
                    </div>
                    <div class="inputs">
                      <label style="width: 83px"
                      >E-mail
                        <strong>*</strong></label>

                      <dx-text-box
                        [ngClass]="{
                              'input-required':
                                (!form.controls.adminMail.valid &&
                                  submitted)
                            }"
                        formControlName="adminMail"
                        width="276"
                      >
                      </dx-text-box>
                    </div>
                  </div>
                  <div class="c-frame-row"
                       [ngStyle]="!mode ? { 'margin-top': '46px' } : { 'margin-top': '0' }"
                       style="width: 100%; margin-top: 46px;">
                    <h5 style="padding-left: 10px">
                      {{
                        "bussinesLink.invitersDetails"
                          | translate
                      }}
                    </h5>
                    <div class="inputs">
                      <label style="width: 100px"
                      >{{ 'start.userInfo.firstNameAndLastName' | translate }}
                      </label>

                      <dx-text-box
                        formControlName="inviterName"
                        width="276"
                      >
                      </dx-text-box>
                    </div>
                    <div class="inputs">
                      <label style="width: 100px"
                      >E-mail
                      </label>

                      <dx-text-box
                        formControlName="inviterMail"
                        width="276"
                      >
                      </dx-text-box>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </form>
      </div>


    </dx-scroll-view>

  </div>
</dx-popup>

<div *ngIf="loadChildComponent">
  <app-confirm-dialog
    [paramsMsg]="paramsConfigContractorWasInvitedToTheService()"
    [confirmText]="'bussinesLink.contractorWasInvitedToTheService'"
    [isVisible]="configContractorWasInvitedToTheServiceIsVisible"
    [kindDialog]="'warning'"
    btnConfig="close"
    (onClosing)="cancelContractorWasInvitedToTheService()"
  >
  </app-confirm-dialog>

</div>

<ng-template #topButtons>

  <div class="d-inline-block">
    <dx-button
      *ngIf="!mode"
      style="margin-right: 6px"
      class="btn-dx-emptyState"
      [text]="'buttons.confirm' | translate"
      type="success"
      (onClick)="onConfirm()"
      [id]="'confirm' + unicalGuid"
    >
    </dx-button>

    <dx-button
      *ngIf="mode"
      style="margin-right: 6px"
      class="btn-dx-emptyState"
      [text]="'bussinesLink.Invite' | translate"
      type="success"
      (onClick)="onConfirm()"
      [disabled]="mode === 'active'"
      [id]="'confirm' + unicalGuid"
    >
    </dx-button>

    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onClose()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>

    <dx-tooltip
      [target]="'#cancel' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.cancel" | translate }} (Esc)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#confirm' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "bussinesLink.Invite" | translate }} (F10)
      </div>
    </dx-tooltip>
  </div>

  <!--    <dx-tooltip-->
  <!--            [target]="'#cancel' + unicalGuid"-->
  <!--            [showEvent]="event.tooltipShowEvent"-->
  <!--            hideEvent="dxhoverend"-->
  <!--            [hideOnOutsideClick]="false"-->
  <!--    >-->
  <!--        <div *dxTemplate="let data of 'content'">-->
  <!--            {{ "buttons.cancel" | translate }} (Esc)-->
  <!--        </div>-->
  <!--    </dx-tooltip>-->
</ng-template>


<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>






