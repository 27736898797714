<dx-popup
  [width]="400"
  height="320"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'dataCollectors.popupTItleEditPosition' | translate"
  [(visible)]="isVisible"
  [focusStateEnabled]="false"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); setFocus()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [id]="'edit-collector-position' + unicalGuid"
  shortcut
>
  <div *dxTemplate="let content of 'content'">
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <!-- <div *ngTemplateOutlet="topButtons"></div> -->
    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : '300px'"
      direction="vertical"
    >
      <div class="grid-container" *ngTemplateOutlet="formTemplate"></div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-template #header>
  <div class="text-right title-pop-up">
    <!-- Buttons -->
    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      (onClick)="onSaving()"
      [id]="'save' + unicalGuid"
      style="margin-right: 6px"
      [disabled]="!form.touched || !isDataFetched"
    ></dx-button>
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onClosing()"
      [id]="'cancel' + unicalGuid"
    ></dx-button>
    <!-- Buttons -->

    <!-- Tooltips -->
    <dx-tooltip
      [target]="'#save' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="true"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.confirm" | translate }} (F10)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#cancel' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.close" | translate }} (Esc)
      </div>
    </dx-tooltip>
    <!-- / Tooltips -->
  </div>
  <div>
    <p class="pl-1">{{ collectorPositionData?.ProductName }}</p>
  </div>
</ng-template>

<ng-template #formTemplate>
  <form [formGroup]="form">
    <div class="form-group-inline-right">
      <div>
        <label style="width: 124px"
          >{{ "dataCollectors.positionsGrid.unit" | translate }}
          <strong>*</strong>
        </label>
        <app-size-units
          [dropDownBoxMode]="true"
          [readOnly]="!isDataFetched"
          [selectedCode]="collectorPositionData?.Unit"
          (onChoosed)="onUnitChoosed($event)"
          [readOnly]="!isDataFetched"
        >
        </app-size-units>
      </div>
      <div>
        <label style="width: 124px"
          >{{ "dataCollectors.positionsGrid.quantity" | translate }}
          <strong>*</strong>
        </label>
        <dx-number-box
          style="width: 276px"
          [readOnly]="!isDataFetched"
          formControlName="Amount"
          [min]="0"
          (onInput)="onFormInput()"
          (onValueChanged)="onFormInput()"
        >
        </dx-number-box>
      </div>
      <div>
        <label style="width: 124px"
          >{{ "dataCollectors.positionsGrid.quantityToProcess" | translate }}
          <strong>*</strong>
        </label>
        <dx-number-box
          style="width: 276px"
          [min]="0"
          [readOnly]="!isDataFetched"
          formControlName="AmountToProceed"
          (onInput)="onFormInput()"
          (onValueChanged)="onFormInput()"
        >
        </dx-number-box>
      </div>
      <div>
        <label style="width: 124px"
          >{{ "dataCollectors.positionsGrid.expDate" | translate }}
        </label>
        <wapro-date-box
          [readOnly]="!isDataFetched"
          type="date"
          width="276"
          [displayFormat]="event.dateFormat"
          formControlName="ExpirationDate"
          (onValueChanged)="onFormInput()"
        >
        </wapro-date-box>
      </div>
      <div>
        <label style="width: 124px"
          >{{ "dataCollectors.positionsGrid.serialNumber" | translate }}
        </label>
        <dx-text-box
          style="width: 276px"
          [readOnly]="!isDataFetched"
          formControlName="SerialNumber"
          (onInput)="onFormInput()"
          (onValueChanged)="onFormInput()"
        >
        </dx-text-box>
      </div>
    </div>
  </form>
</ng-template>

<ng-container *ngFor="let validator of validators; let i = index">
  <app-confirm-dialog
    [isVisible]="validator.showPopup"
    (onRemoving)="onConfirmDialogConfirm(i)"
    [kindDialog]="validator.type"
    [btnConfig]="validator.confirmDialogBtnConfig"
    (onCanceling)="onConfirmDialogCancel(i)"
    [confirmText]="validator.message"
    (onClosing)="onConfirmDialogClose(i)"
  >
  </app-confirm-dialog>
</ng-container>
<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="'edit-collector-position' + unicalGuid"
></wapro-keyboard-shortcuts>
