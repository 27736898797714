import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-customer-group-trade-change',
    templateUrl: './customer-group-trade-change.component.html',
    styleUrls: ['./customer-group-trade-change.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomerGroupTradeChangeComponent implements OnInit {
  @Output() onClosed: EventEmitter<void> = new EventEmitter();
  @Output() onSaved: EventEmitter<void> = new EventEmitter();
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  form: FormGroup;
  selectedTabIndex = 0;
  YesNoDataSource = [
    { label: 'Tak', value: true },
    { label: 'Nie', value: false },
  ];
  PriorityDataSource = [
    { label: 'Niski', value: 0 },
    { label: 'Średni', value: 1 },
    { label: 'Wysoki', value: 2 },
  ];
  EmailTypeDataSource = [
    { label: 'kontrahenta', value: 0 },
    { label: 'domyślnego kontaktu', value: 1 },
    { label: 'wszystkich kontaktów', value: 2 },
    { label: 'kontrahenta i domyślnego kontaktu', value: 3 },
    { label: 'kontrahenta i wszystkich kontaktów', value: 4 },
  ];
  VatPayerEvidenceDataSource = [
    { label: 'NIP', value: 0 },
    { label: 'PESEL', value: 1 },
  ];
  CustomTypeDataSource = [
    { label: 'Finalny odbiorca niezwolniony', value: 0 },
    { label: 'Finalny odbiorca zwolniony', value: 1 },
    { label: 'Pośredniczący podmiot węglowy', value: 2 },
  ];
  SplitPaymentDataSource = [
    { label: 'nieokreślona', value: 0 },
    { label: 'preferowana podzielona płatność', value: 1 },
    { label: 'preferowana płatność zwykła', value: 2 },
  ];
  BlockingModeDataSource = [
    { label: 'nie blokuj', value: 0 },
    { label: 'blokuj', value: 1 },
    { label: 'blok. dla przeterm. należn.', value: 2 },
    { label: 'blok. dla przeterm. należn. powyżej limitu', value: 3 },
    { label: 'blok. dla wszystkich należn. powyżej limitu', value: 4 },
    {
      label: 'blok. dla wszystkich należn. powyżej limitu lub przeterm.',
      value: 5,
    },
  ];

  translate = inject(TranslateService);
  event = inject(EventService);
  itemsTab = [
    this.translate.instant('constractors.commercialData'),
    this.translate.instant('customerTradeData.tags'),
  ];
  constructor(
    private fb: FormBuilder,
    private appServices: AppServices
  ) {
    this.createForm();
    this.getTranslations();

    if(this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO'){
        this.itemsTab.push("RODO")
      }
  }

  getTranslations() {
    this.translate.get('yes').subscribe((text) => {
      this.YesNoDataSource[0].label = text;
    });
    this.translate.get('no').subscribe((text) => {
      this.YesNoDataSource[1].label = text;
    });

    this.translate.get('constractors.low').subscribe((text) => {
      this.PriorityDataSource[0].label = text;
    });
    this.translate.get('constractors.medium').subscribe((text) => {
      this.PriorityDataSource[1].label = text;
    });
    this.translate.get('constractors.high').subscribe((text) => {
      this.PriorityDataSource[2].label = text;
    });

    this.translate.get('constractors.contractor').subscribe((text) => {
      this.EmailTypeDataSource[0].label = text;
    });
    this.translate.get('constractors.defaultContact').subscribe((text) => {
      this.EmailTypeDataSource[1].label = text;
    });
    this.translate.get('constractors.allContacts').subscribe((text) => {
      this.EmailTypeDataSource[2].label = text;
    });
    this.translate
      .get('constractors.contractorAndDefaultContact')
      .subscribe((text) => {
        this.EmailTypeDataSource[3].label = text;
      });
    this.translate
      .get('constractors.contractorAndAllContacts')
      .subscribe((text) => {
        this.EmailTypeDataSource[4].label = text;
      });

    this.translate
      .get('constractors.finalRecipientNotReleased')
      .subscribe((text) => {
        this.CustomTypeDataSource[0].label = text;
      });
    this.translate
      .get('constractors.finalRecipientReleased')
      .subscribe((text) => {
        this.CustomTypeDataSource[1].label = text;
      });
    this.translate
      .get('constractors.anIntermediaryCoalEntity')
      .subscribe((text) => {
        this.CustomTypeDataSource[2].label = text;
      });

    this.translate.get('constractors.undefined').subscribe((text) => {
      this.SplitPaymentDataSource[0].label = text;
    });
    this.translate
      .get('constractors.standardPaymentPreferred')
      .subscribe((text) => {
        this.SplitPaymentDataSource[1].label = text;
      });
    this.translate
      .get('constractors.splitPaymentPreferred')
      .subscribe((text) => {
        this.SplitPaymentDataSource[2].label = text;
      });

    this.translate.get('constractors.dontBlock').subscribe((text) => {
      this.BlockingModeDataSource[0].label = text;
    });
    this.translate.get('constractors.block').subscribe((text) => {
      this.BlockingModeDataSource[1].label = text;
    });
    this.translate
      .get('constractors.blockForOverdueReceivables')
      .subscribe((text) => {
        this.BlockingModeDataSource[2].label = text;
      });
    this.translate
      .get('constractors.blockForOverdueReceivablesAboveLimit')
      .subscribe((text) => {
        this.BlockingModeDataSource[3].label = text;
      });
    this.translate
      .get('constractors.blockForAllReceivablesOverLimit')
      .subscribe((text) => {
        this.BlockingModeDataSource[4].label = text;
      });
    this.translate
      .get('constractors.blockForAllReceivablesOverLimitOrOverdue')
      .subscribe((text) => {
        this.BlockingModeDataSource[5].label = text;
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.saveForm();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosed.emit();
        },
        preventDefault: true,
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      data: this.fb.group({
        VatPayerEvidence: 0,
        BuyerLimit: 0,
        PaymentForm: null,
        DaysForDue: 0,
        DaysForCommitment: 0,
        Priority: 2,
        ChangedBy: null,
        DefaultDiscount: 0,
        TraderId: 0,
        DistinguishCode: null,
        BlockingMode: 0,
        IsBlocked: false,
        UseAutoFiscal: false,
        UseGroupPayment: false,
        IsLoyalProgramActive: false,
        IsIncludedInPromotion: false,
        DeliveryFormId: 0,
        CustomType: 0,
        IsActiveVatPayer: false,
        EmailTypeForReports: 0,
        RodoDate: null,
        SplitPaymentPreferred: 0,
        IsSugarPayer: false,
        BlockAfterDays: 0,
        TypeOfCommercialTransaction: null,
      }),
      checks: this.fb.group({
        VatPayerEvidence: false,
        BuyerLimit: false,
        PaymentForm: false,
        DaysForDue: false,
        DaysForCommitment: false,
        Priority: false,
        ChangedBy: false,
        DefaultDiscount: false,
        TraderId: false,
        DistinguishCode: false,
        BlockingMode: false,
        IsBlocked: false,
        UseAutoFiscal: false,
        UseGroupPayment: false,
        IsLoyalProgramActive: false,
        IsIncludedInPromotion: false,
        DeliveryFormId: false,
        CustomType: false,
        IsActiveVatPayer: false,
        EmailTypeForReports: false,
        RodoDate: false,
        SplitPaymentPreferred: false,
        IsSugarPayer: false,
        BlockAfterDays: false,
        TypeOfCommercialTransaction: false,
      }),
    });
  }

  get f() {
    return this.form.controls;
  }

  saveForm() {
    const data = this.form.get('data').value;
    const checks = this.form.get('checks').value;

    for (let property in checks) {
      if (!checks[property]) delete data[property];
    }

    this.appServices
      .postAuth(`customers/additionalOperations/commercialData`, data)
      .subscribe({
        next: () => {
          this.onSaved.emit();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onCheckBoxChanged(e: any, property: string) {
    if (!e.value) {
      if (
        property === 'IsLoyalProgramActive' ||
        property === 'IsBlocked' ||
        property === 'IsIncludedInPromotion' ||
        property === 'CustomType' ||
        property === 'UseGroupPayment' ||
        property === 'IsActiveVatPayer' ||
        property === 'IsSugarPayer'
      ) {
        this.form.get(`data.${property}`).setValue(false);
      } else if (
        property === 'BlockingMode' ||
        property === 'BuyerLimit' ||
        property === 'DaysForDue' ||
        property === 'DaysForCommitment' ||
        property === 'BlockAfterDays' ||
        property === 'CustomType' ||
        property === 'EmailTypeForReports' ||
        property === 'SplitPaymentPreferred'
      ) {
        this.form.get(`data.${property}`).setValue(0);
      } else if (property === 'VatPayerEvidence') {
        this.form.get(`data.${property}`).setValue(1);
      } else if (property === 'Priority') {
        this.form.get(`data.${property}`).setValue(2);
      } else {
        this.form.get(`data.${property}`).setValue(null);
      }
    }
  }

  onChoosedJPK(e) {
    if (e) {
      let codes: string = '';
      for (let el of e) {
        codes += el.TransactionCode + ' ';
      }
      if (codes.length) codes = codes.substr(0, codes.length - 1);

      this.form.get('data.TypeOfCommercialTransaction').setValue(codes);
    } else if (e === null) {
      this.form.get('data.TypeOfCommercialTransaction').setValue(null);
    }
  }

  onChoosedFormPeyment(e) {
    if (e) {
      this.form.get('data.PaymentForm').setValue(e.PaymentFormName);
    } else if (e == null) {
      this.form.get('data.PaymentForm').setValue(null);
    }
  }

  onChoosedUser(e) {
    if (e === null) {
      this.form.get('data.TraderId').setValue(null);
    } else {
      this.form.get('data.TraderId').setValue(e.AppUserId);
    }
  }

  onChoosedDeliveryForm = (e) => {
    if (e === null) {
      this.form.get('data.DeliveryFormId').setValue(null);
    } else {
      this.form.get('data.DeliveryFormId').setValue(e.DeliveryFormId);
    }
  };
}
