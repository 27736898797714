import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { CustomWindowConfig } from '../../../event.model';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../event.service';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { AppServices } from '../../../app-services.service';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';
import {
  DxiColumnModule,
  DxoLoadPanelModule,
  DxoPagingModule,
  DxoScrollingModule,
  DxoSelectionModule,
} from 'devextreme-angular/ui/nested';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../../environments/environment';

interface paramsConfigContractorWasInvitedToTheService {
  companyName: string;
  TIN: string;
}

@Component({
    selector: 'app-establishing-relationships-with-contractor',
    imports: [
        CommonModule,
        DxPopupModule,
        DxScrollViewModule,
        TranslateModule,
        ReactiveFormsModule,
        NgShortcutsComponent,
        DxButtonModule,
        DxTooltipModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        ConfirmDialogComponent,
        DxDataGridModule,
        DxiColumnModule,
        DxoLoadPanelModule,
        DxoPagingModule,
        DxoScrollingModule,
        DxoSelectionModule,
    ],
    templateUrl: './establishing-relationships-with-contractor.component.html',
    styleUrl: './establishing-relationships-with-contractor.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EstablishingRelationshipsWithContractorComponent {
  @Input() isVisible: boolean = false;
  @Input() focusedCustomer: any;
  @Input() mode: string = '';
  @Input() choosedContact = [];
  @Input() blmtPartnerFindid: number | null = null;

  @Output() onClosing = new EventEmitter();

  @ViewChild('name') name;
  @ViewChild('popUp') popUp;

  shortcuts: ShortcutInput[] = [];
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: 850,
      height: 615,
    },
    params: [],
  };
  minWidth = null;
  maxHeight = null;
  loadChildComponent: boolean = false;
  scrollPosition = 0;
  unicalGuid: number;
  title;
  submitted: boolean = false;
  form: FormGroup;
  languages: any[] = [
    { value: 'EN', label: 'Angielski' },
    { value: 'PL', label: 'Polski' },
  ];
  paramsConfigContractorWasInvitedToTheService: WritableSignal<paramsConfigContractorWasInvitedToTheService> =
    signal({
      companyName: '',
      TIN: '',
    });
  configContractorWasInvitedToTheServiceIsVisible: boolean = false;
  partnerFindList: any;
  heightBottomGrid = 0;
  focusedSelectedPartnerFindList: any;
  firstLoadPartnerFindListData = true;
  partnerFindListFocusedRowIndex = -1;

  constructor(
    public event: EventService,
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    private appService: AppServices,
    private cd: ChangeDetectorRef
  ) {
    this.minWidth = this.event.setMinWidthPopUp();
    this.maxHeight = this.event.setMaxHeight;
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.title = this.translate.instant(
      'bussinesLink.titleEstablishingRelationshipWithBusinessLink'
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.mode?.currentValue &&
      changes?.mode?.currentValue !== changes?.mode?.previousValue
    ) {
      if (changes?.mode?.currentValue) {
        this.localStorageData.windowSize.height = '80%';
        this.heightBottomGrid = window.innerHeight * 0.8 - 650;
      }
    }

    if (
      changes?.blmtPartnerFindid?.currentValue &&
      changes?.blmtPartnerFindid?.currentValue !==
        changes?.blmtPartnerFindid?.previousValue
    ) {
      if (changes?.blmtPartnerFindid?.currentValue) {
        setTimeout(() => {
          this.getPartnerFindList(this.blmtPartnerFindid);
        });
      }
    }

    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      if (changes?.isVisible?.currentValue) {
        this.createForm();
      }
    }
  }

  onKeyDown(e) {
    if (e.event.key == 'F10') {
      this.onConfirm();
    }
  }

  setFocus() {
    setTimeout(() => {
      this.name.instance.focus();
    }, 0);
  }

  ngAfterViewInit(): void {
    this.translate
      .get('menu.navigationPanelUser.polishLanguage')
      .subscribe((text) => (this.languages[1].label = text));
    this.translate
      .get('menu.navigationPanelUser.englishLanguage')
      .subscribe((text) => (this.languages[0].label = text));

    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isVisible) {
            this.onClose();
          }
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onConfirm();
        },
        preventDefault: true,
      }
    );
  }

  getPartnerFindList(blmtPartnerFindid) {
    this.partnerFindList = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}businesslink/BlmtPartner/BlmtPartnerFindList?blmtPartnerFindid=${blmtPartnerFindid}`,
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedSelectedPartnerFindList = [];
            return;
          }
          if (this.firstLoadPartnerFindListData) {
            this.firstLoadPartnerFindListData = false;
            this.partnerFindListFocusedRowIndex = 0;
            this.onFocusedRowChanged({
              row: { data: data[this.partnerFindListFocusedRowIndex] },
            });
            this.cd.detectChanges();
          } else {
            this.onFocusedRowChanged({
              row: { data: data[this.partnerFindListFocusedRowIndex] },
            });
            this.cd.detectChanges();
          }
        },
        onAjaxError: this.event.onAjaxDataSourceError,
        loadMethod: 'POST',
      }),
    });
  }

  onFocusedRowChanged(e) {
    try {
      this.focusedSelectedPartnerFindList = [e?.row?.data];
      if (this.mode) {
        this.form.controls.tenantId.setValue(e?.row?.data?.TenantId);
      }
    } catch {}
  }

  visibleChange() {
    this.submitted = false;
    this.event.loadingVisible = false;

    if (!this.isVisible) {
      this.onClosing.emit();
    }
  }

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  createForm() {
    this.form = this.formBuilder.group({
      contractor_id: [0, Validators.required],
      TIN: ['', Validators.required],
      tenantId: '',
      Name: '',
      adminFirstname: ['', Validators.required],
      adminSurname: ['', Validators.required],
      adminMail: ['', Validators.required],
      adminDefaultLanguage: 'PL',
      inviterName: '',
      inviterMail: '',
      skipInvitations: 0,
    });

    this.form.controls.Name.setValue(this.focusedCustomer?.ShortName);
    this.form.controls.contractor_id.setValue(this.focusedCustomer?.CustomerId);
    this.form.controls.TIN.setValue(this.focusedCustomer?.VatNumber.toString());

    if (this.choosedContact.length > 0 && !this.mode) {
      this.form.controls.adminFirstname.setValue(
        this.choosedContact[0].FirstName
      );
      this.form.controls.adminSurname.setValue(this.choosedContact[0].LastName);
      this.form.controls.adminMail.setValue(this.choosedContact[0].Email);
      this.cd.detectChanges();
    }
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  onConfirm() {
    this.submitted = true;
    if (this.form.invalid) {
      this.cd.detectChanges();
      return;
    }
    this.event.loadingVisible = true;
    this.appService
      .postAuth(
        `businesslink/BlmtPartner/BlmtPartnerAddInvite`,
        this.form.value
      )
      .subscribe(
        () => {
          this.event.loadingVisible = false;
          this.paramsConfigContractorWasInvitedToTheService.set({
            companyName: this.form.controls.Name.value,
            TIN: this.form.controls.TIN.value,
          });
          this.configContractorWasInvitedToTheServiceIsVisible = true;
        },
        (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        }
      );
    this.cd.detectChanges();
  }

  onClose() {
    this.mode = '';
    this.choosedContact = [];
    this.onClosing.emit();
    this.isVisible = false;
  }

  cancelContractorWasInvitedToTheService() {
    this.configContractorWasInvitedToTheServiceIsVisible = false;
    if (this.mode === 'many_tenants') {
      return;
    }
    this.isVisible = false;
    this.onClosing.emit();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }
}
