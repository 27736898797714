import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  Input,
} from '@angular/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';

@Component({
    selector: 'app-offices-bank-accounts',
    templateUrl: './offices-bank-accounts.component.html',
    styleUrls: ['./offices-bank-accounts.component.scss'],
    inputs: ['isVisible', 'readOnly', 'selectedId', 'taxOfficeId', 'companyId'],
    standalone: false
})
export class OfficesBankAccountsComponent implements OnInit {
  @Input() bankAccountMode: string;
  @Input() focusedRowBank;

  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('currencyBox') currencyBox;
  @ViewChild('nameInput') nameInput;
  @ViewChild('IsNRB') IsNRB;

  isVisible;
  customerId;
  readOnly;
  selectedId;
  taxOfficeId;
  companyId;

  widthWindow = 500;
  heightWindow = 350;

  shortcuts: ShortcutInput[] = [];
  visibleShortcut;
  form;
  submitted: boolean = false;

  unicalGuid;
  title;
  accountFormat = [
    { value: true, label: '' },
    { value: false, label: '' },
  ];
  mask = '00 0000 0000 0000 0000 0000 0000';
  rules = { 0: /\d/ };
  accountFormatValue = 0;
  warningAccountNumber = false;
  accountNumberNRP;
  recalculatedControlNumber = null;
  isVisibleConfirmCalculateCheckSum = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public formBuilder: FormBuilder
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.createForm();
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.translate
      .get('singleBank.types.standard')
      .subscribe((text) => (this.accountFormat[0].label = text));
    this.translate
      .get('singleBank.types.default')
      .subscribe((text) => (this.accountFormat[1].label = text));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      switch (this.bankAccountMode) {
        case 'add':
          this.title = this.translate.instant('offices.addingBankAccount');
          break;
        case 'edit':
          this.event.loadingVisible = false;
          this.form.patchValue(this.focusedRowBank[0]);
          this.accountNumberNRP = this.focusedRowBank[0].AccountNumber;
          this.title = this.translate.instant('offices.editingBankAccount');
          break;
      }
    }

    if (this.form) {
      this.form.controls.TaxOfficeId.setValue(this.taxOfficeId);
    }
    // if (this.selectedId && this.selectedId.length > 0) {
    //   // this.appService.getAuth(`offices/taxOffices/${this.taxOfficeId}/bankAccounts?taxOfficeId=${this.selectedId[0]}`).subscribe(res => {
    //   //   this.form.patchValue(res.data[0])
    //   //   if(this.IsNRB) this.IsNRB.instance.focus();
    //   // })
    //   this.form.patchValue(this.selectedId[0]);
    // }

    if (this.isVisible) {
      setTimeout(() => {
        this.IsNRB.instance.focus();
      }, 700);
    }
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      TaxOfficeBankAccountId: [0],
      TaxOfficeId: [0],
      CurrencySymbol: ['PLN'],
      Lk: [''],
      AccountNumber: ['', Validators.required],
      IsActive: [true],
      TaxOfficeName: ['', Validators.required],
      BankId: [undefined, Validators.required],
      BankName: [''],
      BankNumber: [''],
      BankCity: [''],
      BankPostalCode: [''],
      BankSwift: [''],
      AccountType: [0],
      IsMicroAccountType: [false],
      IsNRB: [true],
    });

    this.setBankIdValidators();
  };

  setBankIdValidators() {
    const bankNameControl = this.form.get('BankId');
    const isNRBControl = this.form.get('IsNRB');

    if (isNRBControl.value === true) {
      bankNameControl.setValidators([Validators.required]);
    } else {
      bankNameControl.clearValidators();
    }

    bankNameControl.updateValueAndValidity();
  }

  onChoosedBank = (e) => {
    if (e) {
      this.form.controls.BankId.setValue(e[0].BankId);
      this.form.controls.BankName.setValue(e[0].BankName);
      this.form.controls.BankNumber.setValue(e[0].Number);
      this.form.controls.BankCity.setValue(e[0].City);
      this.form.controls.BankPostalCode.setValue(e[0].PostalCode);
      this.form.controls.BankSwift.setValue(e[0].SWIFT);
    } else {
      this.form.controls.BankId.setValue(undefined);
      this.form.controls.BankName.setValue('');
      this.form.controls.BankNumber.setValue('');
      this.form.controls.BankCity.setValue('');
      this.form.controls.BankPostalCode.setValue('');
      this.form.controls.BankSwift.setValue('');
    }
    if (this.currencyBox) {
      this.currencyBox.currencyDropDownBox.instance.focus();
    }
    if (this.form.controls.IsNRB.value == false) {
      let accountNumber = this.accountNumberNRP;
      let newBankCode = e[0].Number;
      let newAccountNumber =
        accountNumber.slice(0, 2) + newBankCode + accountNumber.slice(10, 26);
      this.accountNumberNRP = newAccountNumber;
      return;
    }
    if (!this.form.controls.AccountNumber.value) {
      let bankCode = '  ' + e[0].Number;
      this.form.controls.AccountNumber.setValue(bankCode);
    } else {
      let accountNumber = this.form.controls.AccountNumber.value;
      let newBankCode = e[0].Number;
      let newAccountNumber =
        accountNumber.slice(0, 2) + newBankCode + accountNumber.slice(10, 26);
      this.form.controls.AccountNumber.setValue(newAccountNumber);
      this.accountNumberNRP = newAccountNumber;
    }
  };

  onChoosedCurrency = (e) => {
    this.form.controls.CurrencySymbol.setValue(e.CurrencyCode);
    if (this.nameInput) {
      this.nameInput.instance.focus();
    }
  };

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.submitted = false;
    this.event.disabletShortcuts.next(e);
    if (!e) {
      this.createForm(); //reset form with initial values
      this.onClosing.emit(false);
    }
  };

  onRemovingConfirmCalculateCheckSum() {
    this.form.controls.AccountNumber.setValue(
      this.recalculatedControlNumber +
        this.form.value.AccountNumber.substring(2)
    );
    this.form.controls.Lk.setValue(this.recalculatedControlNumber);
    this.isVisibleConfirmCalculateCheckSum = false;
    this.saveAftrerConfirmCalculateCheckSum();
  }

  isCancelConfirmCalculateCheckSum() {
    this.isVisibleConfirmCalculateCheckSum = false;
    this.form.controls.Lk.setValue(
      this.form.value.AccountNumber.substring(0, 2)
    );
    this.saveAftrerConfirmCalculateCheckSum();
  }

  onSave = () => {
    this.submitted = true;
    this.setBankIdValidators();

    if (
      (this.form.get('AccountNumber').value.length != 26 ||
        this.form.get('AccountNumber').value.substring(0, 2) == '  ') &&
      this.form.controls.IsNRB.value
    ) {
      this.warningAccountNumber = true;
      return;
    } else {
      if (!this.form.controls.IsNRB.value) this.form.controls.Lk.setValue('');
    }

    this.recalculatedControlNumber = this.event.calculateChecksum(
      this.form.value.AccountNumber
    );

    if (
      this.recalculatedControlNumber !=
        this.form.value.AccountNumber.substring(0, 2) &&
      this.form.controls.IsNRB.value
    ) {
      this.isVisibleConfirmCalculateCheckSum = true;
      return;
    }

    this.saveAftrerConfirmCalculateCheckSum();
  };

  saveAftrerConfirmCalculateCheckSum() {
    if (this.form.valid && !this.readOnly) {
      if (this.form.value.TaxOfficeId == 0) {
        // this.onInserted.emit(this.form.value);
        this.isVisible = false;
      } else if (this.form.get('TaxOfficeBankAccountId').value == 0) {
        this.appService
          .postAuth(
            `offices/taxOffices/${this.taxOfficeId}/bankAccounts`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else {
        this.appService
          .putAuth(
            `offices/taxOffices/${this.taxOfficeId}/bankAccounts/${
              this.form.get('TaxOfficeBankAccountId').value
            }`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  }

  accountFormatChanged(e) {
    if (!e.event) {
      return;
    }
    this.accountFormatValue = e.value;
    if (e.value == false) {
      this.accountNumberNRP = this.form.controls.AccountNumber.value;
      this.form.controls.IsNRB.setValue(false);
      this.form.get('AccountNumber').value = '';
    } else if (e.value && !e.previousValue) {
      this.form.controls.IsNRB.setValue(true);
      this.form.controls.AccountNumber.setValue(this.accountNumberNRP);
    }

    this.setBankIdValidators();
  }

  cancelWarningAccountNumber() {
    this.warningAccountNumber = false;
  }

  changedAccountType(e: any) {
    if (e) {
      this.form.controls.IsMicroAccountType.setValue(true);
    } else {
      this.form.controls.IsMicroAccountType.setValue(false);
    }
  }

  private previousInputBankNumber: string = '';

  onBankNumberChanged() {
    this.form.controls.TaxOfficeName.setValue(this.form.value.AccountNumber);

    const inputBankNumber = this.form.value.AccountNumber.slice(2, 10);

    if (
      inputBankNumber.length >= 8 &&
      inputBankNumber !== this.previousInputBankNumber
    ) {
      this.previousInputBankNumber = inputBankNumber;

      this.appService
        .getAuth(`banks?number=${inputBankNumber}`)
        .subscribe((res) => {
          if (res?.data?.length) {
            this.form.controls.BankId.setValue(res.data[0].BankId);
            this.form.controls.BankName.setValue(res.data[0].BankName);
            this.cd.detectChanges();
          }
        });
    }
  }
}
