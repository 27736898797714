<dx-popup
  *ngIf="isPopupMode"
  [width]="popupWidth"
  height="90%"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  title="{{ 'articles.title' | translate }}"
  [(visible)]="isVisible"
  [focusStateEnabled]="false"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); setFocus()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (onInitialized)="onPopupInitialized($event)"
>
  <div *dxTemplate="let content of 'content'" class="bottom-grid">
    <ng-container *ngIf="componentVisible">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onPopupPrimaryButtonClick()"
          [id]="'choose-nest' + unicalGuid"
          [disabled]="readOnly"
        >
        </dx-button>

        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#choose-nest' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.choose" | translate }} (Enter)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.cancel" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <div class="top-menu-popup" style="margin-bottom: 10px">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </ng-container>
  </div>
</dx-popup>

<ng-container *ngIf="!isPopupMode">
  <div *ngIf="!dropDownBoxMode; else dropDownMode">
    <ng-container *ngIf="componentVisible">
      <div
        [ngClass]="{ 'top-menu-popup': execType, 'top-menu-panel': !execType }"
      >
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
      <!--  "class="top-menu-panel" -->
      <div class="center-panel-scroll-verticall">
        <div
          class="center-panel-scroll-verticall"
          *ngIf="event.deviceType != 'mobile'; else mobileTemplate"
        >
          <ng-container *ngTemplateOutlet="grid"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #dropDownMode>
  <dx-drop-down-box
    #articlesBox
    valueExpr="ProductId"
    [deferRendering]="false"
    displayExpr="ProductFullName"
    [width]="276"
    [showClearButton]="true"
    [readOnly]="true"
    [acceptCustomValue]="true"
    [openOnFieldClick]="false"
  >
    <div
      *dxTemplate="let data of 'content'"
      style="min-height: 300px; height: 300px"
    ></div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <div class="d-inline-block articles-step-2">
      <app-wapro-selected-box
        [(ngModel)]="selectedRowKeys"
        [dataSource]="dataSource"
        *ngIf="!execType"
        [selectedAll]="selectedAll"
        (onDeselectAll)="onDeselectAll()"
        (onOnlySelected)="onOnlySelected()"
      >
      </app-wapro-selected-box>

      <dx-button
        [id]="'analyze' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="isAnalyzeInProgress || readOnly"
        type="default"
        stylingMode="text"
        [text]="'buttons.analyze' | translate"
        (onClick)="analyze()"
      >
      </dx-button>

      <!-- <app-reports
        #reports
        [code]=""
        [currencyCode]="null"
        [menuName]=""
        [type]=""
        [objectId]="focusedArticle"
        [selectedDocumentsData]=""
        (onForceRefresh)="getData()"
      >
      </app-reports> -->

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--print"
        (onClick)="printReport()"
        style="margin-right: 6px"
        [id]="'print-report' + unicalGuid"
        [disabled]="!analizedReportId || readOnly"
      >
      </dx-button>

      <app-btn-export-excel
        [gridReference]="gridBank"
        [nameFile]="'menu.articles' | translate"
        url="StockAvailability"
        [paramsObj]="paramsObj"
        *ngIf="!isPopupMode && !execType"
        [readOnly]="!focusedArticle"
      ></app-btn-export-excel>

      <!-- <app-additional-operation
        #additionalOperation
        *ngIf="
          !chooseVariantMode &&
          (componentMode === null || componentMode === 'stockAndReservations')
        "
        nameComponent="ArticlesComponent"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="
          contextMenu[10].items = event.onSetAdditionalOperation($event)
        "
      >
      </app-additional-operation> -->
    </div>
    <!-- tooltips -->

    <dx-tooltip
      *ngIf="!isAnalyzeInProgress"
      [target]="'#analyze' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="true"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "articles.analyze" | translate }} (Ctrl + G)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#print-report' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "print" | translate }} (Ctrl + D)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--control-date-time"
        (onClick)="openDateFilters()"
        style="margin-right: 6px"
        [id]="'open-date-filters' + unicalGuid"
        [disabled]="readOnly"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        type="default"
        stylingMode="text"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (click)="refresh()"
        type="default"
        stylingMode="text"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder articles-step-3"
        icon="column-chooser"
        type="default"
        stylingMode="text"
        [disabled]="!additionalPermissions.konfKol"
        (click)="columnsChooserVisible = true"
        *ngIf="!dropDownBoxMode"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#open-date-filters' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "articles.dateFilter" | translate }} (F7)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-show-dynamic-filters' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "filterPanel.advancedFilter" | translate }} (F8)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "menu.navigationPanelUser.help" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSettings" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          [ngClass]="{
            without_search_panel: dropDownBoxMode,
            with_search_panel: !dropDownBoxMode
          }"
          [ngStyle]="{
            width: localStorageData?.mainPanelWidth
          }"
          #mainPanel
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="isCustomDropDownOpened"
                #customSearch
                class="articles-step-4"
                [items]="filterCriteria"
                [selectedItem]="localStorageData?.filter?.orderBy"
                [filterValue]="filterValue"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px"
                (onArrowDown)="gridBank.instance.focus()"
              >
              </app-custom-dropdown-box>
              <ng-container *ngTemplateOutlet="mainToolbarPanel"></ng-container>

              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [id]="'grid' + unicalGuid"
                [allowColumnResizing]="event.deviceType != 'mobile'"
                [columnAutoWidth]="true"
                (onKeyDown)="onKeyDown($event)"
                [height]="
                  isPopupMode || execType ? heightGrid - 80 : heightGrid
                "
                [(selectedRowKeys)]="selectedRowKeys"
                [ngClass]="{
                  dropDownBoxClassGrid: dropDownBoxMode,
                }"
                [autoNavigateToFocusedRow]="true"
                [remoteOperations]="true"
                (onContentReady)="onGridContentReady($event)"
                [columnResizingMode]="'widget'"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [(focusedRowKey)]="focusedArticle"
                [focusedRowEnabled]="true"
                #gridBank
                (onOptionChanged)="event.onOptionChanged($event)"
                (onRowClick)="onRowClick($event)"
                [width]="'100%'"
              >
                <!--
              [(focusedRowIndex)]="focusedRowIndex" -->
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="infinite"
                  rowRenderingMode="virtual"
                  columnRenderingMode="virtual"
                  [preloadEnabled]="true"
                  [renderAsync]="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                <dxo-sorting mode="none"></dxo-sorting>

                <dxi-column
                  caption="{{ 'companies.grid.columns.name' | translate }}"
                  alignment="left"
                  [cssClass]="
                    localStorageData?.filter?.orderBy ===
                      'ProductFullNameIntuition' ||
                    localStorageData?.filter?.orderBy === 'ProductNameIntuition'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="nameHeaderTemplate"
                  [width]="event.deviceType == 'mobile' ? 200 : 280"
                  [allowSorting]="false"
                  dataField="ProductName"
                  [renderAsync]="true"
                >
                </dxi-column>

                <div *dxTemplate="let data of 'nameHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('ProductFullNameIntuition')"
                  >
                    {{ "companies.grid.columns.name" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData?.filter?.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'articles.catalogueIndex' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="
                    localStorageData?.filter?.orderBy ===
                    'IndexCatalogueIntuition'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  dataField="IndexCatalogue"
                  headerCellTemplate="IndexCatalogueHeaderTemplate"
                  [renderAsync]="true"
                  [hidingPriority]="27"
                >
                </dxi-column>

                <div *dxTemplate="let cell of 'IndexCatalogueHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('IndexCatalogueIntuition')"
                  >
                    {{ cell.column.caption | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData?.filter?.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'articles.tradingIndex' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  dataField="IndexTrading"
                  [cssClass]="
                    localStorageData?.filter?.orderBy ===
                    'IndexTradingIntuition'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="IndexTradingHeaderTemplate"
                  [renderAsync]="true"
                  [hidingPriority]="26"
                >
                </dxi-column>

                <div *dxTemplate="let cell of 'IndexTradingHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('IndexTradingIntuition')"
                  >
                    {{ cell.column.caption | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData?.filter?.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'articles.totalRevenue' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="IncomeAmount"
                  cellTemplate="numberPrecisionTemplate"
                  [renderAsync]="true"
                  [hidingPriority]="25"
                >
                </dxi-column>
                <dxi-column
                  caption="{{
                    'dataCollectors.positionsGrid.unit' | translate
                  }}"
                  width="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Unit"
                  [renderAsync]="true"
                  [hidingPriority]="24"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'articles.timeStampOfFirstSale' | translate }}"
                  width="140"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TimeStampOfFirstSale"
                  [renderAsync]="true"
                  [hidingPriority]="23"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'articles.timeStampDateSale' | translate }}"
                  width="140"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TimeStampDateSale"
                  [renderAsync]="true"
                  [hidingPriority]="22"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'articles.suppliesAmount' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  dataField="StockState"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="numberPrecisionTemplate"
                  [renderAsync]="true"
                  [hidingPriority]="21"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'articles.suppliesValue' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalAmount"
                  cellTemplate="numberPrecisionTemplate"
                  [renderAsync]="true"
                  [hidingPriority]="20"
                >
                </dxi-column>
                <div *dxTemplate="let cell of 'numberPrecision4Template'">
                  {{ cell.value | amountFormatter : cell : 4 }}
                </div>
                <div *dxTemplate="let cell of 'amountTemplate'">
                  {{
                    cell.value | amountFormatter : cell : (withoutComma ? 0 : 4)
                  }}
                </div>
                <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                  {{ cell.value | amountFormatter : cell }}
                </div>
              </dx-data-grid>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.asc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "columnSorting.asc" | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.desc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "columnSorting.desc" | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                [target]="'#date-filter-chips' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "articles.dateFilter" | translate }} (F7)
                </div>
              </dx-tooltip>
            </div>
          </div>

          <div class="row">
            <div
              class="bottom-side-panel"
              mwlResizable
              *ngIf="!dropDownBoxMode"
              #bottomSidePanel
              (resizeStart)="onResizeStartBottom()"
              (resizing)="resizingBottom($event)"
              (resizeEnd)="resizeEndBottom()"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down':
                      localStorageData?.bottomPanel?.isVisible,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up':
                      !localStorageData?.bottomPanel?.isVisible,
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>

                <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
              </div>
            </div>
          </div>
        </div>
        <div
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          (resizeEnd)="resizeEnd()"
          #sidePanel
          [ngStyle]="{
            'margin-top': execType ? '40px' : 0,
            width: localStorageData?.sidePanel?.isVisible
              ? localStorageData?.sidePanel?.width + 'px'
              : '10px'
          }"
        >
          <div class="right-panel-btn">
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="resizeIcon"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>

          <ng-container *ngTemplateOutlet="sidePanelBox"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sidePanelBox>
  <dx-tab-panel
    [width]="localStorageData?.sidePanel?.width"
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[1, 2, 3]"
    [(selectedIndex)]="localStorageData?.sidePanel.selectedIndex"
    style="margin-top: -20px"
    class="filter-panel right-panel-box production-documents-step-5"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <span *ngIf="item == 1">{{
          "articlesTab.categories" | translate
        }}</span>
        <span *ngIf="item == 2">{{
          "articlesTab.multiLevelCategories" | translate
        }}</span>
        <i class="icon absui-icon--filter" *ngIf="item == 3"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-tree-list
            [dataSource]="categoriesList"
            class="tree-list-as-tree-view"
            [autoExpandAll]="true"
            [ngStyle]="{
              width:
                localStorageData?.sidePanel?.width &&
                localStorageData?.sidePanel?.width > 50
                  ? localStorageData?.sidePanel?.width - 50 + 'px'
                  : 'calc(100% - 5px)',
              maxWidth: 'calc(100% - 5px)',
              height: 'calc(100vh - 165px)'
            }"
            [ngClass]="{ 'drop-highlight': highlightDrop }"
            [selectedRowKeys]="categoriesSelectedRowKeys"
            (onSelectionChanged)="onCategorySelectionChanged($event)"
            keyExpr="CategoryId"
            parentIdExpr="parentId"
            #categoriesTree
            (onInitialized)="restoreTreeConfig('productCategoryName')"
          >
            <dxi-column
              dataField="ProductCategoryName"
              caption=" "
            ></dxi-column>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-row-dragging
              data="2"
              group="tasksGroup"
              [onDragStart]="onDragStart"
              [onAdd]="onAdd"
              [allowDropInsideItem]="true"
              [showDragIcons]="false"
              [allowReordering]="false"
            >
            </dxo-row-dragging>
          </dx-tree-list>
        </div>
        <div *ngSwitchCase="2">
          <dx-tree-list
            [selectedRowKeys]="categoriesTreeSelectedRowKeys"
            [dataSource]="groupsList"
            class="tree-list-as-tree-view"
            dataStructure="tree"
            itemsExpr="Categories"
            [ngStyle]="{
              width:
                localStorageData?.sidePanel?.width &&
                localStorageData?.sidePanel?.width > 50
                  ? localStorageData?.sidePanel?.width - 50 + 'px'
                  : 'calc(100% - 5px)',
              maxWidth: 'calc(100% - 5px)',
              height: 'calc(100vh - 165px)'
            }"
            [ngClass]="{ 'drop-highlight': highlightDrop }"
            (onSelectionChanged)="onCategoryTreeSelectionChanged($event)"
            [autoExpandAll]="true"
            keyExpr="CategoryTreeId"
            parentIdExpr="ParentCategoryTreeId"
            #multiLevelCategoriesTree
            id="multiLevelCategoriesTree"
            (onInitialized)="restoreTreeConfig('productCategoryTreeName')"
          >
            <dxi-column dataField="CategoryName" caption=" "></dxi-column>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-row-dragging
              data="3"
              group="tasksGroup"
              [onDragStart]="onDragStart"
              [onAdd]="onAddTree"
              [allowDropInsideItem]="true"
              [showDragIcons]="false"
              [allowReordering]="false"
            >
            </dxo-row-dragging>
          </dx-tree-list>
        </div>
        <div *ngSwitchCase="3">
          <app-filter-panel
            [deleteFilters]="deleteFilter"
            [openPanelFilters]="visibleAdvanceFilters"
            (onFilterSave)="onFilterSaved($event)"
            [dictionaryCode]="DynamicFilterDictionaryCode"
            [selectedTemplate]="
              localStorageData?.advancedFilter?.FilterUsersDefinitionId
            "
            [isPinned]="localStorageData?.advancedFilter?.IsPinned"
            (onTemplatePinChanged)="onTemplatePinChanged($event)"
            [panelWidth]="localStorageData?.sidePanel?.width"
            [tabActive]="
              localStorageData?.sidePanel?.isVisible &&
              localStorageData?.sidePanel?.selectedIndex == 2
            "
            [values]="
              localStorageData?.advancedFilter?.UserFilterDefinitionValues
            "
            [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
          >
          </app-filter-panel>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    [items]="bottomPanelTabs"
    [(selectedIndex)]="localStorageData?.bottomPanel.selectedIndex"
    (onSelectionChanged)="onSelectionTabChanged()"
    [loop]="false"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    (onTitleClick)="onItemClickTab()"
    class="bottomTabPanelMobile articles-step-7"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div>
        <span [id]="'tabToolTip' + i + unicalGuid">{{ name.title }}</span>
      </div>
      <dx-tooltip
        [target]="'#tabToolTip' + i + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">
          (Alt+{{ i + 1 }})
        </div>
      </dx-tooltip>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container
        [ngSwitch]="name.title"
        *ngIf="localStorageData?.bottomPanel?.isVisible"
      >
        <div *ngSwitchCase="'tasks.details' | translate">
          <dx-data-grid
            [dataSource]="suppliesDataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            [allowColumnReordering]="true"
            [id]="'suppliesGrid' + unicalGuid"
            [allowColumnResizing]="event.deviceType != 'mobile'"
            [columnAutoWidth]="true"
            (onKeyDown)="onDetailsKeyDown($event)"
            [height]="heightBottomGrid"
            [(selectedRowKeys)]="detailsSelectedRowKeys"
            [autoNavigateToFocusedRow]="true"
            [remoteOperations]="true"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedDetailsRowChanged($event)"
            [(focusedRowKey)]="focusedSupply"
            [focusedRowEnabled]="true"
            #gridBank
            (onOptionChanged)="event.onOptionChanged($event)"
            (onRowClick)="onDetailsRowClick($event)"
            [width]="'100%'"
            keyExpr="PositionId"
          >
            <!--
              [(focusedRowIndex)]="focusedRowIndex" -->
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              rowRenderingMode="virtual"
              columnRenderingMode="virtual"
              [preloadEnabled]="true"
              [renderAsync]="true"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxo-sorting mode="none"></dxo-sorting>

            <dxi-column
              caption="{{ 'Data dostawy' }}"
              alignment="left"
              [width]="120"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="DeliveryDate"
              [renderAsync]="true"
              cellTemplate="dateTemplate"
            >
            </dxi-column>
            <div *dxTemplate="let cell of 'dateTemplate'">
              {{ cell.value | date : "yyyy-MM--dd" }}
            </div>
            <dxi-column
              [hidingPriority]="6"
              caption="{{ 'K' | translate }}"
              width="40"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="K"
              [renderAsync]="true"
            >
            </dxi-column>
            <dxi-column
              [hidingPriority]="5"
              caption="{{ 'Dostawca' | translate }}"
              [width]="event.deviceType == 'mobile' ? 200 : 280"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="CustomerName"
              [renderAsync]="true"
            >
            </dxi-column>
            <dxi-column
              [hidingPriority]="4"
              caption="{{ 'articles.totalRevenue' | translate }}"
              width="120"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="IncomeAmount"
              [renderAsync]="true"
            >
            </dxi-column>
            <dxi-column
              [hidingPriority]="0"
              caption="{{ 'j.m.' | translate }}"
              width="60"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="Unit"
              [renderAsync]="true"
              [hidingPriority]="27"
            >
            </dxi-column>
            <dxi-column
              [hidingPriority]="3"
              caption="{{ 'articles.timeStampOfFirstSale' | translate }}"
              width="140"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="TimeStampOfFirstSale"
              [renderAsync]="true"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'articles.timeStampDateSale' | translate }}"
              width="120"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="TimeStampDateSale"
              [renderAsync]="true"
              [hidingPriority]="2"
            >
            </dxi-column>
            <dxi-column
              [hidingPriority]="1"
              caption="{{ 'articles.suppliesAmount' | translate }}"
              width="120"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="StockState"
              [renderAsync]="true"
            >
            </dxi-column>
            <dxi-column
              [hidingPriority]="0"
              caption="{{ 'articles.suppliesValue' | translate }}"
              width="120"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="TotalAmount"
              [renderAsync]="true"
            >
            </dxi-column>
          </dx-data-grid>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [openDropDown]="isCustomDropDownOpened"
      #customSearch
      class="articles-step-4"
      [items]="filterCriteria"
      [selectedItem]="localStorageData?.filter?.orderBy"
      [filterValue]="filterValue"
      (onValueChanged)="onFilterDataChanged($event)"
      style="margin-right: 5px"
      (onArrowDown)="gridBank.instance.focus()"
    >
    </app-custom-dropdown-box>
    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getData()"
      ></i>
    </dx-button>
  </div>

  <ng-container *ngTemplateOutlet="mainToolbarMobilePanel"></ng-container>

  <dx-data-grid
    class="main-mobile-grid"
    [dataSource]="dataSource"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    [allowColumnReordering]="true"
    [id]="'grid' + unicalGuid"
    [allowColumnResizing]="event.deviceType != 'mobile'"
    [columnAutoWidth]="true"
    (onKeyDown)="onKeyDown($event)"
    [height]="isPopupMode || execType ? heightGrid - 80 : heightGrid"
    [(selectedRowKeys)]="selectedRowKeys"
    [ngClass]="{
                  dropDownBoxClassGrid: dropDownBoxMode,
                }"
    [autoNavigateToFocusedRow]="true"
    [remoteOperations]="true"
    (onContentReady)="onGridContentReady($event)"
    [columnResizingMode]="'widget'"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [(focusedRowKey)]="focusedArticle"
    [focusedRowEnabled]="true"
    #gridBank
    (onOptionChanged)="event.onOptionChanged($event)"
    (onRowClick)="onRowClick($event)"
    [width]="'100%'"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      [preloadEnabled]="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

    <dxi-column
      caption="{{ 'companies.grid.columns.name' | translate }}"
      [width]="200"
      [allowSorting]="false"
      dataField="ProductName"
      cellTemplate="mainColumn"
    >
    </dxi-column>
    <div *dxTemplate="let data of 'mainColumn'">
      <div style="display: flex">
        <div class="content-main-grid">
          <span class="hov-underline-mobile">{{ data.data.ProductName }}</span>

          <div class="flex">
            <label class="mobile-grid-label"
              >{{ "articles.catalogueIndex" | translate }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.IndexCatalogue }}</p>
          </div>

          <div class="flex">
            <label class="mobile-grid-label"
              >{{ "articles.tradingIndex" | translate }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.IndexTrading }}</p>
          </div>

          <div class="flex">
            <label class="mobile-grid-label"
              >{{ "dataCollectors.positionsGrid.unit" | translate }}:</label
            >
            <p class="mobile-grid-text">{{ data.data.Unit }}</p>
          </div>

          <div class="flex">
            <label class="mobile-grid-label"
              >{{ "articles.suppliesAmount" | translate }}:</label
            >
            <p class="mobile-grid-text">
              {{ data.data.StockState | amountFormatter : null }}
            </p>
          </div>

          <div class="flex">
            <label class="mobile-grid-label"
              >{{ "articles.suppliesValue" | translate }}:</label
            >
            <p class="mobile-grid-text">
              {{ data.data.TotalAmount | amountFormatter : null }}
            </p>
          </div>
        </div>

        <div class="right-mobile-grid text-center">
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="isContextMenuMobile = true"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobile"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px"
            (click)="isDetails = false"
          ></i>
        </div>
        <div class="right-details">
          <div>
            <label class="details-label"
              >{{ "companies.grid.columns.name" | translate }}:</label
            >
            <p class="nameSelectedPosition">
              {{ focusedArticleData?.ProductName }}
            </p>
          </div>

          <div>
            <label class="details-label"
              >{{ "articles.catalogueIndex" | translate }}:</label
            >
            <p class="details-text">{{ focusedArticleData?.IndexCatalogue }}</p>
          </div>

          <div>
            <label class="details-label"
              >{{ "articles.tradingIndex" | translate }}:</label
            >
            <p class="details-text">{{ focusedArticleData?.IndexTrading }}</p>
          </div>

          <!--          <div>-->
          <!--            <label class="details-label"-->
          <!--            >{{ "articles.totalRevenue" | translate }}:</label>-->
          <!--            <p class="details-text">{{ focusedArticleData?.IncomeAmount | amountFormatter : null }}</p>-->
          <!--          </div>-->

          <div>
            <label class="details-label"
              >{{ "dataCollectors.positionsGrid.unit" | translate }}:</label
            >
            <p class="details-text">{{ focusedArticleData?.Unit }}</p>
          </div>

          <!--          <div>-->
          <!--            <label class="details-label"-->
          <!--            >{{ "articles.timeStampOfFirstSale" | translate }}:</label>-->
          <!--            <p class="details-text">{{ focusedArticleData?.TimeStampOfFirstSale }}</p>-->
          <!--          </div>-->

          <!--          <div>-->
          <!--            <label class="details-label"-->
          <!--            >{{ "articles.timeStampDateSale" | translate }}:</label>-->
          <!--            <p class="details-text">{{ focusedArticleData?.TimeStampDateSale }}</p>-->
          <!--          </div>-->

          <div>
            <label class="details-label"
              >{{ "articles.suppliesAmount" | translate }}:</label
            >
            <p class="details-text">
              {{ focusedArticleData?.StockState | amountFormatter : null }}
            </p>
          </div>

          <div>
            <label class="details-label"
              >{{ "articles.suppliesValue" | translate }}:</label
            >
            <p class="details-text">
              {{ focusedArticleData?.TotalAmount | amountFormatter : null }}
            </p>
          </div>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ "tasks.details" | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; vertical-align: middle">
              <p class="details-label text-right">
                {{ "articles.totalRevenue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: middle; padding-left: 8px">
              <p class="details-text">
                {{ focusedArticleData?.IncomeAmount | amountFormatter : null }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: middle">
              <p class="details-label text-right">
                {{ "articles.timeStampOfFirstSale" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: middle; padding-left: 8px">
              <p class="details-text">
                {{ focusedArticleData?.TimeStampOfFirstSale }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: middle">
              <p class="details-label text-right">
                {{ "articles.timeStampDateSale" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: middle; padding-left: 8px">
              <p class="details-text">
                {{ focusedArticleData?.TimeStampDateSale }}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
  </div>
</ng-template>

<ng-template #mainToolbarMobilePanel>
  <dx-scroll-view
    width="100%"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="onScroll"
  >
    <div style="width: 1000px; padding-top: 2px">
      <ng-container *ngTemplateOutlet="mainToolbarPanel"></ng-container>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #mainToolbarPanel>
  <app-date-range
    #dateRange
    [readOnly]="readOnly"
    [isPopupMode]="true"
    (onChoosed)="onDateRangeChoosed($event)"
    [chipsVisible]="true"
  >
  </app-date-range>

  <dx-button
    class="btn-filter-box chips-btn"
    *ngIf="
      localStorageData?.tree[0]?.productCategoryName &&
      localStorageData?.tree[0]?.productCategoryId != -1
    "
    (click)="openRightPanel(0)"
    >{{ "articles.category" | translate }}:
    <b>{{ localStorageData?.tree[0]?.productCategoryName }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('productCategoryName')"
    ></i>
  </dx-button>

  <dx-button
    [id]="'date-filter-chips' + unicalGuid"
    class="btn-filter-box chips-btn"
    *ngIf="isDateFilterActive"
    (click)="openDateFilters()"
    >{{ "articles.dateFilter" | translate }}:
    <b>{{ "warehouse.active" | translate }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('dateFilter')"
    ></i>
  </dx-button>

  <app-custom-chips-button
    [deleteFilter]="deleteFilter"
    name="{{ 'articles.inputServices' | translate }}"
    [list]="yesNoDataSource"
    (onValueChanged)="onInputSericesValueChanged($event)"
    [selectedValueInput]="inputServices"
    [customDisplayValue]="
      inputServices ? ('yes' | translate) : ('no' | translate)
    "
  >
  </app-custom-chips-button>

  <app-custom-chips-button
    [deleteFilter]="deleteFilter"
    name="{{ 'articles.average' | translate }}"
    [list]="yesNoDataSource"
    (onValueChanged)="onIsAverageValueChanged($event)"
    [selectedValueInput]="isAverage"
    [customDisplayValue]="isAverage ? ('yes' | translate) : ('no' | translate)"
  >
  </app-custom-chips-button>

  <dx-button
    class="btn-filter-box chips-btn"
    *ngIf="
      localStorageData?.tree[0]?.productCategoryName &&
      localStorageData?.tree[0]?.productCategoryId != -1
    "
    (click)="openRightPanel(0)"
    >{{ "articles.category" | translate }}:
    <b>{{ localStorageData?.tree[0]?.productCategoryName }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('productCategoryName')"
    ></i>
  </dx-button>
  <dx-button
    class="btn-filter-box chips-btn"
    *ngIf="
      localStorageData?.tree[1]?.productCategoryName &&
      localStorageData?.tree[1]?.productCategoryId != -1
    "
    (click)="openRightPanel(1)"
  >
    {{ "articles.multiLevelCategory" | translate }}:
    <b>{{ localStorageData?.tree[1]?.productCategoryName }}</b>
    <i
      class="icon absui-icon--multiplication chips-close"
      (click)="resetFiltr('productCategoryTreeName')"
    ></i>
  </dx-button>
  <app-custom-chips-button
    [deleteFilter]="deleteFilter"
    *ngIf="
      localStorageData?.tree[1]?.productCategoryName &&
      localStorageData?.tree[1]?.productCategoryId != -1
    "
    name="{{ 'articles.includeSubcategories' | translate }}"
    [list]="yesNoDataSource"
    (onValueChanged)="onValueChangedSubcategory($event)"
    [selectedValueInput]="localStorageData?.tree[1] || null"
    [customDisplayValue]="
      localStorageData?.tree[1]?.categoryTreeWithChilds != null
        ? localStorageData?.tree[1]?.categoryTreeWithChilds
          ? ('yes' | translate)
          : ('no' | translate)
        : ''
    "
  >
  </app-custom-chips-button>

  <app-custom-chips-button
    *ngIf="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
      localStorageData?.advancedFilter?.IsPinned
    "
    [name]="'filterPanel.template' | translate"
    [labelExpr]="'Name'"
    [valueExpr]="'FilterUsersDefinitionId'"
    [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
    [list]="dynamicFilterUserDefinitions"
    (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
  >
  </app-custom-chips-button>

  <dx-button
    [ngStyle]="
      event.deviceType != 'mobile'
        ? { 'margin-top': '0px' }
        : { 'margin-top': '-9px', 'padding-left': '16px' }
    "
    [id]="'clearValues' + unicalGuid"
    class="btn-filter-box"
    icon="icon absui-icon--clear-filter"
    (onClick)="clearValues()"
  ></dx-button>
  <dx-tooltip
    [target]="'#clearValues' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "clearAllFilters" | translate }} (Ctrl + F10)
    </div>
  </dx-tooltip>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [maxHeight]="event.maxHeightSignal()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="sidePanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333"
        icon="icon absui-icon--clear-filter"
        (onClick)="clearValues()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <dx-button
      [id]="'analyze' + unicalGuid"
      class="btn-dx-top-menu"
      [disabled]="isAnalyzeInProgress || readOnly"
      type="default"
      stylingMode="text"
      [text]="'buttons.analyze' | translate"
      (onClick)="analyze()"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0"
    ></dx-button>
  </div>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
  >
  </app-filter-panel>
</div>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="visibleAdvanceFiltersWithoutPanel || isDateFiltersVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-date-filter
  [isVisible]="true"
  *ngIf="isDateFiltersVisible"
  (onClosing)="onDateFilterClosing()"
  (onSaving)="onDateFilterSaving($event)"
  [values]="dateFilter"
></app-date-filter>
