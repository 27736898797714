<dx-drop-down-box
  valueExpr="TrackingUrlId"
  [deferRendering]="false"
  displayExpr="WebAdres"
  [(value)]="choosingTracking"
  [(opened)]="isGridBoxOpened"
  [dataSource]="dataSourceDrop"
  [showClearButton]="false"
  style="width: 276px"
  (onValueChanged)="onValueChanged($event)"
  class="contractors-form-step-2"
  (openedChange)="onOpenedChanged($event)"
  (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
  [readOnly]="readOnly"
>
  <div *dxTemplate="let data of 'content'" style="height: 280px">
    <!-- <div class="top-menu-popup">
            <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="grid"></ng-container> -->
    <div class="row">
      <div class="col-md-12" class="no-padding" *ngIf="isGridBoxOpened">
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="true"
          [showBorders]="true"
          shortcut
          [height]="258"
          class="dropDownBoxClassGrid"
          [columnResizingMode]="'widget'"
          [(focusedRowIndex)]="focusedRowIndex"
          [remoteOperations]="true"
          (onRowClick)="onRowClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          [focusedRowEnabled]="true"
          #gridClass
          [hoverStateEnabled]="true"
          (onKeyDown)="onKeyDown($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection [mode]="'single'" showCheckBoxesMode="always">
          </dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            [width]="event.deviceType != 'mobile' ? 45 : 10"
            cellTemplate="editTemplate"
            [allowSorting]="false"
            alignment="center"
            renderAsync="true"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
            <dx-button
              class="btn-dx-top-menu-folder-grid"
              icon="icon absui-icon--mode-edit hover-edit-icon"
              [disabled]="readOnly"
              (onClick)="openSmsTrackingForm('edit')"
            >
            </dx-button>
          </div>

          <dxi-column
            [width]="100"
            [allowSorting]="false"
            renderAsync="true"
            dataField="Name"
            [caption]="'companies.grid.columns.name' | translate"
          >
          </dxi-column>

          <dxi-column
            [width]="100"
            [allowSorting]="false"
            renderAsync="true"
            dataField="WebAdres"
            [caption]="'singleBank.form.www' | translate"
          >
          </dxi-column>

          <dxi-column
            [allowSorting]="false"
            renderAsync="true"
            dataField="DateAndTime"
            [caption]="'buffor.dateAndTime' | translate"
            dataType="date"
            [format]="{ type: 'yyyy-MM;dd HH:mm' }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            [allowSorting]="false"
            renderAsync="true"
            dataField="Description"
            [caption]="'form-financial-document.description' | translate"
            width="110"
          >
          </dxi-column>
        </dx-data-grid>
      </div>
    </div>

    <div class="bottom-drop-box">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [id]="'btn-drop-add' + unicalGuid"
        [text]="'buttons.add' | translate"
        [disabled]="readOnly"
        (onClick)="openSmsTrackingForm('add')"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#btn-drop-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }}
        </div>
      </dx-tooltip>
      <!-- <ng-container *ngTemplateOutlet="topButtons"></ng-container> -->
    </div>
  </div>
</dx-drop-down-box>

<dx-context-menu
  [dataSource]="contextMenu"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
>
</dx-context-menu>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<app-add-sms-tracking-url
  *ngIf="addRow"
  [isVisible]="true"
  (onClosing)="onClosing()"
  (onInserted)="onInserted($event)"
  [mode]="mode"
  [selectedId]="focusedSelected[0]"
></app-add-sms-tracking-url>
