import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxTooltipModule,
} from 'devextreme-angular';
import {EventService} from '../../../event.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import {NgShortcutsComponent} from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {environment} from '../../../../environments/environment';
import {LoadOptions} from 'devextreme/data';
import {UsersModule} from '../../../users/users.module';
import {AppServices} from '../../../app-services.service';
import {ConfirmDialogComponent} from '../../../core/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-route-users',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        NgShortcutsComponent,
        TranslateModule,
        DxDataGridModule,
        UsersModule,
        ConfirmDialogComponent,
    ],
    templateUrl: './route-users.component.html',
    styleUrl: './route-users.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteUsersComponent {
  @ViewChild('gridBank') gridBank;

  @Input() isVisible: boolean;
  @Input() readOnly: boolean;
  @Input() focusedSelected = [];

  @Output() onClosing = new EventEmitter();

  title: string;
  form: FormGroup;
  submitted: boolean = false;
  minWidth: any = null;
  maxHeight: any = null;
  unicalGuid: number;
  loadChildComponent: boolean = false;
  shortcuts: ShortcutInput[] = [];
  dataSource: DataSource;
  firstLoad = true;
  focusedRowIndex = -1;
  isDetailOnFocus = false;
  indexToFocus: number;
  setFocusOnLoad = false;
  heightGrid: number = innerHeight * 0.7 - 105;
  focusedSelectedUser: any = [];
  addUserVisible: boolean = false;

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private appService: AppServices
  ) {
    this.minWidth = this.event.setMinWidthPopUp();
    this.maxHeight = this.event.setMaxHeight;
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      this.getRouteUsers();
      this.title = this.translate.instant('routes.routeUsers');
      this.title += ` '${this.focusedSelected[0].Name}'`;
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.addUserVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0) {
            this.confirmDeleteRoutesUserVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancelRouteUsers();
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  getRouteUsers() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RouteId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}customers/GetRoutesUsers?routeId=${this.focusedSelected[0].RouteId}`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedSelectedUser = [];
            return;
          }

          if (this.firstLoad) {
            this.firstLoad = false;

            setTimeout(() => {
              this.focusedRowIndex = 0;
              this.onFocusedRowChanged({
                row: {data: data[this.focusedRowIndex]},
              });
              this.cd.detectChanges();
            });
          } else if (this.setFocusOnLoad) {
            this.setFocusAfterLoad(data);
          }
        },
      }),
    });
  }

  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({row: {data: data[this.focusedRowIndex]}});
    this.cd.detectChanges();
  }

  onFocusedRowChanged = (e) => {
    try {
      this.focusedSelectedUser = [e.row.data];
      this.isDetailOnFocus = false;
    } catch {
    }
  };
  confirmDeleteRoutesUserVisible: boolean = false;

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function() {
    });
  }

  visibleChange() {
    this.submitted = false;

    if (!this.isVisible) {
      this.onClosing.emit(true);
      this.focusedSelected = [];
    }
    this.cd.detectChanges();
  }

  onCancelRouteUsers() {
    this.isVisible = false;
  }

  onChoosedUser(e: any) {
    if (e) {
      const userId = e.AppUserId;
      const routeId = this.focusedSelected[0].RouteId;
      const param = {
        userId: userId,
        routeId: routeId,
      };

      if (userId && routeId) {
        this.appService
          .postAuth(`customers/CustomerRouteUser`, null, param)
          .subscribe({
            next: () => {
              this.dataSource.reload();
            },
            error: (error) => {
              this.event.httpErrorNotification(error);
            },
          });
      }
    }
  }

  addUserRoute() {
    this.addUserVisible = true;
  }

  onDeleteUserRoutes() {
    if (this.focusedSelectedUser.length === 0) {
      return;
    }

    const userId = this.focusedSelectedUser[0].UserId;
    const routeId = this.focusedSelectedUser[0].RouteId;

    this.appService
      .deleteAuth(`customers/RouteUser/${userId}/${routeId}`)
      .subscribe({
        next: () => {
          this.dataSource.reload();
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }
}
