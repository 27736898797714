<dx-popup
  [width]="event.deviceType != 'mobile' ? '70%' : '100%'"
  [height]="event.deviceType != 'mobile' ? '70%' : 'calc(100% - 76px)'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="275"
  [maxHeight]="maxHeight"
  (onShown)="event.onShownPopUp(); loadChildComponent = true; cd.detectChanges()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <div style="display: inline-block">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
    </div>
    <div>
      <dx-button
        class="btn-dx-top-menu-folder"
        stylingMode="text"
        type="default"
        icon="icon absui-icon--add-circle"
        [id]="'btn-add' + unicalGuid"
        (onClick)="addUserRoute()"
        [disabled]="readOnly"
      >
      </dx-button>
      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        stylingMode="text"
        type="default"
        class="btn-dx-top-menu-folder"
        [disabled]="
          focusedSelectedUser.length == 0 || readOnly
        "
        (onClick)="confirmDeleteRoutesUserVisible = true"
      >
      </dx-button>
      <dx-tooltip
        [target]="'#btn-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Del)
        </div>
      </dx-tooltip>
    </div>
    <dx-data-grid
      [dataSource]="dataSource"
      [remoteOperations]="true"
      [wordWrapEnabled]="false"
      [showBorders]="true"
      [autoNavigateToFocusedRow]="true"
      [columnResizingMode]="'widget'"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      [id]="'grid' + unicalGuid"
      [(focusedRowIndex)]="focusedRowIndex"
      (onFocusedRowChanged)="onFocusedRowChanged($event)"
      [height]="heightGrid"
      [focusedRowEnabled]="true"
      [hoverStateEnabled]="true"
      [allowColumnReordering]="true"
      #gridBank
      data-cy="routers-users-grid"
    >
      <dxo-load-panel [enabled]="false"></dxo-load-panel>
      <dxo-scrolling
        mode="virtual"
        rowRenderingMode="virtual"
      ></dxo-scrolling>
      <dxi-column
        caption="{{ 'users.identyficator' | translate }}"
        [allowSorting]="false"
        dataField="Ident"
        width="300"
      >
      </dxi-column>

      <dxi-column
        caption="{{ 'users.userName' | translate }}"
        [allowSorting]="false"
        [cssClass]="'sorting-unavailable'"
        dataField="UserName"
      >
      </dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>

<ng-template #topButtons>
  <div class="d-inline-block">
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelRouteUsers()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<app-users
  *ngIf="addUserVisible"
  (onClosed)="addUserVisible = false; dataSource.reload(); gridBank.instance.focus()"
  [popUpMode]="true"
  [isVisible]="addUserVisible"
  [title]="'routes.routeUsersTitle'"
  (onChoosed)="onChoosedUser($event)"
></app-users>

<app-confirm-dialog
  [confirmText]="'routes.deleteConfirmRouteUser'"
  [isVisible]="confirmDeleteRoutesUserVisible"
  [kindDialog]="'question'"
  btnConfig="yesno"
  (onClosing)="confirmDeleteRoutesUserVisible = false"
  (onRemoving)="onDeleteUserRoutes(); this.confirmDeleteRoutesUserVisible = false"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible || addUserVisible"
>
</wapro-keyboard-shortcuts>
