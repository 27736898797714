<ng-container *ngIf="isVisible">
  <ng-container *ngIf="isPopupMode">
    <dx-popup
      [width]="800"
      [height]="500"
      position="center"
      [showCloseButton]="false"
      [animation]="{
        show: { type: 'slideIn', direction: 'bottom' },
        hide: { type: 'slideOut', direction: 'bottom' }
      }"
      [showTitle]="true"
      [title]="title"
      [(visible)]="isVisible"
      (visibleChange)="visibleChange()"
      (onShown)="event.onShownPopUp()"
      (onHidden)="event.onHiddenPopUp()"
      [wrapperAttr]="{ id: unicalGuid }"
    >
      <div *dxTemplate="let content of 'content'">
        <div class="text-right title-pop-up">
          <dx-button
            text="{{ 'buttons.close' | translate }}"
            type="danger"
            (onClick)="onClose()"
            [id]="'cancel' + unicalGuid"
          ></dx-button>
          <dx-tooltip
            [target]="'#cancel' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.close" | translate }} (Esc)
            </div>
          </dx-tooltip>
        </div>
        <div class="top-menu-popup">
          <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>

        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </dx-popup>
  </ng-container>
  <ng-container *ngIf="!isPopupMode">
    <dx-text-area
      [value]="textAreaAddress"
      [readOnly]="true"
      [elementAttr]="{ class: 'min-h-120' }"
      style="width: 100%"
      [disabled]="readOnly"
    ></dx-text-area>
    <div class="d-flex justify-content-between" style="width: 100%; gap: 6px">
      <dx-button
        type="normal"
        stylingMode="text"
        icon="icon absui-icon--clear-filter"
        (click)="clearReceiveAddress()"
        [disabled]="readOnly"
      ></dx-button>
      <dx-button
        class="btn-extra"
        type="danger"
        (onClick)="isPopupMode = true"
        [disabled]="readOnly"
        text="{{ 'crmDeliveries.chooseAddress' | translate }}"
      ></dx-button>
    </div>
  </ng-container>
</ng-container>
<ng-template #topButtons>
  <dx-scroll-view
    width="95%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
  >
    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      [id]="'add' + unicalGuid"
      (onClick)="showAdd()"
      [disabled]="readOnly"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--mode-edit"
      [text]="'buttons.edit' | translate"
      [id]="'edit' + unicalGuid"
      (onClick)="showEdit()"
      [disabled]="readOnly || focusedRowIndex < 0"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--remove-circle-outline"
      [text]="'buttons.delete' | translate"
      [id]="'delete' + unicalGuid"
      (onClick)="isDelete = true"
      [disabled]="readOnly || focusedRowIndex < 0"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--tool-select-rectangle"
      (onClick)="onChoosingFormPayment()"
      [text]="'buttons.choose' | translate"
      [disabled]="readOnly || focusedRowIndex < 0"
      [id]="'choose' + unicalGuid"
    >
    </dx-button>

    <dx-tooltip
      [target]="'#add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#choose' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.choose" | translate }} ( Enter )
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="info-txt">
        Operator <b>{{ operatorName }}</b>
      </div>
    </div>
  </div>

  <br />
  <div class="row">
    <div class="col-md-12">
      <dx-data-grid
        [id]="'grid' + unicalGuid"
        #gridClass
        [dataSource]="dataSource"
        [remoteOperations]="true"
        [wordWrapEnabled]="false"
        [showBorders]="true"
        shortcut
        [height]="heightGrid"
        [(selectedRowKeys)]="selectedRows"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onInitialized)="onInitialized($event)"
        [focusedRowEnabled]="true"
        (onRowDblClick)="onRowDblClick()"
        [(focusedRowIndex)]="focusedRowIndex"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-selection mode="single" showCheckBoxesMode="none"></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

        <dxi-column
          caption="D"
          width="40"
          dataField="IsDefault"
          [allowSorting]="false"
          cellTemplate="defaultTemplate"
        ></dxi-column>
        <dxi-column
          caption="{{ 'delivery.addressType' | translate }}"
          dataField="IsReturnType"
          cellTemplate="returnTypeTemplate"
          width="80"
        ></dxi-column>
        <dxi-column
          caption="{{ 'constractors.company' | translate }}"
          dataField="CompanyName"
          width="60"
        ></dxi-column>
        <dxi-column
          caption="{{ 'menu.warehouse' | translate }}"
          dataField="WarehouseName"
          width="100"
        ></dxi-column>
        <dxi-column
          caption="{{
            ('users.grid.columns.firstName' | translate) +
              ' ' +
              ('users.grid.columns.lastName' | translate)
          }}"
          dataField="Name"
          width="100"
        ></dxi-column>
        <dxi-column
          caption="{{ 'companies.form.postalCode' | translate }}"
          dataField="PostalCode"
          width="80"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'companies.grid.columns.city' | translate }}"
          dataField="City"
          width="80"
        ></dxi-column>
        <dxi-column
          caption="{{ 'companies.grid.columns.street' | translate }}"
          dataField="Street"
          width="80"
        >
        </dxi-column>
        <dxi-column
          caption="Nr"
          dataField="BuildingNumer"
          width="50"
        ></dxi-column>
        <dxi-column
          caption="{{ 'companies.grid.columns.local' | translate }}"
          dataField="LocalNumber"
          width="50"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'singleBank.form.email' | translate }}"
          dataField="Email"
          width="80"
        ></dxi-column>
        <dxi-column
          caption="{{ 'singleBank.form.phone' | translate }}"
          dataField="Phone"
          width="80"
        ></dxi-column>
        <dxi-column
          caption="{{ 'bankAccounts.grid.columns.country' | translate }}"
          width="60"
          dataField="CountryCode"
        >
        </dxi-column>

        <div *dxTemplate="let data of 'returnTypeTemplate'">
          {{ data.value ? "zwrot" : "nadanie" }}
        </div>

        <div *dxTemplate="let cell of 'defaultTemplate'">
          <i
            class="icon absui-icon--check check-grid"
            *ngIf="cell.data.IsDefault"
          ></i>
        </div>
      </dx-data-grid>

      <dx-context-menu
        [dataSource]="contextMenu"
        [width]="200"
        [target]="'#grid' + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
      </dx-context-menu>
    </div>

    <app-add-delivery-address
      [isVisible]="addRow"
      [type]="type"
      [operatorCode]="operatorCode"
      (onClosing)="
        addRow = false; gridClass.instance.focus(); cd.detectChanges()
      "
      (onInserted)="onInserted($event)"
    >
    </app-add-delivery-address>

    <app-add-delivery-address
      [isVisible]="editRow"
      [selectedId]="selectedRows[0]?.DeliveryAddressId"
      [type]="type"
      [operatorCode]="operatorCode"
      (onClosing)="
        editRow = false; gridClass.instance.focus(); cd.detectChanges()
      "
      (onInserted)="onInserted($event)"
    >
    </app-add-delivery-address>

    <app-confirm-dialog
      *ngIf="isDelete"
      [isVisible]="true"
      (onRemoving)="delete(); gridClass.instance.focus()"
      (onClosing)="closeConfirm()"
    ></app-confirm-dialog>

    <wapro-keyboard-shortcuts
      [shortcuts]="shortcuts"
      [unicalGuid]="unicalGuid"
      [disabled]="!isVisible || editRow || addRow || isDelete"
    >
    </wapro-keyboard-shortcuts>
  </div>
</ng-template>
