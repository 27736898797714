import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

import { SingleRecordMode } from 'src/app/event.model';
import { HelpService } from '../../../../help-service.service';

@Component({
    selector: 'app-add-devices',
    templateUrl: './add-devices.component.html',
    styleUrls: ['./add-devices.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'visibleShortcut',
        'mode',
    ],
    standalone: false
})
export class AddDevicesComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('focusFirst') focusFirst;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  @ViewChild('gridRole') gridRole;
  @ViewChild('shortcutsS') shortcutsS;

  mode: SingleRecordMode = null;
  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;

  widthWindow: any = '75%';
  heightWindow: any = '90%';

  shortcuts = [];
  isMobileSettings: boolean = false;
  form;
  submitted: boolean = false;

  unicalGuid;

  selectedtab = 0;
  visibleUserExtensions: boolean = false;

  mobileMenuFooter = [{ id: 0, name: 'Pola dodatkowe' }];

  addRow: boolean = false;
  deleteRow: boolean = false;
  scrollPosition = 0;
  fullScreen: boolean = false;
  customerRole = [];
  selectedRowCustomerRole = [];
  loadingVisible = false;

  visibleComponent: boolean = false;
  warehouseId;
  isDisabledShortcut: boolean = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private helpService: HelpService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('menu.user-extensions')
      .subscribe((text) => (this.mobileMenuFooter[0].name = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    let warehouseInfo = this.event.decryptString(
      localStorage.getItem('sessionInfoWaproHtml')
    );
    this.warehouseId = warehouseInfo.AppWarehouseId;
    this.createForm();
  }

  clickOptions = (e) => {
    if (e.itemData.id == 0 && !this.readOnly) {
      this.visibleUserExtensions = true;
    }
  };

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  setFocus = () => {
    this.focusFirst.focusFirst.instance.focus();
  };

  getGroup = () => {
    this.appService.getAuth(`services/groups`).subscribe((res) => {
      res.data.forEach((field) => {
        if (field.IsDefault) {
          this.form.controls.ServiceGroupId.setValue(field.ServiceGroupId);
        }
      });
    });
  };

  ngOnChanges() {
    if ((this.mode === 'edit' || this.mode == 'show') && this.isVisible) {
      this.getDateSelectedId(this.selectedId[0]);
      this.getCustomerRole();
    }

    if (this.mode == 'add' && this.isVisible) {
      this.getGroup();
    }
  }

  @ViewChild('popup') popup;

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.selectedtab == 2) {
            this.addRow = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        priority: 1,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 1;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + p',
        preventDefault: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.visibleUserExtensions = true;
          this.cd.detectChanges();
        },
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 2;
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );

    if (localStorage.getItem('sizePopUpDeviceService')) {
      this.widthWindow = JSON.parse(
        localStorage.getItem('sizePopUpDeviceService')
      ).widthWindow;
      this.heightWindow = JSON.parse(
        localStorage.getItem('sizePopUpDeviceService')
      ).heightWindow;
      this.fullScreen = JSON.parse(
        localStorage.getItem('sizePopUpDeviceService')
      ).fullScreen;
    }
  }

  fullScreenChange = (e) => {
    let size = {
      widthWindow: this.widthWindow,
      heightWindow: this.heightWindow,
      fullScreen: e,
    };
    localStorage.setItem('sizePopUpDeviceService', JSON.stringify(size));
  };

  onResizeEnd = () => {
    let size = {
      widthWindow: this.widthWindow,
      heightWindow: this.heightWindow,
    };
    localStorage.setItem('sizePopUpDeviceService', JSON.stringify(size));
  };

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(`services/devices?ObjectId=${selectedId}`)
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
      });
    this.cd.detectChanges();
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      ServiceDeviceId: [0],
      WarehouseId: [this.warehouseId, Validators.required],
      ServiceGroupId: [null, Validators.required],
      ProductId: [0],
      ProductName: ['', Validators.required],
      ProductionNumber: [''],
      SerialNumber: [''],
      ProductionDate: [new Date()],
      SaleDate: [new Date()],
      GuaranteeMonths: [0],
      LastServiceDate: [new Date()],
      NextServiceDate: [new Date()],
      Producer: [''],
      Remarks: [''],
      Description: [''],
      Status: [''],
      Field1: [''],
      Field2: [''],
      Field3: [''],
      Field4: [''],
      Field5: [''],
      Field6: [''],
      Field7: [''],
      Field8: [''],
      Field9: [''],
      Field10: [''],
      Barcode: [''],
      ServiceTechnicianId: [0],
      BrandId: [0],
      ModelId: [0],
      BrandName: [''],
      ModelName: [''],
      ServiceTechnician: [''],
      AttachmentsAmount: [0],
    });
  };

  onChoosedGroup = (e) => {
    if (e) {
      this.form.controls.ServiceGroupId.setValue(e.ServiceGroupId);
    } else {
      this.form.controls.ServiceGroupId.setValue(null);
    }
  };

  onChoosedWaregouse = (e) => {
    if (e) {
      this.form.controls.WarehouseId.setValue(e.WarehouseId);
    } else {
      this.form.controls.WarehouseId.setValue(null);
    }
  };

  onChoosedProducer = (e) => {
    if (e) {
      this.form.controls.Producer.setValue(e.Name);
    } else {
      this.form.controls.Producer.setValue('');
    }
  };

  isChangeName: boolean = false;
  nameDevice: any = null;
  onChoosedArticle = (e) => {
    if (e) {
      this.form.controls.ProductId.setValue(e[0].ProductId);
      if (this.form.value.ProductName != e[0].ProductFullName) {
        this.nameDevice = {
          name: e[0].ProductFullName,
        };
        this.isChangeName = true;
        this.cd.detectChanges();
      } else {
        this.form.controls.ProductName.setValue(e[0].ProductFullName);
      }
    } else {
      this.form.controls.ProductId.setValue(null);
      this.form.controls.ProductName.setValue('');
    }
  };

  yesChangeName() {
    this.form.controls.ProductName.setValue(this.nameDevice.name);
    this.noChangeName();
  }

  noChangeName() {
    this.nameDevice = 'null';
    this.isChangeName = false;
    this.cd.detectChanges();
  }

  onChoosedServiceTechnician = (e) => {
    if (e) {
      this.form.controls.ServiceTechnicianId.setValue(e.EmployeeId);
      this.form.controls.ServiceTechnician.setValue(e.LastName);
    } else {
      this.form.controls.ServiceTechnicianId.setValue(null);
      this.form.controls.ServiceTechnician.setValue('');
    }
  };

  @ViewChild('brand') brand;
  onChoosedBrand = (e) => {
    if (e) {
      if (e.ServiceBrandId !== this.form.get('BrandId').value) {
        this.form.controls.ModelId.setValue(0);
        this.form.controls.ModelName.setValue('');
      }
      this.form.controls.BrandId.setValue(e.ServiceBrandId);
      this.form.controls.BrandName.setValue(e.Name);
    } else {
      this.form.controls.BrandId.setValue(0);
      this.form.controls.BrandName.setValue('');
      this.form.controls.ModelId.setValue(0);
      this.form.controls.ModelName.setValue('');
    }
  };

  onChoosedModel = (e) => {
    if (e) {
      this.form.controls.ModelId.setValue(e.ServiceModelId);
      this.form.controls.ModelName.setValue(e.Name);
    } else {
      this.form.controls.ModelId.setValue(0);
      this.form.controls.ModelName.setValue('');
    }
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;
    this.cd.detectChanges();

    if (!e) {
      this.onClosing.emit(false);
      if (this.mode === 'edit') {
        let data = {
          RecordId: this.selectedId[0],
          StateFlag: 0,
          DictionaryCode: 'SERVICE_DEVICE',
        };
        this.appService
          .postAuth(`services/devices/releaseDeviceLock`, data)
          .subscribe(
            () => {},
            (error) => {
              this.event.showNotification('error', JSON.parse(error).detail);
            }
          );
      }
      this.selectedtab = 0;
    }
    this.cd.detectChanges();
  };

  onInsertedUserExtensions = (e) => {
    this.form.patchValue(e);
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.focusFirst.focusFirst.instance.focus();
    this.cd.detectChanges();
  };

  onClosingForm = () => {
    this.addRow = false;
    this.deleteRow = false;
    this.cd.detectChanges();
  };

  onInsertedRole = (e) => {
    this.customerRole.push(e);
  };

  onFocusedRowChanged = (e) => {
    this.selectedRowCustomerRole = [e.row.data];
  };

  getCustomerRole = () => {
    if (this.selectedId.length > 0) {
      this.appService
        .getAuth(`services/devices/${this.selectedId[0]}/customerRoles`)
        .subscribe((res) => {
          this.customerRole = res.data;
        });
    }
  };

  delete = () => {
    if (this.selectedRowCustomerRole[0].ServiceCustomerRoleId != 0) {
      this.appService
        .deleteAuth(
          `services/devices/${this.selectedId[0]}/customerRoles/${this.selectedRowCustomerRole[0].ServiceCustomerRoleId}`
        )
        .subscribe(() => {
          this.event.showNotification(
            'info',
            this.translate.instant('deleted-record')
          );
          for (let i = 0; i < this.customerRole.length; i++) {
            if (this.customerRole[i] == this.selectedRowCustomerRole[0]) {
              this.customerRole.splice(i, 1);
            }
          }
          this.cd.detectChanges();
        });
    } else {
      for (let i = 0; i < this.customerRole.length; i++) {
        if (this.customerRole[i] == this.selectedRowCustomerRole[0]) {
          this.customerRole.splice(i, 1);
        }
      }
      this.cd.detectChanges();
    }
  };

  closeConfirm = () => {
    this.deleteRow = false;
    setTimeout(() => {
      if (this.gridRole) {
        this.gridRole.instance.focus();
      }
    }, 500);
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.invalid) {
      this.selectedtab = 0;
    }

    if (this.form.valid && !this.readOnly) {
      if (this.isValidUserExtensions.checkIsValid()) {
        if (this.form.value.ServiceDeviceId == 0) {
          this.appService
            .postAuth('services/devices', this.form.value)
            .subscribe(
              (res) => {
                this.customerRole.forEach((field) => {
                  field.DeviceId = res.ServiceDeviceId;
                  this.appService
                    .postAuth(
                      `services/devices/${field.DeviceId}/customerRoles`,
                      field
                    )
                    .subscribe(() => {});
                });
                this.onInserted.emit(res);
                this.isVisible = false;
              },
              (error) => {
                this.event.httpErrorNotification(error);
              }
            );
        } else {
          this.appService
            .putAuth(
              `services/devices/${this.form.value.ServiceDeviceId}`,
              this.form.value
            )
            .subscribe(
              (res) => {
                this.onInserted.emit(res);
                this.isVisible = false;
              },
              (error) => {
                this.event.httpErrorNotification(error);
              }
            );
        }
      } else {
        this.visibleUserExtensions = true;
      }
    }
  };
}
