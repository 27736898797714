<dx-popup
  [width]="400"
  [height]="500"
  [showTitle]="true"
  [title]="'geniusTaskSubject' | translate"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [(visible)]="visible"
  (visibleChange)="visibleChanged()"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px"
        [disabled]="!focusedRow"
        (onClick)="onSave()"
        [id]="'save' + unicalGuid"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#save' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 64px)'"
    >
      <app-custom-dropdown-box
        [hideFilter]="true"
        [(filterValue)]="subject"
        (onValueChanged)="onSearchChanged($event)"
      >
      </app-custom-dropdown-box>
      <dx-data-grid
        [dataSource]="geniusTasks$ | async"
        [wordWrapEnabled]="false"
        [showBorders]="true"
        [id]="'grid' + unicalGuid"
        [columnAutoWidth]="true"
        (onRowDblClick)="onRowDblClick($event)"
        [height]="350"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        [(focusedRowIndex)]="focusedRowIndex"
        [focusedRowEnabled]="true"
        [renderAsync]="true"
        #gridBank
      >
        <dxi-column
          caption="{{ 'tasks.subject' | translate }}"
          [allowSorting]="false"
          dataField="Subject"
        >
        </dxi-column>
      </dx-data-grid>
    </dx-scroll-view>
  </div>

  <wapro-keyboard-shortcuts
    [unicalGuid]="unicalGuid"
    [shortcuts]="shortcuts"
    [disabled]="!visible"
  ></wapro-keyboard-shortcuts
></dx-popup>
