import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { DynamicButton } from 'src/app/core/confirm-dialog/confirm-dialog.model';
import { PrintConfigStorageData } from 'src/app/core/print-settings/print-config-storage-data.model';
import { SetupWizardConfig } from 'src/app/core/setup-wizard/ISetupWizardConfig';
import { SingleRecordMode } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { DeviceType, InterconnectorDevice } from 'src/app/interconnector.model';
import { InterconnectorService } from 'src/app/interconnector.service';
import { LabelPrinter } from './external-devices.interface';
import { AppServices } from 'src/app/app-services.service';

@Component({
    selector: 'app-external-devices',
    templateUrl: './external-devices.component.html',
    styleUrls: ['./external-devices.component.scss'],
    standalone: false
})
export class ExternalDevicesComponent {
  @Input() deviceType: DeviceType = DeviceType['Drukarka etykiet'];
  @Input() isVisible: boolean = false;
  @Input() isPopupMode: boolean = false;

  componentVisible: boolean = false;
  computerName: string = null;
  connectionErrorNotification: string;
  connectionErrorNotificationHeader: string;
  contextMenu: any[];
  dataSource: InterconnectorDevice[];
  labelPrinters: LabelPrinter[];
  deleteConfirmText: string;
  firstLoad: boolean = true;
  focusedGuid: string = null;
  focusedIndex: number = -1;
  isDeleteVisible: boolean = false;
  isSetupWizardVisible: boolean;
  isSingleVisible: boolean = false;
  loadChildComponent: boolean = false;
  mode: SingleRecordMode = 'add';
  noInterconnectorResponse: boolean = false;
  permission: any;
  printConfig: PrintConfigStorageData;
  selected: any = null;
  setupWizardConfig: SetupWizardConfig = {
    headerText: 'externalDevices.setupWizard.headerText.default',
    bodyText: 'externalDevices.setupWizard.bodyText',
    buttons: [
      { type: 'success', text: 'externalDevices.setupWizard.buttons.primary' },
      { type: 'danger', text: 'externalDevices.setupWizard.buttons.secondary' },
    ],
    footerItems: [
      {
        icon: 'icon absui-icon--help',
        text: 'externalDevices.setupWizard.footerText',
        type: 'link',
      },
    ],
  };
  setupWizardShown: boolean = false;
  shortcuts: ShortcutInput[] = [];
  title: string = '';
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  heightGrid: number;
  isInterconnectorClient: boolean;
  interconnectorClientButtons: DynamicButton[] = [
    {
      type: 'success',
      text: '',
      action: 'showMore',
    },
    {
      type: 'danger',
      text: '',
      action: 'close',
    },
  ];
  interconnectorClientHeader: string;
  interconnectorClientText: string;
  focusedRowData: InterconnectorDevice;

  constructor(
    private app: AppServices,
    private interconnector: InterconnectorService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.permission = this.event.decryptString(
      localStorage.getItem('appPermissions')
    );
    this.heightGrid = window.innerHeight - 210;
    this.translate.get('constractors.learnMore').subscribe((text) => {
      this.interconnectorClientButtons.find(
        (x) => x.action == 'showMore'
      ).text = text;
    });
    this.translate.get('buttons.close').subscribe((text) => {
      this.interconnectorClientButtons.find((x) => x.action == 'close').text =
        text;
    });
  }

  async ngOnInit() {
    this.componentVisible = true;
    this.event.onShown();
    this.computerName = await this.getComputerName();
    if (this.computerName) {
      this.dataSource = await this.getDevices();
    }
    this.event.onHidden();
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.deviceType?.currentValue) {
      switch (changes?.deviceType?.currentValue) {
        case DeviceType['Drukarka fiskalna']:
          this.setupWizardConfig.headerText =
            'externalDevices.setupWizard.headerText.fiscalPrinter';
          break;
        case DeviceType['Epodpis']:
          this.setupWizardConfig.headerText =
            'externalDevices.setupWizard.headerText.eSignature';
          break;
        case DeviceType['Drukarka dokumentów']:
          this.setupWizardConfig.headerText =
            'externalDevices.setupWizard.headerText.documentPrinter';
          break;
        case DeviceType['Drukarka etykiet']:
          this.setupWizardConfig.headerText =
            'externalDevices.setupWizard.headerText.labelPrinter';
          this.getLabelPrinters();
          break;
        case DeviceType['Kolektor']:
          this.setupWizardConfig.headerText =
            'externalDevices.setupWizard.headerText.dataCollector';
          break;
        case DeviceType['Moduł wykonywalny']:
          this.setupWizardConfig.headerText =
            'externalDevices.setupWizard.headerText.exeModule';
          break;
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadChildComponent = true;
    }, 1000);
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.showSingleDevice('add');
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.focusedGuid) {
            this.showSingleDevice('edit');
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.focusedGuid) {
            this.deleteSingleDevice();
          }
        },
        preventDefault: true,
      }
    );
  }

  async getComputerName() {
    return new Promise<string>((resolve) => {
      if (this.event.deviceType === 'mobile') {
        this.showMobileInterconnectorErrorNotification();
        resolve(null);
        return;
      }
      if (!this.computerName) {
        this.printConfig = this.event.getPrintConfig();
        this.interconnector.getComputerName().subscribe({
          next: (res) => {
            this.printConfig.computerName = res;
            this.event.savePrintConfig(this.printConfig);
            this.setupWizardShown = false;
            sessionStorage.setItem('setupWizardShown', 'false');
            resolve(res);
          },
          error: (err) => {
            let error = JSON.parse(err);
            if (error.error == 0 && this.computerName) {
              resolve(this.computerName);
              return;
            }
            if (error.error == 0) {
              this.noInterconnectorResponse = true;

              this.showInterconnectorErrorNotification();
            } else {
              // this.event.httpErrorNotification(err);
            }
            resolve(null);
          },
        });
      } else {
        resolve(this.computerName);
      }
    });
  }

  getDevices = (_mode?) => {
    return new Promise<InterconnectorDevice[]>((resolve) => {
      this.interconnector.getDevices().then((res) => {
        if (res) {
          const devices = res.filter(
            (x) => x.Configuration.DeviceType == this.deviceType
          );
          resolve(devices);
        } else {
          this.showInterconnectorErrorNotification();
          resolve([]);
        }
      });
    });
  };

  getLabelPrinters() {
    this.app.getAuth(`labels/barcodeprinters`).subscribe({
      next: (res) => {
        this.labelPrinters = res.data;
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  onConfirm() {}
  onClose() {}

  onClosingInterconnectorClient = (e) => {
    if (e == 'showMore') {
      const url =
        'https://wapro.pl/dokumentacja-erp/anywhere/docs/sprzedaz-i-magazyn/urzadzenia-fiskalne/interkonektor/';
      window.open(url, '_blank');
    }
    this.isInterconnectorClient = false;
    this.cd.detectChanges();
  };

  showInterconnectorClientDialog() {
    this.isInterconnectorClient = true;
    this.cd.detectChanges();
  }

  showSingleDevice(mode: SingleRecordMode) {
    // if (
    //   DeviceType[this.deviceType] !== 'Drukarka dokumentów' &&
    //   mode === 'edit'
    // ) {
    //   /* tymczasowo pokazuje komunikat z przekierowaniem do klienta interkonektora */
    //   this.showInterconnectorClientDialog();
    //   return;
    //   /* --- */
    // }

    this.mode = mode;
    this.isSingleVisible = true;
    this.cd.detectChanges();
  }

  deleteSingleDevice() {
    /* tymczasowo pokazuje komunikat z przekierowaniem do klienta interkonektora */
    // this.showInterconnectorClientDialog();
    // return;
    /* --- */
    this.deleteConfirmText = 'dataCollectors.deleteConfirmText.singleType';
    this.isDeleteVisible = true;
  }

  onClosingForm = () => {
    this.isSingleVisible = false;
  };

  onDeviceSave(_e) {
    this.isSingleVisible = false;
    this.refresh();
  }

  onClosingDelete = () => {
    this.isDeleteVisible = false;
  };

  onCancelingDelete = () => {
    this.isDeleteVisible = false;
  };

  onRemoving() {
    const deviceToDelete = this.dataSource.find(
      (x) => x.Configuration.Guid == this.focusedGuid
    );
    this.interconnector.deleteDevice(deviceToDelete).subscribe({
      next: () => {
        this.refresh();
      },
      error: () => {},
    });
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (this.permission) this.showSingleDevice('add');
        break;
      case 1:
        if (this.permission) this.showSingleDevice('edit');
        break;
      case 2:
        if (this.permission) this.deleteSingleDevice();
        break;
    }
  };

  changeComputerName(_e) {}

  async refresh() {
    this.event.onShown();
    this.computerName = await this.getComputerName();
    if (this.computerName) {
      this.dataSource = await this.getDevices();
    }
    this.event.onHidden();
    this.cd.detectChanges();
  }

  onRowDblClick() {
    this.showSingleDevice('show');
  }

  async setActiveDevice(_guid?: string) {
    // if (DeviceType[this.deviceType] !== 'Drukarka dokumentów') {
    //   /* tymczasowo pokazuje komunikat z przekierowaniem do klienta interkonektora */
    //   this.showInterconnectorClientDialog();
    //   return;
    //   /* --- */
    // }

    const devices: InterconnectorDevice[] =
      await this.interconnector.getDevices();
    const device = devices
      .filter((x) => x.Configuration.DeviceType == this.deviceType)
      .find((x) => x.Configuration.Guid == this.focusedGuid);

    this.event.onShown();
    this.interconnector.setActiveDevice(device, !device.IsDefault).subscribe({
      next: () => {
        this.refresh();
        this.event.onHidden();
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
        this.event.onHidden();
      },
    });
  }

  onSetupWizardClosing() {
    this.interconnector.onSetupWizardShown(DeviceType[this.deviceType]);
    this.setupWizardShown = true;
    this.isSetupWizardVisible = false;
    this.cd.detectChanges();
  }

  onSetupWizardContinue() {
    // this.isSetupWizardVisible = false;
    // this.cd.detectChanges();

    const url =
      'https://storage.wapro.pl/storage/InstalatorWAPRO/InstalatorWAPRO.exe';
    window.open(url, '_blank');
  }

  onSetupWizardLinkClick() {
    const url =
      'https://wapro.pl/dokumentacja-erp/anywhere/docs/sprzedaz-i-magazyn/urzadzenia-fiskalne/interkonektor/';
    window.open(url, '_blank');
  }

  showInterconnectorErrorNotification() {
    if (this.event.deviceType !== 'desktop') {
      this.showMobileInterconnectorErrorNotification();
      return;
    }
    this.setupWizardShown = this.interconnector.isSetupWizardShown(
      DeviceType[this.deviceType]
    );
    if (this.setupWizardShown) return;
    this.isSetupWizardVisible = true;
    this.cd.detectChanges();
  }

  showMobileInterconnectorErrorNotification() {
    this.setupWizardConfig = {
      headerText: 'externalDevices.setupWizard.headerText.mobile',
      bodyText: 'externalDevices.setupWizard.mobileBodyText',
      buttons: [{ type: 'danger', text: 'buttons.close' }],
      footerItems: [
        {
          icon: 'icon absui-icon--help',
          text: 'externalDevices.setupWizard.footerText',
          type: 'link',
        },
      ],
    };
    this.isSetupWizardVisible = true;
    this.cd.detectChanges();
  }

  onFocusedRowChanged(e) {
    this.focusedRowData = e.row.data;
  }
}
