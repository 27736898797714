@use "../../../../assets/scss/colors" as *;


.border-bottom-none {
  border-bottom: none !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.box-dictionary {
  padding: 1px 1px;
}

.button-content {
  cursor: pointer;
  height: 22px;
  width: 22px;
  top: 3px;
  position: absolute;
  &:hover {
    background-color: var(--neutral-85, $neutral-85);
    border-radius: 6px;
  }
}

.check-circle:before {
  content: "\e86c";
  font-size: 16px;
}

:host ::ng-deep .dx-texteditor.dx-editor-outlined {
  max-width: none;
}

:host ::ng-deep .dx-icon-spinup::before {
  color: var(--icons-brand, $icons-brand);
}

@media (max-width: 844px) {
  .button-content {
    left: calc(100% - 30px) !important;
  }

  .box-dictionary {
    width: 100% !important;
  }
}
