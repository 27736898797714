import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxProgressBarModule,
  DxTemplateModule,
  DxTooltipModule
} from 'devextreme-angular';
import {DxiColumnModule, DxoLoadPanelModule, DxoScrollingModule} from 'devextreme-angular/ui/nested';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {EventService} from '../../../event.service';
import {fromLonLat} from 'ol/proj';
import View from 'ol/View';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {Zoom, Rotate, ScaleLine} from 'ol/control';
import {GeocodingService} from './geocoding.service';
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Extent} from 'ol/extent';
import * as polyline from '@mapbox/polyline';
import {LineString} from 'ol/geom';
import {Overlay} from 'ol';
import {NgShortcutsComponent} from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

interface GeoResponse {
  lat: string;
  lon: string;
}

interface Address {
  address: string;
  orders: number;
  registrationType: number;
}

@Component({
    selector: 'app-routes-map',
    imports: [CommonModule, DxButtonModule, DxDataGridModule, DxPopupModule, DxTemplateModule, DxTooltipModule, DxiColumnModule, DxoLoadPanelModule, DxoScrollingModule, TranslateModule, NgShortcutsComponent, DxProgressBarModule],
    templateUrl: './routes-map.component.html',
    styleUrl: './routes-map.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesMapComponent {

  @Output() onClosing = new EventEmitter();


  @Input() isVisible: boolean = false;
  @Input() normalMode: boolean = false;
  @Input() readOnly: boolean;
  @Input() addresses: Address[] = [];
  @Input() selectedRoute = [];

  shortcuts: ShortcutInput[] = [];
  unicalGuid: number;
  title: string;
  map!: Map;
  coordinatesList: number[][] = [];
  routeLayerAdded = false;
  addressPromises = [];

  constructor(
    public event: EventService,
    public translate: TranslateService,
    private geocodingService: GeocodingService,
    public cd: ChangeDetectorRef
  ) {
    this.title = this.translate.instant('routes.route');
    this.initializeMap();

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      setTimeout(() => {
        this.initializeMap();
      });
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.addresses.length >= 2) {
            this.calculateRoute();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.addresses.length > 0) {
            this.adjustMapView();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + g',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.addresses.length > 0) {
            this.openInGoogleMaps();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancelRoutesMap();
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  initializeMap() {
    this.event.loadingVisible = true;
    const view = new View({
      center: fromLonLat([19.1451, 51.9194]), // Center of Poland (Approximate coordinates)
      zoom: 6 // Adjust zoom level to fit entire country
    });

    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      view: view,
      controls: [
        new Zoom(),
        new Rotate(),
        new ScaleLine({
          units: 'metric',
          bar: true,
          steps: 2,
        })
      ]
    });

    // Przekształcenie adresów na współrzędne i dodanie markerów na mapie
    console.log('addresses:', this.addresses);
    this.addressPromises = this.addresses
      .map((addressObj, index) =>
        this.geocodeAddress(addressObj.address, true, index, addressObj.registrationType));
    const addressPromises = this.addressPromises;

    Promise.all(addressPromises).then(() => {
      this.adjustMapView();
      this.event.loadingVisible = false;
    }).catch(error => {
      this.event.loadingVisible = false;
      this.event.httpErrorNotification(error);
    });

    // Get the tooltip element from HTML
    const tooltipElement = document.getElementById('tooltip') as HTMLElement;

    const tooltipOverlay = new Overlay({
      element: tooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });
    this.map.addOverlay(tooltipOverlay);
  }

  private geocodeAddress(address: string, withAddMarker = true, index?: number, registrationType?: number): Promise<number[] | []> {
    console.log(registrationType);
    return new Promise((resolve) => {
      this.geocodingService.geocodeAddress(address)
        .subscribe(
          (response: GeoResponse[]) => {
            if (response && response.length > 0 && response[0].lon && response[0].lat) {
              const coordinates = [parseFloat(response[0].lon), parseFloat(response[0].lat)];
              if (withAddMarker) {
                this.addMarker(coordinates, index, registrationType);
              }
              this.coordinatesList[index] = coordinates; // Użycie indeksu do zachowania kolejności
              // this.coordinatesList.push(coordinates); // Collect coordinates
              resolve(coordinates);
            } else {
              // reject('Nieprawidłowa odpowiedź z usługi geokodowania');
              console.warn(`Nieprawidłowa odpowiedź z usługi geokodowania dla adresu: ${address}`);
              resolve([]); // Zwracamy pustą tablicę, aby kontynuować przetwarzanie innych adresów
            }
          },
          (error) => {
            this.event.httpErrorNotification(error);
            console.error(`Błąd geokodowania adresu: ${address}`, error);
            resolve([]); // Zwracamy pustą tablicę, aby kontynuować przetwarzanie innych adresów
          }
        );
    });
  }

  private addMarker(coordinates: number[], index: number, registrationType: number) {
    const markerColor = this.getMarkerColor(registrationType); // Get color based on registrationType

    const marker = new Feature({
      geometry: new Point(fromLonLat(coordinates))
    });

    // Create a style for the number label
    const numberStyle = new Style({
      // Ustawienia tła numeru
      text: new Text({
        text: (index + 1).toString(), // Etykieta z numerem
        offsetX: 0, // Ustawienie na środku
        offsetY: -21, // Ustawienie nad ikoną
        font: 'bold 16px Arial, sans-serif', // Czcionka
        fill: new Fill({
          color: '#000000' // Czarny tekst dla kontrastu
        }),
        textAlign: 'center', // Tekst wyrównany na środku
        textBaseline: 'middle' // Tekst w pionie wyśrodkowany
      })
    });

// Dodaj białe tło jako osobny styl
    const circleStyle = new Style({
      // image: new CircleStyle({
      //   radius: 10, // Promień okręgu
      //   fill: new Fill({
      //     color: '#ffffff' // Białe tło okręgu
      //   }),
      // }),
      text: new Text({
        text: '',
        offsetY: -15 // Przesunięcie okręgu do góry
      })
    });

// Ustaw ikonę jako osobny styl
    const iconStyle = new Style({
      image: new Icon({
        src: 'data:image/svg+xml;base64,' + btoa(`
<svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_13_8781)">
    <path d="M15.81 3.24C14.47 1.86 12.59 1 10.51 1H10.5C6.34999 1.04 2.98999 4.42 2.98999 8.57V8.63C2.98999 12.44 9.52999 20 10.5 20C10.98 20 12.86 18.11 14.61 15.74C16.36 13.38 18 10.54 18 8.63V8.6C18 6.51 17.17 4.62 15.81 3.24ZM10.5 11.98C8.56999 11.98 6.99999 10.42 6.99999 8.48C6.99999 6.54 8.56999 4.98 10.5 4.98C11.46 4.98 12.34 5.37 12.97 6.01C13.61 6.64 14 7.52 14 8.48C14 10.42 12.43 11.98 10.5 11.98Z" fill="${markerColor}"/>
  </g>
  <g id="circle_00000004518594092232402580000011228820210595459499_">
<circle id="circle" style="fill:#FFFFFF;" cx="10.4949951" cy="8.4799805" r="5.5"/>
</g>
</svg>
`),
        anchor: [0.5, 1], // Ustawienie punktu kotwiczenia
        scale: 1.8, // Zwiększenie skali dla lepszej widoczności
        opacity: 1 // Zachowanie pełnej widoczności ikony
      })
    });

    // Create a style for the address label
    const addressStyle = new Style({
      text: new Text({
        text: this.addresses[index].address, // Etykieta z adresem
        offsetX: 11,
        offsetY: 15, // Adjust this to position the text below the number
        font: '13px Arial, sans-serif', // Simplified font style for address
        fill: new Fill({
          color: '#000000' // Black text color for address
        }),
        stroke: new Stroke({
          color: '#ffffff', // White border around the text
          width: 3 // Thick border for contrast
        }),
        textAlign: 'center', // Center align the text
        textBaseline: 'middle', // Middle baseline for proper vertical alignment
      })
    });

    // Apply the styles to the marker feature
    marker.setStyle([iconStyle, circleStyle, numberStyle]);

    // Create another feature for the address label
    const addressMarker = new Feature({
      geometry: new Point(fromLonLat(coordinates))
    });

    addressMarker.setStyle(addressStyle);

    // Create a vector source and layer for markers
    const vectorSource = new VectorSource({
      features: [marker, addressMarker]
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 1 // Higher zIndex to display markers above other layers
    });

    this.map.addLayer(vectorLayer);

    // After adding all markers, adjust the map view
    if (this.coordinatesList.length === this.addresses.length) {
      setTimeout(() => this.adjustMapView());
    }
  }

  private getMarkerColor(registrationType: number): string {
    switch (registrationType) {
      case 0:
        return '#546E7A';
      case 1:
        return '#1976D2';
      case 2:
        return '#2E7D32';
      case 3:
        return '#bf360c';
      default:
        return '#000000'; // Default color if none of the cases match
    }
  }

  protected adjustMapView() {
    if (this.coordinatesList.length === 0) {
      this.event.loadingVisible = false;
      return;
    }

    // Create an extent from all coordinates
    const extent: Extent = this.coordinatesList.reduce((acc, coord) => {
      const [x, y] = fromLonLat(coord);
      return [
        Math.min(acc[0], x),
        Math.min(acc[1], y),
        Math.max(acc[2], x),
        Math.max(acc[3], y)
      ];
    }, [Infinity, Infinity, -Infinity, -Infinity]);

    // Ensure extent is valid
    if (extent[0] !== Infinity && extent[1] !== Infinity && extent[2] !== -Infinity && extent[3] !== -Infinity) {
      if (this.coordinatesList.length === 1) {
        // If there's only one marker, set a default zoom level and center
        const view = this.map.getView();
        view.setCenter(fromLonLat(this.coordinatesList[0]));
        view.setZoom(16); // Adjust this zoom level as needed
      } else {
        // Fit the extent to the map view with padding
        this.map.getView().fit(extent, {
          size: this.map.getSize(),
          padding: [50, 50, 50, 50]
        });
      }
      this.event.loadingVisible = false;
    }
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {
    });
  }

  onCancelRoutesMap() {
    this.isVisible = false;
  }

  calculateRoute() {
    if (this.addresses.length >= 2){
      this.event.loadingVisible = true;
      const addressPromises = this.addressPromises;
      Promise.all(addressPromises).then(coordinatesList => {
        const filteredCoordinates = coordinatesList.filter(coord => coord.length > 0);
        if (filteredCoordinates.length > 1) {
          this.plotRoute(filteredCoordinates);
        } else {
          console.error('Zbyt mało poprawnych współrzędnych do wyznaczenia trasy');
        }
        this.event.loadingVisible = false;
      }).catch(error => {
        console.error('Błąd podczas wyznaczania trasy:', error);
        this.event.loadingVisible = false;
      });
    }
  }

  private plotRoute(coordinatesList: number[][]) {

    if (this.routeLayerAdded) {
      return;
    }

    this.geocodingService.getRoute(coordinatesList).subscribe(
      (routeData: any) => {
        if (routeData && routeData.routes && routeData.routes.length > 0) {
          const routeFeatureData = routeData.routes[0];
          if (routeFeatureData.geometry) {
            const decodedGeometry = polyline.decode(routeFeatureData.geometry);
            const transformedCoordinates = decodedGeometry.map((coord: number[]) => fromLonLat([coord[1], coord[0]]));
            const routeFeature = new Feature({
              geometry: new LineString(transformedCoordinates)
            });

            routeFeature.setStyle(new Style({
              stroke: new Stroke({
                color: '#008CD2',
                width: 6,
              })
            }));

            const routeSource = new VectorSource({
              features: [routeFeature]
            });

            const routeLayer = new VectorLayer({
              source: routeSource,
              zIndex: 0 // zIndex na niższy niż dla warstwy markerów
            });

            this.map.addLayer(routeLayer);
            this.routeLayerAdded = true;

          } else {
            console.error('Nieprawidłowe dane geometryczne trasy:', routeFeatureData);
          }
        } else {
          console.error('Nieprawidłowe dane trasy:', routeData);
        }
        this.event.loadingVisible = false;

      },
      (error) => {
        this.event.httpErrorNotification(error);
        this.event.loadingVisible = false;
      }
    );
  }

  openInGoogleMaps() {
    if (this.coordinatesList.length > 0) {
      // Ensure coordinates are valid before constructing the URL
      const validCoordinates = this.coordinatesList.filter(coord => coord.length === 2);
      console.log('validCoordinates:', validCoordinates);
      const baseUrl = 'https://www.google.com/maps/dir/';
      const coordinatesString = validCoordinates.map(coord => `${coord[1]},${coord[0]}`).join('/');
      const url = `${baseUrl}${coordinatesString}`;

      window.open(url, '_blank');
    } else {
      console.warn('No valid coordinates available to open in Google Maps.');
    }
  }


  ngOnDestroy() {
    this.addressPromises = [];
    this.coordinatesList = [];
    this.routeLayerAdded = false;
    this.onClosing.emit(true);
    this.event.loadingVisible = false;
    this.map.setTarget(null);

    this.event.onHiddenPopUp();
  }

}
