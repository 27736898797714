import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyDate } from '../single-currency-date/currency-date';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DxDataGridModule } from 'devextreme-angular';

@Component({
    selector: 'app-date-times-grid',
    templateUrl: './date-times-grid.component.html',
    styleUrls: ['./date-times-grid.component.scss'],
    imports: [CommonModule, TranslateModule, DxDataGridModule]
})
export class DateTimesGridComponent implements OnInit {
  @Input() dataSource: any = [];
  @Input() nestedData: any[] = [];

  selectedCurrencyValues: any[] = [];
  selectedCurrencyDate: CurrencyDate = null;
  selectedDateTimes: any[] = [];

  @Output('selectedDate') selectedDate: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('selectedCurrency') selectedCurrency: EventEmitter<any> =
    new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onSelectionChanged(event) {
    this.selectedDate.emit(this.selectedDateTimes[0]);
    this.selectedCurrencyValues = [];
    this.selectedCurrency.emit([]);

    event.component.collapseAll(-1);
    event.component.expandRow(event.currentSelectedRowKeys[0]);
  }

  onCurrencySelectionChanged() {
    this.selectedCurrency.emit(this.selectedCurrencyValues);
  }
}
