<div *ngIf="!dropDownBoxMode && !popUpMode">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </div>
</div>

<div *ngIf="popUpMode">
  <dx-popup
    [width]="widthPopup"
    [height]="
      event.deviceType != 'mobile' ? 'calc(100vh - 32px)' : 'calc(100% - 100px)'
    "
    position="bottom"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [resizeEnabled]="false"
    [title]="title"
    [visible]="isVisible"
    (visibleChange)="visibleChange($event); checkFocusSelectedRow()"
    (onShown)="event.onShownPopUp()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onChoosingRow()"
          [id]="'contractors' + unicalGuid"
          *ngIf="!readOnly && !popUpMode"
          [disabled]="focusedSelected.length == 0 || readOnly"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onChoosingRow()"
          [id]="'contractors' + unicalGuid"
          *ngIf="popUpMode"
          [disabled]="focusedSelected.length == 0"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.close" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <dx-scroll-view
        width="100%"
        height="100%"
        direction="vertical"
        class="bottom-grid"
      >
        <div class="top-menu-popup">
          <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </dx-scroll-view>
    </div>
  </dx-popup>
</div>

<div
  *ngIf="dropDownBoxMode"
  class="customer_div"
  [ngClass]="{ 'no-max-width': noMaxWidth }"
  [ngStyle]="{ width: widthDropBox || '276px' }"
  style="display: inline-block !important"
>
  <dx-drop-down-box
    [(value)]="choosingDocument"
    valueExpr="TaxOfficeId"
    [deferRendering]="false"
    displayExpr="Name"
    [(opened)]="isGridBoxOpened"
    [dataSource]="documentList"
    [showClearButton]="true"
    style="width: 276px"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="onOpenedChanged($event)"
    (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()"
    (keydown.space)="isGridBoxOpened = !isGridBoxOpened; cd.detectChanges()"
    [ngClass]="{ 'input-required': className == true }"
    [readOnly]="readOnly"
    [id]="'dropDown' + unicalGuid"
    placeholder="{{ 'offices.selectOffice' | translate }}"
  >
    <div
      *dxTemplate="let data of 'content'"
      style="height: 280px; min-height: 280px"
    >
      <div class="row">
        <div class="col-md-12" class="no-padding">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="258"
            [(selectedRowKeys)]="selectedRow"
            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            (onSelectionChanged)="onSelectionChanged($event)"
            [focusedRowEnabled]="true"
            #gridClass
            [hoverStateEnabled]="true"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="200"></dxo-paging>
            <dxo-selection
              [mode]="dropDownBoxMode ? 'single' : 'multiple'"
              showCheckBoxesMode="always"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              [width]="event.deviceType != 'mobile' ? 45 : 10"
              cellTemplate="editTemplate"
              [allowSorting]="false"
              alignment="center"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
              <dx-button
                class="btn-dx-top-menu-folder-grid"
                icon="icon absui-icon--mode-edit hover-edit-icon"
                [disabled]="readOnly || !this.perABD.editBtn"
                (onClick)="editOffice()"
              >
              </dx-button>
            </div>
            <dxi-column
              caption="{{ 'offices.officesName' | translate }}"
              [width]="widthCityColumn"
              dataField="Name"
              [allowSorting]="false"
            ></dxi-column>
            <dxi-column
              caption="{{ 'offices.offcesCode' | translate }}"
              [width]="100"
              dataField="Code"
              [allowSorting]="false"
            ></dxi-column>
          </dx-data-grid>

          <dx-context-menu
            [dataSource]="contextMenu()"
            [width]="200"
            [target]="'#grid' + unicalGuid"
            (onItemClick)="contextMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          >
          </dx-context-menu>
        </div>
      </div>

      <div class="bottom-drop-box">
        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--add-circle"
          [text]="'buttons.add' | translate"
          [id]="'btn-drop-add' + unicalGuid"
          [disabled]="readOnly || !this.perABD.addBtn"
          (onClick)="setSingleRecordVisible('add')"
        >
        </dx-button>

        <dx-tooltip
          [target]="'#btn-drop-add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }}
          </div>
        </dx-tooltip>
      </div>
    </div>
  </dx-drop-down-box>

  <dx-tooltip
    [target]="'#dropDown' + unicalGuid"
    [(visible)]="isShowTooltip"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">
      {{ customerNameSelected }}
    </div>
  </dx-tooltip>
  <!-- <dx-tooltip [target]="'#dropDown'+unicalGuid" [showEvent]="event.tooltipShowEvent" hideEvent="dxhoverend"
    [hideOnOutsideClick]="false" *ngIf="isShowTooltip" >
    <div *dxTemplate="let data of 'content'">
      {{customerNameSelected}}
    </div>
  </dx-tooltip> -->
</div>

<ng-template #topButtons>
  <dx-scroll-view
    width="95%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      type="default"
      stylingMode="text"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="setSingleRecordVisible('add')"
      data-cy="office-add-btn"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      [id]="'btn-edit' + unicalGuid"
      icon="icon absui-icon--mode-edit"
      type="default"
      stylingMode="text"
      [disabled]="selectedRow.length == 0 || readOnly || !perABD.editBtn || focusedSelected[0]?.IsReadOnly"
      (onClick)="editOffice()"
      data-cy="office-edit-btn"
    >
    </dx-button>

    <dx-button
      icon="icon absui-icon--form-items"
      [id]="'btn-show' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="focusedSelected.length == 0 || !perABD.showBtn"
      stylingMode="text"
      type="default"
      (onClick)="editOffice()"
    ></dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--highlight-off"
      type="default"
      stylingMode="text"
      [id]="'btn-delete' + unicalGuid"
      [disabled]="selectedRow.length == 0 || readOnly || !perABD.deleteBtn || focusedSelected[0]?.IsReadOnly"
      (onClick)="isDelete()"
      data-cy="office-delete-btn"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--tool-select-rectangle"
      [text]="'buttons.choose' | translate"
      [disabled]="selectedRow.length == 0 || readOnly"
      [id]="'btn-choose' + unicalGuid"
      (onClick)="onChoosingRow()"
      *ngIf="dropDownBoxMode"
    >
    </dx-button>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-show' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift +F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-choose' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.choose" | translate }} (Enter)
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <app-custom-dropdown-box
        [openDropDown]="openCustomDropDown"
        [items]="filterCriteria"
        [(selectedItem)]="valueCriteria"
        [(filterValue)]="filterValue"
        (onValueChanged)="onFilterDataChanged($event)"
        style="margin-right: 5px"
        (onArrowDown)="gridClass.instance.focus()"
        #searchControl
      >
      </app-custom-dropdown-box>

      <dx-data-grid
        id="gridContainer"
        #gridClass
        [dataSource]="dataSource"
        [(focusedRowIndex)]="focusedRowIndex"
        [remoteOperations]="true"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        shortcut
        [height]="!popUpMode ? heightGrid : emptyStateHeight - heightGridPopUp"
        [(selectedRowKeys)]="selectedRow"
        (onSelectionChanged)="onSelectionChanged($event)"
        [focusedRowEnabled]="true"
        (onRowDblClick)="onRowDblClick()"
        [id]="'grid' + unicalGuid"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        (onKeyDown)="onKeyDown($event)"
        data-cy="office-grid"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling
          mode="infinite"
          columnRenderingMode="virtual"
          preloadEnabled="true"
        ></dxo-scrolling>
        <dxo-paging [pageSize]="100"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
        <dxi-column
          caption="{{ 'offices.officesName' | translate }}"
          [width]="widthCityColumn"
          dataField="Name"
          [allowSorting]="false"
          cellTemplate="mainColumn"
        ></dxi-column>

        <div *dxTemplate="let data of 'mainColumn'">
          <span class="hov-underline" (click)="editOffice()">{{
            data.value
          }}</span>
        </div>

        <dxi-column
          caption="{{ 'offices.offcesCode' | translate }}"
          dataField="Code"
          width="100"
          [allowSorting]="false"
        ></dxi-column>
        <dxi-column
          caption="{{ 'offices.city' | translate }}"
          dataField="City"
          [allowSorting]="false"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'offices.postaCode' | translate }}"
          dataField="PostalCode"
          width="100"
          [allowSorting]="false"
        ></dxi-column>
        <dxi-column
          caption="{{ 'offices.street' | translate }}"
          dataField="Street"
          [allowSorting]="false"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'offices.phone' | translate }}"
          width="120"
          dataField="Phone"
          [allowSorting]="false"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'offices.mail' | translate }}"
          dataField="Email"
          [allowSorting]="false"
        >
        </dxi-column>

        <div *dxTemplate="let data of 'IsDefault'">
          <i
            class="icon absui-icon--check check-grid"
            *ngIf="data.data.IsDefault; else nonDefaultTemplate"
          ></i>
        </div>
      </dx-data-grid>

      <dx-context-menu
        [dataSource]="contextMenu()"
        [width]="200"
        [target]="'#grid' + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
      </dx-context-menu>
    </div>
  </div>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    (!isGridBoxOpened && dropDownBoxMode) ||
    isSingleRecordVisible ||
    isDeleteRow
  "
>
</wapro-keyboard-shortcuts>

<app-add-offices
  [isVisible]="isSingleRecordVisible"
  [mode]="singleRecordMode"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted()"
  [selectedId]="focusedSelected"
  title="{{ 'offices.addOffice' | translate }}"
>
</app-add-offices>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<ng-template #nonDefaultTemplate>
  <i class="icon absui-icon--close close-grid"></i>
</ng-template>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="setSingleRecordVisible('add')"
    >
    </dx-button>
  </div>
</ng-template>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <app-custom-dropdown-box
    [items]="filterCriteria"
    [(selectedItem)]="valueCriteria"
    [(filterValue)]="filterValue"
    (onValueChanged)="onFilterDataChanged($event)"
    style="margin-right: 5px"
    (onArrowDown)="gridClass.instance.focus()"
    #searchControl
  >
  </app-custom-dropdown-box>

  <dx-data-grid
    id="gridContainer"
    #gridClass
    [dataSource]="dataSource"
    [remoteOperations]="true"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="heightGrid - 60"
    [(selectedRowKeys)]="selectedRow"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    (onRowDblClick)="onRowDblClick()"
    [id]="'grid' + unicalGuid"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="200"></dxo-paging>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'mainColumn'">
      <div style="display: flex">
        <div class="content-main-grid">
          <span
            class="hov-underline-mobile"
            (click)="setSingleRecordVisible('edit')"
            >{{ data.data.Name }}</span
          ><br />

          <label class="mobile-grid-label"
            >{{ "offices.offcesCode" | translate }}:</label
          >
          <p class="mobile-grid-text">{{ data.data.Code }}</p>
          <br />

          <label class="mobile-grid-label"
            >{{ "offices.city" | translate }}:</label
          >
          <p class="mobile-grid-text">{{ data.data.City }}</p>
          <br />

          <label class="mobile-grid-label"
            >{{ "offices.postaCode" | translate }}:</label
          >
          <p class="mobile-grid-text">{{ data.data.PostalCode }}</p>
          <br />

          <label class="mobile-grid-label"
            >{{ "offices.street" | translate }}:</label
          >
          <p class="mobile-grid-text">{{ data.data.Street }}</p>
          <br />

          <label class="mobile-grid-label"
            >{{ "offices.phone" | translate }}:</label
          >
          <p class="mobile-grid-text">{{ data.data.Phone }}</p>
          <br />

          <label class="mobile-grid-label"
            >{{ "offices.mail" | translate }}:</label
          >
          <p class="mobile-grid-text">{{ data.data.Email }}</p>
          <br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="isContextMenuMobile = true"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenu()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>
</ng-template>
