import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-add-finance-operation',
    templateUrl: './add-finance-operation.component.html',
    styleUrls: ['./add-finance-operation.component.scss'],
    inputs: ['isVisible', 'selected', 'readOnly', 'title', 'mode'],
    standalone: false
})
export class AddFinanceOperationComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firsFocus') firsFocus;

  isVisible;
  selected;
  readOnly;
  title;
  mode;
  unicalGuid;
  form;
  widthWindow = 425;
  heightWindow = 210;
  submitted: boolean = true;
  shortcuts = [];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(false);
        },
        preventDefault: true,
        priority: 1000,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
        priority: 1000,
      }
    );
  }

  ngOnChanges(): void {
    if (this.isVisible && this.mode == 'edit' && this.selected.length > 0) {
      setTimeout(() => {
        this.form.patchValue(this.selected[0]);
        this.cd.detectChanges();
      }, 0);
    }
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      Code: ['', Validators.required],
      Name: ['', Validators.required],
    });
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    this.submitted = true;

    if (this.form.valid) {
      if (this.mode == 'add') {
        this.appService
          .postAuth(`document/sales/types/finance/operation`, this.form.value)
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
        return;
      }

      if (this.mode == 'edit') {
        this.appService
          .putAuth(
            `document/sales/types/finance/operation/${this.form.value.Code}`,
            this.form.value
          )
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
        return;
      }
    }
  };
}
