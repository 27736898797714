import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

import { Subscription } from 'rxjs';
import { SingleRecordMode } from 'src/app/event.model';
import { HelpService } from '../../../../help-service.service';

@Component({
    selector: 'app-add-task-types',
    templateUrl: './add-task-types.component.html',
    styleUrls: ['./add-task-types.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'selected', 'mode'],
    standalone: false
})
export class AddTaskTypesComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('focusFirst') focusFirst;

  mode: SingleRecordMode = null;
  isVisible;
  visibleShortcut;
  selectedId;
  selected;
  readOnly;
  title;

  widthWindow = 450;
  heightWindow = 240;

  shortcuts: ShortcutInput[] = [];

  form;
  submitted: boolean = false;

  unicalGuid;

  secondaryColors = [];
  primaryColors = [];
  colors = [];
  colors$: Subscription;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private helpService: HelpService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.colors$ = this.event.colors.subscribe((res) => {
      this.colors = res;
      if (!res || !res.length) {
        this.getData();
        return;
      }
      this.secondaryColors = res.filter(
        (color) => color.Type === 'complementary'
      );
      this.primaryColors = res.filter((color) => color.Type === 'main');
    });
    this.createForm();
  }

  ngOnChanges() {
    if ((this.mode === 'edit' || this.mode == 'show') && this.isVisible) {
      if (this.mode == 'show') this.readOnly = true;
      else this.readOnly = this.event.readOnly;
      if (this.selectedId && this.selectedId.length != 0) {
        this.getDateSelectedId(this.selectedId[0]);
      } else if (this.selected.length != 0) {
        setTimeout(() => {
          this.form.patchValue(this.selected[0]);
          this.cd.detectChanges();
        }, 0);
      }
    }

    if (this.isVisible) {
      setTimeout(() => {
        this.focusFirst.instance.focus();
      }, 700);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.disabletShortcuts.next(true);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(`crm/taskTypes?typeId=${selectedId}`)
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
        // if (this.form.get('DefaultColor').value !== null && this.form.get('DefaultColor').value !== 0) this.form.controls.DefaultColor.setValue('#'+this.form.get('DefaultColor').value)
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      TypeId: [0],
      Name: ['', Validators.required],
      IsPhased: [false],
      IsSystem: [false],
      IsDefault: [false],
      DefaultColor: ['4DB6AC'],
    });
  };

  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.createForm(); //reset form with initial values
    this.submitted = false;
    this.event.disabletShortcuts.next(e);

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onColourChoosed(e) {
    if (e) {
      this.form.controls.DefaultColor.setValue(e.Body.substr(1));
      this.cd.detectChanges();
    }
  }

  getData() {
    this.appService.getAuth('crm/colorPalette').subscribe(
      (res) => {
        this.event.colors.next(res.data);
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  }

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (
        this.form.value.DefaultColor !== null &&
        this.form.value.DefaultColor != 0
      )
        this.form.value.DefaultColor = this.form.value.DefaultColor.replace(
          '#',
          ''
        );
      if (this.form.value.TypeId == 0) {
        this.appService.postAuth('crm/taskTypes', this.form.value).subscribe(
          () => {
            this.onInserted.emit(this.form.value);
            this.isVisible = false;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
      } else {
        this.appService
          .putAuth(`crm/taskTypes/${this.form.value.TypeId}`, this.form.value)
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };
}
