import { NgModule } from '@angular/core';
import { StockHistoryComponent } from './stock-history.component';
import { StockHistorySummaryComponent } from './stock-history-summary/stock-history-summary.component';
import { StockHistoryFiltersComponent } from './stock-history-filters/stock-history-filters.component';

@NgModule({
  imports: [
    StockHistorySummaryComponent,
    StockHistoryFiltersComponent,
    StockHistoryComponent,
  ],
  exports: [
    StockHistorySummaryComponent,
    StockHistoryFiltersComponent,
    StockHistoryComponent,
  ],
})
export class StockHistoryModule {}
