import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
  computed,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { HelpService } from 'src/app/help-service.service';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { DoubleClickResult, SingleRecordMode } from 'src/app/event.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.scss'],
  inputs: ['dropDownBoxMode', 'selectedId', 'readOnly', 'className'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OfficesComponent implements OnInit {
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass;

  @Output() onDropdownOpened = new EventEmitter();
  @Output() onClosed = new EventEmitter();

  @Input() popUpMode: boolean = false;
  @Input() isVisible: boolean = false;
  @Input() widthDropBox;
  @Input() noMaxWidth = false;

  helpService = inject(HelpService);
  openCustomDropDown: boolean = false;
  readOnly: boolean = false;
  className;
  selectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  documentListPom = [];
  isContextMenuMobile: boolean = false;
  isGridBoxOpened: boolean = false;
  documentList;
  modeSelectionMobile: any = null;
  dataSource;
  isDeleteRow: boolean = false;
  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 200;
  heightGridPopUp: number = 217;
  emptyStateHeight;

  myEventChoose;
  myEventEsc;
  unicalGuid;
  widthCityColumn = '300';

  filterCriteria = [
    { value: 'name', label: 'Nazwa' },
    { value: 'city', label: 'Miasto' },
    { value: 'postalCode', label: 'Kod pocztowy' },
  ];

  valueCriteria = 'Nazwa';
  filterValue = '';

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };

  focusedSelected = [];
  doubleClickResult: DoubleClickResult = 'Edycja';
  focusedRowIndex = -1;
  widthPopup;
  title;

  translate = inject(TranslateService);
  isReadOnly = signal<boolean>(false);

  contextMenu = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: this.readOnly || !this.perABD.addBtn,
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled:
          this.readOnly ||
          !this.perABD.editBtn ||
          this.isReadOnly(),
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled:
          this.readOnly ||
          !this.perABD.deleteBtn ||
          this.isReadOnly(),
      },
    ];
  });

  constructor(
    private appService: AppServices,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.widthPopup = this.event.setWidthPopUp();

    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
    });

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('offices.officesName')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('offices.city')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('offices.postaCode')
      .subscribe((text) => (this.filterCriteria[2].label = text));

    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }
  }

  visibleChange = (e) => {
    if (!e) {
      setTimeout(() => {
        this.isVisible = false;
        this.popUpMode = false;
        this.onClosed.emit(true);
        this.cd.detectChanges();
      }, 0);
    }
  };

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;
    this.getDate(true);
  }

  ngOnInit(): void {
    this.valueCriteria = this.filterCriteria[0].value;
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
    this.getDate();

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.focusedSelected.length > 0) {
          this.onChoosingRow();
        }
      }
    });

    this.perABD = this.event.checkPermissionsBtn(
      'DUrzedy',
      'EUrzedy',
      'UUrzedy',
      'OUrzedy'
    );

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.perABD.addBtn) {
          this.setSingleRecordVisible('add');
        }
        break;
      case 1:
        if (!this.readOnly && this.perABD.editBtn) {
          this.editOffice();
        }
        break;
      case 2:
        if (!this.readOnly && this.perABD.deleteBtn) {
          this.isDelete();
        }
        break;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.popUpMode && changes.isVisible?.currentValue) {
      this.searchControl?.focusInput();
    }

    if (changes?.isVisible?.currentValue) {
      setTimeout(() => {
        this.searchControl.focusInput();
      }, 500);
    }

    if (this.selectedId && this.selectedId != '') {
      this.appService
        .getAuth(`offices/taxOffices?taxOfficeId=${this.selectedId}`)
        .subscribe((res) => {
          this.documentList = res.data;
          this.choosingDocument = [Number(this.selectedId)];
          this.cd.detectChanges();
        });
    } else {
      this.documentList = [];
      this.choosingDocument = [];
    }
  }

  firstLoad = true;
  getDate = (fromSearch: boolean = false) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'TaxOfficeId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}offices/taxOffices`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          this.focusedRowIndex = 0;
          this.onFocusedRowChanged({
            row: { data: data[this.focusedRowIndex] },
          });
          if (this.firstLoad) {
            this.firstLoad = false;
            if (!this.dropDownBoxMode) {
              setTimeout(() => {
                this.searchControl?.focusInput();
              }, 500);
            }
          }
          if (fromSearch) {
            this.searchControl?.focusInput();
          }
        },
        deleteUrl: `${environment.domain}offices/taxOffices`,
      }),
      reshapeOnPush: true,
    });
  };

  getLoadParams() {
    let obj: any = {};

    switch (this.valueCriteria) {
      case 'name':
        obj.name = this.filterValue;
        break;

      case 'city':
        obj.city = this.filterValue;
        break;

      case 'postalCode':
        obj.postalCode = this.filterValue;
        break;
      default:
        break;
    }

    return obj;
  }

  ngOnDestroy() {
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.perABD.addBtn) {
            this.setSingleRecordVisible('add');
          }
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-urzedow'
          );
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRow.length != 0 &&
            !this.readOnly &&
            this.perABD.editBtn
          ) {
            this.editOffice();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRow.length > 0 &&
            !this.readOnly &&
            this.perABD.deleteBtn
          ) {
            this.isDelete();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.openCustomDropDown = !this.openCustomDropDown;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.popUpMode && this.focusedSelected.length != 0) {
            this.onChoosingRow();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
          if (this.popUpMode) {
            this.isVisible = false;
            this.popUpMode = false;
            this.onClosed.emit(true);
          }
        },
        preventDefault: true,
      }
    );

    setTimeout(() => {
      this.title = this.translate.instant('offices.taxOfficesList');

      this.emptyStateHeight = window.innerHeight - 32;

      if (this.popUpMode) {
        this.searchControl?.focusInput();
      }
    }, 100);
  }

  onClosingForm = () => {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  onInserted = () => {
    this.dataSource.reload();
    this.singleRecordMode = null;
  };

  onFocusedRowChanged = (e) => {
    this.selectedRow = [e?.row?.data?.TaxOfficeId];
    this.documentListPom = [e?.row?.data];
    this.focusedSelected = [e?.row?.data];
    this.isReadOnly.set(e?.row?.data?.IsReadOnly);
  };

  checkFocusSelectedRow() {
    if (this.dataSource && this.dataSource._items && this.selectedId) {
      let index = this.dataSource._items.findIndex(
        (el) => el.TaxOfficeId === this.selectedId
      );
      if (index > -1) {
        this.focusedRowIndex = index;
      } else {
        this.focusedRowIndex = 0;
      }
    }
  }

  onChoosingRow = () => {
    if (!this.readOnly) {
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      this.onChoosed.emit(this.documentList[0]);
      this.isGridBoxOpened = false;
      this.isVisible = false;
    }
  };

  onOpenedChanged = (e) => {
    if (e) {
      this.onDropdownOpened.emit();
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  customerNameSelected = '';
  isShowTooltip: boolean = false;
  onValueChanged = (e) => {
    this.customerNameSelected = '';
    this.isShowTooltip = false;
    if (e) {
      setTimeout(() => {
        try {
          this.customerNameSelected = this.documentList[0].Name;
        } catch {}
      }, 0);
    }

    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  mouseenter() {
    if (this.customerNameSelected != '') {
      setTimeout(() => {
        this.isShowTooltip = !this.isShowTooltip;
        this.cd.detectChanges();
      }, 1000);
    }
  }

  mouseleave() {
    this.isShowTooltip = false;
  }

  onSelectionChanged = (e) => {
    this.documentListPom = [];
    this.documentListPom.push(e.selectedRowsData[0]);
  };

  isDelete = () => {
    if (
      this.focusedSelected.length > 0 &&
      this.perABD.deleteBtn &&
      !this.focusedSelected[0]?.IsReadOnly &&
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.instance.focus();
      }
    }, 500);
  }

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) {
      if (this.doubleClickResult === 'Edycja' && this.perABD.editBtn) {
        if (this.focusedSelected[0].IsReadOnly) {
          this.setSingleRecordVisible('show');
        } else {
          this.setSingleRecordVisible('edit');
        }
      } else if (this.perABD.showBtn) {
        this.setSingleRecordVisible('show');
      }
    } else {
      this.onChoosingRow();
    }
  };

  delete = () => {
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(this.selectedRow[0])
    );
    if (this.choosingDocument == this.selectedRow[0]) {
      this.choosingDocument = [];
    }
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.focusedRowIndex = 0;
        this.gridClass.instance.focus();
      }
    }, 500);
  };

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  editRecord(e) {
    e.stopPropagation();
    if (
      !this.readOnly &&
      !this.isSingleRecordVisible &&
      this.perABD.editBtn &&
      this.selectedRow.length > 0 &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.setSingleRecordVisible('edit');
    }
  }

  isSingleRecordVisible: boolean = false;
  singleRecordMode: SingleRecordMode = null;

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  editOffice() {
    if (this.focusedSelected[0].IsReadOnly) {
      this.setSingleRecordVisible('show');
    } else {
      this.setSingleRecordVisible('edit');
    }
  }

  onKeyDown(e: any) {
    if (e.event.keyCode === 113) {
      if (
        this.selectedRow.length != 0 &&
        !this.readOnly &&
        this.perABD.editBtn
      ) {
        this.editOffice();
      }
    }
    if (e.event.keyCode === 27 && this.popUpMode) {
      this.isVisible = false;
      this.popUpMode = false;
      this.onClosed.emit(true);
    }
  }
}
