import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { SingleRecordMode } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { InterconnectorService } from 'src/app/interconnector.service';
import {
  FiscalPrinter,
  FiscalPrinterType,
  PrinterSettings,
} from './fiscal-printer';

@Component({
    selector: 'app-fiscal-printer',
    templateUrl: './fiscal-printer.component.html',
    styleUrls: ['./fiscal-printer.component.scss'],
    inputs: ['isVisible', 'title', 'mode', 'fiscalPrinterId'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FiscalPrinterComponent {
  @Input() computerName: string = null;
  @Output() onClosingForm = new EventEmitter();
  @Output() onSaved = new EventEmitter();

  @ViewChild('printerName') printerName: DxTextBoxComponent;

  title: string;
  isVisible: boolean;
  fiscalPrinterId: number;

  unicalGuid;
  dataSource;
  firstRequest;
  submitted: boolean = false;
  warehouses;
  collectorId;
  focusedFiscalPrinter: FiscalPrinter = null;
  mode: SingleRecordMode;
  tabs = ['Ustawienia podstawowe', 'Ustawienia indywidualne drukarki'];
  activeTabIndex: number = 0;
  fiscalPrinterTypes: FiscalPrinterType[] = [];
  accordionOpened = false;
  individualSettingsEnabled: boolean = false;
  settingsVisible: boolean = false;

  connectionType = [
    {
      label: 'Lokalne',
      value: 'localConnnection',
      id: 0,
      disabled: true,
    },
    {
      label: 'Zdalne lub terminalowe',
      value: 'remoteConnection',
      id: 1,
      disabled: false,
      items: [
        {
          label: 'Zdalna drukarka fiskalna',
          value: 'remotePrinter',
          id: 1,
          disabled: true,
        },
        {
          label: 'Serwer urządzeń',
          value: 'deviceServer',
          id: 2,
          disabled: false,
        },
      ],
    },
  ];

  defaultIpAddress: string = 'https://localhost';
  defaultPortNumber: number = 7721;
  defaultComputerName: string = null;

  printerSettings: PrinterSettings;

  individualSettings = [
    {
      name: 'workWithTerminal',
      type: 'checkbox',
      value: false,
      disabled: true,
      label: 'Współpraca z terminalem płatniczym CELTO 51',
    },
  ];
  individualSettingsAccordion = [
    { value: 0, label: 'Nagłówek', disabled: true },
    { value: 0, label: 'Stopka', disabled: true },
    { value: 0, label: 'Dodatkowe' },
  ];
  individualSettingsAccordionIndex = [];

  IpResetButton;
  portResetButton;
  formBuilder = inject(FormBuilder);
  form: FormGroup = this.formBuilder.group({
    Name: ['', Validators.required],
    TypeId: [null, [Validators.required]],
    BaudRate: [null],
    ComputerName: [null, [Validators.required]],
    IpAddress: [this.defaultIpAddress, [Validators.required]],
    PortNumber: [this.defaultPortNumber, [Validators.required]],
  });

  shortcuts: ShortcutInput[] = [];
  loading: boolean;
  scrollPosition: any;

  constructor(
    private appService: AppServices,
    private interconnector: InterconnectorService,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.defaultIpAddress = 'https://localhost';
    this.defaultPortNumber = 7721;

    this.IpResetButton = {
      icon: 'icon icon-refresh',
      stylingMode: 'text',
      width: 32,
      elementAttr: {
        class: 'reset-button',
      },
      onClick: () => {
        this.resetIpNumber();
      },
    };

    this.portResetButton = {
      icon: 'icon icon-refresh',
      stylingMode: 'text',
      width: 32,
      elementAttr: {
        class: 'reset-button',
      },
      onClick: () => {
        this.resetPortNumber();
      },
    };

    this.printerSettings = {
      // Name: '',
      // FiscalPrinterType: null,
      ConnectionType: 'remoteConnection',
      // ComputerName: this.event.getComputerName(),
      RemoteConnection: 'deviceServer',
      // IpAddress: this.defaultIpAddress,
      // PortNumber: this.defaultPortNumber,
      SharedPrinter: false,
    };
    // this.form = this.formBuilder.group({
    //   FiscalPrinterId: [0],
    //   Name: ['', Validators.required],
    //   TypeId: [null, [Validators.required]],
    //   BaudRate: [null],
    //   ComputerName: [this.getComputerName(), [Validators.required]],
    //   IpAddress: [this.defaultIpAddress],
    //   PortNumber: [this.defaultPortNumber, [Validators.required]],
    // });
  }

  async ngOnInit() {
    this.getPrinterModels();
    this.computerName = await this.getComputerName();
    this.form.controls['ComputerName'].setValue(this.computerName);
  }

  async getComputerName() {
    return new Promise<string>((resolve) => {
      if (!this.computerName) {
        this.interconnector.getComputerName().subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (err) => {
            let error = JSON.parse(err);
            if (error.error == 0) {
              // this.noInterconnectorResponse = true;
            } else {
              this.event.httpErrorNotification(err);
            }
            resolve(null);
          },
        });
      } else {
        resolve(this.computerName);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue) {
      setTimeout(() => {
        this.printerName.instance.focus();
      }, 600);
      if (this.mode == 'add') {
        this.resetForm();
        this.translate
          .get('fiscalPrinter.addMode')
          .subscribe((text) => (this.title = text));
        this.selectPrinterType();
      }
      if (this.mode == 'edit') {
        this.translate
          .get('fiscalPrinter.editMode')
          .subscribe((text) => (this.title = text));
      }
      if (this.fiscalPrinterId && this.mode != 'add') {
        this.restoreForm();
      }
    }
  }

  async restoreForm() {
    this.loading = true;
    const formData = await this.getFiscalPrinter(this.fiscalPrinterId);
    // this.printerSettings.FiscalPrinterType = this.fiscalPrinterTypes.find( type => type.TypeId == formData.TypeId);
    if (formData) {
      this.form.setValue({
        Name: formData.Name,
        TypeId: formData.TypeId,
        BaudRate: formData.BaudRate,
        ComputerName: formData.ComputerName,
        IpAddress: formData.IpAddress,
        PortNumber: formData.PortNumber,
      });
    }
    this.loading = false;
  }

  getFiscalPrinter(fiscalPrinterId: number) {
    return new Promise<any>(async (resolve, reject) => {
      const params = {
        fiscalPrinterId: fiscalPrinterId,
        computerName: await this.getComputerName(),
      };
      this.appService.getAuth(`device/fiscalPrinter`, params).subscribe({
        next: (res) => {
          // zabezpieczenie przeciwko nadpisaniu wartości parametru ComputerName
          // for(let param in res.data[0]) {
          //   if (param !== 'ComputerName') this.printerSettings[param] = res.data[0][param]
          // }
          resolve(res.data[0]);
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
          reject();
        },
      });
    });
  }

  ngAfterViewInit() {
    this.getTranslation();
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancel();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = () => {
    this.submitted = false;
    if (this.mode == 'add') {
      this.accordionOpened = false;
    }
    if (this.mode == 'edit') {
    }
  };

  getTranslation() {
    this.translate
      .get('fiscalPrinter.connectionConfig.workWithTerminal')
      .subscribe((text) => {
        this.individualSettings.find(
          (setting) => setting.name == 'workWithTerminal'
        ).label = text;
      });
  }

  selectPrinterType(type?) {
    if (!type) {
      this.form.controls['TypeId'].setValue(this.fiscalPrinterTypes[0].TypeId);
      this.cd.detectChanges();
    }
  }

  onResizeStart = (_e) => {};

  onResizeEnd = (_e) => {};

  onShown = (_e) => {
    this.event.onShownPopUp();
  };

  onHidden = (_e) => {
    this.event.onHiddenPopUp();
  };

  onInitialized = (_e) => {};

  onCancel = () => {
    this.onClosing();
  };

  validateForm(params) {
    return false;

    if (!params.Name) {
      return false;
    }
    if (!params.TypeId) {
      return false;
    }
    if (!params.PortNumber) {
      return false;
    }

    return true;
  }

  onSave = () => {
    this.submitted = true;
    // let params = {
    //   FiscalPrinterId: 0,
    //   Name: this.printerSettings.Name,
    //   TypeId: this.printerSettings.FiscalPrinterType.TypeId,
    //   PortNumber: this.printerSettings.PortNumber,
    //   BaudRate: this.printerSettings.BaudRate,
    //   ComputerName: this.printerSettings.ComputerName,
    //   IpAddress: this.printerSettings.IpAddress,
    // };

    if (this.form.valid) {
      let params = Object.assign({}, this.form.value);
      switch (this.mode) {
        case 'add':
          params.FiscalPrinterId = 0;
          this.appService.postAuth(`device/fiscalPrinter`, params).subscribe({
            next: () => {},
            error: (err) => {
              err = JSON.parse(err);
              this.event.showNotification('error', err.error);
            },
            complete: () => {
              this.onSaved.emit(true);
              this.onClosing();
            },
          });
          break;

        case 'edit':
          params.FiscalPrinterId = this.fiscalPrinterId;
          this.appService
            .putAuth(`device/fiscalPrinter/${this.fiscalPrinterId}`, params)
            .subscribe({
              next: (res) => {
                this.onSaved.emit(res);
                this.onClosing();
              },
              error: (err) => {
                this.event.httpErrorNotification(err);
              },
            });
          break;
      }
    }
    // if (this.validateForm(params)) {
    //   switch (this.mode) {
    //     case 'add':
    //       this.appService.postAuth(`device/fiscalPrinter`, params).subscribe({
    //         next: (res) => {
    //
    //         },
    //         error: (err) => {
    //           err = JSON.parse(err);
    //           this.event.showNotification('error', err.error);
    //         },
    //         complete: () => {
    //           this.onSaved.emit(true);
    //           this.onClosing();
    //         },
    //       });
    //       break;

    //     case 'edit':
    //       params.FiscalPrinterId = this.fiscalPrinterId;
    //       this.appService
    //         .putAuth(`device/fiscalPrinter/${params.FiscalPrinterId}`, params)
    //         .subscribe({
    //           next: (res) => {
    //             this.onSaved.emit(res);
    //             this.onClosing();
    //           },
    //           error: (err) => {
    //             this.event.httpErrorNotification(err);
    //           },
    //         });
    //       break;
    //   }
    //   this.mode = 'add';
    // }
  };

  resetForm() {
    this.form.reset();
    this.form.controls['ComputerName'].setValue(this.computerName);
    this.form.controls['IpAddress'].setValue(this.defaultIpAddress);
    this.form.controls['PortNumber'].setValue(this.defaultPortNumber);
  }

  onClosing() {
    this.resetForm();
    this.onClosingForm.emit(true);
    this.submitted = false;
    // this.mode = 'add';
    this.cd.detectChanges();
  }

  getPrinterModels = () => {
    return new Promise<FiscalPrinterType[] | any>((resolve, reject) => {
      this.appService.getAuth('device/fiscalPrinter/types').subscribe({
        next: (res) => {
          this.fiscalPrinterTypes = res.data;
          resolve(res.data);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          reject(err);
        },
      });
    });
  };

  onFiscalPrinterSelect = (_e) => {
    // this.form.controls['TypeId'].setValue(e.selectedItem.TypeId);
    // this.form.controls['TypeDescription'].setValue(e.selectedItem.Description);
  };

  // onConnectionTypeSelect = (_e) => {};

  resetPortNumber() {
    // this.printerSettings.PortNumber = this.defaultPortNumber;
    this.form.controls['PortNumber'].setValue(this.defaultPortNumber);
    this.cd.detectChanges();
  }

  resetIpNumber() {
    this.form.controls['IpAddress'].setValue(this.defaultIpAddress);
    this.cd.detectChanges();
  }

  showSettings() {
    this.settingsVisible = true;
    this.cd.detectChanges();
  }

  onSettingsSaved(_e) {}

  onSettingsClosed() {
    this.settingsVisible = false;
  }

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };
}
