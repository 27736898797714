import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-add-description-dictionary',
    templateUrl: './add-description-dictionary.component.html',
    styleUrls: ['./add-description-dictionary.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'type',
        'controllerType',
        'descriptionRequired',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddDescriptionDictionaryComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('focusFirst') focusFirst;

  descriptionRequired = false;
  controllerType: string = 'services';
  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  type;

  widthWindow = 450;
  heightWindow = 210;

  shortcuts: ShortcutInput[] = [];

  form;
  submitted: boolean = false;

  unicalGuid;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.createForm();
  }

  ngOnChanges() {
    if (this.selectedId) {
      if (this.selectedId.length != 0 && this.isVisible)
        setTimeout(() => {
          this.form.patchValue(this.selectedId[0]);
        }, 0);
      //this.getDateSelectedId(this.selectedId[0])
    }

    if (this.isVisible) {
      setTimeout(() => {
        this.focusFirst.instance.focus();
      }, 1000);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.disabletShortcuts.next(true);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(
        `${this.controllerType}/descriptionDictionary/typeCode/${this.type}?descriptionId=${selectedId}`
      )
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      DescriptionId: [0],
      Name: ['', Validators.required],
      Type: [this.type],
      Description: [''],
      IsDefault: [false],
    });

    if (this.descriptionRequired) {
      this.form.get('Description').setValidators(Validators.required);
    }
  };

  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.createForm(); //reset form with initial values
    this.submitted = false;
    this.event.disabletShortcuts.next(e);

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (this.form.value.DescriptionId == 0) {
        this.appService
          .postAuth(
            `${this.controllerType}/descriptionDictionary/typeCode/${this.form.value.Type}`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else {
        this.appService
          .putAuth(
            `${this.controllerType}/descriptionDictionary/${this.form.value.DescriptionId}/typeCode/${this.form.value.Type}`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };
}
