import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { environment } from '../../../../environments/environment';
import { EventService } from '../../../event.service';
@Component({
    selector: 'app-article-price-position-document',
    templateUrl: './article-price-position-document.component.html',
    styleUrls: ['./article-price-position-document.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'productId',
        'customerId',
        'readOnly',
        'PriceName',
        'DocumentDate',
        'DocumentCurrencyCode',
        'CurrencyFactor',
        'width',
        'isOrderItems',
    ],
    standalone: false
})
export class ArticlePricePositionDocumentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onChoosed = new EventEmitter();

  width;
  unicalGuid;
  widthWindow = '85%';
  heightWindow = '620px';
  title;
  isOrderItems;

  isVisible;
  productId;
  customerId;
  readOnly;
  PriceName;
  DocumentDate;
  DocumentCurrencyCode;
  CurrencyFactor;

  IsShowLastSellPriceInput: string;
  ScipCorrectionInput: string;
  SetAtDefaultInput: string;

  filterCriteria = [
    { value: 'NetPrice', label: '' },
    { value: 'Description', label: '' },
    { value: 'MarkUp', label: '' },
    { value: 'Margin', label: '' },
  ];

  valueCriteria = 'NetPrice';
  filterValue = '';

  filterCriteriaStatus = [
    { value: true, label: 'Tak' },
    { value: false, label: 'Nie' },
  ];

  heightGrid = 410;
  shortcuts: ShortcutInput[] = [];
  selectedRow;
  focusedSelected = [];

  orderby: string = 'NetPrice';
  order: string = 'ASC'; // asc/desc

  checkBoxState = {
    IsShowLastSellPrice: null,
    IsSkipCorrection: null,
    IsSetAtDefault: null,
  };

  items = ['Ceny sprzedaży'];
  itemsOrder = [
    'Ceny sprzedaży',
    'Najniższe ceny zakupu innych kontrahentów i grup cenowych kontrahentów',
  ];

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('warehouseDocument.byNetPrice')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('warehouseDocument.byDescription')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('warehouseDocument.byDiscount')
      .subscribe((text) => (this.filterCriteria[2].label = text));
    this.translate
      .get('warehouseDocument.byMargin')
      .subscribe((text) => (this.filterCriteria[3].label = text));

    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.translate
      .get('articles.recalculatesItems.prices')
      .subscribe((text) => (this.itemsOrder[0] = text));
    this.translate
      .get('warehouseDocument.theLowestPurchase')
      .subscribe((text) => (this.itemsOrder[1] = text));

    this.translate
      .get('articles.recalculatesItems.prices')
      .subscribe((text) => (this.items[0] = text));
  }

  ngOnInit(): void {
    if (localStorage.getItem('appArticlePricePositionDocument')) {
      this.checkBoxState = JSON.parse(
        localStorage.getItem('appArticlePricePositionDocument')
      );
    }

    if (!this.CurrencyFactor) this.CurrencyFactor = 1;
    if (!this.DocumentCurrencyCode) this.DocumentCurrencyCode = 'PLN';

    this.getHiddenParameters();
  }

  ngOnChanges() {
    if (this.productId && this.isVisible) {
      let ProductId = this.returnProductId(this.productId);
      this.getPrice(ProductId);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'escape',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  onValueChangedState = (e) => {
    this.checkBoxState.IsShowLastSellPrice = e;
    const saveValue = e ? 'Tak' : 'Nie';
    if (!e) this.IsShowLastSellPriceInput = undefined;
    this.event.saveHiddenParameter({
      code: 'PokazOstCenaDlaKontr',
      value: saveValue,
    });
    setTimeout(() => {
      if (e == '') {
        this.checkBoxState.IsShowLastSellPrice = null;
        this.IsShowLastSellPriceInput = undefined;
        this.event.saveHiddenParameter({
          code: 'PokazOstCenaDlaKontr',
          value: 'Nie',
        });
      }
    }, 0);

    localStorage.setItem(
      'appArticlePricePositionDocument',
      JSON.stringify(this.checkBoxState)
    );
    let ProductId = this.returnProductId(this.productId);
    this.getPrice(ProductId);
  };

  onValueChangedScipCorrection = (e) => {
    this.checkBoxState.IsSkipCorrection = e;
    const saveValue = e ? 'Nie' : 'Tak';
    this.event.saveHiddenParameter({
      code: 'KorekOstCenaDlaKontr',
      value: saveValue,
    });
    if (!e) this.ScipCorrectionInput = undefined;
    setTimeout(() => {
      if (e == '') {
        this.checkBoxState.IsSkipCorrection = null;
        this.ScipCorrectionInput = undefined;
        this.event.saveHiddenParameter({
          code: 'KorekOstCenaDlaKontr',
          value: 'Tak',
        });
      }
    }, 0);

    localStorage.setItem(
      'appArticlePricePositionDocument',
      JSON.stringify(this.checkBoxState)
    );
    let ProductId = this.returnProductId(this.productId);
    this.getPrice(ProductId);
  };

  onValueChangedSetAtDefault = (e) => {
    this.checkBoxState.IsSetAtDefault = e;
    const saveValue = e ? 'Tak' : 'Nie';
    this.event.saveHiddenParameter({
      code: 'UstawSieNaDomCena',
      value: saveValue,
    });
    if (!e) this.SetAtDefaultInput = undefined;
    setTimeout(() => {
      if (e == '') {
        this.checkBoxState.IsSetAtDefault = null;
        this.SetAtDefaultInput = undefined;
        this.event.saveHiddenParameter({
          code: 'UstawSieNaDomCena',
          value: 'Nie',
        });
      }
    }, 0);

    localStorage.setItem(
      'appArticlePricePositionDocument',
      JSON.stringify(this.checkBoxState)
    );
    let ProductId = this.returnProductId(this.productId);
    this.getPrice(ProductId);
  };

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.orderby = e.selectedItem.value;
      this.valueCriteria = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;
    let ProductId = this.returnProductId(this.productId);
    this.getPrice(ProductId);
  }

  returnProductId = (ProductId) => {
    if (typeof ProductId == 'number') return ProductId;
    if (typeof ProductId == 'object' && ProductId.length == 1)
      return ProductId[0].ProductId;
    return ProductId.ProductId;
  };

  visibleChange = (e) => {
    if (e) {
      let ProductId = this.returnProductId(this.productId);
      this.getPrice(ProductId);
    }
    if (!e) this.onClosing.emit(false);
    this.cd.detectChanges();
  };

  showPricePopUp = () => {
    this.isVisible = true;
  };

  dataSource;
  getPrice = (ProductId) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}products/${ProductId}/productPricesForDocumentPosition/${this.customerId}?ProductId=${ProductId}`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  selectedIndexChange = (e) => {
    let ProductId = this.returnProductId(this.productId);
    if (e == 1) this.getPriceForOtherCustomer(ProductId);
  };

  dataSourceOther;
  getPriceForOtherCustomer = (ProductId) => {
    this.dataSourceOther = new DataSource({
      store: AspNetData.createStore({
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}products/${ProductId}/productPricesForDocumentPosition?ProductId=${ProductId}&DocumentDate=${this.DocumentDate}&DocumentCurrencyCode=${this.DocumentCurrencyCode}&CurrencyFactor=${this.CurrencyFactor}`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  getLoadParams = () => {
    let obj: any = {
      CustomerId: this.customerId,
      DocumentDate: this.event.dataFormatted({ value: this.DocumentDate }),
      DocumentCurrencyCode: this.DocumentCurrencyCode,
      CurrencyFactor: this.CurrencyFactor,
    };

    (obj.OrderBy = this.orderby), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.Order = this.order);

    if (this.checkBoxState.IsShowLastSellPrice) {
      obj.IsShowLastSellPrices = this.checkBoxState.IsShowLastSellPrice;
    }

    if (this.checkBoxState.IsSkipCorrection) {
      obj.IsSkipCorrection = this.checkBoxState.IsSkipCorrection;
    }

    if (this.checkBoxState.IsSetAtDefault) {
      obj.IsSetAtDefault = this.checkBoxState.IsSetAtDefault;
    }

    switch (this.valueCriteria) {
      case 'NetPrice':
        obj.NetPrice = this.filterValue.replace(',', '.');
        break;
      case 'Description':
        obj.Description = this.filterValue;
        break;
      case 'Margin':
        obj.margin = this.filterValue.replace(',', '.');
        break;
      case 'MarkUp':
        obj.MarkUp = this.filterValue.replace(',', '.');
        break;
    }
    return obj;
  };

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      let ProductId = this.returnProductId(this.productId);
      this.getPrice(ProductId);
      return;
    }
    this.order = 'ASC';
    let ProductId = this.returnProductId(this.productId);
    this.getPrice(ProductId);
  }

  choosedPrice = () => {
    this.isVisible = false;
    this.onChoosed.emit(this.focusedSelected);
  };

  onSelectionChanged = () => {};

  onRowDblClick = () => {
    this.choosedPrice();
  };

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  async getHiddenParameters() {
    const PokazOstCenaDlaKontr = await this.event.getHiddenParameter(
      'PokazOstCenaDlaKontr'
    );
    if (PokazOstCenaDlaKontr) {
      this.checkBoxState.IsShowLastSellPrice =
        PokazOstCenaDlaKontr.value === 'Tak';
      this.IsShowLastSellPriceInput = this.filterCriteriaStatus[0].label;
    }

    const KorekOstCenaDlaKontr = await this.event.getHiddenParameter(
      'KorekOstCenaDlaKontr'
    );
    if (KorekOstCenaDlaKontr) {
      this.checkBoxState.IsSkipCorrection =
        KorekOstCenaDlaKontr.value !== 'Tak';
      this.ScipCorrectionInput = this.filterCriteriaStatus[0].label;
    }

    const UstawSieNaDomCena = await this.event.getHiddenParameter(
      'UstawSieNaDomCena'
    );
    if (UstawSieNaDomCena) {
      this.checkBoxState.IsSetAtDefault = UstawSieNaDomCena.value === 'Tak';
      this.SetAtDefaultInput = this.filterCriteriaStatus[0].label;
    }
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.valueCriteria) {
      this.valueCriteria = orderBy;
    } else {
      this.switchOrder();
    }
    let ProductId = this.returnProductId(this.productId);
    this.getPrice(ProductId);
    this.cd.detectChanges();
  };
}
