import {
    //HttpClient,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Observable } from 'rxjs';
  
  @Injectable({
    providedIn: 'root',
  })
  export class FkServices implements HttpInterceptor {
    constructor(
     // private http: HttpClient
    ) {
    }

   
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      return next.handle(req);
    }

    mockVehiclesHistory = [
      {
        Datazmiany: '07-05-2024',
        SposobuzytkowaniaId: 1,
        Sposobuzytkowania: 'Osobowy prywatny',
        OdliczVAT: '50%',
        Leasing: false,
        WLeasing: 0,
        WUbez: 0,
        V26Nab: '2024-01-01',
        V26Wyda: '2024-01-01',
        V26Zm: '2024-01-01',
      },
      {
        Datazmiany: '23-01-2023',
        SposobuzytkowaniaId: 3,
        Sposobuzytkowania: 'Osobowy do DG',
        OdliczVAT: '100%',
        Leasing: true,
        WLeasing: 1200,
        WUbez: 0,
        V26Nab: '2024-01-01',
        V26Wyda: '2024-01-01',
        V26Zm: '2024-01-01',
      },
    ];

    mockVehicles = [
      {
        Numerrejestracyjny: 'WP0261J',
        Marka: 'Seat',
        Model: 'Leon',
        Rokprodukcji: 2009,
        SposobuzytkowaniaId: 1,
        Sposobuzytkowania: 'Osobowy prywatny',
        OdliczVAT: '50%',
        Leasing: false,
        WLeasing: 0,
        WUbez: 0,
        V26Nab: '2024-01-01',
        V26Wyda: '2024-01-01',
        V26Zm: '2024-01-01',
        Uwagi: ''
      },
      {
        Numerrejestracyjny: 'WP12543',
        Marka: 'Ford',
        Model: 'Mustang',
        Rokprodukcji: 2020,
        SposobuzytkowaniaId: 2,
        Sposobuzytkowania: 'Osobowy do DG + inne cele',
        OdliczVAT: '50%',
        Leasing: true,
        WLeasing: 99.99,
        WUbez: 0,
        V26Nab: '2024-01-01',
        V26Wyda: '2024-01-01',
        V26Zm: '2024-01-01',
        Uwagi: ''
      },
      {
        Numerrejestracyjny: 'WP5423S',
        Marka: 'Mitsubishi',
        Model: 'Lancer',
        Rokprodukcji: 2021,
        SposobuzytkowaniaId: 3,
        Sposobuzytkowania: 'Osobowy do DG',
        OdliczVAT: '100%',
        Leasing: true,
        WLeasing: 324,
        WUbez: 0,
        V26Nab: '2024-01-01',
        V26Wyda: '2024-01-01',
        V26Zm: '2024-01-01',
        Uwagi: ''
      },
      {
        Numerrejestracyjny: 'WP2315F',
        Marka: 'Lamborghini',
        Model: 'Revuelto',
        Rokprodukcji: 2024,
        SposobuzytkowaniaId: 4,
        Sposobuzytkowania: 'Inny do DG',
        OdliczVAT: '100%',
        Leasing: false,
        WLeasing: 0,
        WUbez: 0,
        V26Nab: '2024-01-01',
        V26Wyda: '2024-01-01',
        V26Zm: '2024-01-01',
        Uwagi: ''
      }
    ];

    mockVehiclesTypeTree = [
      {
        WayToUseName: 'Wszystkie', WayToUseId: 1, 
        Children: [ 
          { WayToUseName: 'osobowy wyłącznie do działalności gospodarczej', WayToUseId: 2, ParentId: 1 }, 
          { WayToUseName: 'osobowy do działalności gospodarczej i prywatnej', WayToUseId: 3, ParentId: 1 }, 
        ]
      }
    ];

    mockVATList = [
      {
        key: 0,
        name: '0%',
      },
      {
        key: 5,
        name: '5%',
      },
      {
        key: 8,
        name: '8%',
      },
      {
        key: 23,
        name: '23%',
      },
  ];

  mockWalutyList = [
    {
      key: 'PLN',
      name: 'PLN',
    },
    {
      key: 'EUR',
      name: 'EUR',
    },
    {
      key: 'USD',
      name: 'USD',
    },
];

mockGrupyVAT = [
  {
    key: 'NTU',
    name: 'Nabycie towarów i usług',
  },
  {
    key: 'NST',
    name: 'Nabycie środków trwałych',
  },
  {
    key: 'NM',
    name: 'Nabycie mięsa',
  },
];

mockPrzedOpod = [
  {
    key: 'NK',
    name: 'Uwaga nieuczciwy kontrahent',
  },
  {
    key: 'UK',
    name: 'Bardzo uczciwy kontrahent',
  },
];

mockPodgrListVAT = [
  {
    key: 'SU',
    name: 'Świadczenie usługo o których mowa',
  },
  {
    key: 'SC',
    name: 'Sprzedaż czegoś',
  },
];

mockNazwaUslugiList = [
  {
    key: 'UZ',
    name: 'Usługa zbiorcza',
  },
];

mockKlasyfikacjaJPKList = [
  {
    key: '1',
    name: 'Klasyfikacja 1',
  },
  {
    key: '2',
    name: 'Klasyfikacja 2',
  },
];

mockArt89List = [
  {
    key: '1',
    name: 'Nie dotyczy korekty VAT',
  },
  {
    key: '2',
    name: 'Dotyczy korekty VAT',
  },
];

mockItemGroup = [ 
  { id: '1', label: 'Dostawy towarów i usług (w kraju)' },
  { id: '2', label: 'Jakieś inne' }
];

mockTaxSubject = [ 
  { id: '1', label: 'Maćkowy Sad' },
  { id: '2', label: 'AbraCadabra' }
];

mockSubjectToVat = [
  { id:'1', label: 'Coś podlega VAT' },
]

mockDocVatSaleList = [
  {
    Id: 1,
    StawkaVAT: '23',
    Waluta: 'PLN',
    Kwotanetto: 25,
    KwotaVAT: 5,
    Kwotabrutto: 30,
    Dataobow: '2024-03-12',
    GrupaVAT: 'NTU',
    Przedmiotopodatkowania: '1',
    PodlegajaceVAT: '1',
    Art1093a: true,
    Nazwauslugi: 'Jakaś nazwa usługi'
  }
];

  mockDocVatList = [
      {
        id: 1,
        stawkaVAT: 23,
        kwNetto: 300,
        kwNettoWaluta: 'PLN',
        kwVAT: 69,
        kwBrutto: 369,
        dataObowPod: '2024-03-12',
        kontrahentNazwa: 'FunLogic',
        kontrahentNIP: '7741232211',
        grVAT: 'NTU',
        prOpod: null,
        podgrVAT: 'SU',
        art109: true,
        nazwaUslugi: null,
        paragon: false,
        fakParagon: true,
        klasyfikacjaJPK: null,
        art89: '1',
        terminPlatnosci: '2024-05-12',
        dataRozliczenia: '2024-05-18'
      },
      {
        id: 2,
        stawkaVAT: 5,
        kwNetto: 120,
        kwNettoWaluta: 'EUR',
        kwVAT: 6,
        kwBrutto: 126,
        dataObowPod: '2024-06-07',
        kontrahentNazwa: 'Maćkowy Sad',
        kontrahentNIP: '2232123422',
        grVAT: 'NM',
        prOpod: 'NK',
        podgrVAT: 'SC',
        art109: false,
        nazwaUslugi: 'UZ',
        paragon: true,
        fakParagon: true,
        klasyfikacjaJPK: '1',
        art89: '2',
        terminPlatnosci: null,
        dataRozliczenia: null
      },
      {
        id: 3,
        stawkaVAT: 8,
        kwNetto: 3400,
        kwNettoWaluta: 'USD',
        kwVAT: 272,
        kwBrutto: 3672,
        dataObowPod: '2024-07-12',
        kontrahentNazwa: 'Maćkowy Sad',
        kontrahentNIP: '2232123422',
        grVAT: 'NM',
        prOpod: null,
        podgrVAT: null,
        art109: false,
        nazwaUslugi: 'UZ',
        paragon: true,
        fakParagon: true,
        klasyfikacjaJPK: '1',
        art89: null,
        terminPlatnosci: null,
        dataRozliczenia: null
      },
  ];

  mockDocKsiegowe = [
    {
      brulion: false,
      rodz: 'P',
      numer: 'FZ 003/24',
      datZap: '2024.01.01',
      zg: 'Am',
      opis: 'Amortyzacja',
      kontrahent: 'Maćkowy Sad',
      wartosc: 3040.25,
      wartoscWal: null,
      waluta: null,
      numerZap: '00026/PZ/24',
      kor: true,
      vat: false
    },
    {
      brulion: true,
      rodz: 'R',
      numer: 'SD 033/24',
      datZap: '2024.05.01',
      zg: 'FS',
      opis: 'Faktura sprzedaży',
      kontrahent: 'AbraCadabra sp. z o.o.',
      wartosc: 145.00,
      wartoscWal: null,
      waluta: null,
      numerZap: '00126/KW/24',
      kor: false,
      vat: true
    },
    {
      brulion: false,
      rodz: 'P',
      numer: 'FZ 003/24',
      datZap: '2024.01.01',
      zg: 'Am',
      opis: 'Amortyzacja',
      kontrahent: 'Maćkowy Sad',
      wartosc: 3040.25,
      wartoscWal: null,
      waluta: null,
      numerZap: '00026/PZ/24',
      kor: true,
      vat: true
    }
  ];

  mockDocumentTypes = [
    {
      DocumentTypeId: 1,
      DocumentTypeParentId: null,
      TypeBackgroundColor: null,
      TypeCode: null,
      TypeName: 'Wszystko',
      TypeStringColor: null,
      TypeId: null
    },
    {
      DocumentTypeId: 2,
      DocumentTypeParentId: 1,
      TypeBackgroundColor: null,
      TypeCode: null,
      TypeName: 'Przychody',
      TypeStringColor: null,
      TypeId: 12
    },
    {
      DocumentTypeId: 3,
      DocumentTypeParentId: 1,
      TypeBackgroundColor: null,
      TypeCode: null,
      TypeName: 'Rozchody',
      TypeStringColor: null,
      TypeId: 12
    },
    {
      DocumentTypeId: 20001,
      DocumentTypeParentId: 2,
      TypeBackgroundColor: 'C8E6C9',
      TypeCode: 'FS',
      TypeName: 'Faktura sprzedaży',
      TypeStringColor: '2C5F30',
      TypeId: 12
    },
    {
      DocumentTypeId: 20002,
      DocumentTypeParentId: 2,
      TypeBackgroundColor: 'C8E6C9',
      TypeCode: 'ZS',
      TypeName: 'Zaliczka sprzedaży',
      TypeStringColor: '2C5F30',
      TypeId: 12
    },
    {
      DocumentTypeId: 20003,
      DocumentTypeParentId: 3,
      TypeBackgroundColor: 'FFCDD2',
      TypeCode: 'FZ',
      TypeName: 'Faktura zakupu',
      TypeStringColor: '741111',
      TypeId: 12
    },
    {
      DocumentTypeId: 20004,
      DocumentTypeParentId: 3,
      TypeBackgroundColor: 'FFCDD2',
      TypeCode: 'KE',
      TypeName: 'Koszt eksploatacji pojazdu',
      TypeStringColor: '741111',
      TypeId: 12
    },
    {
      DocumentTypeId: 20005,
      DocumentTypeParentId: 3,
      TypeBackgroundColor: 'FFCDD2',
      TypeCode: 'VM',
      TypeName: 'VAT marża',
      TypeStringColor: '741111',
      TypeId: 12
    },
    {
      DocumentTypeId: 20006,
      DocumentTypeParentId: 3,
      TypeBackgroundColor: 'FFCDD2',
      TypeCode: 'DT',
      TypeName: 'WDT',
      TypeStringColor: '741111',
      TypeId: 12
    },
    {
      DocumentTypeId: 20007,
      DocumentTypeParentId: 3,
      TypeBackgroundColor: 'FFCDD2',
      TypeCode: 'NT',
      TypeName: 'WNT',
      TypeStringColor: '741111',
      TypeId: 12
    },
  ];

mockDanePodstawoweVAT = 
[
  {
    Id: 1,
    StawkaVAT: 23,
    Waluta: "PLN",
    Kwotanetto: 23.21,
    KwotaVAT: 5.32,
    Kwotabrutto: 28.52,
    Dataobow: "12-12-2024",
    GrupaVAT: "Nabycie towarów i usług",
    Podmiotopodatkowania: "Mostostal Płock",
    LiczbaPojazdow: 3,
    Odliczenie: "Całkowite",
    Dataodliczenia: "12-06-2024",
    PodgrupaVAT: ""
  },
  {
    Id: 2,
    StawkaVAT: 23,
    Waluta: "PLN",
    Kwotanetto: 23.21,
    KwotaVAT: 5.32,
    Kwotabrutto: 28.52,
    Dataobow: "12-12-2024",
    GrupaVAT: "Nabycie towarów i usług",
    Podmiotopodatkowania: "Mostostal Płock",
    LiczbaPojazdow: 0,
    Odliczenie: "Całkowite",
    Dataodliczenia: "12-06-2024",
    PodgrupaVAT: ""
  },
  {
    Id: 3,
    StawkaVAT: 23,
    Waluta: "PLN",
    Kwotanetto: 23.21,
    KwotaVAT: 5.32,
    Kwotabrutto: 28.52,
    Dataobow: "12-12-2024",
    GrupaVAT: "Nabycie towarów i usług",
    Podmiotopodatkowania: "Mostostal Płock",
    LiczbaPojazdow: 1,
    Odliczenie: "Całkowite",
    Dataodliczenia: "12-06-2024",
    PodgrupaVAT: ""
  },
];

mockDanePodstawoweDOC = 
[
  {
    Kolumna: 12,
    Opis: 'Pozostałe koszty',
    KwotaNetto: 1264.45
  },
  {
    Kolumna: 15,
    Opis: 'Obce koszty',
    KwotaNetto: 1264.45
  },
];

mockPojazdyDodane = 
[
  {
    Numerrejestracyjny: 'WP0261J',
    Marka: 'Seat',
    Model: 'Leon',
    SposobuzytkowaniaId: 1,
    Sposobuzytkowania: 'Osobowy prywatny',
    KwotaBrutto: 400,
    Udzial: 25
  },
  {
    Numerrejestracyjny: 'WP12543',
    Marka: 'Ford',
    Model: 'Mustang',
    SposobuzytkowaniaId: 2,
    Sposobuzytkowania: 'Osobowy do DG + inne cele',
    KwotaBrutto: 400,
    Udzial: 25
  },
  {
    Numerrejestracyjny: 'WP5423S',
    Marka: 'Mitsubishi',
    Model: 'Lancer',
    SposobuzytkowaniaId: 3,
    Sposobuzytkowania: 'Osobowy do DG',
    KwotaBrutto: 400,
    Udzial: 25
  },
  {
    Numerrejestracyjny: 'WP2315F',
    Marka: 'Lamborghini',
    Model: 'Revuelto',
    SposobuzytkowaniaId: 4,
    Sposobuzytkowania: 'Inny do DG',
    KwotaBrutto: 400,
    Udzial: 25
  }
];

mockOdliczeniaRozliczenie = 
[
  {
    Numerrejestracyjny: 'WP0261J',
    Marka: 'Seat',
    Model: 'Leon',
    Rokprodukcji: 2009,
    SposobuzytkowaniaId: 1,
    Sposobuzytkowania: 'Osobowy prywatny',
    OdliczVAT: '50%',
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    Netto: 400
  },
  {
    Numerrejestracyjny: 'WP12543',
    Marka: 'Ford',
    Model: 'Mustang',
    Rokprodukcji: 2020,
    SposobuzytkowaniaId: 2,
    Sposobuzytkowania: 'Osobowy do DG + inne cele',
    OdliczVAT: '50%',
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    Netto: 400
  },
  {
    Numerrejestracyjny: 'WP5423S',
    Marka: 'Mitsubishi',
    Model: 'Lancer',
    Rokprodukcji: 2021,
    SposobuzytkowaniaId: 3,
    Sposobuzytkowania: 'Osobowy do DG',
    OdliczVAT: '100%',
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    Netto: 400
  },
  {
    Numerrejestracyjny: 'WP2315F',
    Marka: 'Lamborghini',
    Model: 'Revuelto',
    Rokprodukcji: 2024,
    SposobuzytkowaniaId: 4,
    Sposobuzytkowania: 'Inny do DG',
    OdliczVAT: '100%',
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    Netto: 400
  }
];

mockOdliczeniaUse = [
  {
    Id: 1,
    SposobuzytkowaniaId: 1,
    Sposobuzytkowania: 'Osobowy prywatny',
    OdliczVAT: '50%',
    Iloscpojazdow: 1,
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    NettowKUP: 186.99,
    NettownKUP: 186.99
  },
  {
    Id: 2,
    SposobuzytkowaniaId: 2,
    Sposobuzytkowania: 'Osobowy do DG + inne cele',
    OdliczVAT: '50%',
    Iloscpojazdow: 1,
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    NettowKUP: 186.99,
    NettownKUP: 186.99
  },
  {
    Id: 3,
    SposobuzytkowaniaId: 3,
    Sposobuzytkowania: 'Osobowy do DG',
    OdliczVAT: '100%',
    Iloscpojazdow: 1,
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    NettowKUP: 186.99,
    NettownKUP: 186.99
  },
  {
    Id: 4,
    SposobuzytkowaniaId: 4,
    Sposobuzytkowania: 'Inny do DG',
    OdliczVAT: '100%',
    Iloscpojazdow: 1,
    Kwotanetto: 813,
    VAT: 186.99,
    Kwotabrutto: 1000,
    KUP: 588.60,
    nKUP: 213.41,
    VATpodlodl: 186.99,
    VATniepodlodl: 186.99,
    VATniepodlodlwKUP: 186.99,
    VATniepodlodlwnKUP: 186.99,
    NettowKUP: 186.99,
    NettownKUP: 186.99
  }
];

mockListVat = [
  {
    Pozycje: '',
    OdliczVat: '',
    Kwotanetto: 1.2,
    KwotaVAT: 2,
    Kwotabrutto: 2,
    Kup: 2,
    NKup: 2,
    VatPodlOdl: 2,
    VatNiepodlOdl: 2,
    VatNiepodlOdlWKup: 2,
    NettoWKup: 2
  }
];

    
}
  