import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { TaskSubject } from './task-subject.model';
import { Observable, catchError, of } from 'rxjs';
import {
  FocusedRowChangedEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid';
import { ICustomDropDownBoxValueChanged } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';

@Component({
    selector: 'app-task-subject-genius',
    templateUrl: './task-subject-genius.component.html',
    styleUrls: ['./task-subject-genius.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskSubjectGeniusComponent {
  @Input() subject: string = null;
  @Input() readOnly = false;
  @Output() onClosing: EventEmitter<void> = new EventEmitter();
  @Output() onChoosed: EventEmitter<string> = new EventEmitter();

  visible = true;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  geniusTasks$: Observable<TaskSubject[]> = null;
  focusedRow: TaskSubject = null;
  focusedRowIndex = -1;
  constructor(public event: EventService, private appService: AppServices) {}

  ngOnInit() {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.getGeniusTasks();
  }

  getGeniusTasks() {
    this.geniusTasks$ = this.appService
      .getAuth(`crm/geniusHistoryTasks?subject=${this.subject}`)
      .pipe(
        catchError((error) => {
          this.event.httpErrorNotification(error);
          return of([]);
        })
      );
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.onSave();
          }
        },
        preventDefault: true,
      }
    );
  }

  visibleChanged() {
    if (!this.visible) {
      this.closeWindow();
    }
  }

  closeWindow() {
    this.onClosing.emit();
  }

  onSave() {
    if (this.focusedRow) {
      this.onChoosed.emit(this.focusedRow.Subject);
    }
  }

  onSearchChanged(e: ICustomDropDownBoxValueChanged) {
    this.subject = (e?.filterValue as string) || '';
    this.getGeniusTasks();
  }

  onRowDblClick(e: RowDblClickEvent) {
    if (!e.data) {
      this.focusedRow = null;
      return;
    }
    this.onChoosed.emit(e.data.Subject);
  }

  onFocusedRowChanged(e: FocusedRowChangedEvent) {
    if (!e?.row?.data) {
      this.focusedRow = null;
      return;
    }
    this.focusedRow = e.row.data;
  }
}
