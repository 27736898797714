<div *ngIf="!dropDownBoxMode && !selectBoxMode">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </div>
</div>

<dx-drop-down-box
  [(value)]="choosingDocument"
  valueExpr="ServiceOrderTypeId"
  [deferRendering]="false"
  displayExpr="Name"
  [(opened)]="isGridBoxOpened"
  [dataSource]="documentList"
  *ngIf="dropDownBoxMode"
  [showClearButton]="true"
  [width]="width ? width : 276"
  (onValueChanged)="onValueChanged($event)"
  (openedChange)="onOpenedChanged($event)"
  (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
  [ngClass]="{ 'input-required': className }"
  [readOnly]="readOnly"
>
  <div
    *dxTemplate="let data of 'content'"
    style="height: 280px; min-height: 280px"
  >
    <div class="row">
      <div class="col-md-12" class="no-padding" *ngIf="isGridBoxOpened">
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="true"
          [showBorders]="true"
          shortcut
          [height]="258"
          [(selectedRowKeys)]="selectedRow"
          [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
          [columnResizingMode]="'widget'"
          (onRowClick)="onRowDblClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onSelectionChanged)="onSelectionChanged($event)"
          [focusedRowEnabled]="true"
          #gridClass
          [hoverStateEnabled]="true"
          (onKeyDown)="onKeyDown($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
          <dxo-selection
            [mode]="dropDownBoxMode ? 'single' : 'multiple'"
            showCheckBoxesMode="always"
          >
          </dxo-selection>
          <dxi-column
            [width]="event.deviceType != 'mobile' ? 45 : 10"
            cellTemplate="editTemplate"
            [allowSorting]="false"
            alignment="center"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
            <dx-button
              class="btn-dx-top-menu-folder-grid"
              icon="icon absui-icon--mode-edit hover-edit-icon"
              [disabled]="readOnly || !this.perABD.editBtn"
              (onClick)="editRow = true"
            >
            </dx-button>
            <!-- <i class="icon absui-icon--mode-edit hover-edit-icon"
                            (click)="onEditClick(data.data.CustomerId)"></i> -->
          </div>
          <dxi-column
            caption="D"
            width="40"
            dataField="IsDefault"
            [allowSorting]="false"
            cellTemplate="IsDefault"
          >
          </dxi-column>
          <dxi-column
            caption="{{ 'companies.grid.columns.name' | translate }}"
            [width]="widthCityColumn"
            dataField="Name"
            [allowSorting]="false"
          ></dxi-column>

          <div *dxTemplate="let data of 'IsDefault'">
            <i
              class="icon absui-icon--check check-grid"
              *ngIf="data.data.IsDefault"
            ></i>
            <i
              class="icon absui-icon--close close-grid"
              *ngIf="!data.data.IsDefault"
            ></i>
          </div>
        </dx-data-grid>
        <dx-context-menu
          [dataSource]="contextMenu"
          [width]="200"
          [target]="'#grid' + unicalGuid"
          (onItemClick)="contextMenuClick($event)"
          (onItemRendered)="event.onItemRendered($event)"
        ></dx-context-menu>
      </div>
    </div>

    <div class="bottom-drop-box">
      <dx-button
        [ngClass]="{
          'btn-dx-top-menu': !dropDownBoxMode,
          'btn-dx-top-menu-folder': dropDownBoxMode
        }"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        [id]="'btn-drop-add' + unicalGuid"
        [disabled]="readOnly || !this.perABD.addBtn"
        (onClick)="addRow = true"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (onClick)="refreshData()"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-drop-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }}
        </div>
      </dx-tooltip>
      <!-- <ng-container *ngTemplateOutlet="topButtons"></ng-container> -->
    </div>
  </div>
</dx-drop-down-box>

<dx-select-box
  [dataSource]="dataSource"
  displayExpr="Name"
  [width]="width ? width : 276"
  valueChangeEvent="input"
  valueExpr="ServiceOrderTypeId"
  [readOnly]="readOnly"
  [(value)]="selectedId"
  *ngIf="selectBoxMode"
>
</dx-select-box>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <dx-button
      data-cy="order-type_add-order-button"
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="addRow = true; mode = 'add'"
    >
    </dx-button>
    <dx-button
      data-cy="order-type_edit-order-button"
      class="btn-dx-top-menu-folder"
      [id]="'btn-edit' + unicalGuid"
      icon="icon absui-icon--mode-edit"
      [text]="'buttons.edit' | translate"
      [disabled]="selectedRow.length == 0 || readOnly || !perABD.editBtn"
      (onClick)="addRow = true; mode = 'edit'"
    >
    </dx-button>
    <dx-button
      data-cy="order-type_delete-order-button"
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--highlight-off"
      [text]="'buttons.delete' | translate"
      [id]="'btn-delete' + unicalGuid"
      [disabled]="selectedRow.length == 0 || readOnly || !perABD.deleteBtn"
      (onClick)="isDelete()"
    >
    </dx-button>
    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--tool-select-rectangle"
      [text]="'buttons.choose' | translate"
      [disabled]="selectedRow.length == 0 || readOnly"
      [id]="'btn-choose' + unicalGuid"
      (onClick)="onChoosingRow()"
      *ngIf="dropDownBoxMode"
    >
    </dx-button>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (onClick)="refreshData()"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-choose' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.choose" | translate }} (Enter)
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <dx-data-grid
        id="gridContainer"
        #gridClass
        [dataSource]="dataSource"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        shortcut
        data-cy="order-type_grid"
        [height]="heightGrid"
        [(selectedRowKeys)]="selectedRow"
        (onSelectionChanged)="onSelectionChanged($event)"
        [(focusedRowIndex)]="focusedRowIndex"
        [focusedRowEnabled]="true"
        (onRowDblClick)="onRowDblClick()"
        [id]="'grid' + unicalGuid"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        (onKeyDown)="onKeyDown($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
        <dxi-column
          [caption]="'constractors.default' | translate"
          width="65"
          dataField="IsDefault"
          [allowSorting]="false"
          cellTemplate="IsDefault"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'companies.grid.columns.name' | translate }}"
          [width]="widthCityColumn"
          dataField="Name"
          [allowSorting]="false"
          cellTemplate="mainColumn"
        ></dxi-column>

        <div *dxTemplate="let data of 'mainColumn'">
          <span class="hov-underline" (click)="editRecord()">{{
            data.value
          }}</span>
        </div>

        <div *dxTemplate="let data of 'IsDefault'">
          <i
            class="icon absui-icon--status-ok-green"
            *ngIf="data.data.IsDefault"
          ></i>
        </div>
      </dx-data-grid>

      <dx-context-menu
        [dataSource]="contextMenu"
        [width]="200"
        [target]="'#grid' + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
        <div *dxTemplate="let e of 'item'">
          <div>
            <span *ngIf="e.items" class="dx-icon-spinright"></span> {{ e.text }}
          </div>
        </div>
      </dx-context-menu>
    </div>
  </div>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    (!isGridBoxOpened && dropDownBoxMode) || addRow || editRow || isDeleteRow
  "
>
</wapro-keyboard-shortcuts>

<app-add-order-type
  [isVisible]="addRow"
  [selectedId]="selectedRow"
  [mode]="mode"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted()"
  title="{{ 'servicesDevices.addTypesOrder' | translate }}"
>
</app-add-order-type>

<!--<app-add-order-type-->
<!--  [isVisible]="editRow"-->
<!--  [selectedId]="selectedRow"-->
<!--  (onClosing)="onClosingForm()"-->
<!--  (onInserted)="onInserted()"-->
<!--  title="{{ 'servicesDevices.editTypesOrder' | translate }}"-->
<!--&gt;-->
<!--</app-add-order-type>-->

<app-add-order-type
  [isVisible]="showRow"
  [readOnly]="true"
  [selectedId]="selectedRow"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted()"
  title="{{ 'servicesDevices.editTypesOrder' | translate }}"
>
</app-add-order-type>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="addRow = true"
    >
    </dx-button>
  </div>
</ng-template>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <dx-data-grid
    id="gridContainer"
    #mobileGridClass
    [dataSource]="dataSource"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="heightGrid - 60"
    [(selectedRowKeys)]="selectedRow"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    (onRowDblClick)="onRowDblClick()"
    [id]="'grid' + unicalGuid"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    (onContentReady)="onMobileGridContentReady($event)"
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="200"></dxo-paging>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

    <dxi-column
      caption="{{ 'companies.grid.columns.name' | translate }}"
      dataField="Name"
      [allowSorting]="false"
      cellTemplate="mainColumn"
      [allowResizing]="false"
      [width]="mobileColWidth.name"
    ></dxi-column>
    <dxi-column
      [caption]="'constractors.default' | translate"
      [width]="mobileColWidth.def"
      dataField="IsDefault"
      [allowSorting]="false"
      cellTemplate="IsDefault"
      [allowResizing]="false"
    >
    </dxi-column>
    <dxi-column
      [caption]=""
      [width]="mobileColWidth.dots"
      [allowSorting]="false"
      cellTemplate="dots"
      [allowResizing]="false"
      alignment="left"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'mainColumn'">
      <div class="pl-1">
        <span class="hov-underline" (click)="editRecord()">{{
          data.value
        }}</span>
      </div>
    </div>

    <div *dxTemplate="let data of 'IsDefault'">
      <i
        class="icon absui-icon--status-ok-green"
        *ngIf="data.data.IsDefault"
      ></i>
    </div>

    <div *dxTemplate="let data of 'dots'">
      <dx-button
        type="normal"
        stylingMode="text"
        icon="icon dx-icon-more-horizontal"
        (click)="showContextMenu($event)"
      ></dx-button>
    </div>

    <!-- <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'mainColumn'">
      <div style="display: flex">
        <div class="content-main-grid">
          <span
            class="hov-underline-mobile"
            (click)="editRecord($event, data.data)"
            >{{ data.data.Name }}</span
          ><br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="isContextMenuMobile = true"
          ></dx-button>
        </div>
      </div>
    </div> -->
  </dx-data-grid>
  <dx-context-menu
    [dataSource]="contextMenu"
    [width]="200"
    [target]="contextMenuTarget"
    (onItemClick)="contextMenuClick($event)"
    (onItemRendered)="event.onItemRendered($event)"
    [(visible)]="isContextMenuVisible"
  >
    <div *dxTemplate="let e of 'item'">
      <div>
        <span *ngIf="e.items" class="dx-icon-spinright"></span> {{ e.text }}
      </div>
    </div>
  </dx-context-menu>
  <app-mobile-list
    [dataSource]="contextMenu"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>
</ng-template>
