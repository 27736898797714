import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { CustomWindowConfig } from '../../../event.model';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-delivery-addresses-position-document',
    templateUrl: './delivery-addresses-position-document.component.html',
    styleUrls: ['./delivery-addresses-position-document.component.scss'],
    inputs: [
        'isVisible',
        'readOnly',
        'selectedId',
        'title',
        'CustomerId',
        'WarehouseDocumentId',
        'OrderDocumentId',
        'InvoiceDocumentId',
        'typeDocument',
        'ServiceOrderId',
    ],
    standalone: false
})
export class DeliveryAddressesPositionDocumentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('addressesList') addressesList;
  @ViewChild('firstFocus') firstFocus;
  @ViewChild('customerBox') customerBox;
  @ViewChild('btnShowAddresse') btnShowAddresse;
  typeDocument;
  unicalGuid;
  isVisible;
  readOnly;
  selectedId;
  title;
  CustomerId;
  WarehouseDocumentId;
  OrderDocumentId;
  InvoiceDocumentId;
  widthWindow: any = 1100;
  heightWindow: any = 540;
  submitted: boolean = false;
  ServiceOrderId;
  shortcuts: ShortcutInput[] = [];
  form;
  formServicesOrder;
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: '75%',
      height: '75%',
    },
    params: [],
  };
  addressTypeList = [
    { value: 0, label: '' },
    { value: 1, label: '' },
  ];
  deliveryAddress = [
    {
      CountryCode: '',
      PostalCode: '',
      City: '',
      Street: '',
      AddressType: 0,
      Phone: '',
      Email: '',
      PostageOperator: '',
      PickUpAddressCode: '',
    },
  ];
  showAddresse: boolean = false;
  showConfirmSelectDeliveryAddress = false;
  correctAddress = false;

  constructor(
    private appService: AppServices,
    public cd: ChangeDetectorRef,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('delivery.shippingAddress')
      .subscribe((text) => (this.addressTypeList[0].label = text));
    this.translate
      .get('delivery.pickupPointAddress')
      .subscribe((text) => (this.addressTypeList[1].label = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.createForm();
  }

  ngOnChanges() {
    if (this.isVisible && this.CustomerId) {
      this.form.controls.CustomerId.setValue(this.CustomerId);
    }

    if (this.isVisible && this.selectedId) {
      try {
        this.form.patchValue(this.selectedId[0]);

        if (this.selectedId[0].ReceivedDate == null) {
          this.form.controls.ReceivedPlanTimeFrom.setValue(null);
          this.form.controls.ReceivedPlanTimeTo.setValue(null);
        }
        let data = new DatePipe('en-US').transform(
          this.selectedId[0].PlanDate,
          'yyyy-MM-dd'
        );

        let dataRecive = new DatePipe('en-US').transform(
          this.selectedId[0].ReceivedDate,
          'yyyy-MM-dd'
        );

        let ReceivedPlanTimeFrom;
        let ReceivedPlanTimeTo;
        let ReceivedTime;
        if (
          this.selectedId[0].ReceivedPlanTimeFrom != null &&
          this.selectedId[0].PlanDate != null
        ) {
          ReceivedPlanTimeFrom = `${data} ${this.selectedId[0].ReceivedPlanTimeFrom}`;
        }
        if (
          this.selectedId[0].ReceivedPlanTimeTo != null &&
          this.selectedId[0].PlanDate != null
        ) {
          ReceivedPlanTimeTo = `${data} ${this.selectedId[0].ReceivedPlanTimeTo}`;
        }
        if (
          this.selectedId[0].ReceivedTime != null &&
          this.selectedId[0].ReceivedDate !== null
        ) {
          ReceivedTime = `${dataRecive} ${this.selectedId[0].ReceivedTime}`;
        }

        this.form.controls.ReceivedPlanTimeFrom.setValue(ReceivedPlanTimeFrom);
        this.form.controls.ReceivedPlanTimeTo.setValue(ReceivedPlanTimeTo);
        this.form.controls.ReceivedTime.setValue(ReceivedTime);

        if (this.typeDocument === 'services/orders') {
          // Przypisanie wartości początkowych do formServicesOrder
          this.formServicesOrder.patchValue(this.selectedId[0]);
          if (this.selectedId.length != 0) {
            this.deliveryAddress[0].CountryCode =
              this.selectedId[0].CountryCode;
            this.deliveryAddress[0].PostalCode = this.selectedId[0].PostalCode;
            this.deliveryAddress[0].City = this.selectedId[0].City;
            this.deliveryAddress[0].Street = this.selectedId[0].Street;
            this.deliveryAddress[0].AddressType =
              this.selectedId[0].AddressType;
            this.deliveryAddress[0].Phone = this.selectedId[0].Phone;
            this.deliveryAddress[0].Email = this.selectedId[0].Email;
            this.deliveryAddress[0].PostageOperator =
              this.selectedId[0].PostageOperator;
            this.deliveryAddress[0].PickUpAddressCode =
              this.selectedId[0].PickUpAddressCode;
          }
          // if (this.selectedId[0].ReceivedDate == null) {
          //   this.formServicesOrder.controls.ReceivedPlanTimeFrom.setValue(null);
          //   this.formServicesOrder.controls.ReceivedPlanTimeTo.setValue(null);
          // }
          //
          // const data = new DatePipe('en-US').transform(
          //   this.selectedId[0].PlanDate,
          //   'yyyy-MM-dd'
          // );
          //
          // const dataReceived = new DatePipe('en-US').transform(
          //   this.selectedId[0].ReceivedDate,
          //   'yyyy-MM-dd'
          // );
          //
          // let receivedPlanTimeFrom;
          // let receivedPlanTimeTo;
          // let receivedTime;
          //
          // if (
          //   this.selectedId[0].ReceivedPlanTimeFrom != null &&
          //   this.selectedId[0].PlanDate != null
          // ) {
          //   receivedPlanTimeFrom = `${data} ${this.selectedId[0].ReceivedPlanTimeFrom}`;
          // }
          // if (
          //   this.selectedId[0].ReceivedPlanTimeTo != null &&
          //   this.selectedId[0].PlanDate != null
          // ) {
          //   receivedPlanTimeTo = `${data} ${this.selectedId[0].ReceivedPlanTimeTo}`;
          // }
          // if (
          //   this.selectedId[0].ReceivedTime != null &&
          //   this.selectedId[0].ReceivedDate !== null
          // ) {
          //   receivedTime = `${dataReceived} ${this.selectedId[0].ReceivedTime}`;
          // }
          //
          // this.formServicesOrder.controls.ReceivedPlanTimeFrom.setValue(
          //   receivedPlanTimeFrom
          // );
          // this.formServicesOrder.controls.ReceivedPlanTimeTo.setValue(receivedPlanTimeTo);
          // this.formServicesOrder.controls.ReceivedTime.setValue(receivedTime);
        }
      } catch {}
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  onValueChangedReceivedPlanTimeFrom = (e, control) => {
    try {
      this.form.controls[control].setValue(
        new DatePipe('en-US').transform(e.value, 'HH:mm:ss')
      );
    } catch {}
  };

  createForm = () => {
    const currentDate = new Date();
    const nextDay = new Date(currentDate.getTime() + 86400000);
    this.form = this.formBuilder.group({
      DeliveryId: [null],
      AddressId: [null],
      CustomerId: [this.CustomerId],
      WarehouseDocumentId: [null],
      OrderDocumentId: [null],
      InvoiceDocumentId: [null],
      DeliveryFormId: [null, Validators.required],
      PlanDate: [new Date()],
      ReceivedPlanTimeFrom: [null],
      ReceivedPlanTimeTo: [null],
      ReceivedDate: [nextDay],
      ReceivedTime: [null],
      IsReceived: false,
      CountryCode: [null],
      PostalCode: [''],
      City: [''],
      District: [''],
      Province: [''],
      Street: [''],
      AddressType: [0],
      Phone: [''],
      PostageOperator: [''],
      PickUpAddressCode: [''],
      Email: [''],
    });

    this.formServicesOrder = this.formBuilder.group({
      DeliveryId: null,
      AddressId: null,
      CustomerId: this.CustomerId,
      WarehouseDocumentId: null,
      OrderDocumentId: null,
      InvoiceDocumentId: null,
      ServiceOrderId: null,
      DeliveryFormId: [null, Validators.required],
      PlanDate: new Date(),
      ReceivedPlanTimeFrom: null,
      ReceivedPlanTimeTo: null,
      ReceivedDate: nextDay,
      ReceivedTime: null,
      IsReceived: true,
    });
  };

  getAddressDataFromPostalCode = () => {
    if (this.form.get('PostalCode').value.length > 0) {
      this.appService
        .getAuth(
          `customers/getAddressDataFromPostalCode?postalCode=${
            this.form.get('PostalCode').value
          }`
        )
        .subscribe((res) => {
          this.form.controls.City.setValue(res.City);
          this.form.controls.District.setValue(res.District);
          this.form.controls.Province.setValue(res.Province);
        });
    }
  };

  selectedCountryChange = (e) => {
    if (e) {
      this.form.controls.CountryCode.setValue(e[0].CountryCode);
    } else {
      this.form.controls.CountryCode.setValue(null);
    }
  };

  onChoosedDeliveryForm = (e) => {
    if (e) {
      this.form.controls.DeliveryFormId.setValue(e.DeliveryFormId);
      this.formServicesOrder.controls.DeliveryFormId.setValue(e.DeliveryFormId);
    } else {
      this.form.controls.DeliveryFormId.setValue(null);
      this.formServicesOrder.controls.DeliveryFormId.setValue(null);
    }
  };

  clearAddresses = () => {
    this.form.controls.CountryCode.setValue(null);
    this.form.controls.PostalCode.setValue(null);
    this.form.controls.City.setValue(null);
    this.form.controls.Street.setValue(null);
    this.form.controls.Phone.setValue(null);
    this.form.controls.Email.setValue(null);
    this.form.controls.PostageOperator.setValue(null);
    this.form.controls.PickUpAddressCode.setValue(null);
  };

  onChoosedCustomer = (e) => {
    if (e) {
      if (e.CustomerId != this.form.CustomerId) {
        this.clearAddresses();
      }
    } else {
      this.clearAddresses();
    }

    if (e) {
      this.form.controls.CustomerId.setValue(e.CustomerId);
    }

    setTimeout(() => {
      this.event.setFocus(this.btnShowAddresse);
    }, 500);
  };

  onChoosedCustomerForServicesOrder(e) {
    if (e) {
      this.formServicesOrder.controls.CustomerId.setValue(e.CustomerId);
    } else {
      this.formServicesOrder.controls.CustomerId.setValue(null);
    }
    setTimeout(() => {
      this.event.setFocus(this.btnShowAddresse);
    }, 500);
  }

  onClosedAddress = () => {
    this.showAddresse = false;
    this.cd.detectChanges();
  };

  getCustomerDeliveryAddresses = (customerId) => {
    if (this.choosedAddressId != 0 && this.choosedAddressId) {
      this.appService
        .getAuth(
          `customers/${customerId}/deliveryAddresses?addressId=${this.choosedAddressId}`
        )
        .subscribe((res) => {
          if (this.typeDocument === 'services/orders') {
            if (res.data.length > 0) {
              const addressData = res.data[0];
              this.deliveryAddress[0] = {
                CountryCode: addressData.CountryCode || '',
                PostalCode: addressData.PostalCode || '',
                City: addressData.City || '',
                Street: addressData.Street || '',
                AddressType: addressData.AddressType,
                Phone: addressData.Phone || '',
                Email: addressData?.Email || '',
                PostageOperator: addressData.PostageOperator || '',
                PickUpAddressCode: addressData.PickUpAddressCode || '',
              };
            }
          } else {
            this.form.patchValue(res.data[0]);
          }
          this.cd.detectChanges();
        });
    }
  };
  choosedAddressId = null;
  onChoosedAddress = (e) => {
    this.form.patchValue(e[0]);
    this.choosedAddressId = e[0].AddressId;
    if (this.typeDocument === 'services/orders') {
      if (e.length > 0) {
        const addressData = e[0];
        this.formServicesOrder.controls.AddressId.setValue(
          addressData.AddressId
        );
        this.formServicesOrder.controls.CustomerId.setValue(
          addressData.CustomerId
        );
        this.formServicesOrder.controls.ServiceOrderId.setValue(
          this.ServiceOrderId
        );
        this.deliveryAddress[0] = {
          CountryCode: addressData.CountryCode || '',
          PostalCode: addressData.PostalCode || '',
          City: addressData.City || '',
          Street: addressData.Street || '',
          AddressType: addressData.AddressType,
          Phone: addressData.Phone || '',
          Email: addressData?.Email || '',
          PostageOperator: addressData.PostageOperator || '',
          PickUpAddressCode: addressData.PickUpAddressCode || '',
        };
      } else {
        this.deliveryAddress[0] = {
          CountryCode: '',
          PostalCode: '',
          City: '',
          Street: '',
          AddressType: 0,
          Phone: '',
          Email: '',
          PostageOperator: '',
          PickUpAddressCode: '',
        };
      }
    }

    this.showAddresse = false;
    this.cd.detectChanges();
  };

  visibleChange = (e) => {
    this.submitted = false;
    if (!e) {
      this.createForm(); //reset form with initial values
      this.deliveryAddress = [
        {
          CountryCode: '',
          PostalCode: '',
          City: '',
          Street: '',
          AddressType: 0,
          Phone: '',
          Email: '',
          PostageOperator: '',
          PickUpAddressCode: '',
        },
      ];
      this.onClosing.emit(false);
    }
  };

  transformTimeToHHmmss(time: any): any {
    if (time instanceof Date) {
      return new DatePipe('en-US').transform(time, 'HH:mm:ss');
    }
  }

  onSave = () => {
    this.submitted = true;
    this.form.controls.WarehouseDocumentId.setValue(this.WarehouseDocumentId);
    this.form.controls.OrderDocumentId.setValue(this.OrderDocumentId);
    this.form.controls.InvoiceDocumentId.setValue(this.InvoiceDocumentId);

    this.form.controls.ReceivedPlanTimeFrom.setValue(
      this.transformTimeToHHmmss(this.form.controls.ReceivedPlanTimeFrom.value)
    );

    this.form.controls.ReceivedPlanTimeTo.setValue(
      this.transformTimeToHHmmss(this.form.controls.ReceivedPlanTimeTo.value)
    );

    this.form.controls.ReceivedTime.setValue(
      this.transformTimeToHHmmss(this.form.controls.ReceivedTime.value)
    );

    let formToSubmit;

    if (this.typeDocument == 'services/orders') {
      formToSubmit = this.formServicesOrder;

      this.formServicesOrder.controls.WarehouseDocumentId.setValue(
        this.WarehouseDocumentId
      );
      this.formServicesOrder.controls.OrderDocumentId.setValue(
        this.OrderDocumentId
      );
      this.formServicesOrder.controls.InvoiceDocumentId.setValue(
        this.InvoiceDocumentId
      );
      this.formServicesOrder.controls.ServiceOrderId.setValue(
        this.ServiceOrderId
      );

      if (
        this.formServicesOrder.controls.ReceivedPlanTimeFrom.value instanceof
        Date
      ) {
        this.formServicesOrder.controls.ReceivedPlanTimeFrom.setValue(
          this.transformTimeToHHmmss(
            this.formServicesOrder.controls.ReceivedPlanTimeFrom.value
          )
        );
      }
      if (
        this.formServicesOrder.controls.ReceivedPlanTimeTo.value instanceof Date
      ) {
        this.formServicesOrder.controls.ReceivedPlanTimeTo.setValue(
          this.transformTimeToHHmmss(
            this.formServicesOrder.controls.ReceivedPlanTimeTo.value
          )
        );
      }
      if (this.formServicesOrder.controls.ReceivedTime.value instanceof Date) {
        this.formServicesOrder.controls.ReceivedTime.setValue(
          this.transformTimeToHHmmss(
            this.formServicesOrder.controls.ReceivedTime.value
          )
        );
      }
    } else {
      formToSubmit = this.form;
    }

    if (formToSubmit.invalid) {
      return;
    }

    if (this.typeDocument == 'services/orders') {
      if (
        !this.deliveryAddress[0].CountryCode ||
        !this.deliveryAddress[0].PostalCode ||
        !this.deliveryAddress[0].City ||
        !this.deliveryAddress[0].Street
      ) {
        this.showConfirmSelectDeliveryAddress = true;
        return;
      }
    } else {
      if (
        !this.form.value.CountryCode ||
        !this.form.value.PostalCode ||
        !this.form.value.City ||
        !this.form.value.Street
      ) {
        this.showConfirmSelectDeliveryAddress = true;
        return;
      }
    }

    this.appService
      .postAuth(
        `${this.typeDocument}/${
          this.WarehouseDocumentId ||
          this.OrderDocumentId ||
          this.InvoiceDocumentId ||
          this.ServiceOrderId
        }/deliveryAddresses`,
        formToSubmit.value
      )
      .subscribe(
        (res) => {
          this.onInserted.emit(res);
          this.isVisible = false;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  cancelConfirmSelectDeliveryAddress() {
    this.showConfirmSelectDeliveryAddress = false;
    this.showAddresse = true;
  }

  onReceivedPlanTimeFromChanged(e: any) {
    if (e.value) {
      this.formServicesOrder.controls.ReceivedPlanTimeFrom.setValue(e.value);
    } else {
      this.formServicesOrder.controls.ReceivedPlanTimeFrom.setValue(null);
    }
  }

  onReceivedPlanTimeToChanged(e: any) {
    if (e.value) {
      this.formServicesOrder.controls.ReceivedPlanTimeTo.setValue(e.value);
    } else {
      this.formServicesOrder.controls.ReceivedPlanTimeTo.setValue(null);
    }
  }

  onReceivedTimeChanged(e: any) {
    if (e.value) {
      this.formServicesOrder.controls.ReceivedTime.setValue(e.value);
    } else {
      this.formServicesOrder.controls.ReceivedTime.setValue(null);
    }
  }

  onCorectedAddress() {
    if (this.typeDocument === 'services/orders') {
      setTimeout(() => {
        this.getCustomerDeliveryAddresses(
          this.formServicesOrder.controls.CustomerId.value
        );
      }, 200);
    } else {
      setTimeout(() => {
        this.getCustomerDeliveryAddresses(this.form.controls.CustomerId.value);
      }, 200);
    }
    this.correctAddress = false;
  }
}
