import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'statusRouteText',
  standalone: true
})
export class StatusRouteTextPipe implements PipeTransform {

  private statusMap: { [key: number]: string } = {
    0: 'routes.status.waiting',
    1: 'routes.status.inProgres',
    2: 'routes.status.completed',
    3: 'routes.status.cancelled'
  };

  transform(value: number): string {
    return this.statusMap[value] || value.toString();
  }

}
