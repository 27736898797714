<app-enter-scaner-value
  [title]="
    findModeElement(activeMode)?.label +
    ' ' +
    ('reader.by' | translate) +
    ' ' +
    (findSelectedItemObject(selectedItem)?.label || '')
  "
  [readOnly]="readOnly && inventory"
  [isVisible]="isPopupVisible"
  (onClosed)="isPopupVisible = false"
  (onChoosed)="onChoosed($event)"
  [inventory]="inventory"
></app-enter-scaner-value>

<app-enter-scaner-value
  [title]="
    modeSource[2].label +
    ' ' +
    ('reader.by' | translate) +
    ' ' +
    ('constractors.filter.ean' | translate)
  "
  [isVisible]="isBarcodePopupVisible"
  [readOnly]="readOnly"
  [inventory]="inventory"
  *ngIf="isBarcodePopupVisible"
  (onClosed)="isBarcodePopupVisible = false"
  (onChoosed)="onChoosedBarcode($event)"
  [value]="scanerInputValue"
></app-enter-scaner-value>

<ng-container *ngIf="showScanerContainer">
  <div class="scaner-container" style="margin-right: 6px">
    <dx-select-box
      [id]="'select-mode' + unicalGuid"
      class="scaner-mode-select"
      [width]="90"
      style="margin-left: 2px; display: inline-block; border: none"
      [dataSource]="modeSource"
      [(value)]="activeMode"
      valueExpr="value"
      [readOnly]="readOnly && !inventory"
      (onValueChanged)="onModeValueChanged($event)"
      displayExpr="label"
      itemTemplate="item"
    >
      <div *dxTemplate="let data of 'item'" style="padding: 7px 9px">
        <i class="dx-icon icon icon-select" [ngClass]="data.icon"></i>
        <div class="select-label">{{ data.label }}</div>
      </div>
    </dx-select-box>
    <dx-button
      class="scaner-mode-select i-p-3"
      text=""
      [disabled]="readOnly"
      icon="barcode"
      stylingMode="text"
      type="default"
      [id]="'barcode' + unicalGuid"
      (onClick)="isBarcodePopupVisible = true"
      style="margin-top: 2px; margin-right: 9px; margin-bottom: 2px"
    ></dx-button>

    <div class="custom-scaner-wrapper">
      <dx-button
        [id]="'filter' + unicalGuid"
        [icon]="getIcon()"
        stylingMode="text"
        type="default"
        [disabled]="readOnly"
        class="left-inside-scaner-btn"
        (onClick)="toggleSelectBox()"
      ></dx-button>
      <!-- [ngClass]="{'border-active': selectBoxOpened, 'focus-active': focus, 'full-container': hideFilter}" -->
      <dx-text-box
        [showClearButton]="false"
        class="inline inline-scaner-search-text-box"
        (keyup.enter)="onEnter()"
        [placeholder]="
          (findSelectedItemObject(selectedItem)?.label || '') + '...'
        "
        valueChangeEvent="keyup"
        [(value)]="filterValue"
        (onFocusIn)="onFocusIn()"
        [readOnly]="readOnly"
        (onFocusOut)="onFocusOut()"
      >
        <dxi-button
          name="extraBtn"
          location="after"
          [options]="extraButton"
        ></dxi-button>
      </dx-text-box>
      <!-- <dx-button (onClick)="search()" icon="icon icon-search" class="btn-dx-filter btn-srch-icon"></dx-button> -->
      <dx-select-box
        [opened]="selectBoxOpened"
        [dataSource]="filterSource"
        valueExpr="value"
        displayExpr="label"
        [(value)]="selectedItem"
        class="hidden-select-box"
        [readOnly]="readOnly"
        style="top: -3px !important"
      >
      </dx-select-box>
    </div>
    <dx-tooltip
      [target]="'#filter' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ findModeElement(activeMode)?.label }}
        {{ "reader.by" | translate }}...
      </div>
    </dx-tooltip>
  </div>

  <dx-button
    class="inline"
    icon="scaner"
    stylingMode="text"
    type="default"
    [disabled]="readOnly"
    [disabled]="readOnly"
    (onClick)="openDataCollector()"
    [id]="'data-collector' + unicalGuid"
    *ngIf="
      deviceType === 'desktop' &&
      event.footerInfo.VariantName !== 'START' &&
      event.footerInfo.VariantName !== 'BIZNES'
    "
  ></dx-button>

  <dx-tooltip
    [target]="'#data-collector' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "dataCollectors.buttons.collector" | translate }}
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#barcode' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "constractors.filter.ean" | translate }} (F11)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#extra' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ findModeElement(activeMode)?.label }}
      {{ "reader.by" | translate }}:
      {{ findSelectedItemObject(selectedItem)?.label }}
    </div>
  </dx-tooltip>
</ng-container>

<!-- [unicalGuid]="unicalGuid" -->
<ng-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="keyboardShortcutsDisabled || !showScanerContainer"
>
</ng-keyboard-shortcuts>

<dx-tooltip
  [target]="'#select-mode' + unicalGuid"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
  [showEvent]="event.tooltipShowEvent"
>
  <div *dxTemplate="let data of 'content'">
    {{ "reader.mode" | translate }}
  </div>
</dx-tooltip>
