import { CustomWindowConfig, SingleRecordMode } from 'src/app/event.model';
import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  Output,
  HostListener,
  SimpleChanges,
  Input,
} from '@angular/core';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment } from '../../../../environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { HelpService } from '../../../help-service.service';

@Component({
    selector: 'app-add-offices',
    templateUrl: './add-offices.component.html',
    styleUrls: ['./add-offices.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'mode'],
    standalone: false
})
export class AddOfficesComponent implements OnInit {
  @Input() displayCancelConfirm: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('gridClassBank') gridClassBank;

  @ViewChild('focusFirst') focusFirst;

  mode: SingleRecordMode = null;
  isVisible;
  selectedId;
  readOnly;
  title;

  widthWindow = 650;
  heightWindow = 450;
  selectedtab = 0;
  shortcuts: ShortcutInput[] = [];
  postalCodeMask = '00-000';

  form: FormGroup;
  submitted: boolean = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  taxOfficeType = [
    { value: 'US', label: 'Urząd skarbowy' },
    { value: 'ZUS', label: 'ZUS' },
  ];

  showCodes: boolean = false;
  dataSource: DataSource;
  selectedCodes = [];
  focusedRowBank = [];
  isDeleteAccountBank: boolean = false;

  isMobile: boolean = false;
  bankAccountMode = 'add';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 1200) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  filterCriteria = [
    { value: 'Code', label: 'Kod' },
    { value: 'city', label: 'Miasto' },
  ];

  filterValue = '';

  selectedRow = [];

  dataSourceBank;
  TaxOfficeBankAccountId = [];

  addRowBank: boolean = false;
  editRowBank: boolean = false;
  height: any = 450;

  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'city',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanelColumns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: false,
      height: 210,
    },
    mainPanelWidth: 'calc(100% - 300px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 300,
    },
    windowSize: {
      isFullscreen: false,
      width: 0,
      height: 0,
    },
    params: [],
    skipUserGuide: false,
  };

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private helpService: HelpService
  ) {
    if (event.deviceType === 'mobile') {
      this.height = '90%';
    } else if (this.mode === 'show') {
      this.height = 486;
    } else {
      this.height = 450;
    }
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.createForm();

    this.translate
      .get('offices.taxOffice')
      .subscribe((text) => (this.taxOfficeType[0].label = text));

    this.translate
      .get('bankBox.grid.columns.postalCode')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('offices.city')
      .subscribe((text) => (this.filterCriteria[1].label = text));
  }

  onShown(_e) {
    this.event.onShownPopUp();
    if (this.mode !== 'show' && !this.readOnly) {
      this.focusFirst.instance.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mode === 'edit' || this.mode === 'show') {
      // if (this.mode == 'show') {
      //   this.readOnly = true;
      // } else {
      //   this.readOnly = this.event.readOnly;
      // }
      if (this.isVisible) {
        setTimeout(() => {
          this.selectedId[0].TaxOfficeType =
            this.selectedId[0].TaxOfficeType.replace(' ', '');
          this.form.patchValue(this.selectedId[0]);
          this.getAccount(this.selectedId[0].TaxOfficeId);
        }, 0);
      }
      //this.getDateSelectedId(this.selectedId[0])
    }

    // if (this.isVisible) {
    //   setTimeout(() => {
    //     this.focusFirst.instance.focus();
    //   }, 700);
    // }
    // if (this.mode === 'edit' || this.mode === 'show') {
    //   if (this.isVisible) {
    //     setTimeout(() => {
    //       this.selectedId[0].TaxOfficeType =
    //         this.selectedId[0].TaxOfficeType.replace(' ', '');
    //       this.form.patchValue(this.selectedId[0]);
    //       this.getAccount(this.selectedId[0].TaxOfficeId);
    //     }, 0);
    //     // this.getDateSelectedId(this.selectedId[0])
    //
    //   }
    // }

    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      switch (this.mode) {
        case 'add':
          this.title = this.translate.instant('offices.addOffice');
          this.createForm();
          break;
        case 'edit':
          this.event.loadingVisible = false;
          this.title = this.translate.instant('offices.editOffice');
          this.getTaxOffice();
          break;
        case 'show':
          this.event.loadingVisible = false;
          this.title = this.translate.instant('offices.officePreview');
          this.getTaxOffice();
          this.getAccount(this.selectedId[0].TaxOfficeId);
      }
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancel();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-urzedow'
          );
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 1;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedtab == 1 &&
            this.form.get('TaxOfficeId').value &&
            this.mode !== 'show' &&
            !this.readOnly
          ) {
            this.addBankAccount();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            this.isVisible &&
            this.selectedtab == 1 &&
            this.focusedRowBank.length !== 0 &&
            this.mode !== 'show' &&
            this.form.get('TaxOfficeId').value
          ) {
            this.editBankAccount();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            this.isVisible &&
            this.selectedtab == 1 &&
            this.focusedRowBank.length !== 0 &&
            this.mode !== 'show' &&
            this.form.get('TaxOfficeId').value
          ) {
            this.isDeleteAccountBank = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );
  }

  onKeyDown(e: any) {
    if (e.event.keyCode === 113) {
      if (
        !this.readOnly &&
        this.isVisible &&
        this.selectedtab == 1 &&
        this.focusedRowBank.length !== 0 &&
        this.mode !== 'show' &&
        this.form.get('TaxOfficeId').value
      ) {
        this.editBankAccount();
        this.cd.detectChanges();
      }
    }
  }

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(`offices/taxOffices?taxOfficeId=${selectedId}`)
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
        this.getAccount(selectedId);
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      Code: [''],
      Name: ['', Validators.required],
      PostalCode: [''],
      City: [''],
      Street: [''],
      Phone: [''],
      IsReadOnly: false,
      Email: [''],
      TaxOfficeId: [0],
      TaxOfficeType: ['US'],
      BankAccountId: [0],
    });
  };

  onClosingBank = () => {
    this.editRowBank = false;
    this.addRowBank = false;
  };

  onInsertedBank = (e) => {
    if (e) {
      if (e.TaxOfficeId > 0) {
        this.getAccount(e.TaxOfficeId);
      } else {
        this.dataSourceBank.push(e);
      }
      this.cd.detectChanges();
    }
    // if (e.TaxOfficeId == 0) {
    //   if (typeof this.dataSourceBank == 'undefined') {
    //     this.dataSourceBank = [];
    //   }
    //   for (let i = this.dataSourceBank.length - 1; i >= 0; i--) {
    //     if (
    //       JSON.stringify(this.focusedRowBank[0]) ==
    //       JSON.stringify(this.dataSourceBank[i])
    //     ) {
    //       this.dataSourceBank.splice(i, 1);
    //     }
    //   }
    //   this.dataSourceBank.push(e);
    // } else {
    //   this.dataSourceBank.reload();
    // }
  };

  focusedRowIndex = -1;
  getCode = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Code',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}offices/taxOffices/codes`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  onRowDblClick = () => {
    this.onChoosingRow();
  };

  onFocusedRowChanged = (e) => {
    this.selectedCodes = [e?.row?.data];
  };

  getAccount = (selectedId) => {
    this.dataSourceBank = new DataSource({
      store: AspNetData.createStore({
        key: 'TaxOfficeBankAccountId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}offices/taxOffices/${selectedId}/bankAccounts`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          let isDefault = false;
          if (data.length === 0) {
            this.focusedRowBank = [];
          } else {
            this.focusedRowIndex = 0;
            this.onFocusedRowChanged({
              row: { data: data[this.focusedRowIndex] },
            });
            this.cd.detectChanges();
          }

          data.forEach((item) => {
            if (item.TaxOfficeBankAccountId == this.form.value.BankAccountId) {
              item.IsDefault = true;
              isDefault = true;
            } else {
              item.IsDefault = false;
            }
          });
          if (!isDefault) {
            this.form.controls.BankAccountId.setValue(0);
          }
        },

        deleteUrl: `${environment.domain}offices/taxOffices/${selectedId}/bankAccounts`,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  };

  onRowDblClickBank = () => {
    if (!this.readOnly) {
      this.editRowBank = true;
    }
  };

  onFocusedRowChangeBank = (e) => {
    this.focusedRowBank = [e?.row?.data];
    this.TaxOfficeBankAccountId = [e?.row?.data.TaxOfficeBankAccountId];
  };

  onFilterDataChanged(e) {
    this.localStorageData.filter.orderBy = e.selectedItem.value;
    this.localStorageData.filter.value = e.filterValue;
    this.getCode();
  }

  getLoadParams() {
    let obj: any = {};

    obj.OrderBy = this.localStorageData.filter.orderBy;
    obj.Order = this.localStorageData.filter.order;

    switch (this.localStorageData.filter.orderBy) {
      case 'Code':
        obj.Code = this.localStorageData.filter.value;
        break;
      case 'city':
        obj.city = this.localStorageData.filter.value;
        break;
      default:
        break;
    }

    return obj;
  }

  visibleChange = (e) => {
    this.submitted = false;
    this.dataSourceBank = [];
    if (!e) {
      // this.createForm(); //reset form with initial values
      this.onClosing.emit(false);
    }
    this.selectedtab = 0;
  };

  visibleChangeCode = (e) => {
    if (e) {
      this.getCode();
    }
  };

  onClosingConfirmDelete() {
    this.isDeleteAccountBank = false;
    setTimeout(() => {
      if (this.gridClassBank) {
        this.gridClassBank.instance.focus();
      }
    }, 300);
  }

  onChoosingRow = () => {
    this.form.patchValue(this.selectedCodes[0]);
    this.showCodes = false;
  };

  isDeleteBank = () => {
    if (this.focusedRowBank.length != 0 && !this.readOnly) {
      this.isDeleteAccountBank = true;
    }
  };

  deleteBank = () => {
    if (this.focusedRowBank[0].TaxOfficeId && this.TaxOfficeBankAccountId) {
      this.appService
        .deleteAuth(
          `offices/taxOffices/${this.focusedRowBank[0].TaxOfficeId}/bankAccounts/${this.TaxOfficeBankAccountId}`
        )
        .subscribe(
          () => {
            this.isDeleteAccountBank = false;
            this.getAccount(this.focusedRowBank[0].TaxOfficeId);
          },
          (error) => {
            this.isDeleteAccountBank = false;
            this.event.httpErrorNotification(error);
          }
        );
    }

    // if (this.focusedRowBank[0].BankAccountId == 0) {
    //   for (let i = this.dataSourceBank.length - 1; i >= 0; i--) {
    //     if (
    //       JSON.stringify(this.dataSourceBank[i]) ==
    //       JSON.stringify(this.focusedRowBank[0])
    //     ) {
    //       this.dataSourceBank.splice(i, 1);
    //     }
    //   }
    // } else {
    //   this.gridClassBank.instance.deleteRow(
    //     this.gridClassBank.instance.getRowIndexByKey(this.TaxOfficeBankAccountId[0])
    //   );
    //   setTimeout(() => {
    //     if (this.gridClassBank) {
    //       this.gridClassBank.focusedRowIndex = 0;
    //       this.gridClassBank.instance.focus();
    //     }
    //   }, 500);
    // }
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly && !this.form.value.IsReadOnly) {
      const formValue = this.form.value;
      if (!formValue.PostalCode.includes('-')) {
        formValue.PostalCode =
          formValue.PostalCode.substring(0, 2) +
          '-' +
          formValue.PostalCode.substring(2);
      }
      if (this.form.value.TaxOfficeId == 0) {
        this.appService.postAuth('offices/taxOffices', formValue).subscribe(
          (res) => {
            this.onInserted.emit(res);
            this.isVisible = false;
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
      } else {
        this.appService
          .putAuth(
            `offices/taxOffices/${this.form.value.TaxOfficeId}`,
            formValue
          )
          .subscribe(
            () => {
              this.onInserted.emit(formValue);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };

  editBankAccount() {
    this.editRowBank = true;
    this.bankAccountMode = 'edit';
  }

  taxOfficeId = 0;

  setDefault() {
    const bank = this.dataSourceBank._items.find(
      (item) => item.TaxOfficeBankAccountId == this.TaxOfficeBankAccountId[0]
    );
    if (!bank.IsActive) {
      this.event.showNotification(
        'warning',
        this.translate.instant(
          'confirmMessages.cannotSetDefaultAccountThatInactive'
        )
      );
      return;
    }
    this.form.controls.BankAccountId.setValue(this.TaxOfficeBankAccountId[0]);
    this.getAccount(this.selectedId[0].TaxOfficeId);
  }

  getTaxOffice() {
    this.appService
      .getAuth(
        `offices/taxOffices?taxOfficeId=${this.selectedId[0].TaxOfficeId}`
      )
      .subscribe(
        (res) => {
          this.form.patchValue(res.data[0]);
          this.form.controls.TaxOfficeType.setValue(
            this.form.controls.TaxOfficeType.value.replace(' ', '')
          );
          this.getAccount(this.selectedId[0].TaxOfficeId);
          this.taxOfficeId = this.selectedId[0].TaxOfficeId;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.cd.detectChanges();
    this.getCode();
  };

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }

    this.localStorageData.filter.order = 'ASC';
  }

  addBankAccount() {
    this.bankAccountMode = 'add';
    this.addRowBank = true;
  }

  getAddressDataFromPostalCode = () => {
    let postalCode = this.form.get('PostalCode').value;
    // postalCode = postalCode.substring(0, 2) + '-' + postalCode.substring(2, 5);

    if (postalCode.length >= 3) {
      // Dodaj myślnik po dwóch pierwszych cyfrach
      const formattedNumber =
        postalCode.slice(0, 2) + '-' + postalCode.slice(2);
      this.appService
        .getAuth(
          `customers/getAddressDataFromPostalCode?postalCode=${formattedNumber}`
        )
        .subscribe((res) => {
          this.form.controls.City.setValue(res.City);
        });
    } else {
      // Zwróć niezmienioną liczbę, jeśli ma mniej niż trzy cyfry
      this.form.controls.PostalCode.setValue(postalCode);
      return postalCode;
    }

    // if (postalCode.length > 0) {
    //   this.appService
    //     .getAuth(
    //       `customers/getAddressDataFromPostalCode?postalCode=${postalCode}`
    //     )
    //     .subscribe((res) => {
    //       this.form.controls.City.setValue(res.City);
    //     });
    // }
  };

  isCancelQuestionVisible = false;
  skipCancelQuestion = false;

  onCancelQuestionConfirm() {
    this.isCancelQuestionVisible = false;
    this.skipCancelQuestion = true;
    this.onCancel();
  }

  onCancel() {
``
    if (this.showCodes) return;

    if (this.displayCancelConfirm && !this.skipCancelQuestion) {
      this.isCancelQuestionVisible = true;
      return;
    }
    this.skipCancelQuestion = false;
    this.isVisible = false;
    this.onClosing.emit(false);
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }
}
