import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { SingleRecordMode } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import {
  DeviceSubType,
  DeviceType,
  InterconnectorDevice,
  InterconnectorDeviceConfiguration,
} from 'src/app/interconnector.model';
import { InterconnectorService } from 'src/app/interconnector.service';
import { PrintService } from 'src/app/print.service';

@Component({
    selector: 'app-single-device',
    templateUrl: './single-device.component.html',
    styleUrls: ['./single-device.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SingleDeviceComponent {
  @Input() title: string;
  @Input() isVisible: boolean;
  @Input() deviceType: DeviceType;
  @Input() mode: SingleRecordMode;
  @Input() deviceGuid: string;

  @Output() onClosing = new EventEmitter();
  @Output() onSaved = new EventEmitter();

  @ViewChild('systemName') systemName: DxTextBoxComponent;
  configuration: InterconnectorDevice[];
  interconnectorDevice: InterconnectorDevice;
  formBuilder = inject(FormBuilder);
  activeShortcuts: boolean;
  form: FormGroup = this.formBuilder.group({
    Name: ['', Validators.required],
    SystemName: [null, [Validators.required]],
    Guid: [null],
    DeviceType: [0, [Validators.required]],
    PrinterModel: [null],
    Timeout: [3],
    Repeat: [5],
    Com: [0],
    BaudRate: [9600, [Validators.required]],
    IpAddress: ['https://localhost', [Validators.required]],
    Port: [7721, [Validators.required]],
    ProtocolType: [null],
  });
  loadChildComponent: boolean;
  shortcuts: ShortcutInput[] = [];
  submitted: boolean = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  readOnly: boolean;
  DeviceType = DeviceType;
  systemPrinters: DeviceSubType[] = [];
  systemPrinter: DeviceSubType = {
    Name: 'Domyślna',
    Description: null,
    Id: null,
  };
  customPrinter: DeviceSubType = { Name: 'Inna', Description: null, Id: 0 };
  defaultIpAddress: string = 'https://localhost';
  defaultPortNumber: number = 7721;
  defaultComputerName: string = null;
  IpResetButton;
  portResetButton;
  computerNameResetButton;
  scrollPosition = 0;

  constructor(
    // private reportService: ReportConfigurationService
    private interconnector: InterconnectorService,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public printService: PrintService,
    public translate: TranslateService
  ) {
    this.defaultIpAddress = 'https://localhost';
    this.defaultPortNumber = 7721;

    this.IpResetButton = {
      icon: 'icon icon-refresh',
      stylingMode: 'text',
      width: 32,
      elementAttr: {
        class: 'reset-button',
      },
      onClick: () => {
        this.resetIpNumber();
      },
    };

    this.portResetButton = {
      icon: 'icon icon-refresh',
      stylingMode: 'text',
      width: 32,
      elementAttr: {
        class: 'reset-button',
      },
      onClick: () => {
        this.resetPortNumber();
      },
    };

    // this.computerNameResetButton = {
    //   icon: 'icon icon-refresh',
    //   stylingMode: 'text',
    //   width: 32,
    //   elementAttr: {
    //     class: 'reset-button',
    //   },
    //   onClick: () => {
    //     this.resetComputerName();
    //   },
    // };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadChildComponent = true;
    }, 1000);
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onCancel();
        },
        preventDefault: true,
      }
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.deviceGuid?.currentValue) {
      await this.updateForm();
      this.getSystemName();
    }

    if (changes?.deviceType?.currentValue) {
      this.form.controls['DeviceType'].setValue(
        changes?.deviceType?.currentValue
      );

      switch (this.deviceType) {
        case this.DeviceType['Drukarka dokumentów']:
          let translationSrc = 'externalDevices.documentPrinter.';
          switch (this.mode) {
            case 'show':
              translationSrc += 'showing';
              break;
            case 'edit':
              translationSrc += 'editing';
              break;
            case 'add':
              translationSrc += 'adding';
              break;
          }
          this.translate.get(translationSrc).subscribe((res: string) => {
            this.title = res;
          });
          break;
      }
    }

    if (changes?.mode?.currentValue) {
      if (this.mode == 'show') {
        this.readOnly = true;
      } else {
        this.readOnly = false;
      }
    }
  }

  onShown() {
    this.event.onShownPopUp();
    setTimeout(() => {
      this.activeShortcuts = true;
      this.cd.detectChanges();
    }, 100);
  }

  onHidden() {
    this.event.onHiddenPopUp();
    this.activeShortcuts = false;
  }

  resetForm() {
    this.form.reset();
    this.form.setValue({
      Name: '',
      SystemName: null,
      Guid: null,
      DeviceType: 0,
      PrinterModel: null,
      Timeout: 3,
      Repeat: 5,
      Com: 0,
      BaudRate: 9600,
      IpAddress: 'https://localhost',
      Port: 7721,
      ProtocolType: null,
    });
  }

  async onSave() {
    if (this.form.valid) {
      let params = Object.assign({}, this.form.value);
      this.event.onShown();
      switch (this.mode) {
        case 'add':
          try {
            const newInterconnectorDevice: InterconnectorDevice =
              await this.addToInterconnector(params);
            let guid = newInterconnectorDevice.Configuration.Guid;
            params.SerialNo = guid;

            this.submitted = false;
            this.isVisible = false;
            this.onSaved.emit(newInterconnectorDevice);
            this.resetForm();
          } catch (error) {
            this.event.httpErrorNotification(error);
          }
          break;
        case 'edit':
          try {
            const device: InterconnectorDevice = {
              ...this.interconnectorDevice,
              Configuration: params,
            };
            await this.editInInterconnector(device);
            this.submitted = false;
            this.isVisible = false;
            this.onSaved.emit(device);
          } catch (error) {
            this.event.httpErrorNotification(error);
          }
          break;
      }
      this.event.onHidden();
    }
    // const DeviceDetails: InterconnectorDevice = {
    //   Configuration: this.form.value,
    //   IsDefault: false,
    //   LastUsed: new Date().toISOString(),
    // };
    // this.event.onShown();
    // await this.interconnector.updateDevices(DeviceDetails);
    // this.event.onHidden();
    // this.onSaved.emit();
  }

  addToInterconnector(cfg: InterconnectorDeviceConfiguration) {
    return new Promise<InterconnectorDevice>((resolve, reject) => {
      if (cfg.Guid !== undefined) delete cfg.Guid;
      this.interconnector.addDevice(cfg).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  editInInterconnector(device: InterconnectorDevice) {
    return new Promise((resolve, reject) => {
      try {
        const res = this.interconnector.updateDevices(device);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  onCancel() {
    this.isVisible = false;
    this.cd.detectChanges();
    this.onClosing.emit();
  }

  async updateForm() {
    return new Promise<void>((resolve, reject) => {
      this.interconnector
        .getDevices()
        .then((res) => {
          this.configuration = res;
          const device = res
            .filter((x) => x.Configuration.DeviceType == this.deviceType)
            .map((x) => x?.Configuration)
            .find((x) => x.Guid == this.deviceGuid);
          if (device) this.interconnectorDevice = device;
          this.form.patchValue(device);

          this.cd.detectChanges();
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getDeviceModel(_modelNumber) {
    let modelName = '';
    return modelName;
  }

  async getSystemName() {
    this.systemPrinters =
      (await this.interconnector.getDeviceSubTypes(
        DeviceType['Drukarka etykiet']
      )) || [];
    if (this.systemPrinters.length === 0) {
      return;
    }

    this.systemPrinters.push(this.customPrinter);

    if (this.mode !== 'add') {
      const systemName = this.form.controls['SystemName'].value;
      const savedPrinter = this.systemPrinters.find(
        (printer) => printer.Name === this.form.controls['SystemName'].value
      );
      if (savedPrinter) {
        this.systemPrinter = savedPrinter;
      } else {
        this.systemPrinter = this.customPrinter;
        setTimeout(() => {
          this.form.controls['SystemName'].setValue(systemName);
        }, 500);
      }
    }
  }
  onSystemPrinterSelect(e) {
    if (e.selectedItem) {
      this.systemPrinter = e.selectedItem;
      this.cd.detectChanges();
    }

    if (e.selectedItem && e.selectedItem.Id !== 0) {
      this.form.controls['SystemName'].setValue(e.selectedItem.Name);
    }

    if (e.selectedItem && e.selectedItem.Id === 0) {
      this.form.controls['SystemName'].setValue('');
      setTimeout(() => {
        this.systemName.instance.focus();
      }, 0);
    }
  }

  resetPortNumber() {
    this.form.controls['PortNumber'].setValue(this.defaultPortNumber);
    this.cd.detectChanges();
  }

  resetIpNumber() {
    this.form.controls['IpAddress'].setValue(this.defaultIpAddress);
    this.cd.detectChanges();
  }

  // async resetComputerName() {
  //   this.event.onShown();
  //   try {
  //     this.computerName = await this.getComputerName();
  //   } catch (err) {
  //     let error = JSON.parse(err);
  //     if (error.error == 0) {
  //       this.mode = 'show';
  //       this.onConnectionLost.emit();
  //       this.isVisible = false;
  //     }
  //   }
  //   this.event.onHidden();
  //   this.form.controls['ComputerName'].setValue(this.computerName);
  //   this.cd.detectChanges();
  // }

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };
}
