import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
//  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { SingleRecordMode } from 'src/app/event.model';
//import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

//import { HelpService } from '../../../help-service.service';

@Component({
    selector: 'app-add-vehicles',
    templateUrl: './add-vehicles.component.html',
    styleUrls: ['./add-vehicles.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'mode'],
    standalone: false
})
export class AddVehiclesComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Output() onClosed = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  title;
  isVisible;
  selectedId;
  readOnly;

  widthWindow = 630;
  heightWindow = 560;
  mode: SingleRecordMode = null;

  shortcuts: ShortcutInput[] = [];

  submitted: boolean = false;
  dataSource;
  isVisibleAddVehicle: boolean = false;
  formAddMode: SingleRecordMode = null;

  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  constructor(
    //private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    //private helpService: HelpService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    /*
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.disabletShortcuts.next(true);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
    */
  }

  onSave() {

  }

  onClose() {
    this.onClosed.emit();
  }

  addBtn(): void {
    this.formAddMode = 'add';
    this.isVisibleAddVehicle = true;
  }

}
