<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType != 'mobile' ? heightWindow : '200px'"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        data-cy="add-order-type_button-form-save"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 450px">
          <form [formGroup]="form">
            <label style="width: 110px"
              >{{ "companies.grid.columns.name" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              data-cy="add-order-type_form-name"
              width="276"
              [readOnly]="readOnly"
              formControlName="Name"
              [ngClass]="{
                'input-required': !form.controls.Name.valid && submitted
              }"
              (onFocusIn)="event.onFocusCountry('Name', form)"
              (onFocusOut)="event.onFocusOut('Name', form)"
              valueChangeEvent="input"
              #focusFirst
            >
            </dx-text-box
            ><br />

            <small
              class="text-danger"
              *ngIf="
                submitted && form.get('Name').focus && !form.get('Name').valid
              "
            >
              {{ "form.required" | translate }}
            </small>

            <label style="width: 110px"></label>
            <dx-check-box
              data-cy="add-order_form-default"
              formControlName="IsDefault"
              text="{{ 'intrastatCnCodes.default' | translate }}"
              style="width: 100px"
              [readOnly]="readOnly"
            ></dx-check-box
            ><br />
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
></wapro-keyboard-shortcuts>
