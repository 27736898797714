<dx-popup
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="330"
  [height]="440"
  [width]="520"
  [minWidth]="490"
  (onShown)="onShown()"
  (onHidden)="onHidden()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  #popUp
  *ngIf="event.deviceType != 'mobile'; else mobilePopupTemplate"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
    >
      <div class="d-inline-block">
        <ng-container [ngTemplateOutlet]="popupButtonsTemplate"></ng-container>
      </div>
    </div>

    <div class="popup-form">
      <ng-container [ngTemplateOutlet]="popupContentTemplate"> </ng-container>
    </div>
  </div>
</dx-popup>

<ng-template #popupButtonsTemplate>
  <dx-button
    text="{{ 'buttons.save' | translate }}"
    type="success"
    style="margin-right: 6px"
    (onClick)="onSave()"
    [id]="'add' + unicalGuid"
    [disabled]="!form.valid || submitted"
  >
  </dx-button>
  <dx-button
    text="{{ 'buttons.cancel' | translate }}"
    type="danger"
    (onClick)="onCancel()"
    [id]="'cancel' + unicalGuid"
  >
  </dx-button>

  <dx-tooltip
    [target]="'#add' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #popupContentTemplate>
  <dx-scroll-view
    width="100%"
    [height]="event.deviceType === 'mobile' ? 'calc(100% - 102px)' : '100%'"
    direction="vertical"
    (onScroll)="onScroll($event)"
  >
    <form [formGroup]="form">
      <div class="form-columns">
        <div class="form-column form-column__left">
          <div class="form-column__header">
            {{ "fiscalPrinter.connectionConfig.basicSettings" | translate }}
          </div>
          <div class="form-column__item">
            <label
              >{{ "dataCollectors.name" | translate }}<strong>*</strong></label
            >
            <div class="form-control">
              <dx-text-box
                formControlName="Name"
                [placeholder]="'servicesDevices.nameDevice' | translate"
                valueChangeEvent="change input keyup"
                [ngClass]="{
                  'input-required': !form.get('Name').value && submitted
                }"
                #printerName
                [readOnly]="readOnly"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="form-column__item">
            <label>{{ "externalDevices.systemName" | translate }}</label>
            <div class="form-control">
              <dx-select-box
                [(value)]="systemPrinter"
                [dataSource]="systemPrinters"
                displayExpr="Name"
                [placeholder]="'externalDevices.systemName' | translate"
                (onSelectionChanged)="onSystemPrinterSelect($event)"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="form-column__item" *ngIf="systemPrinter.Id === 0">
            <div class="form-control">
              <dx-text-box
                formControlName="SystemName"
                [placeholder]="'externalDevices.systemName' | translate"
                valueChangeEvent="change input keyup"
                #systemName
              >
              </dx-text-box>
            </div>
          </div>
          <div class="form-column__item">
            <label
              >{{ "externalDevices.TCPIP" | translate
              }}<strong>*</strong></label
            >
            <div class="form-control">
              <dx-text-box
                formControlName="IpAddress"
                valueChangeEvent="blur focusout input"
              >
                <dxi-button
                  name="resetField"
                  location="after"
                  [options]="IpResetButton"
                  *ngIf="
                    form?.get('IpAddress')?.value !== this.defaultIpAddress
                  "
                ></dxi-button>
              </dx-text-box>
            </div>
          </div>
          <div class="form-column__item">
            <label
              >{{ "externalDevices.port" | translate }}<strong>*</strong></label
            >
            <div class="form-control">
              <dx-number-box
                formControlName="Port"
                [min]="0"
                valueChangeEvent="blur focusout input"
                [ngClass]="{
                  'input-required': !form?.get('Port')?.value && submitted
                }"
              >
                <dxi-button
                  name="resetField"
                  location="after"
                  [options]="portResetButton"
                  valueChangeEvent="blur focusout input"
                  *ngIf="form?.get('Port')?.value != defaultPortNumber"
                ></dxi-button>
              </dx-number-box>
            </div>
          </div>
        </div>
      </div>
    </form>
  </dx-scroll-view>
</ng-template>

<ng-template #mobilePopupTemplate>
  <dx-popup
    width="100%"
    height="100%"
    position="bottom"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="false"
    title=""
    [visible]="isVisible"
    (onShown)="onShown()"
    (onHidden)="onHidden()"
    [wrapperAttr]="{
      id: unicalGuid,
      class: 'document-brand document-form-mobile user-guide-fix',
    }"
    [tabIndex]="-1"
  >
    <div *dxTemplate="let content of 'content'" #popup>
      <div class="document-header-mobile-panel">
        <header>
          <div class="d-flex flex-row">
            <div class="left-header">
              <i
                class="icon absui-backe"
                style="position: relative; top: 4px; margin-right: 10px"
                (click)="onCancel()"
              ></i>
            </div>
            <div class="right-details">
              <p
                class="nameSelectedPosition"
                style="margin-top: 3px; margin-bottom: 0px"
              >
                {{ title }}
              </p>
            </div>
          </div>
        </header>
      </div>

      <ng-container [ngTemplateOutlet]="popupContentTemplate"></ng-container>
      <div class="mobile-buttons-panel">
        <ng-container [ngTemplateOutlet]="popupButtonsTemplate"></ng-container>
      </div>
    </div>
  </dx-popup>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>
