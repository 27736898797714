import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.scss'],
    inputs: ['dropDownBoxMode', 'selectedId', 'readOnly'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BrandsComponent implements OnInit {
  @Output() onChoosed = new EventEmitter();
  @Output() onOpened = new EventEmitter();
  @ViewChild('gridClass') gridClass;

  readOnly;

  selectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  documentListPom = [];

  isGridBoxOpened: boolean = false;
  documentList;

  dataSource;

  addRow: boolean = false;
  editRow: boolean = false;
  isDeleteRow: boolean = false;

  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 200;

  myEventEdit;
  myEventChoose;
  myEventEsc;
  myEventShow;
  myEventDelete;
  unicalGuid;
  contextMenu;
  widthCityColumn = '250';
  focusedRow = [];
  disableShortcuts = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.getDate();

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }

    this.myEventEdit = this.event.edit.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.selectedRow.length > 0) this.editRow = true;
      }
    });

    this.myEventDelete = this.event.delete.subscribe(() => {
      this.isDelete();
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.gridClass.instance.getSelectedRowsData().length > 0)
          this.onChoosingRow();
      }
    });

    this.contextMenu = [
      { text: 'Dodaj', icon: 'icon absui-icon--add-circle' },
      { text: 'Edytuj', icon: 'icon absui-icon--mode-edit' },
      { text: 'Usuń', icon: 'icon absui-icon--highlight-off' },
    ];

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
  }

  contextMenuClick = (e) => {
    switch (e.itemIndex) {
      case 0:
        if (!this.readOnly) this.addRow = true;
        break;
      case 1:
        if (!this.readOnly) this.editRow = true;
        break;
      case 2:
        if (!this.readOnly) this.isDelete();
        break;
    }
  };

  ngOnChanges() {
    if (this.selectedId && this.selectedId != '') {
      this.appService
        .getAuth(`services/brands?brandId=${this.selectedId}`)
        .subscribe((res) => {
          this.documentList = res.data;
          this.choosingDocument = [Number(this.selectedId)];
          this.cd.detectChanges();
        });
    } else {
      this.documentList = [];
      this.choosingDocument = [];
    }
  }

  getDate = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceBrandId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}services/brands`,
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}services/brands`,
      }),
      reshapeOnPush: true,
    });
  };

  ngOnDestroy() {
    this.myEventEdit.unsubscribe();
    this.myEventDelete.unsubscribe();
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            document.getElementById(`btn-add${this.unicalGuid}`).click();
          }
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
        },
        preventDefault: true,
      }
    );
  }

  onClosingForm = () => {
    this.addRow = false;
    this.editRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  focusedRowIndex: number = 0;
  async onInserted(e) {
    await this.dataSource.reload();
    this.focusedRowIndex = this.event.returnFocusIndex(
      this.dataSource._items,
      'ServiceBrandId',
      e.ServiceBrandId
    );
    this.focusedRow = [this.dataSource._items[this.focusedRowIndex]];
    this.event.setFocus(this.gridClass);
  }

  onFocusedRowChanged = (e) => {
    this.selectedRow = [e.row.data.ServiceBrandId];
    this.focusedRow = [e.row.data];
  };

  onChoosingRow = () => {
    if (!this.readOnly) {
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      this.onChoosed.emit(this.documentList[0]);
      this.isGridBoxOpened = false;
    }
  };

  onOpenedChanged = (e) => {
    this.onOpened.emit(e);
    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onSelectionChanged = (e) => {
    this.documentListPom = [];
    this.documentListPom.push(e.selectedRowsData[0]);
  };

  isDelete = () => {
    if (
      this.selectedRow.length > 0 &&
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) this.gridClass.instance.focus();
    }, 500);
  }

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) this.editRow = true;
    else this.onChoosingRow();
  };

  delete = () => {
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(this.selectedRow[0])
    );
    if (this.choosingDocument == this.selectedRow[0])
      this.choosingDocument = [];
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.focusedRowIndex = 0;
        this.gridClass.instance.focus();
      }
    }, 500);
  };

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };
}
