<dx-popup
  [width]="widthWindow"
  [height]="height"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="onShown($event)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        [disabled]="form.value.IsReadOnly || readOnly"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="mode != 'show'"
        data-cy="office-add-save-btn"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onCancel()"
        data-cy="office-add-cancel-btn"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div
          class="form-group-inline-right"
          style="width: 620px"
          [ngClass]="{ 'width-mobile': event.deviceType == 'mobile' }"
        >
          <div
            *ngIf="mode === 'show'"
            class="header-bar d-flex align-items-center"
          >
            <div class="confirme-info-message"></div>
            <div
              class="header-bar_text"
              style="padding: 4px 0"
              [innerHTML]="'offices.warningDataCannotBeEdited' | translate"
            ></div>
          </div>

          <dx-tab-panel
            #tabPanel
            [(selectedIndex)]="selectedtab"
            [items]="[
              'companies.tabs.basicInfo' | translate,
              'companies.tabs.bankAccounts' | translate
            ]"
            [loop]="false"
            [animationEnabled]="false"
            [swipeEnabled]="false"
          >
            <div *dxTemplate="let name of 'item'">
              <ng-container [ngSwitch]="name">
                <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                  <div class="d-flex justify-content-center flex-wrap">
                    <form [formGroup]="form">
                      <label style="width: 110px"
                        >{{ "companies.grid.columns.name" | translate }}
                        <strong>*</strong></label
                      >
                      <dx-text-box
                        width="276"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="Name"
                        [ngClass]="{
                          'input-required':
                            !form.controls.Name.valid && submitted
                        }"
                        valueChangeEvent="input"
                        #focusFirst
                        data-cy="office-add-name"
                      >
                      </dx-text-box>
                      <br />

                      <label
                        style="width: 110px"
                        [ngClass]="{ toTop: !isMobile }"
                        >{{ "document-type.type" | translate }}</label
                      >
                      <dx-radio-group
                        [items]="taxOfficeType"
                        [readOnly]="readOnly || mode == 'show'"
                        layout="horizontal"
                        formControlName="TaxOfficeType"
                        displayExpr="label"
                        valueExpr="value"
                      >
                      </dx-radio-group>
                      <br />

                      <label style="width: 110px">{{
                        "offices.postaCode" | translate
                      }}</label>
                      <dx-text-box
                        width="84"
                        [mask]="postalCodeMask"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="PostalCode"
                        valueChangeEvent="input"
                        (onBlur)="getAddressDataFromPostalCode()"
                        data-cy="office-add-postal"
                      >
                      </dx-text-box>

                      <dx-button
                        class="btn-dx-secondary"
                        text="{{ 'buttons.choose' | translate }}"
                        [ngClass]="{
                          'mobile-width-btn': event.deviceType == 'mobile'
                        }"
                        (onClick)="showCodes = true"
                        [disabled]="readOnly || mode === 'show'"
                        width="187"
                        style="
                          margin-left: 6px;
                          height: 28px !important;
                          position: relative;
                          top: 2px;
                        "
                      >
                      </dx-button>

                      <!-- <dx-button class="btn-dx-top-menu-folder noMarginRight" icon="icon absui-icon--folder-open-outline"
                        [id]="'btn-add'+unicalGuid" [disabled]="readOnly" (onClick)="showCodes = true"
                        style="margin-left: 6px;">
                      </dx-button>-->
                      <br />

                      <label style="width: 110px">{{
                        "offices.city" | translate
                      }}</label>
                      <dx-text-box
                        width="276"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="City"
                        valueChangeEvent="input"
                        data-cy="office-add-city"
                      >
                      </dx-text-box>
                      <br />

                      <label style="width: 110px">{{
                        "singleBank.form.adress" | translate
                      }}</label>
                      <dx-text-box
                        width="276"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="Street"
                        valueChangeEvent="input"
                        data-cy="office-add-street"
                      >
                      </dx-text-box>
                      <br />

                      <label style="width: 110px">{{
                        "offices.phone" | translate
                      }}</label>
                      <dx-text-box
                        width="276"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="Phone"
                        valueChangeEvent="input"
                        data-cy="office-add-phone"
                      >
                      </dx-text-box>
                      <br />

                      <label style="width: 110px">{{
                        "offices.mail" | translate
                      }}</label>
                      <dx-text-box
                        width="276"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="Email"
                        valueChangeEvent="input"
                        data-cy="office-add-email"
                      >
                      </dx-text-box>
                      <br />

                      <label style="width: 110px">{{
                        "offices.offcesCode" | translate
                      }}</label>
                      <dx-text-box
                        width="84"
                        [readOnly]="readOnly || mode == 'show'"
                        formControlName="Code"
                        valueChangeEvent="input"
                      >
                      </dx-text-box>
                      <br />
                      <!--
                                      <dx-button class="btn-dx-top-menu-folder" text="{{'companies.tabs.bankAccounts' | translate}}"
                                          [disabled]="readOnly">
                                      </dx-button> -->
                    </form>
                  </div>
                </div>
                <div *ngSwitchCase="'companies.tabs.bankAccounts' | translate">
                  <div
                    *ngIf="form.get('TaxOfficeId').value; else noTaxOfficeId"
                  >
                    <div class="top-menu-popup">
                      <dx-button
                        (onClick)="addBankAccount()"
                        [disabled]="readOnly || mode == 'show'"
                        [id]="'bankAdd' + unicalGuid"
                        [text]="'buttons.add' | translate"
                        class="btn-dx-top-menu"
                        icon="icon absui-icon--add-circle"
                        data-cy="office-add-add-bank"
                      >
                      </dx-button>

                      <dx-button
                        icon="icon absui-icon--mode-edit"
                        [id]="'bankEdit' + unicalGuid"
                        class="btn-dx-top-menu"
                        [text]="'buttons.edit' | translate"
                        [disabled]="
                          focusedRowBank.length == 0 ||
                          readOnly ||
                          mode === 'show'
                        "
                        (onClick)="editBankAccount()"
                        data-cy="office-add-edit-bank"
                      >
                      </dx-button>

                      <dx-button
                        icon="icon absui-icon--highlight-off"
                        [id]="'bankDelete' + unicalGuid"
                        class="btn-dx-top-menu"
                        [text]="'buttons.delete' | translate"
                        [disabled]="
                          focusedRowBank.length == 0 ||
                          readOnly ||
                          mode === 'show'
                        "
                        (onClick)="isDeleteBank()"
                        data-cy="office-add-delete-bank"
                      >
                      </dx-button>

                      <dx-button
                        icon="icon icon absui-icon--tool-select-rectangle"
                        [id]="'setDefault' + unicalGuid"
                        class="btn-dx-top-menu"
                        [text]="'buttons.setDefault' | translate"
                        [disabled]="
                          focusedRowBank.length == 0 ||
                          readOnly ||
                          mode === 'show'
                        "
                        (onClick)="setDefault()"
                        data-cy="office-add-set-default-bank"
                      >
                      </dx-button>

                      <dx-tooltip
                        [target]="'#bankAdd' + unicalGuid"
                        [showEvent]="event.tooltipShowEvent"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false"
                      >
                        <div *dxTemplate="let data of 'content'">
                          {{ "buttons.add" | translate }} (Insert)
                        </div>
                      </dx-tooltip>

                      <dx-tooltip
                        [target]="'#bankEdit' + unicalGuid"
                        [showEvent]="event.tooltipShowEvent"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false"
                      >
                        <div *dxTemplate="let data of 'content'">
                          {{ "buttons.edit" | translate }} (F2)
                        </div>
                      </dx-tooltip>

                      <dx-tooltip
                        [target]="'#bankDelete' + unicalGuid"
                        [showEvent]="event.tooltipShowEvent"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false"
                      >
                        <div *dxTemplate="let data of 'content'">
                          {{ "buttons.delete" | translate }} (Del)
                        </div>
                      </dx-tooltip>
                    </div>
                    <dx-data-grid
                      [dataSource]="dataSourceBank"
                      [remoteOperations]="true"
                      [wordWrapEnabled]="true"
                      [showBorders]="true"
                      shortcut
                      [height]="heightWindow - 186 - 36"
                      [(focusedRowIndex)]="focusedRowIndex"
                      [(selectedRowKeys)]="TaxOfficeBankAccountId"
                      [focusedRowEnabled]="true"
                      (onRowDblClick)="onRowDblClickBank()"
                      [id]="'grid' + unicalGuid"
                      (onFocusedRowChanged)="onFocusedRowChangeBank($event)"
                      #gridClassBank
                      [allowColumnResizing]="false"
                      [allowColumnReordering]="true"
                      [columnResizingMode]="'widget'"
                      (onKeyDown)="onKeyDown($event)"
                      data-cy="office-add-bank-grid"
                    >
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxo-scrolling mode="infinite"></dxo-scrolling>
                      <dxo-paging [pageSize]="200"></dxo-paging>
                      <dxo-selection mode="single"></dxo-selection>
                      <dxo-editing
                        mode="row"
                        [confirmDelete]="false"
                      ></dxo-editing>

                      <dxi-column
                        caption="D"
                        width="30"
                        dataField="IsDefault"
                        headerCellTemplate="isDefaultHeader"
                        cellTemplate="IsDefault"
                      ></dxi-column>
                      <div
                        *dxTemplate="let data of 'isDefaultHeader'"
                        class="text-center"
                      >
                        <div
                          class="grid-header-wrapper"
                          [id]="'isDefault' + unicalGuid"
                        >
                          D
                        </div>
                        <dx-tooltip
                          [target]="'#isDefault' + unicalGuid"
                          [showEvent]="event.tooltipShowEvent"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                        >
                          <div *dxTemplate="let data of 'content'">
                            {{ "constractors.default" | translate }}
                          </div>
                        </dx-tooltip>
                      </div>
                      <div *dxTemplate="let data of 'IsDefault'">
                        <i
                          class="icon absui-icon--status-ok-green"
                          *ngIf="data.data.IsDefault"
                        ></i>
                      </div>

                      <dxi-column
                        caption="{{ 'bankBox.grid.columns.name' | translate }}"
                        dataField="BankName"
                        [allowSorting]="false"
                      ></dxi-column>
                      <dxi-column
                        caption="LK"
                        width="30"
                        dataField="Lk"
                        [allowSorting]="false"
                      >
                      </dxi-column>
                      <dxi-column
                        caption="{{
                          'bankAccounts.grid.columns.accountNumber' | translate
                        }}"
                        dataField="AccountNumber"
                        [allowSorting]="false"
                        [hidingPriority]="1"
                      ></dxi-column>
                      <dxi-column
                        caption="{{
                          'bankAccounts.grid.columns.currency' | translate
                        }}"
                        width="50"
                        dataField="CurrencySymbol"
                        [allowSorting]="false"
                        [hidingPriority]="0"
                      ></dxi-column>

                      <div *dxTemplate="let data of 'IsActive'">
                        <i
                          class="icon absui-icon--check check-grid"
                          *ngIf="data.data.IsActive"
                        ></i>
                        <i
                          class="icon absui-icon--close close-grid"
                          *ngIf="!data.data.IsActive"
                        ></i>
                      </div>
                    </dx-data-grid>

                    <app-offices-bank-accounts
                      [focusedRowBank]="focusedRowBank"
                      [bankAccountMode]="bankAccountMode"
                      [isVisible]="addRowBank"
                      [taxOfficeId]="form.get('TaxOfficeId').value"
                      (onClosing)="onClosingBank()"
                      (onInserted)="onInsertedBank($event)"
                    ></app-offices-bank-accounts>

                    <app-offices-bank-accounts
                      [focusedRowBank]="focusedRowBank"
                      [isVisible]="editRowBank"
                      [taxOfficeId]="form.get('TaxOfficeId').value"
                      (onClosing)="onClosingBank()"
                      (onInserted)="onInsertedBank($event)"
                      [bankAccountMode]="bankAccountMode"
                    >
                    </app-offices-bank-accounts>
                  </div>
                  <ng-template #noTaxOfficeId>
                    <div style="margin-top: 15px">
                      <div
                        class="header-bar d-flex"
                        style="margin-bottom: 24px"
                      >
                        <div class="confirme-info-message"></div>
                        <div
                          class="header-bar_text"
                          style="padding: 4px 0"
                          [innerHTML]="
                            'confirmsForBankAccount.OfficesSettingsAccount'
                              | translate
                          "
                        ></div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </dx-tab-panel>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-confirm-dialog
  [isVisible]="isDeleteAccountBank"
  (onRemoving)="deleteBank(); gridClassBank.instance.focus()"
  (onClosing)="onClosingConfirmDelete()"
>
</app-confirm-dialog>

<dx-popup
  [width]="'75%'"
  [height]="event.deviceType != 'mobile' ? 450 : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'offices.taxOfficesList' | translate }}"
  [visible]="showCodes"
  (visibleChange)="visibleChangeCode($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onRowDblClick()"
        [id]="'choose-off' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="showCodes = false"
        [id]="'cancel-off' + unicalGuid"
      ></dx-button>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="col-md-12">
          <!-- <div class="top-menu-popup">
                        <dx-button class="btn-dx-top-menu" icon="icon absui-icon--tool-select-rectangle"
                            [text]="'buttons.choose' | translate" [disabled]="selectedRow.length == 0 || readOnly"
                            [id]="'btn-choose'+unicalGuid" (onClick)="onChoosingRow();"
                        >
                        </dx-button>
                    </div> -->
          <app-custom-dropdown-box
            [items]="filterCriteria"
            [(selectedItem)]="localStorageData.filter.orderBy"
            [(filterValue)]="localStorageData.filter.value"
            (onValueChanged)="onFilterDataChanged($event)"
            style="margin-right: 5px"
          >
          </app-custom-dropdown-box>

          <dx-data-grid
            id="gridContainer"
            #gridClass
            [dataSource]="dataSource"
            [remoteOperations]="true"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [(selectedRowKeys)]="selectedRow"
            [focusedRowEnabled]="true"
            (onRowDblClick)="onRowDblClick()"
            [id]="'grid' + unicalGuid"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [allowColumnReordering]="true"
            [columnResizingMode]="'widget'"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="200"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'offices.offcesCode' | translate }}"
              dataField="Code"
              [allowSorting]="false"
              [hidingPriority]="5"
            ></dxi-column>
            <dxi-column
              caption="{{ 'offices.officesName' | translate }}"
              dataField="Name"
              [allowSorting]="false"
            ></dxi-column>
            <dxi-column
              caption="{{ 'offices.city' | translate }}"
              dataField="City"
              headerCellTemplate="City"
              [allowSorting]="false"
              [hidingPriority]="4"
              [cssClass]="
                localStorageData.filter.orderBy === 'city'
                  ? 'sort-column-bg'
                  : null
              "
            >
            </dxi-column>
            <div *dxTemplate="let data of 'City'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria('city')"
              >
                {{ "offices.city" | translate }}
                <i
                  class="header-sort-icon"
                  [ngClass]="
                    localStorageData.filter.order === 'DESC'
                      ? 'arr-down'
                      : 'arr-up'
                  "
                ></i>
              </div>
            </div>
            <dxi-column
              caption="{{ 'offices.street' | translate }}"
              dataField="Street"
              [allowSorting]="false"
              [hidingPriority]="3"
            ></dxi-column>
            <dxi-column
              caption="{{ 'offices.postaCode' | translate }}"
              dataField="PostalCode"
              headerCellTemplate="PostalCode"
              [allowSorting]="false"
              [hidingPriority]="2"
              [cssClass]="
                localStorageData.filter.orderBy === 'Code'
                  ? 'sort-column-bg'
                  : null
              "
            ></dxi-column>
            <div *dxTemplate="let data of 'PostalCode'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria('Code')"
              >
                {{ "offices.postaCode" | translate }}
                <i
                  class="header-sort-icon"
                  [ngClass]="
                    localStorageData.filter.order === 'DESC'
                      ? 'arr-down'
                      : 'arr-up'
                  "
                ></i>
              </div>
            </div>
            <dxi-column
              caption="{{ 'offices.phone' | translate }}"
              dataField="Phone"
              [allowSorting]="false"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              caption="{{ 'offices.mail' | translate }}"
              dataField="Email"
              [allowSorting]="false"
              [hidingPriority]="0"
            >
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-confirm-dialog
  *ngIf="isCancelQuestionVisible"
  [confirmText]="'inventory.wantToCancel'"
  [isVisible]="isCancelQuestionVisible"
  (onRemoving)="onCancelQuestionConfirm()"
  (onClosing)="isCancelQuestionVisible = false"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>
