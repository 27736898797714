import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NestDeactivateGuard } from '../can-deactive-guard';
import { OrderTypeComponent } from '../crm/orders/order-type/order-type.component';
import { PanelComponent } from '../menu-wapro/panel/panel.component';
import { NestResolverService } from '../nest-resolver.service';
import { ArticlesCategoryTreeComponent } from './articles/articles-category-tree/articles-category-tree.component';
import { ArticlesComponent } from './articles/articles.component';
import { CategoriesComponent } from './articles/categories/categories.component';
import { BankListComponent } from './bank-list/bank-list.component';
import { CashBoxesComponent } from './cash-boxes/cash-boxes.component';
import { ClasificationComponent } from './contractors/clasification/clasification.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { CustomerGroupsComponent } from './contractors/customer-groups/customer-groups.component';
import { IdentityMasksComponent } from './contractors/identity-masks/identity-masks.component';
import { CalendarsComponent } from './crm/calendars/calendars.component';
import { TaskTypesComponent } from './crm/task-types/task-types.component';
import { CurrencyBoxComponent } from './currency-box/currency-box.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { EDokumentsComponent } from './e-dokuments/e-dokuments.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { FinanceOperationComponent } from './finance-operation/finance-operation.component';
import { FormsPaymentComponent } from './forms-payment/forms-payment.component';
import { InterestComponent } from './interest/interest.component';
import { LocationsComponent } from './locations/locations.component';
import { OfficesComponent } from './offices/offices.component';
import { PricesComponent } from './prices/prices.component';
import { DevicesComponent } from './services/devices/devices.component';
import { GroupsDeviceComponent } from './services/groups/groups.component';
import { RoleCustomerComponent } from './services/role-customer/role-customer.component';
import { SizeUnitsComponent } from './size-units/size-units.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';
import { WarehouseListComponent } from './warehouse-list/warehouse-list.component';
import { WorkersComponent } from './workers/workers.component';
import { PrintReportComponent } from '../core/reports/print-report/print-report.component';
import { StockAvailabilityComponent } from './stock-availability/stock-availability.component';
import { PackingTypesComponent } from './packing-types/packing-types.component';
import { ContractorsRoutesComponent } from './contractors-routes/contractors-routes.component';
import { CustomersGuard, EDocumentsGuard, isPermission } from '../auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PanelComponent,
  },
  {
    path: 'order-type',
    component: OrderTypeComponent,
    canActivate: [isPermission],
    data: { permissions: ['SerwisTypZlecenie'] },
  },
  {
    path: 'finance-operation',
    component: FinanceOperationComponent,
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['Artyk'] },
  },
  {
    path: 'customers',
    component: ContractorsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['Kontr'] },
  },
  {
    path: 'customers/add',
    component: ContractorsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['Kontr', 'DKontr'] },
  },
  {
    path: 'customers/:customerId/:mode',
    component: ContractorsComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission, CustomersGuard],
    data: { permissions: ['Kontr'] },
  },
  {
    path: 'customer-classifications',
    component: ClasificationComponent,
    canActivate: [isPermission],
    data: { permissions: ['TypyKontr'] },
  },
  {
    path: 'customer-groups',
    component: CustomerGroupsComponent,
    canActivate: [isPermission],
    data: { permissions: ['GrupCen'] },
  },
  {
    path: 'banks',
    component: BankListComponent,
    canActivate: [isPermission],
    data: { permissions: ['Banki'] },
  },
  {
    path: 'delivery',
    component: DeliveryComponent,
    canActivate: [isPermission],
    data: { permissions: ['FormyDostawy'] },
  },
  {
    path: 'interest',
    component: InterestComponent,
    canActivate: [isPermission],
    data: { permissions: ['TabOds'] },
  },
  {
    path: 'currency',
    component: CurrencyBoxComponent,
    canActivate: [isPermission],
    data: { permissions: ['Waluty'] },
  },
  {
    path: 'employees',
    component: WorkersComponent,
    canActivate: [isPermission],
    data: { permissions: ['Prac'] },
  },
  {
    path: 'payment-forms',
    component: FormsPaymentComponent,
    canActivate: [isPermission],
    data: { permissions: ['FormPlat'] },
  },
  {
    path: 'warehouse-select',
    component: WarehouseListComponent,
  },
  {
    path: 'warehouse-select/:WarehouseId/:mode',
    component: WarehouseListComponent,
  },
  {
    path: 'warehouse-select/add',
    component: WarehouseListComponent,
  },
  {
    path: 'products-categories',
    component: CategoriesComponent,
    canActivate: [isPermission],
    data: { permissions: ['KatAsort'] },
  },
  {
    path: 'prices',
    component: PricesComponent,
    canActivate: [isPermission],
    data: { permissions: ['Ceny'] },
  },
  {
    path: 'size-units',
    component: SizeUnitsComponent,
    canActivate: [isPermission],
    data: { permissions: ['JedMiar'] },
  },
  {
    path: 'vat-prices',
    component: TaxRatesComponent,
    canActivate: [isPermission],
    data: { permissions: ['StawkiVAT'] },
  },
  {
    path: 'categories-tree',
    component: ArticlesCategoryTreeComponent,
    canActivate: [isPermission],
    data: { permissions: ['KatAsortTree'] },
  },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [isPermission],
    data: { permissions: ['LokalizMagazynu'] },
  },
  {
    path: 'packaging-types',
    component: PackingTypesComponent,
  },
  {
    path: 'identity-masks',
    component: IdentityMasksComponent,
  },
  {
    path: 'routes',
    component: ContractorsRoutesComponent,
    canActivate: [isPermission],
    data: { permissions: ['Trasy'] },
  },
  {
    path: 'cash-boxes',
    component: CashBoxesComponent,
    canActivate: [isPermission],
    data: { permissions: ['Kasy'] },
  },
  {
    path: 'crm-calendars',
    component: CalendarsComponent,
    canActivate: [isPermission],
    data: { permissions: ['CrmKalendarzS'] },
  },
  {
    path: 'task-types',
    component: TaskTypesComponent,
    canActivate: [isPermission],
    data: { permissions: ['CrmTyp'] },
  },
  {
    path: 'services-groups',
    component: GroupsDeviceComponent,
    canActivate: [isPermission],
    data: { permissions: ['SerwisGrupa'] },
  },
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [isPermission],
    data: { permissions: ['SerwisUrzadzenie'] },
  },

  {
    path: 'role-customer',
    component: RoleCustomerComponent,
  },
  {
    path: 'stock-and-reservations',
    component: ArticlesComponent,
    canActivate: [isPermission],
    data: { mode: 'stockAndReservations', permissions: ['RapObrStanMag'] },
  },
  {
    path: 'orders-and-reservations',
    component: ArticlesComponent,
    canActivate: [isPermission],
    data: { mode: 'ordersAndReservations', permissions: ['RapStanZamRez'] },
  },
  {
    path: 'offices',
    component: OfficesComponent,
    canActivate: [isPermission],
    data: { permissions: ['Urzedy'] },
  },
  {
    path: 'e-documents',
    component: EDokumentsComponent,
    canActivate: [isPermission],
    data: { permissions: ['eDokDokSystem'] },
  },
  {
    path: 'e-documents/:DocumentCode',
    component: EDokumentsComponent,
    canActivate: [isPermission, EDocumentsGuard],
    data: { permissions: ['eDokDokSystem'] },
  },
  {
    path: 'vehicles',
    component: VehiclesComponent,
  },
  {
    path: 'statements',
    component: PrintReportComponent,
    canActivate: [isPermission],
    data: { permissions: ['RapWydrZest'] },
  },
  {
    path: 'stock-availability',
    component: StockAvailabilityComponent,
    canActivate: [isPermission],
    data: { permissions: ['RapAnalPlynnosci'] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class routing {}
