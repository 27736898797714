<!-- popup -->
<div *ngIf="isPopupMode" style="display: inline-block; width: 115px">
  <dx-popup
    [width]="windowWidth"
    height="900"
    position="bottom"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    title="{{ 'Lista pojazdów' | translate }}"
    [(visible)]="isVisible"
    (visibleChange)="visibleChangePopUp($event)"
    (onShown)="onPopupShown()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          *ngIf="isSimpleView"
          [text]="'Otwórz pełną kartotekę' | translate"
          type="normal"
          style="margin-right: 6px; height: 26px;"
          [id]="'sendBuffer' + unicalGuid"
          (click)="changeSimpleView()"
          class="outline-secondary"
          stylingMode="outlined"
        ></dx-button>
        <dx-button
          *ngIf="isChoose"
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          [disabled]="selectedRows.length == 0 || readOnly"
          data-cy="documents_chose_button"
          (onClick)="chooseVehicles()"
          [id]="'btn-choose' + unicalGuid"
          style="margin-right: 6px;"
        ></dx-button>
        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          data-cy="documents_close_button"
          (onClick)="onPopupClose()"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.close" | translate }} (Esc)
          </div>
        </dx-tooltip>
        <dx-tooltip
          [target]="'#btn-choose' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.choose" | translate }} (Enter)
          </div>
        </dx-tooltip>
      </div>
      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="btn"></ng-container>
      </div>
      <br />

      <div
        class="center-panel-scroll-verticall"
        style="overflow: hidden"
        [ngStyle]="{ 'height.px': componentHeight - 125 }"
      >
        <div class="row">
          <div class="col-md-12" style="padding-top: 1px">
            <ng-container *ngTemplateOutlet="grid"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </dx-popup>
</div>

<!-- no popup-->
<div *ngIf="!isPopupMode">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="btn"></ng-container>
  </div>
  <div class="center-panel-scroll-verticall">
    <div class="row">
      <div class="col-md-12">
            <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #filter>
  <div class="rows" style="display: block">
    <app-custom-dropdown-box
      [openDropDown]="openCustomDropDown"
      #customSearch
      [items]="filterCriteria"
      [(selectedItem)]="valueCriteria"
      [(filterValue)]="filterValue"
      style="margin-right: 10px; margin-left: 0px"
    >
    </app-custom-dropdown-box>
    <app-custom-chips-button
      [name]="'Marka' | translate"
      [list]="filterMarkList"
      [selectedValueInput]="markFilter"
      labelExpr="label"
      valueExpr="id"
      #markFilterVC
    >
    </app-custom-chips-button>
    <app-custom-chips-button
      [name]="'Rok produkcji' | translate"
      [list]="filterYearList"
      [selectedValueInput]="yearFilter"
      labelExpr="label"
      valueExpr="id"
      #yearFilterVC
    >
    </app-custom-chips-button>
    <app-custom-chips-button
      [name]="'Sposób użytkowania' | translate"
      [list]="filterUseList"
      [selectedValueInput]="useFilter"
      labelExpr="label"
      valueExpr="id"
      #useFilterVC
    >
    </app-custom-chips-button>
    <dx-button
      [id]="'clearValues' + unicalGuid"
      class="btn-filter-box"
      icon="icon absui-icon--clear-filter"
      (onClick)="clearFilters()"
      data-cy="jpk-clear-date-range"
    ></dx-button>
    <dx-tooltip
      [target]="'#clearValues' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "clearAllFilters" | translate }} (Ctrl + F10)
      </div>
    </dx-tooltip>
  </div>
</ng-template>

<ng-template #btn>
  <div style="display: inline-block">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAll]="isAllSelected"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      *ngIf="!isSimpleView"
      class="btn-dx-top-menu-folder"
      stylingMode="text"
      type="default"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [disabled]="readOnly"
      (onClick)="addBtn()"
    >
    </dx-button>

    <dx-button
      *ngIf="!isSimpleView"
      icon="icon absui-icon--mode-edit"
      [id]="'edit' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="focusedSelected.length == 0 || readOnly"
      text="{{ 'buttons.edit' | translate }}"
      (onClick)="isEdit()"
    >
    </dx-button>

    <dx-button
      *ngIf="!isSimpleView"
      icon="icon absui-icon--highlight-off"
      [id]="'btn-delete' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="focusedSelected.length == 0 || readOnly"
      [text]="'buttons.delete' | translate"
      (onClick)="isDelete()"
    >
    </dx-button>
  </div>

  <div style="display: inline-block; position: relative"></div>

  <div class="right-header-btn d-flex">
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon dx-icon-icon-refresh"
      (onClick)="refreshData()"
      [id]="'btn-refresh' + unicalGuid"
    ></dx-button>
    <dx-tooltip
      [target]="'#edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>
    <dx-tooltip
      [target]="'#btn-refresh' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "refresh" | translate }}
      </div>
    </dx-tooltip>
  </div>

  <dx-tooltip
    [target]="'#btn-add' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.add" | translate }} (Insert)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#btn-delete' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.delete" | translate }} (Del)
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12" style="display: flex">
      <ng-container *ngTemplateOutlet="filter"></ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            data-cy="vehicles_grid"
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [height]="heightGrid"
            [(selectedRowKeys)]="selectedRows"
            [autoNavigateToFocusedRow]="true"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            [id]="'gridDoc' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            [focusedRowEnabled]="true"
            style="margin-right: 14px"
            #gridClass
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              [mode]="'multiple'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption="Numer rejestracyjny"
              [allowSorting]="false"
              dataField="Numerrejestracyjny"
              alignment="left"
              cellTemplate="registrationTemplate"
            ></dxi-column>
            <dxi-column
              caption="Marka"
              [allowSorting]="false"
              dataField="Marka"
              alignment="left"
              cellTemplate="makeModelTemplate"
            ></dxi-column>
            <dxi-column
              caption="Rok produkcji"
              [allowSorting]="false"
              dataField="Rokprodukcji"
              alignment="left"
            ></dxi-column>
            <dxi-column
              caption="Sposób użytkowania"
              [allowSorting]="false"
              dataField="Sposobuzytkowania"
              alignment="left"
              cellTemplate="useMethodTemplate"
            ></dxi-column>
            <dxi-column
              caption="Odlicz. VAT"
              [allowSorting]="false"
              dataField="OdliczVAT"
              alignment="left"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="Leasing"
              [allowSorting]="false"
              dataField="Leasing"
              alignment="center"
              cellTemplate="checkboxTemplate"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="Wartość leasing"
              [allowSorting]="false"
              dataField="WLeasing"
              alignment="right"
              cellTemplate="numberPrecisionTemplate"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="Wartość ubezpiecz."
              [allowSorting]="false"
              dataField="WUbez"
              alignment="right"
              cellTemplate="numberPrecisionTemplate"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="VAT-26 data nabycia"
              [allowSorting]="false"
              dataField="V26Nab"
              alignment="left"
              cellTemplate="dateTemplate"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="VAT-26 data wydatku"
              [allowSorting]="false"
              dataField="V26Wyda"
              alignment="left"
              cellTemplate="dateTemplate"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="VAT-26 data zmiany"
              [allowSorting]="false"
              dataField="V26Zm"
              alignment="left"
              cellTemplate="dateTemplate"
            ></dxi-column>
            <dxi-column
              *ngIf="!isSimpleView"
              caption="Uwagi"
              [allowSorting]="false"
              dataField="Uwagi"
              alignment="left"
            ></dxi-column>

            <div *dxTemplate="let data of 'dateTemplate'">
              {{
                data.value
                  | date : "yyyy-MM-dd" : translate.currentLang || "pl"
              }}
            </div>        
            <div *dxTemplate="let data of 'numberPrecisionTemplate'">
              {{ data.value | amountFormatter : 2 }}
            </div>
            <div *dxTemplate="let data of 'makeModelTemplate'">
              <b>{{ data.value }}</b><br>{{ data.data.Model }}
            </div>
            <div *dxTemplate="let data of 'useMethodTemplate'">
              <app-status-label
                [text]="data.value"
                [color]="textColor[data.data.SposobuzytkowaniaId]"
                [backgroundColor]="backgroundColor[data.data.SposobuzytkowaniaId]"
              >
              </app-status-label>
            </div>
            <div *dxTemplate="let data of 'registrationTemplate'">
              <span 
                *ngIf="isSimpleView"
                class="regLab">{{ data.value }}</span>
              <span 
                *ngIf="!isSimpleView"
                class="jpk-from-read hov-underline">{{
                data.value
              }}</span>
            </div>
            <div *dxTemplate="let data of 'checkboxTemplate'">
              <div *ngIf="data.value" class="icon absui-icon--check2"></div>
            </div>
            
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="bottom-side-panel"
      mwlResizable
      #bottomSidePanel
      (resizeStart)="onResizeStartBottom()"
      (resizeEnd)="resizeEndBottom()"
      (resizing)="resizingBottom($event)"
    >
      <div
        class="resize-handle-top"
        mwlResizeHandle
        [resizeEdges]="{ top: true }"
        (mouseleave)="mouseLeaveDown()"
        (mouseenter)="mouseEnterDown()"
      ></div>
      <div class="col-md-12 text-right resize-bottop-panel">
        <i
          class="icon bottom-panel-icon"
          mwlResizeHandle
          [resizeEdges]="{ top: true }"
          [ngClass]="{
            'absui-icon--arrow-drop-down bottom-icon-resize-down':
              showBottomPanel,
            'absui-icon--arrow-drop-up bottom-icon-resize-up': !showBottomPanel
          }"
          (mousedown)="mousedown()"
          (mouseup)="mouseup()"
          (touchend)="mouseup()"
          (touchstart)="mousedown()"
        ></i>

        <dx-tab-panel
          #tabPanel
          width="100%"
          [items]="[
            'Historia pojazdu' | translate
          ]"
          [(selectedIndex)]="selectedtabIndex"
          [animationEnabled]="false"
          [swipeEnabled]="false"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div
                *ngSwitchCase="'Historia pojazdu' | translate"
                class="text-left"
              >
                <ng-container *ngTemplateOutlet="history"></ng-container>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #history>
  <dx-data-grid
    data-cy="vehicles_history_grid"
    [dataSource]="dataSourceHistory"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    shortcut
    [height]="200"
    [autoNavigateToFocusedRow]="true"
    [columnResizingMode]="'widget'"
    [remoteOperations]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [id]="'gridDocHistory' + unicalGuid"
    [(focusedRowIndex)]="focusedHistoryRowIndex"
    [focusedRowEnabled]="true"
    style="margin-right: 14px"
    #gridHistoryClass
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-selection
      [mode]="'multiple'"
      showCheckBoxesMode="always"
      selectAllMode="page"
    >
    </dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxi-column
      caption="Data zmiany"
      [allowSorting]="false"
      dataField="Datazmiany"
      alignment="left"
    ></dxi-column>
    <dxi-column
      caption="Sposób użytkowania"
      [allowSorting]="false"
      dataField="Sposobuzytkowania"
      alignment="left"
      cellTemplate="useMethodTemplate"
    ></dxi-column>
    <dxi-column
      caption="Odlicz. VAT"
      [allowSorting]="false"
      dataField="OdliczVAT"
      alignment="left"
    ></dxi-column>
    <dxi-column
      caption="Leasing"
      [allowSorting]="false"
      dataField="Leasing"
      alignment="center"
      cellTemplate="checkboxTemplate"
    ></dxi-column>
    <dxi-column
      caption="Wartość leasing"
      [allowSorting]="false"
      dataField="WLeasing"
      alignment="right"
      cellTemplate="numberPrecisionTemplate"
    ></dxi-column>
    <dxi-column
      caption="Wartość ubezpiecz."
      [allowSorting]="false"
      dataField="WUbez"
      alignment="right"
      cellTemplate="numberPrecisionTemplate"
    ></dxi-column>
    <dxi-column
      caption="VAT-26 data nabycia"
      [allowSorting]="false"
      dataField="V26Nab"
      alignment="left"
      cellTemplate="dateTemplate"
    ></dxi-column>
    <dxi-column
      caption="VAT-26 data wydatku"
      [allowSorting]="false"
      dataField="V26Wyda"
      alignment="left"
      cellTemplate="dateTemplate"
    ></dxi-column>

    <div *dxTemplate="let data of 'dateTemplate'">
      {{ data.value | date : "yyyy-MM-dd" : translate.currentLang || "pl" }}
    </div>        
    <div *dxTemplate="let data of 'numberPrecisionTemplate'">
      {{ data.value | amountFormatter : 2 }}
    </div>
    <div *dxTemplate="let data of 'useMethodTemplate'">
      <app-status-label
        [text]="data.value"
        [color]="textColor[data.data.SposobuzytkowaniaId]"
        [backgroundColor]="backgroundColor[data.data.SposobuzytkowaniaId]"
      >
      </app-status-label>
    </div>
    <div *dxTemplate="let data of 'checkboxTemplate'">
      <div *ngIf="data.value" class="icon absui-icon--check2"></div>
    </div>
  </dx-data-grid>
</ng-template>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="readOnly || (isPopupMode && !isVisible)"
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<app-confirm-dialog
  confirmText="confirmDeleteMultiple"
  [isVisible]="isDeleteSelected"
  (onRemoving)="deleteSelected()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<dx-context-menu
  [dataSource]="contextMenu"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
>
</dx-context-menu>

<app-add-vehicles
  [title]="'Dodawanie pojazdu'"
  [isVisible]="isVisibleAddVehicle"
  [mode]="formAddMode"
  (onClosed)="isVisibleAddVehicle = false"
>
</app-add-vehicles>
