import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { AppServices } from 'src/app/app-services.service';
import { DynamicFilterService } from 'src/app/core/dynamic-filter.service';
import { SetupWizardConfig } from 'src/app/core/setup-wizard/ISetupWizardConfig';
import { SingleRecordMode } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { InterconnectorService } from 'src/app/interconnector.service';
import {
  CollectorModel,
  CollectorTransferConfig,
  TransferPositionParams,
} from './data-collector.model';

@Component({
    selector: 'app-data-collector',
    templateUrl: './data-collector.component.html',
    styleUrls: ['./data-collector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DataCollectorComponent {
  @Input() visible: boolean = false;
  @Input() fullScreen: boolean = false;
  @Input() isPopupMode: boolean = true;
  @Input() dropDownBoxMode: boolean = false;
  @Input('transferConfig') transferConfigInput: CollectorTransferConfig;
  @Output() onPopupClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSend: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('transmissionGrid') transmissionGrid;
  @ViewChild('positionsGrid') positionsGrid;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('dropdownbtn') dropdownbtn;

  loadChildComponent: boolean = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  readOnly: boolean = false;
  perABD = {
    addBtn: true,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  perAbdExtended = {
    transferInBtn: false,
  };
  readyToSend: boolean = false;
  // valueCriteria = '';
  // filterValue = '';
  // filterCriteria = [
  //   { value: 'CollectorNumber', label: 'po numerze' },
  //   { value: 'date', label: 'wg daty' },
  //   { value: 'time', label: 'wg godziny' },
  // ];
  positionFilterValue = null;
  positionFilterCriteria = [
    { value: 'ProductName', label: 'Po nazwie artykułu' },
    { value: 'CodePLU', label: 'po kodzie PLU' },
    { value: 'EAN', label: 'po EAN' },
    { value: 'IndexCatalogue', label: 'po indeksie katalogowym' },
    { value: 'IndexTrading', label: 'po indeksie handlowym' },
  ];
  proceededFilterValue: string = '';
  proceededFilterLabel: string = '';
  proceededFilterList = [
    { value: 'proceeded', label: 'Tylko rozliczone' },
    { value: 'nonProceeded', label: 'Tylko nierozliczone' },
    { value: 'partiallyProceeded', label: 'Częściowo rozliczone' },
  ];
  positionOrderBy: string = 'ProductName'; // asc/desc
  positionOrder: string = 'ASC'; // asc/desc
  width = 280;
  viewMode: string = 'flexColumns';
  localStorageData: any = {
    advancedFilter: null,
    filter: {
      value: '',
      orderBy: '',
      order: '',
    },
    tree: [],
    columns: [],
    mainPanel: {
      height: 'calc(100% - 34px)',
      width: 'calc(100% - 21px)',
    },
    bottomPanel: {
      isVisible: false,
      height: 34,
      selectedIndex: 0,
    },
    sidePanel: {
      width: '21px',
      isVisible: false,
      selectedIndex: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: '90%',
      height: '90%',
    },
    params: [
      // {
      //   methodName: 'getTransmissions',
      //   apiUri: '/device/collector',
      //   collectorTransferId: null,
      //   skip: 0,
      //   take: 100,
      // },
      // {
      //   methodName: 'getCollectorPositions',
      //   apiUri: '/device/collector/positions',
      //   collectorTransferId: null,
      //   collectorPositionId: null,
      //   skip: 0,
      //   take: 100,
      // },
    ],
  };
  dataSource: any = [];
  collectorStates: Array<any> = [
    {
      state: 0,
      label: 'N',
      tooltip: 'Nierozliczona',
      className: 'bg-accent-red',
    },
    {
      state: 1,
      label: 'C',
      tooltip: 'Częściowo rozliczona',
      className: 'bg-accent-orange',
    },
    {
      state: 2,
      label: 'R',
      tooltip: 'Rozliczona',
      className: 'bg-accent-green',
    },
  ];
  collectorTypes: CollectorModel[];
  emptyStateHeight;
  maxHeight: number;
  focusedCollectorTransferId: number = null;
  selectedCollectors: Array<any> = [];
  isCollectorFocusedSelected: boolean;
  isdeleteTransmissionVisible: boolean = false;
  isDeleteCollectorPositionVisible: boolean = false;
  isCollectorChoiceVisible: boolean = false;
  dataSourcePositions: any[] = [];
  focusedCollectorPositionKey: number = null;
  selectedCollectorPositions: Array<any> = [];
  transmissionsTab = ['Transmisje'];
  positionsTab = ['Pozycje kolektora'];
  bottomPanelTabs = ['Pozycje kolektora'];
  isAddPositionVisible: boolean = false;
  isEditPositionVisible: boolean = false;
  isShowArticleVisible: boolean = false;
  articleMode;
  articlePermission = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  hasPermissionToEditArticles: boolean = false;
  positionPopupMode: SingleRecordMode = 'show';
  deleteTransmissionConfirmText: string =
    'dataCollectors.deleteConfirmText.singleCollector';
  deleteCollectorPositionConfirmText: string =
    'dataCollectors.deletePositionConfirmText.singlePosition';
  positionsContextMenu: any[] = [
    {
      text: 'Popraw pozycję',
      icon: 'icon absui-icon--mode-edit',
      itemIndex: 0,
    },
    {
      text: 'Usuń pozycję',
      icon: 'icon absui-icon--highlight-off',
      itemIndex: 1,
    },
    {
      text: 'Zaznacz wszystko',
      icon: 'icon absui-icon--checkbox-all',
      itemIndex: 2,
    },
    {
      text: 'Odznacz wszystko',
      icon: 'icon absui-icon--deselct-all',
      itemIndex: 3,
    },
    // {
    //   text: 'Odwróć zaznaczone',
    //   icon: 'icon absui-icon--checkbox-arrowback',
    //   itemIndex: 4,
    // },
    {
      text: 'Przywróć pozycję',
      itemIndex: 5,
    },
    {
      text: 'Rozłącz artykuł',
      itemIndex: 6,
    },
    {
      text: 'Skojarz z artykułem',
      itemIndex: 7,
    },
    {
      text: 'Dodaj artykuł',
      itemIndex: 8,
    },
    {
      text: 'Podgląd pozycji asortymentowej',
      itemIndex: 9,
    },
    {
      text: 'Pokaż pozycje',
      itemIndex: 10,
      items: [
        {
          text: 'Wszystkie',
          itemIndex: 101,
        },
        {
          text: 'Tylko rozliczone',
          itemIndex: 102,
        },
        {
          text: 'Tylko nierozliczone',
          itemIndex: 103,
        },
        {
          text: 'Częściowo rozliczone',
          itemIndex: 104,
        },
      ],
    },
  ];
  isResizing: boolean;
  shortcuts: ShortcutInput[] = [];
  collectorGridContextMenu: any[] = [
    { text: 'Nowa transmisja', icon: 'icon absui-icon--play', itemIndex: 0 },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 1 },
    {
      text: 'Prześlij na dokument',
      icon: 'icon absui-icon--file-out',
      itemIndex: 2,
    },
    {
      text: 'Zaznacz wszystko',
      icon: 'icon absui-icon--checkbox-all',
      itemIndex: 3,
    },
    {
      text: 'Odznacz wszystko',
      icon: 'icon absui-icon--deselct-all',
      itemIndex: 4,
    },
    // {
    //   text: 'Odwróć zaznaczone',
    //   icon: 'icon absui-icon--checkbox-arrowback',
    //   itemIndex: 5,
    // },
  ];

  dropDownOptions = {
    width: 350,
    minWidth: 350,
  };
  transferConfig: CollectorTransferConfig = {
    CustomerId: null,
    CollectorTransferId: null,
    DocumentId: null,
    DocumentType: null,
    Date: null,
    FlushCollectorAfter: false,
    TakePricesFromCollector: false,
    TakeDefaultUnit: false,
    IgnoreBlocked: false,
    IsForService: false,
    CurrencyPrice: false,
  };
  transmissionParams = [
    {
      label: 'dataCollectors.TakePricesFromCollector',
      name: 'TakePricesFromCollector',
    },
    {
      label: 'dataCollectors.FlushCollectorAfter',
      name: 'FlushCollectorAfter',
    },
    { label: 'dataCollectors.IgnoreBlocked', name: 'IgnoreBlocked' },
    { label: 'dataCollectors.TakeDefaultUnit', name: 'TakeDefaultUnit' },
  ];

  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'PRODUCT';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Artykul';
  viewUserDefinitionSub: Subscription = null;
  withoutComma: boolean;
  pinnedViewsSub: Subscription = null;
  firstRun: boolean = true;
  isSetupWizardVisible: boolean;
  connectionErrorNotification: string;
  connectionErrorNotificationHeader: string;
  setupWizardConfig: SetupWizardConfig = {
    headerText: 'dataCollector.setupWizard.headerText',
    bodyText: 'dataCollector.setupWizard.bodyText',
    buttons: [
      { type: 'success', text: 'fiscalPrinter.setupWizard.buttons.primary' },
      { type: 'danger', text: 'fiscalPrinter.setupWizard.buttons.secondary' },
    ],
    footerItems: [
      {
        icon: 'icon absui-icon--help',
        text: 'fiscalPrinter.setupWizard.footerText',
        type: 'link',
      },
    ],
  };
  setupWizardShown: boolean = false;

  constructor(
    private appService: AppServices,
    private cd: ChangeDetectorRef,
    private dynamicFilterService: DynamicFilterService,
    private interconnector: InterconnectorService,
    public event: EventService,
    public translate: TranslateService
  ) {
    this.maxHeight = this.event.setMaxHeight;

    this.getTranslations();
    this.getPermissions();
    this.getUserParameters();
  }

  getUserParameters() {
    const withoutComma = this.event.getConfigurationParameter(
      'IloscArtBezPrzecinka'
    );

    if (withoutComma) {
      this.withoutComma = withoutComma.Value === 'Tak';
    }
  }

  getTranslations() {
    this.translate
      .get('dataCollectors.contextMenu.editPosition')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 0);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.deletePosition')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 1);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.selectAll')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 2);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.unselectAll')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 3);
        this.positionsContextMenu[i].text = text;
      });

    // this.translate
    //   .get('dataCollectors.contextMenu.invertSelection')
    //   .subscribe((text) => {
    //     let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 4);
    //     this.positionsContextMenu[i].text = text;
    //   });

    this.translate
      .get('dataCollectors.contextMenu.restorePosition')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 5);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.dettachArticle')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 6);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.attachArticle')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 7);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.addArticle')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 8);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.showArticle')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 9);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.showPositions')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 10);
        this.positionsContextMenu[i].text = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.allPositions')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 10);
        this.positionsContextMenu[i].items[0].text = text;
        // this.proceededFilterList[0].label = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.proceeded')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 10);
        this.positionsContextMenu[i].items[1].text = text;
        this.proceededFilterList[0].label = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.nonProceeded')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 10);
        this.positionsContextMenu[i].items[2].text = text;
        this.proceededFilterList[1].label = text;
      });

    this.translate
      .get('dataCollectors.contextMenu.partiallyProceeded')
      .subscribe((text) => {
        let i = this.positionsContextMenu.findIndex((x) => x.itemIndex == 10);
        this.positionsContextMenu[i].items[3].text = text;
        this.proceededFilterList[2].label = text;
      });

    this.translate
      .get('dataCollectors.tabs.transmissions')
      .subscribe((text) => {
        this.transmissionsTab[0] = text;
      });

    this.translate.get('dataCollectors.tabs.positions').subscribe((text) => {
      this.positionsTab[0] = text;
    });
  }

  getPermissions() {
    if (this.event.checkPermissions('KolDan')) {
      this.perABD = {
        addBtn: true,
        editBtn: true,
        deleteBtn: true,
        showBtn: true,
      };
      this.perAbdExtended = {
        transferInBtn: true,
      };
    }
    this.articlePermission = this.event.checkPermissionsBtn(
      'DArtyk',
      'EArtyk',
      'UArtyk',
      null
    );
  }

  ngOnInit = () => {
    setTimeout(() => {
      if (!this.dropDownBoxMode && !this.isPopupMode) {
        //
      } else {
        this.cd.detectChanges();
      }
    });
    setTimeout(() => {
      this.loadChildComponent = true;

      this.pinnedViewsSub = this.dynamicFilterService
        .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
        .subscribe({
          next: (data) => {
            if (!data) {
              return;
            }
            if (this.localStorageData?.advancedFilter) {
              this.localStorageData.advancedFilter.PinnedFilters =
                data.pinnedFilters;
            } else {
              this.localStorageData.advancedFilter = {
                PinnedFilters: data.pinnedFilters,
              };
            }
          },
        });
    }, 1000);
  };

  onInit(e: any) {
    e.component.registerKeyHandler('escape', () => {
      if (!this.isEditPositionVisible) {
        this.onClose();
      }
    });
  }

  ngAfterViewInit = () => {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClose();
        },
        preventDefault: true,
      },
      {
        key: 'insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.chooseCollector();
        },
        preventDefault: true,
      },
      {
        key: 'delete',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.confirmDeletingCollector();
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.toggleDynamicFilters();
        },
        preventDefault: true,
      }
    );
  };

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.visible?.currentValue) {
      await this.getCollectorTypes();

      await this.getTransmissions();
      setTimeout(async () => {
        await this.setFocus();
        if (!this.firstRun) {
          this.getCollectorPositions();
        }
        this.firstRun = false;
      }, 1000);
      for (let param in this.transferConfigInput) {
        if (this.transferConfigInput[param] !== null)
          this.transferConfig[param] = this.transferConfigInput[param];
      }
    }
  }

  ngOnDestroy = () => {};

  setFocus() {
    return new Promise<void>((resolve) => {
      this.transmissionGrid.instance.focus();
      resolve();
    });
  }

  onPopupResize() {}

  toggleFullscreen = () => {
    this.localStorageData.windowSize.isFullscreen =
      !this.localStorageData.windowSize.isFullscreen;
  };

  onFullScreenChange = (e) => {
    this.localStorageData.windowSize.isFullscreen = e;
    if (e) this.emptyStateHeight = window.innerHeight;
    else this.emptyStateHeight = this.localStorageData.windowSize.height;
    if (this.emptyStateHeight == '90%')
      this.emptyStateHeight = window.innerHeight * 0.9;
  };

  onClose = () => {
    this.closePopup();
  };

  closePopup = () => {
    this.onPopupClose.emit(true);
  };

  chooseCollector = () => {
    this.isCollectorChoiceVisible = true;
  };

  onClosingCollectorChoice = (e) => {
    this.isCollectorChoiceVisible = e;
    setTimeout(() => {
      this.transmissionGrid.instance.focus();
      this.cd.detectChanges();
    }, 300);
  };

  confirmDeletingCollector = () => {
    if (this.selectedCollectors.length > 1) {
      this.deleteTransmissionConfirmText =
        'dataCollectors.deleteConfirmText.manyCollectors';
    } else {
      this.deleteTransmissionConfirmText =
        'dataCollectors.deleteConfirmText.singleCollector';
    }
    this.isdeleteTransmissionVisible = true;
  };

  onClosingdeleteTransmission = () => {
    this.isdeleteTransmissionVisible = false;
  };

  onCancelingdeleteTransmission = () => {
    this.isdeleteTransmissionVisible = false;
  };

  onRemovingCollector = () => {
    let toDelete =
      this.selectedCollectors.length > 0
        ? [...this.selectedCollectors]
        : [this.focusedCollectorTransferId];

    this.isdeleteTransmissionVisible = false;
    if (toDelete.length > 1) {
      this.deleteTransmissions(toDelete);
    } else {
      this.deleteTransmission(toDelete[0]);
    }

    this.isdeleteTransmissionVisible = false;
  };

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'dataCollector',
          JSON.stringify(this.localStorageData)
        );
        this.getTransmissions();
      }
    })
  }

  confirmDeletingPosition() {
    if (this.selectedCollectorPositions.length > 1) {
      this.deleteCollectorPositionConfirmText =
        'dataCollectors.deleteConfirmText.manyPositions';
    } else {
      this.deleteCollectorPositionConfirmText =
        'dataCollectors.deleteConfirmText.singlePosition';
    }
    this.isDeleteCollectorPositionVisible = true;
  }

  onClosingDeleteCollectorPosition = () => {
    this.isDeleteCollectorPositionVisible = false;
  };

  onCancelingDeleteCollectorPosition = () => {
    this.isDeleteCollectorPositionVisible = false;
  };

  onRemovingCollectorPosition = () => {
    let positionsToDelete =
      this.selectedCollectorPositions.length > 0
        ? [...this.selectedCollectorPositions]
        : [this.focusedCollectorPositionKey];

    this.isDeleteCollectorPositionVisible = false;
    if (positionsToDelete.length > 1) {
      this.deleteCollectorPositions({
        collectorTransferId: this.focusedCollectorTransferId,
        collectorPositionId: positionsToDelete,
      });
    } else {
      this.deleteCollectorPosition({
        collectorTransferId: this.focusedCollectorTransferId,
        collectorPositionId: positionsToDelete[0],
      });
    }
    this.refresh();
    this.isDeleteCollectorPositionVisible = false;
  };

  onSending() {
    this.sendCollectorPositions();
  }

  onReadingCollectorChoice = (e) => {
    this.refresh();
    setTimeout(() => {
      this.transmissionGrid.instance.focus(e[0].CollectorTransferId);
      this.focusedCollectorTransferId = e[0].CollectorTransferId;
    }, 100);
  };

  getCollectorTypes = () => {
    return new Promise((resolve) => {
      this.appService.getAuth('device/collector/types').subscribe({
        next: (res) => {
          this.collectorTypes = res.data;
          this.cd.detectChanges();
          resolve(true);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          resolve(false);
        },
      });
    });
  };

  getTransmissions = () => {
    return new Promise((resolve) => {
      this.appService.getAuth('device/collector/transfer').subscribe({
        next: (res) => {
          this.dataSource = res.data;
          for (let transmission of this.dataSource) {
            let type = this.collectorTypes.find(
              (type) => type.TypeId === transmission.CollectorType
            );
            transmission.TypeDescription = type?.Description || null;
          }
          this.cd.detectChanges();
          resolve(true);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          resolve(false);
        },
      });
    });
  };

  deleteTransmission = (collectorTransferId) => {
    this.appService
      .deleteAuth(`device/collector/transfer/${collectorTransferId}`)
      .subscribe({
        next: () => {
          this.refresh();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  };

  deleteTransmissions = (transfersArr) => {
    if (transfersArr.length > 1) {
      let transferToDelete = transfersArr.pop();
      this.appService
        .deleteAuth(`device/collector/transfer/${transferToDelete}`)
        .subscribe({
          next: () => {
            this.deleteTransmissions(transfersArr);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    } else {
      this.deleteTransmission(transfersArr[0]);
    }
  };

  getProceededFilterValue() {}

  getParams() {
    let params: TransferPositionParams = {
      collectorTransferId: null,
      Skip: 0,
      Take: 100,
      Order: this.positionOrder,
      OrderBy: this.positionOrderBy,
      TotallyProceed: false,
      NonProceed: false,
      PartiallyProceed: false,
    };

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        params,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    switch (this.positionOrderBy) {
      case 'ProductName':
        if (params.ProductName) params.ProductName = this.positionFilterValue;
        break;
      case 'CodePLU':
        if (params.CodePLU) params.CodePLU = this.positionFilterValue;
        break;
      case 'EAN':
        if (params.EAN) params.EAN = this.positionFilterValue;
        break;
      case 'IndexCatalogue':
        if (params.IndexCatalogue)
          params.IndexCatalogue = this.positionFilterValue;
        break;
      case 'IndexTrading':
        if (params.IndexTrading) params.IndexTrading = this.positionFilterValue;
        break;
      case 'CollectorPositionId':
        if (params.CollectorPositionId)
          params.CollectorPositionId = Number(
            this.localStorageData.filter.value
          );
        break;
    }

    switch (this.proceededFilterValue) {
      case 'proceeded':
        params.TotallyProceed = true;
        break;
      case 'nonProceeded':
        params.NonProceed = true;
        break;
      case 'partiallyProceeded':
        params.PartiallyProceed = true;
        break;
      default:
    }

    return params;
  }

  getCollectorPositions(collectorTransferId?) {
    let params = this.getParams();

    if (collectorTransferId) params.collectorTransferId = collectorTransferId;
    else params.collectorTransferId = this.focusedCollectorTransferId;

    if (params.collectorTransferId !== null) {
      this.appService
        .getAuth(
          `device/collector/transfer/${params.collectorTransferId}/positions`,
          params
        )
        .subscribe({
          next: (res) => {
            this.dataSourcePositions = res.data;
            this.cd.detectChanges();
            this.positionsGrid.instance.refresh();
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    } else {
      this.dataSourcePositions = [];
      this.cd.detectChanges();
      this.positionsGrid.instance.refresh();
    }
  }

  deleteCollectorPosition = (position) => {
    this.appService
      .deleteAuth(
        `device/collector/transfer/${position.collectorTransferId}/positions/${position.collectorPositionId}?collectorTransferId=${position.collectorTransferId}&collectorPositionId=${position.collectorPositionId}`
      )
      .subscribe({
        next: () => {
          this.refresh();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  };

  deleteCollectorPositions = (position) => {
    //let positionToDelete =
    position.collectorPositionId.pop();
    this.appService
      .deleteAuth(
        `device/collector/transfer/${position.collectorTransferId}/positions/0`
      )
      .subscribe({
        next: () => {
          // this.deleteCollectorPositions(position);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  };

  // deleteCollectorPositions = (position) => {
  //   if (position.collectorPositionId.length > 1) {
  //     let positionToDelete = position.collectorPositionId.pop();
  //     this.appService
  //       .deleteAuth(
  //         `device/collector/transfer/${position.collectorTransferId}/positions/${positionToDelete}?collectorTransferId=${position.collectorTransferId}&collectorPositionId=${positionToDelete}`
  //       )
  //       .subscribe({
  //         next: (res) => {
  //           this.deleteCollectorPositions(position);
  //         },
  //         error: (err) => {
  //           err = JSON.parse(err);
  //           this.event.showNotification('error', err.detail);
  //         },
  //       });
  //   } else {
  //     this.deleteCollectorPosition({
  //       collectorPositionId: position.collectorPositionId[0],
  //       collectorTransferId: position.collectorTransferId,
  //     });
  //   }
  // };

  selectProduct = (idsArr: number[]) => {
    return new Promise((resolve) => {
      const obj = {
        ObjectIds: idsArr,
      };
      this.appService
        .postAuth(`products/selection/product/select`, obj)
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    });
  };

  addPosition = () => {
    return new Promise((resolve) => {
      this.appService
        .postAuth(
          `device/collector/transfer/${this.focusedCollectorTransferId}/positions/`,
          null
        )
        .subscribe({
          next: () => {
            this.getCollectorPositions();
            resolve(true);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
            resolve(false);
          },
        });
    });
  };

  sendCollectorPositions() {
    return new Promise(() => {
      this.transferConfig.CollectorTransferId = this.focusedCollectorTransferId;
      this.appService
        .postAuth(
          `device/collector/positions/transfer-document`,
          this.transferConfig
        )
        .subscribe({
          next: () => {
            this.closePopup();
            this.onSend.emit(true);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    });
  }

  refresh = (idToFocus?) => {
    return new Promise<void>((resolve) => {
      this.getTransmissions().then(() => {
        if (!idToFocus) this.transmissionGrid.instance.focus();
        this.getCollectorPositions();
        resolve();
      });
    });
  };

  collectorOnKeyDown = (e) => {
    if (e.event.key == 'Insert' && !this.isCollectorChoiceVisible) {
      this.chooseCollector();
      e.event.preventDefault();
    }

    if (e.event.key == 'Delete') {
      this.confirmDeletingCollector();
      e.event.preventDefault();
    }

    if (e.event.key == 'F8') {
      this.toggleDynamicFilters();
      e.event.preventDefault();
    }

    if (e.event.key == 'F10' && this.dataSourcePositions.length > 0) {
      this.onSending();
      e.event.preventDefault();
    }

    if (e.event.key == 'Escape') {
      this.onClose();
      e.event.preventDefault();
    }
  };

  skipSendSelectionEvent: boolean = false;
  collectorsSendRequestTimer;
  isAllCollectorsSelected: boolean = false;
  collectorsToDeselect: Array<number> = [];
  collectorsToSelect: Array<number> = [];
  onSelectionChanged = (e: SelectionChangedEvent) => {
    if (this.skipSendSelectionEvent) {
      this.skipSendSelectionEvent = false;
      return;
    }

    if (
      this.selectedCollectors.length == this.dataSource.length &&
      this.selectedCollectors.length > 0
    ) {
      this.selectCollectors();
      this.isAllCollectorsSelected = true;
    } else this.isAllCollectorsSelected = false;

    if (this.selectedCollectors.length == 0) {
      this.unselectAllCollectors();
    }

    if (this.isAllCollectorsSelected) return;

    this.collectorsToSelect.push(...e.currentSelectedRowKeys);
    this.collectorsToDeselect.push(...e.currentDeselectedRowKeys);

    this.collectorsToDeselect = this.removeDuplicates(
      this.collectorsToDeselect
    );

    if (this.collectorsSendRequestTimer) {
      clearTimeout(this.collectorsSendRequestTimer);
      this.collectorsSendRequestTimer = null;
    }

    if (!this.isAllCollectorsSelected && this.selectedCollectors.length > 0)
      this.setCollectorSelectionTimeout();
  };

  removeDuplicates = (array) => {
    const result = [];
    const blocker = {}; // zapobiaga duplikowaniu elementów
    for (const item of array) {
      if (blocker.hasOwnProperty(item)) {
        continue;
      }
      blocker[item] = true;
      result.push(item);
    }
    return result;
  };

  selectCollectors() {
    this.appService
      .postAuth(`device/selection/documents/select`, {
        ObjectIds: this.selectedCollectors,
      })
      .subscribe(() => {});
  }

  unselectAllCollectors() {
    this.appService
      .deleteAuth(`device/selection/documents`)
      .subscribe(() => {});
  }

  setCollectorSelectionTimeout() {
    this.collectorsSendRequestTimer = setTimeout(() => {
      if (!this.dropDownBoxMode) {
        if (this.collectorsToSelect.length) {
          this.collectorsToSelect = [];
          this.event.selectRecords(
            `device/selection/documents/select`,
            this.selectedCollectors
          );
        }
        if (this.collectorsToDeselect.length) {
          this.event.selectRecords(
            `device/selection/documents/unselect`,
            this.collectorsToDeselect
          );
          this.collectorsToDeselect = [];
        }
      }
      this.collectorsSendRequestTimer = null;
    }, 500);
  }

  positionsSendRequestTimer;
  isAllPositionsSelected: boolean = false;
  positionsToDeselect: Array<number> = [];
  positionsToSelect: Array<number> = [];
  onPositionsSelectionChanged = (e: SelectionChangedEvent) => {
    if (this.skipSendSelectionEvent) {
      this.skipSendSelectionEvent = false;
      return;
    }

    if (
      this.selectedCollectorPositions.length ==
        this.dataSourcePositions.length &&
      this.selectedCollectorPositions.length > 0
    ) {
      this.selectPositions();
      this.isAllPositionsSelected = true;
    } else this.isAllPositionsSelected = false;

    if (this.selectedCollectorPositions.length == 0) {
      this.unselectAllPositions();
    }

    if (this.isAllPositionsSelected) return;

    this.positionsToSelect.push(...e.currentSelectedRowKeys);
    this.positionsToDeselect.push(...e.currentDeselectedRowKeys);

    this.positionsToDeselect = this.removeDuplicates(this.positionsToDeselect);

    if (this.positionsSendRequestTimer) {
      clearTimeout(this.positionsSendRequestTimer);
      this.positionsSendRequestTimer = null;
    }

    if (
      !this.isAllPositionsSelected &&
      this.selectedCollectorPositions.length > 0
    )
      this.setPositionsSelectionTimeout();
    // this.cd.detectChanges();
  };

  selectPositions() {
    this.appService
      .postAuth(`device/selection/positions/select`, {
        ObjectIds: this.selectedCollectorPositions,
      })
      .subscribe(() => {});
  }

  unselectAllPositions() {
    this.appService
      .deleteAuth(`device/selection/positions`)
      .subscribe(() => {});
  }

  setPositionsSelectionTimeout() {
    this.positionsSendRequestTimer = setTimeout(() => {
      if (!this.dropDownBoxMode) {
        if (this.positionsToSelect.length) {
          this.positionsToSelect = [];
          this.event.selectRecords(
            `device/selection/positions/select`,
            this.selectedCollectorPositions
          );
        }
        if (this.positionsToDeselect.length) {
          this.event.selectRecords(
            `device/selection/positions/unselect`,
            this.positionsToDeselect
          );
          this.positionsToDeselect = [];
        }
      }
      this.positionsSendRequestTimer = null;
    }, 500);
  }

  onFocusedCollectorChanged = () => {
    this.getCollectorPositions();

    this.cd.detectChanges();
  };

  transmissionsContextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        this.chooseCollector();
        break;
      case 1:
        if (this.selectedCollectors.length < 2) {
          this.selectedCollectors = [this.focusedCollectorTransferId];
        }
        this.confirmDeletingCollector();
        break;
      case 2:
        this.onSending();
        break;
      case 3:
        this.transmissionGrid.instance.selectAll();
        break;
      case 4:
        this.transmissionGrid.instance.selectRows([], false);
        break;
      case 5:
        let selected = this.selectedCollectors;
        this.transmissionGrid.instance.selectAll();
        setTimeout(() => {
          selected.forEach((field) => {
            this.transmissionGrid.selectedRowKeys.forEach((element) => {
              if (field == element) {
                this.transmissionGrid.instance.deselectRows(element);
              }
            });
          });
        }, 100);
        break;
    }
  };

  readyToAttach = false;
  positionsContextMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        this.onEditingPosition();
        break;
      case 1:
        this.confirmDeletingPosition();
        break;
      case 2:
        this.positionsGrid.instance.selectAll();
        break;
      case 3:
        this.positionsGrid.instance.selectRows([], false);
        break;
      case 4:
        let selected = this.selectedCollectorPositions;
        this.positionsGrid.instance.selectAll();
        setTimeout(() => {
          selected.forEach((field) => {
            this.positionsGrid.selectedRowKeys.forEach((element) => {
              if (field == element) {
                this.positionsGrid.instance.deselectRows(element);
              }
            });
          });
        }, 100);
        break;
      case 5:
        this.restorePosition();
        break;
      case 6:
        this.detachPosition();
        break;
      case 7:
        this.isAddPositionVisible = true;
        this.readyToAttach = true;
        break;
      case 8:
        this.articleMode = 'add';
        this.isShowArticleVisible = true;
        break;
      case 9:
        this.showArticleAttached();
        break;
      case 101:
        this.setProceededFilterValue(null);
        break;
      case 102:
        this.setProceededFilterValue('proceeded');
        break;
      case 103:
        this.setProceededFilterValue('nonProceeded');
        break;
      case 104:
        this.setProceededFilterValue('partiallyProceeded');
        break;
    }

    setTimeout(() => {
      this.cd.detectChanges();
    }, 0);
  }

  setProceededFilterValue(value?) {
    this.proceededFilterValue = value;
    if (value)
      this.proceededFilterLabel = this.proceededFilterList.find(
        (item) => item.value === value
      ).label;
    this.getCollectorPositions();
    this.cd.detectChanges();
  }

  getUnitId = (code) => {
    return new Promise((resolve) => {
      this.appService.getAuth(`products/units`, { code: code }).subscribe({
        next: (res) => {
          resolve(res.data[0].UnitId);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
    });
  };

  restorePosition = async () => {
    let position = this.dataSourcePositions.find(
      (x) => x.CollectorPositionId === this.focusedCollectorPositionKey
    );
    const unitId = await this.getUnitId(position.Unit);
    const params = {
      Amount: position.Amount,
      AmountToProceed: position.Amount,
      CollectorTransferId: position.CollectorTransferId,
      CollectorPositionId: position.CollectorPositionId,
      ExpirationDate: position.ExpirationDate,
      SerialNumber: position.SerialNumber,
      UnitId: unitId,
    };
    this.appService
      .putAuth(
        `device/collector/transfer/${params.CollectorTransferId}/positions/${params.CollectorPositionId}`,
        params
      )
      .subscribe({
        next: () => {
          this.onPositionEdited();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  };

  onAddingPosition() {
    this.isAddPositionVisible = true;
  }

  onPositionChoosed(e) {
    if (e && this.readyToAttach) {
      this.attachPosition(e.ProductId);
      this.readyToAttach = false;
    } else if (e && !this.readyToAttach) {
      let toSelect: number[] = [];
      if (Array.isArray(e)) {
        e.forEach((prod) => toSelect.push(prod.ProductId));
      } else {
        toSelect = [e.ProductId];
      }

      setTimeout(() => {
        this.selectProduct(toSelect).then((res) => {
          if (res)
            this.addPosition().then(() => {
              this.event.deselectAllRecords('products/selection/product');
            });
        });
      }, 100);
    }

    this.isAddPositionVisible = false;
  }

  onEditingPosition() {
    this.positionPopupMode = 'edit';
    this.isEditPositionVisible = true;
  }
  onPositionEdited() {
    this.positionPopupMode = 'show';
    this.isEditPositionVisible = false;
    setTimeout(() => {
      this.refresh().then(() => {
        this.cd.detectChanges();
      });
    }, 100);
  }

  onPositionClosed() {
    this.positionPopupMode = 'show';
    this.isEditPositionVisible = false;
  }

  positionOnKeyDown = (e) => {
    if (e.event.key == 'Insert' && !this.isAddPositionVisible) {
      this.onAddingPosition();
      e.event.preventDefault();
    }

    if (e.event.key == 'F2' && !this.isEditPositionVisible) {
      this.onEditingPosition();
      e.event.preventDefault();
    }

    if (e.event.key == 'F8') {
      this.toggleDynamicFilters();
      e.event.preventDefault();
    }

    if (e.event.key == 'Delete') {
      this.confirmDeletingPosition();
      e.event.preventDefault();
    }

    if (e.event.key == 'Escape') {
      if (!this.isEditPositionVisible) {
        this.onClose();
      } else {
        this.isEditPositionVisible = false;
        this.cd.detectChanges();
      }
      e.event.preventDefault();
    }
  };

  resizing(event) {
    let width;
    width = this.localStorageData.sidePanel.width - event.edges.left;
    if (width < 280) width = 280;
    if (width > 620) width = 620;
    this.rightPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.leftPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    // this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.mainPanel.width = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'dataCollector',
      JSON.stringify(this.localStorageData)
    );
  }

  onResizeStart() {
    this.localStorageData.sidePanel.width =
      this.rightPanel.nativeElement.clientWidth;
    this.rightPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.rightPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  getResizeIcon() {
    return this.localStorageData.sidePanel.isVisible
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  timeoutHandlerSidePanel;
  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }

    localStorage.setItem(
      'dataCollector',
      JSON.stringify(this.localStorageData)
    );
  }

  showRightPanel() {
    this.rightPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.localStorageData.sidePanel.isVisible = true;
    this.leftPanel.nativeElement.style.width = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.width = `280px`;
    this.localStorageData.mainPanel.width = `calc(100% - 280px)`;
  }

  hideRightPanel() {
    this.rightPanel.nativeElement.style.width = '10px';
    this.localStorageData.sidePanel.isVisible = false;
    this.width = 21;
    this.leftPanel.nativeElement.style.width = `calc(100% - 21px)`;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanel.width = `calc(100% - 21px)`;
  }
  mouseEnter = () => {
    this.rightPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.rightPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  getCurrentPositionId() {
    return this.focusedCollectorPositionKey;
  }

  onPositionFilterDataChanged = (e) => {
    if (e.selectedItem) {
      this.positionOrderBy = e.selectedItem.value;
      this.positionFilterValue = e.filterValue;
      this.getCollectorPositions();
      this.cd.detectChanges();
    }
  };

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.positionOrderBy) {
      this.positionOrderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getCollectorPositions();
    this.cd.detectChanges();
  };

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  switchOrder() {
    if (this.positionOrder === 'ASC') {
      this.positionOrder = 'DESC';
      return;
    }
    this.positionOrder = 'ASC';
  }

  onPositionsGridReady() {
    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  detachPosition() {
    this.appService
      .putAuth(
        `device/collector/transfer/${this.focusedCollectorTransferId}/positions/${this.focusedCollectorPositionKey}/detach`,
        null
      )
      .subscribe({
        next: () => {
          this.getCollectorPositions();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  attachPosition(productId) {
    this.appService
      .putAuth(
        `device/collector/transfer/${this.focusedCollectorTransferId}/positions/${this.focusedCollectorPositionKey}/attach/${productId}`,
        null
      )
      .subscribe({
        next: () => {
          this.getCollectorPositions();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  articleAttachedId: number = null;
  showArticleAttached() {
    this.articleAttachedId = this.dataSourcePositions.find(
      (pos) => pos.CollectorPositionId === this.focusedCollectorPositionKey
    ).ProductId;
    this.cd.detectChanges();
    setTimeout(() => {
      this.articleMode = 'show';
      this.isShowArticleVisible = true;
    }, 0);
  }

  saveParams = () => {
    this.dropdownbtn.instance.close();
  };

  clearParams = () => {
    (this.transferConfig.FlushCollectorAfter = false),
      (this.transferConfig.TakePricesFromCollector = false),
      (this.transferConfig.TakeDefaultUnit = false),
      (this.transferConfig.IgnoreBlocked = false);
  };

  toggleDynamicFilters() {
    const config = {
      dynamicFilterTabIndex: 0,
      isPanelVisible: this.localStorageData.sidePanel.isVisible,
    };

    // if(config.isPanelVisible && config.dynamicFilterTabIndex !== this.localStorageData.sidePanel.selectedIndex) {
    //   this.localStorageData.sidePanel.selectedIndex = config.dynamicFilterTabIndex;
    //   return
    // }

    // if(this.localStorageData.sidePanel.isVisible) {this.hideRightPanel();} else {
    //   this.showRightPanel();
    // }
    // this.localStorageData.sidePanel.selectedIndex = config.dynamicFilterTabIndex

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'dataCollector',
      this.event.encryptString(this.localStorageData)
    );

    this.refresh();
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getCollectorPositions();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('EAN');
    this.positionFilterValue = e.toString();
    this.getCollectorPositions();
  }

  onSetupWizardClosing() {
    this.interconnector.onSetupWizardShown('Kolektor danych');
    this.setupWizardShown = true;
    this.isSetupWizardVisible = false;
    this.cd.detectChanges();
  }

  onSetupWizardContinue() {
    // this.isSetupWizardVisible = false;
    // this.cd.detectChanges();
    const url =
      'https://storage.wapro.pl/storage/InstalatorWAPRO/InstalatorWAPRO.exe';
    window.open(url, '_blank');
  }

  onSetupWizardLinkClick() {
    const url =
      'https://wapro.pl/dokumentacja-erp/anywhere/docs/sprzedaz-i-magazyn/urzadzenia-fiskalne/interkonektor';
    window.open(url, '_blank');
  }
}
