import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-add-role-to-customer',
    templateUrl: './add-role-to-customer.component.html',
    styleUrls: ['./add-role-to-customer.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'productName',
        'productId',
        'serviceDeviceId',
    ],
    standalone: false
})
export class AddRoleToCustomerComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('Customer') Customer;
  @ViewChild('focusFirst') focusFirst;

  isVisible;
  title;
  serviceDeviceId;

  visibleShortcut;
  selectedId;
  readOnly;
  productName;
  productId;

  widthWindow = 450;
  heightWindow = 160;

  shortcuts: ShortcutInput[] = [];

  form;
  submitted: boolean = false;

  unicalGuid;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.createForm();
  }

  ngOnChanges() {
    if (this.selectedId) {
      if (this.selectedId.length != 0 && this.isVisible)
        this.getDateSelectedId(this.selectedId[0]);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.disabletShortcuts.next(true);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  getDateSelectedId = (_selectedId) => {
    // this.appService.getAuth(`finances/cashBoxes?CashId=${selectedId}`).subscribe(res => {
    //   this.form.patchValue(res.data[0]);
    // })
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      ServiceCustomerRoleId: [0],
      RoleId: [null, Validators.required],
      DeviceId: [this.serviceDeviceId],
      CustomerId: [null, Validators.required],
      RoleName: [''],
      CustomerName: [''],
      ProductName: [this.productName],
    });
  };
  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.createForm(); //reset form with initial values
    this.submitted = false;
    this.event.disabletShortcuts.next(e);

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onChoosedCustomer = (e) => {
    if (e) {
      this.form.controls.CustomerId.setValue(e.CustomerId);
      this.form.controls.CustomerName.setValue(e.Name);
    } else {
      this.form.controls.CustomerId.setValue(null);
      this.form.controls.CustomerName.setValue('');
    }
  };

  onChoosedRole = (e) => {
    if (e) {
      this.form.controls.RoleId.setValue(e.RoleId);
      this.form.controls.RoleName.setValue(e.Name);
    } else {
      this.form.controls.RoleId.setValue(null);
      this.form.controls.RoleName.setValue('');
    }
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (
        this.form.value.ServiceCustomerRoleId == 0 &&
        this.form.value.DeviceId != 0
      ) {
        this.appService
          .postAuth(
            `services/devices/${this.form.value.DeviceId}/customerRoles`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else if (
        this.form.value.ServiceCustomerRoleId != 0 &&
        this.form.value.DeviceId != 0
      ) {
        this.appService
          .putAuth(
            `services/devices/${this.form.value.DeviceId}/customerRoles/${this.form.value.ServiceCustomerRoleId}`,
            this.form.value
          )
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else if (this.form.value.DeviceId == 0) {
        this.onInserted.emit(this.form.value);
        this.isVisible = false;
      }
    } else
      this.event.showNotification(
        'error',
        this.translate.instant('userExtensions.pleaseCompleteAllRequiredFields')
      );
  };
}
