<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
m  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="save-delivery-btn"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClose()"
        [id]="'cancel' + unicalGuid"
        data-cy="cancel-delivery-btn"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 570px">

          <label style="width: 260px">Data od której obowiązuje informacja</label>
          <wapro-date-box
            width="156"
            [displayFormat]="event.dateFormat"
            [readOnly]="readOnly"
          ></wapro-date-box>
          <br />

          <label style="width: 260px">Sposób wykorzystania</label>
          <dx-select-box
            displayExpr="name"
            valueExpr="key"
            [width]="156"
            [readOnly]="readOnly"
          >
          </dx-select-box>
          <br />

          <label style="width: 260px">Odliczenia VAT</label>
          <dx-select-box
            displayExpr="name"
            valueExpr="key"
            [width]="156"
            [readOnly]="readOnly"
          >
          </dx-select-box>
          <br />

          <label style="width: 260px">Najem, dzierżawa, leasing</label>
          <dx-check-box
            [text]=""
            [readOnly]="readOnly"
          >
          </dx-check-box>
          <br />

          <label style="width: 260px">Wylicz KUP w racie najmu wg. zasad obowiązujących od 2019-01-01</label>
          <dx-check-box
            [text]=""
            [readOnly]="readOnly"
          >
          </dx-check-box>
          <br />

          <label style="width: 260px">Wartość pojazdu w umowie najmu</label>
          <dx-number-box
            width="156"
            [min]="0"
            [format]="'#0.00'"
            [useLargeSpinButtons]="false"
            [showSpinButtons]="false"
            [readOnly]="readOnly"
          ></dx-number-box>
          <br />

          <label style="width: 260px">Wartość pojazdu w umowie ubezpieczenia</label>
          <dx-number-box
            width="156"
            [min]="0"
            [format]="'#0.00'"
            [useLargeSpinButtons]="false"
            [showSpinButtons]="false"
            [readOnly]="readOnly"
          ></dx-number-box>
          <br />

          <div class="separator"></div>
          <div style="width: 100%; text-align: center;">Dodatkowe informacje na potrzeby formularza VAT-26</div>
          <br />

          <label style="width: 260px">Data nabycia pojazdu</label>
          <wapro-date-box
            width="156"
            [displayFormat]="event.dateFormat"
            [readOnly]="readOnly"
          ></wapro-date-box>
          <br />

          <label style="width: 260px">Data poniesienia pierwszego wydatku</label>
          <wapro-date-box
            width="156"
            [displayFormat]="event.dateFormat"
            [readOnly]="readOnly"
          ></wapro-date-box>
          <br />

          <label style="width: 260px">Data zmiany wykorzystania</label>
          <wapro-date-box
            width="156"
            [displayFormat]="event.dateFormat"
            [readOnly]="readOnly"
          ></wapro-date-box>
          <br />

        </div>
      </div>
    
    </dx-scroll-view>
  </div>

  <wapro-keyboard-shortcuts
    [unicalGuid]="unicalGuid"
    [shortcuts]="shortcuts"
    [disabled]="!isVisible"
  ></wapro-keyboard-shortcuts>
</dx-popup>
