import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/event.service';
import { FiscalPrinterType } from '../fiscal-printer/fiscal-printer';

@Component({
    selector: 'app-fiscal-printer-settings',
    templateUrl: './fiscal-printer-settings.component.html',
    styleUrls: ['./fiscal-printer-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FiscalPrinterSettingsComponent {
  constructor(
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.getTranslation();
  }
  @Input('isVisible') isVisible;
  @Output() onClosingForm = new EventEmitter();
  @Output() onSaved = new EventEmitter();

  getTranslation() {
    this.connectionSettingsDataSource.forEach((setting) => {
      this.translate.get(setting.label).subscribe((text) => {
        setting.label = text;
      });
      if (setting.items) {
        setting.items.forEach((item) => {
          this.translate.get(item.label).subscribe((text) => {
            item.label = text;
          });
        });
      }
    });
    this.checkboxes.forEach((setting) => {
      this.translate.get(setting.label).subscribe((text) => {
        setting.label = text;
      });
    });
  }

  unicalGuid;
  submitted: boolean = false;
  mode;
  scrollPosition = 0;
  activeTabIndex: number = 0;
  fiscalPrinterTypes: FiscalPrinterType[] = [];
  fiscalPrinterType: FiscalPrinterType = null;

  // brak w API
  settings = {
    ConnectionType: 'Ethernet',
    ConnectionName: '',
    IpAddress: '',
    PortNumberTCPIP: null,
    PortNumberRs232C: null,
    BaudRate: null,
    serialNumber: '',
    workWithTerminal: false,
    invoicePrint: false,
    printDiscounts: false,
  };
  // ---

  connectionSettingsDataSource = [
    {
      name: 'ConnectionType',
      type: 'selectbox',
      disabled: false,
      label: 'externalDevices.connection',
      items: [
        {
          name: 'Ethernet',
          disabled: false,
          label: 'externalDevices.ethernet',
          showIfSelected: ['ConnectionName', 'IpAddress', 'PortNumberTCPIP'],
        },
        {
          name: 'RS232C',
          disabled: false,
          label: 'RS232C',
          showIfSelected: ['PortNumberRS232C', 'BaudRate'],
        },
        {
          name: 'UsBus',
          disabled: true,
          label: 'US-BUS',
          showIfSelected: [],
        },
      ],
      dependent: false,
      isVisible: true,
    },
    {
      name: 'ConnectionName',
      type: 'textbox',
      disabled: false,
      label: 'externalDevices.connectionName',
      dependent: true,
      isVisible: true,
    },
    {
      name: 'IpAddress',
      type: 'textbox',
      disabled: false,
      label: 'externalDevices.TCPIP',
      dependent: true,
      isVisible: true,
    },
    {
      name: 'PortNumberTCPIP',
      type: 'numberbox',
      disabled: false,
      label: 'externalDevices.port',
      min: 0,
      dependent: true,
      isVisible: true,
    },
    {
      name: 'PortNumberRS232C',
      type: 'numberbox',
      disabled: false,
      label: 'externalDevices.port',
      min: 0,
      dependent: true,
      isVisible: true,
    },
    {
      name: 'BaudRate',
      type: 'numberbox',
      disabled: false,
      label: 'externalDevices.baudRate',
      min: 0,
      dependent: true,
      isVisible: true,
    },
    {
      name: 'SerialNumber',
      type: 'textbox',
      disabled: false,
      label: 'fiscalPrinter.connectionConfig.serialNumber',
      dependent: false,
      isVisible: true,
    },
  ];

  checkboxes = [
    {
      name: 'workWithTerminal',
      type: 'checkbox',
      disabled: true,
      label: 'fiscalPrinter.connectionConfig.workWithTerminal',
      isVisible: true,
    },
    {
      name: 'invoicePrint',
      type: 'checkbox',
      disabled: false,
      label: 'fiscalPrinter.connectionConfig.invoicePrint',
      isVisible: true,
    },
    {
      name: 'printDiscounts',
      type: 'checkbox',
      disabled: false,
      label: 'fiscalPrinter.connectionConfig.printDiscounts',
      isVisible: true,
    },
  ];

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue) {
    }
  }

  visibleChange = () => {
    this.submitted = false;
  };

  onSelectionChanged(e) {
    const fieldsToShow: string[] = e.selectedItem.showIfSelected;
    this.showDependentFields(fieldsToShow);
    this.cd.detectChanges();
  }

  showDependentFields(fieldsToShow) {
    const dependentFields = this.connectionSettingsDataSource.filter(
      (field) => field.dependent
    );
    if (fieldsToShow) {
      for (let field of dependentFields) {
        fieldsToShow.indexOf(field.name) === -1
          ? (field.isVisible = false)
          : (field.isVisible = true);
      }
    } else {
      for (let field of dependentFields) {
        field.isVisible = false;
      }
    }
  }

  onCancel = () => {
    this.onClosingForm.emit(true);
    this.isVisible = false;
  };

  onSave = () => {
    this.onSaved.emit();
    this.isVisible = false;
  };

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };
}
