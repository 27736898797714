<dx-popup
  [width]="'100%'"
  [height]="'100%'"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="false"
  [title]="title"
  [(visible)]="isVisible"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="'100%'"
  [maxHeight]="'100%'"
  (onShown)="event.onShownPopUp(); "
  (onHidden)="event.onHiddenPopUp(); ngOnDestroy()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <ng-container *ngTemplateOutlet="map"></ng-container>
  </div>
</dx-popup>

<ng-template #map>
  @if (event.deviceType != 'mobile') {
    <div class="d-flex align-items-center">
      <i
        style="position: relative; height: 40px; width: 40px;"
        class="map icon absui-icon--directions-car icon-tag icon-tag_blue"
      ></i>
      <div class="route-title">
        {{ selectedRoute[0]?.Name }}
      </div>
      <div class="separator">
      </div>
      <div>
        <label class="route-title route-title_fz12 route-title_date"
        >{{ 'intrastate-documents.date' | translate }}:</label>
        <p class="route-desc">
          {{ selectedRoute[0]?.RouteDate | date : event.dateFormat }}
        </p>
      </div>
      <div class="separator">
      </div>
      <div *ngIf="selectedRoute[0]?.CountPos">
        <label class="route-title route-title_fz12 route-title_implementation"
        >{{ 'gdpr.implementation' | translate }}:</label>
        <div class="d-flex align-items-center">
          <dx-progress-bar
            style="display: inline-block"
            width="100px"
            [min]="0"
            [max]="100"
            [value]="(selectedRoute[0]?.CountRegType2 / selectedRoute[0]?.CountPos) * 100"
          >
          </dx-progress-bar>
          <div class="dx-progressbar-percent">
            {{ selectedRoute[0]?.CountRegType2 }} / {{ selectedRoute[0]?.CountPos }}
          </div>
        </div>
      </div>
    </div>
  }
  <div class="text-right title-pop-up">
    <div style="display: inline-block">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>
  </div>

  @if (event.deviceType === 'mobile') {
    <div class="map-mobile-title">
      <div class="map-mobile-title_subject">
        {{ 'routes.routePreview' | translate }}
      </div>
      <i
        style="cursor: pointer"
        class="icon absui-icon--close"
        (click)="isVisible = false"
      ></i>
    </div>
  }
  <div id="map">
  </div>
  <div id="tooltip" class="tooltip"></div>
  @if (event.deviceType === 'mobile') {
    <dx-button
      (onClick)="openInGoogleMaps()"
      [disabled]="addresses.length === 0"
      class="buttons-map buttons-map_google-maps">
      <img src="../../../../assets/img/google-maps-2020-icon.svg" alt="google-maps"/>
    </dx-button>

    <div
      (click)="calculateRoute()"
      class="buttons-map buttons-map_calculate-route">
      <img src="../../../../assets/img/directions-arrows-sign.svg" alt="calculate-route"/>
    </div>

    <div
      (click)="adjustMapView()"
      class="buttons-map buttons-map_fit-to-point">
      <img src="../../../../assets/img/fit_to_point.svg" alt="fit-to-point"/>
    </div>
  }
  <a class="link-open-street"
     href="https://www.openstreetmap.org/copyright"
     target="_blank">&copy; OpenStreetMap contributors</a>
</ng-template>

<ng-template #topButtons>
  <div class="d-inline-block">
    <dx-button
      text="{{ 'routes.openInGoogleMaps' | translate }}"
      class="btn-dx-top-menu"
      style="margin-right: 6px"
      icon="icon absui-icon--external-link"
      (onClick)="openInGoogleMaps()"
      [disabled]="addresses.length === 0"
      [id]="'openInGoogleMaps' + unicalGuid"
    >
    </dx-button>
    <dx-button
      icon="icon absui-icon--fit-to-point"
      text="{{ 'routes.matchToPoints' | translate }}"
      class="btn-dx-top-menu"
      style="margin-right: 6px"
      (onClick)="adjustMapView()"
      [id]="'matchToPoints' + unicalGuid"
      [disabled]="addresses.length === 0"
    >
    </dx-button>
    <dx-button
      text="{{ 'routes.planRoute' | translate }}"
      type="success"
      style="margin-right: 6px"
      (onClick)="calculateRoute()"
      [disabled]="addresses.length < 2"
      [id]="'btn-planRoute' + unicalGuid"
      *ngIf="!readOnly"
    >
    </dx-button>
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelRoutesMap()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>

  <dx-tooltip
    [target]="'#openInGoogleMaps' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ 'routes.openInGoogleMaps' | translate }} (ctrl + G)
    </div>
  </dx-tooltip>
  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
  <dx-tooltip
    [target]="'#btn-planRoute' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ 'routes.planRoute' | translate }} (F10)
    </div>
  </dx-tooltip>
  <dx-tooltip
    [target]="'#matchToPoints' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ 'routes.matchToPoints' | translate }}(ctrl + D)
    </div>
  </dx-tooltip>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

