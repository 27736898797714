import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

interface GeoResponse {
  lat: string;
  lon: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {

  private nominatimApiUrl = 'https://nominatim.openstreetmap.org/search';
  private openRouteApiKey: string =  environment.AUTH_ROUTE;

  constructor(private http: HttpClient) {}

  geocodeAddress(address: string): Observable<GeoResponse[]> {
    const params = new HttpParams()
      .set('q', address)
      .set('format', 'json')
      .set('limit', '1');

    return this.http.get<GeoResponse[]>(this.nominatimApiUrl, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getRoute(coordinates: number[][]): Observable<any> {
    const url = `https://api.openrouteservice.org/v2/directions/driving-car`;
    const headers = new HttpHeaders({
      'Authorization': this.openRouteApiKey
    });

    return this.http.post(url, {
      coordinates: coordinates,
      format: 'json',
    }, { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {

    const detail = this.getErrorDetail(error);
    const message = error?.error?.title || 'Nieznany błąd';
    let errorMessage = '';

    errorMessage = JSON.stringify({
      error: error.status,
      message,
      detail,
    });

    // Zwróć obserwowalny błąd
    return throwError(() => {
      return errorMessage;
    });
  }

  getErrorDetail(error: HttpErrorResponse): string {
    if (!error?.error) return '';
    else if (error?.error?.detail) return error.error.detail;
    else return error?.error?.title + JSON.stringify(error?.error?.errors);
  }
}
