import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, signal, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ICustomSearchItem} from '../../core/custom-dropdown-box/custom-dropdown-box.model';
import {CustomWindowConfig} from '../../event.model';

import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EventService} from '../../event.service';

import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {environment} from '../../../environments/environment';
import {LoadOptions} from 'devextreme/data';
import {AppServices} from '../../app-services.service';
import {forkJoin, Subscription} from 'rxjs';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {GlobalDate} from '../../core/date-range/GlobalDate';
import {CustomDropdownBoxComponent} from '../../core/custom-dropdown-box/custom-dropdown-box.component';
import {DateRangeComponent} from '../../core/date-range/date-range.component';
import {AdditionalOperationComponent} from '../../core/additional-operation/additional-operation.component';
import {RouteUsersComponent} from "./route-users/route-users.component";

@Component({
    selector: 'app-contractors-routes',
    templateUrl: './contractors-routes.component.html',
    styleUrl: './contractors-routes.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContractorsRoutesComponent {
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('gridBank') gridBank;
  @ViewChild('gridBank2') gridBank2;
  @ViewChild('gridBank3') gridBank3;
  @ViewChild('contractorsMobileList') contractorsMobileList;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('bottomSidePanel') bottomSidePanel;

  @ViewChild(RouteUsersComponent) routeUsersComponent: RouteUsersComponent;

  selectedAmount = signal(0);
  selectedAmountPosition = signal(0);
  openCustomDropDown: boolean = false;
  openCustomDropDownMain: boolean = false;
  selectedRows = [];
  dataSource;
  unicalGuid: number;
  modeSelectionMobile: string = 'none';
  focusedSelected = [];
  focusedSelected2 = [];
  filterCriteria: ICustomSearchItem[] = [
    {value: 'Name', label: ''},
    {value: 'Orders', label: ''},
    {value: 'City', label: ''},
    {value: 'Province', label: ''}
  ];
  filterCriteriaMain: ICustomSearchItem[] = [
    {value: 'Name', label: ''},
    {value: 'Date', label: '', type: 'data'},
  ];
  filterValue = '';
  filterValueMain = '';
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'Name',
      order: 'ASC',
    },
    bottomPanelFilter: {
      value: '',
      orderBy: 'Orders',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanelColumns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: false,
      height: 210,
    },
    mainPanelWidth: 'calc(100% - 300px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 300,
    },
    windowSize: {
      isFullscreen: false,
      width: 0,
      height: 0,
    },
    params: [],
    skipUserGuide: false,
  };
  focusedRowIndex = -1;
  focusedRowIndexPosition = -1;
  focusedRowIndexUsers = -1;
  heightGrid;
  pageSize = 50;
  readOnly = false;
  heightBottomGrid = 210;
  timeoutHandler;
  bottomPanelTabs = ['Szczegóły tras', 'Użytkownicy'];
  selectedPositionDocument = [];
  positionList;
  modePosition = 'add';
  isDetailOnFocus = false;
  isData: boolean = false;
  myTimer;
  userExtensionColumns = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };
  userExtensionColumnsPos = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };
  additionalFieldsListPos = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: ''
  };
  newRouteVisible: boolean = false;
  mode: string = 'add';
  confirmDeleteRoutesVisible: boolean = false;
  confirmDeleteRoutesContractorVisible: boolean = false;
  routeUsersVisible: boolean = false;
  routeMapVisible: boolean = false;
  isVisibleExternalFieldsPos: boolean = false;
  onlyShow = true;
  additionalOperationList = signal([]);
  isEditDisabled = signal(false);
  isDeleteDisabled = signal(false);
  contextMenu = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: this.readOnly || !this.routePermission.buttonsPer.addBtn,
        translationSrc: 'buttons.add',
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled: this.readOnly || !this.routePermission.buttonsPer.editBtn || this.isEditDisabled(),
        translationSrc: 'buttons.edit',
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled: this.readOnly || !this.routePermission.buttonsPer.deleteBtn || this.isDeleteDisabled(),
        translationSrc: 'buttons.delete',
      },
      {
        text: this.translate.instant('jpk.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        disabled: this.readOnly || this.isEditDisabled(),
        itemIndex: 3,
      },
      {
        text: this.translate.instant('jpk.unselectAll'),
        icon: 'icon absui-icon--deselct-all',
        disabled: this.readOnly || this.isEditDisabled(),
        itemIndex: 4,
      },
    ];
  });
  isDeleteDisabledDetail = signal(false);
  contextMenuDetail = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: this.readOnly || !this.routePermission.contractorsUpdate,
        translationSrc: 'buttons.add',
      },
      // {
      //   text: this.translate.instant('buttons.edit'),
      //   icon: 'icon absui-icon--mode-edit',
      //   itemIndex: 4,
      //   disabled: this.readOnly || this.isDeleteDisabledDetail(),
      //   translationSrc: 'buttons.edit',
      // },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 1,
        disabled: this.readOnly || !this.routePermission.contractorsUpdate || this.isDeleteDisabledDetail(),
        translationSrc: 'buttons.delete',
      },
      {
        text: this.translate.instant('routes.changeAddressOnRoute'),
        icon: 'icon absui-icon--location-edit',
        itemIndex: 6,
        disabled: this.readOnly || this.isDeleteDisabledDetail(),
        translationSrc: 'routes.changeAddressOnRoute',
      },
      {
        text: this.translate.instant('routes.changingTheRouteStatus'),
        icon: 'icon absui-icon--tag-edit',
        disabled: this.readOnly || this.isDeleteDisabledDetail(),
        items: [
          {
            itemIndex: 7,
            value: 0,
            text: 'Oczekuje'
          },
          {
            itemIndex: 8,
            value: 1,
            text: 'W realizacji'
          },
          {
            itemIndex: 9,
            value: 2,
            text: 'Zakończona'
          },
          {
            itemIndex: 10,
            value: 3,
            text: 'Anulowana'
          }
        ],
        translationSrc: 'routes.changingTheRouteStatus',
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        itemIndex: 5,
        disabled: this.readOnly || this.isDeleteDisabledDetail(),
        items: this.additionalOperationList(),
        translationSrc: 'form-commercial-operation.additionalOperations',
      },
      {
        text: this.translate.instant('jpk.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        disabled: this.readOnly || this.isDeleteDisabledDetail(),
        itemIndex: 2,
      },
      {
        text: this.translate.instant('jpk.unselectAll'),
        icon: 'icon absui-icon--deselct-all',
        disabled: this.readOnly || this.isDeleteDisabledDetail(),
        itemIndex: 3,
      },
    ];
  });
  shortcuts: ShortcutInput[] = [];
  shortcutsStatus: ShortcutInput[] = [];
  editingRouteAddressVisible: boolean = false;
  titleChengeRouteStatus = '';
  titleDetailRouteMobile = '';
  isVisibleChangeRouteStatus: boolean = false;
  selectedStatus: number;
  statuses = [
    {value: 0, text: 'routes.status.waiting'},
    {value: 1, text: 'routes.status.inProgress'},
    {value: 2, text: 'routes.status.completed'},
    {value: 3, text: 'routes.status.cancelled'}
  ];
  currentDate: GlobalDate = new GlobalDate();
  currentDateSub: Subscription;
  additionalOperation: AdditionalOperationComponent;
  mobileOperationsVisible: boolean = false;
  contextMenuOperationsMobile = [
    {name: 'Zaznacz wiele', icon: 'icon checkAll', itemIndex: 99},
  ];
  contextMenuUsersOperationsMobile = [
    {name: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 1},
  ];
  isContextMenuMobile: boolean = false;
  contextMenuMobile = [
    {name: 'Szczegóły', icon: 'icon absui-icon--file-edit', itemIndex: 1000},
    {name: 'Zaznacz', icon: 'icon checkAll', itemIndex: 1001},
    {name: 'Popraw', icon: 'icon absui-icon--mode-edit', itemIndex: 1002},
    {name: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 1003},
    {name: 'Zaznacz wszystko', icon: 'icon absui-icon--checkbox-all', itemIndex: 1004},
    {name: 'Odznacz wszystko', icon: 'icon absui-icon--deselct-all', itemIndex: 1005},
  ];
  contextMenuDetailMobile = [
    {name: 'Zmień adres na trasie', icon: 'icon absui-icon--location-edit', itemIndex: 6},
    {
      name: 'Status', icon: 'icon absui-icon--tag-edit', items: [
        {
          itemIndex: 7,
          value: 0,
          text: 'Oczekuje'
        },
        {
          itemIndex: 8,
          value: 1,
          text: 'W realizacji'
        },
        {
          itemIndex: 9,
          value: 2,
          text: 'Zakończona'
        },
        {
          itemIndex: 10,
          value: 3,
          text: 'Anulowana'
        }
      ]
    },
    {
      name: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 1,
    },
  ];
  dataSourceUsers: DataSource;
  selectedIndex = 0;
  isShowMoreMobileDetails = false;
  isContextMenuDetailMobile = false;
  isContextUsersMenuMobile = false;
  normalMode = false;
  maxHeight: any = null;
  mobileHeight = '100%';
  routePermission = {
    buttonsPer: {
      addBtn: false,
      editBtn: false,
      deleteBtn: false,
    },
    contractorsUpdate: false,
    usersUpdate: false,
    columnConfiguration: false
  }
  columnsChooserVisible = false;

  constructor(
    public event: EventService,
    public translate: TranslateService,
    public cd: ChangeDetectorRef,
    private appService: AppServices
  ) {
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.titleChengeRouteStatus = this.translate.instant('routes.changingTheRouteStatus');
    this.maxHeight = this.event.setMaxHeight;

    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit() {
    this.heightGrid = window.innerHeight - 180 - 27;
    this.checkPermissions();
    this.getRoutes();
  }

  ngAfterViewInit() {
    this.translateFilterCriteria();

    this.translateRouteStatuses();

    this.translateMobileContextMenu();

    this.getViewConfigurations();

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.translate
      .get('routes.routeDetails')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));
    this.translate
      .get('menu.users')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));

    this.translate
      .get('selectMultiple')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));

    this.event.getUserExtension(
      'MOB_TRASA',
      this.userExtensionColumns
    );

    this.event.getUserExtension(
      'MOB_POZYCJA_TRASY',
      this.userExtensionColumnsPos
    );

    this.shortcutsStatus.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isVisibleChangeRouteStatus) {
            this.isVisibleChangeRouteStatus = false;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isVisibleChangeRouteStatus && !this.readOnly) {
            this.onSaveStatusRoute();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );

    this.shortcuts.push(
      {
        key: 'ctrl + m',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && e.event.ctrlKey && this.focusedSelected.length > 0 && this.focusedSelected2.length > 0) {
            this.routeMapVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + u',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && e.event.ctrlKey && this.focusedSelected.length > 0) {
            this.routeUsersVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.ctrlKey) {
            this.addNewRoutes();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ArrowDown',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.gridBank.instance.focus();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.shiftKey) {
            if (
              this.focusedSelected.length > 0
            ) {
              this.editRoutes();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.shiftKey) {
            if (
              this.focusedSelected.length > 0
            ) {
              this.editingRouteAddressVisible = true;
              this.cd.detectChanges();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        preventDefault: true,
        command: () => {
          if (!this.readOnly) {
            if (
              this.focusedSelected.length > 0 && this.focusedSelected2.length > 0
            ) {
              this.isVisibleChangeRouteStatus = true;
              this.cd.detectChanges();
            }
          }
        },
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            if (
              this.focusedSelected.length > 0
            ) {
              this.confirmDeleteRoutesVisible = true;
              this.cd.detectChanges();

            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && this.focusedSelected.length > 0 && e.event.ctrlKey) {
            this.addNewContractors();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0 && this.focusedSelected2.length > 0) {
            this.confirmDeleteRoutesContractorVisible = true;
            this.cd.detectChanges();

          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + up',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0 && this.focusedSelected2.length > 0) {
            this.gridBank2.instance.focus();
            this.moveOrder('up');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + down',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0 && this.focusedSelected2.length > 0) {
            this.gridBank2.instance.focus();
            this.moveOrder('down');
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.openCustomDropDownMain = !this.openCustomDropDownMain;
        },
        preventDefault: true,
      }
    );

  }

  checkPermissions() {
    this.routePermission.buttonsPer = this.event.checkPermissionsBtn(
      'DTrasy',
      'ETrasy',
      'UTrasy'
    );
    this.routePermission.columnConfiguration = this.event.checkPermissions('KonfKolTrasy');
    this.routePermission.contractorsUpdate = this.event.checkPermissions('KontrTrasy');
    this.routePermission.usersUpdate = this.event.checkPermissions('UzytTrasy');
  }

  translateMobileContextMenu() {
    this.translate
      .get('tasks.details')
      .subscribe((text) => (this.contextMenuMobile[0].name = text));
    this.translate
      .get('mark')
      .subscribe((text) => (this.contextMenuMobile[1].name = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenuMobile[2].name = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenuMobile[3].name = text));
    this.translate
      .get('jpk.selectAll')
      .subscribe((text) => (this.contextMenuMobile[4].name = text));
    this.translate
      .get('jpk.unselectAll')
      .subscribe((text) => (this.contextMenuMobile[5].name = text));
  }

  translateRouteStatuses() {
    this.translate
      .get('routes.status.waiting')
      .subscribe((text) => (this.statuses[0].text = text));
    this.translate
      .get('routes.status.inProgres')
      .subscribe((text) => (this.statuses[1].text = text));
    this.translate
      .get('routes.status.completed')
      .subscribe((text) => (this.statuses[2].text = text));
    this.translate
      .get('routes.status.cancelled')
      .subscribe((text) => (this.statuses[3].text = text));

    this.translate
      .get('routes.status.waiting')
      .subscribe((text) => (this.contextMenuDetail()[4].items[0].text = text));
    this.translate
      .get('routes.status.inProgress')
      .subscribe((text) => (this.contextMenuDetail()[4].items[1].text = text));
    this.translate
      .get('routes.status.completed')
      .subscribe((text) => (this.contextMenuDetail()[4].items[2].text = text));
    this.translate
      .get('routes.status.cancelled')
      .subscribe((text) => (this.contextMenuDetail()[4].items[3].text = text));
  }

  openColumnsChooser() {
    this.columnsChooserVisible = true;
  }

  showContextMenuMobile = () => {
    this.event.showContextMenuMobile(this, 'RouteId');
  };

  visibleChangeChengeRouteStatus() {
    if (!this.isVisibleChangeRouteStatus) {
      this.isVisibleChangeRouteStatus = false;
    }
  }

  visibleChangeDetailRouteMobile() {
    if (!this.isDetailRouteMobileVisible) {
      this.isDetailRouteMobileVisible = false;
      this.addresses = [];
      this.normalMode = false;
    }
  }

  onSetAdditionalOperation(e) {
    console.log(e)
    this.additionalOperationList.set(e);
  }

  onKeyDown(e) {
    if (e.event.key === 'F2' && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.editRoutes();
    } else if (e.event.ctrlKey && e.event.key == 'ArrowDown') {
      this.moveOrder('down');
    } else if (e.event.ctrlKey && e.event.key == 'ArrowUp') {
      this.moveOrder('up');
    } else if (e.event.key === 'Insert' && e.event.ctrlKey) {
      this.addNewContractors();
    } else if (e.event.key === 'Delete' && e.event.ctrlKey) {
      this.confirmDeleteRoutesContractorVisible = true;
    } else if (e.event.key === 'F2' && e.event.ctrlKey) {
      this.editingRouteAddressVisible = true;
    } else if (e.event.key === 'Insert' && !e.event.ctrlKey) {
      this.addNewRoutes();
    } else if (e.event.key === 'Delete' && !e.event.ctrlKey) {
      this.confirmDeleteRoutesVisible = true;
    } else if (e.event.ctrlKey && e.event.key === 'm') {
      if (!this.readOnly && this.focusedSelected.length > 0 && this.focusedSelected2.length > 0) {
        this.routeMapVisible = true;
        this.cd.detectChanges();
      }
    }
  }

  onSelectionTabChanged() {
    if (this.selectedIndex === 0) {
      this.getRoutes();
    } else if (this.selectedIndex === 1) {
      this.getRouteUsers();
    }
  }

  focusedSelectedUser = [];

  getRouteUsers() {
    this.dataSourceUsers = new DataSource({
      store: AspNetData.createStore({
        key: 'UserId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}customers/GetRoutesUsers?routeId=${this.focusedSelected[0].RouteId}`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedSelectedUser = [];
            this.cd.detectChanges()
            return;
          }

          if (this.firstLoadUser) {
            this.firstLoadUser = false;

            setTimeout(() => {
              this.focusedRowIndexUsers = 0;
              this.onFocusedRowChangedUser({
                row: {data: data[this.focusedRowIndexUsers]},
              });
              this.cd.detectChanges();
            });
          } else if (this.setFocusOnLoad) {
            this.setFocusAfterLoadUser(data);
          }


        },
      }),
    });
  }

  addUserVisible: boolean = false;
  confirmDeleteRoutesUserVisible: boolean = false;

  addUserRoute() {
    this.addUserVisible = true;
  }

  onChoosedUser(e: any) {
    if (e) {
      const userId = e.AppUserId;
      const routeId = this.focusedSelected[0].RouteId;
      const param = {
        userId: userId,
        routeId: routeId,
      };

      if (userId && routeId) {
        this.appService
          .postAuth(`customers/CustomerRouteUser`, null, param)
          .subscribe({
            next: () => {
              this.dataSourceUsers.reload();
            },
            error: (error) => {
              this.event.httpErrorNotification(error);
            },
          });
      }
    }
  }

  onDeleteUserRoutes() {
    if (this.focusedSelectedUser.length === 0) {
      return;
    }

    const userId = this.focusedSelectedUser[0].UserId;
    const routeId = this.focusedSelectedUser[0].RouteId;

    this.appService
      .deleteAuth(`customers/RouteUser/${userId}/${routeId}`)
      .subscribe({
        next: () => {
          this.dataSourceUsers.reload();
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  getViewConfigurations = () => {
    try {
      this.filterValueMain = this.localStorageData.filter.value;
      this.filterValue = this.localStorageData.bottomPanelFilter.value;

      if (
        !localStorage.getItem('ContractorsRoutesComponent')
      ) {
        this.heightGrid = window.innerHeight - 435 - 34 - 27;

        this.appService
          .getAuth(`viewConfigurations/ContractorsRoutesComponent`)
          .subscribe((res) => {
            if (res.Configuration != null) {
              localStorage.setItem('ContractorsRoutesComponent', res.Configuration);
              this.localStorageData = JSON.parse(res.Configuration);
            }
          });
      } else {
        this.localStorageData = JSON.parse(
          localStorage.getItem('ContractorsRoutesComponent')
        );
      }

      if (this.localStorageData?.bottomPanel?.isVisible) {
        setTimeout(() => {
          this.ngModelChange('first');
        }, 100);
      }

    } catch (e) {
      // this.onInitErr = true;
    }
  };

  contextUsersMenuMobile(e) {
    switch (e.itemData.itemIndex) {
      case 1:
        if (this.focusedSelectedUser.length != 0 || !this.readOnly) {
          this.confirmDeleteRoutesUserVisible = true;
        }
        break
    }
  }

  contextMenuClick(e: any) {
    switch (e.itemData.itemIndex) {
      case 1000:
        this.isDetailRouteMobileVisible = true;
        this.normalMode = true;
        break
      case 99:
      case 1001:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridBank.instance.getDataSource().items()[
          this.focusedRowIndex
          ];
        const id = row.RouteId;
        if (!this.selectedRows.includes(id)) {
          this.gridBank.instance.selectRows(id, true);
        } else {
          this.gridBank.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 100:
        if (
          !this.readOnly &&
          this.focusedSelected.length != 0 &&
          this.dataRoutesPosition.length != 0
        ) {
          this.routeMapVisible = true;
        }
        break;
      case 0:
        if (!this.readOnly) {
          this.addNewRoutes();
        }
        break;
      case 1:
      case 1002:
        if (!this.readOnly && this.focusedSelected.length != 0) {
          this.editRoutes();
        }
        break;
      case 2:
      case 1003:
        if (!this.readOnly && this.focusedSelected.length != 0) {
          this.confirmDeleteRoutesVisible = true;
        }
        break;
      case 3:
      case 1004:
        this.skipSendSelectionEvent = true;
        this.gridBank.instance.selectAll();
        this.selectAll();
        break;
      case 4:
      case 1005:
        this.skipSendSelectionEvent = true;
        this.selectedRows = [];
        this.deselectAllRecords();
        break;
    }
  }

  runOwnAdditionalOperation(operation: any){
    const data = this.event.prepareAdditionalOperationData(operation,this.currentDate, this.selectedRows.length == 0 ? this.focusedSelected[0].RouteId : null)
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if(operation?.RefreshList){
          this.getRoutes();
        }
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      }
    })
  }

  contextMenuClickDetail(e: any) {
    console.log(e)
    if(e.itemData.IsOuter){
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.addNewContractors();
        }
        break;
      case 1:
        if (
          !this.readOnly &&
          this.focusedSelected2.length != 0
        ) {
          this.deleteContractors();
        }
        break;
      case 2:
        this.skipSendSelectionDetailEvent = true;
        this.gridBank2.instance.selectAll();
        this.selectAllPosition();
        break;
      case 3:
        this.skipSendSelectionDetailEvent = true;
        this.selectedPositionDocument = [];
        this.deselectAllPositionRecords();
        break;
      case 4:
        if (
          !this.readOnly &&
          this.focusedSelected2.length != 0
        ) {
          this.editingRouteAddressVisible = true;
        }
        break;
      case '0011_000_POLADOD':
        if (!this.readOnly && this.focusedSelected2.length != 0) {
          this.onlyShow = true;
          this.additionalFieldsListPos = this.focusedSelected2[0];
          this.isVisibleExternalFieldsPos = true;
        }
        break;
      case '0011_000_POLADODGRUP':
        if (!this.readOnly && this.focusedSelected2.length != 0) {
          this.onlyShow = false;
          this.additionalFieldsListPos = this.focusedSelected2[0];
          this.isVisibleExternalFieldsPos = true;
        }
        break;
      case 6:
        if (!this.readOnly && this.focusedSelected2.length != 0 && this.focusedSelected.length != 0) {
          this.editingRouteAddressVisible = true;
        }
        break;
      case 7:
      case 8:
      case 9:
      case 10:
        this.selectedStatus = e.itemData.value;
        this.onSaveStatusRoute();
        break
    }
  }

  onItemClickContractorsDetail(e) {
    this.focusedSelected2 = [e.itemData];
    this.selectedStatus = e.itemData.RegistrationType;
    this.cd.detectChanges();
  }

  onItemClickStatus(e) {
    this.selectedStatus = e.itemData.value;
    this.onSaveStatusRoute();
  }

  disabledChange(e) {
    this.isEditDisabled.set(e);
    this.isDeleteDisabled.set(e);
  }

  disabledChangeDetail(e) {
    this.isDeleteDisabledDetail.set(e);
  }

  isAllSelected = false;
  onlySelected = false;
  isAllSelectedPosition = false;
  onlySelectedPosition = false;

  selectAll() {
    this.isAllSelected = true;
    this.appService
      .postAuth(`customers/selection/customer/route/selectall`, null)
      .subscribe(({RowNumber}) => {
        this.selectedAmount.set(RowNumber);
      });
  }

  selectAllPosition() {
    this.isAllSelectedPosition = true;
    this.appService
      .postAuth(`customers/selection/customer/routepos/selectall`, null)
      .subscribe(({RowNumber}) => {
        this.selectedAmountPosition.set(RowNumber);
      });
  }


  translateFilterCriteria() {
    this.translate
      .get('userExtensions.customers')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('status-type.order')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('companies.grid.columns.city')
      .subscribe((text) => (this.filterCriteria[2].label = text));
    this.translate
      .get('companies.form.addressDetail.voivodeship')
      .subscribe((text) => (this.filterCriteria[3].label = text));


    this.translate
      .get('routes.routeName')
      .subscribe((text) => (this.filterCriteriaMain[0].label = text));
    this.translate
      .get('production-documents.date')
      .subscribe((text) => (this.filterCriteriaMain[1].label = text));
  }

  firstLoad = true;
  firstLoadUser = true;
  indexToFocus: number;
  indexToFocusUser: number;
  setFocusOnLoad = false;
  setFocusOnLoadUser = false;
  skipSendSelectionEvent = false;
  skipSendSelectionDetailEvent = false;

  getRoutes(fromSearch = false) {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RouteId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}customers/GetRoutes`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedSelected = [];
            this.dataSourceDetail = [];
            return;
          }

          const toAdd = data.filter(
            (el) =>
              el.IsSelected && this.selectedRows.indexOf(el.RouteId) === -1
          );
          for (let item of toAdd) {
            this.selectedRows.push(item.RouteId);
            this.skipSendSelectionEvent = true;
          }

          const toDelete = [];
          for (let id of this.selectedRows) {
            const item = data.find((el) => el.RouteId === id);
            if (item && !item.IsSelected) {
              toDelete.push(id);
            }
          }

          for (let id of toDelete) {
            this.selectedRows.splice(
              this.selectedRows.findIndex((el) => el === id),
              1
            );
            this.skipSendSelectionEvent = true;
          }

          // setTimeout(() => {
          //   this.gridBank.instance.focus();
          // }, 0);

          // const instance = this.gridBank.instance;
          // this.localStorageData.columns.forEach((col, i) => {
          //   instance.columnOption(col.caption, 'visible', col.visible);
          //   instance.columnOption(col.caption, 'visibleIndex', i);
          //   instance.columnOption(col.caption, 'width', col.width);
          // });

          if (this.firstLoad) {
            this.firstLoad = false;

            this.focusedRowIndex = 0;
            this.onFocusedRowChanged({
              row: {data: data[this.focusedRowIndex]},
            });
            this.searchControl?.focusInput();

            this.cd.detectChanges();

          } else {
            if (this.focusedRowIndex === -1) {
              this.focusedRowIndex = 0;
            }
            this.onFocusedRowChanged({
              row: {data: data[this.focusedRowIndex]},
            });
          }
          if (fromSearch) {
            this.searchControl?.focusInput();
          }
          this.cd.detectChanges();

        }
      }),
      reshapeOnPush: true,
    });
  }

  getLoadParams() {
    const obj: any = {};

    if (this.localStorageData.filter.orderBy) {
      obj.OrderBy = this.localStorageData.filter.orderBy;
    }
    if (this.localStorageData.filter.order) {
      obj.Order = this.localStorageData.filter.order;
    }

    if (this.currentDate.dateFrom) {
      obj.dateFrom = this.event.dataFormatted({
        value: this.currentDate.dateFrom,
      });
    }
    if (this.currentDate.dateTo) {
      obj.dateTo = this.event.dataFormatted({
        value: this.currentDate.dateTo,
      });
    }

    if (this.filterValueMain) {
      switch (this.localStorageData.filter.orderBy) {
        case 'Name':
          obj.name = this.filterValueMain;
          break;
        case 'Date':
          const correctDate = this.event.setCorrectDate(this.filterValueMain);
          obj.dateFrom = correctDate;
          obj.dateTo = correctDate;
          break;
      }
    }

    return obj;
  }


  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({row: {data: data[this.focusedRowIndex]}});
    this.cd.detectChanges();
  }

  setFocusAfterLoadUser(data) {
    this.focusedRowIndexUsers = this.indexToFocusUser;
    this.setFocusOnLoadUser = false;
    this.onFocusedRowChanged({row: {data: data[this.focusedRowIndexUsers]}});
    this.cd.detectChanges();
  }

  addNewRoutes() {
    this.mode = 'add';
    this.newRouteVisible = true;
    this.cd.detectChanges();
  }

  onDateRangeChoosed() {
    this.getRoutes();
  }

  editRoutes() {
    setTimeout(() => {
      this.mode = 'edit';
      this.newRouteVisible = true;
      this.cd.detectChanges();
    }, 300)

  }

  selectedSearchMobileFilter = ''

  onFilterDataChanged(e) {
    this.localStorageData.bottomPanelFilter.orderBy = e.selectedItem.value;
    this.localStorageData.bottomPanelFilter.value = e.filterValue;

    if (this.event.deviceType === 'mobile') {
      this.selectedSearchMobileFilter = e.selectedItem.value;
    }

    this.getRouteDetails(this.focusedSelectedRouteId);
  }

  onFilterDataChangedMain(e) {
    this.localStorageData.filter.orderBy = e.selectedItem.value;
    this.filterValueMain = e.filterValue;
    this.localStorageData.filter.value = e.filterValue;
    this.getRoutes(true);
  }

  updateRoute = false;
  onFocusedRowChanged = (e) => {
    try {
      this.focusedSelected = [e.row.data];
      this.isDetailOnFocus = false;

      if (this.myTimer) {
        clearTimeout(this.myTimer);
      }

      this.myTimer = setTimeout(() => {
        if (this.focusedSelected.length > 0 && !this.updateRoute) {
          this.focusedSelectedRouteId = this.focusedSelected[0].RouteId;
          setTimeout(() => {
            this.getRouteDetails(this.focusedSelectedRouteId);
            this.cd.detectChanges();
          });
        }
        if (this.selectedAmountPosition() > 0) {
          this.deselectAllPositionRecords();
        }
        this.updateRoute = false;

        this.cd.detectChanges();
      }, 500);
    } catch {
    }

    // if (this.focusedSelected.length !== 0 && !this.readOnly) {
    //   this.contextMenuDocument.forEach(item => {
    //     item.disabled = false;
    //   })
    // }
  };

  onFocusedRowChangedUser = (e) => {
    try {
      this.focusedSelectedUser = [e.row.data];
      this.isDetailOnFocus = false;
    } catch {
    }
  };

  getDataRouteUsers(e) {
    console.log(e)
  }

  ngModelChange = (noChangeState?) => {
    if (noChangeState !== 'first') {
      this.localStorageData.bottomPanel.isVisible =
        !this.localStorageData.bottomPanel.isVisible;
    }

    setTimeout(() => {
        if (
          this.localStorageData.bottomPanel.isVisible &&
          this.localStorageData.bottomPanel.height === 210
        ) {
          this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
          this.heightGrid = window.innerHeight - 435 - 34 - 27;
          this.tabPanel.height = '100%';
          this.heightBottomGrid = 210;
        } else if (
          this.localStorageData.bottomPanel.isVisible &&
          this.localStorageData.bottomPanel.height !== 210
        ) {
          this.bottomSidePanel.nativeElement.style.minHeight =
            this.localStorageData.bottomPanel.height;
          this.heightGrid =
            window.innerHeight -
            Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
            200 -
            34 - 27;
          this.tabPanel.height = '100%';
          this.heightBottomGrid =
            Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
            25;
        } else {
          this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
          this.tabPanel.height = '34px';
          this.bottomSidePanel.nativeElement.style.height = `34px`;
          // this.heightGrid += 256;
          this.heightGrid = window.innerHeight - 180 - 27;
        }
        if (!this.localStorageData.bottomPanel.isVisible) {
          this.isDetailOnFocus = false;
        }
        this.cd.detectChanges();
      }
    )
  };

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  onOptionChanged = (e) => {
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      this.event.onOptionChanged(e);
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {
      }
    }
  };

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
  }

  isQuickPress = true;
  onInitialized() {
    this.isQuickPress = true;
  }

  heightBottomPanel;
  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  resizingBottom = (e) => {
    let height;
    this.localStorageData.bottomPanel.isVisible = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '235px';
    if (height < 235) {
      height = 235;
    }
    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 25;
    this.tabPanel.height = '100%';

    this.heightGrid = window.innerHeight - height - 200 - 34 - 27;
    this.localStorageData.bottomPanel.height = `${height}px`;
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  onItemClickTab = () => {
    try {
      this.localStorageData.bottomPanel.isVisible = true;
      if (
        this.localStorageData.bottomPanel.isVisible &&
        this.localStorageData.bottomPanel.height === 210
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
        this.heightGrid = window.innerHeight - 435 - 34 - 27;
        this.tabPanel.height = '100%';
        this.heightBottomGrid = 210;
      } else if (
        this.localStorageData.bottomPanel.isVisible &&
        this.localStorageData.bottomPanel.height !== 210
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanel.height;
        this.heightGrid =
          window.innerHeight -
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          200 -
          34 - 27;
        this.tabPanel.height = '100%';
        this.heightBottomGrid =
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          25;
      } else {
        this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
        this.tabPanel.height = '34px';
        this.bottomSidePanel.nativeElement.style.height = `34px`;
        // this.heightGrid += 256;
        this.heightGrid = window.innerHeight - 180 - 27;
      }
      if (!this.localStorageData.bottomPanel.isVisible) {
        this.isDetailOnFocus = false;
      }      // if (this.localStorageData.bottomPanel.isVisible == null) {
      //   this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
      //   this.heightGrid = window.innerHeight - 435 - 27;
      //   this.tabPanel.height = '100%';
      // } else if (this.localStorageData.bottomPanel.isVisible != null) {
      //   this.bottomSidePanel.nativeElement.style.minHeight =
      //     this.localStorageData.bottomPanel.height;
      //   this.heightGrid = window.innerHeight - 435 - 34 - 27;
      //   this.tabPanel.height = '100%';
      // }
    } catch {
    }
  };

  focusedSelectedRouteId: number;
  firstLoadDetail = true;

  onFocusedRowChangedDetail(e) {
    this.focusedSelected2 = [e?.row?.data];
    this.selectedStatus = e?.row?.data?.RegistrationType;
    if (this.firstLoadDetail) {
      this.firstLoadDetail = false;
      this.searchControl?.focusInput();
      this.cd.detectChanges();
    }
  }

  onFocusElementChanged(e: boolean) {
    this.isDetailOnFocus = e;
  }

  addNewContractors() {
    this.isCustomerVisible = true;
  }

  deleteContractors() {
    if (this.focusedSelected2.length != 0 || this.selectedPositionDocument.length != 0) {
      const selectedAmountRoutePos: number = this.selectedPositionDocument.length;
      const routePosId: number = this.focusedSelected2[0].RoutePosId;

      this.appService.deleteAuth(`customers/RoutePos/${selectedAmountRoutePos == 0 ? routePosId : 0}`)
        .subscribe({
          next: () => {
            this.dataSourceDetail.reload();
            this.confirmDeleteRoutesContractorVisible = false;
          },
          error: (error) => {
            this.confirmDeleteRoutesContractorVisible = false;
            this.event.httpErrorNotification(error);
          }
        });
    }
  }

  onRowDblClick() {

  }

  onSaveStatusRoute() {
    const params = {
      routePosId: this.focusedSelected2[0].RoutePosId,
      registrationType: this.selectedStatus
    };

    this.appService
      .postAuth(`customers/RouteRegistrationTypeAsynch`, null, params)
      .subscribe({
        next: () => {
          this.isVisibleChangeRouteStatus = false;
          this.dataSourceDetail.reload();
          this.dataSource.reload();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        }
      });
  }

  onCancelStatusRoute() {
    this.isVisibleChangeRouteStatus = false;
    this.cd.detectChanges();
  }

  selectedRowsData: any;
  toDeselect = [];
  toSelect = [];
  sendRequestTimer;
  onSelectionChanged = (e) => {
    this.selectedRowsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource?._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource?._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let toSelect = [];

        this.selectedRows.forEach((field) => {
          if (typeof field == 'object') {
            toSelect.push(field.RouteId);
          } else {
            toSelect.push(field);
          }
        });

        this.toSelect = [];
        this.event.selectRecords(
          `customers/selection/customer/route/select`,
          toSelect
        );
      }
      if (this.toDeselect.length) {
        let toDeselect = [];
        this.toDeselect.forEach((field) => {
          if (typeof field == 'object') {
            toDeselect.push(field.RouteId);
          } else {
            toDeselect.push(field);
          }
        });
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of toDeselect) {
          const item = items.find((el) => el.RouteId === toDes);
          if (!item) {
            toRemove.push(toDeselect.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          toDeselect.splice(toRemove[i], 1);
        }
        this.selectedAmount.set(this.selectedAmount() - toDeselect.length);
        if (this.selectedAmount() < 0) {
          this.selectedAmount.set(0);
        }
        this.event.selectRecords(
          `customers/selection/customer/route/unselect`,
          toDeselect
        );
        toDeselect = [];
        this.toDeselect = [];
      }

      this.sendRequestTimer = null;
    }, 500);
  }

  deselectAllRecords() {
    this.isAllSelected = false;
    this.selectedAmount.set(0);
    return this.event.deselectAllRecords(
      'customers/selection/customer/route/unselectall'
    );
  }

  selectedRowsDataPosition: any;
  toDeselectPosition = [];
  toSelectPosition = [];
  sendRequestTimerPosition;

  onSelectionPositionChanged(e) {
    this.selectedRowsDataPosition = e.selectedRowsData;
    if (this.skipSendSelectionDetailEvent) {
      this.toSelectPosition = [];
      this.toDeselectPosition = [];
      this.skipSendSelectionDetailEvent = false;
      return;
    }

    if (!this.onlySelectedPosition) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSourceDetail._totalCount
      ) {
        this.deselectAllPositionRecords();
        return;
      }
    }

    this.toSelectPosition = this.toSelectPosition.concat(e.selectedRowKeys);
    this.selectedAmountPosition.set(
      this.selectedAmountPosition() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmountPosition() < 0) {
      this.selectedAmountPosition.set(0);
    }
    this.toDeselectPosition = this.toDeselectPosition.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimerPosition) {
      clearTimeout(this.sendRequestTimerPosition);
      this.sendRequestTimerPosition = null;
    }

    if (
      this.selectedPositionDocument.length === 0 ||
      (this.selectedPositionDocument.length &&
        this.selectedPositionDocument.length !== this.dataSourceDetail?._items.length)
    ) {
      this.isAllSelectedPosition = false;
    }

    if (this.dataSourceDetail?._items.length) {
      this.setSelectionPositionTimeout();
    }
  }

  setSelectionPositionTimeout() {
    this.sendRequestTimerPosition = setTimeout(() => {
      if (this.toSelectPosition.length) {
        let toSelect = [];

        this.toSelectPosition.forEach((field) => {
          if (typeof field == 'object') {
            toSelect.push(field.RoutePosId);
          } else {
            toSelect.push(field);
          }
        });

        this.toSelectPosition = [];
        if (toSelect.length > 0) {
          this.event.selectRecords(
            `customers/selection/customer/routepos/select`,
            toSelect
          );
        }
      }
      if (this.toDeselectPosition.length) {
        let toDeselect = [];
        this.toDeselectPosition.forEach((field) => {
          if (typeof field == 'object') {
            toDeselect.push(field.RoutePosId);
          } else {
            toDeselect.push(field);
          }
        });
        const items = this.dataSourceDetail.items();
        const toRemove = [];
        for (let toDes of toDeselect) {
          const item = items.find((el) => el.RoutePosId === toDes);
          if (!item) {
            toRemove.push(toDeselect.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          toDeselect.splice(toRemove[i], 1);
        }
        this.selectedAmountPosition.set(this.selectedAmountPosition() - toDeselect.length);
        if (this.selectedAmountPosition() < 0) {
          this.selectedAmountPosition.set(0);
        }
        if (toDeselect.length > 0) {
          this.event.selectRecords(
            `customers/selection/customer/routepos/unselect`,
            toDeselect
          );
        }
        toDeselect = [];
        this.toDeselectPosition = [];
      }

      this.sendRequestTimerPosition = null;
    }, 500);
  }


  deselectAllPositionRecords() {
    this.isAllSelectedPosition = false;
    this.skipSendSelectionDetailEvent = true;
    this.selectedAmountPosition.set(0);
    return this.event.deselectAllRecords(
      'customers/selection/customer/routepos/unselectall'
    );
  }

  switchOrderPosition() {
    if (this.localStorageData.bottomPanelFilter.order === 'ASC') {
      this.localStorageData.bottomPanelFilter.order = 'DESC';
      return;
    }

    this.localStorageData.bottomPanelFilter.order = 'ASC';
  }

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }

    this.localStorageData.filter.order = 'ASC';
  }

  // Metoda wywoływana podczas rozpoczęcia przeciągania
  onDragStart(e: any): void {
    console.log('Drag started', e);
  }

  // Metoda wywoływana po dodaniu elementu
  onAdd(e: any): void {
    console.log('Element added', e);
  }

  // Metoda wywoływana po usunięciu elementu
  onRemove(e: any): void {
    console.log('Element removed', e);
  }

  // Poprawiona metoda zmieniająca kolejność elementów
  onReorder(e: any) {
    const fromIndex = e.fromIndex;
    const toIndex = e.toIndex;

    // if (fromIndex < toIndex) {
    //   this.moveOrderMobile('down', fromIndex, toIndex);
    // } else if (fromIndex > toIndex) {
    //   this.moveOrderMobile('up', fromIndex, toIndex);
    // }

    const dataSelectedRow = this.dataSourceDetail._items[fromIndex];
    const orderSelectedNo = dataSelectedRow?.Orders;

    const newDataRow = this.dataSourceDetail._items[toIndex];

    let newOrderNo: number;
    let paramsNewOrder: any;
    let paramsReverseOrder: any;

    newOrderNo = newDataRow?.Orders;

    this.cd.detectChanges();

    if (dataSelectedRow && newDataRow) {

      paramsNewOrder = {
        routePosId: newDataRow.RoutePosId,
        orderNo: orderSelectedNo
      };

      paramsReverseOrder = {
        routePosId: dataSelectedRow.RoutePosId,
        orderNo: newOrderNo
      };

      let arrayForkJoin = [];

      arrayForkJoin.push(
        this.appService.postAuth(`customers/selection/customer/routepos/order`, null, paramsNewOrder)
      );
      arrayForkJoin.push(
        this.appService.postAuth(`customers/selection/customer/routepos/order`, null, paramsReverseOrder)
      );

      forkJoin(arrayForkJoin).subscribe({
        next: () => {
          this.getRouteDetails(this.focusedSelectedRouteId);
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        }
      });
    }


    // Informujemy DevExtreme o zmianie w źródle danych

    console.log('Reordering', fromIndex, toIndex);
    console.log('onReorder', e);
    console.log('dataRoutesPosition[fromIndex]', this.dataRoutesPosition[fromIndex]);

  }

  // moveOrderMobile(direction: 'up' | 'down', fromIndex: number, toIndex: number) {
  //
  // }

  moveOrder(direction: 'up' | 'down') {
    const selectedIndex = this.focusedRowIndexPosition;
    const dataSelectedRow = this.dataRoutesPosition[selectedIndex];
    const orderSelectedNo = dataSelectedRow?.Orders;

    let newIndex: number;
    let newOrderNo: number;
    let paramsNewOrder: any;
    let paramsReverseOrder: any;

    if (direction === 'down') {
      newIndex = selectedIndex + 1;
      this.cd.detectChanges();
    } else if (direction === 'up') {
      newIndex = selectedIndex - 1;
      this.cd.detectChanges();
    }

    const newDataRow = this.dataRoutesPosition[newIndex];
    this.cd.detectChanges();

    newOrderNo = newDataRow?.Orders;

    if (dataSelectedRow && newDataRow) {
      paramsNewOrder = {
        routePosId: newDataRow.RoutePosId,
        orderNo: orderSelectedNo
      };

      paramsReverseOrder = {
        routePosId: dataSelectedRow.RoutePosId,
        orderNo: newOrderNo
      };

      let arrayForkJoin = [];

      arrayForkJoin.push(
        this.appService.postAuth(`customers/selection/customer/routepos/order`, null, paramsNewOrder)
      );
      arrayForkJoin.push(
        this.appService.postAuth(`customers/selection/customer/routepos/order`, null, paramsReverseOrder)
      );

      forkJoin(arrayForkJoin).subscribe({
        next: () => {
          this.dataSourceDetail.reload().then(() => {
            setTimeout(() => {
              this.gridBank2.instance.option('focusedRowIndex', newIndex);
              this.cd.detectChanges();
            }, 200);
          });
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        }
      });
    }
  }


  dataSourceDetail;
  isCustomerVisible: boolean = false;
  dataRoutesPosition = [];
  firstLoadPosition = true;
  addresses = [];
  amountOfRealizedRoute = 0;

  getRouteDetails(id) {
    if (!id) {
      return;
    }

    this.dataSourceDetail = new DataSource({
      store: AspNetData.createStore({
        key: 'RoutePosId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}customers/CustomerRoutesPos?routeId=${id}`,
        loadParams: this.getLoadDetailParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedRowIndexPosition = -1;
            this.focusedSelected2 = [];
            this.isData = false;
            this.isDeleteDisabledDetail.set(true);
            this.addresses = [];
            this.selectedAmountPosition.set(0);

            this.dataRoutesPosition = [];
            this.amountOfRealizedRoute = 0;
            this.cd.detectChanges();
          } else {
            this.selectedAmountPosition.set(0);

            this.isData = true;

            this.dataRoutesPosition = data;

            this.amountOfRealizedRoute = this.dataRoutesPosition.filter(data => data.RegistrationType === 2).length

            const addresses = this.dataRoutesPosition
              .filter(item => item.DeliveryCity)
              .map(item => {
                if (!item.DeliveryCity) {
                  return null;
                }

                let address = `${item.DeliveryPostalCode || ''} ${item.DeliveryCity} ${item.DeliveryStreet ? item.DeliveryStreet.trim() : ''} ${item.DeliveryHouseNumber ? item.DeliveryHouseNumber.trim() : ''} ${item.DeliveryHoNr ? item.DeliveryHoNr.trim() : ''}`;

                // Usuwamy nadmiarowe spacje z adresu
                address = address.replace(/\s+/g, ' ').trim();

                // Usuwamy "ul.", "ul", "ulica" i różne warianty z pola Street
                address = address.replace(/\b(ulica?|ul\.?)\b/gi, '').trim();

                // Usuwamy zbędne kropki
                address = address.replace(/\./g, '');

                // Usuwamy nadmiarowe spacje
                address = address.replace(/\s+/g, ' ').trim();

                return {
                  address,
                  orders: item.Orders,
                  registrationType: item.RegistrationType // Add this line to include RegistrationType
                };
              })
              .filter(item => item !== null) // Filtruj null wartości
              .sort((a, b) => a.orders - b.orders) // Sortowanie według Orders
            //   .map(item => item.address); // Tworzenie tablicy adresów
            // this.addresses = addresses.filter(address => address.length > 0);
            this.addresses = addresses;

            const toAdd = data.filter(
              (el) =>
                el.IsSelected && this.selectedRows.indexOf(el.RoutePosId) === -1
            );
            for (let item of toAdd) {
              this.selectedPositionDocument.push(item.RoutePosId);
              this.skipSendSelectionDetailEvent = true;
            }

            const toDelete = [];
            for (let id of this.selectedPositionDocument) {
              const item = data.find((el) => el.RoutePosId === id);
              if (item && !item.IsSelected) {
                toDelete.push(id);
              }
            }

            for (let id of toDelete) {
              this.selectedPositionDocument.splice(
                this.selectedPositionDocument.findIndex((el) => el === id),
                1
              );
              this.skipSendSelectionDetailEvent = true;
            }

            if (this.firstLoadPosition) {
              this.firstLoadPosition = false;
              setTimeout(() => {
                  this.focusedRowIndexPosition = 0;
                  this.onFocusedRowChangedDetail({
                    row: {data: data[this.focusedRowIndexPosition]},
                  });
                }
              );
            } else {
              setTimeout(() => {
                  if (this.focusedRowIndexPosition === -1) {
                    this.focusedRowIndexPosition = 0;
                  }
                  this.onFocusedRowChangedDetail({
                    row: {data: data[this.focusedRowIndexPosition]},
                  });
                  this.cd.detectChanges();
                }
              );
            }

            this.cd.detectChanges();
          }

          // const selected: any[] = data
          //   .filter((el) => el.IsSelected)
          //   .map((el) => el.IntrastatePositionId);

          // if (selected.length > 0) {
          //   this.event.deselectAllRecords(`intrastates/selection/positions`);
          //   this.selectedPositionDocument = [];
          // }

        },
      }),
      reshapeOnPush: true,
    });
  }

  getLoadDetailParams() {

    const obj: any = {};

    if (this.event.deviceType === 'mobile') {
      obj.OrderBy = 'Orders';
      obj.Order = 'ASC';

      switch (this.selectedSearchMobileFilter) {
        case 'Name':
          obj.Name = this.localStorageData.bottomPanelFilter.value;
          break;
        case 'Orders':
          obj.Orders = this.localStorageData.bottomPanelFilter.value;
          break;
        case 'City':
          obj.City = this.localStorageData.bottomPanelFilter.value;
          break;
        case 'Province':
          obj.Province = this.localStorageData.bottomPanelFilter.value;
          break;
      }
      this.cd.detectChanges();
      return obj;
    }


    obj.OrderBy = this.localStorageData.bottomPanelFilter.orderBy;
    obj.Order = this.localStorageData.bottomPanelFilter.order;

    switch (this.localStorageData.bottomPanelFilter.orderBy) {
      case 'Name':
        obj.Name = this.localStorageData.bottomPanelFilter.value;
        break;
      case 'Orders':
        obj.Orders = this.localStorageData.bottomPanelFilter.value;
        break;
      case 'City':
        obj.City = this.localStorageData.bottomPanelFilter.value;
        break;
      case 'Province':
        obj.Province = this.localStorageData.bottomPanelFilter.value;
        break;
    }

    // if (this.localStorageData.advancedFilter) {
    //   this.event.addAdvancedFiltersToLoadParams(
    //     obj,
    //     this.localStorageData.advancedFilter.UserFilterDefinitionValues
    //   );
    // }

    return obj;
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.bottomPanelFilter.orderBy) {
      this.localStorageData.bottomPanelFilter.orderBy = orderBy;
    } else {
      this.switchOrderPosition();
    }
    this.cd.detectChanges();
    this.getRouteDetails(this.focusedSelectedRouteId);
  };

  setSearchMainCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.cd.detectChanges();
    this.getRoutes(true);
  };

  updateRoutes = async (e) => {
    try {
      this.updateRoute = true;

      await this.dataSource.reload();

      this.focusedRowIndex = this.event.returnFocusIndex(
        this.dataSource?._items,
        'RouteId',
        e
      );

      // this.onFocusedRowChanged({row: {data: this.dataSource[this.focusedRowIndex]}});
      this.focusedSelected = [this.dataSource._items[this.focusedRowIndex]];
      this.focusedSelectedRouteId = this.focusedSelected[0].RouteId;
      this.getRouteDetails(this.focusedSelectedRouteId);

      // if (this.mode === 'edit') {
      //   this.availableValue = [this.focusedBox[0]];
      //   this.choosingValue = this.availableValue[0].PackagingId;
      // }

      this.event.setFocus(this.gridBank);
      this.cd.detectChanges();
    } catch (error) {
      this.event.httpErrorNotification(error);
    }
  };

  customerSelected = [];
  multipleSelectCustomers = false;
  confirmAddCustomerWithoutAddress = false

  onChoosedCustomer(e: any) {
    if (e) {
      this.customerSelected = [e];
      this.isCustomerVisible = false;
      if (!e.City) {
        this.confirmAddCustomerWithoutAddress = true;
      }
      this.addCustomerRoute();
    } else {
      this.customerSelected = [];
    }
  }

  closingConfirmCustomerWithoutAddress() {
    this.confirmAddCustomerWithoutAddress = false;
  }

  isDisabled(): boolean {
    return this.focusedSelected.length === 0 || this.readOnly || this.dataRoutesPosition.length === 0 || !this.isData;
  }

  addCustomerRoute() {
    if (this.customerSelected.length === 0) {
      return;
    }

    const params = {
      RouteId: this.focusedSelectedRouteId,
      RoutePosId: 0,
      CustomerId: this.multipleSelectCustomers ? 0 : this.customerSelected[0].CustomerId,
      CustomerNo: 0,
      Name: '',
      FullName: '',
      Street: '',
      PostalCode: '',
      City: '',
      Province: '',
      VatNumber: '',
      REGON: '',
      Remarks: '',
      Orders: 0,
      Field1: '',
      Field2: '',
      Field3: '',
      Field4: '',
      Field5: '',
      Field6: '',
      Field7: '',
      Field8: '',
      Field9: '',
      Field10: ''
    };

    this.appService.postAuth(`customers/CustomerRoutePos`, params)
      .subscribe(
        () => {
          this.getRouteDetails(this.focusedSelectedRouteId);
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onDeleteRoutesVisible() {
    if (this.focusedSelected.length === 0) {
      return;
    }

    this.appService.deleteAuth(`customers/Route/${this.focusedSelected[0]?.RouteId}`)
      .subscribe(
        () => {
          this.dataSource.reload();
          this.confirmDeleteRoutesVisible = false;
        },
        (error) => {
          this.event.httpErrorNotification(error);
          this.confirmDeleteRoutesVisible = false;
        }
      );
  }

  cancelConfirmDeleteRoutes() {
    this.confirmDeleteRoutesVisible = false;
  }

  cancelConfirmDeleteRoutesContractor() {
    this.confirmDeleteRoutesContractorVisible = false;
  }

  saveViewConfigurations = () => {
    let object = {
      View: 'ContractorsRoutesComponent',
      Configuration: JSON.stringify(this.localStorageData),
      TenantId: this.event.footerInfo.TenantId,
    };
    localStorage.setItem(
      'ContractorsRoutesComponent',
      JSON.stringify(this.localStorageData)
    );
    this.appService.postAuth(`viewConfigurations`, object).subscribe(() => {
    });
  };
  protected readonly document = document;

  ngOnDestroy() {
    this.currentDateSub?.unsubscribe();

    if (this.selectedAmountPosition() > 0) {
      this.deselectAllPositionRecords();
    }
    if (this.selectedAmount() > 0) {
      this.deselectAllRecords();
    }

    this.localStorageData.columns = this.event.setWidthColumnGrid(
      this.gridBank
    );
    this.saveViewConfigurations();

    if (
      localStorage.getItem('ContractorsRoutesComponent') &&
      JSON.stringify(this.localStorageData) != localStorage.getItem('ContractorsRoutesComponent')
    ) {
      this.saveViewConfigurations();
    }
  }

  onKeyDown2(e) {
    if (e.event.ctrlKey && e.event.key == 'ArrowDown') {
      this.moveOrder('down');
    } else if (e.event.ctrlKey && e.event.key == 'ArrowUp') {
      this.moveOrder('up');
    } else if (e.event.key === 'Insert' && e.event.ctrlKey) {
      this.addNewContractors();
    } else if (e.event.key === 'Delete' && e.event.ctrlKey) {
      this.confirmDeleteRoutesContractorVisible = true;
    } else if (e.event.key === 'F2' && e.event.ctrlKey) {
      this.editingRouteAddressVisible = true;
    } else if (e.event.ctrlKey && e.event.key === 'u') {
      if (!this.readOnly && this.focusedSelected.length > 0) {
        this.routeUsersVisible = true;
        this.cd.detectChanges();
      } else if (e.event.ctrlKey && e.event.key === 'm') {
        if (!this.readOnly && this.focusedSelected.length > 0 && this.focusedSelected2.length > 0) {
          this.routeMapVisible = true;
          this.cd.detectChanges();
        }
      }
    }
  }

  onInsertedUserExtensions(e: any) {
    const selectedAmountRoutePos: number = this.selectedPositionDocument.length;
    const routePosId = this.focusedSelected2[0]?.RoutePosId;

    if (selectedAmountRoutePos > 0) {
      this.additionalFieldsListPos = {
        Field1: '',
        Field2: '',
        Field3: '',
        Field4: '',
        Field5: '',
        Field6: '',
        Field7: '',
        Field8: '',
        Field9: '',
        Field10: ''
      };
    }

    const uri = selectedAmountRoutePos === 0 ?
      `customers/customerRoutesPos/additionalOperations/${routePosId}/setAdditionalFields`
      :
      `customers/customerRoutesPos/additionalOperations/setAdditionalFields`;
    this.appService.putAuth(uri, e).subscribe({
      next: () => {
        this.dataSourceDetail.reload();
        this.isVisibleExternalFieldsPos = false;
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      }
    });

  }

  touchTimeout: any;

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridBank.instance.getDataSource().items()[
        this.focusedRowIndex
        ];
      const id = row.ProductionDocumentId;
      if (!this.selectedRows.includes(id)) {
        this.gridBank.instance.selectRows(id, true);
      } else {
        this.gridBank.instance.deselectRows(id);
      }
    }, 1500);
  }

  onClosingUserExtensions() {
    this.isVisibleExternalFieldsPos = false;
  }

  isDetailRouteMobileVisible = false;
  selectedItemDetailKeys = []

  onSaveRouteMobileEmitter($event: boolean) {
    if ($event) {
      this.selectedIndex = 0;
      this.isShowMoreMobileDetails = false;
      this.isDetailRouteMobileVisible = true;
      this.normalMode = true;
    }
  }

}
