import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {AppServices} from 'src/app/app-services.service';
import {UserDefinition} from 'src/app/core/filter-panel/filterpanel';
import {CustomWindowConfig, RefreshCardResult} from 'src/app/event.model';
import {EventService} from 'src/app/event.service';
import {environment} from 'src/environments/environment';
import {DefaultObject} from 'src/app/nest/nest.model';
import {TranslateService} from '@ngx-translate/core';
import {DxTabPanelComponent, DxTreeListComponent} from 'devextreme-angular';
import {ContentReadyEvent, KeyDownEvent} from 'devextreme/ui/data_grid';
import {StockAvailability} from './stock-availability.interface';
import {ICustomSearchItem} from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {HelpService} from 'src/app/help-service.service';
import {CustomDropdownBoxComponent} from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import {GlobalDate} from 'src/app/core/date-range/GlobalDate';
import {LoadOptions} from 'devextreme/data';
import {ICustomChipsDataSource} from 'src/app/core/custom-chips-button/custom-chips-button.model';
import {DateRangeComponent} from 'src/app/core/date-range/date-range.component';
import {PrintService} from 'src/app/print.service';
import {ReportData, ReportType} from 'src/app/core/reports/IReportParameters';
import {DatePipe} from '@angular/common';
import { Subscription, of, take, switchMap, finalize } from 'rxjs';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';

@Component({
    selector: 'app-stock-availability',
    templateUrl: './stock-availability.component.html',
    styleUrl: './stock-availability.component.scss',
    standalone: false
})
export class StockAvailabilityComponent {
  @Input('isPopupMode') isPopupMode: boolean;
  @Input('dropDownBoxMode') dropDownBoxMode: boolean;
  @Input('isVisible') isVisible: boolean;

  @Output('onClosing') onClosing: EventEmitter<any> = new EventEmitter();

  @ViewChild('gridBank') gridBank: DxDataGridComponent;
  @ViewChild('sidePanel') sidePanel: ElementRef;
  @ViewChild('mainPanel') mainPanel: ElementRef;
  @ViewChild('categoriesTree') categoriesTree: DxTreeListComponent;
  @ViewChild('multiLevelCategoriesTree') multiLevelCategoriesTree;
  @ViewChild('customSearch') customSearch: CustomDropdownBoxComponent;
  @ViewChild('bottomSidePanel') bottomSidePanel: ElementRef;
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;
  @ViewChild('dateRange') dateRange: DateRangeComponent;

  apiBasePath: string = 'StockAvailability';

  event = inject(EventService);
  sidePanelDefaultWidth: number = 290;
  sidePanelMaxWidth: number = 680;
  resizeIcon = 'icon absui-icon--arrow-drop-right';
  readonly DynamicFilterDictionaryCode = 'PRODUCT';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Artykul';
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  execType?: string = null;
  heightGrid
  pageSize: number = 100;

  bottomPanelDefaultHeight: number = 230;

  isCustomDropDownOpened: boolean = false;
  filterCriteria: ICustomSearchItem[] = [
    {
      value: 'ProductNameIntuition',
      label: 'nazwa',
      translationSrc: 'articles.filter.name',
    },
    {
      value: 'ProductFullNameIntuition',
      label: 'nazwa cała',
      translationSrc: 'articles.filter.fullName',
    },
    {
      value: 'IndexCatalogueIntuition',
      label: 'indeks katalogowy',
      translationSrc: 'articles.catalogueIndex',
    },
    {
      value: 'IndexTradingIntuition',
      label: 'indeks handlowy',
      translationSrc: 'articles.tradingIndex',
    },
    {
      value: 'EAN',
      label: 'kod kreskowy',
      translationSrc: 'articles.filter.ean',
    },
  ];
  filterValue: any = '';
  inputServices: boolean = false;
  isAverage: boolean = false;

  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  initialStorageData = null;
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'ProductNameIntuition',
      order: 'ASC',
    },
    tree: [
      {
        productCategoryId: -1,
        productCategoryName: 'Wszystkie',
      },
      {
        productCategoryId: -1,
        productCategoryName: 'Wszystkie',
        categoryTreeWithChilds: true,
      },
    ],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: false,
      height: 210,
    },
    mainPanelWidth: `calc(100% - ${this.sidePanelDefaultWidth}px)`,
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: this.sidePanelDefaultWidth,
    },
    windowSize: {
      isFullscreen: false,
      width: this.event.setWidthPopUp(),
      height: '90%',
    },
    params: [],
  };
  additionalPermissions = {
    konfKol: false,
  };

  bottomPanelTabs: any[] = [{title: 'Szczegóły', disabled: false}];
  componentVisible: boolean = false;
  hasBeenOpen: boolean = false;
  popupWidth: number = 800;
  onInitErr: boolean;

  focusedArticle: number = undefined;
  selectedRowKeys: number[] = [];
  dataSource: DataSource;
  selectedAll: boolean = false;
  onlySelected: boolean;
  skipSendSelectionEvent: boolean;
  analysisResult: any;
  isGuideButtonHighlighted = false;
  columnsChooserVisible: boolean = false;
  categoriesList;
  readOnly: boolean = false;
  focusedArticleData: StockAvailability = undefined;
  shortcuts: ShortcutInput[] = [];
  isFilterPanelComponent: boolean = false;
  visibleAdvanceFiltersWithoutPanel: boolean = false;
  isDetailOnFocus: boolean = false;

  suppliesDataSource: DataSource;

  withoutComma: boolean = false;
  refreshCardResult: RefreshCardResult = 'Tak';
  groupsList: {
    expanded: boolean;
    Categories: {
      expanded: boolean;
      Categories: any;
      CategoryName: any;
      ParentCategoryTreeId: number;
      Guid: any;
      CategoryTreeId: any;
    }[];
    CategoryName: any;
    ParentCategoryTreeId: any;
    Guid: number;
    CategoryTreeId: number;
  }[];

  dynamicFilterUserDefinitions: any[] = [];
  viewUserDefinitionSub: Subscription = null;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  yesNoDataSource: ICustomChipsDataSource[] = [
    {label: 'Tak', value: true, translationSrc: 'yes'},
    {label: 'Nie', value: false, translationSrc: 'no'},
  ];
  analizedReportId: number;
  perABD = {
    addBtn: true,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  constructor(
    private appService: AppServices,
    private dynamicFilterService: DynamicFilterService,
    private print: PrintService,
    public cd: ChangeDetectorRef,
    public translate: TranslateService,
    public helpService: HelpService
  ) {
    this.getPermissions();
    this.getUserParameters();

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });

    this.getData();
  }

  getPermissions() {
    if (this.event.checkPermissions('RapAnalPlynnosci')) {
      this.perABD = {
        addBtn: true,
        editBtn: true,
        deleteBtn: true,
        showBtn: true,
      };
    } else {
      this.readOnly = true;
    }
  }

  ngOnDestroy() {
    this.currentDateSub?.unsubscribe();
    this.isAutoOnboardingSub?.unsubscribe();
  }

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        this.saveLocalStorageData();
        this.getData();
      }
    })
  }

  getUserParameters() {
    const withoutComma = this.event.getConfigurationParameter(
      'IloscArtBezPrzecinka'
    );
    if (withoutComma) {
      this.withoutComma = withoutComma.Value === 'Tak';
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }
  }

  ngOnInit(): void {
    setTimeout(async () => {
      this.componentVisible = true;
      await this.getViewConfigurations();

      this.cd.detectChanges();
    });

    this.getTranslations();
    this.getGroupList();
    this.getCategoriesList();
  }

  ngAfterViewInit() {
    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 210;
    }

    this.shortcuts.push(
      {
        key: 'ctrl + g',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: async () => {
          if (!this.readOnly) {
            this.analyze();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            // this.analyze();
            this.getData();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/menu-raporty/analiza-plynnosci'
          );
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.isCustomDropDownOpened = !this.isCustomDropDownOpened;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.openDateFilters();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex != 2) {
            this.localStorageData.sidePanel.selectedIndex = 2;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 2 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }

          this.resizeIcon = this.getResizeIcon();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.dateRange.openDateRange();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      }
    );
  }

  getTranslations() {
    this.event.translateSearchboxLabels(this.filterCriteria);
    this.event.translateSearchboxLabels(this.yesNoDataSource);
  }

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit();
      this.componentVisible = false;
      this.event.onHiddenPopUp();
    } else {
      this.componentVisible = true;
      if (!this.hasBeenOpen) {
        this.hasBeenOpen = true;
        this.getViewConfigurations();
      }
    }
  }

  getViewConfigurations = () => {
    return new Promise<void>((resolve) => {
      try {
        const storageName = 'stockAvailabilityComponent';
        const storageApiName = 'stockAvailabilityPanel';
        const localData = localStorage.getItem(storageName);
        if (!localData) {
          this.appService
            .getAuth(`viewConfigurations/${storageApiName}`)
            .subscribe((res) => {
              if (res.Configuration) {
                this.localStorageData = Object.assign(
                  this.localStorageData,
                  JSON.parse(res.Configuration)
                );
              }

              if (!this.localStorageData.sidePanel) {
                this.localStorageData.sidePanel = {
                  selectedIndex: 0,
                  isVisible: true,
                  width: this.sidePanelDefaultWidth,
                };
              }

              this.saveLocalStorageData();
              resolve(this.getViewConfigurations());
            });
        } else if (localData && !this.dropDownBoxMode) {
          this.localStorageData = JSON.parse(localData);
          if (!this.localStorageData.sidePanel) {
            this.localStorageData.sidePanel = {
              selectedIndex: 0,
              isVisible: true,
              width: this.sidePanelDefaultWidth,
            };
          }
          this.initialStorageData = JSON.parse(localData);
          try {
            setTimeout(() => {
              if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
                this.getDefaultFilter();
              }
              else if (
                this.localStorageData?.advancedFilter
                  ?.FilterUsersDefinitionId ||
                this.localStorageData?.advancedFilter?.IsPinned
              ) {
                this.getSavedFilterDefinitions();
              }

              if (this.localStorageData?.advancedFilter?.PinnedFilters) {
                this.dynamicFilterService.addPinnedViewsFilters(
                  this.DynamicFilterDictionaryCode,
                  this.localStorageData.advancedFilter.PinnedFilters
                );
              }
            });
          } catch (e) {
            this.onInitErr = true;
          }

          const sidePanel = this.localStorageData.sidePanel;
          this.sidePanel.nativeElement.style.width = sidePanel.isVisible
            ? `${sidePanel.width}px`
            : '10px';
          this.resizeIcon = this.getResizeIcon();
          resolve();
        }
      } catch (e) {
        this.resizeIcon = this.getResizeIcon();
        this.onInitErr = true;
        resolve();
      }
    });
  };

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  setFocus() {
    setTimeout(() => {
      this.event.setFocus(this.gridBank);
      this.cd.detectChanges();
    }, 400);
  }

  onPopupInitialized(e: any) {
    e.component.registerKeyHandler('escape', function() {
    });
  }

  onPopupPrimaryButtonClick() {
    this.onClosing.emit();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.getData();
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getData();
  }

  preventRefresh: boolean = false;

  clearValues(onlySelected: boolean = false) {
    this.preventRefresh = true;
    this.filterValue = '';
    this.onlySelected = onlySelected;
    this.deleteFilter = !this.deleteFilter;
    this.resetFiltrWithOutRefresh('productCategoryName');
    this.resetFiltrWithOutRefresh('productCategoryTreeName');
    this.resetFiltr('dateFilter');
    this.dateRange.onValueChangedState('last30days');
  }

  resetFiltrWithOutRefresh(filterType: string) {
    if (filterType === 'productCategoryName') {
      this.localStorageData.tree[0].productCategoryId = -1;
      this.localStorageData.tree[0].productCategoryName = this.everything;
      this.categoriesSelectedRowKeys = [-1];
      this.categoriesTree?.instance.selectRows([-1], false);
    } else if (filterType === 'productCategoryTreeName') {
      this.localStorageData.tree[1].productCategoryTreeId = -1;
      this.localStorageData.tree[0].productCategoryTreeName = this.everything;
      this.categoriesTreeSelectedRowKeys = [-1];
      this.localStorageData.tree[1].categoryTreeWithChilds = false;
      this.multiLevelCategoriesTree?.instance.selectRows([-1]);
    }
  }

  async getData(focusToSearch: boolean = false) {
    this.event.onShown();
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProductId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.apiBasePath}`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}products`,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          this.event.onHidden();
          if (!data.length) {
            this.focusedArticle = undefined;
            return;
          }
          if (focusToSearch) {
            setTimeout(() => {
              this.customSearch?.focusInput();
            }, 500);
          } else {
            this.setFocus();
          }
          setTimeout(() => {
            this.cd.detectChanges();
          }, 100);
        },
      }),
    });
  }

  refresh() {
    this.getData();
  }

  getDetails() {
    const params = {
      recordId: this.focusedArticleData.RecordId,
      productId: this.focusedArticleData.ProductId,
    };
    let queryStr: string = '?';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        queryStr += `${key}=${element}&`;
      }
    }
    this.appService
      .postAuth(`${this.apiBasePath}/Details${queryStr}`, params)
      .subscribe({
        next: (res) => {
          this.suppliesDataSource = res.data;
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  paramsObj: any;

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }

    let orderBy = this.localStorageData.filter.orderBy;
    // switch (this.localStorageData.filter.orderBy) {
    //   case 'ProductNameIntuition':
    //     orderBy = 'ProductName';
    //     break;
    //   case 'ProductFullNameIntuition':
    //     orderBy = 'ProductFullName';
    //     break;
    //   case 'IndexCatalogueIntuition':
    //     orderBy = 'IndexCatalogue';
    //     break;
    //   case 'IndexTradingIntuition':
    //     orderBy = 'IndexTrading';
    //     break;
    // }

    let obj: any = {
      orderBy: orderBy,
      order: this.localStorageData.filter.order,
      raportId: this.analizedReportId,
    };

    if (this.filterValue && orderBy) {
      obj[orderBy] = this.filterValue;
    }

    if (this.localStorageData.tree[0].productCategoryId) {
      obj.CategoryId = this.localStorageData.tree[0].productCategoryId;
    }

    if (
      this.localStorageData.tree[1].productCategoryTreeId !== null &&
      this.localStorageData.tree[1].productCategoryTreeId != -1 &&
      this.localStorageData.tree[1].productCategoryTreeId
    ) {
      obj.categoryTreeId = this.localStorageData.tree[1].productCategoryTreeId;
    }

    obj.categoryTreeWithChilds =
      this.localStorageData.tree[1].categoryTreeWithChilds;

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    if (this.isDateFilterActive) {
      const val = this.dateFilter;
      if (val.firstSaleDaysMax) {
        obj.FirstSelPriceLess = val.firstSaleDaysMax;
      }
      if (val.firstSaleDaysMin) {
        obj.FirstSelPriceBigger = val.firstSaleDaysMin;
      }
      if (val.saleTimeMax) {
        obj.TimeSelLess = val.saleTimeMax;
      }
      if (val.saleTimeMin) {
        obj.TimeSelBigger = val.saleTimeMin;
      }
    }

    this.paramsObj = obj;
    return obj;
  }

  isAnalyzeInProgress: boolean = false;
  filterMobileVisible: boolean = false;

  async analyze() {
    this.preventRefresh = false;
    if (this.isAnalyzeInProgress) {
      return;
    }
    this.event.onShown();
    this.isAnalyzeInProgress = true;
    const dateFrom = this.currentDate.dateFrom
      .toISOString()
      .split('T')[0]
      .toString();
    const dateTo = this.currentDate.dateTo
      .toISOString()
      .split('T')[0]
      .toString();
    const obj = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      inputServices: this.inputServices,
      isAverage: this.isAverage,
    };

    // transform obj to http query string
    let queryStr: string = '?';
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const element = obj[key];
        queryStr += `${key}=${element}&`;
      }
    }

    // const query = `?dateFrom=${dateFrom}&dateTo=${dateTo}&inputServices=${this.inputServices}&isAverage=${this.isAverage}`
    this.analizedReportId = await new Promise((resolve) => {
      this.appService
        .postAuth(`${this.apiBasePath}/Add${queryStr}`, obj)
        .pipe(
          finalize(() => {
            this.event.onHidden();
            setTimeout(() => {
              this.isAnalyzeInProgress = false;
              this.cd.detectChanges();
            }, 1000);
          })
        )
        .subscribe({
          next: (res: { Id: number }) => {
            resolve(res?.Id);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
            resolve(null);
          },
        });
    });
    this.getData();
  }

  onInputSericesValueChanged(e) {
    this.inputServices = e ? true : false;
    // this.analyze();
  }

  onIsAverageValueChanged(e) {
    this.isAverage = e ? true : false;
    // this.analyze();
  }

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 2,
      isPanelVisible: this.localStorageData?.sidePanel?.isVisible,
    };

    if (!this.localStorageData?.sidePanel) {
      this.localStorageData.sidePanel = {
        isVisible: true,
        selectedIndex: 0,
        width: this.sidePanelDefaultWidth,
      };
    }
    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
      config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData?.sidePanel.selectedIndex ==
      config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
    this.resizeIcon = this.getResizeIcon();
  };

  hideRightPanel = () => {
    this.sidePanel.nativeElement.style.width = '10px';
    this.mainPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.width = 21;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.isVisible = false;
  };

  showRightPanel = () => {
    this.sidePanel.nativeElement.style.width = `${this.sidePanelDefaultWidth}px`;
    this.localStorageData.sidePanel.width = this.sidePanelDefaultWidth;
    this.mainPanel.nativeElement.style.width = `calc(100% - ${this.sidePanelDefaultWidth}px)`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${this.sidePanelDefaultWidth}px)`;
    this.localStorageData.sidePanel.isVisible = true;
  };

  isGuideActive = false;
  tabIndexBeforeGuide;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    // this.selectedtabIndex = this.tabIndexBeforeGuide;
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    this.saveLocalStorageData();
  }

  runGuide() {
    // this.tabIndexBeforeGuide = this.selectedtabIndex;
    this.isGuideActive = true;
  }

  openColumnsChooser() {
    this.columnsChooserVisible = true;
  }

  onResizeStart() {
    this.localStorageData.sidePanel.width =
      this.sidePanel.nativeElement.clientWidth;
    this.sidePanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizing(event) {
    let width;
    width = this.localStorageData.sidePanel.width - event.edges.left;
    if (width < this.sidePanelDefaultWidth) {
      width = this.sidePanelDefaultWidth;
    }
    if (width > this.sidePanelMaxWidth) {
      width = this.sidePanelMaxWidth;
    }
    this.sidePanel.nativeElement.style.width = `${width}px`;

    this.localStorageData.sidePanel.isVisible = true;
    this.resizeIcon = this.getResizeIcon();

    this.mainPanel.nativeElement.style.width = `calc(100% - ${width}px)`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${width}px)`;

    this.saveLocalStorageData();
  }

  resizeEnd() {
    this.sidePanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
    this.resizeIcon = this.getResizeIcon();
  }

  timeoutHandlerSidePanel;

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  resizeWindowBtn() {
    if (this.localStorageData.sidePanel.width > 25) {
      this.sidePanel.nativeElement.style.width = '10px';
      this.localStorageData.sidePanel.width = 21;
      this.mainPanel.nativeElement.style.width = `calc(100% - 26px)`;
      this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
      this.localStorageData.sidePanel.isVisible = false;
    } else {
      this.sidePanel.nativeElement.style.width = `${this.sidePanelDefaultWidth}px`;
      this.localStorageData.sidePanel.width = this.sidePanelDefaultWidth;
      this.mainPanel.nativeElement.style.width = `calc(100% - ${this.sidePanelDefaultWidth}px)`;
      this.localStorageData.mainPanelWidth = `calc(100% - ${this.sidePanelDefaultWidth}px)`;
      this.localStorageData.sidePanel.isVisible = true;
    }

    this.resizeIcon = this.getResizeIcon();

    this.saveLocalStorageData();
  }

  getResizeIcon() {
    return this.localStorageData.sidePanel.isVisible
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  mouseEnter = () => {
    this.sidePanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.sidePanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  everything = 'Wszystkie';
  getCategoriesList = () => {
    const body: DefaultObject = {skip: 0, take: 200};
    // if (this.extWarehouseId) {
    //   body.warehouseIdExt = this.extWarehouseId;
    // }

    this.appService.getAuth(`products/categories`, body).subscribe((res) => {
      res.data.forEach((el) => (el.parentId = null));
      this.categoriesList = res.data;
      this.translate
        .get('filterPanel.all')
        .subscribe((text) => (this.everything = text));
      this.categoriesList.unshift({
        CategoryId: -1,
        ProductCategoryName: this.everything,
      });

      this.saveLocalStorageData('articlesCategories', res.data);
      this.cd.detectChanges();
    });
  };

  getGroupList = () => {
    // const body = this.extWarehouseId
    //   ? { warehouseIdExt: this.extWarehouseId }
    //   : null;

    const body = null;

    this.appService.getAuth(`products/categoryTree`, body).subscribe((res) => {
      this.translate.get('filterPanel.all').subscribe((text) => {
        this.groupsList = [
          {
            expanded: true,
            Categories: [
              {
                expanded: true,
                Categories: res.Categories,
                CategoryName: res.CategoryName,
                ParentCategoryTreeId: -1,
                Guid: res.Guid,
                CategoryTreeId: res.CategoryTreeId,
              },
            ],
            CategoryName: text,
            ParentCategoryTreeId: null,
            Guid: -1,
            CategoryTreeId: -1,
          },
        ];

        this.saveLocalStorageData('articlesCategoriesTree', this.groupsList);
      });
    });
    this.cd.detectChanges();
  };

  onDragStart = (e) => {
    e.cancel = true;
  };

  changeCategoryName: string = '';
  changeCategoryTreeName: string = '';
  copyCategory = {
    items: [],
    toElement: null,
    toKey: null,
  };

  copyCategoryTree = {
    items: [],
    toElement: null,
    toKey: null,
  };

  isVisibleChangeCategorySingle: boolean = false;
  isVisibleChangeCategoryTreeMultiple: boolean = false;
  onAdd = (e) => {
    const toElement = this.categoriesList[e.toIndex];
    const fromElement = e.itemData;
    if (fromElement.CategoryId == -1) {
      return;
    }

    this.changeCategoryName = toElement.ProductCategoryName;
    this.copyCategory.toKey = toElement.CategoryId;
    this.copyCategory.toElement = toElement;

    this.copyCategory.items = [fromElement.ProductId];
    this.isVisibleChangeCategorySingle = true;
    this.cd.detectChanges();
  };

  onAddTree = (e) => {
    const fromElement = e.itemData;
    const toKey = e.toComponent.getKeyByRowIndex(e.toIndex);
    if (toKey == -1) {
      return;
    }

    const toElement = this.getCategoryTreeItemById(
      toKey,
      this.groupsList[0].Categories,
      null
    );
    this.copyCategoryTree.toElement = toElement;
    this.changeCategoryTreeName = toElement.CategoryName;
    const selectedItems = this.gridBank.instance
      .getSelectedRowsData()
      .map((el) => el.ProductId);
    if (selectedItems.findIndex((el) => el == fromElement.ProductId) > -1) {
      this.copyCategoryTree.items = selectedItems;
      this.copyCategoryTree.toKey = toKey;
      this.isVisibleChangeCategoryTreeMultiple = true;
      this.cd.detectChanges();
    } else {
      this.updateSingleAttributes(
        [fromElement.ProductId],
        'CategoryTreeId',
        toKey
      );
    }
    this.cd.detectChanges();
  };
  checkBoxList = [
    {text: '', modelName: 'newIndexes', value: false, readOnly: false},
  ];

  async updateSingleAttributes(
    ids: number[],
    property: string,
    newValue: number,
    withIndex: boolean = false
  ) {
    await this.event.selectRecords(`products/selection/product/select`, ids);
    const obj = {};
    obj[property] = newValue;
    if (withIndex) {
      obj['GenerateNewIndex'] = this.checkBoxList[0].value;
    }
    this.appService
      .putAuth('products/selection/modifyAttributes', obj)
      .subscribe(async () => {
        this.checkBoxList[0].value = false;
        // await this.event.selectRecords(
        //   `products/selection/product/unselect`,
        //   ids
        // );
        // this.getData();
      });
  }

  highlightDrop: boolean = false;
  onDragStartRow = () => {
    this.highlightDrop = true;
    this.cd.detectChanges();
  };

  onDragEndRow = () => {
    this.highlightDrop = false;
    this.cd.detectChanges();
  };

  getCategoryTreeItemById(id: number, items, item = null) {
    for (let i of items) {
      if (i.CategoryTreeId == id) {
        item = i;
      }
      if (i.Categories?.length) {
        item = this.getCategoryTreeItemById(id, i.Categories, item);
      }
    }

    return item;
  }

  categoriesSelectedRowKeys: any[] = [];
  categoriesTreeSelectedRowKeys: any[] = [];

  onCategorySelectionChanged(e) {
    this.localStorageData.tree[0].productCategoryId =
      e.component.getSelectedRowKeys()[0];
    this.localStorageData.tree[0].productCategoryName =
      e.component.getSelectedRowsData()[0].ProductCategoryName;

    this.saveLocalStorageData();
    if (!this.preventRefresh) {
      this.getData();
    }
  }

  onCategoryTreeSelectionChanged(e) {
    this.localStorageData.tree[1].productCategoryId =
      e?.selectedRowsData[0]?.CategoryTreeId;
    this.localStorageData.tree[1].productCategoryName =
      e?.selectedRowsData[0]?.CategoryName;
    if (!this.localStorageData.tree[1].productCategoryName) {
      this.localStorageData.tree[1].productCategoryName = this.everything;
      this.localStorageData.tree[1].productCategoryId = -1;
    }

    this.saveLocalStorageData();
    if (!this.preventRefresh) {
      this.getData();
    }
  }

  restoreTreeConfig = (filterType) => {
    let selectedRows = [];
    setTimeout(() => {
      switch (filterType) {
        case 'productCategoryName':
          selectedRows = this.categoriesTree.instance.getSelectedRowKeys();
          break;
      }
      if (selectedRows?.length == 0) {
        this.resetFiltr(filterType);
      }
    }, 0);
  };

  openRightPanel = (tabIndex?: number) => {
    if (!this.localStorageData?.sidePanel?.isVisible) {
      this.resizeWindowBtn();
    }
    if (tabIndex !== undefined) {
      this.localStorageData.sidePanel.selectedIndex = tabIndex;
    }
  };

  resetFiltr(filterType: string) {
    if (filterType === 'productCategoryName') {
      this.categoriesSelectedRowKeys = [-1];
      this.categoriesTree?.instance?.selectRows([-1], false);
    } else if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    } else if (filterType === 'onlySelected') {
      this.onlySelected = false;
    } else if (filterType === 'dateFilter') {
      this.dateFilter = {
        firstSaleDaysMax: 0,
        firstSaleDaysMin: 0,
        saleTimeMax: 0,
        saleTimeMin: 0,
      };
      this.isDateFilterActive = false;
    }
    if (!this.preventRefresh) {
      this.getData();
    }
  }

  deleteFilter = false;
  visibleAdvanceFilters = false;

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }

    this.saveLocalStorageData();

    this.getData();
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getData();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  onKeyDown(e: KeyDownEvent) {
    if (e.event.keyCode == 27) {
      e.event.preventDefault();
    }
    if (e.event.code == 'F11') {
      e.event.preventDefault();
      this.filterValue = '';
      this.localStorageData.filter.orderBy = 'EAN';
      this.customSearch?.focusInput();
    }
  }

  onContentReady(e: ContentReadyEvent) {
    if (this.event.deviceType == 'mobile' && !this.isPopupMode) {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onGridContentReady = (e) => {
    this.onContentReady(e);
    this.sortingIcons.asc = e.element.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = e.element.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  };

  onFocusedRowChanged = (e) => {
    this.focusedArticle = e.row.data.ProductId;
    this.focusedArticleData = e.row.data;
    this.getDetails();
  };

  onRowClick = (e) => {
    try {
      this.focusedArticle = e.data.ProductId;
    } catch {
    }
  };

  setSearchCriteria = (orderBy: string) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getData();
    this.cd.detectChanges();
  };

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }
    this.localStorageData.filter.order = 'ASC';
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.localStorageData.filter.order = 'ASC';
    }
    if (this.filterValue !== e.filterValue) {
      this.gridBank.instance.deselectAll();
    }
    this.filterValue = e.filterValue;

    this.getData(true);
  }

  onShouldReloadCategory() {
    this.getCategoriesList();
  }

  onShouldReloadCategoryTree() {
    this.getGroupList();
  }

  onDateRangeChoosed(date: GlobalDate) {
    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.dataSource = null;
      this.currentDate.dateFrom = date.dateFrom;
      this.currentDate.dateTo = date.dateTo;
      this.getData();
      // this.dataSource.reload();

      setTimeout(() => {
        this.cd.detectChanges();
      }, 1000);
    }
  }

  // heightBottomPanel: number = 280;
  heightBottomPanel
  showBottomPanel: boolean = true;
  // heightBottomGrid: number = 130;
  heightBottomGrid = 210;
  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizingBottom = (e) => {
    let height;
    this.localStorageData.bottomPanel.isVisible = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '235px';
    if (height < 235) {
      height = 235;
    }
    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 25;
    this.tabPanel.height = '100%';

    this.heightGrid = window.innerHeight - height - 200;
    this.localStorageData.bottomPanel.height = `${height}px`;
  };

  // resizingBottom = (e) => {
  //   let height;
  //   this.showBottomPanel = true;
  //   height = this.heightBottomPanel - e.edges.top;
  //   this.bottomSidePanel.nativeElement.style.minHeight = '230px';
  //   if (height < 235) {
  //     height = 235;
  //   }
  //   if (height > window.innerHeight - 250) {
  //     height = window.innerHeight - 250;
  //   }
  //   this.bottomSidePanel.nativeElement.style.height = `${height}px`;
  //   this.heightBottomGrid = height - 35;
  //
  //   this.heightGrid = window.innerHeight - height - 200;
  //   this.localStorageData.bottomPanel.height = height;
  //   this.gridBank?.instance?.updateDimensions();
  //
  //   this.saveLocalStorageData();
  // };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  timeoutHandler;

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange = (noChangeState?) => {
    if (noChangeState !== 'first') {
      this.localStorageData.bottomPanel.isVisible =
        !this.localStorageData.bottomPanel.isVisible;
    }
    if (!this.dropDownBoxMode) {
      if (
        this.localStorageData.bottomPanel.isVisible &&
        this.localStorageData.bottomPanel.height === 210
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
        this.heightGrid = window.innerHeight - 435;
        this.tabPanel.height = '100%';
        this.heightBottomGrid = 210;
      } else if (
        this.localStorageData.bottomPanel.isVisible &&
        this.localStorageData.bottomPanel.height !== 210
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanel.height;
        this.heightGrid =
          window.innerHeight -
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          200
        this.tabPanel.height = '100%';
        this.heightBottomGrid =
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          25;
      } else {
        this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
        this.tabPanel.height = '34px';
        this.bottomSidePanel.nativeElement.style.height = `34px`;
        // this.heightGrid += 256;
        this.heightGrid = window.innerHeight - 210;
      }
      if (!this.localStorageData.bottomPanel.isVisible) {
        this.isDetailOnFocus = false;
      }
    }
  };

  onItemClickTab = () => {
    try {
      this.localStorageData.bottomPanel.isVisible = true;
      if (this.localStorageData.bottomPanel.isVisible == null) {
        this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
        this.heightGrid = window.innerHeight - 435;
        this.tabPanel.height = '100%';
      } else if (this.localStorageData.bottomPanel.isVisible != null) {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanel.height;
        this.heightGrid = window.innerHeight - 435;
        this.tabPanel.height = '100%';
      }
    } catch {
    }
  };

  saveLocalStorageData(
    storageName: string = 'stockAvailabilityComponent',
    data: any = this.localStorageData
  ) {
    localStorage.setItem(storageName, this.event.encryptString(data));
  }

  onSelectionTabChanged = () => {
    this.getArticleDetails(this.focusedArticle);
    this.isDetailOnFocus = false;

    this.saveLocalStorageData();
  };

  getArticleDetails = (articleId: number) => {
    if (!this.localStorageData.bottomPanel.isVisible || !articleId) {
      return;
    }

    switch (this.localStorageData.bottomPanel.selectedIndex) {
      case 0:
        if (this.focusedArticleData) {
        }
        break;
    }
  };

  detailsSelectedRowKeys: number[] = [];
  focusedSupply: number = undefined;
  focusedSupplyData: any = undefined;
  onDetailsKeyDown = (_e) => {
  };

  onFocusedDetailsRowChanged = (e) => {
    this.focusedSupply = e.row.data.ProductId;
    this.focusedSupplyData = e.row.data;
  };

  onDetailsRowClick = (e) => {
    try {
      this.focusedSupply = e.data.ProductId;
    } catch {
    }
  };

  onValueChangedSubcategory(e: boolean) {
    this.localStorageData.tree[1].categoryTreeWithChilds = e ? true : false;
    this.getData();
  }

  isDateFiltersVisible: boolean = false;

  openDateFilters() {
    this.isDateFiltersVisible = true;
  }

  onDateFilterClosing() {
    this.isDateFiltersVisible = false;
    this.cd.detectChanges();
  }

  isDateFilterActive: boolean = false;
  dateFilter: DateFilter = {
    firstSaleDaysMax: 0,
    firstSaleDaysMin: 0,
    saleTimeMax: 0,
    saleTimeMin: 0,
  };

  onDateFilterSaving(e) {
    this.onDateFilterClosing();
    this.dateFilter = e;
    for (let key in this.dateFilter) {
      if (this.dateFilter[key] !== 0) {
        this.isDateFilterActive = true;
        this.getData();
        return;
      }
    }
    this.isDateFilterActive = false;
  }

  contextMenuMobile = [
    {name: 'Szczegóły', icon: 'icon absui-icon--file-edit', itemIndex: 1},
  ];
  isContextMenuMobile = false;
  isDetails: boolean = false;
  isShowMoreDetails: boolean = false;
  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 1:
        if (!this.readOnly) {
          this.isDetails = true;
        }
        break;
    }
  };


  report: ReportType = {
    DefaultVariantId: null,
    Description: null,
    FileName: null,
    IsCrystalReport: null,
    IsDefault: null,
    IsPersonalData: null,
    IsSavedInDatabase: null,
    Language: 'PL',
    MenuName: null,
    MultipleCurrency: null,
    Name: 'Analiza płynności',
    OrderIndex: null,
    ProgramCode: null,
    ReportId: 50,
    UserId: null,
    UserName: null,
  };

  printReport() {
    const date = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
    const docNumber = `AP${this.analizedReportId}-${date}`;
    const reportData: ReportData = {
      allowFiscalPrint: false,
      code: 'analiza_plynnosci',
      currencyCode: null,
      documentData: this.focusedArticleData,
      documentNumber: docNumber,
      menuName: null,
      objectId: this.analizedReportId,
      report: this.report,
      reportTypeId: this.report.ReportId,
      type: 'AP',
      actions: {
        downloadPdf: false,
        sendToEdocuments: false,
        email: false,
        print: true,
      },
      isBatch: false,
      lang: null,
    };
    this.print.getPdfApi(reportData);
  }
}

export interface DateFilter {
  firstSaleDaysMax: number;
  firstSaleDaysMin: number;
  saleTimeMax: number;
  saleTimeMin: number;
}
