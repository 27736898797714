import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { Observable, catchError, EMPTY } from 'rxjs';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { ContractorLimit, Customer } from './contractor-limit.model';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { WaproDateBoxComponent } from '../../../core/wapro-date-box/wapro-date-box.component';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-contractor-limit',
    templateUrl: './contractor-limit.component.html',
    styleUrls: ['./contractor-limit.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxScrollViewModule,
        DxNumberBoxModule,
        WaproDateBoxComponent,
        NgShortcutsComponent,
        FormsModule,
    ]
})
export class ContractorLimitComponent implements AfterViewInit, OnChanges {
  @Input() customer: Customer = null;
  @Output() onClosing: EventEmitter<void> = new EventEmitter();

  data$: Observable<ContractorLimit>;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService
  ) {}

  errorHandler = (error: any) => {
    this.event.httpErrorNotification(error);

    return EMPTY;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.customer?.currentValue) {
      this.data$ = this.getData();
    }
  }

  getData() {
    return this.appService
      .getAuth(
        `customers/additionalOperations/limit/${this.customer.CustomerId}`
      )
      .pipe(catchError(this.errorHandler));
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit();
      },
      preventDefault: true,
    });
  }
}
