import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

import { DxDataGridComponent } from 'devextreme-angular';
import { DeliveryAddress, DeliveryAddressType } from './delivery-address.model';

@Component({
    selector: 'app-delivery-address',
    templateUrl: './delivery-address.component.html',
    styleUrls: ['./delivery-address.component.scss'],
    inputs: ['readOnly', 'width', 'isVisible', 'type', 'title'],
    standalone: false
})
export class DeliveryAddressComponent implements OnInit {
  title: string = '';
  type: DeliveryAddressType = 'nadanie';
  operatorName: string = '';
  @Input() isPopupMode: boolean = false;
  @Input() operatorCode: string;
  @Input() selectedId: number = null;
  @Output() onClosed = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass: DxDataGridComponent;
  checkAll: boolean = false;
  isVisible = false;
  DeliveryAddressId = '';
  readOnly = false;
  width;

  isGridBoxOpened: boolean = false;
  selectedRows = [];
  heightGrid: number = 300;
  listPom = [];
  dataSource: DeliveryAddress[];
  addRow: boolean = false;
  editRow: boolean = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    {
      text: 'Wybierz',
      icon: 'absui-icon--tool-select-rectangle',
      itemIndex: 3,
    },
  ];
  myEventEsc;
  myEventEnter;
  textAreaAddress: string = '';
  selectedAddress: DeliveryAddress;
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.myEventEsc = this.event.esc.subscribe(() => {
      this.isVisible = false;
    });

    this.myEventEnter = this.event.choose.subscribe(() => {
      if (this.isVisible && this.selectedRows.length) {
        this.onChoosingFormPayment();
      }
    });

    this.getTranslations();
  }

  getData<DeliveryAddress>() {
    return new Promise<DeliveryAddress[]>((resolve) => {
      this.appService
        .getAuth(`deliveries/operators/${this.operatorCode}/addresses`)
        .subscribe({
          next: (res) => {
            const data = res.data;
            const defaultForCurrentWarehouse = data.filter(
              (el) => el.WarehouseId == this.event.footerInfo.AppWarehouseId
            );
            const defaultAddress = data.filter(
              (el) => el.IsDefault && el.WarehouseId === 0
            );
            if (data.length > 0) {
              if (this.selectedId) {
                this.focusedData = data.find(
                  (el) => el.DeliveryAddressId === this.selectedId
                );
                this.selectAddress(this.focusedData);
                // this.selectedAddress = this.focusedData;
                // this.selectedRows = [this.selectedAddress];
                // this.textAreaAddress = this.formatAddress(this.selectedAddress);
                if (this.gridClass?.instance) {
                  this.gridClass.instance.selectRows([this.selectedId], false);
                  setTimeout(() => {
                    let selectedAddress =
                      this.gridClass.instance.getSelectedRowsData()[0];
                    this.gridClass.instance.focus(selectedAddress);
                  }, 300);
                }
              } else if (defaultForCurrentWarehouse?.length) {
                this.focusedData = defaultForCurrentWarehouse[0];
                this.selectAddress(this.focusedData);
                if (this.gridClass?.instance) {
                  setTimeout(() => {
                    this.gridClass.instance.selectRows(
                      [this.selectedAddress.DeliveryAddressId],
                      false
                    );
                    let selectedAddress =
                      this.gridClass.instance.getSelectedRowsData()[0];
                    this.gridClass.instance.focus(selectedAddress);
                  }, 300);
                }
              } else if (defaultAddress?.length) {
                this.focusedData = defaultAddress[0];
                this.selectAddress(this.focusedData);
                if (this.gridClass?.instance) {
                  setTimeout(() => {
                    this.gridClass.instance.selectRows(
                      [this.selectedAddress.DeliveryAddressId],
                      false
                    );
                    let selectedAddress =
                      this.gridClass.instance.getSelectedRowsData()[0];
                    this.gridClass.instance.focus(selectedAddress);
                  }, 300);
                }
              } else {
                this.focusedData = data[0];
                this.selectAddress(this.focusedData);
                if (this.gridClass?.instance) {
                  setTimeout(() => {
                    this.gridClass.instance.selectRows(
                      [this.selectedAddress.DeliveryAddressId],
                      false
                    );
                    let selectedAddress =
                      this.gridClass.instance.getSelectedRowsData()[0];
                    this.gridClass.instance.focus(selectedAddress);
                  }, 300);
                }
              }
              this.empty = false;
            } else {
              this.selectAddress(null);
              this.empty = true;
            }
            resolve(res.data);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
            resolve([]);
          },
        });
    });
  }

  selectAddress(e) {
    if (e) {
      this.selectedAddress = e;
      this.selectedId = e.DeliveryAddressId;
      this.selectedRows = [e];
      this.textAreaAddress = this.formatAddress(e);
      this.onChoosed.emit(e);
    } else {
      this.selectedAddress = null;
      this.selectedId = null;
      this.selectedRows = [];
      this.textAreaAddress = '';
      this.onChoosed.emit(null);
    }
  }

  clearReceiveAddress() {
    this.textAreaAddress = '';
    this.selectedId = null;
  }

  getOperatorName() {
    this.appService
      .getAuth(`deliveries/operators/`, { operatorCode: this.operatorCode })
      .subscribe((res) => {
        this.operatorName = res.data[0].Name;
      });
  }

  contextMenuClick = (e) => {
    switch (e.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.showAdd();
        }
        break;
      case 1:
        if (!this.readOnly) {
          this.showEdit();
        }
        break;
      case 2:
        if (!this.readOnly) {
          this.isDelete = true;
        }
        break;
      case 3:
        if (!this.readOnly) {
          this.onChoosingFormPayment();
        }
        break;
    }
  };

  ngOnDestroy() {
    this.myEventEsc.unsubscribe();
    this.myEventEnter.unsubscribe();
  }

  focusedRowKey;

  empty: boolean = true;
  focusSelected = (DeliveryAddressId?) => {
    let addresses = this.gridClass.instance;
    let key;
    if (DeliveryAddressId) {
      key = DeliveryAddressId;
    } else {
      key = addresses.getSelectedRowKeys()[0];
    }
    this.focusedRowIndex = addresses.getRowIndexByKey(key);
  };

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.operatorCode?.currentValue) {
      this.dataSource = await this.getData();
      this.getOperatorName();
    }

    if (changes?.selectedId?.currentValue) {
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.showAdd();
        },
        preventDefault: true,
      },
      {
        key: 'F2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.showEdit();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedRowIndex > -1) this.isDelete = true;
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onChoosingFormPayment();
        },
        preventDefault: true,
      }
    );
  }

  onClosing = () => {
    this.addRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
    this.cd.detectChanges();
  };

  focusedData;
  onSelectionChanged = (e) => {
    // this.selectedId = e.selectedRowsData[0];
    this.listPom = [];
    this.listPom.push(e.selectedRowsData[0]);
    this.focusedRowKey = e?.selectedRowsData[0]?.DeliveryAddressId;
    this.focusedData = e?.selectedRowsData[0];
  };

  onChoosingFormPayment = () => {
    if (!this.readOnly) {
      // this.selectedId = this.focusedRowKey;
      this.onChoosed.emit(this.focusedData);
      this.selectedRows = [this.focusedData];
      this.selectedAddress = this.focusedData;
      this.textAreaAddress = this.formatAddress(this.focusedData);
      this.isPopupMode = false;
    }
  };

  onRowDblClick = () => {
    if (!this.readOnly && this.isVisible) {
      this.onChoosingFormPayment();
    }
  };

  onInserted = async (e) => {
    this.dataSource = await this.getData();
    this.gridClass.instance.selectRows(e.DeliveryAddressId, false);
    this.focusSelected(e.DeliveryAddressId);
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 10);
  };

  formatAddress(e) {
    let address = '';
    if (e) {
      if (e.CompanyName) {
        address += `Firma: ${e.CompanyName}\n`;
      }
      if (e.PostalCode) {
        address += `${e.PostalCode}`;
        if (e.City) {
          address += ` ${e.City}\n`;
        } else {
          address += '\n';
        }
      }
      if (e.Street) {
        address += `${e.Street}`;
        if (e.BuildingNumer) {
          address += ` ${e.BuildingNumer}${
            e.LocalNumber ? '/' + e.LocalNumber : ''
          }\n`;
        } else {
          address += '\n';
        }
      }
      if (e.CountryCode) {
        address += `Kraj: ${e.CountryCode}\n`;
      }
      if (e.Name) {
        address += `Osoba: ${e.Name}\n`;
      }
      if (e.Phone) {
        address += `Tel. ${e.Phone}`;
      }
    }
    return address;
  }

  visibleChange() {
    setTimeout(() => {
      if (!this.isVisible) {
        this.onClosed.emit(true);
      } else {
        this.gridClass.instance.focus();
      }
    });
  }

  getTranslations() {
    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
    this.translate
      .get('buttons.choose')
      .subscribe((text) => (this.contextMenu[3].text = text));
  }

  showAdd() {
    this.addRow = true;
  }

  showEdit() {
    if (this.focusedRowIndex < 0) return;

    this.editRow = true;
  }

  isDelete: boolean = false;

  closeConfirm() {
    this.isDelete = false;
    setTimeout(() => {
      if (this.gridClass) this.gridClass.instance.focus();
    }, 300);
  }

  isEmpty = () => {
    let addresses = this.gridClass.instance;
    if (addresses.totalCount() > 0) {
      if (this.selectedId) {
        addresses.selectRows([this.selectedId], false);
      } else {
        addresses.selectRowsByIndexes([0]);
      }
      return false;
    } else {
      return true;
    }
  };

  focusedRowIndex = null;
  delete = () => {
    const el = this.gridClass.instance
      .getDataSource()
      .items()
      .find((_el, i) => this.focusedRowIndex === i);
    this.appService
      .deleteAuth(
        `deliveries/operators/${this.operatorCode}/addresses/${el.DeliveryAddressId}`
      )
      .subscribe({
        next: async () => {
          this.isDelete = false;
          this.dataSource = await this.getData();
          if (this.selectedAddress == undefined) {
            this.selectedRows = null;
            this.selectedAddress = null;
            this.onChoosed.emit(null);
            this.textAreaAddress = this.formatAddress(null);
          }
          this.empty = this.isEmpty();
          if (!this.empty) {
            this.focusedRowIndex = 0;
          }
        },
        error: (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        },
      });
  };

  onInitialized(e) {
    if (this.selectedId) {
      let index = this.dataSource.findIndex(
        (el) => el.DeliveryAddressId === this.selectedId
      );
      setTimeout(() => {
        e.component.focus(e.component.getRowElement(index));
      }, 300);
    }
  }

  onClose() {
    this.isPopupMode = false;
    this.cd.detectChanges();
  }
}
