import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { HelpService } from 'src/app/help-service.service';
import { environment } from '../../../environments/environment';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { SmsType } from './sms-offers.model';

@Component({
    selector: 'app-sms-offers',
    templateUrl: './sms-offers.component.html',
    styleUrls: ['./sms-offers.component.scss'],
    inputs: ['isVisible', 'title', 'readOnly', 'CustomerId', 'type'],
    standalone: false
})
export class SmsOffersComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;
  @ViewChild('gridBank') gridBank;
  @ViewChild('bottomSidePanel') bottomSidePanel;

  helpService = inject(HelpService);
  type: SmsType = 'offers';
  isVisible;
  title;
  readOnly;
  unicalGuid;
  CustomerId;

  pageSize = 100;
  widthWindow = '90%';
  heightWindow = '90%';

  filterCriteria = [
    { value: 'Name', label: 'Nazwa' },
    { value: 'DateTime', label: 'Data i czas' },
  ];

  valueCriteria = 'Name';
  filterValue = '';

  focusedSelected = [];
  dataSource;
  selectedRows = [];
  heightGrid;

  bottomPanelTabs = ['Odbiorcy SMS-ów', 'Dziennik wykonania wysyłki'];

  dataSourceRecipient;
  selectedRowsRecipient = [];
  selectedIndex = 0;
  dataSourceDiary;
  selectedRowsDiary = [];
  loadChildComponent: boolean = false;
  shortcuts: ShortcutInput[] = [];

  addRow: boolean = false;
  isDeleteRow: boolean = false;
  mode: string = 'add';
  focusedRowIndex;
  isDeleteContact: boolean = false;
  focusedRecipientSelected = [];
  iSCustomerOpen: boolean = false;
  showBottomPanel: boolean = true;

  filterCriteriaStatus = [
    { value: true, label: 'Wysłany' },
    { value: false, label: 'Do wysyłki' },
  ];

  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    { text: 'Popraw', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    { text: 'Wyślij', icon: 'icon absui-icon--send', itemIndex: 3 },
    // { text: 'Sprawdź', icon: 'icon absui-icon--messge-ok', itemIndex: 4 },
    // { text: 'Płatności', icon: 'icon absui-icon--payment', itemIndex: 5 },
  ];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('companies.grid.columns.name')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('buffor.dateAndTime')
      .subscribe((text) => (this.filterCriteria[1].label = text));

    this.translate
      .get('offers.SMSrecipients')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));
    this.translate
      .get('offers.shipmentExecutionLog')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));

    this.translate.get('buttons.add').subscribe((text) => {
      this.contextMenu[0].text = text;
    });

    this.translate.get('buttons.edit').subscribe((text) => {
      this.contextMenu[1].text = text;
    });

    this.translate.get('buttons.delete').subscribe((text) => {
      this.contextMenu[2].text = text;
    });

    this.translate.get('buffor.send').subscribe((text) => {
      this.contextMenu[3].text = text;
    });

    // this.translate.get('offers.checkTheDelivery').subscribe((text) => {
    //   this.contextMenu[4].text = text;
    // });
    //
    // this.translate.get('form-financial-document.paymentM').subscribe((text) => {
    //   this.contextMenu[5].text = text;
    // });

    this.heightGrid = 0.9 * window.innerHeight - 400;
  }

  ngOnInit(): void {
    this.getSmsSenderMessage();
    this.event.onHiddenPopUp();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.addSmsSenderMessage();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/modul-SMS/wysylanie-sms-spersonalizowanych'
          );
        },
        preventDefault: true,
      },
      {
        key: 'F2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.editSmsSenderMessage();
        },
        preventDefault: true,
      },
      {
        key: 'Delete',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0) {
            this.isDeleteRow = true;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );

    setTimeout(() => {
      this.loadChildComponent = true;
    }, 500);
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;
    //this.getDocuments();
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.selectedIndex = 0;
    }
  };

  onRowDblClick = () => {};

  message = '';
  myTimer;

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
    this.message = e.row.data.Message;

    setTimeout(() => {
      this.getContact(this.focusedSelected[0].SmsSenderMessageId);
    });
  };

  onInsertedSms = () => {
    this.addRow = false;
    // this.focusedSelected = [e];

    // if (this.mode === 'edit') {
    //   this.getPositions(e.IntrastateId);
    // }
    // this.getSmsSenderMessage();
  };

  focusedRowIndexDataSource = 0;

  async onSetFocus(e) {
    await this.dataSource.reload();
    this.focusedRowIndexDataSource = this.event.returnFocusIndex(
      this.dataSource._items,
      'SmsSenderMessageId',
      e
    );

    this.focusedSelected = [
      this.dataSource._items[this.focusedRowIndexDataSource],
    ];

    this.event.setFocus(this.gridBank);

    setTimeout(() => {
      this.getContact(this.focusedSelected[0].SmsSenderMessageId);
    }, 100);
  }

  closeConfirm = () => {
    this.isDeleteRow = false;
    this.isDeleteContact = false;
  };

  onFocusedRowChangedRecipient = (e) => {
    this.focusedRecipientSelected = [e.row.data];
  };

  deleteContact = () => {
    this.isDeleteContact = false;
    this.appService
      .postAuth(
        `${this.type}/additionalOperations/smsSenderMessage/${this.focusedRecipientSelected[0].SmsSenderMessageId}/contacts/delete`,
        { ObjectIds: [this.focusedRecipientSelected[0].ContactId] }
      )
      .subscribe(
        () => {
          this.getContact(this.focusedSelected[0].SmsSenderMessageId);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  delete = () => {
    this.isDeleteRow = false;
    this.gridBank.instance.deleteRow(
      this.gridBank.instance.getRowIndexByKey(
        this.focusedSelected[0].SmsSenderMessageId
      )
    );
    setTimeout(() => {
      if (this.gridBank) {
        this.gridBank.focusedRowIndex = 0;
        this.gridBank.instance.focus();
        this.focusedSelected = [];
      }
    }, 500);
  };

  getContact = (id) => {
    this.appService
      .getAuth(
        `${this.type}/additionalOperations/smsSenderMessage/${id}/contacts?SmsSenderMessageId=${id}`
      )
      .subscribe(
        (res) => {
          this.dataSourceRecipient = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  onChoosedContact = (e) => {
    if (e) {
      this.appService
        .postAuth(
          `${this.type}/additionalOperations/smsSenderMessage/${this.focusedSelected[0].SmsSenderMessageId}/contacts`,
          { ObjectIds: [e.ContactId] }
        )
        .subscribe(
          () => {
            this.getContact(this.focusedSelected[0].SmsSenderMessageId);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
    // this.appService
    // .postAuth(
    //   `${this.type}/additionalOperations/smsSenderMessage/${this.focusedSelected[0].SmsSenderMessageId}/contacts`,
    //   e.ContactId
    // )
    // .subscribe(
    //   (res) => {
    //     this.getContact(this.focusedSelected[0].SmsSenderMessageId);
    //   },
    //   (error) => {
    //     this.event.httpErrorNotification(error);
    //   }
    // );
  };

  getSmsSenderMessage = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'SmsSenderMessageId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.type}/additionalOperations/smsSenderMessage`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions) {
          loadOptions.requireTotalCount = true;
        },
        deleteUrl: `${environment.domain}${this.type}/additionalOperations/smsSenderMessage`,
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  };

  addSmsSenderMessage = () => {
    if (!this.readOnly) {
      this.mode = 'add';
      this.addRow = true;
    }
  };

  editSmsSenderMessage = () => {
    if (!this.readOnly && this.focusedSelected.length > 0) {
      this.mode = 'edit';
      this.addRow = true;
    }
  };

  sendSms = () => {
    this.appService
      .postAuth(
        `${this.type}/additionalOperations/smsSenderMessage/${this.focusedSelected[0].SmsSenderMessageId}/send`,
        null
      )
      .subscribe(
        () => {},
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  timeoutHandler;

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange = () => {
    this.showBottomPanel = !this.showBottomPanel;

    if (this.showBottomPanel) {
      this.bottomSidePanel.nativeElement.style.minHeight = `80px`;
      this.heightGrid = 0.9 * window.innerHeight - 400;
    } else {
      this.bottomSidePanel.nativeElement.style.minHeight = `34px`;

      this.bottomSidePanel.nativeElement.style.height = `34px`;
      this.heightGrid = 0.9 * window.innerHeight - 250;
    }
  };

  heightBottomPanel;
  heightBottomGrid = 140;
  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  resizingBottom = (e) => {
    let height;
    this.showBottomPanel = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '185px';
    if (height < 185) {
      height = 185;
    }

    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 25;

    this.heightGrid = window.innerHeight - height - 300;
  };

  onSelectionTabChangedLeft = () => {
    if (this.selectedIndex == 0 && this.focusedSelected.length > 0) {
      this.message = this.focusedSelected[0].Message;
    }
    if (this.selectedIndex == 1 && this.focusedSelected.length > 0) {
      this.getContact(this.focusedSelected[0].SmsSenderMessageId);
    }
  };

  contextMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        this.addSmsSenderMessage();
        break;
      case 1:
        this.editSmsSenderMessage();
        break;
      case 2:
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.isDeleteRow = true;
        }
        this.cd.detectChanges();
        break;
      case 3:
        this.sendSms();
        break;
      case 4:
        break;
      case 5:
        break;
    }
  }

  getLoadParams() {
    let obj: any = {};

    switch (
      this.valueCriteria
      // case 'Number':
      //   obj.Number = this.filterValue;
      //   break;
      // default:
      //   break;
    ) {
    }

    return obj;
  }
}
