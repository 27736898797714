<div *ngIf="!dropDownBoxMode && !popUpMode">
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</div>

<div *ngIf="popUpMode">
  <dx-popup
    width="1000"
    [height]="popupHeight"
    position="center"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [resizeEnabled]="true"
    [title]="title"
    [(visible)]="isVisible"
    (visibleChange)="visibleChange($event)"
    (onShown)="this.onPopupShown()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
    (onResizeEnd)="onResizeEnd($event)"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          (onClick)="onChoosingRow()"
          [id]="'btn-choose' + unicalGuid"
          *ngIf="!readOnly"
          [disabled]="focusedRow.length == 0 || readOnly"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.close" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      <br />
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </dx-popup>
</div>

<div
  style="
    display: inline-block;
    position: relative;
    max-width: 100%;
    width: 100%;
  "
  [ngStyle]="{ width: widthArea ? widthArea : '100%' }"
  *ngIf="dropDownBoxMode"
  class="box-dictionary"
>
  <dx-text-area
    class="dictionary"
    [minHeight]="heightArea ? heightArea : 58"
    [width]="widthArea ? widthArea : '100%'"
    [autoResizeEnabled]="true"
    style="max-width: 100%"
    [(value)]="myValue"
    (click)="onClickArea()"
    [readOnly]="readOnly"
    #descriptionTextBox
    (onInput)="onInput()"
    valueChangeEvent="input"
    [maxLength]="maxLength"
    [maxHeight]="heightArea ? heightArea : 58"
    [ngClass]="{
      'input-required': requiredWarning
    }"
    [placeholder]="placeholder"
  >
  </dx-text-area>
  <div class="button-content" [style.right.px]="2">
    <!-- [style.left.px]="widthArea ? widthArea - 25 : 251" -->
    <i
      class="icon"
      [ngClass]="{
        'dx-icon-spinup': isGridBoxOpened,
        'dx-icon-spindown': !isGridBoxOpened
      }"
      style="margin-top: 4.5px; margin-left: 2.5px"
      (click)="openedBoxDictionary()"
    ></i>
  </div>

  <dx-drop-down-box
    [deferRendering]="false"
    [openOnFieldClick]="false"
    [opened]="isGridBoxOpened"
    [dataSource]="[]"
    [showClearButton]="true"
    style="
      max-width: 100%;
      z-index: -1;
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 6px;
      width: calc(100% - 2px);
      height: calc(100% - calc(var(--text-box-margin-bottom, 6px) + 2px));
    "
    [readOnly]="readOnly"
    (onOpened)="onDropdownOpened()"
    [dropDownOptions]=" {
      width: 460,
      maxWidth: '100%',
    }"
  >
    <!-- [width]="widthArea ? widthArea : 'calc(100% - 2px)'" -->
    <!-- [ngStyle]="{ 'left.px': widthArea <= 450 ? 1 : widthArea - 460 }" -->
    <div
      *dxTemplate="let data of 'content'"
      style="height: 280px; min-height: 280px !important"
    >
      <div class="row">
        <div class="col-md-12" class="no-padding">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="dropDownHeight"
            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            (onSelectionChanged)="onSelectionChanged($event)"
            [(selectedRowKeys)]="selectedRow"
            [focusedRowEnabled]="true"
            [focusedRowIndex]="focusedRowIndex"
            #gridClass
            [hoverStateEnabled]="true"
            [showColumnHeaders]="true"
            style="max-width: 450px"
            [columnAutoWidth]="true"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              [mode]="dropDownBoxMode ? 'single' : 'multiple'"
              showCheckBoxesMode="always"
            >
            </dxo-selection>
            <dxi-column
              [width]="event.deviceType != 'mobile' ? 45 : 10"
              cellTemplate="editTemplate"
              [allowSorting]="false"
              alignment="center"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
              <dx-button
                class="btn-dx-top-menu-folder-grid"
                icon="icon absui-icon--mode-edit hover-edit-icon"
                [disabled]="readOnly"
                (onClick)="onDropdownEditClick()"
              >
              </dx-button>
            </div>

            <dxi-column
              caption="{{ 'companies.grid.columns.name' | translate }}"
              cellTemplate="companyCell"
              dataField="Name"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              cellTemplate="companyCell"
              dataField="Description"
              [allowSorting]="false"
            ></dxi-column>

            <dxi-column
              caption="D"
              width="40"
              dataField="IsDefault"
              [allowSorting]="false"
              cellTemplate="isDefaultTemplate"
              headerCellTemplate="isDefaultHeaderTemplate"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'companyCell'">
              {{
                data?.value?.length > 50
                  ? (data.value | slice : 0 : 50) + "..."
                  : data?.value
              }}
            </div>

            <div *dxTemplate="let cell of 'isDefaultHeaderTemplate'">
              <div class="text-center" id="isDefaultHeader">D</div>
              <dx-tooltip
                [target]="cell.cellElement"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "default" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <div *dxTemplate="let cell of 'isDefaultTemplate'">
              <div
                class="d-flex align-items-center justify-content-center text-center"
              >
                <i
                  class="icon absui-icon--status-ok-green"
                  *ngIf="cell.data.IsDefault"
                ></i>
                <i
                  class="icon icon-circle-blank"
                  *ngIf="!cell.data.IsDefault"
                  (click)="setDefault(cell, $event)"
                ></i>
              </div>

              <dx-tooltip
                [target]="cell.cellElement"
                [hideOnOutsideClick]="true"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "buttons.setDefault" | translate }}
                </div>
              </dx-tooltip>
            </div>

            <!-- <div *dxTemplate="let data of 'IsDefault'">
              <i
                class="icon absui-icon--status-ok-green ok-grid"
                *ngIf="data.data.IsDefault"
              ></i>
              <dx-button
                class="btn-dx-top-menu-folder-grid icon-set-default"
                icon="icon absui-icon--check-circle hover-edit-icon"
                [disabled]="readOnly"
                (onClick)="setDefault(data, $event)"
                *ngIf="!data.data.IsDefault"
                [id]="'default' + data.data.DescriptionId"
              >
              </dx-button>

              <dx-tooltip
                [target]="'#default' + data.data.DescriptionId"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "buttons.setDefault" | translate }}
                </div>
              </dx-tooltip>
            </div> -->
          </dx-data-grid>

          <dx-context-menu
            [dataSource]="contextMenu"
            [width]="200"
            [target]="'#grid' + unicalGuid"
            (onItemClick)="contextMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          >
          </dx-context-menu>
        </div>
      </div>

      <div class="bottom-drop-box pb-0 d-flex align-items-center">
        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--add-circle"
          [disabled]="readOnly"
          [id]="'addDropDown' + unicalGuid"
          (onClick)="addRow()"
        >
        </dx-button>

        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--import-contacts"
          [disabled]="readOnly"
          (onClick)="changeModeWork()"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder ml-auto"
          icon="icon absui-icon--clear-filter"
          (onClick)="clearValue()"
        >
        </dx-button>

        <dx-tooltip
          [target]="'#addDropDown' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }} (Insert)
          </div>
        </dx-tooltip>
      </div>
    </div>
  </dx-drop-down-box>
</div>

<ng-template #topButtons>
  <div class="top-menu-popup">
    <dx-scroll-view
      width="95%"
      height="100%"
      direction="horizontal"
      showScrollbar="never"
    >
      <dx-button
        [ngClass]="{
          'btn-dx-top-menu': !dropDownBoxMode,
          'btn-dx-top-menu-folder': dropDownBoxMode
        }"
        icon="icon absui-icon--add-circle"
        [id]="'btn-add' + unicalGuid"
        [text]="'buttons.add' | translate"
        [disabled]="readOnly"
        (onClick)="addRow()"
      >
      </dx-button>
      <dx-button
        [ngClass]="{
          'btn-dx-top-menu': !dropDownBoxMode,
          'btn-dx-top-menu-folder': dropDownBoxMode
        }"
        [id]="'btn-edit' + unicalGuid"
        icon="icon absui-icon--mode-edit"
        [text]="'buttons.edit' | translate"
        [disabled]="pomSelected.length == 0 || readOnly"
        (onClick)="editRow = true"
      >
      </dx-button>
      <dx-button
        [ngClass]="{
          'btn-dx-top-menu': !dropDownBoxMode,
          'btn-dx-top-menu-folder': dropDownBoxMode
        }"
        icon="icon absui-icon--highlight-off"
        [text]="'buttons.delete' | translate"
        [id]="'btn-delete' + unicalGuid"
        [disabled]="pomSelected.length == 0 || readOnly"
        (onClick)="isDelete()"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#btn-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-edit' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.edit" | translate }} (F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Del)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-choose' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (Enter)
        </div>
      </dx-tooltip>
    </dx-scroll-view>
  </div>
</ng-template>

<ng-template #grid>
  <dx-data-grid
    id="gridContainer"
    #gridClass
    [dataSource]="dataSource"
    [remoteOperations]="true"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="popupGridHeight"
    [(selectedRowKeys)]="selectedRow"
    [focusedRowIndex]="focusedRowIndex"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    (onRowDblClick)="onRowDblClick()"
    [id]="'grid' + unicalGuid"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [hoverStateEnabled]="true"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="200"></dxo-paging>
    <dxo-selection mode="none" showCheckBoxesMode="always"></dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxi-column
      caption="D"
      width="40"
      dataField="IsDefault"
      [allowSorting]="false"
      cellTemplate="isDefaultTemplate"
      headerCellTemplate="isDefaultHeaderTemplate"
    >
    </dxi-column>
    <dxi-column
      caption="{{ 'companies.grid.columns.name' | translate }}"
      dataField="Name"
      [allowSorting]="false"
      [width]="200"
    ></dxi-column>
    <dxi-column
      caption="{{ 'form-financial-document.description' | translate }}"
      cellTemplate="companyCell"
      [width]="isChangedMode ? null : widthCityColumn"
      dataField="Description"
      [allowSorting]="false"
    ></dxi-column>

    <div *dxTemplate="let data of 'companyCell'">
      {{ data.data.Description | slice : 0 : 50 }}..
    </div>

    <!-- <div *dxTemplate="let data of 'IsDefault'">
      <i
        class="icon absui-icon--check check-grid"
        *ngIf="data.data.IsDefault; else falseCheck"
      ></i>
    </div> -->
    <div *dxTemplate="let cell of 'isDefaultHeaderTemplate'">
      <div class="text-center" id="isDefaultHeader">D</div>
      <dx-tooltip
        [target]="'#isDefaultHeader'"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "default" | translate }}
        </div>
      </dx-tooltip>
    </div>
    <div *dxTemplate="let cell of 'isDefaultTemplate'">
      <div class="d-flex align-items-center justify-content-center text-center">
        <i
          class="icon absui-icon--status-ok-green"
          [id]="'setDefault' + cell.rowIndex"
          *ngIf="cell.data.IsDefault"
        ></i>
        <i
          class="icon icon-circle-blank"
          [id]="'setDefault' + cell.rowIndex"
          *ngIf="!cell.data.IsDefault"
          (click)="setDefault(cell, $event)"
        ></i>
      </div>

      <dx-tooltip
        [target]="'#setDefault' + cell.rowIndex"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="true"
        [showEvent]="event.tooltipShowEvent"
        [hideEvent]="{ name: 'dxhoverend' }"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.setDefault" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-data-grid>
  <dx-context-menu
    [dataSource]="contextMenu"
    [width]="200"
    [target]="'#grid' + unicalGuid"
    (onItemClick)="contextMenuClick($event)"
    (onItemRendered)="event.onItemRendered($event)"
  >
  </dx-context-menu>

  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [unicalGuid]="unicalGuid"
    [disabled]="
      (isGridBoxOpened && !dropDownBoxMode) ||
      isAddRowVisible ||
      editRow ||
      isDeleteRow ||
      !isVisible
    "
  >
  </wapro-keyboard-shortcuts>
</ng-template>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<app-add-description-dictionary
  [isVisible]="isAddRowVisible"
  [controllerType]="controllerType"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted($event)"
  title="{{ 'servicesDevices.addingDescription' | translate }}"
  [type]="type"
  [descriptionRequired]="descriptionRequired"
>
</app-add-description-dictionary>

<app-add-description-dictionary
  [isVisible]="editRow"
  [controllerType]="controllerType"
  [selectedId]="focusedRow"
  (onClosing)="onClosingForm()"
  (onInserted)="onInserted($event)"
  title="{{ 'servicesDevices.edditingDescription' | translate }}"
  [type]="type"
  [descriptionRequired]="descriptionRequired"
>
</app-add-description-dictionary>

<ng-template #falseCheck>
  <i class="icon absui-icon--close close-grid"></i>
</ng-template>
