import { EventService } from 'src/app/event.service';
import { AppServices } from 'src/app/app-services.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  Input,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { ProductScanerService } from './product-scaner.service';

@Component({
    selector: 'app-product-scaner',
    templateUrl: './product-scaner.component.html',
    styleUrls: ['./product-scaner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductScanerComponent implements OnInit, AfterViewInit {
  @Input() readOnly: boolean = false;
  @Input() inventory: boolean = false;
  @Input() keyboardShortcutsDisabled: boolean = false;
  @Input() showScanerContainer: boolean = true;
  @Input() parentId: string | number = null;
  @Output() onInputChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScanerChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCollectorOpened: EventEmitter<any> = new EventEmitter<any>();

  activeMode: number = 0;
  selectedItem: string = 'IndexCatalogue';

  modeSource: any[] = [
    { label: 'Dodaj', value: 0, icon: 'absui-icon--add-circle' },
    { label: 'Odejmij', value: 1, icon: 'absui-icon--remove-circle-outline' },
    { label: 'Szukaj', value: 2, icon: 'icon-search' },
    { label: 'Nadpisz', value: 3, icon: 'absui-icon--files' },
    { label: 'Nowy', value: 4, icon: 'absui-icon--file-add' },
  ];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  filterSource: any[] = [
    { label: 'Indeks katalogowy', value: 'IndexCatalogue' },
    { label: 'Indeks handlowy', value: 'IndexTrading' },
    { label: 'Kod egzemplarzowy', value: 'CodePLU' },
    { label: 'Nr serii', value: 'serialNumber' }, // chyba nie ma pola
  ];
  selectBoxOpened: boolean = false;
  searchIn: string = 'Szukaj w';
  filterValue: string = '';
  shortcuts: ShortcutInput[] = [];

  deviceType: string = 'desktop';

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.selectBoxOpened = false;
    }
  }

  @HostListener('window:keyup.escape', ['$event'])
  handleKeyDown() {
    this.isBarcodePopupVisible = false;
    this.cd.detectChanges();
  }

  constructor(
    private translate: TranslateService,
    private eRef: ElementRef,
    private appServices: AppServices,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private renderer2: Renderer2,
    public scanerService: ProductScanerService
  ) {
    this.extraButton = {
      icon: 'no-stroke-width icon absui-icon--add-circle',
      type: 'default',
      elementAttr: {
        id: 'extra' + this.unicalGuid,
      },
      onClick: () => {
        if (!this.filterValue.length) {
          this.isPopupVisible = true;
        } else {
          this.onChoosed(this.filterValue);
        }
      },
    };

    this.deviceType = this.event.deviceType;
  }

  private scanerInputListener: () => void;

  getTranslations() {
    //select
    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.modeSource[0].label = text));
    this.translate
      .get('buttons.substract')
      .subscribe((text) => (this.modeSource[1].label = text));
    this.translate
      .get('search')
      .subscribe((text) => (this.modeSource[2].label = text));
    this.translate
      .get('buttons.overwrite')
      .subscribe((text) => (this.modeSource[3].label = text));
    this.translate
      .get('buttons.new')
      .subscribe((text) => (this.modeSource[4].label = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    if (this.inventory) this.readOnly = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.parentId?.currentValue) {
      this.scanerService.setActiveScaner(this, true);
    }
  }

  ngOnDestroy() {
    this.scanerService.setActiveScaner(this, false);
  }

  scanerInputValue: string = '';
  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'f11',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.startScanerTransmission();
          if (!this.scanerService.activeScaner.showScanerContainer) {
            return;
          }
          if (!this.readOnly) {
            this.isBarcodePopupVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: ['cmd + f11'],
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.openDataCollector();
          }
        },
        preventDefault: true,
      }
    );
  }

  startScanerTransmission = (_delay = false) => {
    if (this.scanerInputListener) return;
    this.scanerInputListener = this.renderer2.listen(
      'window',
      'keydown',
      (event) => {
        event.preventDefault();
        if (this.scanerInputTimeout) {
          clearTimeout(this.scanerInputTimeout);
          this.scanerInputTimeout = null;
        }
        this.scanerInputTimeout = setTimeout(() => {
          this.stopScanerTransmission();
        }, 1000);
        if (event.key === 'F11') {
          return;
        }
        if (event.key === 'Enter' || event.key === 'F10') {
          this.stopScanerTransmission();
          return;
        }
        this.scanerInput += event.key;
      }
    );
  };

  stopScanerTransmission = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        this.scanerInputValue = this.scanerInput;
        this.cd.detectChanges();
        resolve(true);
      }, 600);
    });
    this.onChoosedBarcode(this.scanerInput);
    this.isBarcodePopupVisible = false;
    this.scanerInputListener();
    this.scanerInput = '';
    clearTimeout(this.scanerInputTimeout);
    this.scanerInputTimeout = null;
    this.scanerInputListener = null;
  };

  scanerInput: string = '';
  scanerInputTimeout: NodeJS.Timeout;

  getIcon() {
    let icon = 'icon ';
    const opened = this.selectBoxOpened ? 'dx-icon-spinup' : 'dx-icon-spindown';
    return icon + opened;
  }

  toggleSelectBox() {
    this.selectBoxOpened = !this.selectBoxOpened;
  }

  findSelectedItemObject(item: string) {
    return this.filterSource.find((el) => el.value === item) || null;
  }

  focus: boolean = false;
  onFocusIn = () => {
    this.focus = true;
  };

  onFocusOut = () => {
    this.focus = false;
  };

  extraButton = {
    icon: 'no-stroke-width icon absui-icon--add-circle',
    type: 'default',
    elementAttr: {
      id: 'extra',
    },
    onClick: () => {
      if (!this.filterValue.length) {
        this.isPopupVisible = true;
      } else {
        this.onChoosed(this.filterValue);
      }
    },
  };

  onModeValueChanged(e) {
    const foundItem = this.findModeElement(e.value);
    if (foundItem) {
      this.extraButton = {
        type: 'default',
        elementAttr: {
          id: 'extra' + this.unicalGuid,
        },
        icon: 'no-stroke-width icon ' + foundItem.icon,
        onClick: () => {
          if (!this.filterValue.length) {
            this.isPopupVisible = true;
          } else {
            this.onChoosed(this.filterValue);
          }
        },
      };
    }
  }

  findModeElement(value) {
    return this.modeSource.find((el) => el.value === value);
  }

  isPopupVisible: boolean = false;

  onEnter() {
    if (this.filterValue.length) {
      this.onChoosed(this.filterValue);
    }
  }

  onChoosed(e) {
    if (!e || this.readOnly) return;
    const uri = `?${this.selectedItem}=${e}`;
    this.getInputProducts(uri);
  }

  isBarcodePopupVisible: boolean = false;

  onChoosedBarcode(e) {
    if (!e || this.readOnly) return;
    if (!this.showScanerContainer) {
      this.onInputChanged.emit(e);
      return;
    }
    const uri = `?EAN=${e}`;
    this.getInputProducts(uri, true);
  }

  getInputProducts(uri: string, barcodeUse?) {
    this.appServices.getAuth(`products${uri}`).subscribe({
      next: (res) => {
        if (barcodeUse) {
          res.data.barcodeUse = uri.slice(5, uri.length);
        }

        this.onInputChanged.emit({
          mode: this.activeMode,
          items: res.data,
        });
        this.filterValue = '';
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  // dla symulacji skanera -> jak bedzie mozliwosc
  getScanerProducts(uri: string) {
    this.appServices.getAuth(`products${uri}`).subscribe({
      next: (res) => {
        this.onScanerChanged.emit({
          mode: this.activeMode,
          items: res.data,
        });
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  openDataCollector = () => {
    if (!this.readOnly) this.onCollectorOpened.emit(true);
  };
}
