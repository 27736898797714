<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'servicesDevices.historyStatusChanges' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex flex-wrap">
        <div class="form-group-inline-right" style="width: 630px">
          <dx-data-grid
            id="gridContainer"
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [focusedRowEnabled]="true"
            style="height: 260px; margin-bottom: 10px"
            *ngIf="!invoicesDocument; else gridInvoices"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="200"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption="{{ 'users.userName' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="UserName"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'deliveries.data' | translate }}"
              [allowSorting]="false"
              width="260"
              dataField="Date"
              dataType="date"
              [format]="{ type: event.dateFormat + ' HH:mm:ss' }"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'worker' | translate }}"
              [allowSorting]="false"
              width="110"
              dataField="EmployeeName"
            >
            </dxi-column>

            <dxi-column
              caption="Z"
              [allowSorting]="false"
              width="40"
              dataField="StatusBefore"
              alignment="center"
              cellTemplate="statusCell"
            >
            </dxi-column>

            <dxi-column
              caption="Na"
              [allowSorting]="false"
              width="40"
              dataField="StatusAfter"
              alignment="center"
              cellTemplate="statusCellAfter"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'status-type.statusBefore' | translate }}"
              [allowSorting]="false"
              dataField="DocumentStatusBefore"
              width="200"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'status-type.statusAfter' | translate }}"
              [allowSorting]="false"
              dataField="DocumentStatusAfter"
              width="200"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'statusCell'">
              <div
                [ngClass]="{
                  statusN:
                    data.data.DocumentStatusCodeBefore != 'Z' &&
                    data.data.DocumentStatusCodeBefore != 'R' &&
                    data.data.DocumentStatusCodeBefore != 'O' &&
                    data.data.DocumentStatusCodeBefore != 'Y',
                  statusZ: data.data.DocumentStatusCodeBefore == 'Z',
                  statusR: data.data.DocumentStatusCodeBefore == 'R',
                  statusO: data.data.DocumentStatusCodeBefore == 'O',
                  statusY: data.data.DocumentStatusCodeBefore == 'Y'
                }"
              >
                {{ data.data.DocumentStatusCodeBefore }}
              </div>
            </div>

            <div *dxTemplate="let data of 'statusCellAfter'">
              <div
                [ngClass]="{
                  statusN:
                    data.data.DocumentStatusCodeAfter != 'Z' &&
                    data.data.DocumentStatusCodeAfter != 'R' &&
                    data.data.DocumentStatusCodeAfter != 'O' &&
                    data.data.DocumentStatusCodeAfter != 'Y',
                  statusZ: data.data.DocumentStatusCodeAfter == 'Z',
                  statusR: data.data.DocumentStatusCodeAfter == 'R',
                  statusO: data.data.DocumentStatusCodeAfter == 'O',
                  statusY: data.data.DocumentStatusCodeAfter == 'Y'
                }"
              >
                {{ data.data.DocumentStatusCodeAfter }}
              </div>
            </div>
          </dx-data-grid>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-template #gridInvoices>
  <dx-data-grid
    id="gridContainer"
    [dataSource]="dataSource"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [focusedRowEnabled]="true"
    style="height: 260px; margin-bottom: 10px"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="200"></dxo-paging>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxi-column
      caption="{{ 'users.userName' | translate }}"
      [allowSorting]="false"
      width="200"
      dataField="UserName"
    >
    </dxi-column>
    <dxi-column
      caption="{{ 'deliveries.data' | translate }}"
      [allowSorting]="false"
      width="110"
      dataField="Date"
      dataType="date"
      [format]="{ type: event.dateFormat }"
    >
    </dxi-column>
    <dxi-column
      caption="{{ 'deliveries.data' | translate }}"
      [allowSorting]="false"
      width="150"
      dataField="Date"
      dataType="date"
      [format]="{ type: event.dateFormat + ' HH:mm:ss' }"
    >
    </dxi-column>
    <dxi-column
      caption="{{ 'worker' | translate }}"
      [allowSorting]="false"
      width="110"
      dataField="EmployeeName"
    >
    </dxi-column>

    <dxi-column
      caption="Z"
      [allowSorting]="false"
      width="40"
      dataField="StatusBefore"
      alignment="center"
      cellTemplate="statusCell"
    >
    </dxi-column>

    <dxi-column
      caption="Na"
      [allowSorting]="false"
      width="40"
      dataField="StatusAfter"
      alignment="center"
      cellTemplate="statusCellAfter"
    >
    </dxi-column>

    <dxi-column
      caption="{{ 'status-type.statusBefore' | translate }}"
      [allowSorting]="false"
      dataField="StatusDescriptionBefore"
      width="200"
    >
    </dxi-column>

    <dxi-column
      caption="{{ 'status-type.statusAfter' | translate }}"
      [allowSorting]="false"
      dataField="StatusDescriptionAfter"
      width="200"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'statusCell'">
      <div
        [ngClass]="{
          statusN: data.data.DocumentStatusBefore == 'N',
          statusZ: data.data.DocumentStatusBefore == 'Z',
          statusR: data.data.DocumentStatusBefore == 'R',
          statusO: data.data.DocumentStatusBefore == 'O',
          statusY: data.data.DocumentStatusBefore == 'Y'
        }"
      >
        {{ data.data.DocumentStatusBefore }}
      </div>
    </div>

    <div *dxTemplate="let data of 'statusCellAfter'">
      <div
        [ngClass]="{
          statusN: data.data.DocumentStatusAfter == 'N',
          statusZ: data.data.DocumentStatusAfter == 'Z',
          statusR: data.data.DocumentStatusAfter == 'R',
          statusO: data.data.DocumentStatusAfter == 'O',
          statusY: data.data.DocumentStatusAfter == 'Y'
        }"
      >
        {{ data.data.DocumentStatusAfter }}
      </div>
    </div>
  </dx-data-grid>
</ng-template>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>
