<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '260px'"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, 'data-cy': 'add-clasification-popup' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        data-cy="save-clasification-customer-btn"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        data-cy="cancel-clasification-customer-btn"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical" #scrollView>
      <div class="row">
        <div class="col-md-12">
          <dx-tab-panel
            #tabPanel
            [items]="panelTabs"
            class="form-tab"
            [loop]="false"
            [animationEnabled]="false"
            [swipeEnabled]="false"
            [(selectedIndex)]="selectedtab"
          >
            <div *dxTemplate="let name of 'title'; let i = index">
              <div [id]="'tabToolTipArt' + i + unicalGuid">
                <span>{{ name }}</span>
              </div>
            </div>

            <div *dxTemplate="let name of 'item'" style="padding-right: 10px">
              <div
                class="d-flex justify-content-center flex-wrap"
                [formGroup]="form"
              >
                <div class="form-group-inline-right" style="width: 100%">
                  <ng-container [ngSwitch]="name">
                    <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                      <div class="form-box">
                        <div class="box-title">
                          <label style="width: 110px"
                            >{{ "companies.grid.columns.name" | translate }}
                            <strong>*</strong></label
                          >
                          <dx-text-box
                            width="276"
                            [readOnly]="readOnly"
                            valueChangeEvent="input"
                            #inputName
                            formControlName="Name"
                            [ngClass]="{
                              'input-required':
                                !form.controls.Name.valid && submitted
                            }"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <div class="form-box">
                        <div class="box-title">
                          <label style="width: 110px"
                            >{{ "singleBank.form.www" | translate }}
                            <strong>*</strong></label
                          >
                          <dx-text-box
                            width="276"
                            [readOnly]="readOnly"
                            valueChangeEvent="input"
                            #inputName
                            formControlName="WebAdres"
                            [ngClass]="{
                              'input-required':
                                !form.controls.WebAdres.valid && submitted
                            }"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <div class="c-frame-row">
                        <h5>{{ "offers.dataGenerated" | translate }}</h5>
                      </div>

                      <div class="form-box">
                        <div class="box-title">
                          <label style="width: 110px"
                            >{{ "offers.trackingKey" | translate }}
                          </label>
                          <dx-text-box
                            width="276"
                            [readOnly]="true"
                            valueChangeEvent="input"
                            #inputName
                            formControlName="TrackingKey"
                          >
                          </dx-text-box>
                        </div>
                      </div>

                      <div class="form-box">
                        <div class="box-title">
                          <label style="width: 110px"
                            >{{ "buffor.dateAndTime" | translate }}
                          </label>
                          <dx-text-box
                            width="276"
                            [readOnly]="true"
                            valueChangeEvent="input"
                            #inputName
                            formControlName="DateAndTime"
                          >
                          </dx-text-box>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngSwitchCase="
                        'form-financial-document.description' | translate
                      "
                    >
                      <dx-text-area
                        formControlName="Description"
                        style="max-width: 100%; height: 200px !important"
                      ></dx-text-area>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </dx-tab-panel>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
