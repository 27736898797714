<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'offers.sendSms' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); event.setFocus(inputName)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical" #scrollView>
      <form [formGroup]="form">
        <dx-tab-panel
          (onSelectionChanged)="tabSelectionChanged()"
          #tabPanel
          [(selectedIndex)]="selectedTabIndex"
          width="100%"
          [items]="mode == 'add' ? itemTab : itemTabEdit"
          [animationEnabled]="false"
          [swipeEnabled]="false"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div *ngSwitchCase="'offers.shippingDetails' | translate">
                <div
                  class="d-flex justify-content-center flex-wrap flex-box-wrapper"
                >
                  <div
                    class="form-group-inline-right flex-box-column"
                    style="width: 640px"
                  >
                    <div class="form-box">
                      <div class="box-title">
                        <label style="width: 110px"
                          >{{ "companies.grid.columns.name" | translate }}
                          <strong>*</strong></label
                        >
                        <dx-text-box
                          width="276"
                          [readOnly]="readOnly"
                          valueChangeEvent="input"
                          #inputName
                          formControlName="Name"
                          [ngClass]="{
                            'input-required':
                              !form.controls.Name.valid && submitted
                          }"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <div class="form-box">
                      <div class="box-title">
                        <label style="width: 110px"
                          >{{ "offers.URLtracking" | translate }}
                        </label>
                        <app-sms-tracking-url
                          [selectedId]="form.value.TrackingUrlId"
                          (onChoosed)="onChoosedTrackingUrl($event)"
                        ></app-sms-tracking-url>
                      </div>
                    </div>

                    <div class="form-box">
                      <div class="box-title">
                        <label style="width: 110px"></label>
                        <dx-check-box
                          text="{{ 'offers.personalizedMessage' | translate }}"
                          formControlName="IsPersonalized"
                        ></dx-check-box>
                      </div>
                    </div>

                    <!--                    <div class="form-box">-->
                    <!--                      <div class="box-title">-->
                    <!--                        <label style="width: 110px">{{-->
                    <!--                          "offers.URLtracking" | translate-->
                    <!--                        }}</label>-->
                    <!--                        <dx-text-box-->
                    <!--                          width="276"-->
                    <!--                          [readOnly]="readOnly"-->
                    <!--                          valueChangeEvent="input"-->
                    <!--                          formControlName="TrackingUrlId"-->
                    <!--                        >-->
                    <!--                        </dx-text-box>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                    <div class="form-box">
                      <div class="box-title">
                        <label style="width: 110px"
                          >{{ "offers.textMessage" | translate }}
                          <strong>*</strong></label
                        >
                        <dx-text-area
                          formControlName="Message"
                          [ngClass]="{
                            'input-required':
                              !form.controls.Message.valid && submitted
                          }"
                          style="
                            width: calc(100% - 125px);
                            max-width: 100%;
                            height: 200px !important;
                          "
                        ></dx-text-area>
                      </div>
                    </div>

                    <div class="form-box">
                      <div class="box-title">
                        <label style="width: 110px"></label>
                        <dx-drop-down-button
                          text="{{ 'offers.insert' | translate }}"
                          width="auto"
                          [disabled]="readOnly || !form.value.IsPersonalized"
                          style="margin-right: 6px"
                          class="btn-dx-secondary"
                          [items]="exportList"
                          stylingMode="text"
                          type="default"
                          (onItemClick)="onItemClickExport($event)"
                          itemTemplate="fontItem"
                        >
                          <div
                            style="min-width: 240px"
                            *dxTemplate="let data of 'fontItem'"
                          >
                            <span style="min-width: 240px">{{
                              data.text
                            }}</span>
                          </div>
                        </dx-drop-down-button>

                        <dx-button
                          class="btn-dx-secondary"
                          text="{{ 'offers.withoutPolish' | translate }}"
                          style="width: auto; margin-right: 6px"
                          [disabled]="readOnly"
                          (onClick)="replacePolishChar()"
                        >
                        </dx-button>

                        <!--                        <dx-button-->
                        <!--                          class="btn-dx-secondary"-->
                        <!--                          text="{{ 'offers.insertURL' | translate }}"-->
                        <!--                          style="width: auto"-->
                        <!--                          [disabled]="readOnly"-->
                        <!--                        >-->
                        <!--                        </dx-button>-->
                      </div>
                    </div>

                    <!-- <dx-button class="btn-dx-secondary" text="{{'buttons.getFromGUS'| translate}}" style="width: auto"
                            (onClick)="isVisibleGus = true" [disabled]="readOnly">
                          </dx-button> -->
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'offers.SMSrecipientList' | translate">
                <div class="header-bar d-flex" style="margin-bottom: 24px">
                  <div class="confirme-info-message"></div>
                  <div
                    class="header-bar_text"
                    style="padding: 4px 0"
                    [innerHTML]="'RODOPhoneConsentNotice' | translate"
                  ></div>
                </div>

                <app-custom-chips-button
                  name="{{ 'offers.receivingPersons' | translate }}"
                  [list]="receivingPersonsList"
                  (onValueChanged)="onValueChangedReceivingPersons($event)"
                  [disabledClear]="true"
                  style="position: relative; top: 2px"
                  [selectedValueInput]="defaultValue"
                >
                </app-custom-chips-button>
                <br />

                <dx-data-grid
                  [dataSource]="dataSource"
                  [wordWrapEnabled]="false"
                  [showBorders]="true"
                  [height]="heightGrid"
                  (onSelectionChanged)="onSelectionChanged($event)"
                  [(selectedRowKeys)]="selectedRows"
                  style="margin-top: 10px"
                  [columnResizingMode]="'widget'"
                  [remoteOperations]="true"
                  [columnAutoWidth]="true"
                  [allowColumnResizing]="true"
                  [id]="'grid' + unicalGuid"
                  [autoNavigateToFocusedRow]="true"
                  [focusedRowEnabled]="true"
                  #gridBank
                >
                  <dxo-selection
                    mode="multiple"
                    showCheckBoxesMode="always"
                    selectAllMode="page"
                    [allowSelectAll]="false"
                  ></dxo-selection>

                  <dxi-column
                    caption="{{ 'constractors.mobilePhone' | translate }}"
                    [width]="150"
                    [allowSorting]="false"
                    dataField="Phone"
                  >
                  </dxi-column>

                  <dxi-column
                    caption="{{ 'start.userInfo.firstName' | translate }}"
                    dataField="FirstName"
                    [width]="200"
                    [allowSorting]="false"
                  >
                  </dxi-column>

                  <dxi-column
                    caption="{{ 'start.userInfo.lastName' | translate }}"
                    dataField="LastName"
                    [width]="200"
                    [allowSorting]="false"
                  >
                  </dxi-column>

                  <dxi-column
                    caption="{{
                      'form-financial-document.payer-type.contractor'
                        | translate
                    }}"
                    [allowSorting]="false"
                    dataField="CustomerName"
                  >
                  </dxi-column>
                </dx-data-grid>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<wapro-context-menu
  [dataSource]="contextMenuDocument"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
>
</wapro-context-menu>
