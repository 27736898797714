<ng-container *ngIf="componentVisible">
  <ng-container *ngIf="!isPopupMode; else popupTemplate">
    <ng-container *ngTemplateOutlet="header"></ng-container>

    <div class="top-menu-panel">
      <div *ngTemplateOutlet="topButtons"></div>
    </div>
    <div class="center-panel-scroll-verticall">
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </ng-container>

  <ng-template #popupTemplate>
    <dx-popup
      [animation]="{
        show: { type: 'slideIn', direction: 'bottom' },
        hide: { type: 'slideOut', direction: 'bottom' }
      }"
      [showTitle]="true"
      [title]="title"
      [showTitle]="event.deviceType != 'mobile'"
      [showCloseButton]="false"
      [(visible)]="isVisible"
      [showCloseButton]="false"
      [minHeight]="400"
      [minWidth]="600"
      [width]="600"
      [height]="400"
      (onShown)="event.onShownPopUp()"
      (onHidden)="event.onHiddenPopUp()"
      [wrapperAttr]="{ id: unicalGuid }"
      [resizeEnabled]="true"
      [dragEnabled]="true"
      #popup
    >
      <dxo-position my="center" at="center" of="window"> </dxo-position>

      <div *dxTemplate="let content of 'content'">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="pb-3">
          <div *ngTemplateOutlet="topButtons"></div>
        </div>
        <div class="grid-container" *ngTemplateOutlet="grid"></div>
      </div>
    </dx-popup>
  </ng-template>

  <ng-template #header>
    <div class="text-right title-pop-up">
      <!-- Buttons -->
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="onConfirm()"
        [id]="'send' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClose()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <!-- Buttons -->
    </div>
  </ng-template>

  <ng-template #topButtons>
    <div
      class="top-buttons-container"
      style="display: flex; align-items: center"
    >
      <!-- <app-wapro-selected-box
          [(ngModel)]="selected"
          [selectedAll]="isAllSelected"
          *ngIf="loadChildComponent"
        ></app-wapro-selected-box> -->
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        (onClick)="showSingleDevice('add')"
        [disabled]="!focusedGuid"
        [id]="'btn-add' + unicalGuid"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--mode-edit"
        [id]="'btn-editPosition' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="!focusedGuid"
        stylingMode="text"
        type="default"
        (onClick)="showSingleDevice('edit')"
      >
      </dx-button>
      <dx-button
        icon="icon absui-icon--form-items"
        [id]="'btn-showPosition' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="!focusedGuid"
        stylingMode="text"
        type="default"
        (onClick)="showSingleDevice('show')"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="!focusedGuid"
        stylingMode="text"
        type="default"
        (onClick)="deleteSingleDevice()"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--status-off-circle"
        type="default"
        stylingMode="text"
        [text]="
          (focusedRowData?.IsDefault
            ? 'externalDevices.turnOff'
            : 'externalDevices.turnOn'
          ) | translate
        "
        [disabled]="!focusedGuid"
        [id]="'btn-set-default' + unicalGuid"
        (onClick)="setActiveDevice(focusedGuid)"
      >
      </dx-button>

      <div
        style="display: flex; align-items: center; gap: 6px; margin-left: auto"
        [style.marginRight.px]="event.deviceType === 'mobile' ? 0 : 6"
      >
        <div
          class="form-item"
          style="display: inline-flex; gap: 8px"
          *ngIf="noInterconnectorResponse"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--help"
            stylingMode="text"
            type="default"
            (click)="isSetupWizardVisible = true"
            [id]="'btn-help' + unicalGuid"
          ></dx-button>
        </div>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          stylingMode="text"
          type="default"
          (click)="refresh()"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>
      </div>

      <!-- Tooltips -->
      <dx-tooltip
        [target]="'#btn-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-editPosition' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.edit" | translate }} (F2)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-showPosition' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.show" | translate }} (F2)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Esc)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#btn-set-default' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{
            (focusedRowData?.IsDefault
              ? "externalDevices.turnOff"
              : "externalDevices.turnOn"
            ) | translate
          }}
        </div>
      </dx-tooltip>
      <!-- / Tooltips -->
    </div>
  </ng-template>

  <ng-template #grid>
    <div class="row">
      <div class="col-md-12">
        <div #gridContainer [ngSwitch]="deviceType">
          <ng-container *ngSwitchCase="6">
            <dx-data-grid
              [dataSource]="labelPrinters"
              [wordWrapEnabled]="false"
              [showBorders]="true"
              shortcut
              [(selectedRowKeys)]="selected"
              [columnResizingMode]="'widget'"
              [remoteOperations]="true"
              [autoNavigateToFocusedRow]="true"
              (onRowDblClick)="onRowDblClick()"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [id]="'grid' + unicalGuid"
              [allowColumnReordering]="true"
              [(focusedRowKey)]="focusedGuid"
              [(focusedRowIndex)]="focusedIndex"
              (onFocusedRowChanged)="onFocusedRowChanged($event)"
              keyExpr="EctId"
              [focusedRowEnabled]="true"
              #fiscalPrintersGrid
              [width]="'100%'"
              [height]="
                event.deviceType !== 'mobile'
                  ? heightGrid
                  : 'calc(100dvh - 190px)'
              "
              [hoverStateEnabled]="true"
              [ngClass]="{ 'fixed-height': event.deviceType === 'mobile' }"
            >
              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxo-sorting mode="none"> </dxo-sorting>
              <dxo-scrolling
                mode="virtual"
                rowRenderingMode="virtual"
              ></dxo-scrolling>
              <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
              <dxo-selection
                [mode]="'multiple'"
                showCheckBoxesMode="never"
                selectAllMode="page"
              >
              </dxo-selection>
              <dxi-column
                dataField="IsDefault"
                [width]="100"
                caption="D"
                dataField="Configuration.Guid"
                cellTemplate="isDefaultTemplate"
                headerCellTemplate="isDefaultHeaderTemplate"
              ></dxi-column>
              <div *dxTemplate="let cell of 'isDefaultHeaderTemplate'">
                <div class="text-center">Włączona</div>
              </div>
              <div *dxTemplate="let cell of 'isDefaultTemplate'">
                <!-- <div
                  class="d-flex align-items-center justify-content-center text-center"
                >
                  <i
                    class="icon absui-icon--toggle-on-full"
                    [id]="'isDefault' + cell.data.Configuration.Guid"
                    *ngIf="cell.data.IsDefault"
                    (click)="setActiveDevice()"
                  ></i>
                  <i
                    class="icon absui-icon--toggle-off"
                    [id]="'setDefault' + cell.data.Configuration.Guid"
                    *ngIf="!cell.data.IsDefault"
                    (click)="setActiveDevice()"
                  ></i>

                </div>
                <dx-tooltip
                  [target]="'#isDefault' + cell.data.Configuration.Guid"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="true"
                  [showEvent]="event.tooltipShowEvent"
                  [hideEvent]="{ name: 'dxhoverend' }"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "externalDevices.isTurnedOn" | translate }}
                  </div>
                </dx-tooltip>

                <dx-tooltip
                  [target]="'#setDefault' + cell.data.Configuration.Guid"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="true"
                  [showEvent]="event.tooltipShowEvent"
                  [hideEvent]="{ name: 'dxhoverend' }"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "externalDevices.isTurnedOff" | translate }}
                  </div>
                </dx-tooltip> -->
              </div>
              <dxi-column
                caption="{{ 'fiscalPrinter.name' | translate }}"
                dataField="DeviceName"
                cellTemplate="mainColumn"
              >
                <div *dxTemplate="let data of 'mainColumn'">
                  <span
                    class="hov-underline"
                    (click)="showSingleDevice('edit')"
                  >
                    {{ data.value }}
                  </span>
                </div>
              </dxi-column>
              <dxi-column
                caption="{{ 'fiscalPrinter.type' | translate }}"
                [allowSorting]="false"
                dataField="TypeId"
              >
              </dxi-column>
            </dx-data-grid>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <dx-data-grid
              [dataSource]="dataSource"
              [wordWrapEnabled]="false"
              [showBorders]="true"
              shortcut
              [(selectedRowKeys)]="selected"
              [columnResizingMode]="'widget'"
              [remoteOperations]="true"
              [autoNavigateToFocusedRow]="true"
              (onRowDblClick)="onRowDblClick()"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [id]="'grid' + unicalGuid"
              [allowColumnReordering]="true"
              [(focusedRowKey)]="focusedGuid"
              [(focusedRowIndex)]="focusedIndex"
              (onFocusedRowChanged)="onFocusedRowChanged($event)"
              keyExpr="Configuration.Guid"
              [focusedRowEnabled]="true"
              #fiscalPrintersGrid
              [width]="'100%'"
              [height]="heightGrid"
              [hoverStateEnabled]="true"
              [ngClass]="{ 'fixed-height': event.deviceType === 'mobile' }"
              [height]="
                event.deviceType !== 'mobile'
                  ? heightGrid
                  : 'calc(100dvh - 190px)'
              "
            >
              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxo-sorting mode="none"> </dxo-sorting>
              <dxo-scrolling
                mode="virtual"
                rowRenderingMode="virtual"
              ></dxo-scrolling>
              <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
              <dxo-selection
                [mode]="'multiple'"
                showCheckBoxesMode="never"
                selectAllMode="page"
              >
              </dxo-selection>
              <dxi-column
                dataField="IsDefault"
                [width]="100"
                caption="D"
                dataField="Configuration.Guid"
                cellTemplate="isDefaultTemplate"
                headerCellTemplate="isDefaultHeaderTemplate"
              ></dxi-column>
              <div *dxTemplate="let cell of 'isDefaultHeaderTemplate'">
                <div class="text-center">Włączona</div>
              </div>
              <div *dxTemplate="let cell of 'isDefaultTemplate'">
                <div
                  class="d-flex align-items-center justify-content-center text-center"
                >
                  <i
                    class="icon absui-icon--toggle-on-full"
                    [id]="'isDefault' + cell.data.Configuration.Guid"
                    *ngIf="cell.data.IsDefault"
                    (click)="setActiveDevice()"
                  ></i>
                  <i
                    class="icon absui-icon--toggle-off"
                    [id]="'setDefault' + cell.data.Configuration.Guid"
                    *ngIf="!cell.data.IsDefault"
                    (click)="setActiveDevice()"
                  ></i>
                </div>
                <dx-tooltip
                  [target]="'#isDefault' + cell.data.Configuration.Guid"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="true"
                  [showEvent]="event.tooltipShowEvent"
                  [hideEvent]="{ name: 'dxhoverend' }"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "externalDevices.isTurnedOn" | translate }}
                  </div>
                </dx-tooltip>

                <dx-tooltip
                  [target]="'#setDefault' + cell.data.Configuration.Guid"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="true"
                  [showEvent]="event.tooltipShowEvent"
                  [hideEvent]="{ name: 'dxhoverend' }"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "externalDevices.isTurnedOff" | translate }}
                  </div>
                </dx-tooltip>
              </div>
              <dxi-column
                caption="{{ 'fiscalPrinter.name' | translate }}"
                dataField="Configuration.Name"
                cellTemplate="mainColumn"
              >
                <div *dxTemplate="let data of 'mainColumn'">
                  <span
                    class="hov-underline"
                    (click)="showSingleDevice('edit')"
                  >
                    {{ data.value }}
                  </span>
                </div>
              </dxi-column>
              <dxi-column
                caption="{{ 'fiscalPrinter.type' | translate }}"
                [allowSorting]="false"
                dataField="TypeDescription"
              >
              </dxi-column>
            </dx-data-grid>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <app-single-device
    [isVisible]="isSingleVisible"
    (onClosing)="onClosingForm()"
    (onSaved)="onDeviceSave($event)"
    [mode]="mode"
    [deviceGuid]="focusedGuid"
    [deviceType]="deviceType"
    [title]="title"
    *ngIf="isSingleVisible"
  >
  </app-single-device>

  <app-confirm-dialog
    [isVisible]="isDeleteVisible"
    (onClosing)="onClosingDelete()"
    (onCanceling)="onCancelingDelete()"
    (onRemoving)="onRemoving()"
    [confirmText]="deleteConfirmText"
  >
  </app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isInterconnectorClient"
    [dynamicBtns]="interconnectorClientButtons"
    (onDynamicBtnClicked)="onClosingInterconnectorClient($event)"
    btnConfig="dynamicContent"
    kindDialog="information"
    [confirmHeader]="
      'externalDevices.interconnectorClientDialogHeader' | translate
    "
    [confirmText]="'externalDevices.interconnectorClientDialogText' | translate"
  >
  </app-confirm-dialog>

  <app-setup-wizard
    [isVisible]="isSetupWizardVisible"
    [config]="setupWizardConfig"
    (onClosing)="onSetupWizardClosing()"
    (onContinue)="onSetupWizardContinue()"
    (onLinkClick)="onSetupWizardLinkClick()"
    mode="interconnector"
  >
    </app-setup-wizard
  >
</ng-container>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="isSingleVisible || isSetupWizardVisible || isDeleteVisible"
>
</wapro-keyboard-shortcuts>
