import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';

import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';

import { environment } from '../../../../environments/environment';
import { AddSmsTrackingUrlComponent } from './add-sms-tracking-url/add-sms-tracking-url.component';
@Component({
    selector: 'app-sms-tracking-url',
    imports: [
        CommonModule,
        DxDropDownBoxModule,
        TranslateModule,
        DxButtonModule,
        DxDataGridModule,
        DxContextMenuModule,
        DxTooltipModule,
        AddSmsTrackingUrlComponent,
        ConfirmDialogComponent,
    ],
    templateUrl: './sms-tracking-url.component.html',
    styleUrl: './sms-tracking-url.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['readOnly', 'selectedId']
})
export class SmsTrackingUrlComponent implements OnInit, OnChanges {
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass: any;
  unicalGuid;
  isGridBoxOpened: boolean = false;

  dataSource: any;
  dataSourceDrop: smsTracking[] = [];
  selectedRow: number[] = [];
  focusedRowIndex: number = 0;
  focusedSelected: smsTracking[] = [];
  selectedId;
  mode: string = 'add';

  readOnly: boolean = false;
  addRow: boolean = false;

  contextMenu;
  isDeleteRow: boolean = false;

  constructor(
    public event: EventService,
    public cd: ChangeDetectorRef,
    public appService: AppServices,
    public translate: TranslateService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.contextMenu = [
      { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
      { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
      { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
      {
        text: 'Generuj klucz śledzący',
        icon: 'icon absui-icon--globe-key',
        itemIndex: 3,
      },
    ];

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));

    this.translate
      .get('offers.generateTrackingKey')
      .subscribe((text) => (this.contextMenu[3].text = text));
  }

  ngOnInit(): void {
    this.getData();
  }

  choosingTracking: any = [];
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedId &&
      changes.selectedId.currentValue &&
      changes.selectedId.currentValue != null &&
      changes.selectedId.currentValue != undefined &&
      changes.selectedId.currentValue != '' &&
      changes.selectedId.currentValue != 0
    ) {
      this.appService
        .getAuth(
          `customers/additionalOperations/smsSenderMessage/smsTrackingUrl?TrackingUrlId=${changes.selectedId.currentValue}`
        )
        .subscribe((data) => {
          this.dataSourceDrop = data.data;
          this.choosingTracking = [changes.selectedId.currentValue];
          this.cd.detectChanges();
        });
    }
  }

  getData() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'TrackingUrlId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}customers/additionalOperations/smsSenderMessage/smsTrackingUrl`,
        onLoaded: (data) => {
          this.dataSourceDrop = data;
        },
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}customers/additionalOperations/smsSenderMessage/smsTrackingUrl`,
      }),
      reshapeOnPush: true,
    });
  }

  onRowClick() {
    this.onChoosingSmsTracking();
  }

  onFocusedRowChanged = (e) => {
    this.selectedRow = [e.row.data.TrackingUrlId];
    this.focusedSelected = [e.row.data];
  };

  onKeyDown = (e) => {
    try {
      if (e.event.originalEvent.code == 'Enter') this.onChoosingSmsTracking();
      if (e.event.originalEvent.code == 'Escape') {
        this.isGridBoxOpened = false;
        this.cd.detectChanges();
      }
    } catch {}
  };

  onChoosingSmsTracking() {
    this.choosingTracking = [this.focusedSelected[0].TrackingUrlId];
    this.onChoosed.emit(this.focusedSelected[0]);
    this.isGridBoxOpened = false;
    this.cd.detectChanges();
  }

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onOpenedChanged = (e) => {
    if (e) {
      try {
        setTimeout(() => {
          this.gridClass.instance.focus();
        }, 500);
      } catch {}
    }
  };

  openSmsTrackingForm(mode) {
    this.mode = mode;
    this.addRow = true;
    this.cd.detectChanges();
  }

  onClosing() {
    this.addRow = false;
    this.cd.detectChanges();
  }

  onInserted(e) {
    this.addRow = false;
    this.dataSource.reload().then((data) => {
      this.focusedRowIndex = this.event.returnFocusIndex(
        data,
        'TrackingUrlId',
        e.Id
      );
      this.cd.detectChanges();
      this.focusedSelected = [data[this.focusedRowIndex]];
    });
  }

  contextMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) this.openSmsTrackingForm('add');
        break;
      case 1:
        if (!this.readOnly) this.openSmsTrackingForm('edit');
        break;
      case 2:
        if (!this.readOnly) this.isDelete();
        break;
      case 3:
        if (!this.readOnly) this.generateKey();
        break;
    }
  }

  isDelete = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.isDeleteRow = true;
      this.cd.detectChanges();
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    this.cd.detectChanges();
  }

  delete() {
    this.appService
      .deleteAuth(
        `customers/additionalOperations/smsSenderMessage/smsTrackingUrl/${this.focusedSelected[0].TrackingUrlId}`
      )
      .subscribe(
        () => {
          this.isDeleteRow = false;
          this.cd.detectChanges();
          this.dataSource.reload().then(() => {
            this.focusedRowIndex = 0;
          });
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }
  generateKey() {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.appService
        .postAuth(
          `customers/additionalOperations/smsSenderMessage/smsTrackingUrl/${this.focusedSelected[0].TrackingUrlId}/generateKey`,
          {}
        )
        .subscribe(
          () => {
            this.dataSource.reload().then((data) => {
              this.focusedRowIndex = 0;
              this.focusedSelected = [data[0]];
            });
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  }
}

interface smsTracking {
  TrackingUrlId: number;
  CompanyId: number;
  Name: string;
  Description: string;
  WebAdres: string;
  TrackingKey: string;
  DateAndTime: string;
}
