import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-discount-markup-factor',
    templateUrl: './discount-markup-factor.component.html',
    styleUrls: ['./discount-markup-factor.component.scss'],
    inputs: [
        'readOnly',
        'isVisible',
        'valueBefore',
        'discountMarkupPercent',
        'discountMarkupAmount',
        'type',
        'multiply',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DiscountMarkupFactorComponent implements OnInit {
  @Output() onClosed = new EventEmitter();
  @Output() onChoosed = new EventEmitter();

  type: number = 0; // 0 - netto, 1 - brutto
  valueBefore: number = 0;
  discountMarkupPercent: number = 0;
  discountMarkupAmount: number = 0;
  valueAfter: number = 0;

  isVisible;
  readOnly;

  unicalGuid;

  shortcuts: ShortcutInput[] = [];
  multiply;

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.getTranslations();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.isVisible?.currentValue &&
      changes.isVisible.currentValue !== changes.isVisible.previousValue
    ) {
      if (this.valueBefore != null && this.valueBefore != 0) {
        if (this.multiply)
          this.discountMarkupPercent = this.discountMarkupPercent * 100;
        if (this.discountMarkupPercent) {
          this.countMarkupPercent();
        } else if (this.discountMarkupAmount) {
          this.countMarkupAmount();
        } else {
          this.discountMarkupAmount = 0;
          this.discountMarkupPercent = 0;
          this.valueAfter = this.valueBefore;
        }
        this.cd.detectChanges();
      }
    }
  }

  onSave() {
    this.onChoosed.emit({
      valueBefore: this.valueBefore,
      discountMarkupPercent: this.discountMarkupPercent,
      discountMarkupAmount: this.discountMarkupAmount,
      valueAfter: this.valueAfter,
    });
    this.isVisible = false;
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.onSave();
          }
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosed.emit(true);
        },
        preventDefault: true,
      }
    );
  }

  visibleChange() {
    setTimeout(() => {
      if (!this.isVisible) {
        this.event.disabletShortcuts.next(true);
        this.onClosed.emit(true);
      }
    });
  }

  getTranslations() {}

  countMarkupPercent() {
    const discountAmount: number =
      (this.valueBefore * this.discountMarkupPercent) / 100;
    this.discountMarkupAmount = Number(discountAmount.toFixed(2)) || 0;

    this.valueAfter = this.valueBefore + this.discountMarkupAmount;
  }

  countMarkupAmount() {
    const discountPercent: number =
      (this.discountMarkupAmount / this.valueBefore) * 100;
    this.discountMarkupPercent = Number(discountPercent.toFixed(2)) || 0;
    this.valueAfter = this.valueBefore + this.discountMarkupAmount;
  }

  countValueAfter() {
    const discountAmount: number = this.valueAfter - this.valueBefore;
    this.discountMarkupAmount = Number(discountAmount.toFixed(2)) || 0;

    const discountPercent: number =
      (this.discountMarkupAmount / this.valueBefore) * 100;
    this.discountMarkupPercent = Number(discountPercent.toFixed(2)) || 0;
  }

  onEnterMarkupPercent(e) {
    if (!e.event) return;
    this.countMarkupPercent();
  }

  onEnterMarkupAmount(e) {
    if (!e.event) return;
    this.countMarkupAmount();
  }

  onEnterMarkupValueAfter(e) {
    if (!e.event) return;
    this.countValueAfter();
  }
}
