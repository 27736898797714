<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="row">
        <div class="col-md-12">
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--add-circle"
            [text]="'buttons.add' | translate"
            [disabled]="readOnly"
            [id]="'btn-add' + unicalGuid"
            (onClick)="addSmsSenderMessage()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--mode-edit"
            [text]="'buttons.edit' | translate"
            [id]="'btn-edit' + unicalGuid"
            [disabled]="focusedSelected.length == 0 || readOnly"
            (onClick)="editSmsSenderMessage()"
          >
          </dx-button>

          <dx-button
            icon="icon absui-icon--highlight-off"
            [id]="'btn-delete' + unicalGuid"
            class="btn-dx-top-menu-folder"
            [disabled]="focusedSelected.length == 0 || readOnly"
            [text]="'buttons.delete' | translate"
            (onClick)="isDeleteRow = true"
          >
          </dx-button>

          <dx-button
            icon="icon absui-icon--send"
            [id]="'btn-send' + unicalGuid"
            class="btn-dx-top-menu"
            [disabled]="focusedSelected.length == 0 || readOnly"
            [text]="'buffor.send' | translate"
            (onClick)="sendSms()"
          >
          </dx-button>

          <!--          <dx-button-->
          <!--            [id]="'btn-createACrmJob' + unicalGuid"-->
          <!--            icon="icon absui-icon&#45;&#45;calendar-text-add"-->
          <!--            class="btn-dx-top-menu"-->
          <!--            [disabled]="readOnly"-->
          <!--            [text]="'offers.createACrmJob' | translate"-->
          <!--          >-->
          <!--          </dx-button>-->

          <!--          <dx-button-->
          <!--            [id]="'btn-checkTheDelivery' + unicalGuid"-->
          <!--            icon="icon absui-icon&#45;&#45;messge-ok"-->
          <!--            class="btn-dx-top-menu"-->
          <!--            [disabled]="readOnly"-->
          <!--            [text]="'offers.checkTheDelivery' | translate"-->
          <!--          >-->
          <!--          </dx-button>-->

          <!--          <dx-button-->
          <!--            [id]="'btn-paymentM' + unicalGuid"-->
          <!--            icon="icon absui-icon&#45;&#45;payment"-->
          <!--            class="btn-dx-top-menu"-->
          <!--            [disabled]="readOnly"-->
          <!--            [text]="'form-financial-document.paymentM' | translate"-->
          <!--          >-->
          <!--          </dx-button>-->

          <!--          <dx-button-->
          <!--            [id]="'btn-accountBalance' + unicalGuid"-->
          <!--            icon="icon absui-icon&#45;&#45;wallet-giftcard"-->
          <!--            class="btn-dx-top-menu"-->
          <!--            [disabled]="readOnly"-->
          <!--            [text]="'offers.accountBalance' | translate"-->
          <!--          >-->
          <!--          </dx-button>-->

          <!--          <dx-button-->
          <!--            [id]="'btn-checktheURL' + unicalGuid"-->
          <!--            icon="icon absui-icon&#45;&#45;link"-->
          <!--            class="btn-dx-top-menu"-->
          <!--            [disabled]="readOnly"-->
          <!--            [text]="'offers.checktheURL' | translate"-->
          <!--          >-->
          <!--          </dx-button>-->
        </div>
      </div>

      <div class="row" style="margin-top: 12px">
        <div class="col-md-12">
          <app-custom-dropdown-box
            [items]="filterCriteria"
            [(selectedItem)]="valueCriteria"
            [(filterValue)]="filterValue"
            (onValueChanged)="onFilterDataChanged($event)"
            style="margin-right: 5px"
          >
          </app-custom-dropdown-box>

          <app-custom-chips-button name="Status" [list]="filterCriteriaStatus">
          </app-custom-chips-button>
        </div>
      </div>

      <div class="row-">
        <div class="col-md-12" style="padding: 0px 0px">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            [height]="heightGrid"
            [(selectedRowKeys)]="selectedRows"
            [(focusedRowIndex)]="focusedRowIndexDataSource"
            style="margin-top: 10px"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            [autoNavigateToFocusedRow]="true"
            [focusedRowEnabled]="true"
            #gridBank
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="virtual"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            >
            </dxo-scrolling>
            <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="allPages"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'companies.grid.columns.name' | translate }}"
              [width]="150"
              [allowSorting]="false"
              dataField="Name"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="right"
              [allowSorting]="false"
              dataField="DateAndTime"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.time' | translate }}"
              dataType="date"
              [format]="{ type: 'HH:mm' }"
              width="110"
              alignment="right"
              [allowSorting]="false"
              dataField="DateAndTime"
            >
            </dxi-column>
            <dxi-column
              caption="S"
              [width]="60"
              [allowSorting]="false"
              alignment="center"
              cellTemplate="booleanTemplate"
              dataField="IsPersonalized"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'booleanTemplate'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.value; else falseTemplate"
              ></i>
              <ng-template #falseTemplate
                ><i class="icon absui-icon--close close-grid"></i
              ></ng-template>
            </div>
            <dxi-column
              caption="URL"
              [width]="100"
              [allowSorting]="false"
              dataField="TrackingUrlId"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.sent' | translate }}"
              [width]="100"
              [allowSorting]="false"
              dataField="Sent"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'status-type.text' | translate }}"
              [allowSorting]="false"
              dataField="Message"
            >
            </dxi-column>
          </dx-data-grid>

          <!-- bottom panel -->
          <div class="row">
            <div
              class="bottom-side-panel"
              mwlResizable
              #bottomSidePanel
              style="margin-top: 20px"
              (resizeStart)="onResizeStartBottom()"
              (resizeEnd)="resizeEndBottom()"
              (resizing)="resizingBottom($event)"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down':
                      showBottomPanel,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up':
                      !showBottomPanel
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>

                <dx-tab-panel
                  width="100%"
                  [items]="[
                    'status-type.messageContent' | translate,
                    'offers.SMSrecipients' | translate,
                    'offers.shipmentExecutionLog' | translate
                  ]"
                  [animationEnabled]="false"
                  [swipeEnabled]="false"
                  [showNavButtons]="true"
                  (onSelectionChanged)="onSelectionTabChangedLeft()"
                  [(selectedIndex)]="selectedIndex"
                >
                  <div *dxTemplate="let name of 'item'">
                    <ng-container [ngSwitch]="name">
                      <div
                        *ngSwitchCase="'status-type.messageContent' | translate"
                      >
                        <dx-text-area
                          style="max-width: 100%; width: 100%"
                          [readOnly]="true"
                          [(ngModel)]="message"
                          [style.height]="heightBottomGrid + 'px!important'"
                        ></dx-text-area>
                      </div>

                      <div
                        *ngSwitchCase="'offers.SMSrecipients' | translate"
                        class="text-left"
                      >
                        <dx-button
                          class="btn-dx-top-menu-folder"
                          icon="icon absui-icon--add-circle"
                          [text]="'buttons.add' | translate"
                          [disabled]="readOnly || focusedSelected.length == 0"
                          [id]="'btn-add-right' + unicalGuid"
                          (onClick)="iSCustomerOpen = true"
                        >
                        </dx-button>

                        <dx-button
                          icon="icon absui-icon--highlight-off"
                          [id]="'btn-delete-right' + unicalGuid"
                          class="btn-dx-top-menu-folder"
                          [disabled]="
                            readOnly || focusedRecipientSelected.length == 0
                          "
                          [text]="'buttons.delete' | translate"
                          (onClick)="isDeleteContact = true"
                        >
                        </dx-button>

                        <dx-data-grid
                          [dataSource]="dataSourceRecipient"
                          [wordWrapEnabled]="false"
                          [showBorders]="true"
                          [height]="heightBottomGrid - 10"
                          [(selectedRowKeys)]="selectedRowsRecipient"
                          [columnResizingMode]="'widget'"
                          [remoteOperations]="true"
                          [columnAutoWidth]="true"
                          (onFocusedRowChanged)="
                            onFocusedRowChangedRecipient($event)
                          "
                          [allowColumnResizing]="true"
                          [autoNavigateToFocusedRow]="true"
                          [focusedRowEnabled]="true"
                        >
                          <dxo-load-panel [enabled]="false"></dxo-load-panel>
                          <dxo-scrolling
                            mode="virtual"
                            columnRenderingMode="virtual"
                            preloadEnabled="true"
                          >
                          </dxo-scrolling>
                          <dxo-paging
                            [pageSize]="pageSize"
                            [enabled]="true"
                          ></dxo-paging>
                          <dxo-selection
                            mode="single"
                            showCheckBoxesMode="always"
                            selectAllMode="allPages"
                          >
                          </dxo-selection>
                          <dxo-editing
                            mode="row"
                            [confirmDelete]="false"
                          ></dxo-editing>

                          <dxi-column
                            caption="{{
                              'constractors.mobilePhone' | translate
                            }}"
                            [width]="150"
                            [allowSorting]="false"
                            dataField="Phone2"
                          >
                          </dxi-column>

                          <dxi-column
                            caption="{{
                              'start.userInfo.lastName' | translate
                            }}"
                            [width]="150"
                            [allowSorting]="false"
                            dataField="LastName"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{
                              'start.userInfo.firstName' | translate
                            }}"
                            [width]="150"
                            [allowSorting]="false"
                            dataField="FirstName"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{ 'constractors.company' | translate }}"
                            [width]="150"
                            [allowSorting]="false"
                            dataField="CustomerName"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{
                              'constractors.jobPosition' | translate
                            }}"
                            [width]="150"
                            [allowSorting]="false"
                            dataField="Role"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="E-mail"
                            [allowSorting]="false"
                            dataField="Email"
                          >
                          </dxi-column>
                        </dx-data-grid>
                      </div>

                      <div
                        *ngSwitchCase="
                          'offers.shipmentExecutionLog' | translate
                        "
                      >
                        <dx-data-grid
                          [dataSource]="dataSourceDiary"
                          [wordWrapEnabled]="false"
                          [showBorders]="true"
                          [height]="heightBottomGrid"
                          [(selectedRowKeys)]="selectedRowsDiary"
                          [columnResizingMode]="'widget'"
                          [remoteOperations]="true"
                          [columnAutoWidth]="true"
                          [(focusedRowIndex)]="focusedRowIndex"
                          [allowColumnResizing]="true"
                          [autoNavigateToFocusedRow]="true"
                          [focusedRowEnabled]="true"
                        >
                          <dxo-load-panel [enabled]="false"></dxo-load-panel>
                          <dxo-scrolling
                            mode="virtual"
                            columnRenderingMode="virtual"
                            preloadEnabled="true"
                          >
                          </dxo-scrolling>
                          <dxo-paging
                            [pageSize]="pageSize"
                            [enabled]="true"
                          ></dxo-paging>
                          <dxo-selection
                            mode="single"
                            showCheckBoxesMode="always"
                            selectAllMode="allPages"
                          >
                          </dxo-selection>
                          <dxo-editing
                            mode="row"
                            [confirmDelete]="false"
                          ></dxo-editing>

                          <dxi-column
                            caption="{{ 'offers.Sender' | translate }}"
                            [width]="150"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{ 'constractors.recipient' | translate }}"
                            [width]="150"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{
                              'form-financial-document.date' | translate
                            }}"
                            dataType="date"
                            [format]="{ type: event.dateFormat }"
                            width="110"
                            alignment="left"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{ 'offers.time' | translate }}"
                            [width]="150"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{ 'offers.Consignor' | translate }}"
                            [width]="150"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="Status SMS"
                            [width]="150"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                          <dxi-column
                            caption="{{ 'offers.deliveryStatus' | translate }}"
                            [allowSorting]="false"
                          >
                          </dxi-column>
                        </dx-data-grid>
                      </div>
                    </ng-container>
                  </div>
                </dx-tab-panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<div *ngIf="loadChildComponent">
  <app-confirm-dialog
    [isVisible]="isDeleteRow"
    (onRemoving)="delete(); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isDeleteContact"
    (onRemoving)="deleteContact(); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
    [confirmText]="
      'form-commercial-operation.doYouWantToDeleteTheAssignedRecipient'
    "
  >
  </app-confirm-dialog>

  <app-add-sms
    [mode]="mode"
    [isVisible]="addRow"
    (onClosing)="addRow = false"
    [CustomerId]="CustomerId"
    [type]="type"
    (onInserted)="onInsertedSms()"
    (onSetFocus)="onSetFocus($event)"
    [selected]="focusedSelected"
  ></app-add-sms>

  <app-contractors
    *ngIf="iSCustomerOpen"
    [isVisible]="iSCustomerOpen"
    [popUpMode]="true"
    (onClosed)="iSCustomerOpen = false"
    [isButtonContact]="true"
    (onChoosedContact)="onChoosedContact($event)"
  ></app-contractors>
</div>

<dx-context-menu
  [dataSource]="contextMenu"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
>
</dx-context-menu>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
