import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { HelpService } from 'src/app/help-service.service';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { DoubleClickResult, SingleRecordMode } from 'src/app/event.model';
import { TASK_TYPES_KEY } from 'src/app/consts';
import { of, map, tap } from 'rxjs';

@Component({
    selector: 'app-task-types',
    templateUrl: './task-types.component.html',
    styleUrls: ['./task-types.component.scss'],
    inputs: ['dropDownBoxMode', 'selectedId', 'readOnly', 'width'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskTypesComponent implements OnInit {
  @Output() onChoosed = new EventEmitter();
  @Output() shouldReloadTypes = new EventEmitter<void>();
  @ViewChild('gridClass') gridClass;

  helpService = inject(HelpService);
  readOnly;
  width;
  selectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  documentListPom = [];
  isContextMenuMobile: boolean = false;
  isGridBoxOpened: boolean = false;
  documentList;

  dataSource = [];

  isDeleteRow: boolean = false;

  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 200;

  myEventEdit;
  myEventChoose;
  myEventEsc;
  myEventShow;
  myEventDelete;
  unicalGuid;
  contextMenu;
  widthCityColumn = '250';

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };

  doubleClickResult: DoubleClickResult = 'Edycja';

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
    });

    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.getDate();

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 170;
    }

    this.myEventEdit = this.event.edit.subscribe(() => {
      if (
        !this.readOnly &&
        (this.isGridBoxOpened || !this.dropDownBoxMode) &&
        this.perABD.editBtn
      ) {
        if (this.focusedSelected.length > 0)
          this.setSingleRecordVisible('edit');
      }
    });

    this.myEventDelete = this.event.delete.subscribe(() => {
      this.isDelete();
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.gridClass.instance.getSelectedRowsData().length > 0)
          this.onChoosingRow();
      }
    });

    this.contextMenu = [
      { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
      { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
      { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    ];

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));

    this.perABD = this.event.checkPermissionsBtn(
      'DCrmTyp',
      'ECrmTyp',
      'UCrmTyp',
      null
    );
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.perABD.addBtn)
          this.setSingleRecordVisible('add');
        break;
      case 1:
        if (!this.readOnly && this.perABD.editBtn)
          this.setSingleRecordVisible('edit');
        break;
      case 2:
        if (!this.readOnly && this.perABD.deleteBtn) this.isDelete();
        break;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selectedId) {
      if (changes?.selectedId?.currentValue) {
        this.getTaskTypesDef().subscribe({
          next: (data) => {
            this.documentList = data;
            this.dataSource = data;
            this.choosingDocument = [Number(this.selectedId)];
            this.cd.detectChanges();
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
      } else {
        this.documentList = [];
        this.choosingDocument = [];
      }
    }
  }

  refreshData() {
    sessionStorage.removeItem(TASK_TYPES_KEY);
    this.getDate();
  }

  getTaskTypesDef() {
    const localData = this.event.getSessionStorageData(TASK_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`crm/taskTypes`).pipe(
        map((res) => res.data || []),
        tap((data) => {
          this.event.saveSessionStorageData(TASK_TYPES_KEY, data);
        })
      );
    }
  }

  getDate = () => {
    this.getTaskTypesDef().subscribe({
      next: (data) => {
        this.dataSource = data;
        this.cd.detectChanges();
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  };

  ngOnDestroy() {
    this.myEventEdit.unsubscribe();
    this.myEventDelete.unsubscribe();
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.perABD.addBtn) {
            document.getElementById(`btn-add${this.unicalGuid}`).click();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/kartoteki/kartoteki-inne'
          );
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
        },
        preventDefault: true,
      }
    );
  }

  onClosingForm = () => {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  onInserted = (e) => {
    sessionStorage.removeItem(TASK_TYPES_KEY);
    this.getDate();
    this.singleRecordMode = null;
    this.focusedSelected = [e];
    this.shouldReloadTypes.emit();
  };

  focusedSelected = [];
  onFocusedRowChanged = (e) => {
    this.selectedRow = [e.row.data.TypeId];
    this.focusedSelected = [e.row.data];
    this.cd.detectChanges();
  };

  onChoosingRow = () => {
    if (!this.readOnly) {
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      this.onChoosed.emit(this.documentList[0]);
      this.isGridBoxOpened = false;
    }
  };

  onOpenedChanged = (e) => {
    setTimeout(() => {
      this.event.disabletShortcuts.next(e);
    }, 0);

    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onSelectionChanged = (e) => {
    this.documentListPom = [];
    this.documentListPom.push(e.selectedRowsData[0]);
  };

  isDelete = () => {
    if (
      this.focusedSelected.length > 0 &&
      this.perABD.deleteBtn &&
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) this.gridClass.instance.focus();
    }, 500);
  }

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) {
      if (this.doubleClickResult === 'Edycja' && this.perABD.editBtn) {
        this.setSingleRecordVisible('edit');
      } else {
        this.setSingleRecordVisible('show');
      }
    } else this.onChoosingRow();
  };

  delete = () => {
    this.appService
      .deleteAuth(`crm/taskTypes/${this.focusedSelected[0].TypeId}`)
      .subscribe({
        next: () => {
          sessionStorage.removeItem(TASK_TYPES_KEY);
          this.getDate();
          if (this.choosingDocument == this.selectedRow[0]) {
            this.choosingDocument = [];
          }
          this.isDeleteRow = false;
          setTimeout(() => {
            if (this.gridClass) {
              this.gridClass.focusedRowIndex = 0;
              this.gridClass.instance.focus();
            }
          }, 500);
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  };

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  editRecord(data?) {
    if (data) this.focusedSelected = [data];
    if (
      !this.readOnly &&
      !this.isSingleRecordVisible &&
      this.perABD.editBtn &&
      this.focusedSelected.length > 0 &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    )
      this.setSingleRecordVisible('edit');
  }

  isSingleRecordVisible: boolean = false;
  singleRecordMode: SingleRecordMode = null;

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  setDefault() {
    const record = JSON.parse(JSON.stringify(this.focusedSelected[0]));
    record.IsDefault = true;
    this.appService
      .putAuth(`crm/taskTypes/${record.TypeId}`, record)
      .subscribe({
        next: () => {
          sessionStorage.removeItem(TASK_TYPES_KEY);
          this.getDate();
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  mobileGridWidth;
  mobileColWidth = {
    name: 160,
    def: 70,
    dots: 40,
  };

  onMobileGridContentReady = (e) => {
    this.mobileGridWidth = e.element.offsetWidth;
    let { def, dots } = this.mobileColWidth;

    this.mobileColWidth.name = this.mobileGridWidth - def - dots;
  };
}
