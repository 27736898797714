<dx-popup [width]="950" [height]="600" position="center" [showCloseButton]="false" [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
}" [showTitle]="true" title="{{ 'contractorLimit.title' | translate }}" [visible]="true"
    (onShown)="event.onShownPopUp()" (onHidden)="event.onHiddenPopUp();" [wrapperAttr]="{ id: unicalGuid}">
    <div *dxTemplate="let content of 'content'">
        <div class="text-right title-pop-up">
            <dx-button text="{{ 'buttons.close' | translate }}" type="danger" (onClick)="onClosing.emit()"
                [id]="'cancel'+unicalGuid"></dx-button>
            <dx-tooltip [target]="'#cancel'+unicalGuid" [showEvent]="event.tooltipShowEvent" hideEvent="dxhoverend"
                [hideOnOutsideClick]="false">
                <div *dxTemplate="let data of 'content'">
                    {{'buttons.close' | translate}} (Esc)
                </div>
            </dx-tooltip>
        </div>

        <dx-scroll-view width="100%" [height]="(event.deviceType != 'mobile')?'100%':'calc(100% - 64px)'"
            direction="vertical" #scrollView *ngIf="data$ | async as data">
            <div class="popup-form">
                <div class="header-document">
                    <header>
                      <p class="p-header-info">
                        {{'userExtensions.customers' |translate}}:
                        <strong >{{customer?.Name}}</strong> |
                        {{'form-financial-document.payer' |translate}}:
                        <strong>{{customer?.PayerName || customer?.Name}}</strong>
                      </p>
                    </header>
                </div>

                <div class="d-flex justify-content-center flex-wrap">
                    <div class="form-group-inline-right" style="width: 1020px;">
                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 900px;">
                                <div class="form-box">
                                    <div class="box-title">
                                        <div class="c-frame-row" style="width: 100%;">
                                            <h5 style="margin-top: 5px; text-align: left; width: 100%">{{ 'contractorLimit.payersBalance' | translate }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 420px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'contractorLimit.grantedLimit' | translate }}
                                            </label>
                                            <dx-number-box width="276" format="#0.00"
                                            [readOnly]="true" [value]="data.BuyerLimit || 0">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group-inline-right flex-box-column" style="width: 420px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'contractorLimit.forUse' | translate }}
                                            </label>
                                            <dx-number-box width="276" format="#0.00"
                                            [readOnly]="true" [value]="data.Limit || 0">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 850px;">
                                <div class="form-box">
                                    <div class="box-title">
                                        <div class="c-frame-row" style="width: 100%;">
                                            <h5 style="margin-top: 5px; text-align: left; width: 100%">
                                                {{ 'contractorLimit.overdueTransactions' | translate }}
                                                ({{data.BlockAfterDays}} {{ 'contractorLimit.daysAfterPeriod' | translate }})
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="flex-box-column-content"></div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 420px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'formPayment.receivables' | translate }}
                                            </label>
                                            <dx-number-box width="276" [readOnly]="true" [value]="data.OverdueIn || 0" format="#0.00">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group-inline-right flex-box-column" style="width: 420px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'formPayment.obligations' | translate }}
                                            </label>
                                            <dx-number-box width="276" [readOnly]="true" [value]="data.OverdueOut || 0" format="#0.00">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 850px;">
                                <div class="form-box">
                                    <div class="box-title">
                                        <div class="c-frame-row" style="width: 100%;">
                                            <h5 style="margin-top: 5px; text-align: left; width: 100%">{{ 'contractorLimit.allUnsettled' | translate}}</h5>
                                        </div>
                                    </div>
                                    <div class="flex-box-column-content"></div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 420px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'formPayment.receivables' | translate }}
                                            </label>
                                            <dx-number-box width="276" [readOnly]="true" [value]="data.AllIn || 0" format="#0.00">
                                            </dx-number-box>
                                        </div>
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'contractorLimit.lastTransaction' | translate }}
                                            </label>
                                            <wapro-date-box width="276" [readOnly]="true" [ngModel]="data.LastTransaction" [displayFormat]="event.dateFormat">
                                            </wapro-date-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group-inline-right flex-box-column" style="width: 420px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'formPayment.obligations' | translate }}
                                            </label>
                                            <dx-number-box width="276" [readOnly]="true" [value]="data.AllOut || 0" format="#0.00">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 850px;">
                                <div class="form-box">
                                    <div class="box-title">
                                        <div class="c-frame-row" style="width: 100%;">
                                            <h5 style="margin-top: 5px; text-align: left; width: 100%">
                                                {{ 'contractorLimit.allWithoutCommercial' | translate }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="flex-box-column-content"></div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 900px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'contractorLimit.releaseAmount' | translate }}
                                            </label>
                                            <dx-number-box width="276" [readOnly]="true" [value]="data.NonInvoiced || 0" format="#0.00">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 850px;">
                                <div class="form-box">
                                    <div class="box-title">
                                        <div class="c-frame-row" style="width: 100%;">
                                            <h5 style="margin-top: 5px; text-align: left; width: 100%">
                                                {{ 'contractorLimit.unfilled' | translate }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="flex-box-column-content"></div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
                            <div class="form-group-inline-right flex-box-column" style="width: 900px;">
                                <div class="form-box">
                                    <div class="box-title">
                                    </div>
                                    <div class="flex-box-column-content">
                                        <div class="box-row">
                                            <label style="width: 120px;">
                                                {{ 'contractorLimit.positionValue' | translate }}
                                            </label>
                                            <dx-number-box width="276" [readOnly]="true" [value]="data.Unrealized || 0" format="#0.00">
                                            </dx-number-box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </dx-scroll-view>
    </div>
</dx-popup>

<wapro-keyboard-shortcuts [shortcuts]="shortcuts" [unicalGuid]="unicalGuid"></wapro-keyboard-shortcuts>
