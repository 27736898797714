import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { SmsType } from '../sms-offers.model';

@Component({
    selector: 'app-add-sms',
    templateUrl: './add-sms.component.html',
    styleUrls: ['./add-sms.component.scss'],
    inputs: ['isVisible', 'mode', 'CustomerId', 'readOnly', 'selected', 'type'],
    standalone: false
})
export class AddSmsComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @Output() onSetFocus = new EventEmitter();
  @ViewChild('inputName') inputName;
  @ViewChild('gridBank') gridBank;
  unicalGuid;
  selected;
  widthWindow = '90%';
  heightWindow = '90%';
  isVisible;
  title;
  mode;
  CustomerId;
  readOnly;
  type: SmsType = 'offers';
  itemTab = ['Dane wysyłki', 'Lista odbiorców SMS'];

  itemTabEdit = ['Dane wysyłki'];

  receivingPersonsList: any = [
    { value: 0, label: 'wszyscy z listy kontaktów' },
    { value: 1, label: 'pierwszy z listy kontaktów' },
    { value: 2, label: 'kontakty domyślne' },
  ];

  defaultValue: string = 'wszyscy z listy kontaktów';
  recivingPersonSelect = 0;
  heightGrid = 'calc(90vh - 265px)';
  dataSource;

  selectedRows = [];
  submitted: boolean = false;
  form;
  shortcuts: ShortcutInput[] = [];

  exportList = [
    { id: 0, text: 'Kontakt.Imie', disabled: false },
    { id: 1, text: 'Kontakt.Nazwisko', disabled: false },
    { id: 2, text: 'Kontakt.Tytul', disabled: false },
    { id: 3, text: 'Kontakt.Stanowisko', disabled: false },
    { id: 4, text: 'Kontrahent.Nazwa', disabled: false },
    { id: 5, text: 'Oferta.Numer', disabled: false },
    { id: 6, text: 'Oferta.Waluta', disabled: false },
    { id: 7, text: 'Oferta.KwotaNetto', disabled: false },
    { id: 8, text: 'Oferta.KwotaBrutto', disabled: false },
    { id: 9, text: 'Oferta.Status', disabled: false },
    { id: 10, text: 'Oferta.KomentarzStatusu', disabled: false },
    { id: 11, text: 'Oferta.DataWystawienia', disabled: false },
    { id: 12, text: 'Oferta.TerminRealizacji', disabled: false },
    { id: 13, text: 'Oferta.Uwagi', disabled: false },
  ];
  contextMenuDocument = [];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('offers.shippingDetails')
      .subscribe((text) => (this.itemTab[0] = text));
    this.translate
      .get('offers.shippingDetails')
      .subscribe((text) => (this.itemTabEdit[0] = text));
    this.translate
      .get('offers.SMSrecipientList')
      .subscribe((text) => (this.itemTab[1] = text));

    this.translate
      .get('offers.everyoneOnYourContactList')
      .subscribe((text) => (this.receivingPersonsList[0].label = text));
    this.translate
      .get('offers.firstFromTheContactList')
      .subscribe((text) => (this.receivingPersonsList[1].label = text));
    this.translate
      .get('offers.defaultContacts')
      .subscribe((text) => (this.receivingPersonsList[2].label = text));

    this.defaultValue = this.receivingPersonsList[0].label;
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.createForm();
  }

  ngOnChanges() {
    if (this.isVisible) {
      if (this.selected && this.mode == 'edit' && this.selected.length > 0) {
        setTimeout(() => {
          this.form.patchValue(this.selected[0]);
        }, 0);
      }
    }

    switch (this.type) {
      case 'offers':
        this.exportList = [
          { id: 0, text: 'Kontakt.Imie', disabled: false },
          { id: 1, text: 'Kontakt.Nazwisko', disabled: false },
          { id: 2, text: 'Kontakt.Tytul', disabled: false },
          { id: 3, text: 'Kontakt.Stanowisko', disabled: false },
          { id: 4, text: 'Kontrahent.Nazwa', disabled: false },
          { id: 5, text: 'Oferta.Numer', disabled: false },
          { id: 6, text: 'Oferta.Waluta', disabled: false },
          { id: 7, text: 'Oferta.KwotaNetto', disabled: false },
          { id: 8, text: 'Oferta.KwotaBrutto', disabled: false },
          { id: 9, text: 'Oferta.Status', disabled: false },
          { id: 10, text: 'Oferta.KomentarzStatusu', disabled: false },
          { id: 11, text: 'Oferta.DataWystawienia', disabled: false },
          { id: 12, text: 'Oferta.TerminRealizacji', disabled: false },
          { id: 13, text: 'Oferta.Uwagi', disabled: false },
        ];
        break;
      case 'orders':
        this.exportList = [
          { id: 0, text: 'Kontakt.Imie', disabled: false },
          { id: 1, text: 'Kontakt.Nazwisko', disabled: false },
          { id: 2, text: 'Kontakt.Tytul', disabled: false },
          { id: 3, text: 'Kontakt.Stanowisko', disabled: false },
          { id: 4, text: 'Kontrahent.Nazwa', disabled: false },
          { id: 5, text: 'Zamowienie.Numer', disabled: false },
          { id: 6, text: 'Zamowienie.Waluta', disabled: false },
          { id: 7, text: 'Zamowienie.KwotaNetto', disabled: false },
          { id: 8, text: 'Zamowienie.KwotaBrutto', disabled: false },
          { id: 9, text: 'Zamowienie.Status', disabled: false },
          { id: 10, text: 'Zamowienie.KomentarzStatusu', disabled: false },
          { id: 11, text: 'Zamowienie.NumerZamKlienta', disabled: false },
          { id: 12, text: 'Zamowienie.DataWystawienia', disabled: false },
          { id: 13, text: 'Zamowienie.TerminRealizacji', disabled: false },
          { id: 14, text: 'Zamowienie.InformacjeDodatkowe', disabled: false },
          { id: 15, text: 'Zamowienie.FormaDostawy', disabled: false },
          { id: 16, text: 'Zamowienie.Uwagi', disabled: false },
          { id: 17, text: 'Zamowienie.NrPrzesylki', disabled: false },
          { id: 18, text: 'Zamowienie.URLNrPrzesylki', disabled: false },
          { id: 19, text: 'Zamowienie.LinkDoPlatnosci', disabled: false },
        ];
        break;
      case 'services':
        this.exportList = [
          { id: 0, text: 'Kontakt.Imie', disabled: false },
          { id: 1, text: 'Kontakt.Nazwisko', disabled: false },
          { id: 2, text: 'Kontakt.Tytul', disabled: false },
          { id: 3, text: 'Kontakt.Stanowisko', disabled: false },
          { id: 4, text: 'Kontrahent.Nazwa', disabled: false },
          { id: 5, text: 'Zlecenie.Numer', disabled: false },
          { id: 6, text: 'Zlecenie.DataWystawienia', disabled: false },
          { id: 7, text: 'Zlecenie.Status', disabled: false },
          { id: 8, text: 'Zlecenie.KomentarzStatusu', disabled: false },
          { id: 9, text: 'Urzadzenie.NrSeryjny', disabled: false },
          { id: 10, text: 'Urzadzenie.NrFabryczny', disabled: false },
        ];
        break;
      case 'customers':
        this.exportList = [
          { id: 0, text: 'Kontakt.Imie', disabled: false },
          { id: 1, text: 'Kontakt.Nazwisko', disabled: false },
          { id: 2, text: 'Kontakt.Tytul', disabled: false },
          { id: 3, text: 'Kontakt.Stanowisko', disabled: false },
          { id: 4, text: 'Kontrahent.Nazwa', disabled: false },
        ];
        break;
      case 'finances':
        this.exportList = [
          { id: 0, text: 'Kontakt.Imie', disabled: false },
          { id: 1, text: 'Kontakt.Nazwisko', disabled: false },
          { id: 2, text: 'Kontakt.Tytul', disabled: false },
          { id: 3, text: 'Kontakt.Stanowisko', disabled: false },
          { id: 4, text: 'Kontrahent.Nazwa', disabled: false },
          { id: 5, text: 'Rozrachunek.Numer', disabled: false },
          { id: 6, text: 'Rozrachunek.Waluta', disabled: false },
          { id: 7, text: 'Rozrachunek.Pozostalo', disabled: false },
        ];
    }
  }

  ngAfterViewInit() {
    this.contextMenuDocument = [
      {
        text: 'Zaznacz wszystko',
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 0,
        disabled: this.dataSource?.length == 0,
      },
      {
        text: 'Odznacz wszystko',
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 1,
        disabled: this.dataSource?.length == 0,
      },
    ];

    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  contextMenuClick(e: any) {
    switch (e.itemIndex) {
      case 0:
        this.selectAll();
        break;
      case 1:
        this.deselectAll();
        break;
    }
  }
  onChoosedTrackingUrl(e) {
    if (e) {
      this.form.controls['TrackingUrlId'].setValue(e.TrackingUrlId);
    }
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      ContextName: '',
      Name: ['', Validators.required],
      Message: ['', Validators.required],
      TemplateId: 0,
      TrackingUrlId: 0,
      IsPersonalized: false,
      DateAndTime: new Date(),
    });
  };

  onValueChangedReceivingPersons = (e) => {
    this.recivingPersonSelect = e;
    switch (this.recivingPersonSelect) {
      case 0:
        this.getContactList('ALL');
        break;
      case 1:
        this.getContactList('FIRST');
        break;
      case 2:
        this.getContactList('DEFAULT');
        break;
      default:
        this.getContactList('ALL');
    }
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values

    if (!e) {
      this.submitted = false;
      this.onClosing.emit(false);
      this.firstTabClickToIndex1 = true;
    }

    if (e) {
      this.getContactList('ALL');
    }
  };

  onItemClickExport(e) {
    switch (e.itemData.id) {
      case 0:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[0].text}}`
        );
        break;
      case 1:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[1].text}}`
        );
        break;
      case 2:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[2].text}}`
        );
        break;
      case 3:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[3].text}}`
        );
        break;
      case 4:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[4].text}}`
        );
        break;
      case 5:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[5].text}}`
        );
        break;
      case 6:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[6].text}}`
        );
        break;
      case 7:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[7].text}}`
        );
        break;
      case 8:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[8].text}}`
        );
        break;
      case 9:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[9].text}}`
        );
        break;
      case 10:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[10].text}}`
        );
        break;
      case 11:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[11].text}}`
        );
        break;
      case 12:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[12].text}}`
        );
        break;
      case 13:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[13].text}}`
        );
        break;
      case 14:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[14].text}}`
        );
        break;
      case 15:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[15].text}}`
        );
        break;
      case 16:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[16].text}}`
        );
        break;
      case 17:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[17].text}}`
        );
        break;
      case 18:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[18].text}}`
        );
        break;
      case 19:
        this.form.controls['Message'].setValue(
          this.form.value.Message + ` {${this.exportList[19].text}}`
        );
        break;
    }
  }

  // setRecived = (value: string) => {

  // if (this.recivingPersonSelect == 0) {
  //   this.selectedRows = this.dataSource;
  // }
  // if (this.recivingPersonSelect == 1 && this.dataSource.length > 0) {
  //   this.selectedRows = [this.dataSource[0]];
  // }
  // if (this.recivingPersonSelect == 2) {
  //   this.selectedRows = [];
  //   this.dataSource.forEach((element) => {
  //     if (element.IsDefault) {
  //       this.selectedRows = [element];
  //     }
  //   });
  // }
  // };

  getContactList = (value: string) => {
    const listContentFilter = `?ListContent=${value}`;

    this.appService
      .getAuth(
        `${this.type}/additionalOperations/smsSenderMessage/contacts${listContentFilter}`
      )
      .subscribe(
        (res) => {
          this.dataSource = res.data;
          if (
            this.firstTabClickToIndex1 &&
            this.dataSource.length > 0 &&
            this.selectedTabIndex == 1
          ) {
            setTimeout(() => {
              this.selectAll();
              this.firstTabClickToIndex1 = false;
            });
          }
          this.cd.detectChanges();
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );

    // let listCustomer: any[] = [];
    //
    // let forkArray = [];
    // this.CustomerId.forEach((field) => {
    //   let offer = this.appService.getAuth(
    //     `${this.type}/documents/${field.OfferDocumentId}`
    //   );
    //   forkArray.push(offer);
    // });
    //
    // forkJoin(forkArray).subscribe((res) => {
    //   res.forEach((element: any) => {
    //     let isExist = listCustomer.findIndex(
    //       (field: any) => field.CustomerId == element.CustomerId
    //     );
    //     if (isExist == -1) {
    //       listCustomer.push(element);
    //     }
    //   });
    //
    //   this.dataSource = [];
    //   listCustomer.forEach((element) => {
    //     this.appService
    //       .getAuth(`customers/${element.CustomerId}/contacts`)
    //       .subscribe((res) => {
    //         res.data.forEach((contact) => {
    //           contact.CustomerName = element.CustomerName;
    //           this.dataSource.push(contact);
    //         });
    //
    //         this.setRecived();
    //       });
    //   });
    // });
  };

  replacePolishChar = () => {
    let msg = this.form.value.Message.replace(/Ą/g, 'A');
    msg = msg.replace(/ą/g, 'a');
    msg = msg.replace(/ę/g, 'e');
    msg = msg.replace(/Ę/g, 'E');
    msg = msg.replace(/ó/g, 'o');
    msg = msg.replace(/Ó/g, 'O');
    msg = msg.replace(/ś/g, 's');
    msg = msg.replace(/Ś/g, 'S');
    msg = msg.replace(/ł/g, 'l');
    msg = msg.replace(/Ł/g, 'L');
    msg = msg.replace(/Ż/g, 'Z');
    msg = msg.replace(/ż/g, 'z');
    msg = msg.replace(/Ź/g, 'Z');
    msg = msg.replace(/ź/g, 'z');
    msg = msg.replace(/ć/g, 'c');
    msg = msg.replace(/Ć/g, 'C');
    msg = msg.replace(/ń/g, 'n');
    msg = msg.replace(/Ń/g, 'N');

    this.form.controls.Message.setValue(msg);
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid) {
      if (this.mode == 'add') {
        if (this.selectedContactId.length === 0) {
          this.event.showNotification(
            'warning',
            this.translate.instant('SelectSMSrecipients')
          );
          return;
        }
        this.addMsg();
      }
      if (this.mode == 'edit') {
        this.editMsg();
      }
    } else {
      this.selectedTabIndex = 0;
    }
  };
  selectedTabIndex = 0;
  addMsg = () => {
    // let forkArray = [];
    this.appService
      .postAuth(
        `${this.type}/additionalOperations/smsSenderMessage`,
        this.form.value
      )
      .subscribe(
        (res) => {
          this.onSetFocus.emit(res.Id);

          if (this.selectedContactId.length > 0) {
            this.appService
              .postAuth(
                `${this.type}/additionalOperations/smsSenderMessage/${res.Id}/contacts`,
                { ObjectIds: this.selectedContactId }
              )
              .subscribe(
                () => {
                  this.onInserted.emit(this.form.value);
                  this.submitted = false;
                  this.isVisible = false;
                  this.onClosing.emit(false);
                  this.selectedContactId = [];
                  this.deselectAll();
                  this.selectedTabIndex = 0;
                  // this.appService.postAuth(
                  //   `${this.type}/additionalOperations/smsSenderMessage/${res.Id}/contacts/delete`,
                  //   {ObjectIds: this.selectedContactId})
                  //   .subscribe(res => {
                  //       this.selectedContactId = [];
                  //     }
                  //   );
                },
                (error) => {
                  this.event.httpErrorNotification(error);
                }
              );
          } else {
            this.isVisible = false;
            this.onClosing.emit(false);
            this.onInserted.emit(this.form.value);
            this.submitted = false;
          }
          // this.selectedRows.forEach((field) => {
          //   let contact = this.appService.postAuth(
          //     `${this.type}/additionalOperations/smsSenderMessage/${res.Id}/contacts`,
          //     field.ContactId
          //   );
          //   forkArray.push(contact);
          // });
          //
          // if (!forkArray.length) {
          //   this.onInserted.emit(this.form.value);
          //   this.submitted = false;
          // }
          //
          // forkJoin(forkArray).subscribe((res) => {
          //   this.onInserted.emit(this.form.value);
          //   this.submitted = false;
          // });
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  editMsg = () => {
    this.form.value.SmsSenderMessageId = this.selected[0].SmsSenderMessageId;
    this.appService
      .putAuth(
        `${this.type}/additionalOperations/smsSenderMessage/${this.selected[0].SmsSenderMessageId}`,
        this.form.value
      )
      .subscribe(
        () => {
          this.submitted = false;
          this.onInserted.emit(this.form.value);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  toSelect = [];
  toDeselect = [];
  sendRequestTimer;
  selectedContactId = [];

  onSelectionChanged(e) {
    // Zapisz zaznaczone rekordy do zmiennej toSelect
    this.selectedContactId = e.selectedRowKeys.map((row) => row.ContactId);

    // Usuń odznaczone rekordy z selectedContactId
    e.currentDeselectedRowKeys.forEach((deselectedRow) => {
      const index = this.selectedContactId.indexOf(deselectedRow.ContactId);
      if (index > -1) {
        this.selectedContactId.splice(index, 1);
      }
    });

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (this.dataSource.length) {
      // this.setSelectionTimeout();
    }
  }

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let toSelect = [];

        this.selectedRows.forEach((field) => {
          if (typeof field == 'object') {
            toSelect.push(field.ContactId);
          } else {
            toSelect.push(field);
          }
        });

        this.toSelect = [];
      }
      if (this.toDeselect.length) {
        let toDeselect = [];
        this.toDeselect.forEach((field) => {
          if (typeof field == 'object') {
            toDeselect.push(field.ContactId);
          } else {
            toDeselect.push(field);
          }
        });
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of toDeselect) {
          const item = items.find((el) => el.ContactId === toDes);
          if (!item) {
            toRemove.push(toDeselect.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          toDeselect.splice(toRemove[i], 1);
        }

        toDeselect = [];
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  private selectAll() {
    this.gridBank.instance.selectAll();
  }

  private deselectAll() {
    this.gridBank.instance.deselectAll();
  }

  firstTabClickToIndex1 = true;

  tabSelectionChanged() {
    if (
      this.selectedTabIndex == 1 &&
      this.firstTabClickToIndex1 &&
      this.dataSource.length > 0
    ) {
      this.getContactList('ALL');
    }
  }
}
