<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'crmDeliveries.sendReturnAddress' | translate }}"
  [visible]="isVisible"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (visibleChange)="visibleChange($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%"
                    height="100%"
                    direction="vertical">
      <form [formGroup]="form">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right"
               style="width: 600px">
            <label style="width: 120px"
            >{{ "delivery.addressType" | translate }}
            </label>
            <dx-radio-group
              [items]="typeItems"
              layout="horizontal"
              formControlName="IsReturnType"
              [readOnly]="true"
              displayExpr="label"
              valueExpr="value"
            ></dx-radio-group>
            <br/>
            <label style="width: 120px"
            >{{ "delivery.compnayName" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              class="no-max-width"
              width="460"
              [readOnly]="readOnly"
              formControlName="CompanyName"
              [ngClass]="{
                'input-required': !form.controls.CompanyName.valid && submitted
              }"
            >
            </dx-text-box>
            <br/>
            <label style="width: 120px">{{
                ("users.grid.columns.firstName" | translate) +
                " " +
                ("users.grid.columns.lastName" | translate)
              }}</label>
            <dx-text-box
              class="no-max-width"
              width="460"
              [readOnly]="readOnly"
              formControlName="Name"
            ></dx-text-box>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right"
               style="width: 299px">
            <label style="width: 120px">{{
                "bankAccounts.grid.columns.country" | translate
              }}</label>
            <app-country-box
              [advancedChooser]="true"
              [selectedCountry]="currentCountryCode"
              style="width: 160px; margin-bottom: 0px"
              (onChoosed)="selectedCountryChange($event)"
            >
            </app-country-box>
          </div>
          <div class="form-group-inline-right"
               style="width: 299px">
            <label style="width: 120px"
            >{{ "companies.form.postalCode" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              class="no-max-width"
              width="160"
              [readOnly]="readOnly"
              formControlName="PostalCode"
              [ngClass]="{
                'input-required': !form.controls.PostalCode.valid && submitted
              }"
            >
            </dx-text-box>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right"
               style="width: 600px">
            <label style="width: 120px"
            >{{ "companies.grid.columns.city" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              class="no-max-width"
              width="460"
              [readOnly]="readOnly"
              formControlName="City"
            ></dx-text-box>
            <br/>
            <label style="width: 120px"
            >{{ "companies.grid.columns.street" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              class="no-max-width"
              width="460"
              [readOnly]="readOnly"
              formControlName="Street"
              [ngClass]="{
                'input-required': !form.controls.Street.valid && submitted
              }"
            ></dx-text-box>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right"
               style="width: 299px">
            <label style="width: 120px">Nr <strong>*</strong></label>
            <dx-text-box
              class="no-max-width"
              width="160"
              [readOnly]="readOnly"
              formControlName="BuildingNumer"
              [ngClass]="{
                'input-required':
                  !form.controls.BuildingNumer.valid && submitted
              }"
            >
            </dx-text-box>
          </div>
          <div class="form-group-inline-right"
               style="width: 299px">
            <label style="width: 120px">{{
                "companies.grid.columns.local" | translate
              }}</label>
            <dx-text-box
              class="no-max-width"
              width="160"
              [readOnly]="readOnly"
              formControlName="LocalNumber"
            >
            </dx-text-box>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right"
               style="width: 600px">
            <label style="width: 120px">{{
                "singleBank.form.email" | translate
              }}</label>
            <dx-text-box
              class="no-max-width"
              width="460"
              [readOnly]="readOnly"
              formControlName="Email"
            ></dx-text-box>
            <br/>
            <label style="width: 120px"
            >{{ "singleBank.form.phone" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              class="no-max-width"
              width="460"
              [readOnly]="readOnly"
              formControlName="Phone"
              [ngClass]="{
                'input-required': !form.controls.Phone.valid && submitted
              }"
            ></dx-text-box>
            <br/>
            <label style="width: 120px"> </label>
            <dx-button
              height="28"
              class="btn-extra"
              (onClick)="getCompanyAddress()"
              [disabled]="readOnly"
              text="{{ 'deliveries.copyFromCompanyAddress' | translate }}"
            ></dx-button>

            <div class="c-frame-row"
                 style="width: 100%">
              <h5>{{ "userExtensions.defaultValue" | translate }}</h5>
            </div>
            <label style="width: 120px"> </label>
            <dx-radio-group
              [items]="defaultItems"
              layout="horizontal"
              [(value)]="defaultValue"
              (onValueChanged)="onDefaultValueChanged($event)"
              [readOnly]="readOnly"
              displayExpr="label"
              valueExpr="value"
            ></dx-radio-group>
            <ng-container *ngIf="defaultValue === 2">
              <label style="width: 120px">{{
                  "menu.warehouse" | translate
                }}</label>
              <app-warehouse-list
                [dropDownBoxMode]="true"
                [selectedId]="form.get('WarehouseId').value"
                (onChoosed)="onWarehouseChoosed($event)"
                width="460"
                [readOnly]="readOnly"
              ></app-warehouse-list>
            </ng-container>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>
<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
