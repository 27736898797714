import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';

@Component({
    selector: 'app-add-sms-tracking-url',
    imports: [
        CommonModule,
        TranslateModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxTabPanelModule,
        DxScrollViewModule,
        DxTextBoxModule,
        DxTextAreaModule,
        ReactiveFormsModule,
    ],
    templateUrl: './add-sms-tracking-url.component.html',
    styleUrl: './add-sms-tracking-url.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['isVisible', 'readOnly', 'mode', 'selectedId']
})
export class AddSmsTrackingUrlComponent implements OnInit, OnChanges {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  form;
  readOnly: boolean = false;
  widthWindow: number = 500;
  heightWindow: number = 360;
  title: string = '';
  isVisible;
  boolean = false;
  submitted: boolean = false;
  unicalGuid;
  selectedtab: number = 0;
  mode;
  selectedId;

  panelTabs: string[] = ['Dane podstawowe', 'Opis'];

  constructor(
    public event: EventService,
    public appServices: AppServices,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (this.readOnly) this.readOnly = this.event.readOnly;

    this.translate
      .get('companies.tabs.basicInfo')
      .subscribe((text) => (this.panelTabs[0] = text));
    this.translate
      .get('form-financial-document.description')
      .subscribe((text) => (this.panelTabs[1] = text));
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible && this.mode === 'add') {
      this.title = 'form.mode.add';
    }

    if (changes.isVisible && this.mode === 'edit') {
      this.title = 'form.mode.edit';
      setTimeout(() => {
        this.form.patchValue(this.selectedId);
      }, 0);
    }
  }

  createForm() {
    this.form = this.fb.group({
      TrackingUrlId: 0,
      CompanyId: [this.event.footerInfo.AppCompanyId, Validators.required],
      Name: ['', Validators.required],
      Description: [''],
      WebAdres: ['', Validators.required],
      TrackingKey: [''],
      DateAndTime: [null],
    });
  }

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;
    this.selectedtab = 0;

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onSave() {
    this.submitted = true;
    if (this.form.invalid) return;

    this.appServices
      .postAuth(
        `customers/additionalOperations/smsSenderMessage/smsTrackingUrl/${this.form.value.TrackingUrlId}`,
        this.form.value
      )
      .subscribe(
        (res) => {
          this.onInserted.emit(res);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }
}
