<dx-popup
  [width]="430"
  [height]="225"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'articles.changeVat' | translate }}"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="saveForm()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="onClosed.emit()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
      *ngIf="form"
    >
      <form [formGroup]="form">
        <div class="d-flex justify-content-center flex-wrap">
          <div class="form-group-inline-right" style="width: 410px">
            <label style="width: 120px">{{
              "articles.fromVat" | translate
            }}</label>
            <app-tax-rates
              [dropDownBoxMode]="true"
              [width]="276"
              [taxCode]="f.VatIn.value"
              (onChoosed)="onTaxRateFromChanged($event)"
            >
            </app-tax-rates>
            <br />
            <label style="width: 120px">{{
              "articles.toVat" | translate
            }}</label>
            <app-tax-rates
              [dropDownBoxMode]="true"
              [width]="276"
              [taxCode]="f.VatOut.value"
              (onChoosed)="onTaxRateToChanged($event)"
            >
            </app-tax-rates>
            <br />
            <label style="width: 120px">{{
              "articles.recalculateSellBuyPrices" | translate
            }}</label>
            <dx-radio-group
              [items]="priceItems"
              layout="horizontal"
              formControlName="BruttoNetto"
              displayExpr="label"
              valueExpr="value"
            >
            </dx-radio-group>
            <br />
            <label style="width: 395px; text-align: center"
              ><strong>
                {{ "articles.appliedToAll" | translate }}
              </strong></label
            >
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>
