<dx-popup
  [width]="400"
  [height]="150"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp(); event.setFocus(codeInput)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="onChoose()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <div>
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 100%">
          <label style="width: 80px" for="">{{
            "reader.enterCode" | translate
          }}</label>
          <dx-text-box
            width="260"
            [(value)]="value"
            [readOnly]="readOnly"
            #codeInput
            valueChangeEvent="input"
            (onKeyDown)="onKeyDown($event)"
          ></dx-text-box>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [disabled]="!isVisible"
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
