<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, 'data-cy': 'add-cash-boxes-form' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="save-cash-boxes-btn"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
        data-cy="cancel-cash-boxes-btn"
      ></dx-button>

      <dx-tooltip
        *ngIf="event.deviceType != 'mobile'"
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        *ngIf="event.deviceType != 'mobile'"
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 428px">
          <form [formGroup]="form">
            <div class="header-bar d-flex" style="margin-bottom: 24px">
              <div class="confirme-info-message"></div>
              <div
                class="header-bar_text"
                style="padding: 4px 0"
                [innerHTML]="'defaultCashRegister' | translate"
              ></div>
            </div>
            <label style="width: 90px"
              >{{ "warehouse.symbol" | translate }} <strong>*</strong></label
            >
            <dx-text-box
              width="80"
              [readOnly]="readOnly"
              formControlName="Symbol"
              [ngClass]="{
                'input-required': !form.controls.Symbol.valid && submitted
              }"
              (onFocusIn)="event.onFocusCountry('Symbol', form)"
              [maxLength]="5"
              (onFocusOut)="event.onFocusOut('Symbol', form)"
              valueChangeEvent="input"
              data-cy="cash-boxes-symbol-input"
              #focusFirst
            >
            </dx-text-box
            ><br />

            <small
              class="text-danger"
              *ngIf="
                submitted &&
                form.get('Symbol').focus &&
                !form.get('Symbol').valid
              "
            >
              {{ "form.required" | translate }}
            </small>

            <label style="width: 90px"
              >{{ "cash-boxes.nameCash" | translate }} <strong>*</strong></label
            >
            <dx-text-box
              width="276"
              [readOnly]="readOnly"
              formControlName="Name"
              [ngClass]="{
                'input-required': !form.controls.Name.valid && submitted
              }"
              (onFocusIn)="event.onFocusCountry('Name', form)"
              (onFocusOut)="event.onFocusOut('Name', form)"
              valueChangeEvent="input"
              data-cy="cash-boxes-name-input"
            >
            </dx-text-box>

            <small
              class="text-danger"
              *ngIf="
                submitted && form.get('Name').focus && !form.get('Name').valid
              "
            >
              {{ "form.required" | translate }}
            </small>

            <label style="width: 90px">{{
              "constractors.accounFK" | translate
            }}</label>
            <dx-text-box
              [readOnly]="readOnly"
              formControlName="FKAccount"
              width="276"
              valueChangeEvent="input"
            >
            </dx-text-box>

            <dx-check-box
              text="{{
                'cash-boxes.cashReportBrokenDownByCurrency' | translate
              }}"
              [ngClass]="{ noMargin: event.deviceType == 'mobile' }"
              style="width: calc(100% - 110px); margin-left: 102px"
              formControlName="IsCurrencyReport"
              valueExpr="value"
              [readOnly]="readOnly"
            ></dx-check-box>

            <dx-check-box
              data-cy="archive-cash-boxes-checkbox"
              text="{{ 'cash-boxes.archivalCash' | translate }}"
              [ngClass]="{ noMargin: event.deviceType == 'mobile' }"
              style="width: calc(100% - 110px); margin-left: 102px"
              formControlName="IsArchive"
              valueExpr="value"
              [readOnly]="readOnly"
            ></dx-check-box>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
