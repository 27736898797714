<div *ngIf="!dropDownBoxMode; else dropDownMode">
    <div class="top-menu-panel">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
        <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
            <ng-container *ngTemplateOutlet="grid"></ng-container>
        </div>
    </div>
</div>

<ng-template #dropDownMode>
    <dx-drop-down-box
            [(value)]="choosingDocument"
            valueExpr="ServiceGroupId"
            [deferRendering]="false"
            displayExpr="Name"
            [(opened)]="isGridBoxOpened"
            [dataSource]="documentList"
            [showClearButton]="true"
            style="width: 276px"
            (onValueChanged)="onValueChanged($event)"
            (openedChange)="onOpenedChanged($event)"
            (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
            [readOnly]="readOnly"
            #focusFirst
    >
        <div
                *dxTemplate="let data of 'content'"
                style="height: 280px; min-height: 280px"
        >
            <div class="row">
                <div class="col-md-12"
                     class="no-padding"
                     *ngIf="isGridBoxOpened">
                    <dx-data-grid
                            [dataSource]="dataSource"
                            [wordWrapEnabled]="true"
                            [showBorders]="true"
                            shortcut
                            [height]="258"
                            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
                            [columnResizingMode]="'widget'"
                            [remoteOperations]="true"
                            [focusedRowIndex]="focusedRowIndex"
                            (onRowClick)="onRowDblClick()"
                            [columnAutoWidth]="true"
                            [allowColumnResizing]="true"
                            (onFocusedRowChanged)="onFocusedRowChanged($event)"
                            [id]="'grid' + unicalGuid"
                            style="max-width: 100% !important"
                            (onSelectionChanged)="onSelectionChanged($event)"
                            [focusedRowEnabled]="true"
                            #gridClass
                            [hoverStateEnabled]="true"
                    >
                        <dxo-load-panel [enabled]="false"></dxo-load-panel>
                        <dxo-scrolling mode="infinite"></dxo-scrolling>
                        <dxo-paging [pageSize]="50"></dxo-paging>
                        <dxo-selection
                                [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                                showCheckBoxesMode="always"
                        >
                        </dxo-selection>
                        <dxo-editing mode="row"
                                     [confirmDelete]="false"></dxo-editing>
                        <dxi-column
                                [width]="event.deviceType != 'mobile' ? 45 : 10"
                                cellTemplate="editTemplate"
                                [allowSorting]="false"
                                alignment="center"
                        >
                        </dxi-column>

                        <div *dxTemplate="let data of 'editTemplate'"
                             tabindex="-1">
                            <dx-button
                                    class="btn-dx-top-menu-folder-grid"
                                    icon="icon absui-icon--mode-edit hover-edit-icon"
                                    [disabled]="readOnly || !this.perABD.editBtn"
                                    (onClick)="setSingleRecordVisible('edit')"
                            >
                            </dx-button>
                        </div>
                        <dxi-column
                                [caption]="'constractors.default' | translate"
                                width="70"
                                dataField="IsDefault"
                                [allowSorting]="false"
                                cellTemplate="IsDefault"
                        >
                        </dxi-column>
                        <dxi-column
                                caption="{{ 'companies.grid.columns.name' | translate }}"
                                [width]="widthCityColumn"
                                dataField="Name"
                                [allowSorting]="false"
                        ></dxi-column>

                        <div *dxTemplate="let data of 'IsDefault'">
                            <i
                                    class="icon absui-icon--status-ok-green"
                                    *ngIf="data.data.IsDefault"
                            ></i>
                        </div>
                    </dx-data-grid>
                    <dx-context-menu
                            [dataSource]="contextMenu"
                            [width]="200"
                            [target]="'#grid' + unicalGuid"
                            (onItemClick)="contextMenuClick($event)"
                            (onItemRendered)="event.onItemRendered($event)"
                    >
                    </dx-context-menu>
                </div>
            </div>

            <div class="bottom-drop-box">
                <dx-button
                        [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
                        icon="icon absui-icon--add-circle"
                        [text]="'buttons.add' | translate"
                        [id]="'btn-drop-add' + unicalGuid"
                        [disabled]="readOnly || !this.perABD.addBtn"
                        (onClick)="setSingleRecordVisible('add')"
                >
                </dx-button>
                <dx-tooltip
                        [target]="'#btn-drop-add' + unicalGuid"
                        [showEvent]="event.tooltipShowEvent"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false"
                >
                    <div *dxTemplate="let data of 'content'">
                        {{ "buttons.add" | translate }}
                    </div>
                </dx-tooltip>
            </div>
        </div>
    </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
    <dx-scroll-view
            width="95%"
            height="100%"
            direction="horizontal"
            showScrollbar="never"
            *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
    >
        <dx-button
                data-cy="services-groups_add-button"
                class="btn-dx-top-menu-folder"
                icon="icon absui-icon--add-circle"
                [id]="'btn-add' + unicalGuid"
                [text]="'buttons.add' | translate"
                [disabled]="readOnly || !perABD.addBtn"
                (onClick)="setSingleRecordVisible('add')"
        >
        </dx-button>
        <dx-button
                data-cy="services-groups_edit-button"
                class="btn-dx-top-menu-folder"
                [id]="'btn-edit' + unicalGuid"
                icon="icon absui-icon--mode-edit"
                [text]="'buttons.edit' | translate"
                [disabled]="selectedRow.length == 0 || readOnly || !perABD.editBtn"
                (onClick)="setSingleRecordVisible('edit')"
        >
        </dx-button>
        <dx-button
                data-cy="services-groups_delete-button"
                class="btn-dx-top-menu-folder"
                icon="icon absui-icon--highlight-off"
                [text]="'buttons.delete' | translate"
                [id]="'btn-delete' + unicalGuid"
                [disabled]="selectedRow.length == 0 || readOnly || !perABD.deleteBtn"
                (onClick)="isDelete()"
        >
        </dx-button>
        <dx-button
                class="btn-dx-top-menu"
                icon="icon absui-icon--tool-select-rectangle"
                [text]="'buttons.choose' | translate"
                [disabled]="selectedRow.length == 0 || readOnly"
                [id]="'btn-choose' + unicalGuid"
                (onClick)="onChoosingRow()"
                *ngIf="dropDownBoxMode"
        >
        </dx-button>

        <dx-tooltip
                [target]="'#btn-add' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
        >
            <div *dxTemplate="let data of 'content'">
                {{ "buttons.add" | translate }} (Insert)
            </div>
        </dx-tooltip>

        <dx-tooltip
                [target]="'#btn-edit' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
        >
            <div *dxTemplate="let data of 'content'">
                {{ "buttons.edit" | translate }} (F2)
            </div>
        </dx-tooltip>

        <dx-tooltip
                [target]="'#btn-delete' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
        >
            <div *dxTemplate="let data of 'content'">
                {{ "buttons.delete" | translate }} (Del)
            </div>
        </dx-tooltip>

        <dx-tooltip
                [target]="'#btn-choose' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
        >
            <div *dxTemplate="let data of 'content'">
                {{ "buttons.choose" | translate }} (Enter)
            </div>
        </dx-tooltip>
    </dx-scroll-view>
</ng-template>

<ng-template #grid>
    <div class="row">
        <div class="col-md-12">
            <dx-data-grid
                    id="gridContainer"
                    data-cy="services-groups_grid"
                    #gridClass
                    [dataSource]="dataSource"
                    [remoteOperations]="true"
                    [wordWrapEnabled]="true"
                    [showBorders]="true"
                    shortcut
                    [height]="heightGrid"
                    [(selectedRowKeys)]="selectedRow"
                    (onSelectionChanged)="onSelectionChanged($event)"
                    [focusedRowIndex]="focusedRowIndex"
                    [focusedRowEnabled]="true"
                    (onRowDblClick)="onRowDblClick()"
                    [id]="'grid' + unicalGuid"
                    (onFocusedRowChanged)="onFocusedRowChanged($event)"
            >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling mode="infinite"></dxo-scrolling>
                <dxo-paging [pageSize]="200"></dxo-paging>
                <dxo-selection mode="single"></dxo-selection>
                <dxo-editing mode="row"
                             [confirmDelete]="false"></dxo-editing>
                <dxi-column
                        [caption]="'constractors.default' | translate"
                        width="70"
                        dataField="IsDefault"
                        [allowSorting]="false"
                        cellTemplate="IsDefault"
                >
                </dxi-column>
                <dxi-column
                        caption="{{ 'companies.grid.columns.name' | translate }}"
                        [width]="widthCityColumn"
                        dataField="Name"
                        [allowSorting]="false"
                        cellTemplate="mainColumn"
                ></dxi-column>

                <div *dxTemplate="let data of 'mainColumn'">
          <span class="hov-underline"
                (click)="editRecord(data.data)">{{
            data.value
              }}</span>
                </div>

                <div *dxTemplate="let data of 'IsDefault'">
                    <i
                            class="icon absui-icon--status-ok-green"
                            *ngIf="data.data.IsDefault"
                    ></i>
                </div>
            </dx-data-grid>

            <dx-context-menu
                    [dataSource]="contextMenu"
                    [width]="200"
                    [target]="'#grid' + unicalGuid"
                    (onItemClick)="contextMenuClick($event)"
                    (onItemRendered)="event.onItemRendered($event)"
            >
            </dx-context-menu>
        </div>
    </div>
</ng-template>

<wapro-keyboard-shortcuts
        [shortcuts]="shortcuts"
        [unicalGuid]="unicalGuid"
        [disabled]="
    (!isGridBoxOpened && dropDownBoxMode) ||
    isSingleRecordVisible ||
    isDeleteRow
  "
>
</wapro-keyboard-shortcuts>

<app-add-services-group
        [isVisible]="isSingleRecordVisible"
        [mode]="singleRecordMode"
        (onClosing)="onClosingForm()"
        (onInserted)="onInserted($event)"
        title="{{ 'user-groups.addGroup' | translate }}"
        [selectedId]="focusedSelection"
>
</app-add-services-group>

<app-confirm-dialog
        [isVisible]="isDeleteRow"
        (onRemoving)="delete()"
        (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<ng-template #mobileButtonsTemplate>
    <div style="position: relative">
        <dx-button
                class="btn-dx-top-menu-folder"
                icon="icon absui-icon--add-circle"
                [id]="'btn-add' + unicalGuid"
                [text]="'buttons.add' | translate"
                [disabled]="readOnly || !perABD.addBtn"
                (onClick)="setSingleRecordVisible('add')"
        >
        </dx-button>
    </div>
</ng-template>

<!-- mobile template -->
<ng-template #mobileTemplate>
    <dx-data-grid
            id="gridContainer"
            #gridClass
            [dataSource]="dataSource"
            [remoteOperations]="true"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="heightGrid - 60"
            [(selectedRowKeys)]="selectedRow"
            (onSelectionChanged)="onSelectionChanged($event)"
            [focusedRowEnabled]="true"
            (onRowDblClick)="onRowDblClick()"
            [id]="'grid' + unicalGuid"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            (onContentReady)="onMobileGridContentReady($event)"
            class="main-mobile-grid"
    >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="200"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing mode="row"
                     [confirmDelete]="false"></dxo-editing>

        <dxi-column
                caption="{{ 'constractors.contractorName' | translate }}"
                [allowSorting]="false"
                dataField="Name"
                cellTemplate="mainColumn"
                [width]="mobileColWidth.name"
        >
        </dxi-column>

        <dxi-column
                [caption]="'constractors.default' | translate"
                [width]="mobileColWidth.def"
                dataField="IsDefault"
                [allowSorting]="false"
                cellTemplate="IsDefault"
                [allowResizing]="false"
        >
        </dxi-column>
        <dxi-column
                [caption]=""
                [width]="mobileColWidth.dots"
                [allowSorting]="false"
                cellTemplate="dots"
                [allowResizing]="false"
                alignment="left"
        >
        </dxi-column>

        <div *dxTemplate="let data of 'mainColumn'">
            <div style="display: flex">
                <div class="content-main-grid">
          <span class="hov-underline-mobile"
                (click)="editRecord(data.data)">{{
            data.data.Name
              }}</span
          ><br/>
                </div>
            </div>
        </div>

        <div *dxTemplate="let data of 'IsDefault'">
            <i
                    class="icon absui-icon--status-ok-green"
                    *ngIf="data.data.IsDefault"
            ></i>
        </div>

        <div *dxTemplate="let data of 'dots'">
            <dx-button
                    class="btn-dx-top-menu-folder"
                    icon="icon absui-icon--more-horiz"
                    style="float: left; position: relative; top: calc(50% - 13px)"
                    (onClick)="isContextMenuMobile = true"
            ></dx-button>
        </div>
    </dx-data-grid>

    <app-mobile-list
            [dataSource]="contextMenu"
            [visible]="isContextMenuMobile"
            (onClosed)="isContextMenuMobile = false"
            (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>
</ng-template>
