import { EventService } from 'src/app/event.service';
import { CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class NestDeactivateGuard implements CanDeactivate<any> {
  constructor(private event: EventService) {}

  canDeactivate(
    target: any,
    _currRoute: any,
    _currState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (!this.event.isVersionForNestAvailable) return true;

    if (target?.closeNest) {
      target.nestService.runGlobalNest({
        nest: target.closeNest,
        nextUrl: nextState.url,
      });
      return false;
    }
    return true;
  }
}
