import { Component, Input } from '@angular/core';
import { DeviceType } from 'src/app/interconnector.model';

@Component({
    selector: 'app-document-printers',
    templateUrl: './document-printers.component.html',
    styleUrls: ['./document-printers.component.scss'],
    standalone: false
})
export class DocumentPrintersComponent {
  @Input() isVisible = false;
  @Input() isPopupMode = false;
  deviceType: DeviceType = DeviceType['Drukarka dokumentów'];

  constructor() {}
}
