import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { DoubleClickResult, RefreshCardResult } from 'src/app/event.model';
import { environment } from '../../../environments/environment';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';

@Component({
    selector: 'app-finance-operation',
    templateUrl: './finance-operation.component.html',
    styleUrls: ['./finance-operation.component.scss'],
    inputs: ['dropDownBoxMode', 'selectedId', 'className', 'readOnly'],
    standalone: false
})
export class FinanceOperationComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass;
  @Input() heightGrid: number | string = window.innerHeight - 165;
  readOnly;
  dropDownBoxMode;
  selectedId;
  className;
  unicalGuid;
  focusedSelected: any[] = [];
  selectedRow: any = [];
  doubleClickResult: DoubleClickResult = 'Edycja';
  dataSource;
  code: string = '';
  focusOnLastRow: RefreshCardResult = 'Nie';
  mode: string = '';
  addRow: boolean = false;
  shortcuts: ShortcutInput[] = [];
  isDeleteRow: boolean = false;
  focusedRowIndex: any = 0;
  choosingCode: any = [];
  isGridBoxOpened: boolean = false;
  codeList: any = [];

  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
  ];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    if (this.dropDownBoxMode) {
      this.heightGrid = 200;
    }

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.getUserParameters();
    this.getData();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.addNewRow();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.editRow();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isDelete();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    if (this.selectedId && this.selectedId.length >= 0) {
      this.appService
        .getAuth(
          `document/sales/types/finance/operation?Code=${this.selectedId}`
        )
        .subscribe((res) => {
          this.codeList = res.data;
          this.choosingCode = [this.selectedId];
          this.cd.detectChanges();
        });
    } else this.choosingCode = [];
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) this.addNewRow();
        break;
      case 1:
        if (!this.readOnly) this.editRow();
        break;
      case 2:
        if (!this.readOnly) this.isDelete();
        break;
    }
  };

  onClosing = () => {
    this.addRow = false;
    this.isDeleteRow = false;
    setTimeout(() => {
      this.event.setFocus(this.gridClass);
    }, 100);
    this.cd.detectChanges();
  };

  onValueChangeSearch = (e) => {
    this.code = e.filterValue;
    this.getData();
  };

  delete = () => {
    this.isDeleteRow = false;
    this.cd.detectChanges();
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(this.focusedSelected[0].Code)
    );
  };

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  getData = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Code',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}document/sales/types/finance/operation`,
        loadParams: {
          code: this.code,
        },
        onLoaded: (data) => {
          setTimeout(() => {
            this.event.setFocus(this.gridClass);
            this.focusedSelected = [data[this.focusedRowIndex]];
          }, 100);
        },
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}document/sales/types/finance/operation`,
      }),
      reshapeOnPush: true,
    });
  };

  onSelectionChanged = (e) => {
    this.focusedSelected = [e.selectedRowsData[0]];
  };

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) {
      if (this.doubleClickResult === 'Edycja') {
        this.editRow();
      } else {
        this.showRow();
      }
    } else this.onChoosingRow();
  };

  addNewRow = () => {
    if (!this.readOnly) {
      this.mode = 'add';
      this.addRow = true;
      this.cd.detectChanges();
    }
  };

  editRow = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.mode = 'edit';
      this.addRow = true;
      this.cd.detectChanges();
    }
  };

  showRow = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.mode = 'show';
      this.addRow = true;
      this.cd.detectChanges();
    }
  };

  onInserted = () => {
    this.addRow = false;
    this.cd.detectChanges();
    this.getData();
  };

  isDelete = () => {
    if (
      this.focusedSelected.length > 0 &&
      !this.readOnly &&
      !this.dropDownBoxMode
    ) {
      this.isDeleteRow = true;
      this.cd.detectChanges();
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onKeyDown = (e) => {
    try {
      if (e.event.originalEvent.code == 'Enter') this.onChoosingRow();
      if (e.event.originalEvent.code == 'Escape') {
        this.isGridBoxOpened = false;
        this.cd.detectChanges();
      }
    } catch {}
  };

  onOpenedChanged = (_e) => {
    // if (e) {
    //   try {
    //     setTimeout(() => {
    //       this.gridClass.instance.focus();
    //     }, 200);
    //   } catch {}
    // }
  };

  onChoosingRow = () => {
    this.codeList = this.focusedSelected;
    this.choosingCode = this.selectedRow[0];
    this.onChoosed.emit(this.codeList[0]);
    this.isGridBoxOpened = false;
  };

  editRecord(_e) {}
}
