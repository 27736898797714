import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { EventService } from './event.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { IEdocumentCode } from './libraries/e-dokuments/IEDocumentType';
const helper = new JwtHelperService();

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate() {
    if (
      window.localStorage.getItem('WAPRO-HTML-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp * 1000
    ) {
      return true;
    }
    this.event.clearLocalStorage();
    this.event.isLog = false;

    this._router.navigate(['']);
    return false;
  }
}

@Injectable()
export class isCashId implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}
  canActivate() {
    if (
      this.event?.footerInfo?.AppCashId == 0 ||
      this.event?.footerInfo?.AppCashId == null
    ) {
      this._router.navigate(['/trade-operations/change-cash-box']);
      return false;
    }
    return true;
  }
}

@Injectable()
export class isDevelopment implements CanActivate {
  constructor() {}
  canActivate() {
    console.warn('isDevelopment');
    return false;
  }
}

@Injectable()
export class isExistToken implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate() {
    if (
      window.localStorage.getItem('WAPRO-HTML-token') &&
      new Date().getTime() <=
        helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp * 1000
    ) {
      this._router.navigate(['start/dashboard']);
      return false;
    }

    if (
      environment.AUTHAPI === 'https://qa-auth.assecobs.com/' ||
      environment.AUTHAPI === 'https://dev-auth.assecobs.com/' ||
      (environment.AUTHAPI === 'https://auth.assecobs.com/' &&
        window.localStorage.getItem('WAPRO-HTML-token') &&
        new Date().getTime() >
          helper.decodeToken(localStorage.getItem('WAPRO-HTML-token')).exp *
            1000)
    ) {
      localStorage.setItem('logOut', 'true');
    }
    this.event.clearLocalStorage();
    return true;
  }
}

@Injectable()
export class isPermission implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let code: boolean = true;
    switch (location.pathname) {
      case '/start':
        code = true;
        break;
      case '/warehouse':
        code = this.event.checkPermissions('Magazyn');
        break;
      case '/trade-operations':
        code = this.event.checkPermissions('OperacjeHandl');
        break;
      case '/reports':
        code = this.event.checkPermissions('Rap');
        break;
      case '/crm':
        code = this.event.checkPermissions('CrM');
        break;
      case '/library':
        code = this.event.checkPermissions('Kartoteki');
        break;
      case '/other':
        code = this.event.checkPermissions('Inne');
        break;
      case '/administrator':
        code = this.event.checkPermissions('Administrator');
        break;

      case '/warehouse/warehouse-documents':
        code =
          this.event.checkPermissions('DokMag') &&
          this.event.checkPermissions('Magazyn');
        break;

      case '/warehouse/orders':
        code = this.event.checkPermissions('Zam');
        break;

      case '/warehouse/production-documents':
        code = this.event.checkPermissions('Prod');
        break;

      case '/warehouse/intrastate-documents':
        code = this.event.checkPermissions('Intrastat');
        break;

      case '/warehouse/discounts':
        code = this.event.checkPermissions('Przeceny');
        break;

      case '/warehouse/sad':
        code = this.event.checkPermissions('Sad');
        break;

      case '/warehouse/inventory':
        code = this.event.checkPermissions('Rem');
        break;

      case '/warehouse/opening-balance':
        code = this.event.checkPermissions('Bil');
        break;

      case '/trade-operations/retail-sales':
        code = this.event.checkPermissions('Detal');
        break;

      case '/trade-operations/trader-documents':
        code = this.event.checkPermissions('DokHandl');
        break;

      case '/trade-operations/sales':
        code = this.event.checkPermissions('Sprzedaz');
        break;

      case '/trade-operations/buy':
        code = this.event.checkPermissions('Zakup');
        break;

      case '/trade-operations/settlements':
        code = this.event.checkPermissions('Rozr');
        break;

      case '/trade-operations/financial-raports':
        code = this.event.checkPermissions('RKWBDokFin');
        break;

      case '/trade-operations/finances-documents':
        code = this.event.checkPermissions('DokFin');
        break;

      case '/crm/crm-tasks':
        code = this.event.checkPermissions('CrMZadania');
        break;

      case '/crm/crm-kanban':
        code = this.event.checkPermissions('CrMZadania');
        break;

      case '/crm/task-calendars':
        code = this.event.checkPermissions('CrMKalendarz');
        break;

      case '/crm/service-orders':
        code = this.event.checkPermissions('SerwisZlec');
        break;

      case '/crm/service-orders-kanban':
        code = this.event.checkPermissions('SerwisZlec');
        break;

      case '/crm/offers':
        code = this.event.checkPermissions('Ofe');
        break;

      case '/crm/parcels':
        code = this.event.checkPermissions('Paczki');
        break;

      case '/library/articles':
        code = this.event.checkPermissions('Artyk');
        break;

      case '/library/customers':
        code = this.event.checkPermissions('Kontr');
        break;

      case '/library/employees':
        code = this.event.checkPermissions('Prac');
        break;
      case '/library/stock-availability':
        code = this.event.checkPermissions('RapAnalPlynnosci');
        break;

      case '/administrator/data-collectors':
        code =
          this.event.checkPermissions('UrzZew') &&
          this.event.checkPermissions('KolDan');
        break;
      case '/administrator/fiscal-printers':
        code =
          this.event.checkPermissions('UrzZew') &&
          this.event.checkPermissions('DrukFis');
        break;
      case '/administrator/document-printers':
        code = this.event.checkPermissions('UrzZew');
        break;
      case '/administrator/label-printers':
        code =
          this.event.checkPermissions('UrzZew') &&
          this.event.checkPermissions('DrukEtyk');
        break;
      case '/administrator/additional-dictionaries':
        code = this.event.checkPermissions('SlownikiDodatkowe');
        break;
      case '/administrator/additional-tables':
        code = this.event.checkPermissions('TabDodatkowe');
        break;
    }

    code = this.checkParentPath(code);
    const permissions = route.data['permissions'] as string[];
    code = this.checkNecessaryPermissions(code, permissions);

    if (route?.params?.documentType) {
      code = this.checkDocumentType(route.params.documentType);
    }

    if (!code) this._router.navigate(['no-permissions']);

    return code;
  }

  checkParentPath(canActivate: boolean): boolean {
    const path = this._router.getCurrentNavigation().finalUrl.toString();

    if (
      path.startsWith('/warehouse/') &&
      !this.event.checkPermissions('Magazyn')
    ) {
      return false;
    }

    if (
      path.startsWith('/warehouse/warehouse-documents') &&
      !this.event.checkPermissions('DokMag')
    ) {
      return false;
    }

    if (
      path.startsWith('/trade-operations/') &&
      !this.event.checkPermissions('OperacjeHandl')
    ) {
      return false;
    }

    if (path.startsWith('/crm/') && !this.event.checkPermissions('CrM')) {
      return false;
    }

    if (path.startsWith('/reports/') && !this.event.checkPermissions('Rap')) {
      return false;
    }

    if (path.startsWith('/other/') && !this.event.checkPermissions('Inne')) {
      return false;
    }

    if (
      path.startsWith('/administrator/') &&
      !this.event.checkPermissions('Administrator')
    ) {
      return false;
    }

    if (
      path.startsWith('/library/') &&
      !this.event.checkPermissions('Kartoteki')
    ) {
      return false;
    }

    return canActivate;
  }

  checkNecessaryPermissions(canActivate: boolean, codes: string[]): boolean {
    if (codes) {
      codes.forEach((code) => {
        if (!this.event.checkPermissions(code)) {
          canActivate = false;
        }
      });
    }
    return canActivate;
  }

  checkDocumentType(
    documentType: 'Z' | 'S' | 'O' | 'B' | 'H' | 'C' | 'M'
  ): boolean {
    switch (documentType) {
      case 'Z':
        return this.event.checkPermissions('StatusyZam');
      case 'S':
        return this.event.checkPermissions('StatusySerwisZlec');
      case 'O':
        return this.event.checkPermissions('StatusyOfert');
      case 'B':
        return this.event.checkPermissions('StatusyBL');
      case 'H':
        return this.event.checkPermissions('StatusyDokHan');
      case 'C':
        return this.event.checkPermissions('StatusyCRM');
      case 'M':
        return this.event.checkPermissions('StatusyDokMag');
    }
  }
}

@Injectable()
export class WarehouseDocumentsGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const mode = route.params.mode;
    let isPermission = true;
    if (mode === 'show') {
      isPermission = this.event.checkPermissions('ODokMag');
    }

    if (mode === 'edit') {
      isPermission = this.event.checkPermissions('EDokMag');
    }

    if (!isPermission) {
      this._router.navigate(['no-permissions']);
    }
    return isPermission;
  }
}

@Injectable()
export class ComercialDocumentsGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const mode = route.params.mode;
    let isPermission = true;
    if (mode === 'show') {
      isPermission = this.event.checkPermissions('ODokHandl');
    }

    if (mode === 'edit') {
      isPermission = this.event.checkPermissions('EDokHandl');
    }

    if (!isPermission) {
      this._router.navigate(['no-permissions']);
    }
    return isPermission;
  }
}

@Injectable()
export class SettlementsGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const mode = route.params.mode;
    let isPermission = true;
    if (mode === 'show') {
      isPermission = this.event.checkPermissions('ORozr');
    }

    if (mode === 'edit') {
      isPermission = this.event.checkPermissions('ERozr');
    }

    if (!isPermission) {
      this._router.navigate(['no-permissions']);
    }
    return isPermission;
  }
}

@Injectable()
export class CrmPackagesGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const mode = route.params.mode;
    let isPermission = true;
    if (mode === 'show') {
      isPermission = this.event.checkPermissions('PaczkiO');
    }

    if (mode === 'edit') {
      isPermission = this.event.checkPermissions('PaczkiE');
    }

    if (!isPermission) {
      this._router.navigate(['no-permissions']);
    }
    return isPermission;
  }
}

@Injectable()
export class CustomersGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const mode = route.params.mode;
    let isPermission = true;
    if (mode === 'show') {
      const oglOdb = this.event.checkPermissions('OKontrO');
      const oglDost = this.event.checkPermissions('OKontrD');
      isPermission =
        this.event.checkPermissions('OKontr') && (oglOdb || oglDost);
    }

    if (mode === 'edit') {
      isPermission = this.event.checkPermissions('EKontr');
    }

    if (!isPermission) {
      this._router.navigate(['no-permissions']);
    }
    return isPermission;
  }
}

@Injectable()
export class EDocumentsGuard implements CanActivate {
  constructor(private event: EventService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const code = route.params.DocumentCode as IEdocumentCode;
    let isPermission = true;

    switch (code.toLowerCase()) {
      case 'Primitive'.toLowerCase():
      case 'EDeclaration'.toLowerCase():
      case 'Others'.toLowerCase():
      case 'DataExchange'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokSystem');
        break;
      case 'Trade'.toLowerCase():
      case 'TradePurchase'.toLowerCase():
      case 'TradeSale'.toLowerCase():
      case 'Financial'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokHandl');
        break;
      case 'Warehouse'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokMag');
        break;
      case 'Orders'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokZam');
        break;
      case 'CustomerRelated'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokKontr');
        break;
      case 'ProductRelated'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokArtyk');
        break;
      case 'EmployeeRelated'.toLowerCase():
      case 'ContractWorkAgreementRelated'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokPrac');
        break;
      case 'FirmRelated'.toLowerCase():
      case 'CarsRelated'.toLowerCase():
      case 'FixedAssetsRelated'.toLowerCase():
      case 'EquipmentRelated'.toLowerCase():
      case 'JPK'.toLowerCase():
      case 'JPK_VAT'.toLowerCase():
      case 'EFinancialReport'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokPodm');
        break;
      case 'CrmTaskRelated'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokCrm');
        break;
      case 'ProductionOrderRelated'.toLowerCase():
      case 'Service'.toLowerCase():
      case 'ServiceOrderRelated'.toLowerCase():
      case 'ServiceDeviceRelated'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokProd');
        break;
      case 'Offers'.toLowerCase():
        isPermission = this.event.checkPermissions('eDokDokOfe');
        break;
      default:
        isPermission = false;
    }

    if (!isPermission) {
      this._router.navigate(['no-permissions']);
    }
    return isPermission;
  }
}

@Injectable()
export class chechCassLog implements CanActivate {
  constructor() {}

  canActivate() {
    if (
      !localStorage.getItem('WAPRO-HTML-token') &&
      (environment.AUTHAPI === 'https://qa-auth.assecobs.com/' ||
        environment.AUTHAPI === 'https://dev-auth.assecobs.com/' ||
        environment.AUTHAPI === 'https://auth.assecobs.com/') &&
      !localStorage.getItem('TGC')
    ) {
      // const state = this.strRandom(40);
      // const codeVerifier = this.strRandom(128);
      // localStorage.setItem('state', state);
      // localStorage.setItem('codeVerifier', codeVerifier);
      // const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(
      //   CryptoJS.enc.Base64
      // );
      // const codeChallenge = codeVerifierHash
      //   .replace(/=/g, '')
      //   .replace(/\+/g, '-')
      //   .replace(/\//g, '_');
      // const params = [
      //   'response_type=code',
      //   'state=' + state,
      //   'client_id=' + environment.CASCLIENTID,
      //   'scope=openid profile email offline_access',
      //   'code_challenge=' + codeChallenge,
      //   'code_challenge_method=S256',
      //   'redirect_uri=' + encodeURIComponent(environment.oauthCallbackUrl),
      // ];
      // window.location.href =
      //   environment.AUTHAPI + 'oauth2.0/authorize?' + params.join('&');
      // localStorage.setItem('TGC', 'state');
      return false;
    }
    if (localStorage.getItem('TGC')) return false;
    return true;
  }

  strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
