import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-add-model-brand',
    templateUrl: './add-model-brand.component.html',
    styleUrls: ['./add-model-brand.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'brandId'],
    standalone: false
})
export class AddModelBrandComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('focusFirst') focusFirst;

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  brandId;

  widthWindow = 450;
  heightWindow = 150;

  shortcuts: ShortcutInput[] = [];

  form;
  submitted: boolean = false;

  unicalGuid;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.createForm();
  }

  ngOnChanges() {
    if (this.selectedId) {
      if (this.selectedId.length != 0 && this.isVisible)
        setTimeout(() => {
          this.form.patchValue(this.selectedId[0]);
        }, 0);
      //this.getDateSelectedId(this.selectedId[0])
    }

    if (this.isVisible) {
      setTimeout(() => {
        this.focusFirst.instance.focus();
      }, 700);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.disabletShortcuts.next(true);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(`services/brands/${this.brandId}/models?modelId=${selectedId}`)
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      ServiceModelId: [0],
      ServiceBrandId: [this.brandId],
      Name: [''],
      IsDefault: [false],
    });
  };

  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.createForm(); //reset form with initial values
    this.submitted = false;
    this.event.disabletShortcuts.next(e);

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (this.form.value.ServiceModelId == 0) {
        this.appService
          .postAuth(`services/brands/${this.brandId}/models`, this.form.value)
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else {
        this.appService
          .putAuth(
            `services/brands/${this.brandId}/models/${this.form.value.ServiceModelId}`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };
}
