<dx-popup
  [(width)]="localStorageData.windowSize.width"
  [(height)]="localStorageData.windowSize.height"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [fullScreen]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.isFullscreen
      : false
  "
  (onShown)=" event.onShownPopUp();"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <div style="display: inline-block">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
    </div>
    <form [formGroup]="form">
      <div>
        <div class="d-flex justify-content-center flex-wrap">
          <div
            class="form-group-inline-right"
            [ngStyle]="event.deviceType == 'mobile' ? { 'width': '360px' } : {'width': '400px'}"
          >
            <div class="c-frame-row"
                 style="width: 100%">
              <h5 style="padding-left: 10px">
                {{
                  "routes.addressRoute" | translate
                }}
              </h5>
            </div>

            <div>
              <label style="width: 110px"
              >{{ "companies.form.postalCode" | translate }}
              </label
              >
              <dx-text-box
                width="276"
                [readOnly]="readOnly"
                formControlName="DeliveryPostalCode"
              >
              </dx-text-box>
            </div>
            <div>
              <label style="width: 110px"
              >{{ "bankBox.grid.columns.city" | translate }}
                <strong>*</strong></label
              >
              <dx-text-box
                width="276"
                [readOnly]="readOnly"
                formControlName="DeliveryCity"
                [ngClass]="{
                    'input-required': form.controls.DeliveryCity.invalid && submitted
                  }"
              >
              </dx-text-box>
            </div>

            <div>
              <label style="width: 110px"
              >{{ "offices.street" | translate }}</label
              >
              <dx-text-box
                width="276"
                [readOnly]="readOnly"
                formControlName="DeliveryStreet"
                [ngClass]="{
                    'input-required': form.controls.DeliveryStreet.invalid && submitted
                  }"
              >
              </dx-text-box>
            </div>

            <div>
              <label style="width: 110px"
              >{{ "companies.grid.columns.house" | translate }}</label
              >
              <dx-text-box
                width="276"
                [readOnly]="readOnly"
                formControlName="DeliveryHouseNo"
                [ngClass]="{
                    'input-required': form.controls.DeliveryHouseNo.invalid && submitted
                  }"
              >
              </dx-text-box>
            </div>

            <div>
              <label style="width: 110px"
              >{{ "companies.grid.columns.local" | translate }}
              </label>
              <dx-text-box
                width="276"
                [readOnly]="readOnly"
                formControlName="DeliveryApNo"
              >
              </dx-text-box>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      *ngIf="event.deviceType == 'mobile'"
      class="popup-footer">
      <ng-container *ngTemplateOutlet="mobileButtonsTemplate"></ng-container>
    </div>
  </div>
</dx-popup>

<ng-template #topButtons>
  <div
    class="d-inline-block">
    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 6px"
      (onClick)="onSaveAddressRoute()"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
    >
    </dx-button>

    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancelAddressRoute()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>

  <dx-tooltip
    [target]="'#add' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<ng-template #mobileButtonsTemplate>
  <dx-button
    text="{{ 'buttons.save' | translate }}"
    type="success"
    style="margin-right: 6px"
    (onClick)="onSaveAddressRoute()"
    [id]="'add' + unicalGuid"
    *ngIf="!readOnly"
  >
  </dx-button>

  <dx-button
    text="{{ 'buttons.cancel' | translate }}"
    type="danger"
    (onClick)="onCancelAddressRoute()"
    [id]="'cancel' + unicalGuid"
  >
  </dx-button>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>
