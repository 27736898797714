<div *ngIf="!dropDownBoxMode; else dropDownMode">
  <ng-container>
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <dx-button
      data-cy="add-packing-type-btn"
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      stylingMode="text"
      type="default"
      [id]="'add' + unicalGuid"
      (onClick)="addBox()"
      (disabledChange)="disabledChange($event)"
      [disabled]="readOnly"
    >
    </dx-button>
    <dx-tooltip
      [target]="'#add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }}
      </div>
    </dx-tooltip>

    <dx-button data-cy="edit-packing-type-btn"
               class="btn-dx-top-menu-folder"
               icon="icon absui-icon--mode-edit"
               stylingMode="text"
               type="default"
               [id]="'btn-edit' + unicalGuid"
               (onClick)="onEditClick()"
               (disabledChange)="disabledChange($event)"
               [disabled]="readOnly || focusedBox?.length == 0 || !focusedBox[0].CompanyId"
    >
    </dx-button>
    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>
    <dx-button data-cy="delete-packing-type-btn"
               icon="icon absui-icon--highlight-off"
               [id]="'delete' + unicalGuid"
               class="btn-dx-top-menu-folder"
               [disabled]="readOnly || focusedBox?.length == 0 || !focusedBox[0].CompanyId"
               stylingMode="text"
               type="default"
               (onClick)="isDelete()"
    >
    </dx-button>
    <dx-tooltip
      [target]="'#delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }}
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <dx-data-grid data-cy="packing-types-grid"
                [dataSource]="dataSource"
                [wordWrapEnabled]="true"
                [showBorders]="true"
                shortcut
                [height]="heightGrid"
                [columnResizingMode]="'widget'"
                [remoteOperations]="true"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                [focusedRowEnabled]="true"
                [autoNavigateToFocusedRow]="true"
                [(focusedRowIndex)]="packingFocusedRowIndex"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [id]="'grid' + unicalGuid"
                #gridClass
                [hoverStateEnabled]="true"
                (onKeyDown)="onKeyDown($event)"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="virtual"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="50"
                [enabled]="true"
    ></dxo-paging>
    <dxo-editing
      mode="row"
      [confirmDelete]="false"
    ></dxo-editing>

    <dxi-column [allowSorting]="false"
                dataField="Name"
                cellTemplate="Name"
                [minWidth]="85"
                [caption]="'companies.grid.columns.name' | translate"
    ></dxi-column>
    <div *dxTemplate="let data of 'Name'">
      <div class="d-flex justify-content-between align-items-center">
        <div><span class="hov-underline"
                   (click)="onEditClick()">{{
            data.data.Name
          }}</span></div>
        <div *ngIf="!data.data.CompanyId">
          <i class="icon absui-icon--lock-full"></i>
        </div>
      </div>

    </div>

    <dxi-column [allowSorting]="false"
                [minWidth]="50"
                [format]="format0"
                dataField="Width"
                [caption]="
                  'articles.width4' | translate
                "
                headerCellTemplate="Width"
                cellTemplate="WidthTemplate"
    ></dxi-column>
    <div
      *dxTemplate="let data of 'Width'"
      class="text-center"
    >
      <div
        class="text-center"
        [id]="'Width' + unicalGuid"
      >
        {{ "articles.width4" | translate }}
      </div>
      <dx-tooltip
        [target]="'#Width' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "articles.width2" | translate }}
        </div>
      </dx-tooltip>
    </div>
    <div *dxTemplate="let WidthTemplate of 'WidthTemplate'">
      <div>
        {{ WidthTemplate.data.Width | amountFormatter : null : 1 }}
      </div>
    </div>

    <dxi-column [allowSorting]="false"
                [minWidth]="50"
                [format]="format0"
                dataField="Height"
                [caption]="
                  'articles.height4' | translate
                "
                headerCellTemplate="Height"
                cellTemplate="HeightTemplate"
    ></dxi-column>
    <div *dxTemplate="let HeightTemplate of 'HeightTemplate'">
      <div>
        {{ HeightTemplate.data.Height | amountFormatter : null : 1 }}
      </div>
    </div>
    <div
      *dxTemplate="let data of 'Height'"
      class="text-center"
    >
      <div
        class="text-center"
        [id]="'Height' + unicalGuid"
      >
        {{ "articles.height4" | translate }}
      </div>
      <dx-tooltip
        [target]="'#Height' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "articles.height2" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dxi-column [allowSorting]="false"
                [format]="format0"
                [minWidth]="50"
                dataField="Depth"
                [caption]="
                  'articles.depth4' | translate
                "
                headerCellTemplate="Depth"
                cellTemplate="DepthTemplate"
    ></dxi-column>
    <div *dxTemplate="let DepthTemplate of 'DepthTemplate'">
      <div>
        {{ DepthTemplate.data.Depth | amountFormatter : null : 1 }}
      </div>
    </div>
    <div
      *dxTemplate="let data of 'Depth'"
    >
      <div
        class="text-center"
        [id]="'Depth' + unicalGuid"
      >
        {{ "articles.depth4" | translate }}
      </div>
      <dx-tooltip
        [target]="'#Depth' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "articles.depth2" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dxi-column
      [allowSorting]="false"
      [minWidth]="80"
      dataField="MaxWeight"
      [format]="format000"
      [caption]="
                  'articles.weight4' | translate
                "
      headerCellTemplate="MaxWeight"
      cellTemplate="MaxWeightTemplate"
    ></dxi-column>
    <div *dxTemplate="let MaxWeightTemplate of 'MaxWeightTemplate'">
      <div>
        {{ MaxWeightTemplate.data.MaxWeight | amountFormatter : null : 3 }}
      </div>
    </div>
    <div
      *dxTemplate="let data of 'MaxWeight'"
      class="text-center"
    >
      <div
        class="text-center"
        [id]="'MaxWeight' + unicalGuid"
      >
        {{ "articles.weight4" | translate }}
      </div>
      <dx-tooltip
        [target]="'#MaxWeight' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "articles.weight2" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dxi-column [allowSorting]="false"
                [minWidth]="90"
                [format]="format0"
                alignment="center"
                dataField="Thickness"
                [caption]="
                  'articles.packagingThickness' | translate
                "
                cellTemplate="ThicknessTemplate"
    ></dxi-column>
    <div *dxTemplate="let ThicknessTemplate of 'ThicknessTemplate'">
      <div>
        {{ ThicknessTemplate.data.Thickness | amountFormatter : null : 1 }}
      </div>
    </div>

    <!--              <dxi-column-->
    <!--                dataField="IsDefault"-->
    <!--                caption=" "-->
    <!--                width="30"-->
    <!--                cellTemplate="IsDefault"-->
    <!--              ></dxi-column>-->
    <!--              <div *dxTemplate="let data of 'IsDefault'">-->
    <!--                <i-->
    <!--                  class="icon absui-icon&#45;&#45;check check-grid"-->
    <!--                  *ngIf="data.data.IsDefault"-->
    <!--                ></i>-->
    <!--                <i-->
    <!--                  class="icon absui-icon&#45;&#45;close close-grid"-->
    <!--                  *ngIf="!data.data.IsDefault"-->
    <!--                ></i>-->
    <!--              </div>-->

  </dx-data-grid>

</ng-template>

<ng-template #mobileTemplate>
  <dx-data-grid
    [dataSource]="dataSource"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    shortcut
    [height]="heightGrid"
    [columnResizingMode]="'widget'"
    [remoteOperations]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [focusedRowEnabled]="true"
    [autoNavigateToFocusedRow]="true"
    [(focusedRowIndex)]="packingFocusedRowIndex"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [id]="'grid' + unicalGuid"
    #gridClass
    [hoverStateEnabled]="true"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-paging [pageSize]="200"></dxo-paging>
    <dxo-editing mode="row"
                 [confirmDelete]="false"></dxo-editing>
    <dxi-column [allowSorting]="false"
                dataField="Name"
                cellTemplate="Name"
                [minWidth]="85"
                [caption]="'companies.grid.columns.name' | translate"
    ></dxi-column>
    <div *dxTemplate="let data of 'Name'">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="d-flex">
            <span *ngIf="data.data.CompanyId; else blockPaking"
                  class="hov-underline"
                  (click)="onEditClick()">{{ data.data.Name }}
            </span>
            <ng-template #blockPaking>
              {{ data.data.Name }}
            </ng-template>
            <div style="margin-left: 5px"
                 *ngIf="!data.data.CompanyId">
              <i class="icon absui-icon--lock-full_mobile"></i>
            </div>
          </div>
          <div>
            <label class="mobile-grid-label">{{ "articles.width2" | translate }}:</label>
            <p class="mobile-grid-text">{{ data.data.Width | amountFormatter : null : 1 }}</p>
          </div>
          <div>
            <label class="mobile-grid-label">{{ "articles.height2" | translate }}:</label>
            <p class="mobile-grid-text">{{ data.data.Height | amountFormatter : null : 1 }}</p>
          </div>
          <div>
            <label class="mobile-grid-label">{{ "articles.depth4" | translate }}:</label>
            <p class="mobile-grid-text">{{ data.data.Depth | amountFormatter : null : 1 }}</p>
          </div>
          <div>
            <label class="mobile-grid-label">{{ "articles.weight4" | translate }}:</label>
            <p class="mobile-grid-text">{{ data.data.MaxWeight | amountFormatter : null : 3 }}</p>
          </div>
          <div>
            <label class="mobile-grid-label">{{ "articles.packagingThickness" | translate }}:</label>
            <p class="mobile-grid-text">{{ data.data.Thickness | amountFormatter : null : 1 }}</p>
          </div>
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="isContextMenuMobile = true"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenu()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>
</ng-template>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      stylingMode="text"
      type="default"
      [id]="'add' + unicalGuid"
      (onClick)="addBox()"
      (disabledChange)="disabledChange($event)"
      [disabled]="readOnly || focusedBox?.length == 0"
    >
    </dx-button>
  </div>
</ng-template>

<ng-template #dropDownMode>
  <p>dropDownMode</p>
</ng-template>

<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType != 'mobile' ? heightWindow : '450'"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isAddingOrEditingVisible"
  [wrapperAttr]="{ id: unicalGuid }"
  (onShown)="setFocus();"
  (visibleChange)="visibleChange($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        data-cy="save-packing-type-btn"
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'save' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        data-cy="cancel-packing-type-btn"
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isAddingOrEditingVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#save' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <!--    'input-required':-->
    <!--    !form.controls.DeliveryCode.valid && submitted-->
    <dx-scroll-view width="100%"
                    height="100%"
                    direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right"
             style="width: 445px">
          <form [formGroup]="form">
            <label style="width: 156px"
            >{{ 'companies.grid.columns.name' | translate }}
              <strong>*</strong></label
            >
            <dx-text-box data-cy="packing-type-name-input"
                         width="276"
                         #Name
                         formControlName="Name"
                         [ngClass]="{
                    'input-required': submitted && !form.controls.Name.valid
                  }"
                         [readOnly]="readOnly"
                         valueChangeEvent="input"
            >
            </dx-text-box>

            <br/>
            <label style="width: 156px">{{
                'articles.width2' | translate
              }}<strong>*</strong></label>
            <dx-number-box data-cy="packing-type-width-input"
                           [format]="format0"
                           formControlName="Width"
                           width="276"
                           [ngClass]="{
                    'input-required': submitted && !form.controls.Width.valid
                  }"
                           [readOnly]="readOnly"
                           valueChangeEvent="input"
            >
            </dx-number-box>
            <br/>
            <label style="width: 156px">{{
                'articles.height2' | translate
              }}<strong>*</strong></label>
            <dx-number-box data-cy="packing-type-height-input"
                           [format]="format0"
                           formControlName="Height"
                           width="276"
                           [ngClass]="{
                    'input-required': submitted && !form.controls.Height.valid
                  }"
                           [readOnly]="readOnly"
                           valueChangeEvent="input"
            >
            </dx-number-box>
            <br/>
            <label style="width: 156px">{{
                'articles.depth2' | translate
              }}<strong>*</strong></label>
            <dx-number-box data-cy="packing-type-depth-input"
                           [format]="format0"
                           formControlName="Depth"
                           width="276"
                           [ngClass]="{
                    'input-required': submitted && !form.controls.Depth.valid
                  }"
                           [readOnly]="readOnly"
                           valueChangeEvent="input"
            >
            </dx-number-box>

            <label style="width: 156px">{{
                'articles.weight2' | translate
              }}<strong>*</strong></label>
            <dx-number-box data-cy="packing-type-max-weight-input"
                           [format]="format000"
                           formControlName="MaxWeight"
                           width="276"
                           [ngClass]="{
                    'input-required': submitted && !form.controls.MaxWeight.valid
                  }"
                           [readOnly]="readOnly"
                           valueChangeEvent="input"
            >
            </dx-number-box>

            <div style="position: relative">
              <label style="width: 156px">{{
                  'articles.packagingThickness' | translate
                }}</label>
              <dx-number-box
                [format]="format0"
                formControlName="Thickness"
                width="276"
                [readOnly]="readOnly"
              >
              </dx-number-box>
              <dx-button
                style="position: absolute; right: -35px; top: 1px"
                *ngIf="event.deviceType != 'mobile'"
                class="btn-dx-top-menu-folder"
                icon="icon absui-icon--help"
                type="default"
                (click)="showTooltipHelp = true"
                [id]="'btn-help' + unicalGuid"
              ></dx-button>
            </div>


            <dx-tooltip
              [target]="'#btn-help' + unicalGuid"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              [showEvent]="event.tooltipShowEvent"
            >
              <div
                *dxTemplate="let data of 'content'"
                [innerHTML]="
                          'articles.packagingThicknessDscr' | translate
                        "
              ></div>
            </dx-tooltip>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="isAddingOrEditingVisible"
></wapro-keyboard-shortcuts>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcutsEdit"
  [unicalGuid]="unicalGuid"
  [disabled]="!isAddingOrEditingVisible"
></wapro-keyboard-shortcuts>

<app-confirm-dialog
  [isVisible]="isDeleteBox"
  (onRemoving)="deleteBox(); gridClass.instance.focus()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<dx-context-menu
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
  [dataSource]="contextMenu()"
  [target]="'#grid' + unicalGuid"
  [width]="200"
>
</dx-context-menu>

