import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { EventService } from 'src/app/event.service';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';

@Component({
    selector: 'app-enter-scaner-value',
    templateUrl: './enter-scaner-value.component.html',
    styleUrls: ['./enter-scaner-value.component.scss'],
    inputs: ['isVisible', 'title'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnterScanerValueComponent implements OnInit {
  @Output() onClosed = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('codeInput') codeInput;
  @Input() inventory = false;
  title;
  @Input() value: string = '';
  isVisible: boolean = false;
  unicalGuid;
  shortcuts: ShortcutInput[] = [];
  @Input() readOnly = false;

  constructor(public event: EventService, public cd: ChangeDetectorRef) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    if (this.inventory) this.readOnly = false;
  }

  ngAfterViewInit(): void {
    this.shortcuts.push({
      key: 'f10',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        if (!this.readOnly) {
          this.onChoose();
          this.cd.detectChanges();
        }
      },
      preventDefault: true,
    });
  }

  visibleChange() {
    setTimeout(() => {
      if (!this.isVisible) {
        this.value = '';
        this.event.disabletShortcuts.next(true);
        this.onClosed.emit(true);
      }
    });
  }

  onChoose() {
    this.onChoosed.emit(this.value);
    this.onClosed.emit(true);
  }

  onKeyDown(e) {
    if (e.event.key == 'F11') e.event.preventDefault();
  }
}
