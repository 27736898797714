<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  (onResizeEnd)="onResizeEnd()"
  [fullScreen]="event.deviceType != 'mobile' ? fullScreen : false"
  (fullScreenChange)="fullScreenChange($event)"
  (onShown)="event.onShownPopUp(); setFocus(); visibleComponent = true"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
      *ngIf="event.deviceType != 'mobile'; else mobileHeaderTemplate"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--fullscreen2': !fullScreen,
          'absui-icon--fullscreen-off': fullScreen
        }"
        (click)="fullScreen = !fullScreen"
        style="position: relative; top: 5px"
        [id]="'fitToWidth' + unicalGuid"
      ></i>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
      >
      </dx-button>
      <dx-button
        data-cy="add-devices_save-button"
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        *ngIf="event.deviceType === 'desktop'"
        [target]="'#fitToWidth' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "servicesDevices.fitToWidth" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        *ngIf="event.deviceType === 'desktop'"
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        *ngIf="event.deviceType === 'desktop'"
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      (onScroll)="onScroll($event)"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 64px)'"
    >
      <form [formGroup]="form">
        <dx-tab-panel
          #tabPanel
          [(selectedIndex)]="selectedtab"
          [items]="[
            'companies.tabs.basicInfo' | translate,
            'servicesDevices.descriptionComments' | translate,
            'servicesDevices.rolesOfContractors' | translate
          ]"
          [loop]="false"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          class="mt5px"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                <div class="form-columns">
                  <div class="form-column form-column__left">
                    <div class="form-column__item">
                      <label
                        >{{ "servicesDevices.serviceGroup" | translate }}
                        <strong>*</strong></label
                      >
                      <div class="form-control">
                        <app-groups
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          (onChoosed)="onChoosedGroup($event)"
                          [selectedId]="form.get('ServiceGroupId').value"
                          #focusFirst
                        ></app-groups>
                      </div>
                    </div>
                  </div>

                  <div class="form-column form-column__left">
                    <div class="form-column__header">
                      {{ "servicesDevices.linkingToTheAssortment" | translate }}
                    </div>

                    <div class="form-column__item">
                      <label
                        >{{ "menu.warehouse" | translate }}
                        <strong>*</strong></label
                      >
                      <div class="form-control">
                        <app-warehouse-list
                          data-cy="add-devices_warehouse-list"
                          [className]="
                            !form.get('WarehouseId').value && submitted
                          "
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          [selectedId]="form.get('WarehouseId').value"
                          (onChoosed)="onChoosedWaregouse($event)"
                        >
                        </app-warehouse-list>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{ "articles.title" | translate }}</label>
                      <div class="form-control">
                        <app-articles
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          (onChoosed)="onChoosedArticle($event)"
                          [selectedId]="form.get('ProductId').value"
                          [ngClass]="{
                            'width-mobile': event.deviceType == 'mobile'
                          }"
                        ></app-articles>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label
                        >{{ "articles.filter.name" | translate }}
                        <strong>*</strong></label
                      >
                      <div class="form-control">
                        <dx-text-box
                          data-cy="add-devices_form-name"
                          [readOnly]="readOnly"
                          formControlName="ProductName"
                          [ngClass]="{
                            'width-mobile': event.deviceType == 'mobile'
                          }"
                          [ngClass]="{
                            'input-required':
                              !form.controls.ProductName.valid && submitted
                          }"
                          (onFocusIn)="
                            event.onFocusCountry('ProductName', form)
                          "
                          (onFocusOut)="event.onFocusOut('ProductName', form)"
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label
                        >{{ "servicesDevices.brandName" | translate }}
                      </label>
                      <div class="form-control">
                        <app-brands
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          (onChoosed)="onChoosedBrand($event)"
                          [selectedId]="form.get('BrandId').value"
                          *ngIf="isVisible"
                          #brand
                          [ngClass]="{
                            'width-mobile': event.deviceType == 'mobile'
                          }"
                        >
                        </app-brands>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.modelName" | translate
                      }}</label>
                      <div class="form-control">
                        <app-model-brand
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          [brandId]="form.get('BrandId').value"
                          (onChoosed)="onChoosedModel($event)"
                          [selectedId]="form.get('ModelId').value"
                          [ngClass]="{
                            'width-mobile': event.deviceType == 'mobile'
                          }"
                        ></app-model-brand>
                      </div>
                    </div>

                    <div class="form-column__header">
                      {{ "servicesDevices.numbers" | translate }}
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.production" | translate
                      }}</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="readOnly"
                          formControlName="ProductionNumber"
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{ "servicesDevices.serial" | translate }}</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="readOnly"
                          formControlName="SerialNumber"
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{ "servicesDevices.barcode" | translate }}</label>
                      <div class="form-control">
                        <dx-text-box
                          [readOnly]="readOnly"
                          formControlName="Barcode"
                          valueChangeEvent="input"
                        >
                        </dx-text-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.guaranteeMonths" | translate
                      }}</label>
                      <div class="form-control">
                        <dx-number-box
                          [readOnly]="readOnly"
                          formControlName="GuaranteeMonths"
                          valueChangeEvent="input"
                          [min]="0"
                          [useLargeSpinButtons]="true"
                          [showSpinButtons]="true"
                        >
                        </dx-number-box>
                      </div>
                    </div>
                  </div>

                  <div class="form-column form-column__right">
                    <div class="form-column__header">
                      {{ "servicesDevices.deviceDate" | translate }}
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.productionDate" | translate
                      }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="date"
                          [readOnly]="readOnly"
                          [displayFormat]="event.dateFormat"
                          formControlName="ProductionDate"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.saleDate" | translate
                      }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="date"
                          [readOnly]="readOnly"
                          [displayFormat]="event.dateFormat"
                          formControlName="SaleDate"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.lastServiceDate" | translate
                      }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="date"
                          [readOnly]="readOnly"
                          [displayFormat]="event.dateFormat"
                          formControlName="LastServiceDate"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.nextServiceDate" | translate
                      }}</label>
                      <div class="form-control">
                        <wapro-date-box
                          type="date"
                          [readOnly]="readOnly"
                          [displayFormat]="event.dateFormat"
                          formControlName="NextServiceDate"
                        >
                        </wapro-date-box>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.serviceTechnician" | translate
                      }}</label>

                      <div class="form-control">
                        <app-workers
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          (onChoosed)="onChoosedServiceTechnician($event)"
                          [selectedId]="form.get('ServiceTechnicianId').value"
                          *ngIf="isVisible"
                        ></app-workers>
                      </div>
                    </div>

                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.producer" | translate
                      }}</label>

                      <div class="form-control">
                        <app-contractors
                          [dropDownBoxMode]="true"
                          [readOnly]="readOnly"
                          [selectedId]="form.get('Producer').value"
                          (onChoosed)="onChoosedProducer($event)"
                          filtrName="true"
                          *ngIf="visibleComponent"
                        ></app-contractors>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngSwitchCase="
                  'servicesDevices.descriptionComments' | translate
                "
              >
                <div class="form-columns">
                  <div class="form-column form-column__left">
                    <div class="form-column__item">
                      <label>{{
                        "servicesDevices.description" | translate
                      }}</label>
                      <div class="form-control">
                        <dx-text-area
                          [readOnly]="readOnly"
                          formControlName="Description"
                          valueChangeEvent="input"
                          [minHeight]="120"
                        >
                        </dx-text-area>
                      </div>
                    </div>
                  </div>

                  <div class="form-column form-column__right">
                    <div class="form-column__item">
                      <label>{{ "servicesDevices.remarks" | translate }}</label>
                      <div class="form-control">
                        <dx-text-area
                          [readOnly]="readOnly"
                          formControlName="Remarks"
                          valueChangeEvent="input"
                          [minHeight]="120"
                        >
                        </dx-text-area>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngSwitchCase="'servicesDevices.rolesOfContractors' | translate"
              >
                <div class="top-menu-popup">
                  <dx-button
                    class="btn-dx-top-menu"
                    icon="icon absui-icon--add-circle"
                    [id]="'btn-add-role' + unicalGuid"
                    [text]="'userExtensions.assignRole' | translate"
                    [disabled]="readOnly"
                    (onClick)="addRow = true"
                  >
                  </dx-button>

                  <dx-button
                    class="btn-dx-top-menu"
                    icon="icon absui-icon--highlight-off"
                    [id]="'btn-delete-role' + unicalGuid"
                    [text]="'userExtensions.deleteRole' | translate"
                    [disabled]="readOnly"
                    (onClick)="deleteRow = true"
                  >
                  </dx-button>

                  <dx-tooltip
                    *ngIf="event.deviceType != 'mobile'"
                    [target]="'#btn-add-role' + unicalGuid"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                    [showEvent]="event.tooltipShowEvent"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "buttons.add" | translate }} (Insert)
                    </div>
                  </dx-tooltip>

                  <dx-tooltip
                    *ngIf="event.deviceType != 'mobile'"
                    [target]="'#btn-delete-role' + unicalGuid"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                    [showEvent]="event.tooltipShowEvent"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "buttons.delete" | translate }} (Del)
                    </div>
                  </dx-tooltip>
                </div>
                <dx-data-grid
                  id="gridContainer"
                  [dataSource]="customerRole"
                  #gridRole
                  [wordWrapEnabled]="true"
                  [showBorders]="true"
                  [(selectedRowKeys)]="selectedRowCustomerRole"
                  [focusedRowEnabled]="true"
                  (onFocusedRowChanged)="onFocusedRowChanged($event)"
                  style="min-height: 300px"
                  [allowColumnResizing]="false"
                  [allowColumnReordering]="true"
                  [columnResizingMode]="'widget'"
                >
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxo-scrolling mode="infinite"></dxo-scrolling>
                  <dxo-paging [pageSize]="200"></dxo-paging>
                  <dxo-selection
                    [mode]="'single'"
                    showCheckBoxesMode="always"
                  ></dxo-selection>
                  <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                  <dxi-column
                    caption="{{ 'userExtensions.role' | translate }}"
                    width="300"
                    dataField="RoleName"
                    [allowSorting]="false"
                  ></dxi-column>
                  <dxi-column
                    caption="{{
                      'form-financial-document.payer-type.contractor'
                        | translate
                    }}"
                    dataField="CustomerName"
                    [allowSorting]="false"
                    [hidingPriority]="0"
                  ></dxi-column>
                </dx-data-grid>

                <app-add-role-to-customer
                  [isVisible]="addRow"
                  (onClosing)="onClosingForm()"
                  (onInserted)="onInsertedRole($event)"
                  title="{{ 'userExtensions.assignRole' | translate }}"
                  [productId]="form.get('ServiceDeviceId').value"
                  [productName]="form.get('ProductName').value"
                  [serviceDeviceId]="form.get('ServiceDeviceId').value"
                >
                </app-add-role-to-customer>

                <app-confirm-dialog
                  [isVisible]="deleteRow"
                  (onRemoving)="delete()"
                  (onClosing)="closeConfirm()"
                >
                </app-confirm-dialog>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
        <!-- pola dodatkowe -->
        <app-form-user-extensions
          [isVisible]="visibleUserExtensions"
          groupCode="Serwis_urzadzenie"
          [readOnly]="readOnly"
          title="{{ 'categories.externalFields' | translate }}"
          (onChoosed)="onInsertedUserExtensions($event)"
          (onClosing)="onClosingUserExtensions()"
          [userExtensionsValue]="form.value"
          #isValidUserExtensions
          *ngIf="isVisible"
        >
        </app-form-user-extensions>
      </form>
    </dx-scroll-view>

    <div *ngIf="event.deviceType == 'mobile'">
      <ng-container *ngTemplateOutlet="mobileBottomTemplate"></ng-container>
    </div>

    <app-confirm-dialog
      kindDialog="question"
      [paramsMsg]="nameDevice"
      confirmText="servicesDevices.doYouWantChangeDeviceName"
      [isVisible]="isChangeName"
      (onRemoving)="yesChangeName()"
      (onClosing)="noChangeName()"
    >
    </app-confirm-dialog>

    <wapro-keyboard-shortcuts
      [shortcuts]="shortcuts"
      [unicalGuid]="unicalGuid"
      [disabled]="
        !isVisible || visibleUserExtensions || deleteRow || isDisabledShortcut
      "
      #shortcutsS
    >
    </wapro-keyboard-shortcuts>
  </div>
</dx-popup>

<!-- mobile template -->

<ng-template #mobileHeaderTemplate>
  <div class="header-search-mobile">
    <i
      class="icon absui-icon--close"
      style="position: absolute; top: 14px; right: 16px; z-index: 1"
      (click)="isVisible = false"
    ></i>
  </div>
</ng-template>

<ng-template #mobileBottomTemplate>
  <div class="bottom-mobile-form">
    <dx-button
      [id]="'isMobileSettings' + unicalGuid"
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      style="float: left"
      (onClick)="isMobileSettings = true"
    ></dx-button>

    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 12px"
      (onClick)="onSave()"
      [id]="'add-mobile' + unicalGuid"
      *ngIf="!readOnly"
    ></dx-button>
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="isVisible = false"
      [id]="'cancel-mobile' + unicalGuid"
    ></dx-button>
  </div>

  <app-mobile-list
    [dataSource]="mobileMenuFooter"
    [visible]="isMobileSettings"
    (onClosed)="isMobileSettings = false"
    (onItemClick)="clickOptions($event)"
  ></app-mobile-list>
</ng-template>
