<div
  style="position: relative; display: inline-block"
  [ngClass]="{ displayBloc: event.deviceType == 'mobile' }"
  class="comercial-operation-form-position-step-3 warehouse-documents-form-position-step-3 documents-orders-form-position-step-4"
>
  <dx-text-box
    style="display: inline-block; margin-bottom: 6px"
    [(ngModel)]="PriceName"
    [readOnly]="true"
    [width]="width || 160"
    class="borderTextInput"
  >
  </dx-text-box>
  <div class="dx-btn" (click)="showPricePopUp()" *ngIf="!readOnly">
    <div class="dx-button-has-icon">
      <div class="dx-button-content">
        <i
          style="position: relative; top: -1px; left: -5px"
          class="dx-icon icon absui-icon--dropdown--folder-expand"
        ></i>
      </div>
    </div>
  </div>
</div>

<div style="position: absolute">
  <dx-popup
    [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
    [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
    [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [title]="title?.length < 10 ? title : title?.substring(0, 100) + '...'"
    [(visible)]="isVisible"
    (visibleChange)="visibleChange($event)"
    [resizeEnabled]="true"
    [dragEnabled]="true"
    [minWidth]="730"
    [minHeight]="280"
    (onShown)="event.onShownPopUp()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px"
          [id]="'choosed' + unicalGuid"
          *ngIf="!readOnly"
          (onClick)="choosedPrice()"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>
      </div>
      <dx-scroll-view width="100%" height="100%" direction="vertical">
        <div class="row">
          <div class="col-md-12">
            <dx-tab-panel
              #tabPanel
              [items]="isOrderItems ? itemsOrder : items"
              [loop]="false"
              [animationEnabled]="false"
              [swipeEnabled]="false"
              (selectedIndexChange)="selectedIndexChange($event)"
            >
              <div *dxTemplate="let name of 'item'">
                <ng-container [ngSwitch]="name">
                  <div
                    *ngSwitchCase="
                      'articles.recalculatesItems.prices' | translate
                    "
                  >
                    <app-custom-dropdown-box
                      [items]="filterCriteria"
                      [(selectedItem)]="valueCriteria"
                      [(filterValue)]="filterValue"
                      (onValueChanged)="onFilterDataChanged($event)"
                      style="margin-right: 5px"
                    >
                    </app-custom-dropdown-box>
                    <br />

                    <app-custom-chips-button
                      name="{{ 'articles.IsShowLastSellPrice' | translate }}"
                      [selectedValueInput]="IsShowLastSellPriceInput"
                      [list]="filterCriteriaStatus"
                      (onValueChanged)="onValueChangedState($event)"
                    >
                    </app-custom-chips-button>

                    <app-custom-chips-button
                      name="{{ 'articles.IsSkipCorrection' | translate }}"
                      [selectedValueInput]="ScipCorrectionInput"
                      [list]="filterCriteriaStatus"
                      (onValueChanged)="onValueChangedScipCorrection($event)"
                    >
                    </app-custom-chips-button>

                    <app-custom-chips-button
                      name="{{ 'articles.IsSetAtDefault' | translate }}"
                      [selectedValueInput]="SetAtDefaultInput"
                      [list]="filterCriteriaStatus"
                      (onValueChanged)="onValueChangedSetAtDefault($event)"
                    >
                    </app-custom-chips-button>

                    <br /><br />

                    <dx-data-grid
                      id="gridContainer"
                      #gridClass
                      [dataSource]="dataSource"
                      [wordWrapEnabled]="false"
                      [showBorders]="true"
                      shortcut
                      [height]="heightGrid"
                      [(selectedRowKeys)]="selectedRow"
                      (onSelectionChanged)="onSelectionChanged()"
                      [focusedRowEnabled]="true"
                      [remoteOperations]="true"
                      (onRowDblClick)="onRowDblClick()"
                      (onFocusedRowChanged)="onFocusedRowChanged($event)"
                      width="100%"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [allowColumnReordering]="true"
                    >
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxo-scrolling mode="infinite"></dxo-scrolling>
                      <dxo-paging [pageSize]="20"></dxo-paging>
                      <dxo-selection mode="single"></dxo-selection>
                      <dxo-editing
                        mode="row"
                        [confirmDelete]="false"
                      ></dxo-editing>

                      <dxi-column
                        caption="{{
                          'form-financial-document.description' | translate
                        }}"
                        dataField="Description"
                        width="250"
                        [allowSorting]="false"
                        headerCellTemplate="DescriptionHeader"
                        [hidingPriority]="1"
                        [cssClass]="
                          orderby == 'Description'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      >
                      </dxi-column>
                      <div *dxTemplate="let data of 'DescriptionHeader'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('Description')"
                        >
                          {{
                            "form-financial-document.description" | translate
                          }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>
                      <dxi-column
                        caption="{{ 'prices.name' | translate }}"
                        dataField="PriceName"
                        [allowSorting]="false"
                        [width]="100"
                        [hidingPriority]="4"
                      >
                      </dxi-column>

                      <dxi-column
                        caption="{{ 'articlePrices.priceNet' | translate }}"
                        dataField="PriceNet"
                        [allowSorting]="false"
                        cellTemplate="PriceNetCell"
                        headerCellTemplate="PriceNetHeader"
                        [cssClass]="
                          orderby == 'NetPrice'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>
                      <div *dxTemplate="let data of 'PriceNetHeader'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('NetPrice')"
                        >
                          {{ "articlePrices.priceNet" | translate }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>
                      <div *dxTemplate="let data of 'PriceNetCell'">
                        {{
                          data.data.NetPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{ 'articlePrices.priceGross' | translate }}"
                        dataField="GrossPrice"
                        [allowSorting]="false"
                        width="100"
                        cellTemplate="PriceGross"
                        [hidingPriority]="8"
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>
                      <div *dxTemplate="let data of 'PriceGross'">
                        {{
                          data.data.GrossPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{
                          'bankAccounts.grid.columns.currency' | translate
                        }}"
                        dataField="CurrencyCode"
                        [allowSorting]="false"
                        width="100"
                        [hidingPriority]="7"
                      ></dxi-column>

                      <dxi-column
                        caption="{{
                          'form-commercial-operation.grid.netAmountDiscount'
                            | translate
                        }}"
                        dataField="DiscountedNetPrice"
                        [allowSorting]="false"
                        width="130"
                        cellTemplate="PriceNetDiscount"
                        [hidingPriority]="6"
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>

                      <div *dxTemplate="let data of 'PriceNetDiscount'">
                        {{
                          data.data.DiscountedNetPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{
                          'form-commercial-operation.grid.grossAmountDiscount'
                            | translate
                        }}"
                        dataField="DiscountedGrossPrice"
                        [allowSorting]="false"
                        width="130"
                        [hidingPriority]="5"
                        cellTemplate="PriceGrossDiscount"
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>
                      <div *dxTemplate="let data of 'PriceGrossDiscount'">
                        {{
                          data.data.DiscountedGrossPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{ 'articles.margin' | translate }}"
                        dataField="Margin"
                        [allowSorting]="false"
                        width="80"
                        headerCellTemplate="MarginHeader"
                        [hidingPriority]="3"
                        [cssClass]="
                          orderby == 'Margin'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      ></dxi-column>

                      <div *dxTemplate="let data of 'MarginHeader'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('Margin')"
                        >
                          {{ "articles.margin" | translate }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>

                      <dxi-column
                        caption="{{ 'articles.discountMarkup' | translate }}"
                        dataField="MarkUp"
                        [allowSorting]="false"
                        width="80"
                        headerCellTemplate="DiscountHeaderDiscount"
                        [cssClass]="
                          orderby == 'MarkUp'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                        [hidingPriority]="2"
                      >
                      </dxi-column>
                      <div *dxTemplate="let data of 'DiscountHeaderDiscount'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('MarkUp')"
                        >
                          {{ "articles.discountMarkup" | translate }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>

                      <dxi-column
                        caption="{{ 'articles.currencyConverter' | translate }}"
                        dataField="CurrencyFactor"
                        [allowSorting]="false"
                        width="110"
                        [hidingPriority]="0"
                      ></dxi-column>

                      <dxi-column
                        caption="{{ 'servicesDevices.remarks' | translate }}"
                        dataField="Remarks"
                        [allowSorting]="false"
                        [width]="100"
                        [hidingPriority]="9"
                      >
                      </dxi-column>
                    </dx-data-grid>
                  </div>
                  <div
                    *ngSwitchCase="
                      'warehouseDocument.theLowestPurchase' | translate
                    "
                  >
                    <dx-data-grid
                      id="gridContainer"
                      #gridClass
                      [dataSource]="dataSourceOther"
                      [wordWrapEnabled]="false"
                      [showBorders]="true"
                      shortcut
                      [height]="heightGrid"
                      [(selectedRowKeys)]="selectedRow"
                      (onSelectionChanged)="onSelectionChanged()"
                      [focusedRowEnabled]="true"
                      [remoteOperations]="true"
                      (onRowDblClick)="onRowDblClick()"
                      (onFocusedRowChanged)="onFocusedRowChanged($event)"
                    >
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxo-scrolling mode="infinite"></dxo-scrolling>
                      <dxo-paging [pageSize]="20"></dxo-paging>
                      <dxo-selection mode="single"></dxo-selection>
                      <dxo-editing
                        mode="row"
                        [confirmDelete]="false"
                      ></dxo-editing>

                      <dxi-column
                        caption="{{
                          'form-financial-document.description' | translate
                        }}"
                        dataField="Description"
                        width="250"
                        [allowSorting]="false"
                        headerCellTemplate="DescriptionHeader"
                        [cssClass]="
                          orderby == 'Description'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      >
                      </dxi-column>
                      <div *dxTemplate="let data of 'DescriptionHeader'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('Description')"
                        >
                          {{
                            "form-financial-document.description" | translate
                          }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>
                      <dxi-column
                        caption="{{ 'articlePrices.priceNet' | translate }}"
                        dataField="PriceNet"
                        [allowSorting]="false"
                        width="100"
                        cellTemplate="PriceNetCell"
                        headerCellTemplate="PriceNetHeader"
                        [cssClass]="
                          orderby == 'NetPrice'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>
                      <div *dxTemplate="let data of 'PriceNetHeader'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('NetPrice')"
                        >
                          {{ "articlePrices.priceNet" | translate }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>
                      <div *dxTemplate="let data of 'PriceNetCell'">
                        {{
                          data.data.NetPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{ 'articlePrices.priceGross' | translate }}"
                        dataField="GrossPrice"
                        [allowSorting]="false"
                        width="100"
                        cellTemplate="PriceGross"
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>
                      <div *dxTemplate="let data of 'PriceGross'">
                        {{
                          data.data.GrossPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{
                          'bankAccounts.grid.columns.currency' | translate
                        }}"
                        dataField="CurrencyCode"
                        [allowSorting]="false"
                        width="100"
                      ></dxi-column>

                      <dxi-column
                        caption="{{
                          'form-commercial-operation.grid.netAmountDiscount'
                            | translate
                        }}"
                        dataField="DiscountedNetPrice"
                        [allowSorting]="false"
                        width="130"
                        cellTemplate="PriceNetDiscount"
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>

                      <div *dxTemplate="let data of 'PriceNetDiscount'">
                        {{
                          data.data.DiscountedNetPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{
                          'form-commercial-operation.grid.grossAmountDiscount'
                            | translate
                        }}"
                        dataField="DiscountedGrossPrice"
                        [allowSorting]="false"
                        width="130"
                        cellTemplate="PriceGrossDiscount"
                      >
                        <dxo-format type="fixedPoint" [precision]="2">
                        </dxo-format>
                      </dxi-column>
                      <div *dxTemplate="let data of 'PriceGrossDiscount'">
                        {{
                          data.data.DiscountedGrossPrice.toFixed(2)
                            | currency : " "
                            | pointReplacer
                        }}
                      </div>

                      <dxi-column
                        caption="{{ 'articles.margin' | translate }}"
                        dataField="Margin"
                        [allowSorting]="false"
                        width="80"
                        headerCellTemplate="MarginHeader"
                        [cssClass]="
                          orderby == 'Margin'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      ></dxi-column>

                      <div *dxTemplate="let data of 'MarginHeader'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('Margin')"
                        >
                          {{ "articles.margin" | translate }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>

                      <dxi-column
                        caption="{{ 'articles.discountMarkup' | translate }}"
                        dataField="MarkUp"
                        [allowSorting]="false"
                        width="80"
                        headerCellTemplate="DiscountHeaderDiscount"
                        [cssClass]="
                          orderby == 'MarkUp'
                            ? 'sort-column-bg'
                            : 'sort-column-muted'
                        "
                      >
                      </dxi-column>
                      <div *dxTemplate="let data of 'DiscountHeaderDiscount'">
                        <div
                          class="grid-header-wrapper"
                          (click)="setSearchCriteria('MarkUp')"
                        >
                          {{ "articles.discountMarkup" | translate }}
                          <i
                            class="header-sort-icon"
                            [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                          ></i>
                        </div>
                      </div>

                      <dxi-column
                        caption="{{ 'articles.currencyConverter' | translate }}"
                        dataField="CurrencyFactor"
                        [allowSorting]="false"
                        width="110"
                      ></dxi-column>
                    </dx-data-grid>
                  </div>
                </ng-container>
              </div>
            </dx-tab-panel>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </dx-popup>
</div>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
