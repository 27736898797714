import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Input, signal, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventService} from '../../event.service';
import {
  DxButtonModule, DxContextMenuModule,
  DxDataGridModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextBoxModule,
  DxTooltipModule
} from 'devextreme-angular';
import {
  DxiColumnModule,
  DxoLoadPanelModule,
  DxoPagingModule,
  DxoScrollingModule,
  DxoSelectionModule
} from 'devextreme-angular/ui/nested';
import {ShortcutDirective} from '../../menu-wapro/shortcut.directive';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {environment} from '../../../environments/environment';
import {AllowIn, ShortcutInput} from 'ng-keyboard-shortcuts';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PipesModule} from '../../pipes/pipes.module';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {AppServices} from '../../app-services.service';
import {ConfirmDialogComponent} from '../../core/confirm-dialog/confirm-dialog.component';
import {MobileListComponent} from '../../core/mobile-list/mobile-list.component';
import {NgShortcutsComponent} from "../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component";

@Component({
    selector: 'app-packing-types',
    imports: [CommonModule, DxScrollViewModule, DxButtonModule, DxDataGridModule, DxTooltipModule, DxiColumnModule, DxoLoadPanelModule, DxoPagingModule, DxoScrollingModule, DxoSelectionModule, ShortcutDirective, TranslateModule, PipesModule, DxNumberBoxModule, DxPopupModule, DxTextBoxModule, ReactiveFormsModule, ConfirmDialogComponent, DxContextMenuModule, MobileListComponent, NgShortcutsComponent],
    templateUrl: './packing-types.component.html',
    styleUrl: './packing-types.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackingTypesComponent {

  @ViewChild('gridClass') gridClass;
  @ViewChild('Name') Name;

  @Input() dropDownBoxMode: boolean = false;
  @Input() readOnly;

  isAddingOrEditingVisible = false;
  heightGrid: number = 0;
  dataSource;
  availableValue = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  shortcutsEdit: ShortcutInput[] = [];
  focusedBox = [];
  packingFocusedRowIndex = -1;
  firstLoadPacking = true;
  choosingValue = [];
  isGridBoxOpened = false;
  format0 = '#0.0';
  format000 = '#0.000';
  widthWindow = 570;
  heightWindow = 300;
  title: string;
  showTooltipHelp: boolean = false;
  mode: string = 'add';
  submitted: boolean = false;
  isDeleteBox = false;
  form: FormGroup;
  isContextMenuMobile: boolean = false;
  modeSelectionMobile: any = null;
  isEditDisabled = signal(false);
  isDeleteDisabled = signal(false);
  contextMenu = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: this.readOnly,
        translationSrc: 'buttons.add',
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled: this.readOnly || this.isEditDisabled(),
        translationSrc: 'buttons.edit',
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled: this.readOnly || this.isDeleteDisabled(),
        translationSrc: 'buttons.delete',
      }
    ];
  })

  constructor(
    public cd: ChangeDetectorRef,
    public event: EventService,
    private appService: AppServices,
    public formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getDataSource();
    });
    this.getDataSource();

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  }

  ngOnInit() {
    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 120;
    }
  }

  ngAfterViewInit() {

    this.shortcuts.push(
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.addBox();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly || this.focusedBox?.length != 0 || this.focusedBox[0].CompanyId) {
            this.isDelete();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
    );

    this.shortcutsEdit.push(
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isAddingOrEditingVisible) {
            this.onSave();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isAddingOrEditingVisible) {
            this.isAddingOrEditingVisible = false;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    )
  }

  getDataSource = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'PackagingId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}products/packaging`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedBox = [];
          } else {
            if (this.availableValue.length == 0) {
              this.availableValue = [data[0]];
              this.choosingValue = this.availableValue[0].PackagingId;
            }
          }
          if (this.firstLoadPacking) {
            this.firstLoadPacking = false;
            this.packingFocusedRowIndex = 0;
            this.onFocusedRowChanged({
              row: {data: data[this.packingFocusedRowIndex]},
            });
            this.cd.detectChanges();
          } else {
            this.onFocusedRowChanged({
              row: {data: data[this.packingFocusedRowIndex]},
            });
            setTimeout(() => {
              this.gridClass.instance.focus();
            }, 50);
            this.cd.detectChanges();
          }
        },
      })
    });
  };

  disabledChange(e) {
    this.isEditDisabled.set(e);
  }

  contextMenuClick(e: any) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.addBox();
        }
        break;
      case 1:
        if (
          !this.readOnly &&
          this.focusedBox.length != 0) {
          this.onEditClick();
        }
        break;
      case 2:
        if (
          !this.readOnly &&
          this.focusedBox.length != 0
        ) {
          this.isDelete();
        }
        break;
    }
  }

  onFocusedRowChanged(e) {
    if (e && e?.row?.data) {
      this.focusedBox = [e.row.data];
      this.isDeleteDisabled.set(this.focusedBox[0].CompanyId === 0);
      this.isEditDisabled.set(this.focusedBox[0].CompanyId === 0);
    }
  }

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;
    if (!e) {
      this.isAddingOrEditingVisible = false;
    }
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      PackagingId: [null],
      CompanyId: [0],
      Name: ['', Validators.required],
      Height: [null, Validators.required],
      Width: [null, Validators.required],
      Depth: [null, Validators.required],
      Thickness: [0],
      MaxWeight: [null, Validators.required]
    });

    if (this.mode == 'edit') {
      this.form.patchValue(this.focusedBox[0]);
    }
  };

  addBox() {
    this.mode = 'add';
    this.title = this.translate.instant(
      'addingCardboard'
    );
    this.isAddingOrEditingVisible = true;
  }

  isDelete() {
    if (this.focusedBox[0].CompanyId === 0) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'articles.systemPackagingCannotBeDeleted'
        )
      );
      return;
    }

    if (!this.readOnly) {
      this.isDeleteBox = true;
    }
  }

  deleteBox() {
    this.appService.deleteAuth(`products/packaging/${this.focusedBox[0].PackagingId}`)
      .subscribe(() => {
        this.isDeleteBox = false;
        this.focusedBox = [];
        this.getDataSource();
        this.cd.detectChanges();
      }, error => {
        this.event.httpErrorNotification(error);
      });
  }

  closeConfirm() {
    this.isDeleteBox = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  }

  onEditClick() {
    setTimeout(() => {
      if (this.focusedBox[0].CompanyId === 0) {
        this.event.showNotification(
          'error',
          this.translate.instant(
            'articles.systemPackagingCannotBeEdited'
          )
        );
        return;
      }
      this.mode = 'edit';
      this.title = this.translate.instant(
        'cardboardEditing'
      );
      this.isAddingOrEditingVisible = true;
      this.cd.detectChanges();
    });
  }

  onSave() {
    this.submitted = true;

    console.log(this.form.value);


    if (this.form.invalid) {
      return;
    }

    if (this.mode == 'add') {
      this.appService.postAuth(`products/packaging`, this.form.value).subscribe((res) => {
        this.submitted = false;
        this.isAddingOrEditingVisible = false;
        this.updateDataSource(res.PackagingId);
      }, error => {
        this.event.httpErrorNotification(error);
      });
    } else if (this.mode == 'edit') {
      this.appService.putAuth(`products/packaging/${this.form.value.PackagingId}`, this.form.value).subscribe((res) => {
        this.submitted = false;
        this.isAddingOrEditingVisible = false;
        this.updateDataSource(res.PackagingId);
      }, error => {
        this.event.httpErrorNotification(error);
      });
    }
  }

  updateDataSource = async (e) => {
    try {
      await this.dataSource.reload();

      this.packingFocusedRowIndex = this.event.returnFocusIndex(
        this.dataSource._items,
        'PackagingId',
        e
      );

      this.onFocusedRowChanged({row: {data: this.dataSource[this.packingFocusedRowIndex]}});

      if (this.mode === 'edit') {
        this.availableValue = [this.focusedBox[0]];
        this.choosingValue = this.availableValue[0].PackagingId;
      }

      this.event.setFocus(this.gridClass);
      this.cd.detectChanges();
    } catch (error) {
      this.event.httpErrorNotification(error);
    }
  };


  editRecord(e, i?) {
    if (i) {
      this.focusedBox = [i];
    }
    e.stopPropagation();
    if (
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.editPackage();
    }
  }

  setFocus() {
    setTimeout(() => {
      this.Name.instance.focus();
    }, 500);
  }

  editPackage() {

  }

  onKeyDown(e) {
    if (e.event.key === 'F2' && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (!this.readOnly || this.focusedBox?.length != 0 || this.focusedBox[0].CompanyId) {
        this.onEditClick();
        this.cd.detectChanges();
      }
    }
  }
}
